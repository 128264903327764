import React, { useEffect, useState } from 'react';
import { BreadcrumbCustom } from '../../../components/BreadcrumbCustom';
import { Col, Row, Steps, Image } from 'antd';
import { isEmpty } from 'lodash';
import ChartLines from './Chart/ChartLines';
import { EmptyView } from '../../../components/EmptyView';
import { getAlarmDetail } from '../../../api/webServe';
import { convert, status, alarmLevel, hiddenDanger } from '../../../global';
import { MyIcon } from '../../../util/iconfont';
import { peakvalue } from '../../../util/function-util';

const { Step } = Steps;

export const ReadHiddenAlarm = () => {
    return (
        <div className="container clearfix">
            <BreadcrumbCustom
                paths={['首页', '隐患管理', '隐患列表', '查看隐患']}
                className="rumbCustom"
                showCloseBtn={true}
            />
            <div className="row clearfix" style={{ borderRadius: '10px', paddingBottom: '1rem', width: '100%' }}>
                <DeviceDetailsAlarm />
            </div>
        </div>
    );
};

const DeviceDetailsAlarm = () => {
    var id = window.location.search.slice(window.location.search.lastIndexOf('?') + 1);
    const [detailsData, setDetailsData] = useState({});
    const [work_orderShow, setWork_orderShow] = useState(false);
    const [eventShow, setEventShow] = useState(false);
    useEffect(() => {
        getDetail(id.slice(3)); //设备详情
    }, [id]);
    const getDetail = id => {
        getAlarmDetail({ id })
            .then(result => {
                setDetailsData(result.data);
                setWork_orderShow(result.data && (result.data.work_order == null ? false : true))
                setEventShow(result.data && (result.data.event === '设备离线' ? false : true))
            })
            .catch();
    };

    /**
     * yData y轴数值
     * xTime x轴时间
     * 从后端获取到的数据把时间x轴和数值y轴分开，并且把y轴的数据处理一下
     * y轴的数据：根据selected字段判断是否为本次异常点，给本次异常点添加样式异常红点特别标注出来
     */
    let yData = [],
        xTime = [];
    detailsData.except &&
        detailsData.except.forEach((item, index) => {
            xTime.push(item.time);
            if (index === detailsData.except.indexOf(detailsData.except.filter(d => d.selected === true)[0])) {
                let value = {
                    value: peakvalue(item.amount),
                    itemStyle: { color: '#f40' },
                    emphasis: {
                        itemStyle: {
                            color: '#f40',
                        },
                    },
                    symbol: 'circle',
                    symbolSize: 12,
                };
                yData.splice(index, 0, value);
            } else {
                yData.push(peakvalue(item.amount));
            }
        });
    return (
        <div style={{ width: '100%', height: '100%' }}>
            <Row style={{ marginBottom: '1rem' }}>
                {/* <Col span={24} className="common_title">
                    {detailsData.name}
                </Col> */}
                <Col span={24} style={{ background: '#fff', borderRadius: '10px' }}>
                    <div className="common_title">
                        <MyIcon
                            type="icon_title_info"
                            style={{
                                fontSize: '1.5rem',
                                marginTop: '-0.1rem',
                                verticalAlign: 'middle',
                                paddingRight: '0.4rem',
                                height: 50,
                                lineHeight: '60px',
                            }}
                        />
                        <span style={{ verticalAlign: 'middle' }}>基础信息</span>
                    </div>
                    <Row style={{ padding: '1rem' }}>
                        <Col span={8}>
                            <span style={{ ...Styles.detailsName }}>设备名称：</span>
                            <p style={{ ...Styles.detailsValue }}>{detailsData && (detailsData.device_name || '—')}</p>
                        </Col>
                        <Col span={8}>
                            <span style={{ ...Styles.detailsName }}>设备型号：</span>
                            <p style={{ ...Styles.detailsValue }}>
                                {detailsData && (detailsData.category_name || '—')}
                            </p>
                        </Col>
                        <Col span={8}>
                            <span style={{ ...Styles.detailsName }}>隐患等级：</span>
                            <p style={{ ...Styles.detailsValue }}>
                                {detailsData && (alarmLevel[detailsData.grade] || '—')}
                            </p>
                        </Col>
                        <Col span={8}>
                            <span style={{ ...Styles.detailsName }}>发生时间：</span>
                            <p style={{ ...Styles.detailsValue }}>
                                {detailsData.time &&
                                    (detailsData.time.slice(0, 10) + ' ' + detailsData.time.slice(11, 19) || '—')}
                            </p>
                        </Col>
                        <Col span={8}>
                            <span style={{ ...Styles.detailsName }}>区域位置：</span>
                            <p style={{ ...Styles.detailsValue }}>{detailsData && (detailsData.location || '—')}</p>
                        </Col>
                        <Col span={8}>
                            <span style={{ ...Styles.detailsName }}>隐患内容：</span>
                            <p style={{ ...Styles.detailsValue }}>{detailsData && (detailsData.event || '—')}</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {eventShow && <Row style={{ background: '#fff', borderRadius: '10px', marginBottom: '1rem' }}>
            <Col span={24}>
                    <div className="common_title">
                        <MyIcon
                            type="icon_title_alarm"
                            style={{
                                fontSize: '1.5rem',
                                marginTop: '-0.1rem',
                                verticalAlign: 'middle',
                                paddingRight: '0.4rem',
                                height: 50,
                                lineHeight: '60px',
                            }}
                        />
                        <span style={{ verticalAlign: 'middle' }}>本次异常</span>
                        <span style={{ ...Styles.mark }}>
                            {detailsData.status && status[detailsData.status] === '离线' ? '(离线前采集数据)' : ''}
                        </span>
                    </div>
                </Col>
                <Col span={24} style={{ height: '26vh', padding: '1rem' }}>
                    {/* <Loading show={isLoading}> */}
                    {isEmpty(detailsData.except) ? (
                        <EmptyView />
                    ) : (
                        <ChartLines
                            yData={yData}
                            xTime={xTime}
                            names={hiddenDanger[detailsData.event_name]}
                            style={{ height: '93%' }}
                            top={'-10px'}
                        />
                    )}
                    {/* </Loading> */}
                </Col>
            </Row>}
            <Row style={{ background: '#fff', marginBottom: '1rem', borderRadius: '10px' }}>
                <Col span={24}>
                    <div className="common_title">
                        <MyIcon
                            type="icon_title_processing_progress"
                            style={{
                                fontSize: '1.5rem',
                                marginTop: '-0.1rem',
                                verticalAlign: 'middle',
                                paddingRight: '0.4rem',
                                height: 50,
                                lineHeight: '60px',
                            }}
                        />
                        <span style={{ verticalAlign: 'middle' }}>处理进度</span>
                    </div>
                </Col>
                <Col span={24} style={{ padding: '2rem 5rem' }}>
                    <Steps current={detailsData && detailsData.handler_status}>
                        {convert.map(item => {
                            return <Step title={item.value} key={item.id} />;
                        })}
                    </Steps>
                </Col>
            </Row>
            {work_orderShow === true && <Row style={{ background: '#fff', marginBottom: '1rem', borderRadius: '10px' }}>
                <Col span={24}>
                    <div className="common_title">
                        <MyIcon
                            type="icon_title_manage_details"
                            style={{
                                fontSize: '1.5rem',
                                marginTop: '-0.1rem',
                                verticalAlign: 'middle',
                                paddingRight: '0.4rem',
                                height: 50,
                                lineHeight: '60px',
                            }}
                        />
                        <span style={{ verticalAlign: 'middle' }}>处理详情</span>
                    </div>
                </Col>
                <Col span={24} style={{ padding: '1rem' }}>
                    <Row>
                        <Col span={8}>
                            <span style={{ ...Styles.detailsName }}>处理人员：</span>
                            <p style={{ ...Styles.detailsValue }}>{detailsData && (detailsData.handler_name || '—')}</p>
                        </Col>
                        <Col span={8}>
                            <span style={{ ...Styles.detailsName }}>工单：</span>
                            <p style={{ ...Styles.detailsValue }}>{detailsData && (detailsData.work_order || '—')}</p>
                        </Col>
                        <Col span={8}>
                            <span style={{ ...Styles.detailsName }}>处理时间：</span>
                            <p style={{ ...Styles.detailsValue }}>
                                {detailsData.handler_time &&
                                    (detailsData.handler_time.slice(0, 10) +
                                        ' ' +
                                        detailsData.handler_time.slice(11, 19) ||
                                        '—')}
                            </p>
                        </Col>
                        <Col span={8}>
                            <span style={{ ...Styles.detailsName }}>处理说明：</span>
                            <p style={{ ...Styles.detailsValue }}>
                                {detailsData && (detailsData.handler_content || '—')}
                            </p>
                        </Col>
                        <Col span={8}>
                            <span style={{ ...Styles.detailsName, verticalAlign: 'top' }}>附件：</span>
                            <div style={{ ...Styles.detailsValue }}>
                                {detailsData &&
                                    (isEmpty(detailsData.image) ? (
                                        <p>{'—'}</p>
                                    ) : (
                                        detailsData.image.map(item => {
                                            return (
                                                <p style={{ margin: '0 0.5rem', display: 'inline-block' }} key={item}>
                                                    <Image
                                                        src={item}
                                                        style={{ width: '100px', height: '100px' }}
                                                    />
                                                </p>
                                            );
                                        })
                                    ))}
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>}
        </div>
    );
};

const Styles = {
    detailsName: {
        marginBottom: '0.3rem',
        fontSize: '0.93rem',
        lineHeight: '35px',
        color: '#666666',
    },
    detailsValue: {
        marginLeft: '1rem',
        fontSize: '1rem',
        lineHeight: '35px',
        color: '#595959',
        display: 'inline-block',
    },
    detailsTitle: {
        color: '#333',
        fontSize: '1.1rem',
        lineHeight: '35px',
        marginBottom: '0.2rem',
    },
    mark: {
        fontSize: '0.7rem',
        color: '#F8BF73',
        marginLeft: '0.5rem',
    },
};
