/**
 * 中台
 */
import AccountManagement from '../views/admin/AccountNumber/AdminAccount';
import AdminRoleManagement from '../views/admin/Role/RoleManagement';
import Device from '../views/admin/Hardware/HardwareDevice';
import Classify from '../views/admin/Hardware/HardwareClassify';
import Firmware from '../views/admin/Hardware/HardwareFirmware';
import DeviceFailure from '../views/admin/Hardware/DeviceFailure';
import BannerList from '../views/admin/Apps/Banner';
import Feedback from '../views/admin/Apps/Feedback';
import { ReadFeedback } from '../views/admin/Apps/ReadFeedback';
import ChangePassword from '../views/admin/ChangePassword';

/**
 * web端
 */
import Workbench from '../views/web/Workbench';
import { GeneralView } from '../views/web/EnterpriseOverview/GeneralView'; //主页
import { Camera } from '../views/web/CameraKanban/Camera'; //摄像头

import { HiddenRecord } from '../views/web/HiddenDanger/HiddenRecord'; //隐患管理
import { HiddenAnalysis } from '../views/web/HiddenDanger/HiddenAnalysis';
import { EditHiddenAlarm } from '../views/web/HiddenDanger/EditHiddenAlarm';
import { ReadHiddenAlarm } from '../views/web/HiddenDanger/ReadHiddenAlarm';

import { OnlinePatrol } from '../views/web/Patrol/OnlinePatrol'; // 巡检管理
import { PatrolTask } from '../views/web/Patrol/PatrolTask';
import { PatrolRecord } from '../views/web/Patrol/PatrolRecords';
import { ReadRecords } from '../views/web/Patrol/ReadRecords';
import { ReadTasks } from '../views/web/Patrol/ReadTasks';

import AccountManagement2 from '../views/web/AccountNumber/AccountManagement'; //账号管理

import SystemSetting from '../views/web/SystemSetting'; //系统设置
import Personal from '../views/web/SystemSetting/personal';
import { Relation } from '../views/web/SystemSetting/Relation'; //关联设置
// import { TimeSwitch } from '../views/web/SystemSetting/TimeSwitchs';
import { TimeSwitchV1 } from '../views/web/SystemSetting/TimeSwitchV1';
import ChangePassword2 from '../views/web/SystemSetting/changePassword';
import GufengElectricity from '../views/web/SystemSetting/GufengElectricity';
import HiddenDanger from '../views/web/SystemSetting/HiddenDanger'; //隐患设置
import { ActionLog } from '../views/web/SystemSetting/ActionLog'; //操作日志

import OperationLog2 from '../views/OperationLog'; //操作日志

import { MonitorManagement } from '../views/web/MonitorManagement/MonitorManagement'; //监测管理
import { EquipmentManagement } from '../views/web/MonitorManagement/EquipmentManagement';
import { ClassificationManagement } from '../views/web/MonitorManagement/ClassificationManagement';
import { ReadDevice } from '../views/web/MonitorManagement/ReadDevice';
//能耗分析
import { ECRCondition } from '../views/web/EnergyConsumptionChart/ECRCondition'; //能耗概况
import { ECRStatistics } from '../views/web/EnergyConsumptionChart/ECRStatistics'; //能耗统计
import { ECRRanking } from '../views/web/EnergyConsumptionChart/ECRRanking'; //能耗排行
import { ECRReport } from '../views/web/EnergyConsumptionChart/ECRReport'; //能耗报表
import { ComparativeAnalysis } from '../views/web/EnergyConsumptionChart/ComparativeAnalysis'; //对比分析
import { PeakVallePower } from '../views/web/EnergyConsumptionChart/PeakVallePower'; //对比分析

import { HealthReport } from '../views/web/Report/HealthReport'; //健康报表
import { BigScreen } from '../views/BigScreen'; //监测大厅
import { Insurance } from '../views/web/Kanban/Insurance'; //智慧监测中心
import { InsuranceChildren } from '../views/web/Kanban/InsuranceChildren'; //标的物监测

export const webRoute = {
    '/web': { component: Workbench, icon: 'icon_workBench' },

    '/web/homePage': { component: Workbench },

    '/web/generalView': { component: GeneralView /*icon: 'icongongzuotai'*/ },

    '/web/bigScreen/screen': { component: BigScreen, hidden: true, hiddenHeader: true },

    '/web/bigScreen/insurance': { component: Insurance, hidden: true, hiddenHeader: true },

    '/web/bigScreen/insuranceChildren': { component: InsuranceChildren, hidden: true, hiddenHeader: true },

    '/web/photoSlider': { component: Camera },

    'https://projects.finsiot.com/': { component: null, third: true },

    '/web/monitor/point': { component: MonitorManagement },

    '/web/monitor/device': { component: EquipmentManagement },

    '/web/monitor/device2/readDevice': { component: ReadDevice },

    '/web/monitor/class': { component: ClassificationManagement },

    '/web/ecr/condition': { component: ECRCondition },

    '/web/ecr/statistics': { component: ECRStatistics },

    '/web/ecr/ranking': { component: ECRRanking },

    '/web/ecr/report': { component: ECRReport },

    '/web/ecr/comparativeAnalysis': { component: ComparativeAnalysis },

    '/web/ecr/peakVallePower': { component: PeakVallePower },

    '/web/systemSetting/inform': { component: SystemSetting },

    '/web/systemSetting/hiddenDanger': { component: HiddenDanger },

    '/web/systemSetting/gufengElectricity': { component: GufengElectricity },

    '/web/systemSetting/timeSwitch': { component: TimeSwitchV1 },

    '/web/systemSetting/personal': { component: Personal },

    '/web/systemSetting/relation': { component: Relation },

    '/web/systemSetting/changePassword': { component: ChangePassword2 },

    '/web/systemSetting/actionLog': { component: ActionLog },

    '/web/hiddenDanger/list': { component: HiddenRecord },

    '/web/hiddenDanger/hiddenAnalysis': { component: HiddenAnalysis },

    '/web/hiddenDanger/editHiddenAlarm': { component: EditHiddenAlarm },

    '/web/hiddenDanger/list2/readHiddenAlarm': { component: ReadHiddenAlarm },

    '/web/patrol/online': { component: OnlinePatrol },

    '/web/patrol/patrolRecord': { component: PatrolRecord },

    '/web/patrol/patrolRecord2/readRecords': { component: ReadRecords },

    '/web/patrol/patrolTask': { component: PatrolTask },

    '/web/patrol/patrolTask2/readTasks': { component: ReadTasks },

    '/web/accountManagement': { component: AccountManagement2, icon: 'icon_account_management' },

    // '/web/operationLog': { component: OperationLog2 },日志
    '/web/report/healthReport': { component: HealthReport },

    //一级菜单
    '/web/bigScreen': { redirect: '/web/bigScreen/screen', icon: 'icon_kanBan' },
    '/web/monitor': { redirect: '/web/monitor/point', icon: 'icon_monitor' },
    '/web/ecr': { redirect: '/web/ecr/condition', icon: 'icon_analysis' },
    '/web/hiddenDanger': { redirect: '/web/hiddenDanger/list', icon: 'icon_hiddenDanger' },
    '/web/patrol': { redirect: '/web/patrol/online', icon: 'icon_patrol' },
    '/web/report': { redirect: '/web/report/statiDay', icon: 'icon_report' },
    '/web/systemSetting': { redirect: '/web/systemSetting/inform', icon: 'icon_setting' },
};
export const requestWebRoute = [
    {
        client_type: 2,
        name: '工作台',
        path: '/web',
        icon: 'icon_workBench',
        children: [
            { client_type: 2, path: '/web/homePage', name: '智慧微电网' },
            {
                client_type: 2,
                path: '/web/photoSlider',
                name: '图像监控',
                cache_route: true,
                apply_route: ['/web/monitor/device2/readDevice'],
            },
            { client_type: 2, path: '/web/generalView', name: 'CT环保' },
        ],
    },
    // { client_type: 2, name: '主页', path: '/web/generalView', icon: 'icongongzuotai' },
    {
        client_type: 2,
        name: '监测大厅',
        path: '/web/bigScreen',
        icon: 'icon_kanBan',
        children: [
            { client_type: 2, path: '/web/bigScreen/screen', name: '数据大屏' },
            { client_type: 2, path: 'https://projects.finsiot.com/', name: '实时监控' },
            { client_type: 2, path: '/web/bigScreen/insurance', name: '智慧监测中心' },
            { client_type: 2, path: '/web/bigScreen/insuranceChildren', name: '标的物监测' },
        ],
    },
    {
        client_type: 2,
        name: '监测管理',
        path: '/web/monitor',
        icon: 'icon_monitor',
        children: [
            {
                client_type: 2,
                name: '设备管理',
                path: '/web/monitor/device',
                cache_route: true,
                apply_route: ['/web/monitor/device2/readDevice'],
            },
            { client_type: 2, name: '功能管理', path: '/web/monitor/class' },
            { client_type: 2, name: '查看设备', path: '/web/monitor/device2/readDevice', hidden: true },
        ],
    },
    {
        client_type: 2,
        name: '能耗分析',
        path: '/web/ecr',
        icon: 'icon_analysis',
        children: [
            { client_type: 2, name: '能耗概况', path: '/web/ecr/condition' },
            { client_type: 2, name: '能耗统计', path: '/web/ecr/statistics' },
            { client_type: 2, name: '能耗排行', path: '/web/ecr/ranking' },
            { client_type: 2, name: '对比分析', path: '/web/ecr/comparativeAnalysis' },
            { client_type: 2, name: '峰谷用电', path: '/web/ecr/peakVallePower' },
            { client_type: 2, name: '能耗报表', path: '/web/ecr/report' },
        ],
    },
    {
        client_type: 2,
        name: '隐患管理',
        path: '/web/hiddenDanger',
        icon: 'icon_hiddenDanger',
        children: [
            {
                client_type: 2,
                name: '隐患列表',
                path: '/web/hiddenDanger/list',
                cache_route: true,
                apply_route: ['/web/hiddenDanger/list2/readHiddenAlarm'],
            },
            {
                client_type: 2,
                name: '隐患分析',
                path: '/web/hiddenDanger/hiddenAnalysis',
            },
            {
                client_type: 2,
                name: '隐患编辑',
                path: '/web/hiddenDanger/editHiddenAlarm',
                hidden: true,
            },
            {
                client_type: 2,
                name: '查看隐患',
                path: '/web/hiddenDanger/list2/readHiddenAlarm',
                hidden: true,
            },
        ],
    },
    {
        client_type: 2,
        name: '巡检管理',
        path: '/web/patrol',
        icon: 'icon_patrol',
        children: [
            { client_type: 2, name: '在线巡检', path: '/web/patrol/online' },
            {
                client_type: 2,
                name: '巡检计划',
                path: '/web/patrol/patrolRecord',
                cache_route: true,
                apply_route: ['/web/patrol/patrolRecord2/readRecords', '/web/patrol/patrolTask2/readTasks'],
            },
            {
                client_type: 2,
                name: '巡检任务',
                path: '/web/patrol/patrolTask',
                cache_route: true,
                apply_route: ['/web/patrol/patrolRecord2/readRecords', '/web/patrol/patrolTask2/readTasks'],
            },
            { client_type: 2, name: '查看计划', path: '/web/patrol/patrolRecord2/readRecords', hidden: true },
            { client_type: 2, name: '查看任务', path: '/web/patrol/patrolTask2/readTasks', hidden: true },
        ],
    },
    {
        client_type: 2,
        name: '健康报表',
        path: '/web/report/healthReport',
        icon: 'icon_report',
        // children: [
        //     {
        //         path: '/web/report/healthReport',
        //         client_type: 2,
        //         name: '月报详情',
        //         hidden: true,
        //     },
        //     {
        //         path: '/web/report/statiDay',
        //         client_type: 2,
        //         name: '健康月报',
        //     },
        // ],
    },
    {
        client_type: 2,
        name: '账号管理',
        path: '/web/accountManagement',
        icon: 'icon_account_management',
    },
    {
        client_type: 2,
        name: '系统设置',
        path: '/web/systemSetting',
        icon: 'icon_setting',
        children: [
            { client_type: 2, name: '操作日志', path: '/web/systemSetting/actionLog' },
            { client_type: 2, name: '关联设置', path: '/web/systemSetting/relation' },
            { client_type: 2, name: '告警通知设置', path: '/web/systemSetting/inform' },
            {
                client_type: 2,
                name: '隐患设置',
                path: '/web/systemSetting/hiddenDanger',
            },
            {
                client_type: 2,
                name: '谷峰电费设置',
                path: '/web/systemSetting/gufengElectricity',
            },
            {
                client_type: 2,
                name: '定时开关',
                path: '/web/systemSetting/timeSwitch',
            },
            {
                client_type: 2,
                name: '个人中心',
                path: '/web/systemSetting/personal',
            },
            {
                client_type: 2,
                name: '修改密码',
                path: '/web/systemSetting/changePassword',
            },
        ],
    },
    // { path: '/web/operationLog', name: '操作日志', icon: 'icon_log' },
];
export const baseRouter = {
    '/admin': { component: AccountManagement, icon: 'icon_account_management' },

    '/admin/roleManagement': { component: AdminRoleManagement, icon: 'icon_role' },

    '/admin/hardware/device': { component: Device },

    '/admin/hardware/classify': { component: Classify },

    '/admin/hardware/firmware': { component: Firmware },

    '/admin/hardware/deviceFailure': { component: DeviceFailure }, //设备故障

    '/admin/appManagement/banner': { component: BannerList },

    '/admin/appManagement/feedback': { component: Feedback },

    '/admin/appManagement/feedback2/readFeedback': { component: ReadFeedback },

    '/admin/changePassword': { component: ChangePassword, icon: 'icon_change_password' },

    '/admin/operationLog': { component: OperationLog2, icon: 'icon_log' },

    //一级菜单
    '/admin/hardware': { redirect: '/admin/hardware/classify', icon: 'icon_hardware' },
    '/admin/appManagement': { redirect: '/admin/appManagement/banner', icon: 'icon_app' },
};
export const requestRoute = [
    { client_type: 1, name: '用户管理', path: '/admin', icon: 'icon_account_management' },
    { client_type: 1, name: '角色管理', path: '/admin/roleManagement', icon: 'icon_role' },
    {
        client_type: 1,
        name: '硬件管理',
        path: '/admin/hardware',
        icon: 'icon_hardware',
        children: [
            { client_type: 1, name: '分类管理', path: '/admin/hardware/classify' },
            {
                client_type: 1,
                name: '产品管理',
                path: '/admin/hardware/device',
            },
            {
                client_type: 1,
                name: '固件管理',
                path: '/admin/hardware/firmware',
            },
            {
                client_type: 1,
                name: '设备故障',
                path: '/admin/hardware/deviceFailure',
            },
        ],
    },
    {
        client_type: 1,
        name: 'APP管理',
        path: '/admin/appManagement',
        icon: 'icon_app',
        children: [
            { client_type: 1, name: 'banner图管理', path: '/admin/appManagement/banner' },
            {
                client_type: 1,
                name: '意见反馈',
                path: '/admin/appManagement/feedback',
                cache_route: true,
                apply_route: ['/admin/appManagement/feedback2/readFeedback'],
            },
            {
                client_type: 1,
                name: '查看反馈',
                path: '/admin/appManagement/feedback2/readFeedback',
                hidden: true,
            },
        ],
    },
    { client_type: 1, name: '操作日志', path: '/admin/operationLog', icon: 'icon_log' },
    { client_type: 1, name: '修改密码', path: '/admin/changePassword', icon: 'icon_change_password' },
];
