import React, { useState, useMemo, useEffect } from 'react';
import { Tree, Input } from 'antd';
import { map, get, isEmpty, omit } from 'lodash';
import treeStyle from './commonTree.module.less';
import { searchArea2 } from '../api/webServe';
import { Loading } from './Loading';
import { EmptyView } from './EmptyView';
import { MyIcon } from '../util/iconfont';
import { treeIcon } from '../global';
const { Search } = Input;
// const getParentKey = (key, tree) => {
//     let parentKey;
//     for (let i = 0; i < tree.length; i++) {
//         const node = tree[i];
//         if (node.children) {
//             if (node.children.some(item => item.key === key)) {
//                 parentKey = node.key;
//             } else if (getParentKey(key, node.children)) {
//                 parentKey = getParentKey(key, node.children);
//             }
//         }
//     }
//     return parentKey;
// };
/**
 * 包含区域和设备的组件（主要用于隐患分析和隐患列表）
 * × 不可多选
 * × 不可默认渲染第一条数据
 *
 * @param {*} treeData   区域树的数据
 * @param {*} onClick    点击事件触发
 * @param {*} operable   编辑节点
 * @param {*} add        新增节点
 * @param {*} edit       编辑节点
 * @param {*} remove     删除节点
 * @param {*} selectNum  节点title旁边的数量
 * @param {*} getAllTree
 * @returns
 */
export const CommonTree = ({ treeData = [], onClick, operable = true, add, edit, remove, getNode }) => {
    const [searchValue, setSearchValue] = useState('');
    const [searchResult, setSearchResult] = useState({ isLoading: true, data: null });
    const { dataList, parentRegionIds } = useMemo(() => {
        const nodeList = [];
        const generateList = data => {
            return map(data, item => {
                const { id, name } = item;
                nodeList.push({ id, name });
                return { ...item, key: `${id}`, title: name, children: generateList(item.children) };
            });
        };
        const dataList = generateList(treeData);
        const parentRegionIds = get(dataList, '[0].id');
        getNode && getNode(nodeList);
        // onClick && onClick(dataList[0]);
        return { dataList, parentRegionIds };
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [treeData]);
    const onDrop = (event, node, dragNode, dragNodesKeys) => {
        // console.log(event);
        // console.log(node);
        // console.log(dragNode);
        // console.log(dragNodesKeys, 'dragNodesKeys');
    };
    //点击树节点触发
    const onSelect = (selectedKeys, info) => {
        onClick && onClick(info.node);
    };
    const [expandedKeys, setExpandedKeys] = useState([`${parentRegionIds}`]);
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    useEffect(() => {
        setExpandedKeys([`${parentRegionIds}`]);
    }, [parentRegionIds]);
    //展开的回调
    const onExpand = expandedKeysValue => {
        //展开/收起节点时触发
        //展开的状态
        setExpandedKeys(expandedKeysValue);
        setAutoExpandParent(false);
    };
    return (
        dataList.length > 0 ? <div className={treeStyle.container}>
            <Search
                style={{ marginBottom: 8 }}
                placeholder="请输入"
                allowClear
                onSearch={value => {
                    setSearchValue(`${value}`);
                    setSearchResult({ ...searchResult, isLoading: true });
                    searchArea2(value)
                        .then(result => {
                            // console.log(result,'result')
                            result = map(result.data, item => {
                                let path = '';
                                map(get(item, 'routes', []), area => {
                                    path += `${area.name}>`;
                                });
                                path = path.substr(0, path.length - 1);
                                return { ...omit(item, ['routes']), path };
                            });
                            setSearchResult({ data: result, isLoading: false });
                        })
                        .catch(() => {
                            setSearchResult({ data: null, isLoading: false });
                        });
                }}
            />
            {searchValue ? (
                <Loading show={searchResult.isLoading}>
                    {isEmpty(searchResult.data) ? (
                        <EmptyView />
                    ) : (
                        <SearchTemplate data={searchResult.data} onClick={onClick} />
                    )}
                </Loading>
            ) : (
                <Tree
                    onExpand={onExpand} //展开事件
                    onDrop={onDrop}
                    onSelect={onSelect}
                    expandedKeys={expandedKeys} //默认展开的key
                    autoExpandParent={autoExpandParent} //是否自动展开父节点
                    treeData={dataList}
                    selectable={['11']}
                    titleRender={operable ? node => <TreeTitle node={node} /> : null}
                />
            )}
        </div> : <div style={{height: '76vh'}}><EmptyView /></div> 
    );
};
const SearchTemplate = ({ data, onClick }) => {
    return (
        <div style={{ padding: '0 10px 10px 10px' }}>
            {map(data, item => {
                return (
                    <div
                        onClick={() => {
                            onClick(item);
                        }}
                        key={item.key}
                        style={{ padding: '10px 0', cursor: 'pointer' }}
                    >
                        {item.path}
                    </div>
                );
            })}
        </div>
    );
};
const TreeTitle = ({ node }) => {
    if (node.level === 1) {
        return <span>{node.title}</span>;
    }
    return (
        <div
            className={treeStyle.title}
            style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
            <div>
                {node.dev_cate ? (
                    <MyIcon type={treeIcon[node.dev_cate]} style={{ fontSize: '1.5rem', verticalAlign: 'middle' }} />
                ) : (
                    <MyIcon type="icon_address" />
                )}
                <span style={{ verticalAlign: 'middle', whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: '95px', display: 'inline-block', overflow: 'hidden', }}>{' ' + node.title} </span>
                {/* <span style={{ verticalAlign: 'middle' }}><i style={{fontStyle: 'normal', display: 'inline-block', width: '70%', overflow: 'hidden', verticalAlign: 'middle',textOverflow: 'ellipsis', whiteSpace: 'nowrap', }}>{' ' + node.title}</i></span> */}
                <span style={{ verticalAlign: 'middle' }}>{node.total !== undefined ? '(' + node.total + ')' : ''}</span>
            </div>
        </div>
    );
};
