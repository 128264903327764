import React, {useEffect, useState} from 'react';
import {Carousel, Col, Row, Select} from 'antd';
import styles from './Insurance.module.less';
import moment from 'moment';
import {map, isEmpty, ceil, isNull} from 'lodash';
import {LineChart3} from '../../../components/BarChart';
import {Page} from '../../../components/Pagination';
import RouteHelper from '../../../tools/RouteHelper';
import {RankingChart, Pie3D3} from './Chart/HorizontalBarChart';
import {Loading} from '../../../components/Loading';
import NoImg from '../../../assets/images/noImg.png';
import icon from '../../../assets/images/insurance_icon.png';
import titleIcon from '../../../assets/images/title_icon.png';
import Icon1 from '../../../assets/images/jinrigaojing_bg.png';
import Icon2 from '../../../assets/images/shebeizongliang_bg.png';
import Icon3 from '../../../assets/images/zaixianshebei_bg.png';
import Icon4 from '../../../assets/images/pingjunwendu.png';
import Icon5 from '../../../assets/images/zuigaowendu.png';
import Icon6 from '../../../assets/images/zuidiwendu.png';
import Camera from '../../../assets/images/shexiangtou.png';
import NewestAlarmPlaceHolder from '../../../assets/images/newest_alarm_placeholder.png';
import AlarmRankPlaceHolder from '../../../assets/images/alarm_rank_placeholder.png';
import CameraWholeDataPlaceHolder from '../../../assets/images/camera_whole_data_placeholder.png';
import {Slider} from '../CameraKanban/Slider';
import FullScrenn from '../../../util/fullScreen';
import {
    getInsuranceChildrenNavigation,
    getInsuranceChildrenRunStatus,
    getInsuranceChildrenTemperature,
    getInsuranceChildrenCamera,
    getInsuranceChildrenAlarmStatistics,
    getInsuranceChildrenAlarmRank,
    getInsuranceChildrenAlarmTrend,
    getInsuranceChildrenAlarmInfo,
} from '../../../api/webServe';
import {EmptyView} from '../../../components/EmptyView'; //空数据占位组件
import {MyIcon} from '../../../util/iconfont'; //阿里图标组件
import {removeEmpty} from '../../../util/function-util'
import {alarmLevel} from '../../../global'; //颜色集

const {Option} = Select;

const emptyTest = desc => {
    return <div style={{color: '#ffffff'}}>{desc || '暂无数据'}</div>;
};

const Timer = () => {
    const [time, setTime] = useState(moment(new Date()).format('llll:ss'));
    useEffect(() => {
        //当前时间日期
        const interval = setInterval(() => {
            setTime(moment(new Date()).format('llll:ss'));
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, []);
    return (
        <div className={styles.right}>
            {time}
            {/*<span style={{ marginLeft: '20px', fontWeight: 'bold', fontSize: '20px' }}>10:00:00</span>*/}
        </div>
    );
};

export const InsuranceChildren = () => {
    const [navigation, setNavigation] = useState({data: null, isLoading: true});
    const [region_id, setRegionId] = useState(
        window.location.search.slice(window.location.search.lastIndexOf('?') + 1).slice(3),
    );
    const [statusData, setRunStatusData] = useState({data: null, isLoading: true, desc: null});
    const [alarmStatistics, setAlarmStatistics] = useState({data: null, isLoading: true, desc: null});
    const [alarmInfo, setAlarmInfo] = useState({data: null, isLoading: true, desc: null});
    const [alarmRank, setAlarmRank] = useState({data: null, isLoading: true, desc: null});
    const [temperature, setTemperature] = useState({data: null, isLoading: true, desc: null});
    const [alarmTrend, setAlarmTrend] = useState({data: null, isLoading: true, desc: null});
    const [cameraData, setCameraData] = useState({data: null, isLoading: true, desc: null});
    const [total, setTotal] = useState(0); //数据总条数
    const [page, setPage] = useState(1); //当前页
    const [page_size, setPageSize] = useState(4); //当前页显示条数

    // let region_id = window.location.search.slice(window.location.search.lastIndexOf('?') + 1)
    /**
     * 监听浏览器是否全屏
     * */
    const [isScreenFull, setIsScreenFull] = useState(false); //是否全屏
    useEffect(() => {
        //初始化
        FullScrenn.init(screenChange);
    }, [isScreenFull]);
    //屏幕变化
    const screenChange = isFull => {
        // console.log('是否全屏', isFull);
        setIsScreenFull(isFull);
    };
    const [name, setName] = useState(null);
    //左上角的区域树
    const Navigation = () => {
        setNavigation({data: null, isLoading: true});
        getInsuranceChildrenNavigation()
            .then(result => {
                setNavigation({data: result.data, isLoading: false});
                const name = result.data.children.filter(item => {
                    return item.id == (window.location.search.slice(window.location.search.lastIndexOf('?') + 1).slice(3) ? window.location.search.slice(window.location.search.lastIndexOf('?') + 1).slice(3) : result.data.children[0].id);
                });
                setName(name[0].id);
                !region_id && setRegionId(result.data.children[0].id);
            })
            .catch(error => {
                setNavigation({data: null, isLoading: false, desc: error});
            });
    };
    //运行状态
    const RunStatus = () => {
        setRunStatusData({data: null, isLoading: true});
        getInsuranceChildrenRunStatus({
            region_id:
                region_id || (navigation.data && navigation.data.children.length > 0 && navigation.data.children[0].id),
        })
            .then(result => {
                setRunStatusData({data: result.data, isLoading: false});
            })
            .catch(error => {
                setRunStatusData({data: null, isLoading: false, desc: error});
            });
    };
    //设备统计
    const Temperature = () => {
        setTemperature({data: null, isLoading: true});
        getInsuranceChildrenTemperature({
            region_id:
                region_id || (navigation.data && navigation.data.children.length > 0 && navigation.data.children[0].id),
        })
            .then(result => {
                setTemperature({data: result.data, isLoading: false});
            })
            .catch(error => {
                setTemperature({data: null, isLoading: true, desc: error});
            });
    };
    //告警统计
    const AlarmStatistics = () => {
        setAlarmStatistics({data: null, isLoading: true});
        getInsuranceChildrenAlarmStatistics({
            region_id:
                region_id || (navigation.data && navigation.data.children.length > 0 && navigation.data.children[0].id),
        })
            .then(result => {
                setAlarmStatistics({data: result.data, isLoading: false});
            })
            .catch(error => {
                setAlarmStatistics({data: null, isLoading: false, desc: error});
            });
    };
    //告警排行
    const AlarmRank = () => {
        setAlarmRank({data: null, isLoading: true});
        getInsuranceChildrenAlarmRank({
            region_id:
                region_id || (navigation.data && navigation.data.children.length > 0 && navigation.data.children[0].id),
        })
            .then(result => {
                setAlarmRank({data: result.data, isLoading: false});
            })
            .catch(error => {
                setAlarmRank({data: null, isLoading: false, desc: error});
            });
    };
    //告警趋势
    const getAlarmTrend = () => {
        setAlarmTrend({data: null, isLoading: true});
        getInsuranceChildrenAlarmTrend({
            region_id:
                region_id || (navigation.data && navigation.data.children.length > 0 && navigation.data.children[0].id),
        })
            .then(result => {
                setAlarmTrend({data: result.data, isLoading: false});
            })
            .catch(error => {
                setAlarmTrend({data: null, isLoading: false, desc: error});
            });
    };
    //告警信息
    const getAlarmInfo = () => {
        setAlarmInfo({data: null, isLoading: true});
        getInsuranceChildrenAlarmInfo({
            region_id:
                region_id || (navigation.data && navigation.data.children.length > 0 && navigation.data.children[0].id),
        })
            .then(result => {
                setAlarmInfo({data: result.data, isLoading: false});
            })
            .catch(error => {
                setAlarmInfo({data: null, isLoading: false, desc: error});
            });
    };
    //摄像头
    const getCamera = () => {
        setCameraData({data: null, isLoading: true});
        getInsuranceChildrenCamera({
            region_id:
                region_id || (navigation.data && navigation.data.children.length > 0 && navigation.data.children[0].id),
            page: page,
            page_size: page_size,
        })
            .then(result => {
                let data = result.data;
                let count = result.data.length === 0 ? 0 : result.count;
                switch (count) {
                    // case 0:
                    //     data.push({id:1+Math.random(),img: NoImg},{id:2+Math.random(),img: NoImg},{id:3+Math.random(),img: NoImg},{id:3+Math.random(),img: NoImg});
                    // break;
                    case 1:
                        data.push(
                            // { id: 1 + Math.random(), img: NoImg },
                            // { id: 2 + Math.random(), img: NoImg },
                            // { id: 3 + Math.random(), img: NoImg },
                        );
                        break;
                    case 2:
                        data.push({id: 1 + Math.random(), img: NoImg}, {id: 2 + Math.random(), img: NoImg});
                        break;
                    case 3:
                        data.push({id: 1 + Math.random(), img: NoImg});
                        break;
                    default:
                        data.push();
                }
                setCameraData({data: data, isLoading: false});
                setTotal(result.count);
            })
            .catch(() => {
                setCameraData({data: null, isLoading: false});
            });
    };
    useEffect(() => {
        Navigation();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (region_id) {
            RunStatus();
            Temperature();
            AlarmStatistics();
            AlarmRank();
            getAlarmInfo();
            getAlarmTrend();
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [region_id]);
    useEffect(() => {
        if (region_id) {
            getCamera();
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [region_id, page, page_size]);

    return (
        <Row>
            <Col span={24} className={styles.bigScreenContainer}>
                <div className={styles.bigScreenHeader}>
                    <div className={styles.left}>
                        <MyIcon type="icon_location" style={{fontSize: '26px', marginRight: '5px'}}/>
                        <Select
                            value={name || (navigation.data && navigation.data.id)}
                            style={{width: 150}}
                            onSelect={(e, value) => {
                                if (e !== navigation.data.id) {
                                    RouteHelper.locationTo('/web/bigScreen/insuranceChildren', {id: e});
                                } else {
                                    RouteHelper.locationTo('/web/bigScreen/insurance');
                                }
                                setRegionId(e);
                                setName(e);
                            }}
                            dropdownClassName={styles.insuranceSelectStyle}
                            dropdownStyle={{
                                backgroundColor: 'rgba(0, 63, 91, 0.95)',
                            }}
                            virtual={false}
                        >
                            {navigation.data && navigation.data.master && <Option
                                value={navigation.data && navigation.data.id}
                                key={navigation.data && navigation.data.id}
                                title={navigation.data && navigation.data.name}
                                style={{fontSize: '16px'}}
                            >
                                {/* <MyIcon
                                    type={'iconaddress'}
                                    style={{
                                        fontSize: '1.2rem',
                                        marginRight: '0.1rem',
                                        color: 'rgba(255, 255, 255, 0.85)',
                                        verticalAlign: 'middle',
                                    }}
                                /> */}
                                <span style={{verticalAlign: 'middle'}}>
                                    {navigation.data && navigation.data.name}
                                </span>
                            </Option>}
                            {navigation.data &&
                            navigation.data.children.map(childrenItem => {
                                return (
                                    <Option
                                        value={childrenItem.id}
                                        key={childrenItem.id}
                                        title={childrenItem.name}
                                        style={{paddingLeft: '1rem'}}
                                    >
                                        {/* <MyIcon
                                                type={'iconaddress'}
                                                style={{
                                                    fontSize: '1.2rem',
                                                    marginRight: '0.1rem',
                                                    color: 'rgba(255, 255, 255, 0.85)',
                                                    verticalAlign: 'middle',
                                                }}
                                            /> */}
                                        <span style={{verticalAlign: 'middle'}}>{childrenItem.name}</span>
                                    </Option>
                                );
                            })}
                        </Select>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', width: '40vw'}}>
                        <div
                            className={styles.center}
                            style={{marginTop: '-0.6rem', position: 'relative', display: 'flex'}}
                        >
                            <img src={titleIcon} alt=""/>
                            <span style={{verticalAlign: 'middle', color: '#DBE5F8'}}>标的物监测中心</span>
                        </div>
                    </div>
                    <Timer/>
                </div>
                <div className={styles.bigScreenContent}>
                    <div className={styles.contentCenter}>
                        <div className={styles.left}>
                            <div className={styles.top}>
                                <div className={styles.title}>
                                    <div className={styles.titleName}>
                                        <img src={icon} alt=""/>
                                        运行状态
                                    </div>
                                </div>
                                <Loading show={statusData.isLoading} hiddenShade={true}>
                                    {isEmpty(statusData.data) ? (
                                        <EmptyView description={emptyTest(statusData.desc)}/>
                                    ) : (
                                        <div className={styles.content}>
                                            <div className={styles.leftCenter}>
                                                <div className={styles.leftCenterItem}>
                                                    <div
                                                        className={styles.text}
                                                        style={{backgroundImage: 'url(' + Icon1 + ')'}}
                                                    >
                                                        <div>{removeEmpty(ceil(statusData.data.alarm_count, 2))}</div>
                                                        <span>次</span>
                                                    </div>
                                                    <div className={styles.titleName}>
                                                        <p>今日告警</p>
                                                    </div>
                                                </div>

                                                <div className={styles.leftCenterItem}>
                                                    <div
                                                        className={styles.text}
                                                        style={{backgroundImage: 'url(' + Icon2 + ')'}}
                                                    >
                                                        <div>{removeEmpty(statusData.data.device_count)}</div>
                                                        <span>台</span>
                                                    </div>
                                                    <div className={styles.titleName}>
                                                        <p>设备总量</p>
                                                    </div>
                                                </div>
                                                <div className={styles.leftCenterItem}>
                                                    <div
                                                        className={styles.text}
                                                        style={{backgroundImage: 'url(' + Icon3 + ')'}}
                                                    >
                                                        <div>{removeEmpty(statusData.data.device_online)}</div>
                                                        <span>台</span>
                                                    </div>
                                                    <div className={styles.titleName}>
                                                        <p>在线设备</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Loading>
                            </div>
                            <div
                                className={styles.bottom}
                                style={{
                                    marginTop:
                                        '0.8rem' /*background: `url("${Pies}") 80px 22px no-repeat`, backgroundSize:'31%'*/,
                                }}
                            >
                                <div className={styles.title}>
                                    <div className={styles.titleName}>
                                        <img src={icon} alt=""/>
                                        温度
                                    </div>
                                </div>
                                <Loading show={temperature.isLoading} hiddenShade={true}>
                                    {isEmpty(temperature.data) || temperature.data.length === 0 ? (
                                        <EmptyView description={emptyTest(temperature.desc)}/>
                                    ) : (
                                        <div className={styles.content}>
                                            <div className={styles.leftCenterItem}>
                                                <div
                                                    className={styles.text}
                                                    style={{backgroundImage: 'url(' + Icon4 + ')'}}
                                                >
                                                    <MyIcon
                                                        type={'iconpingjunwenshidu'}
                                                        style={{color: '#000', fontSize: '2rem'}}
                                                    />
                                                </div>
                                                <div className={styles.titleName}>
                                                    <p>平均温度</p>
                                                    <div>
                                                        {temperature.data && !isNull(temperature.data.temperature.mean)
                                                            ? ceil(temperature.data.temperature.mean, 2)
                                                            : '-'}
                                                        <span>℃</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={styles.leftCenterItem}>
                                                <div
                                                    className={styles.text}
                                                    style={{backgroundImage: 'url(' + Icon5 + ')'}}
                                                >
                                                    <MyIcon
                                                        type={'iconwenshidu_line'}
                                                        style={{color: '#000', fontSize: '2rem'}}
                                                    />
                                                </div>
                                                <div className={styles.titleName}>
                                                    <p>最高温度</p>
                                                    <div>
                                                        {temperature.data && !isNull(temperature.data.temperature.max)
                                                            ? ceil(temperature.data.temperature.max, 2)
                                                            : '-'}
                                                        <span>℃</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={styles.leftCenterItem}>
                                                <div
                                                    className={styles.text}
                                                    style={{backgroundImage: 'url(' + Icon6 + ')'}}
                                                >
                                                    <MyIcon
                                                        type={'iconzuidiwenshidu'}
                                                        style={{color: '#000', fontSize: '2rem'}}
                                                    />
                                                </div>
                                                <div className={styles.titleName}>
                                                    <p>最低温度</p>
                                                    <div>
                                                        {temperature.data && !isNull(temperature.data.temperature.min)
                                                            ? ceil(temperature.data.temperature.min, 2)
                                                            : '-'}
                                                        <span>℃</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Loading>
                            </div>
                        </div>
                        <div className={styles.center} style={{height: '98%', borderRadius: '10px'}}>
                            <Loading show={cameraData.isLoading}>
                                {isEmpty(cameraData.data) ? (
                                    <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                        <img src={CameraWholeDataPlaceHolder} alt="摄像头无数据" width="473px" height="358px" style={{outline:'none'}}/>
                                    </div>
                                ) : (
                                    <div style={{padding: '2rem 0 1rem 0'}} className="camera">
                                        <Slider
                                            count={total === 1 ? total : page_size}
                                            data={cameraData.data}
                                            click={true}
                                            width={'98%'}
                                            gutter={[10, 10]}
                                        />
                                        <div
                                            className="paginations"
                                            style={{
                                                left: '0%',
                                                width: '100%',
                                                bottom: '-16px',
                                                marginLeft: '0',
                                                marginBottom: '1rem',
                                            }}
                                        >
                                            <Page
                                                count={page_size}
                                                total={total}
                                                page_size={page_size}
                                                setPageSize={setPageSize}
                                                page={page}
                                                setPage={setPage}
                                            />
                                        </div>
                                    </div>
                                )}
                            </Loading>
                        </div>
                        <div className={styles.right}>
                            <div className={styles.top}>
                                <div className={styles.title}>
                                    <div className={styles.titleName}>
                                        <img src={icon} alt=""/>
                                        告警统计
                                        <span
                                        style={{
                                            fontSize: '12px',
                                            verticalAlign: 'bottom',
                                            marginTop: '0.5rem',
                                            marginLeft: '0.3rem',
                                        }}
                                    >
                                        (今日)
                                    </span>
                                    </div>
                                </div>
                                <div style={{width: '50%', height: '100%', float: 'left'}}>
                                    <Loading show={alarmStatistics.isLoading} hiddenShade={true}>
                                        {isEmpty(alarmStatistics.data) ||
                                        ((alarmStatistics.data && alarmStatistics.data.grade.length === 0) ? (
                                            <Pie3D3
                                                data={[{"grade": 2, "number": 1, "category_name": "故障"}, {
                                                    "grade": 2,
                                                    "number": 1,
                                                    "category_name": "高危"
                                                }, {"grade": 2, "number": 1, "category_name": "隐患"}]}
                                                color={['#EC6A1A', '#0077C1', '#FBC44C', '#2564B8', '#138280']}
                                                show={false}
                                                distance={400}
                                                useFakeData
                                                dealLittleLegend
                                            />
                                        ) : (
                                            <Pie3D3
                                                data={alarmStatistics.data && alarmStatistics.data.grade}
                                                color={['#EC6A1A', '#0077C1', '#FBC44C', '#2564B8', '#138280']}
                                                show={false}
                                                distance={400}
                                            />
                                        ))}
                                    </Loading>
                                </div>
                                <div style={{width: '50%', height: '100%', float: 'right'}}>
                                    <Loading show={alarmStatistics.isLoading} hiddenShade={true}>
                                        {isEmpty(alarmStatistics.data) ||
                                        ((alarmStatistics.data && alarmStatistics.data.category.length === 0) ? (
                                            <Pie3D3
                                                data={[{
                                                    "category_id": 2,
                                                    "number": 1,
                                                    "category_name": "网关"
                                                }, {
                                                    "category_id": 2,
                                                    "number": 1,
                                                    "category_name": "摄像头"
                                                }, {"category_id": 2, "number": 1, "category_name": "温湿度计"},
                                                    {
                                                        "category_id": 2,
                                                        "number": 1,
                                                        "category_name": "烟宝"
                                                    }, {
                                                        "category_id": 2,
                                                        "number": 1,
                                                        "category_name": "门磁"
                                                    }, {"category_id": 2, "number": 1, "category_name": "电流互感器"}]}
                                                color={['#EC6A1A', '#0077C1', '#FBC44C', '#2564B8', '#138280', 'red']}
                                                show={false}
                                                distance={400}
                                                useFakeData
                                            />
                                        ) : (
                                            <Pie3D3
                                                data={alarmStatistics.data && alarmStatistics.data.category}
                                                color={['#EC6A1A', '#F79A0D', '#97D6F6', '#2564B8', '#138280']}
                                                show={false}
                                                distance={400}
                                            />
                                        ))}
                                    </Loading>
                                </div>
                                {/* <Loading show={data7.isLoading} hiddenShade={true}>
                                    {isEmpty(data7.data) || data7.data.data.length === 0 ? (
                                        <EmptyView description={emptyTest()} />
                                    ) : (
                                        <Row>
                                            {data7.data.data &&
                                                data7.data.data.slice(0, 3).map(item => {
                                                    return (
                                                        <Col
                                                            span={8}
                                                            style={{ textAlign: 'center', marginTop: '2.5rem' }}
                                                        >
                                                            <AnnularEchart
                                                                seriesData={item}
                                                                style={{ width: '100%', height: '130px' }}
                                                                color={
                                                                    item.event === '高风险'
                                                                        ? ['#B92B0C', '#2F353B']
                                                                        : item.event === '中风险'
                                                                        ? ['#EEA019', '#2F353B']
                                                                        : item.event === '低风险'
                                                                        ? ['#709341', '#2F353B']
                                                                        : ['#0077C0', '#2F353B']
                                                                }
                                                                showValue={true}
                                                            />
                                                            <p>{item.event}</p>
                                                        </Col>
                                                    );
                                                })}
                                        </Row>
                                    )}
                                </Loading>
                            </div>
                            <div className={styles.bottom}>
                                <div className={styles.title}>
                                    <div className={styles.titleName}>
                                        <img src={icon} alt="" />
                                        高危区域
                                    </div>
                                </div>
                                <Loading show={data3.isLoading} hiddenShade={true}>
                                    {isEmpty(data3.data) ? (
                                        <EmptyView description={emptyTest()} />
                                    ) : (
                                        <HorizontalBarChart data={data3.data.slice(6, 9)} />
                                    )}
                                </Loading> */}
                            </div>
                            <div className={styles.bottom} style={{height: '47%', marginTop: '3%'}}>
                                <div className={styles.title}>
                                    <div className={styles.titleName}>
                                        <img src={icon} alt=""/>
                                        告警排行
                                        <span
                                        style={{
                                            fontSize: '12px',
                                            verticalAlign: 'bottom',
                                            marginTop: '0.5rem',
                                            marginLeft: '0.3rem',
                                        }}
                                    >
                                        (前5条)
                                    </span>
                                    </div>
                                </div>
                                <Loading show={alarmRank.isLoading} hiddenShade={true}>
                                    {isEmpty(alarmRank.data) &&
                                    (isNull(alarmRank.data) || alarmRank.data.length === 0) ? (
                                        <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                            <img src={AlarmRankPlaceHolder} alt="最新告警无数据" width="226px" height="126px" />
                                        </div>
                                    ) : (
                                        <RankingChart data={alarmRank.data}/>
                                    )}
                                </Loading>
                            </div>
                        </div>
                    </div>
                    <div className={styles.contentBottom}>
                        <div className={styles.center}>
                            <div className={styles.title}>
                                <div className={styles.titleName}>
                                    <img src={icon} alt=""/>
                                    告警趋势
                                    <span
                                        style={{
                                            fontSize: '12px',
                                            verticalAlign: 'bottom',
                                            marginTop: '0.5rem',
                                            marginLeft: '0.3rem',
                                        }}
                                    >
                                        (近30日)
                                    </span>
                                </div>
                            </div>
                            <Loading show={alarmTrend.isLoading} hiddenShade={true}>
                                {isEmpty(alarmTrend.data) ? (
                                    <EmptyView description={'暂无告警数据'}/>
                                ) : (
                                    <LineChart3 data={alarmTrend.data}/>
                                )}
                            </Loading>
                        </div>
                        <div className={styles.right}>
                            <div className={styles.title} style={{marginLeft: 0}}>
                                <div className={styles.titleName}>
                                    <img src={icon} alt=""/>
                                    告警列表
                                    <span
                                        style={{
                                            fontSize: '12px',
                                            verticalAlign: 'bottom',
                                            marginTop: '0.5rem',
                                            marginLeft: '0.3rem',
                                        }}
                                    >
                                        (近30条)
                                    </span>
                                </div>
                                {/* <Switch defaultChecked /> 目前我们没有语音，先暂时隐藏按钮*/}
                            </div>
                            <Loading show={alarmInfo.isLoading} hiddenShade={true}>
                                {isEmpty(alarmInfo.data) ? (
                                    <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                        <img src={NewestAlarmPlaceHolder} alt="最新告警无数据" width="574px" height="256px" />
                                    </div>
                                ) : (
                                    <div className={styles.swiper}>
                                        <SwiperNews data={alarmInfo.data}/>
                                    </div>
                                )}
                            </Loading>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    );
};

//告警信息轮流循环播放组件
const SwiperNews = ({data}) => {
    return (
        <Carousel dotPosition="left" dots={false} infinite={data.length > 6} autoplay={data.length > 6}>
            {map(data, (item, idx) => {
                return (
                    <div key={idx} className={styles.swiperItem}>
                        <div className={styles.message} title={item.location}>
                            {item.location}
                        </div>
                        <div className={styles.statusAlarm}>
                            <span
                                className={styles.alarm}
                                style={{
                                    backgroundColor:
                                        alarmLevel[item.grade] || item.grade === '高危'
                                            ? '#DC5F4F'
                                            : alarmLevel[item.grade] || item.grade === '异常'
                                                ? '#FFBC24'
                                                : '#c7c7c7',
                                }}
                            >
                                {item.grade || alarmLevel[item.grade]}
                            </span>
                            <span className={styles.status}>
                                {item.update_time.slice(0, 10) + ' ' + item.update_time.slice(11, 19)}
                            </span>
                        </div>
                    </div>
                );
            })}
        </Carousel>
    );
};
