import React, { Component } from 'react';
import { Row, Col } from 'antd';
import './style.less';
import { MyIcon } from '../../../util/iconfont';
import { Loading } from '../../../components/Loading';
import { EmptyView } from '../../../components/EmptyView'; //空数据占位组件
import { isEmpty } from 'lodash';

//工作台中的企业概况组件
class SurveyList extends Component {
    renderList = data => {
        return (
            <Row style={{ width: '100%' }}>
                <Col span={8}>
                    <MyIcon type="icon_declare" style={{ fontSize: '2.6rem' }} />
                    <p className="name">申报需量</p>
                    <p className="number">
                        {parseInt(Number(data.data.declare_power) * 100) / 100 || 0}
                        <span className="company">kW</span>
                    </p>
                </Col>
                <Col span={8}>
                    <MyIcon type="icon_device" style={{ fontSize: '2.6rem' }} />
                    <p className="name">监测装置</p>
                    <p className="number">
                        {data.data.sub_device || 0}
                        <span className="count">({data.data.offline_sub_device || 0})</span>
                        <span className="company">个</span>
                    </p>
                </Col>
                <Col span={8}>
                    <MyIcon type="icon_gateway" style={{ fontSize: '2.6rem' }} />
                    <p className="name">网关</p>
                    <p className="number">
                        {data.data.gateway || 0}
                        <span className="count">({data.data.offline_gateway || 0})</span>
                        <span className="company">个</span>
                    </p>
                </Col>
            </Row>
        );
    };

    render() {
        const { title, rawData } = this.props;
        return (
            <div className="surveyList">
                <span className="common_title">
                    <MyIcon
                        type="icon_title_enterprise_profile"
                        style={{
                            fontSize: '1.5rem',
                            marginTop: '-0.1rem',
                            verticalAlign: 'middle',
                            paddingRight: '0.6rem',
                        }}
                    />
                    {title}
                </span>
                <Loading show={rawData.isLoading} height={'80%'}>
                    {isEmpty(rawData.data) ? (
                        <EmptyView />
                    ) : (
                        <div className="surveyItem">{this.renderList(rawData)}</div>
                    )}
                </Loading>
            </div>
        );
    }
}

export default SurveyList;
