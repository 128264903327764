import React, { useMemo } from 'react';
import { map, ceil } from 'lodash';
import { Chart, Grid, Series, Tooltip, XAxis, YAxis, Dataset, VisualMap } from '@echarts-start/react-bridge';
import { COLOR_PLATE_16, HeatmapChart_COLOR } from '../../../../global';
const hours = [
    '00:00',
    '01:00',
    '02:00',
    '03:00',
    '04:00',
    '05:00',
    '06:00',
    '07:00',
    '08:00',
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
    '21:00',
    '22:00',
    '23:00',
];
const transformWeek = week => {
    switch (week) {
        case 1:
            return '周一';
        case 2:
            return '周二';
        case 3:
            return '周三';
        case 4:
            return '周四';
        case 5:
            return '周五';
        case 6:
            return '周六';
        case 7:
            return '周日';
        default: 
            return '周一';
    }
};
export const CartesianThermogramChart = ({ rawData }) => {
    const { data, days, max } = useMemo(() => {
        const data = [];
        const days = [];
        let max = 0;
        map(rawData, (item, y) => {
            days.push(`${transformWeek(item.week)}  ${item.date}`);
            map(item.data, everyOne => {
                if (max < everyOne.amount) {
                    max = everyOne.amount;
                }
                data.push([everyOne.interval, y, everyOne.amount]);
            });
        });
        max = ceil(max);
        return { data, days, max };
    }, [rawData]);
    return (
        <Chart color={COLOR_PLATE_16} style={{ width: '100%', height: '100%' }} resize>
            <Tooltip position="top" />
            <Dataset source={data} />
            <XAxis
                options={{
                    type: 'category',
                    data: hours,
                    splitArea: {
                        show: true,
                    },
                    axisTick: false,
                }}
            />
            <YAxis
                options={{
                    type: 'category',
                    data: days,
                    splitArea: {
                        show: true,
                    },
                    axisTick: false,
                }}
            />
            <Grid
                options={{
                    left: '9%',
                    right: '9%',
                    top: 20,
                    bottom: 20,
                }}
            />
            <VisualMap
                options={{
                    min: 0,
                    max,
                    type: 'piecewise',
                    calculable: false,
                    orient: 'vertical',
                    right: 0,
                    top: 'center',
                    itemGap: 0,
                    align: 'left',
                    inRange: {
                        symbol: 'rect',
                        color: HeatmapChart_COLOR,
                    },
                }}
            />
            <Series
                options={{
                    type: 'heatmap',
                    label: {
                        show: true,
                    },
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowColor: 'rgba(0, 0, 0, 0.5)',
                        },
                    },
                }}
            />
        </Chart>
    );
};
