import React, {useState, useMemo, useEffect} from 'react';
import {Tree} from 'antd';
import {map, get} from 'lodash';
import treeStyle from './Tree.module.less';
import {EmptyView} from '../../../components/EmptyView';
import {MyIcon} from '../../../util/iconfont';
import {status, treeIcon} from '../../../global';

const {DirectoryTree} = Tree;
// const getParentKey = (key, tree) => {
//     let parentKey;
//     for (let i = 0; i < tree.length; i++) {
//         const node = tree[i];
//         if (node.children) {
//             if (node.children.some(item => item.key === key)) {
//                 parentKey = node.key;
//             } else if (getParentKey(key, node.children)) {
//                 parentKey = getParentKey(key, node.children);
//             }
//         }
//     }
//     return parentKey;
// };
/**
 * 包含区域和设备的组件 （主要用于巡检管理）
 * × 不可多选
 * √ 可默认渲染第一条数据
 *
 * @param {*} treeData   区域树的数据
 * @param {*} onClick    点击事件触发
 * @param {*} operable   编辑节点
 * @param {*} add        新增节点
 * @param {*} edit       编辑节点
 * @param {*} remove     删除节点
 * @param {*} selectNum  节点title旁边的数量
 * @param {*} getAllTree
 * @returns
 */
export const Trees = ({treeData = [], onClick, operable = true, getNode, height}) => {
    const {dataList, parentRegionIds} = useMemo(() => {
        const nodeList = [];
        const generateList = data => {
            return map(data, item => {
                const {id, name, dev_cate} = item;
                nodeList.push({id, name});
                return {
                    ...item,
                    key: `${id}`,
                    title: name,
                    children: generateList(item.children),  /*disabled: `${dev_cate}` !== 'undefined' ? false : true*/
                    selectable: `${dev_cate}` !== 'undefined' ? true : false,
                    isLeaf: `${dev_cate}` !== 'undefined' ? true : false,
                    icon: `${dev_cate}` !== 'undefined' ?
                        <MyIcon type={treeIcon[dev_cate]} style={{fontSize: '1.5rem', verticalAlign: 'middle'}}/> :
                        <MyIcon type={'icon_address'}/>
                };
            });
        };
        const dataList = generateList(treeData);
        const parentRegionIds = get(dataList, '[0].id');
        getNode && getNode(nodeList);
        // onClick && onClick(dataList[0]);
        return {dataList, parentRegionIds};
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [treeData]);
    const onDrop = (event, node, dragNode, dragNodesKeys) => {
        // console.log(event);
        // console.log(node);
        // console.log(dragNode);
        // console.log(dragNodesKeys, 'dragNodesKeys');
    };
    //点击树节点触发
    const onSelect = (selectedKeys, info) => {
        onClick && onClick(info.node);
    };
    const [expandedKeys, setExpandedKeys] = useState([`${parentRegionIds}`]);
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    useEffect(() => {
        setExpandedKeys([`${parentRegionIds}`]);
    }, [parentRegionIds]);
    //展开的回调
    const onExpand = expandedKeysValue => {
        //展开/收起节点时触发
        //展开的状态
        setExpandedKeys(expandedKeysValue);
        setAutoExpandParent(false);
    };
    return (
        dataList.length > 0 ? <div className={treeStyle.container}>
            <DirectoryTree
                onExpand={onExpand} //展开事件
                onDrop={onDrop}
                onSelect={onSelect}
                expandedKeys={expandedKeys} //默认展开的key
                autoExpandParent={autoExpandParent} //是否自动展开父节点
                treeData={dataList}
                selectable={['11']}
                titleRender={operable ? node => <TreeTitle node={node}/> : null}
                // icon={<MyIcon type={'icon_address'}/>}
            />
        </div> : <div style={{height: height || '76vh'}}><EmptyView/></div>
    );
};

const TreeTitle = ({node}) => {
    if (node.level === 1) {
        return <span>{node.title}</span>;
    }
    return (
        <div
            className={treeStyle.title}
            style={{width: '70%', display: 'inline-block', alignItems: 'center', justifyContent: 'space-between'}}
        >
            <div>
                {/* {node.dev_cate ? (
                    <MyIcon type={treeIcon[node.dev_cate]} style={{ fontSize: '1.5rem', verticalAlign: 'middle' }} />
                ) : (
                    <MyIcon type="icon_address" />
                )} */}
                {/* <span style={{ verticalAlign: 'middle' }}>{' ' + node.title}</span> */}
                <span style={{
                    verticalAlign: 'middle',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    width: '200'
                }}>
                    <i style={{
                        fontStyle: 'normal',
                        display: 'inline-block',
                        width: '70%',
                        overflow: 'hidden',
                        verticalAlign: 'middle',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}>{' ' + node.title}</i>
                    {status[node.status] === '离线' ?
                        (<span style={{
                                color: '#EB4442',
                                fontSize: '12px',
                                borderColor: '#EB4442',
                                borderWidth: 1,
                                borderRadius: 3,
                                borderStyle: 'solid',
                                padding: '0 3px',
                                marginLeft: '3px'
                            }}>{'离线'}</span>
                        )
                        : (status[node.status] === '未激活' ? (
                                <span style={{
                                    color: '#F49759',
                                    fontSize: '12px',
                                    borderColor: '#F49759',
                                    borderWidth: 1,
                                    borderRadius: 3,
                                    borderStyle: 'solid',
                                    padding: '0 3px',
                                    marginLeft: '3px'
                                }}>{'未激活'}</span>
                            )
                            : undefined)}
                </span>
                <span style={{verticalAlign: 'middle'}}>{node.total !== undefined ? '(' + node.total + ')' : ''}</span>
            </div>
        </div>
    );
};
