import React from 'react';
import { Table } from 'antd';

/**
 * 峰谷表格组件
 * @param {*} peakValleyData 表格数据
 * @param {*} columns 表格标题以及参数
 * @className {*} peakValleyTableStyle 样式修改 能耗分析中的表格图
 * @className {*} reportSurveyTableStyle 样式修改 健康报表中的企业概况表格图
 * @className {*} reportSummaryTableStyle 样式修改 健康报表中的总结右侧表格图
 * @returns
 */
export const CommonTable = ({ peakValleyData, columns, className }) => {
    return (
        <Table
            rowKey={(record) => record.name || Math.random()}
            columns={columns}
            dataSource={peakValleyData.data}
            bordered={true}
            loading={peakValleyData.isLoading}
            // scroll={{ x: '100%' }}
            locale={{
                emptyText: '暂无数据',
            }}
            pagination={false}
            className={className}
        />
    );
};
