/**
 * 设备故障列表
 */
import React, { useRef, useState, useEffect, useImperativeHandle, useMemo } from 'react';
import { Table, Form, ConfigProvider, Select, Row, Col, Button } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import { BreadcrumbCustom } from '../../../components/BreadcrumbCustom';
import { omitBy, isUndefined, isNull, trim } from 'lodash';
import './adminTablePagination.less';
import { getFirmware, getDeviceCategory } from '../../../api/adminServe';

const FormItem = Form.Item;

const DeviceFailure = () => {
    return (
        <div className="container clearfix">
            <BreadcrumbCustom paths={['硬件管理', '设备故障']} className="rumbCustom" />
            <div className="row clearfix" style={{ width: '100%' }}>
                <DevicesTable />
            </div>
        </div>
    );
};

const DevicesTable = () => {
    const searchChildrenRef = useRef();
    const [data, setData] = useState({ data: [], isLoading: true });
    const [count, setCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [page_size, setPageSize] = useState(10);
    const ref = useRef(null);
    const [category, setCategory] = useState([]);
    const [device, setDevice] = useState([]);
    const [searchParams, setSearchParams] = useState({});
    // 获取固件列表数据
    const userListData = (page = 1, pageSize) => {
        const params = omitBy(searchParams, value => {
            return isUndefined(value) || isNull(value) || value === '' || trim(value) === '';
        });
        setCurrentPage(page);
        setData({ data: [], isLoading: true });
        //设备故障列表数据获取:
        // getFirmware({ page, page_size: pageSize ? pageSize : page_size, ...params })
        //     .then(result => {
        //         setData({ data: result.data, isLoading: false });
        //         setCount(result.count);
        //     })
        //     .catch(() => {
        //         setData({ data: [], isLoading: true });
        //     });
        setData({
            data: [
                {
                    id: 86,
                    create_time: '2022-01-19T16:12:45.380463',
                    update_time: '2022-01-19T16:12:45.380541',
                    failure_time: '2022-01-27T16:05:10.588030',
                    name: '0.0.7',
                    enterprise: '飞英思特',
                    category: 'F901',
                    device: 'F901-111111111-11111',
                    desc: '前端模拟数据',
                },
                {
                    id: 85,
                    create_time: '2022-01-19T14:28:50.787989',
                    update_time: '2022-01-19T14:28:50.788038',
                    failure_time: '2022-01-27T14:28:50.788038',
                    name: 'V1.0.6',
                    enterprise: '飞英思特',
                    category: 'F001',
                    device: 'F001-222222222-22222',
                    desc: '前端模拟数据',
                },
            ],
            isLoading: false,
        });
    };
    useEffect(() => {
        userListData();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);
    useEffect(() => {
        //获取分类ID
        // getDeviceCategory({ get_all: true })
        //     .then(result => {
        //         setCategory(result.data);
        //     })
        //     .catch();
        setCategory([
            { id: 'F001', name: '测试1' },
            { id: 'F601', name: '测试2' },
        ]);
        setDevice([
            { id: 1, name: 'F901-111111111-11111' },
            { id: 2, name: 'F001-222222222-22222' },
        ]);
    }, []);
    const columns = useMemo(() => {
        return [
            {
                title: '序号',
                dataIndex: 'id',
                key: 'id',
                width: 100, //宽度
                render: (text, record, index) => `${index + 1}`,
            },
            {
                title: '设备ID',
                dataIndex: 'device',
                sorter: true,
                render: (text, record) => (
                    <p
                        style={{
                            minWidth: '8rem',
                            maxWidth: '10rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={text === null || text === undefined ? '' : text}
                    >
                        {text === null || text === undefined ? '' : text}
                    </p>
                ),
            },
            {
                title: '分类ID',
                dataIndex: 'category',
                sorter: true,
                render: (text, record) => (
                    <p
                        style={{
                            minWidth: '3rem',
                            maxWidth: '5rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={text === null || text === undefined ? '' : text}
                    >
                        {text === null || text === undefined ? '' : text}
                    </p>
                ),
            },
            {
                title: '固件原因',
                dataIndex: 'desc',
                render: text => (
                    <p
                        style={{
                            minWidth: '8rem',
                            maxWidth: '16rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={text}
                    >
                        {text}
                    </p>
                ),
            },
            {
                title: '固件版本',
                dataIndex: 'name',
                render: text => (
                    <p
                        style={{
                            minWidth: '4rem',
                            maxWidth: '8rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={text}
                    >
                        {text}
                    </p>
                ),
            },
            {
                title: '关联企业',
                dataIndex: 'enterprise',
                render: text => (
                    <p
                        style={{
                            minWidth: '6rem',
                            maxWidth: '10rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={text}
                    >
                        {text}
                    </p>
                ),
            },
            {
                title: '故障时间',
                dataIndex: 'failure_time',
                width: 180,
                render: text => <span>{text && text.slice(0, 10) + ' ' + text.slice(11, 19)}</span>,
            },
        ];
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);
    const paginationNo = {
        pageSize: page_size, //每页的条数
        current: currentPage, //当前页
        total: count, //总数据
        showSizeChanger: true,
        onChange: (page, pageSize) => {
            ref.current = page;
            userListData(page, pageSize);
        }, //点击分页号时触发的方法
        onShowSizeChange: (current, pageSize) => {
            setPageSize(pageSize);
        }, //pageSize 变化的回调
        hideOnSinglePage: false, //为true则数据条数不满或刚好一页，则隐藏分页器
        pageSizeOptions: ['10', '15', '20', '30', '50', '100'], // 分页类别
        showTotal: total => `共 ${total} 条`,
    };
    return (
        <div className="tableBox">
            <Row>
                <Col span={20} offset={4} className="gutter-row">
                    <SearchForm
                        setSearchParams={setSearchParams}
                        category={category}
                        childRef={searchChildrenRef}
                        device={device}
                    />
                </Col>
            </Row>
            <ConfigProvider locale={zhCN}>
                <Table
                    rowKey={record => record.id}
                    pagination={paginationNo}
                    columns={columns}
                    loading={data.isLoading}
                    dataSource={data.data}
                    bordered={true}
                    scroll={{ x: '100%' }}
                    className="formTable"
                    style={{ textAlign: 'center', position: 'relative' }}
                />
            </ConfigProvider>
        </div>
    );
};

// 搜索
const SearchForm = ({ setSearchParams, category, childRef, device }) => {
    const [form] = Form.useForm();
    const onFinish = value => {
        setSearchParams(value);
    };
    useImperativeHandle(childRef, () => ({
        validateFields: form.validateFields,
        setFieldsValue: form.setFieldsValue,
    }));
    return (
        <Form
            form={form}
            onFinish={value => {
                onFinish(value);
            }}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
            <FormItem label="设备ID" name="category" style={{ marginRight: '1rem' }}>
                <Select
                    placeholder="请选择设备ID"
                    virtual={false}
                    showArrow
                    allowClear
                    style={{ minWidth: '10rem', width: '12rem' }}
                >
                    {device &&
                        device.map(item => {
                            return (
                                <Select.Option value={item.id} key={item.id}>
                                    {item.name}
                                </Select.Option>
                            );
                        })}
                </Select>
            </FormItem>
            <FormItem label="分类ID" name="category" style={{ marginRight: '1rem' }}>
                <Select
                    placeholder="请选择分类ID"
                    virtual={false}
                    showArrow
                    allowClear
                    style={{ minWidth: '6rem', width: '8rem' }}
                >
                    {category &&
                        category.map(item => {
                            return (
                                <Select.Option value={item.id} key={item.id}>
                                    {item.id}
                                </Select.Option>
                            );
                        })}
                </Select>
            </FormItem>
            <Button type="primary" htmlType="submit">
                搜索
            </Button>
        </Form>
    );
};
export default DeviceFailure;
