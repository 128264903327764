import React, { useRef, useEffect } from 'react';
import { Form, Button, Input, InputNumber, message } from 'antd';
import { BreadcrumbCustom } from '../../../components/BreadcrumbCustom';
import { getUsersInfo, editUsersInfo } from '../../../api/webServe';
import { account } from '../../../global'

const FormItem = Form.Item;
const FormItemLayout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 19 },
};
const layout = {
    wrapperCol: { offset: 8, span: 16 },
};

/**
 * 个人中心组件
 * @returns 
 */
const Personal = () => {
    const formChildrenRef = useRef();
    return (
        <div className="container clearfix">
            <BreadcrumbCustom paths={['首页', '系统设置', '个人中心']} className="rumbCustom" />
            <div className="row clearfix" style={{ backgroundColor: '#fff', padding: '1rem 1rem 0', margin: '1rem', borderRadius: '10px'  }}>
                <div className="personal" style={{ width: '40%' }}>
                    <RenderInfo childRef={formChildrenRef} />
                </div>
            </div>
        </div>
    );
};

/**
 * 个人中心表单组件
 * @param {*} childRef 表单
 * @returns 
 */
const RenderInfo = ({ childRef }) => {
    const [form] = Form.useForm();
    //个人中心默认值
    useEffect(() => {
        getUsersInfo()
            .then(result => {
                form.setFieldsValue({
                    enterprise_name: result.data.enterprise_name,
                    username: result.data.username,
                    real_name: result.data.real_name,
                    declare_power: result.data.declare_power,
                    rule_name: account[result.data.rule_name],
                    mobile: result.data.mobile,
                    email: result.data.email,
                    location: result.data.location,
                });
            })
            .catch();
            //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Form
            layout="horizontal"
            form={form}
            onFinish={value => {
                const location = value.location;
                editUsersInfo(location)
                    .then(() => {
                        message.success('修改成功');
                    })
                    .catch(err => {
                        message.error(err);
                    });
            }}
        >
            <FormItem label="企业名称" {...FormItemLayout} name="enterprise_name">
                <Input type="text" disabled />
            </FormItem>
            <FormItem label="申报需量" {...FormItemLayout}>
                <FormItem name="declare_power" rules={[{ required: false, message: '请输入申报需量！' }]} noStyle>
                    <InputNumber min={1} disabled precision={0}/>
                </FormItem>
                <span className="ant-form-text"> kW</span>
            </FormItem>
            <FormItem label="账号名" {...FormItemLayout} name="username">
                <Input type="text" disabled />
            </FormItem>
            <FormItem label="姓名" {...FormItemLayout} name="real_name">
                <Input type="text" disabled />
            </FormItem>
            <FormItem label="账号类型" {...FormItemLayout} name="rule_name">
                <Input type="text" disabled />
            </FormItem>
            <FormItem
                label="联系电话"
                {...FormItemLayout}
                name="mobile"
                rules={[{ pattern: /^1(3|4|5|7|8)\d{9}$/, message: '请输入正确的手机号！' }]}
            >
                <Input type="text" disabled />
            </FormItem>
            <FormItem
                label="邮箱"
                {...FormItemLayout}
                name="email"
                rules={[{ type: 'email', message: 'The input is not valid E-mail!' }]}
            >
                <Input disabled />
            </FormItem>
            <FormItem label="地址" {...FormItemLayout} name="location">
                <Input type="text" />
            </FormItem>
            <FormItem {...layout}>
                <Button style={{ textAlign: 'center', marginBottom: '10px', marginRight: '2rem' }}>取消</Button>
                <Button 
                type="primary" 
                htmlType="submit" 
                style={{ textAlign: 'center', marginBottom: '10px', }} 
                >
                    确定
                </Button>
            </FormItem>
        </Form>
    );
};

export default Personal;