let dialog = null;
const setDialog = _dialog => {
    dialog = _dialog;
};

const showDialog = (content, title, callback, width) => {
    dialog && dialog.onShow(content, title, callback, width);
};

const hiddenDialog = () => {
    dialog && dialog.hidden();
};

const showLoading = () => {
    dialog && dialog.showLoading();
};

const hiddenLoading = () => {
    dialog && dialog.hiddenLoading();
};

const exports = {
    setDialog, showDialog, hiddenDialog, showLoading, hiddenLoading
}
export default exports;
