import React, { useState, useEffect, useMemo } from 'react';
import { map, size, omit, clone, every, includes, filter } from 'lodash';
import { Table, Input, Form, Button, Checkbox,
    // Tabs 
} from 'antd';
import { getPermissionList, addUser, getUserDetail, editUser } from '../../../api/adminServe';
import ToastHelper from '../../../tools/ToastHelper';

// const { TabPane } = Tabs;
const layout = {
    labelCol: {
        span: 2,
    },
    wrapperCol: {
        span: 20,
    },
};
const inputLayout = {
    wrapperCol: {
        span: 8,
    },
};
const buttonLayout = {
    wrapperCol: {
        offset: 2,
        span: 16,
    },
};
const handlePermission = data => {
    const result = map(data, item => {
        let children = [];
        if (size(item.children)) {
            children = handlePermission(item.children);
        }
        return { ...omit(item, 'children'), is_active: false, list: children, key: item.id };
    });
    return result;
};

const handlePermissionSelect = (data, ids) => {
    const result = map(data, item => {
        let list = [];
        if (size(item.children)) {
            list = handlePermissionSelect(item.children, ids);
        }
        const permissions = map(item.permissions, per => {
            per.is_active = includes(ids, per.id);
            return per;
        });
        return { ...omit(item, 'children'), list, permissions, is_active: includes(ids, item.id), key: item.id };
    });
    return result;
};
export const EditRole = ({ setDataSource, type, userId, ...otherProps }) => {
    const [permission, setPermission] = useState([]);
    const [buttonPermission, setButtonPermission] = useState([]);
    const [form] = Form.useForm();
    const { showLoading, hiddenLoading, onOk } = otherProps;
    // const [clientType, setClientType] = useState('2');
    useEffect(() => {
        showLoading();
        setButtonPermission([]);
        form.setFieldsValue({ name: '', desc: '' });
        getPermissionList()
            .then(result => {
                // setPermission(handlePermission(result.data.filter(item => item.client_type === clientType)));
                setPermission(handlePermission(result.data));
                hiddenLoading();
                if (type !== 'add') {
                    getUserDetail(userId + '/')
                        .then(permissionResult => {
                            const ids = permissionResult.data.checked_permissions;
                            const selectPermissions = clone(handlePermissionSelect(result.data, ids));
                            const selectButtonPermission = [];
                            const handleButtonPermission = data => {
                                map(data, item => {
                                    if (size(item.list)) {
                                        handleButtonPermission(item.list);
                                    } else {
                                        item.is_active && selectButtonPermission.push(item);
                                    }
                                });
                            };
                            handleButtonPermission(selectPermissions);
                            form.setFieldsValue({ name: permissionResult.data.name, desc: permissionResult.data.desc });
                            // setPermission(selectPermissions.filter(item => item.client_type === clientType));
                            setPermission(selectPermissions);
                            setButtonPermission(selectButtonPermission);
                            hiddenLoading();
                        })
                        .catch();
                }
            })
            .catch();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, userId]);
    const onFinish = values => {
        showLoading();
        const permissions = [];
        map(permission, item => {
            item.is_active && permissions.push(item.id);
        });
        map(buttonPermission, item => {
            permissions.push(item.id);
            map(item.permissions, pre => {
                pre.is_active && permissions.push(pre.id);
            });
        });
        const { name, desc } = values;
        const requestPermissions = new Set(permissions);
        if (type === 'add') {
            addUser({ name, desc, permissions: [...requestPermissions] })
                .then(result => {
                    hiddenLoading();
                    setDataSource(dataSource => {
                        return [result.data, ...dataSource];
                    });
                    onOk();
                    ToastHelper.successToast('添加成功');
                })
                .catch(() => {
                    hiddenLoading();
                });
        }
        if (type === 'edit') {
            editUser({ name, desc, permissions: [...requestPermissions], id: userId })
                .then(result => {
                    hiddenLoading();
                    onOk();
                    ToastHelper.successToast('修改成功');
                })
                .catch(() => {
                    hiddenLoading();
                });
        }
    };
    const disabled = useMemo(() => {
        return type === 'ready';
    }, [type]);
    // const callback = key => {
    //     setClientType(Number(key));
    // };
    return (
        <Form {...layout} form={form} name="basic" onFinish={onFinish}>
            <Form.Item
                {...inputLayout}
                label="角色名称"
                name="name"
                rules={[
                    {
                        required: true,
                        message: '请输入角色名称!',
                    },
                ]}
            >
                <Input disabled={disabled} />
            </Form.Item>

            <Form.Item
                {...inputLayout}
                label="角色描述"
                name="desc"
                rules={[
                    {
                        required: true,
                        message: '请输入角色描述!',
                    },
                ]}
            >
                <Input.TextArea
                    maxLength={50}
                    showCount={true}
                    disabled={disabled}
                    autoSize={{ minRows: 4, maxRows: 6 }}
                />
            </Form.Item>
            <Form.Item label="权限配置" name="power">
                {/* <Tabs defaultActiveKey="2" onChange={callback} className="roleStyle">
                    <TabPane tab="Web权限" key="2">
                        <RightsProfile
                            data={permission}
                            disabled={disabled}
                            buttonPermission={buttonPermission}
                            setButtonPermission={setButtonPermission}
                            setData={setPermission}
                        />
                    </TabPane>
                    <TabPane tab="APP权限" key="3">
                        <RightsProfile
                            data={permission}
                            disabled={disabled}
                            buttonPermission={buttonPermission}
                            setButtonPermission={setButtonPermission}
                            setData={setPermission}
                        />
                    </TabPane>
                    <TabPane tab="中台权限" key="1">
                        <RightsProfile
                            data={permission}
                            disabled={disabled}
                            buttonPermission={buttonPermission}
                            setButtonPermission={setButtonPermission}
                            setData={setPermission}
                        />
                    </TabPane>
                </Tabs> */}
                <RightsProfile
                    data={permission}
                    disabled={disabled}
                    buttonPermission={buttonPermission}
                    setButtonPermission={setButtonPermission}
                    setData={setPermission}
                />
            </Form.Item>
            {!disabled && (
                <Form.Item {...buttonLayout}>
                    <Button type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            )}
        </Form>
    );
};

const RightsProfile = ({ disabled, data, setData, buttonPermission, setButtonPermission }) => {
    const columns = [
        {
            title: '类型',
            dataIndex: 'name',
            render: (record, item, idx) => {
                return (
                    <div style={{ display: 'flex' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Checkbox
                                disabled={disabled}
                                checked={item.is_active}
                                onClick={value => {
                                    value = value.target.checked;
                                    data[idx].is_active = value;
                                    if (!size(item.list)) {
                                        let resultData = value
                                            ? [...buttonPermission, { ...item }]
                                            : filter(buttonPermission, per => per.id !== item.id);
                                        setButtonPermission(resultData);
                                    } else {
                                        let resultData = value
                                            ? [...buttonPermission, { ...item }]
                                            : filter(buttonPermission, per => per.id !== item.id);
                                        !value &&
                                            map(item.list, values => {
                                                resultData = filter(resultData, per => per.id !== values.id);
                                            });
                                        setButtonPermission(resultData);
                                    }
                                    if (!value) {
                                        const list = map(data[idx].list, item => {
                                            return { ...item, is_active: false };
                                        });
                                        data[idx].list = list;
                                    }
                                    setData(clone(data));
                                }}
                            >
                                {record}
                            </Checkbox>
                        </div>
                    </div>
                );
            },
        },
        {
            title: '权限列表',
            dataIndex: 'list',
            width: '80%',
            render: (record, itemData, idx) => {
                if (size(record)) {
                    return (
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {map(record, (item, childIdx) => {
                                return (
                                    <div
                                        key={item.id}
                                        style={{ display: 'flex', alignItems: 'center', padding: '2px 0' }}
                                    >
                                        <Checkbox
                                            disabled={disabled}
                                            checked={item.is_active}
                                            onClick={value => {
                                                value = value.target.checked;
                                                data[idx].list[childIdx].is_active = value;
                                                let permissions = value
                                                    ? [...buttonPermission, item]
                                                    : filter(buttonPermission, per => per.id !== item.id);
                                                // setButtonPermission(
                                                //     value
                                                //         ? [...buttonPermission, item]
                                                //         : filter(buttonPermission, per => per.id !== item.id),
                                                // );
                                                if (every(data[idx].list, { is_active: false })) {
                                                    permissions = filter(permissions, per => per.id !== data[idx].id);
                                                    data[idx].is_active = false;
                                                } else {
                                                    data[idx].is_active = true;
                                                }
                                                setButtonPermission(permissions);
                                                setData(clone(data));
                                            }}
                                        >
                                            {item.name}
                                        </Checkbox>
                                    </div>
                                );
                            })}
                        </div>
                    );
                }
                return '-';
            },
        },
        {
            title: '全选',
            dataIndex: 'list',
            width: '10%',
            render: (record, item, idx) => {
                if (size(record)) {
                    let selected = record.filter(isActive => isActive.is_active === true); //是否全选状态
                    return (
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            <div key={item.id} style={{ display: 'flex', alignItems: 'center', padding: '2px 0' }}>
                                <Checkbox
                                    disabled={disabled}
                                    checked={item.is_active}
                                    indeterminate={
                                        record && record.length === selected.length
                                            ? false
                                            : selected.length === 0
                                            ? false
                                            : true
                                    }
                                    onClick={value => {
                                        value = value.target.checked;
                                        // debugger;
                                        //remove old permission first
                                        let buttonPermissionCopy = JSON.parse(JSON.stringify(buttonPermission));
                                        let recordIds = record.map(eachRecord => eachRecord.id);
                                        buttonPermissionCopy = buttonPermissionCopy.filter(
                                            eachOldPermission => !recordIds.includes(eachOldPermission.id),
                                        );
                                        if (value) {
                                            //if checked , add permission to button list
                                            setButtonPermission(buttonPermissionCopy.concat(record));
                                        } else {
                                            setButtonPermission(buttonPermissionCopy);
                                        }
                                        map(record, (item, childIdx) => {
                                            data[idx].list[childIdx].is_active = value;
                                            //let permissions = value
                                            //    ? [...buttonPermission, item]
                                            //    : filter(buttonPermission, per => per.id !== item.id);
                                            if (every(data[idx].list, { is_active: false })) {
                                                // permissions = filter(buttonPermission, per => per.id !== data[idx].id);
                                                data[idx].is_active = false;
                                            } else {
                                                data[idx].is_active = true;
                                            }
                                            // setButtonPermission(
                                            //     value
                                            //     ? [...buttonPermission, ...permissions]
                                            //     : filter(buttonPermission, (per,index) => per.id !== permissions[index].id),
                                            // );
                                            setData(clone(data));
                                        });
                                    }}
                                >
                                    全选
                                </Checkbox>
                            </div>
                        </div>
                    );
                }
                return '-';
            },
        },
    ];

    const buttonColumns = [
        {
            title: '页面',
            dataIndex: 'name',
        },
        {
            title: '操作权限',
            dataIndex: 'permissions',
            width: '80%',
            render: (record, itemData, idx) => {
                return (
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {map(record, (item, childIdx) => {
                            return (
                                <div key={item.id} style={{ display: 'flex', alignItems: 'center', padding: '2px 0' }}>
                                    <Checkbox
                                        disabled={disabled}
                                        checked={item.is_active}
                                        onClick={value => {
                                            value = value.target.checked;
                                            buttonPermission[idx].permissions[childIdx].is_active = value;
                                            setButtonPermission(clone(buttonPermission));
                                        }}
                                    >
                                        {item.name}
                                    </Checkbox>
                                </div>
                            );
                        })}
                    </div>
                );
            },
        },
        {
            title: '全选',
            dataIndex: 'permissions',
            width: '10%',
            render: (record, itemData, idx) => {
                if (size(record)) {
                    let selected = record.filter(isActive => isActive.is_active === true); //是否全选状态
                    return (
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            <div
                                key={itemData.id}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '2px 0',
                                    justifyContent: 'center',
                                }}
                            >
                                <Checkbox
                                    disabled={disabled}
                                    checked={record && record[0].is_active ? true : false}
                                    indeterminate={
                                        record && record.length === selected.length
                                            ? false
                                            : selected.length === 0
                                            ? false
                                            : true
                                    }
                                    onClick={value => {
                                        value = value.target.checked;
                                        map(record, (item, childIdx) => {
                                            buttonPermission[idx].permissions[childIdx].is_active = value;
                                            setButtonPermission(clone(buttonPermission));
                                        });
                                    }}
                                >
                                    全选
                                </Checkbox>
                            </div>
                        </div>
                    );
                }
                return '-';
            },
        },
    ];

    useEffect(() => {
        if (buttonPermission.find(val => val.list && val.list.length > 0)) {
            setButtonPermission(currentVal => currentVal.filter(val => val.list && val.list?.length === 0));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buttonPermission]);

    return (
        <div>
            <div style={{ fontSize: '16px', marginTop: '5px' }}>Web权限</div>
            <Table style={{ marginTop: '20px' }} columns={columns} pagination={false} dataSource={data} />
            <div style={{ padding: '20px 0', fontSize: '16px' }}>操作权限</div>
            <Table columns={buttonColumns} pagination={false} dataSource={buttonPermission} />
        </div>
    );
};
