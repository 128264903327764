import React, { useState, useRef, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button, message } from 'antd';
import { GlobalModal } from '../../../components/GlobalModal';
import OneBtnSwitch from './OneBtnSwitch';
import IconCondition from '../../../assets/images/icon_condition.png';
import IconHiddenDanger from '../../../assets/images/icon_hiddenDanger.png';
import IconOff from '../../../assets/images/icon_off.png';
import IconOn from '../../../assets/images/icon_on.png';
import IconPatrol from '../../../assets/images/icon_patrol.png';
import IconTimeSwitch from '../../../assets/images/icon_timeSwitch.png';
import { getPermissions } from '../../../tools/StorageHelper';

const getStatus = action => {
    //一键开关 1开 2关
    let checkedValues = JSON.parse(localStorage.getItem('checkedValue'));
    this.props.getControlDevice(action, JSON.stringify(checkedValues) || JSON.stringify(this.state.checkedValues));
};

//工作台 -- 快捷入口
const QuickTag = () => {
    const childRef = useRef();
    const [modalType, setModalType] = useState('add'); //add 新增 edit 编辑 ready 查看
    /**
     * 判断是开灯(on), 还是关灯(off)
     */
    const title = useMemo(() => {
        if (modalType === 'on') {
            return '一键开灯';
        }
        return '一键关灯';
    }, [modalType]);

    const onPath = (show) => {
        let path =  getPermissions() != 'undefined' && JSON.parse(getPermissions()).filter(item => {
            return item.name === show
        })
        return path
    }
    
    const path = (father, childern) => {
        let dataPath = onPath(father).length > 0 && onPath(father)[0].children.filter(item => {
            return item.name === childern
        })
        return dataPath
    }
    //根据配置的权限触发对应的点击事件
    const handle = (e, value) => {
        // if(!showFunction(value)){
        // if (!showFunctionChildren(value)) {
        //     e.preventDefault();
        //     message.error('暂无访问权限');
        //     //e.stopPropagation(); //阻止冒泡
        // }
        if(path(value[0], value[1]).length === 0){
            e.preventDefault();
            message.error('暂无访问权限');
        }
    };
    return (
        <div style={style.quickTag}>
            <Row style={style.quickTag_top}>
                <Col span={4} style={{ paddingBottom: '0rem' }}>
                    <Button
                        style={style.quickTag_top_btn}
                        type="default"
                        onClick={e => {
                            // if (!showFunctionPermission('一键开关')) {
                            //     e.preventDefault();
                            //     message.error('暂无访问权限');
                            // } else {
                            //     setModalType('on'); //弹框类型为一键开灯
                            //     childRef.current.showModal(); //点击打开弹框
                            // }
                            setModalType('on'); //弹框类型为一键开灯
                            childRef.current.showModal(); //点击打开弹框
                        }}
                    >
                        <img src={IconOn} alt="一键开灯" width="44px" height="44px" />
                        <p style={{ marginTop: '0.5rem' }}>一键开灯</p>
                    </Button>
                </Col>
                <Col span={4} style={{ paddingBottom: '0.5rem' }}>
                    <Button
                        style={style.quickTag_top_btn}
                        type="default"
                        onClick={e => {
                            // if (!showFunctionPermission('一键开关')) {
                            //     e.preventDefault();
                            //     message.error('暂无访问权限');
                            // } else {
                            //     setModalType('off');
                            //     childRef.current.showModal();
                            // }
                            setModalType('off');
                            childRef.current.showModal();
                        }}
                    >
                        <img src={IconOff} alt="一键关灯" width="58px" height="58px" />
                        <p>一键关灯</p>
                    </Button>
                </Col>
                <Col span={4} style={{ paddingBottom: '0.5rem' }}>
                    <Link
                        to="/web/systemSetting/timeSwitch"
                        style={style.quickTag_bottom_btn}
                        className="quickTag"
                        onClick={e => {
                            // if (!showFunctionChildren('定时开关')) {
                            //     e.preventDefault();
                            //     message.error('暂无访问权限');
                            // }
                            handle(e, ['系统设置','定时开关']);
                        }}
                    >
                        <img src={IconTimeSwitch} alt="定时开关" width="58px" height="58px" />
                        <p>定时开关</p>
                    </Link>
                </Col>
                <Col span={4} style={{ paddingBottom: '0.5rem' }}>
                    <Link
                        to="/web/patrol/online"
                        style={style.quickTag_bottom_btn}
                        className="quickTag"
                        onClick={e => {
                            handle(e, ['巡检管理','在线巡检']);
                        }}
                    >
                        <img src={IconPatrol} alt="在线巡检" width="58px" height="58px" />
                        <p>在线巡检</p>
                    </Link>
                </Col>
                <Col span={4} style={{ paddingBottom: '0.5rem' }}>
                    <Link
                        to="/web/ecr/condition"
                        style={style.quickTag_bottom_btn}
                        className="quickTag"
                        onClick={e => {
                            handle(e, ['能耗分析','能耗概况']);
                        }}
                    >
                        <img src={IconCondition} alt="能耗概况" width="58px" height="58px" />
                        <p>能耗概况</p>
                    </Link>
                </Col>
                <Col span={4} style={{ paddingBottom: '0.5rem' }}>
                    <Link
                        to="/web/hiddenDanger/list"
                        style={style.quickTag_bottom_btn}
                        className="quickTag"
                        onClick={e => {
                            handle(e, ['隐患管理','隐患列表']);
                        }}
                    >
                        <img src={IconHiddenDanger} alt="隐患管理" width="58px" height="58px" />
                        <p>隐患管理</p>
                    </Link>
                </Col>
            </Row>
            <GlobalModal title={title} childRef={childRef} onOk={getStatus} footer={null}>
                <OneBtnSwitch type={modalType} />
            </GlobalModal>
        </div>
    );
};

export default QuickTag;

const style = {
    quickTag: {
        width: '100%',
        backgroundColor: '#fff',
        boxShadow: '0px 3px 6px rgba(220, 231, 235, 0.16)',
        borderRadius: '10px',
    },
    quickTag_top_btn: {
        display: 'block',
        textAlign: 'center',
        border: 'none',
        borderRadius: '0',
        width: '100%',
        height: '100%',
        backgroundColor: 'transparent',
        boxShadow: '0px 0px 0px #fff',
        color: '#666666',
        flex: '1 1 50%',
        fontSize: '1rem',
        paddingTop: '1rem',
    },
    quickTag_bottom: {
        textAlign: 'center',
        marginTop: '1.2rem',
        current: 'pointer',
        display: 'flex',
    },
    quickTag_bottom_btn: {
        textAlign: 'center',
        position: 'relative',
        paddingTop: '1rem',
        color: '#666666',
        flex: '1 1 50%',
        display: 'block',
        fontSize: '1rem',
    },
};
