/*
 * @Descripttion:折线图组件
 * @Author: lipei
 * @Date: 2021-16-Th 09:46:06
 * @Last Modified by:   lipei
 * @Last Modified time: 2021-16-Th 09:46:06
 */
import React, { Component } from 'react';
import ReactEcharts from 'echarts-for-react';
import { unitControl } from '../../../../util/function-util';

export default class ChartLines extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    getOption = () => {
        let { yData, xTime, names } = this.props;
        let dataZoom_start;
        if (yData) {
            if (yData.length > 30) {
                dataZoom_start = (150 / yData.length) * 100;
            } else {
                dataZoom_start = 0;
            }
        }
        let option = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#6a7985',
                    },
                },
            },
            grid: {
                left: '3%',
                top: '15%',
                right: '3%',
                bottom: '3%',
                containLabel: true,
            },
            legend: {
                top: '1%',
                left: '80%',
                // x: 'right',
                itemWidth: 12, // 设置宽度
                itemHeight: 8, // 设置高度
                icon: 'circle',
                data: [
                    {
                        name: names,
                        orient: 'horizontal',
                        textStyle: {
                            color: '#c7c7c7',
                            fontSize: 10,
                        },
                    },
                    {
                        name: '本次异常点',
                    },
                ],
                textStyle: {
                    color: '#c7c7c7',
                    fontSize: 10,
                },
            },
            xAxis: {
                type: 'category',
                //minInterval: 1, //设置成1保证坐标轴分割刻度显示成整数。
                min: 0, // 起始
                data: xTime,
                axisLabel: {
                    inside: false,
                    // textStyle: {
                    color: '#000',
                    fontSize: '10',
                    itemSize: '',
                    // },
                    //interval: 0, //使x轴文字显示全
                },
                splitLine: {
                    show: false,
                    lineStyle: {
                        type: 'dotted',
                        opacity: '0.3',
                        color: '#666',
                    },
                },
            },
            yAxis: {
                name: unitControl[names] ? '单位:(' + unitControl[names] + ')' : '',
                type: 'value',
                //minInterval: 1, //设置成1保证坐标轴分割刻度显示成整数。
                min: 0, // 起始
                // max: 1, //最大
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#666',
                    },
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        type: 'dotted',
                        opacity: '0.3',
                        color: '#666',
                    },
                },
            },
            dataZoom: [
                {
                    type: 'inside', //使鼠标在图表中时滚轮可用
                    show: true,
                    start: dataZoom_start,
                    end: 100,
                },
            ],
            animationType: 'scale',
            animationEasing: 'elasticOut',
            animationDelay: function (idx) {
                return Math.random() * 200;
            },
            animationDuration: 1000,
            series: [
                {
                    data: yData,
                    type: 'line',
                    name: names,
                    animation: true,
                    itemStyle: {
                        color: '#E9B316',
                    },
                    emphasis: {
                        itemStyle: {
                            color: '#E9B316',
                        },
                    },
                    barGap: '0%',
                },
                {
                    // data: [{value: null},{value: null},{value: null},{value: 219.31},{value: null},{value: null},{value: null}],
                    name: '本次异常点',
                    type: 'line',
                    itemStyle: {
                        color: '#f40',
                    },
                    symbol: 'circle',
                    symbolSize: 12,
                },
            ],
        };
        return option;
    };
    render() {
        const option = this.getOption();
        return (
            <ReactEcharts
                ref={ref => (this.echarts = ref)}
                option={option}
                style={this.props.style}
                // className='react_for_echarts'
            />
        );
    }
}
