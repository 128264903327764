import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Carousel, Col, Row } from 'antd';
import styles from './bigScreen.module.less';
import moment from 'moment';
import { map, isEmpty, sortBy, ceil } from 'lodash';
import { Player } from '@lottiefiles/react-lottie-player';
import { InstrumentPanelChart } from '../components/InstrumentPanelChart';
import { BarChart, LineChart, LineChart2, MixedGraph, OnlyBarChart, PieChart } from '../components/BarChart';
import { Loading } from '../components/Loading';
import icon from '../assets/images/big_screen_icon.png';
import titleIcon from '../assets/images/title_icon.png';
import BigScreenBorder from '../assets/BigScreenBorder.json';
import FullScrenn from '../util/fullScreen';
import {
    bigScreen_1,
    bigScreen_10,
    bigScreen_2,
    bigScreen_3,
    bigScreen_4,
    bigScreen_5,
    bigScreen_6,
    bigScreen_7,
    bigScreen_8,
    bigScreen_9,
    EnterpriseName,
} from '../api/webServe';
import { EmptyView } from '../components/EmptyView'; //空数据占位组件
import { MyIcon } from '../util/iconfont'; //阿里图标组件
import { PieChart_COLOR2, dataConvert, alarmLevel } from '../global'; //颜色集

const emptyTest = () => {
    return <div style={{ color: '#ffffff' }}>暂无数据</div>;
};

const Timer = () => {
    const [time, setTime] = useState(moment(new Date()).format('llll:ss'));
    useEffect(() => {
        //当前时间日期
        const interval = setInterval(() => {
            setTime(moment(new Date()).format('llll:ss'));
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, []);
    return (
        <div className={styles.right}>
            {time}
            {/*<span style={{ marginLeft: '20px', fontWeight: 'bold', fontSize: '20px' }}>10:00:00</span>*/}
        </div>
    );
};

export const BigScreen = () => {
    const [data1, setData1] = useState({ data: null, isLoading: true });
    const [data2, setData2] = useState({ data: null, isLoading: true });
    const [data3, setData3] = useState({ data: null, isLoading: true });
    const [data4, setData4] = useState({ data: null, isLoading: true });
    const [data5, setData5] = useState({ data: null, isLoading: true });
    const [data6, setData6] = useState({ data: null, isLoading: true });
    const [data7, setData7] = useState({ data: null, isLoading: true });
    const [data8, setData8] = useState({ data: null, isLoading: true });
    const [data9, setData9] = useState({ data: null, isLoading: true });
    const [data10, setData10] = useState({ data: null, isLoading: true });
    /**
     * 监听浏览器是否全屏
     * */
    const [isScreenFull, setIsScreenFull] = useState(false); //是否全屏
    useEffect(() => {
        //初始化
        FullScrenn.init(screenChange);
    }, [isScreenFull]);
    //屏幕变化
    const screenChange = isFull => {
        // console.log('是否全屏', isFull);
        setIsScreenFull(isFull);
    };
    //企业总览
    const [enterpriseName, setEnterpriseName] = useState();
    const bigScreen1 = () => {
        setData1({ data: null, isLoading: true });
        bigScreen_1()
            .then(result => {
                setData1({ data: result.data, isLoading: false });
            })
            .catch(() => {
                setData1({ data: null, isLoading: false });
            });
    };
    //企业指数
    const bigScreen2 = () => {
        setData2({ data: null, isLoading: true });
        bigScreen_2()
            .then(result => {
                setData2({ data: result.data, isLoading: false });
            })
            .catch(() => {
                setData2({ data: null, isLoading: false });
            });
    };
    //设备统计
    const bigScreen3 = () => {
        setData3({ data: null, isLoading: true });
        bigScreen_3()
            .then(result => {
                setData3({ data: result.data, isLoading: false });
            })
            .catch(() => {
                setData3({ data: null, isLoading: false });
            });
    };
    //用电趋势
    const bigScreen4 = () => {
        setData4({ data: null, isLoading: true });
        bigScreen_4()
            .then(result => {
                setData4({ data: result.data, isLoading: false });
            })
            .catch(() => {
                setData4({ data: null, isLoading: true });
            });
    };
    //功率因数
    const bigScreen5 = () => {
        setData5({ data: null, isLoading: true });
        bigScreen_5()
            .then(result => {
                setData5({ data: result.data, isLoading: false });
            })
            .catch(() => {
                setData5({ data: null, isLoading: false });
            });
    };
    //三相不平衡
    const bigScreen6 = () => {
        setData6({ data: null, isLoading: true });
        bigScreen_6()
            .then(result => {
                setData6({ data: result.data, isLoading: false });
            })
            .catch(() => {
                setData6({ data: null, isLoading: false });
            });
    };
    //告警统计
    const bigScreen7 = () => {
        setData7({ data: null, isLoading: true });
        bigScreen_7()
            .then(result => {
                setData7({ data: result.data, isLoading: false });
            })
            .catch(() => {
                setData7({ data: null, isLoading: false });
            });
    };
    //告警排行
    const bigScreen8 = () => {
        setData8({ data: null, isLoading: true });
        bigScreen_8()
            .then(result => {
                result = sortBy(result.data, item => -item.number);
                setData8({ data: result, isLoading: false });
            })
            .catch(() => {
                setData8({ data: null, isLoading: false });
            });
    };
    //告警趋势
    const bigScreen9 = () => {
        setData9({ data: null, isLoading: true });
        bigScreen_9()
            .then(result => {
                setData9({ data: result.data, isLoading: false });
            })
            .catch(() => {
                setData9({ data: null, isLoading: false });
            });
    };
    //告警信息
    const bigScreen10 = () => {
        setData10({ data: null, isLoading: true });
        bigScreen_10()
            .then(result => {
                setData10({ data: result.data, isLoading: false });
            })
            .catch(() => {
                setData10({ data: null, isLoading: false });
            });
    };
    //企业名称
    useEffect(() => {
        EnterpriseName()
            .then(result => {
                setEnterpriseName(result.enterprise_name);
            })
            .catch();
    }, []);
    useEffect(() => {
        bigScreen1();
        bigScreen2();
        bigScreen3();
        bigScreen4();
        bigScreen5();
        bigScreen6();
        bigScreen7();
        bigScreen8();
        bigScreen9();
        bigScreen10();
    }, []);
    /**
     * 监听屏幕的宽高
     * */
    // const getWindowSize = () => ({
    //     innerHeight: window.innerHeight,
    //     innerWidth: window.innerWidth,
    // });
    // const [windowSize, setWindowSize] = useState(getWindowSize());
    // const handleResize = () => {
    //     setWindowSize(getWindowSize());
    // };
    // useEffect(() => {
    //     // 监听
    //     window.addEventListener("resize", handleResize);
    //     // 销毁
    //     return () => window.removeEventListener("resize", handleResize);
    // });

    return (
        <Row>
            <Col span={24} className={styles.bigScreenContainer}>
                <div className={styles.bigScreenHeader}>
                    <div className={styles.left}>
                        <MyIcon type="icon_location" style={{ fontSize: '26px', marginRight: '5px' }} />
                        {enterpriseName}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '40vw' }}>
                        <div
                            className={styles.center}
                            style={{ marginTop: '-0.6rem', position: 'relative', display: 'flex' }}
                        >
                            <img src={titleIcon} alt="" />
                            <span style={{ verticalAlign: 'middle' }}>智慧微电网数字化监控大厅</span>
                        </div>
                        {/* <div style={{height: isScreenFull ? '1vh' :'0.8vh', display: isScreenFull ? 'none': 'block' }}></div> */}
                        <Player
                            autoplay
                            loop
                            controls={true}
                            src={BigScreenBorder}
                            // style={{ position: 'absolute', top: `${((windowSize.innerWidth/windowSize.innerHeight)/(3840/2160)).toFixed(1) === '1.0' ? '-0.2' : (((windowSize.innerWidth/windowSize.innerHeight)/(3840/2160)).toFixed(1) < '1.0' ? '1.5' : '-1.5')}%`, right: '0' }}
                            style={
                                isScreenFull
                                    ? {
                                          width: '46vw',
                                          height: '2vh',
                                          transform: 'scale(0.68)',
                                          marginLeft: '-3.2vw',
                                          marginTop: '0.5vh',
                                      }
                                    : {
                                          width: '46vw',
                                          height: '2vh',
                                          transform: 'scale(0.68)',
                                          marginLeft: '-3.2vw',
                                          marginTop: '-0.3vh',
                                      }
                            }
                            renderer={'svg'}
                            rendererSettings={{ preserveAspectRatio: 'xMinYMin slice' }} //xMidYMid meet xMinYMin slice  xMinYMax
                        ></Player>
                    </div>
                    <Timer />
                </div>
                <div className={styles.bigScreenContent}>
                    <div className={styles.contentFirst}>
                        <div className={styles.left}>
                            <div className={styles.title}>
                                <div className={styles.titleName}>
                                    <img src={icon} alt="" />
                                    企业总览
                                </div>
                            </div>
                            <Loading show={data1.isLoading} hiddenShade={true}>
                                {isEmpty(data1.data) ? (
                                    <EmptyView description={emptyTest()} />
                                ) : (
                                    <div className={styles.content}>
                                        <div className={styles.leftCenter}>
                                            <div className={styles.leftCenterItem}>
                                                <div className={styles.img}>
                                                    <MyIcon type="icon_power" />
                                                </div>
                                                <div className={styles.text}>
                                                    <p>今日用电</p>
                                                    <div>{ceil(data1.data.power_consumption, 2)}kWh</div>
                                                </div>
                                            </div>
                                            <div className={styles.leftCenterItem}>
                                                <div className={styles.img}>
                                                    <MyIcon type="icon_bj" />
                                                </div>
                                                <div className={styles.text}>
                                                    <p>告警次数</p>
                                                    <div>{data1.data.alarm_count}次</div>
                                                </div>
                                            </div>
                                            <div className={styles.leftCenterItem}>
                                                <div className={styles.img}>
                                                    <MyIcon type="icon_jc" />
                                                </div>
                                                <div className={styles.text}>
                                                    <p>检测装置</p>
                                                    <div>{data1.data.sub_device}个</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.leftBottom}>
                                            <div className={styles.leftBottomLeft} style={{ fontSize: '0.8rem' }}>
                                                最大负荷
                                                <div>{ceil(data1.data.max_power, 2)}kW</div>
                                            </div>
                                            <div className={styles.leftBottomRight}>
                                                {data1.data.max_power_time ? data1.data.max_power_time : '-'}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Loading>
                        </div>
                        <div className={styles.center}>
                            <div className={styles.title}>
                                <div className={styles.titleName}>
                                    <img src={icon} alt="" />
                                    企业指数
                                </div>
                            </div>
                            <Loading show={data2.isLoading} hiddenShade={true}>
                                {isEmpty(data2.data) ? (
                                    <EmptyView description={emptyTest()} />
                                ) : (
                                    <div className={styles.content}>
                                        {map(data2.data, (item, idx) => {
                                            return (
                                                <div className={styles.everyOne} key={idx}>
                                                    <InstrumentPanelChart type={idx + 1} data={item} />
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </Loading>
                        </div>
                        <div className={styles.right}>
                            <div className={styles.title}>
                                <div className={styles.titleName}>
                                    <img src={icon} alt="" />
                                    功率因数
                                </div>
                            </div>
                            <Loading show={data5.isLoading} hiddenShade={true}>
                                {/* {isEmpty(data5.data) ? (
                                    <EmptyView description={emptyTest()} />
                                ) : ( */}
                                <OnlyBarChart data={data5.data} />
                                {/* )} */}
                            </Loading>
                        </div>
                        {/* <div className={styles.right}>
                            <div className={styles.content}>
                                <div className={styles.title}>
                                    <div className={styles.titleName}>
                                        <img src={icon} alt="" />
                                        设备统计
                                    </div>
                                </div>
                                <Loading show={data3.isLoading} hiddenShade={true}>
                                    {isEmpty(data3.data) ? (
                                        <EmptyView description={emptyTest()} />
                                    ) : (
                                        <BarChart data={data3.data} />
                                    )}
                                </Loading>
                            </div>
                        </div> */}
                    </div>
                    <div className={styles.contentCenter}>
                        <div className={styles.left}>
                            <div className={styles.top}>
                                <div className={styles.title}>
                                    <div className={styles.titleName}>
                                        <img src={icon} alt="" />
                                        设备统计
                                    </div>
                                </div>
                                <Loading show={data3.isLoading} hiddenShade={true}>
                                    {isEmpty(data3.data) ? (
                                        <EmptyView description={emptyTest()} />
                                    ) : (
                                        <BarChart data={data3.data} />
                                    )}
                                </Loading>
                            </div>
                            <div
                                className={styles.bottom}
                                style={{
                                    marginTop:
                                        '0.8rem' /*background: `url("${Pies}") 80px 22px no-repeat`, backgroundSize:'31%'*/,
                                }}
                            >
                                <div className={styles.title}>
                                    <div className={styles.titleName}>
                                        <img src={icon} alt="" />
                                        告警统计
                                    </div>
                                </div>
                                <Loading show={data7.isLoading} hiddenShade={true}>
                                    {isEmpty(data7.data) || data7.data.data.length === 0 ? (
                                        <EmptyView description={emptyTest()} />
                                    ) : (
                                        <PieChart
                                            data={data7.data}
                                            color={PieChart_COLOR2}
                                            isScreenFull={isScreenFull}
                                        />
                                    )}
                                </Loading>
                            </div>
                        </div>
                        <div className={styles.center}>
                            <div className={styles.title}>
                                <div className={styles.titleName}>
                                    <img src={icon} alt="" />
                                    用电趋势
                                </div>
                            </div>
                            <Loading show={data4.isLoading} hiddenShade={true}>
                                {isEmpty(data4.data) ? (
                                    <EmptyView description={emptyTest()} />
                                ) : (
                                    <MixedGraph data={data4.data} />
                                )}
                            </Loading>
                        </div>
                        <div className={styles.right}>
                            {/* <div className={styles.top}>
                                <div className={styles.title}>
                                    <div className={styles.titleName}>
                                        <img src={icon} alt="" />
                                        功率因数
                                    </div>
                                </div>
                                <Loading show={data5.isLoading} hiddenShade={true}>
                                    {isEmpty(data5.data) ? (
                                        <EmptyView description={emptyTest()} />
                                    ) : (
                                    <OnlyBarChart data={data5.data} />
                                    )}
                                </Loading>
                            </div> */}
                            <div className={styles.top}>
                                <div className={styles.title}>
                                    <div className={styles.titleName}>
                                        <img src={icon} alt="" />
                                        三相不平衡
                                    </div>
                                </div>
                                <Loading show={data6.isLoading} hiddenShade={true}>
                                    {/* {isEmpty(data6.data) ? (
                                        <EmptyView description={emptyTest()} />
                                    ) : ( */}
                                    <LineChart
                                        data={data6.data}
                                        company={'%'}
                                        colors={[
                                            {
                                                rgb: 'rgba(23, 195, 192)',
                                                rgbOpacity: 'rgba(23, 195, 192, 0.1)',
                                                color: '#17C3C0',
                                            },
                                            {
                                                rgb: 'rgba(67,65,159)',
                                                rgbOpacity: 'rgba(67,65,159,0.1)',
                                                color: '#43419F',
                                            },
                                        ]}
                                    />
                                    {/* )} */}
                                </Loading>
                            </div>
                            <Harmonic />
                        </div>
                    </div>
                    <div className={styles.contentBottom}>
                        <div className={styles.center}>
                            <div className={styles.title}>
                                <div className={styles.titleName}>
                                    <img src={icon} alt="" />
                                    告警趋势
                                </div>
                            </div>
                            <Loading show={data9.isLoading} hiddenShade={true}>
                                {/* {isEmpty(data9.data) ? (
                                    <EmptyView description={emptyTest()} />
                                ) : ( */}
                                <LineChart2 data={data9.data} />
                                {/* )} */}
                            </Loading>
                        </div>
                        <div className={styles.leftLine}>
                            <div className={styles.title} style={{ marginLeft: 0 }}>
                                <div className={styles.titleName}>
                                    <img src={icon} alt="" />
                                    告警排行
                                </div>
                            </div>
                            <Loading show={data8.isLoading} hiddenShade={true}>
                                {isEmpty(data8.data) ? (
                                    <EmptyView description={emptyTest()} />
                                ) : (
                                    <div className={styles.swiper}>
                                        <RankAlarm data={data8.data} />
                                    </div>
                                )}
                            </Loading>
                        </div>

                        <div className={styles.right}>
                            <div className={styles.title} style={{ marginLeft: 0 }}>
                                <div className={styles.titleName}>
                                    <img src={icon} alt="" />
                                    告警信息
                                </div>
                                {/* <Switch defaultChecked /> 目前我们没有语音，先暂时隐藏按钮*/}
                            </div>
                            <Loading show={data10.isLoading} hiddenShade={true}>
                                {isEmpty(data10.data) ? (
                                    <EmptyView description={emptyTest()} />
                                ) : (
                                    <div className={styles.swiper}>
                                        <SwiperNews data={data10.data} />
                                    </div>
                                )}
                            </Loading>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    );
};

//告警信息轮流循环播放组件
const SwiperNews = ({ data }) => {
    return (
        <Carousel dotPosition="left" dots={false} infinite={data.length > 6} autoplay={data.length > 6}>
            {map(data, (item, idx) => {
                return (
                    <div key={idx} className={styles.swiperItem}>
                        <div className={styles.message} title={item.location}>
                            {item.location}
                        </div>
                        <div className={styles.statusAlarm}>
                            <span className={styles.alarm}>{alarmLevel[item.grade]}</span>
                            <span className={styles.status}>{dataConvert[item.handler_status]}</span>
                        </div>
                    </div>
                );
            })}
        </Carousel>
    );
};

//告警排行组件
const RankAlarm = ({ data }) => {
    const max = useMemo(() => {
        return data[0].number;
    }, [data]);
    return (
        <Carousel dotPosition="left" dots={false} infinite={data.length > 6} autoplay={data.length > 6}>
            {map(data, (item, idx) => {
                return (
                    <div className={styles.rankContainer} key={idx}>
                        <div className={styles.rankItem}>
                            <div style={{ width: '30%' }} className={styles.text}>
                                <span
                                    style={{ display: 'inline-block', width: '60%', verticalAlign: 'middle' }}
                                    className={styles.text}
                                    title={item.name}
                                >
                                    {item.name}
                                </span>
                                &nbsp;&nbsp;No.{idx + 1}
                            </div>
                            <div className={styles.line}>
                                <div className={styles.lineGradient} style={{ width: (item.number / max) * 100 + '%' }}>
                                    <div className={styles.value}>{item.number}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </Carousel>
    );
};

/**
 * 谐波轮播组件
 * @returns
 */
const Harmonic = () => {
    const [dataCurrent, setDataCurrent] = useState({});
    const [dataVoltage, setDataVoltage] = useState({});
    //const [active, setActive] = useState(1); //初始化
    useEffect(() => {
        setDataVoltage([
            {
                name: '电压谐波',
                data: [
                    { time: '00:00', amount: 49.18 },
                    { time: '01:00', amount: '0.00' },
                    { time: '02:00', amount: 12 },
                    { time: '03:00', amount: 32 },
                    { time: '04:00', amount: '0.00' },
                    { time: '05:00', amount: '0.00' },
                    { time: '06:00', amount: 89.18 },
                    { time: '07:00', amount: '0.00' },
                    { time: '08:00', amount: '0.00' },
                    { time: '09:00', amount: 12 },
                    { time: '10:00', amount: 89.18 },
                    { time: '11:00', amount: '0.00' },
                    { time: '12:00', amount: 22 },
                    { time: '13:00', amount: '0.00' },
                    { time: '14:00', amount: 89.18 },
                    { time: '15:00', amount: 16.03 },
                    { time: '16:00', amount: 7.52 },
                    { time: '17:00', amount: 8.49 },
                    { time: '18:00', amount: 40.37 },
                ],
            },
        ]);
        setDataCurrent([
            {
                name: '谐波电流',
                data: [
                    { time: '00:00', amount: 89.18 },
                    { time: '01:00', amount: 12.2 },
                    { time: '02:00', amount: 23 },
                    { time: '03:00', amount: 34 },
                    { time: '04:00', amount: 13 },
                    { time: '05:00', amount: '0.00' },
                    { time: '06:00', amount: '0.00' },
                    { time: '07:00', amount: '0.00' },
                    { time: '08:00', amount: '0.00' },
                    { time: '09:00', amount: 11 },
                    { time: '10:00', amount: '0.00' },
                    { time: '11:00', amount: 23 },
                    { time: '12:00', amount: '0.00' },
                    { time: '13:00', amount: '0.00' },
                    { time: '14:00', amount: '0.00' },
                    { time: '15:00', amount: 16.03 },
                    { time: '16:00', amount: 7.52 },
                    { time: '17:00', amount: 8.49 },
                    { time: '18:00', amount: 40.37 },
                ],
            },
        ]);
    }, []);

    // 获取包裹容器
    const container = useRef(null);

    // var timer;
    //封装定时器函数
    // const startAutoPlay = () => {
    //     timer = setInterval(function () {
    //         for (var i = 0; i < 2; i++) {
    //             var j = 0;
    //             var arr5 = [1, 3, 2];
    //             setTimeout(() => {
    //                 setActive(arr5[j++]);
    //             }, i * 6000);
    //         }
    //     }, 12000);
    // };

    //封装清除定时器函数
    // const stopAutoPlay = () => {
    //     if (timer) {
    //     clearInterval(timer);
    //     }
    // };

    useEffect(() => {
        // startAutoPlay()
    }, []);

    return (
        <div ref={container} className={styles.bottom} style={{ marginTop: '0.8rem' }}>
            <div className={styles.title} style={{ position: 'relative', zIndex: 99999 }}>
                <div className={styles.titleName}>
                    <img src={icon} alt="" />
                    谐波
                </div>
                <div
                    style={{
                        marginTop: '0.2rem',
                        width: '80%',
                        textAlign: 'center',
                        overflow: 'hidden',
                        position: 'relative',
                        zIndex: 99999,
                    }}
                >
                    {/* <Button
                        style={{
                            background: active === 1 ? '#1D5A82' : '#163548',
                            border: 'none',
                            borderRadius: '5px',
                            color: active === 1 ? '#fff' : '#acb6c3',
                            textAlign: 'center',
                            cursor: 'pointer',
                            height: '27px',
                            lineHeight: '18px',
                        }}
                        onClick={() => {
                            setActive(1);
                        }}
                    >
                        谐波电流
                    </Button> */}
                    {/* <Button
                        style={{
                            background: active === 3 ? '#1D5A82' : '#163548',
                            border: 'none',
                            marginLeft: '1rem',
                            borderRadius: '5px',
                            color: active === 3 ? '#fff' : '#acb6c3',
                            textAlign: 'center',
                            cursor: 'pointer',
                            height: '27px',
                            lineHeight: '18px',
                        }}
                        onClick={() => {
                            setActive(3);
                        }}
                    >
                        谐波电压
                    </Button> */}
                </div>
            </div>
            {/* <Loading show={data6.isLoading} hiddenShade={true}> */}
            {/* {isEmpty(data6.data) ? (
                <EmptyView description={emptyTest()} />
            ) : ( */}
            {/* <LineChart
                data={active === 1 ? dataCurrent : dataVoltage}
                colors={[
                    {
                        rgb: 'rgba(100,183,236,0.5)',
                        rgbOpacity: 'rgba(91,181,237,0.1)',
                        color: '#5BB5ED',
                    },
                    {
                        rgb: 'rgba(254,180,74,0.7)',
                        rgbOpacity: 'rgba(254,180,74, 0.1)',
                        color: '#FEB44A',
                    },
                ]}
                company={active === 1 ? 'A' : 'V'}
            /> */}
            {/* )} */}
            {/* </Loading> */}
            <Carousel
                autoplay
                autoplaySpeed={60000} //60s后切换一次
                dotPosition={'top'}
                customPaging={i => <button>{i === 0 ? '谐波电流' : '电压谐波'}</button>}
            >
                {/* <Loading show={data6.isLoading} hiddenShade={true}> */}
                {/* {isEmpty(data6.data) ? (
                <EmptyView description={emptyTest()} />
            ) : ( */}
                <LineChart
                    data={dataCurrent}
                    colors={[
                        {
                            rgb: 'rgba(254,180,74,0.7)',
                            rgbOpacity: 'rgba(252,180,74, 0.4)',
                            color: '#FEB44A',
                        },
                        {
                            rgb: 'rgba(100,183,236,0.5)',
                            rgbOpacity: 'rgba(91,183,236,0.5)',
                            color: '#5BB5ED',
                        },
                    ]}
                    company={'A'}
                    height={'20vh'}
                />
                {/* )} */}
                {/* </Loading> */}
                {/* <Loading show={data6.isLoading} hiddenShade={true}> */}
                {/* {isEmpty(data6.data) ? (
                <EmptyView description={emptyTest()} />
            ) : ( */}
                <LineChart
                    data={dataVoltage}
                    colors={[
                        {
                            rgb: 'rgba(100,183,236,0.5)',
                            rgbOpacity: 'rgba(95,181,236,0.3)',
                            color: '#5BB5ED',
                        },
                        {
                            rgb: 'rgba(254,180,74,0.7)',
                            rgbOpacity: 'rgba(254,180,74, 0.1)',
                            color: '#FEB44A',
                        },
                    ]}
                    company={'V'}
                    height={'20vh'}
                />
                {/* )} */}
                {/* </Loading> */}
            </Carousel>
        </div>
    );
};
