/*
 * @Descripttion:柱状图组件 (动态切换)
 * @Author: lipei
 * @Date: 2021-16-Th 09:46:06
 * @Last Modified by:   lipei
 * @Last Modified time: 2021-16-Th 09:46:06
 */
import React, { Component } from 'react';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts';
import { unitControl } from '../../../util/function-util';

/**
 * 企业概况中的电流
 */

export default class EchartView extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    generateChartOption = areaMode => {
        return [
            {
                data: this.props.yData,
                type: 'line',
                barWidth: 15,
                barMinWidth: 15,
                name: this.props.names,
                animation: true,
                showBackground: true,
                itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        { offset: 0, color: '#ACF7EF' },
                        { offset: 0.5, color: '#8FEBE5' },
                        { offset: 1, color: '#0FCCC5' },
                    ]),
                    //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
                    bordRadius: [10, 10, 0, 0],
                    borderRadius: [10, 10, 0, 0],
                },
                emphasis: {
                    bordRadius: [10, 10, 0, 0],
                    borderRadius: [10, 10, 0, 0],
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            { offset: 0, color: '#0FCCC5' },
                            { offset: 0.7, color: '#8FEBE5' },
                            { offset: 1, color: '#ACF7EF' },
                        ]),
                    },
                },
                areaStyle:
                    areaMode === true
                        ? {
                              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                  {
                                      offset: 0,
                                      // color: 'rgb(255, 158, 68)'
                                      color: '#8FEBE5',
                                  },
                                  {
                                      offset: 1,
                                      // color: 'rgb(255, 70, 131)'
                                      color: '#ACF7EF',
                                  },
                              ]),
                          }
                        : undefined,
                barGap: '0%',
            },
        ];
    };

    getOption = () => {
        let { yData, xTime, names, top, bottom } = this.props;
        let dataZoom_start;
        if (yData) {
            if (yData.length > 30) {
                dataZoom_start = (150 / yData.length) * 100;
            } else {
                dataZoom_start = 0;
            }
        }
        let option = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#6a7985',
                    },
                },
            },
            grid: {
                left: '5%',
                top: '15%',
                right: '1%',
                bottom: bottom || '10%',
                containLabel: true,
            },
            toolbox: {
                //可视化的工具箱
                show: true,
                showTitle: true,
                orient: 'horizontal',
                itemGap: 15,
                itemSize: 16,
                zlevel: 10,
                z: 2,
                top: top || '5',
                right: '8',
                feature: {
                    // dataView: { show: true, readOnly: false },
                    // restore: { show: true, title: '还原' },
                    magicType: {
                        show: true,
                        type: ['bar','line'],
                        title: { bar: '切换为柱状图', line: '切换为折线图' },
                        icon: {
                            line:
                                 'path://M1006.948898 1023.953728H3.123503V13.881609a13.881609 13.881609 0 1 1 27.763217 0v982.355174h976.062178a13.881609 13.881609 0 0 1 0 27.763217z M168.546006 703.288568a23.136014 23.136014 0 0 1-14.15924-41.644826l257.920289-199.987709 249.868956 140.111703 215.673927-215.859015a23.136014 23.136014 0 1 1 33.315861 32.066516l-27.763218 28.873746-213.406597 212.388613-253.524447-142.193945-233.720018 181.201265a23.136014 23.136014 0 0 1-14.205513 5.043652z',
                            bar:
                                'path://M1006.948898 1023.953728H3.123503V13.881609a13.881609 13.881609 0 1 1 27.763217 0v982.355174h976.062178a13.881609 13.881609 0 1 1 0 27.763217z M168.592279 893.744239a23.136014 23.136014 0 0 1-23.136015-23.136015v-280.130863a23.136014 23.136014 0 0 1 46.272029 0v280.130863a23.136014 23.136014 0 0 1-23.136014 23.136015z M662.962635 893.744239a23.136014 23.136014 0 0 1-23.136014-23.136015v-280.130863a23.136014 23.136014 0 0 1 46.272029 0v280.130863a23.136014 23.136014 0 0 1-23.136015 23.136015z M415.916273 912.206778a23.136014 23.136014 0 0 1-23.136014-23.136014V393.358518a23.136014 23.136014 0 0 1 46.272029 0v495.712246a23.136014 23.136014 0 0 1-23.136015 23.136014z M910.379174 912.25305a23.136014 23.136014 0 0 1-23.136014-23.136014v-417.928966a23.136014 23.136014 0 0 1 46.272029 0v417.928966a23.136014 23.136014 0 0 1-23.136015 23.136014z',
                        },
                    },
                    // myTool2: {
                    //           show: true,
                    //           title: '切换为折线图',
                    //           icon:
                    //               'path://M1006.948898 1023.953728H3.123503V13.881609a13.881609 13.881609 0 1 1 27.763217 0v982.355174h976.062178a13.881609 13.881609 0 0 1 0 27.763217z M168.546006 703.288568a23.136014 23.136014 0 0 1-14.15924-41.644826l257.920289-199.987709 249.868956 140.111703 215.673927-215.859015a23.136014 23.136014 0 1 1 33.315861 32.066516l-27.763218 28.873746-213.406597 212.388613-253.524447-142.193945-233.720018 181.201265a23.136014 23.136014 0 0 1-14.205513 5.043652z',
                    //           onclick: () => {
                    //                     const echartInstance = this.echarts.getEchartsInstance();
                    //                     echartInstance.getOption({
                    //                         toolbox: { iconStyle: { color: '#64D399' } },
                    //                         emphasis: {
                    //                             color: '#64D399',
                    //                             iconStyle: {
                    //                                 color: '#64D399',
                    //                                 borderColor: '#64D399',
                    //                             },
                    //                             borderColor: '#64D399',
                    //                         },
                    //                     });
                    //                     echartInstance.setOption(
                    //                         {
                    //                             series: this.generateChartOption(false),
                    //                         },
                    //                         {
                    //                             replaceMerge: ['series'],
                    //                         },
                    //                     );
                    //                     // showLine = true;
                    //                 },
                    //       },
                    // myTool1:{
                    //           show: true,
                    //           title: '切换为面积图',
                    //           icon:
                    //               'path://M637.6 381.7L495.8 493.1c-4.1 3.1-9.8 3.6-13.9 1l-154.7-84.6c-4.6-2.6-10.8-2.1-14.4 1.5L162.2 547.2v153.1l150-133.5c3.6-3.6 9.8-4.1 14.4-1.5l177.9 96.4c4.1 2.1 9.3 2.1 12.9-0.5l144.4-91.3c1-0.5 2.6-1.5 3.6-1.5l294.4-72.7V301.8l-317.6 77.3c-1.5 0.5-3.1 1.6-4.6 2.6z M662.3 622.2l-142.8 91.3c-3.6 2.6-8.8 2.6-12.9 0.5l-179.9-96.4c-4.6-2.6-10.8-2.1-14.4 1.5 0 0-150.5 133.5-150.5 136.6v111.4h797.6V545.9l-293.9 74.8-3.2 1.5z M64 144.2c0-1.3 0.5-2.6 1.5-3.6s2.3-1.5 3.6-1.5H101c1.3 0 2.6 0.5 3.6 1.5s1.5 2.3 1.5 3.6v778.9H955c1.3 0 2.6 0.5 3.6 1.5s1.5 2.3 1.5 3.6v31.9c0 1.3-0.5 2.6-1.5 3.6s-2.3 1.5-3.6 1.5H64v-821z',
                    //           onclick: () => {
                    //                     const echartInstance = this.echarts.getEchartsInstance();
                    //                     echartInstance.setOption(
                    //                         {
                    //                             series: this.generateChartOption(true),
                    //                         },
                    //                         {
                    //                             replaceMerge: ['series'],
                    //                         },
                    //                     );
                    //                     // showLine = true;
                    //                 },
                    //       },
                    saveAsImage: {
                        show: true,
                        title: '保存为图片',
                        icon:
                            'path://M505.593445 831.028187l-145.048047-145.139244a22.799127 22.799127 0 0 1 32.237966-32.237966l112.810081 112.901278 112.901278-112.901278a22.799127 22.799127 0 0 1 32.237966 32.237966z M1028.787817 1024H0v-399.805495a13.679476 13.679476 0 1 1 27.358953 0v372.492141h974.115509v-372.492141a13.679476 13.679476 0 1 1 27.358953 0z M505.593445 804.763593a22.799127 22.799127 0 0 1-22.799127-22.799128V22.799127a22.799127 22.799127 0 0 1 45.598254 0v759.165338a22.799127 22.799127 0 0 1-22.799127 22.799128z',
                    },
                },
                emphasis: {
                    color: '#64D399',
                    iconStyle: {
                        color: '#64D399',
                        borderColor: '#64D399',
                    },
                    borderColor: '#64D399',
                },
                // iconStyle: {
                //     color: '#64D399',
                //     borderColor: '#64D399',
                // },
            },
            legend: {
                top: '4%',
                left: '70%',
                // x: 'right',
                itemWidth: 12, // 设置宽度
                itemHeight: 8, // 设置高度
                icon: 'circle',
                data: [
                    {
                        name: names,
                        orient: 'horizontal',
                        color: '#c7c7c7',
                        fontSize: 12,
                    },
                ],
                color: '#c7c7c7',
                fontSize: 12,
                show: false,
            },
            xAxis: {
                type: 'category',
                //minInterval: 1, //设置成1保证坐标轴分割刻度显示成整数。
                min: 0, // 起始
                data: xTime,
                axisLabel: {
                    inside: false,
                    color: '#000',
                    fontSize: '12',
                    itemSize: '',
                    //interval: 0, //使x轴文字显示全
                },
                splitLine: {
                    show: false,
                    lineStyle: {
                        type: 'dotted',
                        opacity: '0.3',
                        color: '#666',
                    },
                },
            },
            yAxis: {
                name: unitControl[names] ? '单位:(' + unitControl[names] + ')' : '',
                type: 'value',
                //minInterval: 1, //设置成1保证坐标轴分割刻度显示成整数。
                min: 0, // 起始
                // max: 1, //最大
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#666',
                    },
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        type: 'dotted',
                        opacity: '0.3',
                        color: '#666',
                    },
                },
            },
            dataZoom: [
                {
                    type: 'inside', //使鼠标在图表中时滚轮可用
                    show: true,
                    start: dataZoom_start,
                    end: 100,
                },
            ],
            animationType: 'scale',
            animationEasing: 'elasticOut',
            animationDelay: function (idx) {
                return Math.random() * 200;
            },
            animationDuration: 1000,
            series: [
                {
                    data: yData,
                    type: 'bar',
                    barWidth: 15,
                    barMinWidth: 15,
                    name: names,
                    animation: true,
                    showBackground: true,
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            { offset: 0, color: '#ACF7EF' },
                            { offset: 0.5, color: '#8FEBE5' },
                            { offset: 1, color: '#0FCCC5' },
                        ]),
                        //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
                        bordRadius: [10, 10, 0, 0],
                        borderRadius: [10, 10, 0, 0],
                    },
                    emphasis: {
                        bordRadius: [10, 10, 0, 0],
                        borderRadius: [10, 10, 0, 0],
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: '#0FCCC5' },
                                { offset: 0.7, color: '#8FEBE5' },
                                { offset: 1, color: '#ACF7EF' },
                            ]),
                        },
                    },
                    barGap: '0%',
                },
            ],
        };
        return option;
    };
    render() {
        const option = this.getOption();
        return (
            <ReactEcharts
                ref={ref => (this.echarts = ref)}
                option={option}
                style={this.props.style}
                id="myChart"
                // className='react_for_echarts'
            />
        );
    }
}
