import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import {
    Button,
    Col,
    Form,
    Row,
    Table,
    Popconfirm,
    Input,
    Select,
    Checkbox,
    Radio,
    message,
    TreeSelect,
    TimePicker,
    DatePicker,
    Typography,
} from 'antd';
import moment from 'moment';
import { omitBy, isUndefined, map, isNull, trim } from 'lodash';
import { BreadcrumbCustom } from '../../../components/BreadcrumbCustom';
import { GlobalModal } from '../../../components/GlobalModal';
import RouteHelper from '../../../tools/RouteHelper';
import { MyIcon } from '../../../util/iconfont'
import { BASE_URL, dataMode, mode, patrol, dateDatas, date, week, weekData } from '../../../global';
import { timeFormat, dateFormat } from '../../../util/function-util';
import { disabledDate } from '../../../util/timeUtil';
import {
    getAlarmTree,
    getPatrolRecord,
    getPatrolRecordDetail,
    addPatrolRecord,
    editPatrolRecord,
    removePatrolRecord,
    getPatrolUser,
    removePatrolRecordBatch,
} from '../../../api/webServe';

const FormItem = Form.Item;
const Option = Select.Option;
const { SHOW_PARENT } = TreeSelect;
const FormItemLayout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 19 },
};
/**
 *
 * @returns 巡检管理 --- 巡检计划
 */
export const PatrolRecord = () => {
    return (
        <div className="container clearfix">
            <BreadcrumbCustom paths={['首页', '巡检管理', '巡检计划']} className="rumbCustom" />
            <div className="row clearfix" style={{ backgroundColor: 'transparent', margin: '1rem', padding: '0' }}>
                <HiddenTable />
            </div>
        </div>
    );
};

const HiddenTable = () => {
    const [form] = Form.useForm();
    const childRef = useRef(); //弹框
    const formChildrenRef2 = useRef(); //隐患表单
    const [modalType, setModalType] = useState('add'); //edit add assign
    const [data, setData] = useState({ data: [], isLoading: true }); //隐患列表数据
    const [count, setCount] = useState(0); //数据总条数
    const [page, setPage] = useState(1); //当前页
    const [page_size, setPageSize] = useState(10); //当前页显示条数
    const [searchParams, setSearchParams] = useState({}); //搜索
    const [selectedRowKeys, setSelectedRowKeys] = useState([]); //全选
    const [patrolDetail, setPatrolDetail] = useState(null);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const getList = () => {
        const params = omitBy(searchParams, value => {
            if (isNaN(value) || value === '') {
                return isUndefined(value) || isNull(value) || value === '' || trim(value) === '';
            } else {
                return isNull(value);
            }
        });
        setData({ data: [], isLoading: true });
        getPatrolRecord({ page, page_size, ...params })
            .then(result => {
                setData({ data: result.data, isLoading: false });
                setCount(result.count);
            })
            .catch();
    };
    const getDetail = id => {
        getPatrolRecordDetail({ id })
            .then(result => {
                setModalType('edit');
                setPatrolDetail(result.data);
                childRef.current.showModal();
            })
            .catch();
    };
    useEffect(() => {
        getList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams, page, page_size]);
    // 巡检计划表导出
    const exportData = () => {
        const params = omitBy(searchParams, value => {
            if (isNaN(value) || value === '') {
                return isUndefined(value) || isNull(value) || value === '' || trim(value) === '';
            } else {
                return isNull(value);
            }
        });
        getPatrolRecord({ page, page_size, ...params, download: true })
            .then(result => {
                window.open(BASE_URL + result.data.url);
            })
            .catch();
    };
    //搜索表单
    const renderForm = () => {
        return (
            <Form
                layout="horizontal"
                form={form}
                onFinish={value => {
                    setSearchParams(value);
                }}
                initialValues={{ manner: '', cycle: '' }}
            >
                <Row style={{ marginLeft: '0px', marginRight: '0px', display: 'flex', flexWrap: 'nowrap',  justifyContent: 'space-between', width: '100%'}}>
                    <Col span={4} offset={8}>
                        <FormItem
                            name="name"
                            label="名称"
                            rules={[
                                { required: false, message: '请选择名称' },
                                {
                                    pattern: /^(?!\s)(?!.*\s$)/,
                                    message: '名称不允许有空格!',
                                },
                            ]}
                            getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}
                        >
                            <Input placeholder="请输入名称" />
                        </FormItem>
                    </Col>
                    <Col span={4}>
                        <FormItem name="manner" label="方式" rules={[{ required: false, message: '请选择方式' }]}>
                            <Select placeholder="请选择" allowClear={true} virtual={false}>
                                <Option value={''} key={'idx'}>
                                    全部
                                </Option>
                                {mode.map(item => {
                                    return (
                                        <Option value={item.id} key={item.id}>
                                            {item.value}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </FormItem>
                    </Col>
                    <Col span={4}>
                        <FormItem name="cycle" label="周期" rules={[{ required: false, message: '请选择周期' }]}>
                            <Select placeholder="请选择" allowClear={true} virtual={false}>
                                <Option value={''} key={'idx'}>
                                    全部
                                </Option>
                                {dateDatas.map(item => {
                                    return (
                                        <Option value={item.id} key={item.id}>
                                            {item.value}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </FormItem>
                    </Col>
                    <Col style={{ textAlign: 'right', paddingRight: '0px' }}>
                        <FormItem>
                            <Button type="primary" htmlType="submit">
                                搜索
                            </Button>
                        </FormItem>
                    </Col>
                    <Col style={{ textAlign: 'right', paddingRight: '0px' }}>
                        <FormItem>
                            <Button
                                type="default"
                                style={{
                                    color: '#fff',
                                    backgroundColor: '#439BE3',
                                    borderColor: '#439BE3',
                                }}
                                onClick={exportData}
                            >
                                导出
                            </Button>
                        </FormItem>
                    </Col>
                </Row>
            </Form>
        );
    };
    const columns = [
        {
            title: '序号',
            dataIndex: 'id',
            key: 'id',
            width: 100, //宽度
            render: (text, record, index) => `${index + 1}`,
        },
        {
            title: '名称',
            dataIndex: 'name',
            render: text => (
                <p
                    style={{
                        minWidth: '8rem',
                        maxWidth: '12rem',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                    }}
                    title={text}
                >
                    {text}
                </p>
            ),
        },
        {
            title: '周期',
            dataIndex: 'cycle',
            render: text => (
                <p
                    title={text}
                    style={{
                        minWidth: '3rem',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                    }}
                >
                    {text === null ? '' : date[text]}
                </p>
            ),
        },
        {
            title: '巡检日期',
            dataIndex: 'date',
            render: (text, record) => (
                <p
                    title={text}
                    style={{
                        minWidth: '3.5rem',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                    }}
                >
                    {text === null || text === 0 ? '-' : record.cycle === 1 ? week[text] : text + '号'}
                </p>
            ),
        },
        {
            title: '方式',
            dataIndex: 'manner',
            render: text => (
                <p
                    title={text}
                    style={{
                        minWidth: '3rem',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                    }}
                >
                    {text === null ? '' : dataMode[text]}
                </p>
            ),
        },
        {
            title: '巡检人',
            dataIndex: 'user',
            render: text => (
                <p
                    style={{
                        minWidth: '3rem',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                    }}
                    title={text === null ? '-' : text}
                >
                    {text === null ? '-' : text}
                </p>
            ),
        },
        {
            title: '开始时间',
            dataIndex: 'start_date',
            width: 200,
            render: text => <span>{text && text.slice(0, 10) + ' ' + text.slice(11, 19)}</span>,
        },
        {
            title: '结束时间',
            dataIndex: 'end_date',
            width: 200,
            render: text => <span>{text && text.slice(0, 10) + ' ' + text.slice(11, 19)}</span>,
        },
        {
            title: '操作',
            dataIndex: 'opera',
            width: 200,
            render: (text, record) => (
                <div className="opera">
                    <Typography.Link
                        className="opera"
                        // target="_blank"
                        // href={`/web/patrol/patrolRecord/readRecords?id=${record.id}`}
                    >
                        {/* <span
                            className="read"
                            onClick={() => {
                                RouteHelper.locationTo('/web/patrol/patrolRecord2/readRecords', { id: record.id });
                            }}
                        >
                            查看
                        </span> */}
                        <MyIcon
                            type={'iconchakan1'}
                            style={{ fontSize: '1rem', verticalAlign: 'middle', color: 'rgba(0, 0, 0, 0.85)' }}
                            title={'查看'}
                            onClick={() => {
                                RouteHelper.locationTo('/web/patrol/patrolRecord2/readRecords', { id: record.id });
                            }}
                        />
                    </Typography.Link>
                    {/* <span
                        className="edit"
                        onClick={() => {
                            getDetail(record.id);
                            setConfirmLoading(false)
                        }}
                    >
                        编辑
                    </span> */}
                    <MyIcon
                        type={'icona-zu6914'}
                        style={{ fontSize: '1rem', verticalAlign: 'middle', color: 'rgba(0, 0, 0, 0.85)', margin: '0 0.6rem' }}
                        title={'编辑'}
                        onClick={() => {
                            getDetail(record.id);
                            setConfirmLoading(false)
                        }}
                    />
                    {/* <span className="off">
                        <Popconfirm
                            title="确定要关闭吗?"
                            onConfirm={() => {
                                let id = record.id;
                                changeAlarm({ handler_status: [id] })
                                    .then(result => {
                                        message.success('关闭成功');
                                       getList()
                                    })
                                    .catch();
                            }}
                        >
                            {' '}
                            关闭{' '}
                        </Popconfirm>
                    </span> */}
                    {/* <span className="del"> */}
                        <Popconfirm
                            title="确定要删除吗?"
                            onConfirm={() => {
                                removePatrolRecord(record.id)
                                    .then(result => {
                                        message.success('删除成功');
                                        getList();
                                    })
                                    .catch();
                            }}
                        >
                            <MyIcon
                                type='iconshanchu1'
                                style={{ color: 'rgba(0, 0, 0, 0.85)', fontSize: '1rem', verticalAlign: 'middle' }}
                                title={'删除'}
                            />
                        </Popconfirm>
                    {/* </span> */}
                </div>
            ),
        },
    ];
    /**
     *  表格分页
     */
    const pagination = {
        pageSize: page_size, //每页的条数
        current: page, //当前页
        total: count, //总数据
        showSizeChanger: true,
        onChange: page => {
            setPage(page);
        }, //点击分页号时触发的方法
        onShowSizeChange: (current, pageSize) => {
            setPageSize(pageSize);
        }, //pageSize 变化的回调
        hideOnSinglePage: true, //为true则数据条数不满或刚好一页，则隐藏分页器
        pageSizeOptions: ['10', '15', '20', '30', '50', '100'], // 分页类别
        showTotal: total => `共 ${total} 条`,
    };

    /**
     *  勾选时触发的方法 (全选)
     * @param e
     */
    const onSelectChange = selectedRowKeys => {
        setSelectedRowKeys(selectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const onCheckAllChange = e => {
        setSelectedRowKeys(e.target.checked ? data.data.map((item, index) => item.id) : []);
    };
    return (
        <div
            className="tableBox"
            style={{
                width: '100%',
                height: '100%',
                backgroundColor: '#fff',
                borderRadius: '10px',
                padding: '1rem',
                boxSizing: 'border-box',
            }}
        >
            {/* 新增、搜索、导出按钮功能 */}
            <Row gutter={16}>
                <Col span={2}>
                    <Button
                        onClick={() => {
                            setModalType('add');
                            childRef.current.showModal();
                            setConfirmLoading(false)
                        }}
                        type="primary"
                        style={{ marginRight: '10px', marginBottom: '10px' }}
                    >
                        新增
                    </Button>
                </Col>
                <Col span={22}>{renderForm()}</Col>
            </Row>
            {/* 表格 */}
            <Table
                rowKey={record => record.id}
                columns={columns}
                dataSource={data.data}
                bordered={true}
                loading={data.isLoading}
                scroll={{ x: '100%' }}
                pagination={pagination}
                rowSelection={rowSelection}
                locale={{
                    cancelSort: '点击取消排序',
                    triggerAsc: '点击升序',
                    triggerDesc: '点击降序',
                    filterConfirm: '确定',
                    filterReset: '重置',
                    emptyText: '暂无数据',
                }}
            />
            {/* 批量操作 */}
            <div
                style={
                    data.data.length > 0
                        ? { display: 'block', position: 'inherit', marginTop: '1rem' }
                        : { display: 'none' }
                }
            >
                <Checkbox
                    indeterminate={
                        //负责样式控制
                        data.data.length !== selectedRowKeys.length && selectedRowKeys.length !== 0
                    }
                    onChange={onCheckAllChange} //勾选时触发的方法
                    checked={data.data.length === selectedRowKeys.length} //勾选还是未勾选  options：所有的勾选子项，list
                >
                    全选
                </Checkbox>
                <Radio.Group style={{ marginBottom: 16 }}>
                    <Radio.Button value="del">
                        <Popconfirm
                            title={
                                selectedRowKeys.length === 0
                                    ? '未选中'
                                    : `确定要将${selectedRowKeys.length}项,全部删除吗?`
                            }
                            onConfirm={() => {
                                removePatrolRecordBatch(selectedRowKeys)
                                    .then(() => {
                                        message.success('批量删除成功');
                                        getList();
                                        setSelectedRowKeys([]);
                                    })
                                    .catch(err => {
                                        message.error(err);
                                    });
                            }}
                        >
                            <span style={{ display: 'block' }}>删除</span>
                        </Popconfirm>
                    </Radio.Button>
                </Radio.Group>
            </div>
            <GlobalModal
                onOk={() => {
                    formChildrenRef2.current
                        .validateFields()
                        .then(value => {
                            setConfirmLoading(true)
                            const params = omitBy(value, val => {
                                return isUndefined(val) || isNull(val) || val === '' || trim(val) === '';
                            });
                            if (modalType === 'add') {
                                params.start_date =
                                    value.start_date === undefined || value.end_date === null
                                        ? null
                                        : dateFormat(new Date(value.start_date));
                                params.end_date =
                                    value.end_date === undefined || value.end_date === null
                                        ? null
                                        : dateFormat(new Date(value.end_date));
                                if (value.manner === 0) {
                                    params.start_time =
                                        value.times[0] === undefined ? null : timeFormat(new Date(value.times[0]));
                                    params.end_time =
                                        value.times[1] === undefined ? null : timeFormat(new Date(value.times[1]));
                                } else {
                                    params.start_time =
                                        value.start_time === undefined ? null : timeFormat(new Date(value.start_time));
                                }
                                addPatrolRecord(params)
                                    .then(() => {
                                        message.success('新增成功');
                                        getList();
                                        childRef.current.hiddenLoading();
                                        childRef.current.hiddenModal();
                                        // formChildrenRef.current.resetFields();
                                        setConfirmLoading(false)
                                    })
                                    .catch(() => {
                                        setConfirmLoading(false)
                                    });
                            }
                            if (modalType === 'edit') {
                                params.id = patrolDetail.id;
                                params.start_date =
                                    value.start_date === undefined || value.start_date === null
                                        ? null
                                        : dateFormat(new Date(value.start_date));
                                params.end_date =
                                    value.end_date === undefined || value.end_date === null
                                        ? null
                                        : dateFormat(new Date(value.end_date));
                                if (value.manner === 0) {
                                    params.start_time =
                                        value.times[0] === undefined ? null : timeFormat(new Date(value.times[0]));
                                    params.end_time =
                                        value.times[1] === undefined ? null : timeFormat(new Date(value.times[1]));
                                } else {
                                    params.start_time =
                                        value.start_time === undefined ? null : timeFormat(new Date(value.start_time));
                                }
                                if (params.cycle === 1 && params.date > 8) {
                                    message.error('请重新选择巡检日期');
                                } else {
                                    editPatrolRecord(params)
                                        .then(() => {
                                            message.success('编辑成功');
                                            childRef.current.showLoading();
                                            // childRef.current.hiddenLoading();
                                            childRef.current.hiddenModal();
                                            // formChildrenRef.current.resetFields();
                                            getList();
                                            setConfirmLoading(false)
                                        })
                                        .catch(() => {
                                            setConfirmLoading(false)
                                        });
                                }
                            }
                        })
                        .catch();
                }}
                onCancel={() => {
                    formChildrenRef2.current.resetFields();
                }}
                childRef={childRef}
                title={`${modalType === 'edit' ? '编辑' : '新增'}计划`}
                height={'94vh'}
                confirmLoading={confirmLoading}
            >
                <HiddenForm childRef={formChildrenRef2} patrolDetail={patrolDetail} type={modalType} />
            </GlobalModal>
        </div>
    );
};

//新增、编辑弹框内容显示
const HiddenForm = ({ childRef, patrolDetail, type }) => {
    const [form] = Form.useForm();
    useImperativeHandle(childRef, () => ({
        validateFields: form.validateFields,
        resetFields: form.resetFields,
    }));
    const [valueLocation, setValueLocation] = useState(null); //巡检区域
    const [permission, setPermission] = useState([]); //巡检区域
    const [valueMode, setValueMode] = useState(1); //巡检方式
    const [dataDate, setDataDate] = useState([]); //巡检日期列表数据
    const [date, setDate] = useState(); //巡检日期当前值
    const [cycle, setCycle] = useState(0); //巡检周期
    const [user, setUser] = useState([]); //巡检人
    const [getStartTime, setGetStartTime] = useState(type === 'edit' ? patrolDetail.start_date : null);
    //巡检区域事件
    const onChangeLocation = value => {
        setValueLocation(value);
    };
    const recursion = data => {
        return map(data, item => {
            return { ...item, key: item.id, title: item.name, children: recursion(item.children) };
        });
    };
    //巡检区域列表
    useEffect(() => {
        getAlarmTree()
            .then(result => {
                setPermission(recursion(result.data));
            })
            .catch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type]);
    //巡检人列表
    useEffect(() => {
        valueMode === 0 &&
            getPatrolUser()
                .then(result => {
                    setUser(result.data);
                })
                .catch();
    }, [valueMode]);
    //巡检区域
    const tProps = {
        treeData: permission,
        value: valueLocation,
        onChange: onChangeLocation,
        treeCheckable: true,
        showCheckedStrategy: SHOW_PARENT,
        placeholder: '请选择数据权限',
        style: {
            width: '100%',
        },
    };
    //巡检周期月
    const monthData = [];
    for (var i = 1; i < 32; i++) {
        monthData.push({ id: i, value: `${i}号` });
    }
    //巡检方式事件
    const onChangeMode = e => {
        setValueMode(e.target.value);
    };
    useEffect(() => {
        if (type === 'edit') {
            const formValue = {
                name: patrolDetail.name,
                cycle: patrolDetail.cycle,
                qr_code: patrolDetail.qr_code,
                date: patrolDetail.date && Number(patrolDetail.date),
                genre: patrolDetail.genre,
                manner: patrolDetail.manner,
                start_date:
                    patrolDetail.start_date === null
                        ? patrolDetail.start_date
                        : moment(patrolDetail.start_date, 'yyyy-MM-DD'),
                end_date:
                    patrolDetail.end_date === null
                        ? patrolDetail.end_date
                        : moment(patrolDetail.end_date, 'yyyy-MM-DD'),
                start_time:
                    patrolDetail.start_time === null
                        ? patrolDetail.start_time
                        : moment(patrolDetail.start_time, 'HH:mm'),
                end_time:
                    patrolDetail.end_time === null ? patrolDetail.end_time : moment(patrolDetail.end_time, 'HH:mm'),
                content: patrolDetail.content,
                remark: patrolDetail.remark,
                user_id: patrolDetail.user_id,
                // location: patrolDetail.location.region_name,
                location:
                    patrolDetail.location &&
                    (patrolDetail.location.map(item => {
                        return item.region_id;
                    }) ||
                        []),
                times: [
                    patrolDetail.start_time === null
                        ? patrolDetail.start_time
                        : moment(patrolDetail.start_time, 'HH:mm'),
                    patrolDetail.end_time === null ? patrolDetail.end_time : moment(patrolDetail.end_time, 'HH:mm'),
                ],
            };
            setDataDate(patrolDetail.cycle === 1 ? weekData : monthData);
            setCycle(patrolDetail.cycle);
            setValueMode(patrolDetail.manner);
            setDate(patrolDetail.date);
            form.setFieldsValue(formValue);
        } else {
            form.setFieldsValue({
                cycle: cycle,
                manner: 1,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, patrolDetail]);

    // const disabledStartDate = startValue => {
    //     //startValue参数会打印出多个，为打开当前时间选择面板下所有的时间。这个参数是一个moment类型的时间
    //     const endValue = patrolDetail.end_date;
    //     if (!startValue || !endValue) {
    //         //这里的意思是还没有选择开始时间和结束时间的时候返回false,也就是当前面板的每个时间都可以选择
    //         return false;
    //     }
    //     //你选择的开始时间不能大于你已经选择了的结束时间，满足这个条件的时间都会返回true，而返回值为true的时间都不能被选中
    //     return startValue.valueOf() > endValue.valueOf();
    // };
    
    /*
     * 结束时间- 禁止选择的日期
     *   1.当前以及大于当前天，
     *   2.新增时：选择的开始日期以及大于选择的开始日期，
     *   3.编辑时：开始日期以及大于开始日期/选择日期以及大于选择的日期
     */
    const disabledEndDate = current => {
        if (getStartTime === null) {
            return current && current <= moment().startOf('day');
        } else if (type === 'add' && getStartTime !== null) {
            return current && current <= getStartTime.valueOf();
        } else if (type === 'edit') {
            return current && current <= moment(getStartTime);
        }
    };
    return (
        <Form layout="horizontal" form={form} style={{ width: '80%', margin: '0 auto', paddingTop: '3rem' }}>
            <Row gutter={[32, 16]}>
                <Col span={11}>
                    <Row>
                        <Col span={24}>
                            <FormItem
                                label="计划名称"
                                name="name"
                                rules={[{ required: true, message: '请输入计划名称' }]}
                                getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}
                                {...FormItemLayout}
                            >
                                <Input placeholder="请输入计划名称" />
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <FormItem
                                name="genre"
                                label="巡检类型"
                                rules={[{ required: true, message: '请选择巡检类型' }]}
                                {...FormItemLayout}
                            >
                                <Select placeholder="请选择巡检类型" allowClear={true} virtual={false}>
                                    {patrol.map(item => {
                                        return (
                                            <Option value={item.id} key={item.id}>
                                                {item.value}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <FormItem
                                name="location"
                                label="巡检区域"
                                rules={[
                                    { required: true, message: '请选择区域' },
                                    {
                                        pattern: /^(?!\s)(?!.*\s$)/,
                                        message: '内容不允许有空格!',
                                    },
                                ]}
                                {...FormItemLayout}
                                // getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}
                            >
                                <TreeSelect {...tProps} />
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <FormItem
                                label="内容描述"
                                name="content"
                                rules={[
                                    { required: true, message: '请输入描述内容' },
                                    {
                                        pattern: /^(?!\s)(?!.*\s$)/,
                                        message: '内容不允许有空格!',
                                    },
                                ]}
                                getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}
                                {...FormItemLayout}
                            >
                                <Input.TextArea
                                    placeholder="请输入描述内容"
                                    allowClear
                                    autoSize={{ minRows: 3, maxRows: 6 }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <FormItem
                                label="   巡检备注"
                                name="remark"
                                rules={[
                                    {
                                        pattern: /^(?!\s)(?!.*\s$)/,
                                        message: '备注不允许有空格!',
                                    },
                                ]}
                                {...FormItemLayout}
                                getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}
                            >
                                <Input.TextArea
                                    placeholder="请输入备注"
                                    allowClear
                                    autoSize={{ minRows: 3, maxRows: 6 }}
                                />
                            </FormItem>
                        </Col>
                    </Row>
                </Col>
                <Col span={11} offset={2}>
                    <Row>
                        <Col span={24}>
                            <FormItem
                                label="巡检周期"
                                name="cycle"
                                rules={[{ required: true, message: '请输入巡检周期' }]}
                                {...FormItemLayout}
                            >
                                <Radio.Group
                                    onChange={e => {
                                        setCycle(e.target.value);
                                        setDataDate(e.target.value === 1 ? weekData : monthData);
                                        // setDate(e.target.value !== 0 ? '' : patrolDetail.date)
                                        form.setFieldsValue({
                                            date: null,
                                        });
                                    }}
                                    value={cycle}
                                    style={{display: 'flex', justifyContent: 'space-between', width: '224px'}}
                                >
                                    {dateDatas.map(item => {
                                        return (
                                            <Radio value={item.id} key={item.id}>
                                                {item.value}
                                            </Radio>
                                        );
                                    })}
                                </Radio.Group>
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            {cycle !== 0 && (
                                <FormItem
                                    name="date"
                                    label="巡检日期"
                                    rules={[{ required: true, message: '请选择巡检日期' }]}
                                    {...FormItemLayout}
                                >
                                    <Select
                                        value={date}
                                        virtual={false}
                                        placeholder={'请选择巡检日期'}
                                        defaultValue={date}
                                        style={{width: '220px'}}
                                    >
                                        {dataDate.map(week => {
                                            return (
                                                <Option value={week.id} key={week.id}>
                                                    {week.value}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </FormItem>
                            )}
                        </Col>
                        <Col span={24}>
                            <FormItem
                                label="开始时间"
                                name="start_date"
                                rules={[{ required: true, message: '请选择开始时间' }]}
                                {...FormItemLayout}
                            >
                                <DatePicker
                                    placeholder="请选择开始时间"
                                    disabledDate={disabledDate}
                                    onChange={value => setGetStartTime(value)}
                                    style={{width: '220px'}}
                                />
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <FormItem label="结束时间" name="end_date" {...FormItemLayout}>
                                <DatePicker placeholder="请选择结束时间" disabledDate={disabledEndDate} style={{width: '220px'}}/>
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <FormItem
                                name="manner"
                                label="巡检方式"
                                rules={[{ required: true, message: '请选择巡检方式' }]}
                                {...FormItemLayout}
                            >
                                <Radio.Group 
                                    onChange={onChangeMode} 
                                    value={valueMode} 
                                    style={{display: 'flex', justifyContent: 'space-between', width: '249px'}}
                                >
                                    {mode.map(item => {
                                        return (
                                            <Radio value={item.id} key={item.id}>
                                                {item.value}
                                            </Radio>
                                        );
                                    })}
                                    <Radio style={{visibility: 'hidden'}}>　　　</Radio>
                                </Radio.Group>
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            {valueMode === 0 && (
                                <FormItem
                                    name="user_id"
                                    label="巡检人"
                                    rules={[{ required: true, message: '请选择巡检人' }]}
                                    {...FormItemLayout}
                                >
                                    <Select virtual={false} placeholder={'请选择巡检人'} style={{width: '220px'}}>
                                        {user.map(item => {
                                            return (
                                                <Option value={item.id} key={item.id}>
                                                    {item.real_name}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </FormItem>
                            )}
                        </Col>
                        <Col span={24}>
                            {valueMode === 0 ? (
                                <FormItem
                                    label="巡检时间段"
                                    name="times"
                                    rules={[{ required: true, message: '请选择巡检时间段' }]}
                                    {...FormItemLayout}
                                >
                                    <TimePicker.RangePicker style={{width: '220px'}}/>
                                </FormItem>
                            ) : (
                                <FormItem
                                    label="巡检时间"
                                    name="start_time"
                                    rules={[{ required: true, message: '请选择巡检时间' }]}
                                    {...FormItemLayout}
                                >
                                    <TimePicker style={{width: '220px'}}/>
                                </FormItem>
                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    );
};
