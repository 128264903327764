/**
 * 固件管理分类列表
 */
import React, { useRef, useState, useEffect, useImperativeHandle, useMemo } from 'react';
import { Table, Popconfirm, Button, Form, Input, ConfigProvider, Select, Row, Col, message, Upload } from 'antd';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import zhCN from 'antd/es/locale/zh_CN';
import OSS from 'ali-oss';
import { BreadcrumbCustom } from '../../../components/BreadcrumbCustom';
import { CommonModal } from '../../../components/CommoModal';
import { omitBy, isUndefined, isNull, trim } from 'lodash';
import { MyIcon } from '../../../util/iconfont';
import { peakvalue } from '../../../util/function-util';
import ToastHelper from '../../../tools/ToastHelper';
import './adminTablePagination.less';
import {
    getFirmware,
    getDeviceDetails,
    addFirmware,
    delFirmware,
    getDeviceCategory,
    getImageKey,
} from '../../../api/adminServe';

const FormItem = Form.Item;

const HardwareFirmware = () => {
    return (
        <div className="container clearfix">
            <BreadcrumbCustom paths={['硬件管理', '固件管理']} className="rumbCustom" />
            <div className="row clearfix" style={{ width: '100%' }}>
                <DevicesTable />
            </div>
        </div>
    );
};

const DevicesTable = () => {
    const childRef = useRef();
    const formChildrenRef = useRef();
    const searchChildrenRef = useRef();
    const [modalType, setModalType] = useState('add'); //edit add
    const [data, setData] = useState({ data: [], isLoading: true });
    const [count, setCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [page_size, setPageSize] = useState(10);
    const ref = useRef(null);
    const [update, setUpdate] = useState(0);
    const [userId, setUserId] = useState();
    const [category, setCategory] = useState([]);
    const [searchParams, setSearchParams] = useState({});
    const [orderWay, setOrderWay] = useState(); //排序
    const [size, setSize] = useState(); //资源包大小
    // 获取固件列表数据
    const userListData = (page, pageSize) => {
        searchParams.order = orderWay !== null && orderWay !== undefined ? orderWay : undefined;
        searchParams.order_key = orderWay === 1 || orderWay === 0 ? 'category' : undefined;
        const params = omitBy(searchParams, value => {
            return isUndefined(value) || isNull(value) || value === '' || trim(value) === '';
        });
        setCurrentPage(page);
        setData({ data: [], isLoading: true });
        getFirmware({ page, page_size: pageSize ? pageSize : page_size, ...params })
            .then(result => {
                setData({ data: result.data, isLoading: false });
                setCount(result.count);
            })
            .catch(() => {
                setData({ data: [], isLoading: true });
            });
    };
    useEffect(() => {
        userListData(currentPage);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [update, searchParams, orderWay]);
    // 编辑详情
    const getDetail = userId => {
        getDeviceDetails(userId)
            .then(result => {
                formChildrenRef.current.setFieldsValue({
                    version: result.data.version,
                    category_id: result.data.category.id,
                    qr_code: result.data.qr_code,
                    service_start_time: result.service_start_time,
                    service_time: result.service_time,
                    // topic_id: result.data.topic_id,
                });
            })
            .catch();
    };
    useEffect(() => {
        modalType === 'edit' && getDetail(userId);
    }, [userId, modalType]);
    useEffect(() => {
        getDeviceCategory({ get_all: true })
            .then(result => {
                setCategory(result.data);
            })
            .catch();
    }, []);
    const columns = useMemo(() => {
        return [
            {
                title: '序号',
                dataIndex: 'id',
                key: 'id',
                width: 100, //宽度
                render: (text, record, index) => `${index + 1}`,
            },
            {
                title: '分类ID',
                dataIndex: 'category',
                sorter: true,
                render: (text, record) => (
                    <p
                        style={{
                            minWidth: '3rem',
                            maxWidth: '5rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={text === null || text === undefined ? '' : text}
                    >
                        {text === null || text === undefined ? '' : text}
                    </p>
                ),
            },
            {
                title: '版本号',
                dataIndex: 'name',
                render: text => (
                    <p
                        style={{
                            minWidth: '4rem',
                            maxWidth: '8rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={text}
                    >
                        {text}
                    </p>
                ),
            },
            {
                title: '固件包大小(K)',
                dataIndex: 'size',
                width: 140,
                render: text => <span>{text}</span>,
            },
            {
                title: '说明',
                dataIndex: 'desc',
                render: text => (
                    <p
                        style={{
                            minWidth: '8rem',
                            maxWidth: '16rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={text}
                    >
                        {text}
                    </p>
                ),
            },
            {
                title: '创建时间',
                dataIndex: 'create_time',
                width: 180,
                render: text => <span>{text && text.slice(0, 10) + ' ' + text.slice(11, 19)}</span>,
            },
            {
                title: '操作',
                dataIndex: 'opera',
                width: 150,
                render: (text, record) => (
                    <div className="opera">
                        {/* <span
                            className="edit"
                            onClick={() => {
                                setModalType('edit');
                                getDetail(record.id)
                                childRef.current.showModal(0);
                                setUserId(record.id);
                            }}
                        >
                            <EditOutlined /> 编辑
                        </span> */}
                        <span
                            style={{ width: '25px' }}
                            onClick={() => {
                                window.open(record.url);
                            }}
                            title={'下载'}
                        >
                            <DownloadOutlined
                                style={{
                                    color: 'rgba(0, 0, 0, 0.85)',
                                    fontSize: '1rem',
                                    verticalAlign: 'middle',
                                    margin: '0 0.6rem',
                                }}
                            />
                        </span>
                        <Popconfirm
                            title="确定要删除吗?"
                            onConfirm={() => {
                                delFirmware(record.id)
                                    .then(result => {
                                        message.success('删除成功');
                                        setUpdate(result);
                                        setUserId(record.id);
                                    })
                                    .catch();
                            }}
                        >
                            <MyIcon
                                type="iconshanchu1"
                                style={{
                                    color: 'rgba(0, 0, 0, 0.85)',
                                    fontSize: '1rem',
                                    verticalAlign: 'middle',
                                    margin: '0 0.6rem',
                                }}
                                title={'删除'}
                            />
                        </Popconfirm>
                    </div>
                ),
            },
        ];
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);
    const paginationNo = {
        pageSize: page_size, //每页的条数
        current: currentPage, //当前页
        total: count, //总数据
        showSizeChanger: true,
        onChange: (page, pageSize) => {
            ref.current = page;
            userListData(page, pageSize);
        }, //点击分页号时触发的方法
        onShowSizeChange: (current, pageSize) => {
            setPageSize(pageSize);
        }, //pageSize 变化的回调
        hideOnSinglePage: true, //为true则数据条数不满或刚好一页，则隐藏分页器
        pageSizeOptions: ['10', '15', '20', '30', '50', '100'], // 分页类别
        showTotal: total => `共 ${total} 条`,
    };

    const handleTableChange = (pagination, filters, sorter) => {
        //第三个参数 就是排序信息
        if (sorter.order === 'descend') {
            //降序
            // values.orderWay = "desc";
            setOrderWay(0);
        }
        if (sorter.order === 'ascend') {
            //升序
            setOrderWay(1);
            //orderBy和orderWay就是传给后台的排序参数
            //orderBy是表格选中的列的表头属性名
            // values.orderWay = "asc";
        }
        // values.orderBy = sorter.field;
    };
    return (
        <div className="tableBox">
            <Row>
                <Col span={4} className="gutter-row" style={{ textAlign: 'left' }}>
                    <Button
                        type="primary"
                        onClick={() => {
                            setModalType('add');
                            childRef.current.showModal();
                            if (formChildrenRef.current !== undefined || formChildrenRef.current !== null) {
                                formChildrenRef.current.resetFields();
                            }
                        }}
                        style={{ marginRight: '15px' }}
                    >
                        新增
                    </Button>
                </Col>
                <Col span={20} className="gutter-row">
                    <SearchForm setSearchParams={setSearchParams} category={category} childRef={searchChildrenRef} />
                </Col>
            </Row>
            <ConfigProvider locale={zhCN}>
                <Table
                    rowKey={record => record.id}
                    pagination={paginationNo}
                    columns={columns}
                    loading={data.isLoading}
                    dataSource={data.data}
                    bordered={true}
                    scroll={{ x: '100%' }}
                    className="formTable"
                    style={{ textAlign: 'center', position: 'relative' }}
                    onChange={handleTableChange} //排序
                />
            </ConfigProvider>
            <CommonModal
                onOk={() => {
                    formChildrenRef.current
                        .validateFields()
                        .then(value => {
                            //do something
                            //TODO 发起http请求提交当前设置的巡检
                            // modalType === 'add'
                            //     ?
                            let url = value.url.file;
                            // if (isUndefined(url)) {
                            //     ToastHelper.errorToast('请上传固件资源包');
                            // } else {
                            getImageKey({ purpose: 6 })
                                .then(async result => {
                                    const client = new OSS({
                                        // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
                                        region: result.data.endpoint.split('//')[1].split('.a')[0],
                                        // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
                                        accessKeyId: result.data.AccessKeyId,
                                        accessKeySecret: result.data.AccessKeySecret,
                                        // 从STS服务获取的安全令牌（SecurityToken）。
                                        stsToken: result.data.SecurityToken,
                                        // 填写Bucket名称。
                                        bucket: result.data.bucket_name,
                                    });
                                    const results = await client.put(
                                        // result.data.folder + uuidv4() + url.name,
                                        result.data.folder + value.category + '/' + url.name,
                                        url && url.originFileObj,
                                    );
                                    value.url = results.url.split(`firmware/${value.category}/`)[1];
                                    value.size = size;
                                    addFirmware(value)
                                        .then(() => {
                                            ToastHelper.successToast('新增成功');
                                            setUpdate(result);
                                            // childRef.current.hiddenLoading();
                                            childRef.current.hiddenModal();
                                            formChildrenRef.current.resetFields();
                                        })
                                        .catch();
                                })
                                .catch();
                            // :  value.id = userId
                            // changeDevice(value)
                            // .then(result => {
                            //     message.success('编辑成功');
                            //     childRef.current.hiddenModal();
                            //     setUpdate(result);
                            //     formChildrenRef.current.resetFields();
                            // })
                            // .catch();
                            // }
                        })
                        .catch();
                }}
                childRef={childRef}
                title={`${modalType === 'edit' ? '编辑' : '新增'}产品`}
            >
                <ModelForm childRef={formChildrenRef} type={modalType} category={category} setSize={setSize} />
            </CommonModal>
        </div>
    );
};

const ModelForm = ({ childRef, category, setSize }) => {
    const [form] = Form.useForm();
    const FormItemLayout = {
        labelCol: { span: 5 },
        wrapperCol: { span: 16 },
    };
    let fileList;
    useImperativeHandle(childRef, () => ({
        validateFields: form.validateFields,
        resetFields: form.resetFields,
        setFieldsValue: form.setFieldsValue,
    }));
    // 通过覆盖默认的上传行为，可以自定义自己的上传实现
    const uploadImageRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess('ok');
        }, 0);
    };
    const props = {
        name: 'file',
        // headers: {
        //   authorization: 'authorization-text',
        // },
        fileList: fileList,
        showUploadList: true, // 显示图片删除标识的配置
        maxCount: 1,
        customRequest: uploadImageRequest,
        onChange(info) {
            setSize(peakvalue(info.file.size / 1024));
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'removed') {
                form.setFieldsValue({ url: undefined });
            }
            if (info.file.status === 'done') {
                // message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                // message.error(`${info.file.name} file upload failed.`);
            }
        },
        onRemove: file => {
            if (file.status === 'removed') {
                form.setFieldsValue({ url: undefined });
            }
        },
    };
    return (
        <Form layout="horizontal" form={form}>
            <Form.Item
                label="分类ID"
                {...FormItemLayout}
                name="category"
                rules={[{ required: true, message: '请选择分类ID！' }]}
            >
                <Select placeholder="请选择分类ID" virtual={false} showArrow>
                    {category &&
                        category.map(item => {
                            return (
                                <Select.Option value={item.id} key={item.id}>
                                    {item.id}
                                </Select.Option>
                            );
                        })}
                </Select>
            </Form.Item>
            <FormItem
                label="版本号"
                name="name"
                {...FormItemLayout}
                rules={[{ required: true, message: '请输入固件版本！' }]}
                getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}
            >
                <Input placeholder="请输入固件版本" maxLength={32} />
            </FormItem>
            <FormItem
                label="说明"
                name="desc"
                {...FormItemLayout}
                rules={[{ required: false, message: '请输入说明内容！' }]}
                getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}
            >
                <Input.TextArea showCount maxLength={256} rows={4} placeholder="请输入说明内容" />
            </FormItem>
            <FormItem
                label="固件包"
                name="url"
                {...FormItemLayout}
                rules={[{ required: true, message: '请上传固件资源！' }]}
            >
                <Upload {...props}>
                    <Button icon={<UploadOutlined />}>上传固件资源</Button>
                </Upload>
            </FormItem>
        </Form>
    );
};

const SearchForm = ({ setSearchParams, category, childRef }) => {
    const [form] = Form.useForm();
    const onFinish = value => {
        setSearchParams(value);
    };
    useImperativeHandle(childRef, () => ({
        validateFields: form.validateFields,
        setFieldsValue: form.setFieldsValue,
    }));
    return (
        <Form
            form={form}
            onFinish={value => {
                onFinish(value);
            }}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
            <FormItem label="分类ID" name="category" style={{ marginRight: '1rem' }}>
                <Select
                    placeholder="请选择分类ID"
                    virtual={false}
                    showArrow
                    allowClear
                    style={{ minWidth: '6rem', width: '8rem' }}
                >
                    {category &&
                        category.map(item => {
                            return (
                                <Select.Option value={item.id} key={item.id}>
                                    {item.id}
                                </Select.Option>
                            );
                        })}
                </Select>
            </FormItem>
            <Button type="primary" htmlType="submit">
                搜索
            </Button>
        </Form>
    );
};
export default HardwareFirmware;