import React, { useMemo } from 'react';
import {
    Chart,
    Legend,
    XAxis,
    YAxis,
    Series,
    Tooltip,
    Grid,
    Title,
    Polar,
    AngleAxis,
    RadiusAxis,
} from '@echarts-start/react-bridge';
import { map, ceil } from 'lodash';
import * as echarts from 'echarts';
import ReactEcharts from 'echarts-for-react';
import 'echarts-gl';
import { BarChart_COLOR, legend } from '../../../../global';

export const HorizontalBarChart = ({ data }) => {
    const { y, seriesData } = useMemo(() => {
        const y = [];
        const value = [];
        const total = [];
        const number = [];
        map(data, item => {
            y.push(item.risk);
            value.push(item.number);
            total.push(item.total);
            number.push({
                name: item.risk,
                value: item.total === 0 ? 0 : ceil((item.number / item.total) * 100, 2),
                total: item.total,
                num: item.number,
            });
        });
        const seriesData = number;
        return { y, seriesData };
    }, [data]);
    var riskColor = [
        ['#F28679 ', '#DC5F4F'],
        ['#FAD762', '#FFBC24 '],
        ['#6ACAE7', '#3D95D6'],
        ['#6ACAE7', '#3D95D6'],
    ];
    var salvProMax = []; //背景按最大值
    for (let i = 0; i < data.length; i++) {
        salvProMax.push(data[0]);
    }
    return (
        <Chart color={BarChart_COLOR} style={{ width: '100%', height: '100%', marginLeft: '2rem' }} resize={true}>
            <Tooltip
                options={{
                    show: true,
                    trigger: 'axis',
                    formatter: '{b}:{c}%',
                    axisPointer: {
                        type: 'shadow',
                    },
                    backgroundColor: '#08294C',
                    textStyle: {
                        color: '#fff',
                    },
                    borderColor: '#08294C',
                }}
            />
            <Legend
                options={{
                    right: '10%',
                    top: '10%',
                    data: legend,
                    textStyle: {
                        color: '#c7c7c7',
                        fontSize: 12,
                    },
                    show: false,
                }}
            />
            <XAxis
                options={{
                    type: 'value', //是否显示x轴
                    axisLine: { show: false, lineStyle: { color: '#fff' } },
                    axisTick: { show: false },
                    axisLabel: { show: false },
                    splitLine: { show: false },
                    show: false,
                    position: 'top',
                }}
            />
            <YAxis
                options={{
                    type: 'category',
                    data: y,
                    formatter: function (value, index) {
                        return ['{lg|' + (index + 1) + '}{title|' + value + '} '].join('\n');
                    },
                    //定义富文本标签
                    rich: {
                        lg: {
                            fontWeight: 'bold',
                            fontSize: 12, //字体默认12
                            color: '#08C',
                            padding: [0, 5, 0, 0],
                        },
                        title: {
                            color: '#fff',
                            fontWeight: 'lighter',
                        },
                    },
                    axisTick: { show: false, alignWithLabel: false, length: 5 },
                    splitLine: { show: false },
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: '#FFFFFF',
                        },
                    },
                    inverse: 'true', //排序 让y轴数据逆向
                }}
            />
            <Grid
                options={{
                    show: 'true',
                    borderWidth: '0',
                    height: '70%',
                    width: '80%',
                    left: '5%',
                    top: '22%',
                    right: '1%',
                    bottom: '3%',
                    containLabel: true,
                }}
            />
            <Series
                options={{
                    type: 'bar',
                    barWidth: 15,
                    data: seriesData,
                    label: {
                        normal: {
                            show: true,
                            position: 'right',
                            formatter: function (data) {
                                return `{value|${data.data.num}}{total|/ ${data.data.total}}`;
                            },
                            fontSize: 12,
                            rich: {
                                value: {
                                    fontSize: 12,
                                    lineHeight: 12,
                                    color: '#DC5F4F',
                                },
                                total: {
                                    color: '#eee',
                                    padding: [2, 4],
                                    fontSize: 12,
                                }
                            },
                            color: function (params) {
                                var num = riskColor.length;
                                return new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                                    { offset: 0, color: riskColor[params.dataIndex % num][0] },
                                    { offset: 1, color: riskColor[params.dataIndex % num][1] },
                                ]);
                            },
                        },
                    },
                    // barGap: '0%',
                    // barCategoryGap: '50%',
                    itemStyle: {
                        normal: {
                            show: true,
                            borderRadius:20,
                            borderWidth: 0,
                            borderColor: '#fff',
                            color: function (params) {
                                var num = riskColor.length;
                                return new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                                    { offset: 0, color: riskColor[params.dataIndex % num][0] },
                                    { offset: 1, color: riskColor[params.dataIndex % num][1] },
                                ]);
                            },
                        },
                    },
                }}
            />
            {/* );})} */}
            <Series
                options={{
                    name: '外框',
                    type: 'bar',
                    itemStyle: {
                        normal: {
                            borderRadius:20,
                            //color: 'rgba(255, 255, 255, 0.74)', //rgba设置透明度0.14
                            color: '#2A3845',
                        },
                    },
                    barGap: '-100%',
                    z: 0,
                    barWidth: 15,
                    data: [100, 100, 100],
                }}
            />
        </Chart>
    );
};

// 告警排行
export const RankingChart = ({ data }) => {
    var riskColor = [
        ['#F28679 ', '#DC5F4F'],
        ['#FAD762', '#FFBC24 '],
        ['#6ACAE7', '#3D95D6'],
        ['#6ACAE7', '#3D95D6'],
    ];
    const { y, seriesData } = useMemo(() => {
        const y = [];
        const number = [];
        map(data, item => {
            y.push(item.region_name);
            number.push({
                name: item.region_name,
                value: item.number,
                num: item.number,
            });
        });
        const seriesData = number;
        return { y, seriesData };
    }, [data]);
    var salvProMax = []; //背景按最大值
    for (let i = 0; i < data.length; i++) {
        salvProMax.push(data[0]);
    }
    return (
        <Chart style={{ width: '100%', height: '100%', marginLeft: '2rem' }} resize={true}>
            <Tooltip
                options={{
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow',
                    },
                    backgroundColor: '#08294C',
                    textStyle: {
                        color: '#fff',
                    },
                    borderColor: '#08294C',
                    formatter: '{b0}: {c0}',
                }}
            />
            <Legend
                options={{
                    right: '10%',
                    top: '10%',
                    data: y,
                    textStyle: {
                        color: '#c7c7c7',
                        fontSize: 12,
                    },
                    show: false,
                }}
            />
            <XAxis
                options={{
                    show: false, //是否显示x轴
                    type: 'value',
                }}
            />
            <YAxis
                options={{
                    type: 'category',
                    inverse: true, //让y轴数据逆向
                    axisLabel: {
                        show: true,
                        textStyle: {
                            color: '#fff', //y轴字体颜色
                        },
                        formatter: function (value, index) {
                            return value;
                        },
                        //定义富文本标签
                        rich: {
                            lg: {
                                fontWeight: 'bold',
                                fontSize: 12, //字体默认12
                                padding: [0, 5, 0, 0],
                            },
                            title: {
                                color: '#fff',
                                fontWeight: 'lighter',
                            },
                        },
                    },
                    splitLine: { show: false }, //横向的线
                    axisTick: { show: false }, //y轴的端点
                    axisLine: { show: false }, //y轴的线
                    data: y,
                }}
            />
            <Grid
                options={{
                    show: 'true',
                    borderWidth: '0',
                    height: '70%',
                    width: '70%',
                    x: '12%',
                    left: '18%',
                    top: '22%',
                    // y:"20%",
                }}
            />
            <Series
                options={{
                    type: 'bar',
                    barWidth: 15,
                    data: seriesData,
                    label: {
                        normal: {
                            show: true,
                            position: 'right',
                            fontSize: 12,
                            color: '#D2E4FA',
                        },
                    },
                    itemStyle: {
                        normal: {
                            show: true,
                            borderRadius:15,
                            borderWidth: 0,
                            borderColor: '#fff',
                            color: function (params) {
                                var num = riskColor.length;
                                return new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                                    { offset: 0, color: riskColor[params.dataIndex % num][0] },
                                    { offset: 1, color: riskColor[params.dataIndex % num][1] },
                                ]);
                            },
                        },
                    },
                }}
            />
            <Series
                options={{
                    name: '外框',
                    type: 'bar',
                    itemStyle: {
                        normal: {
                            borderRadius:20,
                            color: '#2A3845',
                        },
                    },
                    barGap: '-100%',
                    z: 0,
                    barWidth: 15,
                    data: salvProMax,
                }}
            />
        </Chart>
    );
};

//渐变的圆环图
export const GradientRingChart = ({ data }) => {
    // 通过值控制圆环缺口
    let arr = [100, 0];
    // 圆环起始角度
    let startAngle = -((arr[1] / 100) * 360) / 2;
    let radius3 = '53%';
    return (
        <Chart color={BarChart_COLOR} style={{ width: '100%', height: '100%', marginLeft: '2rem' }} resize={true}>
            <Title
                options={{
                    text: '进度：' + data,
                    textStyle: {
                        color: '#fff',
                        fontSize: 40,
                    },
                    top: '47%',
                    left: '45%',
                }}
            />
            <Polar
                options={[
                    {
                        radius: ['33.5%', '63%'],
                        center: ['50%', '50%'],
                    },
                ]}
            />
            <AngleAxis
                options={{
                    max: 100,
                    show: false,
                    inverse: false,
                }}
            />
            <RadiusAxis
                options={{
                    type: 'category',
                    show: true,
                    axisLabel: {
                        show: false,
                    },
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                }}
            />
            <Series
                options={{
                    type: 'pie',
                    radius: ['0%', '42.5%'],
                    // hoverAnimation: false,
                    clockwise: false,
                    itemStyle: {
                        normal: {
                            shadowBlur: 40,
                            shadowColor: 'rgba(0, 118, 239,1)',
                            //shadowColor: 'rgba(28,64,102,.3)',//#183B5E
                            color: 'rgba(15, 35, 46,.6)',
                            shadowOffsetX: 0,
                            shadowOffsetY: 0,
                        },
                    },
                    label: {
                        show: false,
                    },
                    data: [100],
                }}
            />
            <Series
                options={{
                    type: 'pie',
                    radius: ['53%', '56.5%'],
                    // hoverAnimation: false,
                    clockwise: false,
                    itemStyle: {
                        normal: {
                            shadowBlur: 30,
                            shadowColor: 'rgba(0, 118, 239,1)',
                            color: 'rgba(15, 35, 46,.6)',
                            shadowOffsetX: 0,
                            shadowOffsetY: 0,
                        },
                    },
                    label: {
                        show: false,
                    },
                    data: [100],
                }}
            />
            <Series
                options={{
                    type: 'gauge',
                    radius: radius3,
                    clockwise: false,
                    startAngle: startAngle,
                    endAngle: 360 + startAngle - 0.000001,
                    splitNumber: 30,
                    detail: {
                        offsetCenter: [0, 0],
                        formatter: ' ',
                    },
                    pointer: {
                        show: false,
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: [
                                [0, '#fff'],
                                [-startAngle / 180, 'transparent'],
                                [(100 / (arr[0] / 100) - data) / (100 / (arr[0] / 100)), 'rgba(53,53,53,.3)'],
                                [1, '#DC5F4F'],
                            ],
                            width: 26,
                            shadowColor: '#0B1A44',
                            shadowBlur: 1,
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        show: true,
                        length: 26,
                        distance: -26,
                        lineStyle: {
                            color: 'rgba(0,0,0,1)',
                            width: 4,
                        },
                    },
                    axisLabel: {
                        show: false,
                    },
                }}
            />
        </Chart>
    );
};

//渐变的圆环图
export const GradientRingChart1 = ({ total, data, name, color, style }) => {
    // 通过值控制圆环缺口
    let arr = [100, 0];
    // 圆环起始角度
    let startAngle = -((arr[1] / 100) * 360) / 2;
    let radius3 = '53%';
    let option = {
        title: {
            text: `${name + '\n' + data}`,
            textStyle: {
                color: '#fff',
                fontSize: 12,
            },
            top: '43%',
            x: 'center',
        },
        polar: [
            {
                radius: ['33.5%', '63%'],
                center: ['50%', '50%'],
            },
        ],

        angleAxis: {
            max: 100,
            show: false,
            inverse: false,
        },
        radiusAxis: {
            type: 'category',
            show: true,
            axisLabel: {
                show: false,
            },
            axisLine: {
                show: false,
            },
            axisTick: {
                show: false,
            },
        },
        series: [
            //极坐标柱状图
            {
                type: 'pie',
                radius: ['0%', '44.5%'],
                // hoverAnimation: false,
                clockwise: false,
                itemStyle: {
                    normal: {
                        shadowBlur: 40,
                        shadowColor: 'rgba(0, 118, 239,1)',
                        //shadowColor: 'rgba(28,64,102,.3)',//#183B5E
                        color: 'rgba(15, 35, 46,.6)',
                        shadowOffsetX: 0,
                        shadowOffsetY: 0,
                    },
                },
                label: {
                    show: false,
                },
                data: [100],
            },
            {
                type: 'pie',
                radius: ['53%', '56.5%'],
                clockwise: false,
                itemStyle: {
                    normal: {
                        shadowBlur: 30,
                        shadowColor: 'rgba(0, 118, 239,1)',
                        color: 'rgba(15, 35, 46,.6)',
                        shadowOffsetX: 0,
                        shadowOffsetY: 0,
                    },
                },
                label: {
                    show: false,
                },
                data: [100],
            },
            //分割刻度
            {
                type: 'gauge',
                radius: radius3,
                clockwise: false,
                startAngle: startAngle,
                endAngle: 360 + startAngle - 0.000001,
                splitNumber: 30,
                detail: {
                    offsetCenter: [0, 0],
                    formatter: ' ',
                },
                pointer: {
                    show: false,
                },
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: [
                            [0, '#fff'],
                            [-startAngle / 180, 'transparent'],
                            [(100 / (arr[0] / 100) - total) / (100 / (arr[0] / 100)), 'rgba(53,53,53,.3)'],
                            [1, color],
                        ],
                        width: 16,
                        shadowColor: '#0B1A44',
                        shadowBlur: 1,
                    },
                },
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    length: 16,
                    distance: -16,
                    lineStyle: {
                        color: 'rgba(0,0,0,1)',
                        width: 3,
                    },
                },
                axisLabel: {
                    show: false,
                },
            },
        ],
    };
    return (
        <ReactEcharts
            option={option}
            style={style}
            // className='react_for_echarts'
        />
    );
};

//3D饼图
export const Pie3D = ({ data, color, show, distance }) => {
    let optionData =
        data &&
        data.map((item, index) => {
            item.name = item.category_name;
            item.value = item.number;
            item.itemStyle = {
                color: color[index],
                opacity: 0.5,
            };
            return item;
        });
    const getPie3D = (pieData, internalDiameterRatio) => {
        //internalDiameterRatio:透明的空心占比
        let series = [];
        let sumValue = 0;
        let startValue = 0;
        let endValue = 0;
        let legendData = [];
        let legendBfb = [];
        let k = 1 - internalDiameterRatio;
        pieData.sort((a, b) => {
            return b.value - a.value;
        });
        // 为每一个饼图数据，生成一个 series-surface 配置
        for (let i = 0; i < pieData.length; i++) {
            sumValue += pieData[i].value;
            let seriesItem = {
                name: typeof pieData[i].name === 'undefined' ? `series${i}` : pieData[i].name,
                type: 'surface',
                parametric: true,
                wireframe: {
                    show: false,
                },
                pieData: pieData[i],
                pieStatus: {
                    selected: false,
                    hovered: false,
                    k: k,
                },
                center: ['10%', '50%'],
                // hoverAnimation: false,
            };

            if (typeof pieData[i].itemStyle != 'undefined') {
                let itemStyle = {};
                itemStyle.color = typeof pieData[i].itemStyle.color != 'undefined' ? pieData[i].itemStyle.color : null;
                itemStyle.opacity =
                    typeof pieData[i].itemStyle.opacity != 'undefined' ? pieData[i].itemStyle.opacity : null;
                seriesItem.itemStyle = itemStyle;
            }
            series.push(seriesItem);
        }

        // 使用上一次遍历时，计算出的数据和 sumValue，调用 getParametricEquation 函数，
        // 向每个 series-surface 传入不同的参数方程 series-surface.parametricEquation，也就是实现每一个扇形。
        legendData = [];
        legendBfb = [];
        for (let i = 0; i < series.length; i++) {
            endValue = startValue + series[i].pieData.value;
            series[i].pieData.startRatio = startValue / sumValue;
            series[i].pieData.endRatio = endValue / sumValue;
            series[i].parametricEquation = getParametricEquation(
                series[i].pieData.startRatio,
                series[i].pieData.endRatio,
                false,
                false,
                k,
                series[i].pieData.value,
            );
            startValue = endValue;
            let bfb = fomatFloat(series[i].pieData.value / sumValue, 4);
            legendData.push({
                name: series[i].name,
                value: bfb,
            });
            legendBfb.push({
                name: series[i].name,
                value: bfb,
            });
        }
        let boxHeight = getHeight3D(series, 36); //通过传参设定3d饼/环的高度，26代表26px
        // 准备待返回的配置项，把准备好的 legendData、series 传入。
        let option = {
            legend: {
                data: legendData,
                orient: 'vertical',
                right: 10,
                top: 'center',
                itemGap: 10,
                textStyle: {
                    color: '#D2E4FA',
                },
                icon: 'circle',
                formatter: function (param) {
                    let item = legendBfb.filter(item => item.name === param)[0];
                    let bfs = fomatFloat(item.value * 100, 2) + '%';
                    return `${item.name}  ${bfs}`;
                },
                show: show,
            },
            labelLine: {
                show: true,
                lineStyle: {
                    color: '#A8A8A8',
                },
            },
            label: {
                show: true,
                // position: 'outside',
                color: '#A8A8A8',
                rich: {
                    b: {
                        color: '#A8A8A8',
                        fontSize: 12,
                        lineHeight: 15,
                    },
                    c: {
                        fontSize: 14,
                    },
                },
                formatter: '{b|{b} \n}{c|{c}}{b|  个}',
            },
            tooltip: {
                formatter: params => {
                    if (params.seriesName !== 'mouseoutSeries' && params.seriesName !== 'pie2d') {
                        let bfb = (
                            (option.series[params.seriesIndex].pieData.endRatio -
                                option.series[params.seriesIndex].pieData.startRatio) *
                            100
                        ).toFixed(2);
                        return (
                            `${params.seriesName}<br/>` +
                            `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${params.color};"></span>` +
                            `${bfb}%`
                        );
                    }
                },
                backgroundColor: '#08294C',
                textStyle: {
                    color: '#ffffff',
                },
                borderColor: '#08294C',
                show: false,
            },
            xAxis3D: {
                min: -1,
                max: 1,
            },
            yAxis3D: {
                min: -1,
                max: 1,
            },
            zAxis3D: {
                min: -1,
                max: 1,
            },
            grid3D: {
                show: false,
                // boxHeight: boxHeight, //圆环的高度
                boxHeight: 25, //圆环的高度
                maxboxHeight: boxHeight,
                viewControl: {
                    //3d效果可以放大、旋转等，请自己去查看官方配置
                    alpha: 20, //角度
                    beta: 5,
                    distance: distance, //调整视角到主体的距离，类似调整zoom
                    rotateSensitivity: 0, //设置为0无法旋转
                    zoomSensitivity: 0, //设置为0无法缩放
                    panSensitivity: 0, //设置为0无法平移
                    autoRotate: false, //自动旋转
                },
            },
            series: series,
        };
        return option;
    };
    //获取3d丙图的最高扇区的高度
    const getHeight3D = (series, height) => {
        series.sort((a, b) => {
            return b.pieData.value - a.pieData.value;
        });
        return (height * 25) / series[0].pieData.value;
    };
    // 生成扇形的曲面参数方程，用于 series-surface.parametricEquation
    const getParametricEquation = (startRatio, endRatio, isSelected, isHovered, k, h) => {
        // 计算
        let midRatio = (startRatio + endRatio) / 2;
        let startRadian = startRatio * Math.PI * 2;
        let endRadian = endRatio * Math.PI * 2;
        let midRadian = midRatio * Math.PI * 2;
        // 如果只有一个扇形，则不实现选中效果。
        if (startRatio === 0 && endRatio === 1) {
            isSelected = false;
        }
        // 通过扇形内径/外径的值，换算出辅助参数 k（默认值 1/3）
        k = typeof k !== 'undefined' ? k : 1 / 3;
        // 计算选中效果分别在 x 轴、y 轴方向上的位移（未选中，则位移均为 0）
        let offsetX = isSelected ? Math.cos(midRadian) * 0.1 : 0;
        let offsetY = isSelected ? Math.sin(midRadian) * 0.1 : 0;
        // 计算高亮效果的放大比例（未高亮，则比例为 1）
        let hoverRate = isHovered ? 1.05 : 1;
        // 返回曲面参数方程
        return {
            u: {
                min: -Math.PI,
                max: Math.PI * 3,
                step: Math.PI / 32,
            },
            v: {
                min: 0,
                max: Math.PI * 2,
                step: Math.PI / 20,
            },
            x: function (u, v) {
                if (u < startRadian) {
                    return offsetX + Math.cos(startRadian) * (1 + Math.cos(v) * k) * hoverRate;
                }
                if (u > endRadian) {
                    return offsetX + Math.cos(endRadian) * (1 + Math.cos(v) * k) * hoverRate;
                }
                return offsetX + Math.cos(u) * (1 + Math.cos(v) * k) * hoverRate;
            },
            y: function (u, v) {
                if (u < startRadian) {
                    return offsetY + Math.sin(startRadian) * (1 + Math.cos(v) * k) * hoverRate;
                }
                if (u > endRadian) {
                    return offsetY + Math.sin(endRadian) * (1 + Math.cos(v) * k) * hoverRate;
                }
                return offsetY + Math.sin(u) * (1 + Math.cos(v) * k) * hoverRate;
            },
            z: function (u, v) {
                if (u < -Math.PI * 0.5) {
                    return Math.sin(u);
                }
                if (u > Math.PI * 2.5) {
                    return Math.sin(u) * h * 0.1;
                }
                return Math.sin(v) > 0 ? 1 * h * 0.1 : -1;
            },
        };
    };

    const fomatFloat = (num, n) => {
        var f = parseFloat(num);
        if (isNaN(f)) {
            return false;
        }
        f = Math.round(num * Math.pow(10, n)) / Math.pow(10, n); // n 幂
        var s = f.toString();
        var rs = s.indexOf('.');
        //判定如果是整数，增加小数点再补0
        if (rs < 0) {
            rs = s.length;
            s += '.';
        }
        while (s.length <= rs + n) {
            s += '0';
        }
        return s;
    };
    //构建3d饼状图
    // 传入数据生成 option
    let option1 = getPie3D(optionData, 0.8);
    //是否需要label指引线，如果要就添加一个透明的2d饼状图并调整角度使得labelLine和3d的饼状图对齐，并再次setOption
    option1.series.push({
        name: 'pie2d',
        type: 'pie',
        avoidLabelOverlap: false,
        legendHoverLink: false, //移入leged不显示在中间
        labelLine: {
            show: true,
        },
        startAngle: -10, //起始角度，支持范围[0, 360]。
        clockwise: false, //饼图的扇区是否是顺时针排布。上述这两项配置主要是为了对齐3d的样式
        radius: ['30%', '30%'],
        center: ['55%', '45%'],
        data: optionData,
        itemStyle: {
            opacity: 0,
        },
        label: {
            show: true,
            position: 'outside',
            rich: {
                b: {
                    fontSize: 12,
                },
                c: {
                    fontSize: 12,
                },
            },
            formatter: '{b|{b} {d}% \n}{c|{c}}{b|  个}',
            emphasis: {
                show: true,
            },
        },
        emphasis: {
            show: false,
        },
    });
    return (
        <ReactEcharts
            option={option1}
        />
    );
};

//3D饼图2
export const Pie3D2 = ({ data, color, show, distance }) => {
    let optionData =
        data &&
        data.map((item, index) => {
            item.name = item.category__name;
            item.value = item.number;
            item.itemStyle = {
                color: color[index],
                opacity: 0.5,
            };
            item.label = {
                show: true,
            };
            return item;
        });
    // 生成扇形的曲面参数方程，用于 series-surface.parametricEquation
    const getParametricEquation = (startRatio, endRatio, isSelected, isHovered, k, height) => {
        // 计算
        let midRatio = (startRatio + endRatio) / 2;

        let startRadian = startRatio * Math.PI * 2;
        let endRadian = endRatio * Math.PI * 2;
        let midRadian = midRatio * Math.PI * 2;

        // 如果只有一个扇形，则不实现选中效果。
        if (startRatio === 0 && endRatio === 1) {
            isSelected = false;
        }

        // 通过扇形内径/外径的值，换算出辅助参数 k（默认值 1/3）
        k = typeof k !== 'undefined' ? k : 1 / 3;

        // 计算选中效果分别在 x 轴、y 轴方向上的位移（未选中，则位移均为 0）
        let offsetX = isSelected ? Math.cos(midRadian) * 0.1 : 0;
        let offsetY = isSelected ? Math.sin(midRadian) * 0.1 : 0;

        // 计算高亮效果的放大比例（未高亮，则比例为 1）
        let hoverRate = isHovered ? 1.05 : 1;

        // 返回曲面参数方程
        return {
            u: {
                min: -Math.PI,
                max: Math.PI * 3,
                step: Math.PI / 32,
            },

            v: {
                min: 0,
                max: Math.PI * 2,
                step: Math.PI / 20,
            },

            x: function (u, v) {
                if (u < startRadian) {
                    return offsetX + Math.cos(startRadian) * (1 + Math.cos(v) * k) * hoverRate;
                }
                if (u > endRadian) {
                    return offsetX + Math.cos(endRadian) * (1 + Math.cos(v) * k) * hoverRate;
                }
                return offsetX + Math.cos(u) * (1 + Math.cos(v) * k) * hoverRate;
            },

            y: function (u, v) {
                if (u < startRadian) {
                    return offsetY + Math.sin(startRadian) * (1 + Math.cos(v) * k) * hoverRate;
                }
                if (u > endRadian) {
                    return offsetY + Math.sin(endRadian) * (1 + Math.cos(v) * k) * hoverRate;
                }
                return offsetY + Math.sin(u) * (1 + Math.cos(v) * k) * hoverRate;
            },

            z: function (u, v) {
                if (u < -Math.PI * 0.5) {
                    return Math.sin(u);
                }
                if (u > Math.PI * 2.5) {
                    return Math.sin(u);
                }
                return Math.sin(v) > 0 ? 1 * height : -1;
            },
        };
    };
    //获取3d丙图的最高扇区的高度
    const getHeight3D = (series, height) => {
        series.sort((a, b) => {
            return b.pieData.value - a.pieData.value;
        });
        return (height * 15) / series[0].pieData.value;
    };
    // 生成模拟 3D 饼图的配置项
    const getPie3D = (pieData, internalDiameterRatio) => {
        let optionData =
            data &&
            data.map((item, index) => {
                item.name = item.category__name || item.grade;
                item.value = item.number;
                item.itemStyle = {
                    color: color[index],
                    opacity: 0.5,
                };
                return item;
            });
        let series = [];
        let sumValue = 0;
        let startValue = 0;
        let endValue = 0;
        let legendData = [];
        let k =
            typeof internalDiameterRatio !== 'undefined'
                ? (1 - internalDiameterRatio) / (1 + internalDiameterRatio)
                : 1 / 3;

        // 为每一个饼图数据，生成一个 series-surface 配置
        for (let i = 0; i < pieData.length; i++) {
            sumValue += pieData[i].value;

            let seriesItem = {
                name: typeof pieData[i].name === 'undefined' ? `series${i}` : pieData[i].name,
                type: 'surface',
                parametric: true,
                wireframe: {
                    show: false,
                },
                pieData: pieData[i],
                pieStatus: {
                    selected: false,
                    hovered: false,
                    k: k,
                },
            };

            if (typeof pieData[i].itemStyle != 'undefined') {
                let itemStyle = {};
                itemStyle.color = typeof pieData[i].itemStyle.color != 'undefined' ? pieData[i].itemStyle.color : null;
                itemStyle.opacity =
                    typeof pieData[i].itemStyle.opacity != 'undefined' ? pieData[i].itemStyle.opacity : null;
                seriesItem.itemStyle = itemStyle;
            }
            series.push(seriesItem);
        }

        // 使用上一次遍历时，计算出的数据和 sumValue，调用 getParametricEquation 函数，
        // 向每个 series-surface 传入不同的参数方程 series-surface.parametricEquation，也就是实现每一个扇形。
        for (let i = 0; i < series.length; i++) {
            endValue = startValue + series[i].pieData.value;
            series[i].pieData.startRatio = startValue / sumValue;
            series[i].pieData.endRatio = endValue / sumValue;
            series[i].parametricEquation = getParametricEquation(
                series[i].pieData.startRatio,
                series[i].pieData.endRatio,
                false,
                false,
                k,
                series[i].pieData.value,
            );
            startValue = endValue;
            legendData.push(series[i].name);
        }

        let boxHeight = getHeight3D(series, 6); //通过传参设定3d饼/环的高度，26代表26px

        // 准备待返回的配置项，把准备好的 legendData、series 传入。
        let option = {
            tooltip: {
                formatter: params => {
                    if (params.seriesName !== 'mouseoutSeries') {
                        return `${
                            params.seriesName
                        }<br/><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                            params.color
                        };"></span>${option.series[params.seriesIndex].pieData.value}`;
                    }
                },
                backgroundColor: '#08294C',
                textStyle: {
                    color: '#D2E4FA',
                },
                borderColor: '#08294C',
            },
            legend: {
                data: optionData,
                textStyle: {
                    color: '#D2E4FA',
                    fontSize: 12,
                },
                orient: 'vertical',
                right: 5,
                top: 'center',
                itemGap: 10,
                icon: 'circle',
                formatter: function (param) {
                    let item = optionData.filter(item => item.name === param)[0];
                    return `${item.name}   ${item.value}个`;
                },
                // formatter: '{dark|{b}}\n {color|{c}} {color|({d}%)}',
                // rich: {
                //     dark: {
                //         color: '#000',
                //     },
                //     color: {
                //         color: '#309cfe'
                //     }

                // }
                // show: show,
            },
            xAxis3D: {
                min: -1,
                max: 1,
            },
            yAxis3D: {
                min: -1,
                max: 1,
            },
            zAxis3D: {
                min: -1,
                max: 1,
            },
            grid3D: {
                show: false,
                boxHeight: boxHeight || 10,
                //top: '30%',
                bottom: '50%',
                // environment: '#021041',
                viewControl: {
                    distance: 300,
                    alpha: 25,
                    beta: -30,
                },
            },
            series: series,
        };
        return option;
    };
    // 传入数据生成 option
    let option1 = getPie3D(optionData, 2);
    return (
        <ReactEcharts
            option={option1}
            // style={this.props.style}
            // className='react_for_echarts'
        />
    );
};

/**
 * @param useFakeData 是否应用默认数据，如果用默认数据的话，需额外处理legend以及tooltip上显示的数值
 * @param dealLittleLegend 该flag可以让默认数据距离饼图的距离一致
 * @returns {JSX.Element}
 * @constructor
 */
export const Pie3D3 = ({data, color,useFakeData,dealLittleLegend}) => {
    if (useFakeData) {
        console.log('trigger useFakeData')
    }
    let optionData =
        data &&
        data.map((item, index) => {
            item.name = item.category_name;
            item.value = item.number;
            item.itemStyle = {
                color: color[index],
                opacity: 0.5,
            };
            item.label = {
                show: true,
                color: '#91cc75',
                backgroundColor : 'rgba(0,23,11,0.3)',
            };
            return item;
        });
    // 生成扇形的曲面参数方程，用于 series-surface.parametricEquation
    const getParametricEquation = (startRatio, endRatio, isSelected, isHovered, k) => {
        // 计算
        let midRatio = (startRatio + endRatio) / 2;

        let startRadian = startRatio * Math.PI * 2;
        let endRadian = endRatio * Math.PI * 2;
        let midRadian = midRatio * Math.PI * 2;

        // 如果只有一个扇形，则不实现选中效果。
        if (startRatio === 0 && endRatio === 1) {
            isSelected = false;
        }

        // 通过扇形内径/外径的值，换算出辅助参数 k（默认值 1/3）
        k = typeof k !== 'undefined' ? k : 1 / 3;

        // 计算选中效果分别在 x 轴、y 轴方向上的位移（未选中，则位移均为 0）
        let offsetX = isSelected ? Math.cos(midRadian) * 0.1 : 0;
        let offsetY = isSelected ? Math.sin(midRadian) * 0.1 : 0;

        // 计算高亮效果的放大比例（未高亮，则比例为 1）
        let hoverRate = isHovered ? 1.05 : 1;

        // 返回曲面参数方程
        return {
            u: {
                min: -Math.PI,
                max: Math.PI * 3,
                step: Math.PI / 32,
            },

            v: {
                min: 0,
                max: Math.PI * 2,
                step: Math.PI / 20,
            },

            x: function (u, v) {
                if (u < startRadian) {
                    return offsetX + Math.cos(startRadian) * (1 + Math.cos(v) * k) * hoverRate;
                }
                if (u > endRadian) {
                    return offsetX + Math.cos(endRadian) * (1 + Math.cos(v) * k) * hoverRate;
                }
                return offsetX + Math.cos(u) * (1 + Math.cos(v) * k) * hoverRate;
            },

            y: function (u, v) {
                if (u < startRadian) {
                    return offsetY + Math.sin(startRadian) * (1 + Math.cos(v) * k) * hoverRate;
                }
                if (u > endRadian) {
                    return offsetY + Math.sin(endRadian) * (1 + Math.cos(v) * k) * hoverRate;
                }
                return offsetY + Math.sin(u) * (1 + Math.cos(v) * k) * hoverRate;
            },

            z: function (u, v) {
                if (u < -Math.PI * 0.5) {
                    return Math.sin(u);
                }
                if (u > Math.PI * 2.5) {
                    return Math.sin(u);
                }
                return Math.sin(v) > 0 ? 1 : -1;
            },
        };
    }

    // 生成模拟 3D 饼图的配置项
    const getPie3D = (pieData, internalDiameterRatio) => {
        let series = [];
        let sumValue = 0;
        let startValue = 0;
        let endValue = 0;
        let legendData = [];
        let k =
            typeof internalDiameterRatio !== 'undefined'
                ? (1 - internalDiameterRatio) / (1 + internalDiameterRatio)
                : 1 / 3;

        // 新增标签 series @20210613
        let labelSeries = {
            id: 'labelSeries',
            type: 'bar3D',
            //zlevel:-9,
            barSize: [0.1, 0.1],
            data: [],
            label: {
                show: false,//显示饼图上的标签文字
                formatter: function(params){
                    // return `${params.name}\n${params.value[3]}`;
                    return `${params.name}:${params.value[3]}`;
                },
                // backgroundColor: '#fff',
                color: '#A8A8A8',
                fontSize: 12,
            },
        };

        // 为每一个饼图数据，生成一个 series-surface 配置
        for (let i = 0; i < pieData.length; i++) {
            sumValue += pieData[i].value;

            let seriesItem = {
                name: typeof pieData[i].name === 'undefined' ? `series${i}` : pieData[i].name,
                type: 'surface',
                parametric: true,
                wireframe: {
                    show: false,
                },
                pieData: pieData[i],
                pieStatus: {
                    selected: false,
                    hovered: false,
                    k: k,
                },
            };

            if (typeof pieData[i].itemStyle != 'undefined') {
                let itemStyle = {};

                itemStyle.color = typeof pieData[i].itemStyle.color != 'undefined' ? pieData[i].itemStyle.color : null;
                itemStyle.opacity =
                    typeof pieData[i].itemStyle.opacity != 'undefined' ? pieData[i].itemStyle.opacity : null;

                seriesItem.itemStyle = itemStyle;
            }
            series.push(seriesItem);
        }

        // 使用上一次遍历时，计算出的数据和 sumValue，调用 getParametricEquation 函数，
        // 向每个 series-surface 传入不同的参数方程 series-surface.parametricEquation，也就是实现每一个扇形。
        for (let i = 0; i < series.length; i++) {
            endValue = startValue + series[i].pieData.value;

            series[i].pieData.startRatio = startValue / sumValue;
            series[i].pieData.endRatio = endValue / sumValue;
            series[i].parametricEquation = getParametricEquation(
                series[i].pieData.startRatio,
                series[i].pieData.endRatio,
                false,
                false,
                k
            );

            startValue = endValue;

            legendData.push(series[i].name);

            // 判断增加 label 效果 @20210613
            if (pieData[i].label && pieData[i].label.show) {
                let labelRadian = (series[i].pieData.startRatio + series[i].pieData.endRatio) * Math.PI;
                labelSeries.data.push({
                    name: series[i].name,
                    value: [Math.cos(labelRadian), Math.sin(labelRadian), 1.2, series[i].pieData.value],
                    itemStyle: {
                        opacity: 1
                    }
                });
            }
        }

        // 补充一个透明的圆环，用于支撑高亮功能的近似实现。
        series.push({
            name: 'mouseoutSeries',
            type: 'surface',
            parametric: true,
            wireframe: {
                show: false,
            },
            itemStyle: {
                opacity: 0,
            },
            parametricEquation: {
                u: {
                    min: 0,
                    max: Math.PI * 2,
                    step: Math.PI / 20,
                },
                v: {
                    min: 0,
                    max: Math.PI,
                    step: Math.PI / 20,
                },
                x: function (u, v) {
                    return Math.sin(v) * Math.sin(u) + Math.sin(u);
                },
                y: function (u, v) {
                    return Math.sin(v) * Math.cos(u) + Math.cos(u);
                },
                z: function (u, v) {
                    return Math.cos(v) > 0 ? 0.1 : -0.1;
                },
            },
        });

        // 将 labelSeries 添加进去 @20210613
        series.push(labelSeries);

        // 准备待返回的配置项，把准备好的 legendData、series 传入。
        let option = {
            //animation: false,
            legend: {
                data: legendData,
                orient: 'vertical',
                right: dealLittleLegend ? 50 : 0,
                top: 'center',
                itemWidth: 10,
                itemHeight: 5,
                textStyle: {
                    color: '#A8A8A8',
                    fontSize: 12,
                },
                formatter: function (param) {
                    let item = optionData.filter(item => item.name === param)[0];
                    return `${item.name} ${useFakeData ? 0 : item.value}次`;
                },
            },
            tooltip: {
                formatter: params => {
                    if (params.seriesName !== 'mouseoutSeries' && params.seriesName !== 'pie2d') {
                        let bfb = (
                            (option.series[params.seriesIndex].pieData.endRatio -
                                option.series[params.seriesIndex].pieData.startRatio) *
                            100
                        ).toFixed(2);
                        return (
                            `${params.seriesName}<br/>` +
                            `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${params.color};"></span>` +
                            `${useFakeData ? 0 : option.series[params.seriesIndex].pieData.value} 次 ` + (useFakeData ? '' : `${bfb}%`)
                        );
                    }
                },
                backgroundColor: '#08294C',
                textStyle: {
                    color: '#D2E4FA',
                },
                borderColor: '#08294C',
            },
            xAxis3D: {
                min: -1,
                max: 1,
            },
            yAxis3D: {
                min: -1,
                max: 1,
            },
            zAxis3D: {
                min: -1,
                max: 1,
            },
            grid3D: {
                show: false,
                boxHeight: 20,
                //top: '30%',
                left:-50,
                bottom: '50%',
                viewControl: {
                    distance: 400,
                    alpha: 25,
                    beta: -30,
                },
            },
            series: series,
        };
        return option;
    }
    // 传入数据生成 option
    let option = getPie3D(optionData);
    return (
        <ReactEcharts
            option={option}
            id={'myChart'}
            // style={this.props.style}
            // className='react_for_echarts'
        />
    );
}
