import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row, Radio, Tabs, message } from 'antd';
import { isEmpty, map, isNumber } from 'lodash';
import { BreadcrumbCustom } from '../../../components/BreadcrumbCustom';
import { CommonTree } from '../../../components/CommonTree';
import { PieChart } from './Chart/PieChart';
import { HistogramChart } from './Chart/HistogramChart';
import { RelationChart } from './Chart/RelationChart';
import { TimeSearch } from './ECRCondition';
import { Loading } from '../../../components/Loading';
import { EmptyView } from '../../../components/EmptyView';
import {
    getAreaBar,
    getAreaGraph,
    getAreaList,
    getAreaPie,
    getAreaTrend,
    getRegionFunctionList,
} from '../../../api/webServe';
import { MyIcon } from '../../../util/iconfont';
import lessStyle from './eCRCondition.module.less';
import { HistogramChart_COLOR1, HistogramChart_COLOR2 } from '../../../global';
import { toDay } from '../../../util/timeUtil';

const { TabPane } = Tabs;
export const ECRStatistics = () => {
    return (
        <div className={'container clearfix ' + lessStyle.containers}>
            <BreadcrumbCustom paths={['首页', '能耗分析', '能耗统计']} className="rumbCustom" />
            <div className="row clearfix" style={{ backgroundColor: 'transparent', margin: '1rem', padding: '0' }}>
                <Tabs type="card" style={{ width: '100%' }}>
                    <TabPane tab="按功能统计" key={1}>
                        <AreaStatistics />
                    </TabPane>
                    <TabPane tab="按区域统计" key={2}>
                        <ClassificationStatistics />
                    </TabPane>
                </Tabs>
            </div>
        </div>
    );
};

/**
 *
 * @returns 按功能统计
 */
const AreaStatistics = () => {
    const [areaInfo, setAreaInfo] = useState();
    const [treeData, setTreeData] = useState([]);
    useEffect(() => {
        getAreaList()
            .then(result => {
                setTreeData(result.data);
            })
            .catch();
    }, []);
    return (
        <Row style={{ marginLeft: '0rem', width: '100%' }} gutter={32}>
            <Col
                span={4}
                className="gutter-row"
                style={{ padding: '1rem', background: '#ffffff', borderRadius: '10px' }}
            >
                <CommonTree
                    treeData={treeData}
                    operable={false}
                    onClick={info => {
                        if (!areaInfo || areaInfo.id !== info.id) {
                            setAreaInfo(info);
                        }
                    }}
                />
            </Col>
            <Col
                span={20}
                style={{
                    borderRadius: '10px',
                    boxSizing: 'border-box',
                    paddingRight: '0',
                }}
            >
                <ChartView areaInfo={areaInfo} />
            </Col>
        </Row>
    );
};

/**
 *
 * @returns 按区域统计
 */
const ClassificationStatistics = () => {
    const [categoryInfo, setCategoryInfo] = useState();
    return (
        <Row gutter={32} style={{ marginLeft: '0rem', width: '100%' }}>
            <Col
                span={4}
                style={{ backgroundColor: '#ffffff', overflow: 'auto', borderRadius: '10px', padding: '1rem' }}
            >
                <ClassificationList onSelect={info => setCategoryInfo(info)} />
            </Col>
            <Col span={20}>
                <ChartView categoryInfo={categoryInfo} isCategory={true} />
            </Col>
        </Row>
    );
};

/**
 * 左侧查看类型筛选（全部、照明、插座）
 * @onSelect {*} 默认选中项
 * @returns
 */
const ClassificationList = ({ onSelect }) => {
    const [category, setCategory] = useState('');
    const [buttons, setButtons] = useState([]);
    useEffect(() => {
        getRegionFunctionList()
            .then(result => {
                // setButtons([{ id: 0, name: '全部' }, ...result.data]);
                setButtons([...result.data]);
                const defaultValue = 0;
                setCategory(defaultValue);
                onSelect({ id: defaultValue });
            })
            .catch(error => {
                message.error(error);
            });
            //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div style={{ padding: 16 }}>
            {map(buttons, item => (
                <Radio.Group
                    key={item.id}
                    onChange={e => {
                        const id = e.target.value;
                        setCategory(id);
                        onSelect({ id });
                    }}
                    style={{ display: 'flex', flexDirection: 'column' }}
                    value={category}
                    size="large"
                >
                    <Radio.Button
                        value={item.id}
                        style={{
                            marginBottom: 20,
                            textAlign: 'center',
                            borderRadius: 5,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {item.name}
                    </Radio.Button>
                </Radio.Group>
            ))}
        </div>
    );
};

const gutter = [16, 16];
/**
 * 右侧图表组件
 * @areaInfo {*} 区域
 * @categoryInfo {*} 类别
 * @isCategory {*} 是否选中类型
 * @returns
 */
const ChartView = ({ areaInfo, categoryInfo, isCategory }) => {
    const [timeValue, setTimeValue] = useState('d');
    const id = useMemo(() => {
        if (isEmpty(areaInfo) && isEmpty(categoryInfo)) {
            return null;
        }
        if (!isEmpty(areaInfo)) {
            return areaInfo.id;
        }
        return categoryInfo.id;
    }, [areaInfo, categoryInfo]);
    const [barData, setBarData] = useState({ isLoading: true, data: null });
    const [pieData, setPieData] = useState({ isLoading: true, data: null });
    const [graphData, setGraphData] = useState({ isLoading: true, data: null, total: 0 });
    const [trendData, setTrendData] = useState({ isLoading: true, data: null });
    //用能对比
    const getBarData = time => {
        setBarData({ data: null, isLoading: true });
        getAreaBar({ id, rule: time, isCategory })
            .then(result => {
                const old = time === 'd' ? `昨日` : time === 'm' ? '上月' : '去年';
                const atPresent = time === 'd' ? `今日` : time === 'm' ? '本月' : '今年';
                const data = [['product', old, atPresent]];
                result = result.data;
                map(result, item => {
                    const everyOne = [];
                    everyOne.push([item.name]);
                    everyOne.push(item.old_amount);
                    everyOne.push(item.now_amount);
                    data.push(everyOne);
                });
                setBarData({ isLoading: false, data: isEmpty(result) ? null : data });
            })
            .catch(() => {
                setBarData({ data: null, isLoading: false });
            });
    };
    //用能分布
    const getPieData = time => {
        setPieData({ data: null, isLoading: true });
        getAreaPie({ id, start_time: time[0], end_time: time[1], isCategory })
            .then(result => {
                result = result.data;
                setPieData({ isLoading: false, data: result });
            })
            .catch(() => {
                setPieData({ data: null, isLoading: false });
            });
    };
    //能耗流向
    const getGraphData = time => {
        setGraphData({ data: null, isLoading: true });
        getAreaGraph({ id, start_time: time[0], end_time: time[1], isCategory })
            .then(result => {
                const total = result.total;
                result = result.data;
                setGraphData({ data: result, isLoading: false, total });
            })
            .catch(() => {
                setGraphData({ data: null, isLoading: false });
            });
    };
    //用能趋势
    const getTrendData = time => {
        setTrendData({ data: null, isLoading: true });
        getAreaTrend({ id, start_time: time[0], end_time: time[1], isCategory })
            .then(result => {
                const legend = ['product'];
                const data = [];
                result = result.data;
                map(result, (item, idx) => {
                    legend.push(item.name);
                    map(item.data, (res, index) => {
                        if (!idx) {
                            const everyOne = [];
                            everyOne.push(res.interval);
                            everyOne.push(res.amount);
                            data.push(everyOne);
                        } else {
                            data[index].push(res.amount);
                        }
                    });
                });
                data.unshift(legend);
                setTrendData({ isLoading: false, data: isEmpty(result) ? null : data });
            })
            .catch(() => {
                setTrendData({ data: null, isLoading: false });
            });
    };
    useEffect(() => {
        if (!id && !isNumber(id)) {
            return;
        }
        getBarData(timeValue); //用能对比
        getPieData(toDay); //用能分布
        getGraphData(toDay); //能耗流向
        getTrendData(toDay); //用能趋势
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    return (
        <>
            <Row gutter={gutter}>
                <Col span={12}>
                    <div style={{ ...Style.module }}>
                        <Row
                            style={{
                                height: 50,
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                                lineHeight: '50px',
                                borderTopLeftRadius: '10px',
                                borderTopRightRadius: '10px',
                                boxShadow: '0px 2px 3px #e7ebeb',
                                background: 'linear-gradient(180deg, #fefefe 0%, #f5f6fa 100%)',
                            }}
                        >
                            <Col span={8}>
                                <span style={Style.title}>
                                    <MyIcon
                                        type="icon_title_contrast"
                                        style={{
                                            fontSize: '1.5rem',
                                            marginTop: '-0.1rem',
                                            verticalAlign: 'middle',
                                            paddingRight: '0.4rem',
                                            height: 50,
                                            lineHeight: '60px',
                                        }}
                                    />
                                    用能对比
                                </span>
                            </Col>
                            <Col
                                span={16}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    paddingRight: 16,
                                    marginTop: '0.3rem',
                                }}
                            >
                                <Radio.Group
                                    value={timeValue}
                                    onChange={e => {
                                        setTimeValue(e.target.value);
                                        getBarData(e.target.value);
                                    }}
                                >
                                    <Radio.Button value="d">今日</Radio.Button>
                                    <Radio.Button value="m">本月</Radio.Button>
                                    <Radio.Button value="y">今年</Radio.Button>
                                </Radio.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} style={{ height: 200 }}>
                                <Loading show={barData.isLoading}>
                                    {isEmpty(barData.data) ? (
                                        <EmptyView />
                                    ) : (
                                        <HistogramChart rawData={barData.data} color={HistogramChart_COLOR1} />
                                    )}
                                </Loading>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col span={12}>
                    <div style={{ ...Style.module }} className={lessStyle.timeSearch}>
                        <Row
                            style={{
                                height: 50,
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                                lineHeight: '50px',
                                borderTopLeftRadius: '10px',
                                borderTopRightRadius: '10px',
                                boxShadow: '0px 2px 3px #e7ebeb',
                                background: 'linear-gradient(180deg, #fefefe 0%, #f5f6fa 100%)',
                            }}
                        >
                            <Col span={8}>
                                <span style={Style.title}>
                                    <MyIcon
                                        type="icon_title_spread"
                                        style={{
                                            fontSize: '1.5rem',
                                            marginTop: '-0.1rem',
                                            verticalAlign: 'middle',
                                            paddingRight: '0.4rem',
                                            height: 50,
                                            lineHeight: '60px',
                                        }}
                                    />
                                    用能分布
                                </span>
                            </Col>
                            <Col
                                span={16}
                                style={{ display: 'flex', justifyContent: 'space-between', lineHeight: '50px' }}
                            >
                                <TimeSearch
                                    timeValue={toDay}
                                    onValueChange={times => {
                                        if (!id && !isNumber(id)) {
                                            return;
                                        }
                                        const { time } = times;
                                        getPieData(time);
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Loading show={pieData.isLoading}>
                                <Col style={{ height: 200 }} span={24}>
                                    {isEmpty(pieData.data) ? (
                                        <EmptyView />
                                    ) : (
                                        <PieChart data={pieData.data} color={HistogramChart_COLOR2} />
                                    )}
                                </Col>
                            </Loading>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Row
                        style={{
                            ...Style.module,
                            marginTop: 16,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%',
                            borderBottomLeftRadius: '0px',
                            borderBottomRightRadius: '0px',
                            boxShadow: '0px 2px 3px #e7ebeb',
                            background: 'linear-gradient(180deg, #fefefe 0%, #f5f6fa 100%)',
                        }}
                    >
                        <Col span={8} style={{ ...Style.title }}>
                            <MyIcon
                                type="icon_title_sankey"
                                style={{
                                    fontSize: '1.5rem',
                                    marginTop: '-0.1rem',
                                    verticalAlign: 'middle',
                                    paddingRight: '0.6rem',
                                    height: 50,
                                    lineHeight: '60px',
                                }}
                            />
                            能耗流向
                        </Col>
                        <Col
                            span={16}
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginTop: '-0.3rem',
                            }}
                        >
                            <TimeSearch
                                timeValue={toDay}
                                onValueChange={times => {
                                    if (!id && !isNumber(id)) {
                                        return;
                                    }
                                    const { time } = times;
                                    getGraphData(time);
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col
                    style={{
                        height: 350,
                        width: '100%',
                        backgroundColor: '#fff',
                        borderBottomLeftRadius: '10px',
                        borderBottomRightRadius: '10px',
                    }}
                    span={24}
                >
                    <Loading show={graphData.isLoading}>
                        {isEmpty(graphData.data) || !graphData.total ? (
                            <EmptyView />
                        ) : (
                            <RelationChart total={graphData.total} rawData={graphData.data} />
                        )}
                    </Loading>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Row
                        style={{
                            ...Style.module,
                            marginTop: 16,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%',
                            borderBottomLeftRadius: '0px',
                            borderBottomRightRadius: '0px',
                            boxShadow: '0px 2px 3px #e7ebeb',
                            background: 'linear-gradient(180deg, #fefefe 0%, #f5f6fa 100%)',
                        }}
                    >
                        <Col span={8} style={{ ...Style.title }}>
                            <MyIcon
                                type="icon_title_trend"
                                style={{
                                    fontSize: '1.5rem',
                                    marginTop: '-0.1rem',
                                    verticalAlign: 'middle',
                                    paddingRight: '0.6rem',
                                    height: 50,
                                    lineHeight: '60px',
                                }}
                            />
                            用能趋势
                        </Col>
                        <Col
                            span={16}
                            style={{ display: 'flex', justifyContent: 'space-between', marginTop: '-0.3rem' }}
                        >
                            <TimeSearch
                                timeValue={toDay}
                                onValueChange={times => {
                                    if (!id && !isNumber(id)) {
                                        return;
                                    }
                                    const { time } = times;
                                    getTrendData(time);
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col
                    style={{
                        height: 300,
                        width: '100%',
                        backgroundColor: '#fff',
                        borderBottomLeftRadius: '10px',
                        borderBottomRightRadius: '10px',
                    }}
                >
                    <Loading show={trendData.isLoading}>
                        {isEmpty(trendData.data) ? (
                            <EmptyView />
                        ) : (
                            <HistogramChart showToolbox={true} rawData={trendData.data} color={HistogramChart_COLOR2} />
                        )}
                    </Loading>
                </Col>
            </Row>
        </>
    );
};

/**
 * 样式
 */
const Style = {
    title: {
        display: 'flex',
        width: '100%',
        height: '50px',
        lineHeight: '50px',
        fontSize: '1.2rem',
        color: '#333333',
        fontWeight: 500,
        padding: '0 1rem',
        verticalAlign: 'middle',
    },
    module: {
        background: '#ffffff',
        borderRadius: '10px',
        boxShadow: `0px 0px 3px 0px rgba(0,0,0,0.08)`,
    },
};
