import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Form, Button, Select, Input, TimePicker, message, Divider } from 'antd';
import { BreadcrumbCustom } from '../../../components/BreadcrumbCustom';
import { getPachhours, addPachhours } from '../../../api/webServe';
import { isNull, isUndefined } from 'lodash';

const FormItem = Form.Item;
const { Option } = Select;
const FormItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
};
//谷峰电费设置组件
const GufengElectricity = () => {
    const formChildrenRef = useRef();
    const [form1] = Form.useForm();
    const [form2] = Form.useForm();
    const [data, setData] = useState({});
    /**
     * 提交事件
     */
    const handleFormSubmit = () => {
        //处理多个异步
        Promise.all([form1.validateFields(), form2.validateFields()])
            .then(result => {
                const value = { peak_expense: result[1], peak_hour: result[0] };
                addPachhours(value)
                    .then(() => {
                        message.success('提交成功');
                    })
                    .catch();
            })
            .catch();
    };
    //向后端发送请求
    useEffect(() => {
        getPachhours()
            .then(result => {
                setData(result.data.peak_hour);
                const { peak_hour, peak_expense } = result.data;
                if (!isUndefined(peak_hour) && !isNull(peak_hour)) {
                    form1.setFieldsValue({
                        peacetime_interval: peak_hour.peacetime_interval === null ? [] : peak_hour.peacetime_interval,
                        peak_hour_interval: peak_hour.peak_hour_interval === null ? [] : peak_hour.peak_hour_interval,
                        valley_interval: peak_hour.valley_interval === null ? [] : peak_hour.valley_interval,
                        tip_time_interval: peak_hour.tip_time_interval === null ? [] : peak_hour.tip_time_interval,
                    });
                }
                if (!isUndefined(peak_expense) && !isNull(peak_expense)) {
                    form2.setFieldsValue({
                        peacetime_interval: peak_expense.peacetime_interval === null ? [] : peak_expense.peacetime_interval ,
                        peak_hour_interval: peak_expense.peak_hour_interval === null ? [] : peak_expense.peak_hour_interval,
                        valley_interval: peak_expense.valley_interval === null ? [] : peak_expense.valley_interval,
                        tip_time_interval: peak_expense.tip_time_interval === null ? [] : peak_expense.tip_time_interval,
                    });
                }
            })
            .catch();
            //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div className="container clearfix">
            <BreadcrumbCustom paths={['首页', '系统设置', '谷峰电费设置']} className="rumbCustom" />
            <div
                className="row clearfix"
                style={{ backgroundColor: '#fff', margin: '1rem', borderRadius: '10px', padding: '0' }}
            >
                <div className="personal" style={{ width: '100%' }}>
                    <RenderInfo
                        childRef={formChildrenRef}
                        form={form1}
                        dataForm={!isUndefined(data) && data}
                        names={'时间设置'}
                        title={'peak_hour'}
                    />
                    <RenderPackExpense
                        childRef={formChildrenRef}
                        form={form2}
                        name={'电费设置'}
                        title={'peak_expense'}
                    />
                </div>
                <div className="personal" style={{ width: '100%' }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        onClick={handleFormSubmit}
                        style={{ display: 'flex', margin: '5rem auto 2rem' }}
                    >
                        保存设置
                    </Button>
                </div>
            </div>
        </div>
    );
};

/**
 * 时间设置组件
 * @param {*} form 表单
 * @param {*} names 表单标题
 * @param {*} dataForm  表单数据
 * @returns
 */
const RenderInfo = ({ form, names, dataForm }) => {
    // let peak_hour_interval = !isUndefined(dataForm.peak_hour_interval) ? dataForm.peak_hour_interval : [];
    // let valley_interval = !isUndefined(dataForm.valley_interval) ? dataForm.valley_interval : [];
    // let peacetime_interval = !isUndefined(dataForm.peacetime_interval) ? dataForm.peacetime_interval : [];
    // let tip_time_interval = !isUndefined(dataForm.tip_time_interval) ? dataForm.tip_time_interval : [];
    const { peak_hour_interval, valley_interval, peacetime_interval, tip_time_interval } = useMemo(() => {
        if (isUndefined(dataForm.peak_hour_interval)) {
            return { peak_hour_interval: [], valley_interval: [], peacetime_interval: [], tip_time_interval: [] };
        }
        if (isNull(dataForm.peak_hour_interval)) {
            return { peak_hour_interval: [], valley_interval: [], peacetime_interval: [], tip_time_interval: [] };
        }
        return {
            peak_hour_interval: dataForm.peak_hour_interval,
            valley_interval: dataForm.valley_interval,
            peacetime_interval: dataForm.peacetime_interval,
            tip_time_interval: dataForm.tip_time_interval,
        };
    }, [dataForm]);
    const [optionShow, setOptionShow] = useState(false);
    const [optionShowgu, setOptionShowgu] = useState(false);
    const [optionShowping, setOptionShowping] = useState(false);
    const [optionShowjian, setOptionShowjian] = useState(false);
    const [items, setItems] = useState(peak_hour_interval);
    const [itemsgu, setItemsgu] = useState(valley_interval);
    const [itemsping, setItemsping] = useState(peacetime_interval);
    const [itemsjian, setItemsjian] = useState(tip_time_interval);
    useEffect(() => {
        setItems([...peak_hour_interval]);
    }, [peak_hour_interval]);
    useEffect(() => {
        setItemsgu([...valley_interval]);
    }, [valley_interval]);
    useEffect(() => {
        setItemsping([...peacetime_interval]);
    }, [peacetime_interval]);
    useEffect(() => {
        setItemsjian([...tip_time_interval]);
    }, [tip_time_interval]);

    let status = false;
    const focusmethon = () => {
        if (status) {
        } else {
            setOptionShowjian(false);
        }
    };
    const focusmethon2 = () => {
        if (status) {
        } else {
            setOptionShow(false);
        }
    };
    const focusmethon3 = () => {
        if (status) {
        } else {
            setOptionShowgu(false);
        }
    };
    const focusmethon4 = () => {
        if (status) {
        } else {
            setOptionShowping(false);
        }
    };

    return (
        <Form layout="horizontal" form={form} name={names} scrollToFirstError>
            <span
                style={{
                    display: 'block',
                    width: '100%',
                    lineHeight: '50px',
                    height: '50px',
                    padding: '0 1rem',
                    fontSize: '1.25rem',
                    fontWeight: '500',
                    color: '#333333',
                    marginBottom: '1rem',
                    boxShadow: '0px 2px 3px #e7ebeb',
                    background: 'linear-gradient(180deg, #f5f6fa 0%, #f5f6fa 100%)',
                }}
            >
                {names}
            </span>
            <FormItem
                name="tip_time_interval"
                label="尖时"
                {...FormItemLayout}
                rules={[{ required: false, message: '请输入尖时' }]}
                style={{ width: '50%' }}
            >
                <Select
                    mode="multiple"
                    allowClear
                    virtual={false}
                    open={optionShowjian}
                    onFocus={() => {
                        setOptionShowjian(true);
                    }}
                    onBlur={() => {
                        focusmethon();
                    }}
                    onDeselect={value => {
                        if (itemsjian.includes(value)) {
                            itemsjian.splice(value, 1);
                        }
                        setItemsjian(itemsjian);
                    }}
                    style={{ width: '100%' }}
                    dropdownRender={menu => {
                        return (
                            <>
                                {menu}
                                <Divider style={{ margin: '4px 0' }} />
                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                    <TimePicker.RangePicker
                                        allowClear={false}
                                        format="HH"
                                        value={[false, false]}
                                        allowEmpty={optionShowjian && [false, false]}
                                        style={{ flex: 'auto' }}
                                        onOk={() => {
                                            // console.log('ok========>');
                                        }}
                                        onOpenChange={value => {
                                            status = value;
                                            !value && focusmethon();
                                        }}
                                        onChange={(time, timeString) => {
                                            let newTime = [timeString[0] + ':00', timeString[1] + ':00']
                                            const times = newTime.join('-');
                                            var arr = itemsjian;
                                            arr.push(times);
                                            setItemsjian(arr);
                                            form.setFieldsValue({
                                                tip_time_interval: itemsjian?.map(item => item), //如果选中 给select 赋data里面所有的值
                                            });
                                        }}
                                    />
                                </div>
                            </>
                        );
                    }}
                    placeholder="请选择尖时段"
                >
                    {itemsjian.map(item => (
                        <Option key={item}>{item}</Option>
                    ))}
                </Select>
            </FormItem>

            <FormItem
                name="peak_hour_interval"
                label="峰时"
                {...FormItemLayout}
                rules={[{ required: false, message: '请输入峰时' }]}
                style={{ width: '50%' }}
            >
                <Select
                    mode="multiple"
                    allowClear
                    virtual={false}
                    open={optionShow}
                    onFocus={() => {
                        setOptionShow(true);
                    }}
                    onBlur={() => {
                        focusmethon2();
                    }}
                    onDeselect={value => {
                        if (items.includes(value)) {
                            items.splice(value, 1);
                        }
                        setItems(items);
                    }}
                    style={{ width: '100%' }}
                    dropdownRender={menu => {
                        return (
                            <>
                                {menu}
                                <Divider style={{ margin: '4px 0' }} />
                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                    <TimePicker.RangePicker
                                        allowClear={false}
                                        format="HH"
                                        value={[false, false]}
                                        allowEmpty={optionShowjian && [false, false]}
                                        style={{ flex: 'auto' }}
                                        onOpenChange={value => {
                                            status = value;
                                            !value && focusmethon2();
                                        }}
                                        onChange={(time, timeString) => {
                                            let newTime = [timeString[0] + ':00', timeString[1] + ':00']
                                            const times = newTime.join('-');
                                            var arr = items;
                                            arr.push(times);
                                            setItems(arr);
                                            form.setFieldsValue({
                                                peak_hour_interval: items?.map(item => item), //如果选中 给select 赋data里面所有的值
                                            });
                                        }}
                                    />
                                </div>
                            </>
                        );
                    }}
                    placeholder="请选择峰时段"
                >
                    {items.map(item => (
                        <Option key={item}>{item}</Option>
                    ))}
                </Select>
            </FormItem>
            <FormItem
                label="谷时"
                {...FormItemLayout}
                name="valley_interval"
                rules={[{ required: false, message: '请输入谷时' }]}
                style={{ width: '50%' }}
            >
                <Select
                    mode="multiple"
                    virtual={false}
                    allowClear
                    open={optionShowgu}
                    onFocus={() => {
                        setOptionShowgu(true);
                    }}
                    onBlur={() => {
                        focusmethon3();
                    }}
                    onDeselect={value => {
                        if (itemsgu.includes(value)) {
                            itemsgu.splice(value, 1);
                        }
                        setItemsgu(itemsgu);
                    }}
                    style={{ width: '100%' }}
                    dropdownRender={menu => {
                        return (
                            <>
                                {menu}
                                <Divider style={{ margin: '4px 0' }} />
                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                    <TimePicker.RangePicker
                                        allowClear={false}
                                        format="HH"
                                        value={[false, false]}
                                        allowEmpty={optionShowjian && [false, false]}
                                        style={{ flex: 'auto' }}
                                        // onOpenChange={value => {
                                        //     !value && setOptionShowgu(false);
                                        // }}
                                        onOpenChange={value => {
                                            status = value;
                                            value && focusmethon3();
                                        }}
                                        onChange={(time, timeString) => {
                                            let newTime = [timeString[0] + ':00', timeString[1] + ':00']
                                            const times = newTime.join('-');
                                            var arr = itemsgu;
                                            arr.push(times);
                                            setItemsgu(arr);
                                            form.setFieldsValue({
                                                valley_interval: itemsgu?.map(item => item), //如果选中 给select 赋data里面所有的值
                                            });
                                        }}
                                    />
                                </div>
                            </>
                        );
                    }}
                    placeholder="请选择谷时段"
                >
                    {itemsgu.map(item => (
                        <Option key={item}>{item}</Option>
                    ))}
                </Select>
            </FormItem>
            <FormItem
                label="平时"
                {...FormItemLayout}
                name="peacetime_interval"
                rules={[{ required: false, message: '请输入平时' }]}
                style={{ width: '50%' }}
            >
                <Select
                    mode="multiple"
                    allowClear
                    virtual={false}
                    open={optionShowping}
                    onFocus={() => {
                        setOptionShowping(true);
                    }}
                    onBlur={() => {
                        focusmethon4();
                    }}
                    onDeselect={value => {
                        if (itemsping.includes(value)) {
                            itemsping.splice(value, 1);
                        }
                        setItemsping(itemsping);
                    }}
                    style={{ width: '100%' }}
                    dropdownRender={menu => {
                        return (
                            <>
                                {menu}
                                <Divider style={{ margin: '4px 0' }} />
                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                    <TimePicker.RangePicker
                                        allowClear={false}
                                        format="HH"
                                        value={[false, false]}
                                        allowEmpty={optionShowjian && [false, false]}
                                        style={{ flex: 'auto' }}
                                        onOpenChange={value => {
                                            status = value;
                                            !value && focusmethon4();
                                        }}
                                        onChange={(time, timeString) => {
                                            let newTime = [timeString[0] + ':00', timeString[1] + ':00']
                                            const times = newTime.join('-');
                                            var arr = itemsping;
                                            arr.push(times);
                                            setItemsping(arr);
                                            // form.setFieldsValue({
                                            //     peacetime_interval: selectValue.concat(times)
                                            // })
                                            form.setFieldsValue({
                                                peacetime_interval: itemsping?.map(item => item), //如果选中 给select 赋data里面所有的值
                                            });
                                        }}
                                    />
                                </div>
                            </>
                        );
                    }}
                    placeholder="请选择平时段"
                >
                    {itemsping.map(item => (
                        <Option key={item}>{item}</Option>
                    ))}
                </Select>
            </FormItem>
        </Form>
    );
};

/**
 * 电费设置组件
 * @param {*} form 表单
 * @param {*} name 表单name
 * @param {*} title 表单标题
 * @returns
 */
const RenderPackExpense = ({ form, name, title }) => {
    return (
        <Form layout="horizontal" form={form} name={title}>
            <span
                style={{
                    display: 'block',
                    width: '100%',
                    height: '50px',
                    lineHeight: '50px',
                    padding: '0 1rem',
                    fontSize: '1.25rem',
                    fontWeight: '500',
                    marginBottom: '1rem',
                    color: '#333333',
                    boxShadow: '0px 2px 3px #e7ebeb',
                    background: 'linear-gradient(180deg, #f5f6fa 0%, #f5f6fa 100%)',
                }}
            >
                {name}
            </span>

            <FormItem
                label="尖时"
                name="tip_time_interval"
                {...FormItemLayout}
                rules={[
                    { required: false, message: '请输入尖时' },
                    { pattern: /^[.0-9]*$/g, message: '尖时只能包含数字' },
                ]}
                style={{ width: '50%' }}
            >
                <Input placeholder="请输入尖时" />
            </FormItem>
            <FormItem
                label="峰时"
                name="peak_hour_interval"
                {...FormItemLayout}
                rules={[
                    { required: false, message: '请输入峰时' },
                    { pattern: /^[.0-9]*$/g, message: '峰时只能包含数字' },
                ]}
                style={{ width: '50%' }}
            >
                <Input placeholder="请输入峰时" />
            </FormItem>
            <FormItem
                label="谷时"
                name="valley_interval"
                {...FormItemLayout}
                rules={[
                    { required: false, message: '请输入谷时' },
                    { pattern: /^[.0-9]*$/g, message: '谷时只能包含数字' },
                ]}
                style={{ width: '50%' }}
            >
                <Input placeholder="请输入谷时" />
            </FormItem>
            <FormItem
                label="平时"
                name="peacetime_interval"
                {...FormItemLayout}
                rules={[
                    { required: false, message: '请输入平时' },
                    { pattern: /^[.0-9]*$/g, message: '平时只能包含数字' },
                ]}
                style={{ width: '50%' }}
            >
                <Input placeholder="请输入平时" />
            </FormItem>
        </Form>
    );
};

export default GufengElectricity;
