import React, { useEffect, useState, useRef } from 'react';
import { BreadcrumbCustom } from '../../../components/BreadcrumbCustom';
import { Col, Row, Select, Timeline, Form, message, Tooltip, DatePicker } from 'antd';
import { isEmpty, omitBy, isUndefined, isNull, map } from 'lodash';
import EchartBar from '../../../components/Echart/OneBarEchart';
import { ChartView } from './ChartView';
import { EmptyView } from '../../../components/EmptyView';
import { Loading } from '../../../components/Loading';
import { toDay, day30, getToDay, disabledDate_30day } from '../../../util/timeUtil';
import { TimeSearch } from '../EnergyConsumptionChart/ECRCondition';
import { Slider } from '../CameraKanban/Slider';
import { Page } from '../../../components/Pagination';
import { LineChart } from './LineChart';
import { AreaLineChart, LineCharts } from './AreaLineChart';
import { CommonModal } from '../../../components/CommoModal';
import { SetUp, MotorConfigSetup } from './SetUp';
import { MyIcon } from '../../../util/iconfont';
import {
    equipmentDetail,
    equipmentDetailDevice,
    getCameraDetail,
    workbenchCategory,
    smokerDetailDevice,
    temperatureDetailDevice,
    doorDetailDevice,
    MotorDetailDevice,
    editCamera,
    getMotorConfig,
    editMotorConfig,
    electricityMeterDevice,
} from '../../../api/webServe';
import { toDecimal2, peakvalue, peakvalue8 } from '../../../util/function-util';
import moment from 'moment';
import { status } from '../../../global';
import Style from './style.module.less';

// const { RangePicker } = DatePicker;
const { Option } = Select;

//查看设备详情
export const ReadDevice = () => {
    return (
        <div className="container clearfix">
            <BreadcrumbCustom
                paths={['首页', '监测管理', '设备监测', '查看设备']}
                className="rumbCustom"
                showCloseBtn={true}
            />
            <div className="row clearfix" style={{ borderRadius: '10px', paddingBottom: '1rem', width: '100%' }}>
                <DeviceDetails />
            </div>
        </div>
    );
};

const DeviceDetails = () => {
    var id = window.location.search.slice(window.location.search.lastIndexOf('?') + 1);
    const [detailsData, setDetailsData] = useState([]);
    const [modalType, setModalType] = useState('setUp'); //add edit
    const childRef = useRef();
    const [form] = Form.useForm();
    // 电机配置（展示）
    const [motorConfig, setMotorConfig] = useState({ point: '-', send_time: '-', collect: '-' });
    // const [open, setOpen] = useState();
    useEffect(() => {
        getDetail(id.slice(3)); //设备详情
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    // 获取电机配置
    useEffect(() => {
        if (
            detailsData.category?.id === 'F602' ||
            detailsData.category?.id === 'F601' ||
            detailsData.category?.id === 'F603'
        ) {
            getMotorConfig({ id: id.slice(3) })
                .then(val => {
                    setMotorConfig(val.data);
                })
                .catch(e => {
                    console.log(e);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detailsData]);

    const getDetail = id => {
        equipmentDetail({ id })
            .then(result => {
                setDetailsData(result.data);
            })
            .catch();
    };

    // const onOpenChange = open => {
    //     setOpen(open);
    //     if (open) {
    //         // setDates([]);
    //         // setTime()
    //     }
    // };
    // useEffect(() => {
    //     if (!open && isBoolean(open)) {
    //         // value && setValue('');
    //         // !isEmpty(time.current) && onValueChange({ time: time.current });
    //         // !isEmpty(time.current) && setTime(time.current);
    //         time && setTime('');
    //     }
    //     //eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [open]);
    const getSetUp = value => {
        editCamera({ ...value, id: id.slice(3) })
            .then(() => {
                message.info('设置成功');
                childRef.current.hiddenModal();
                getDetail(id.slice(3)); //设备详情
            })
            .catch();
    };
    const getMotorSetup = value => {
        editMotorConfig({ ...value, id: id.slice(3) }).then(() => {
            message.info('电机设置修改提交成功');
            childRef.current.hiddenModal();
            getMotorConfig({ id: id.slice(3) })
                .then(val => {
                    setMotorConfig(val.data);
                })
                .catch(e => {
                    console.log(e);
                });
        });
    };

    return (
        <div style={{ width: '100%', height: '100%' }} className={Style.container}>
            <Row style={{ marginBottom: '1rem' }}>
                <Col
                    span={24}
                    className="common_title"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingRight: '1rem',
                    }}
                >
                    <span>{detailsData.name}</span>
                    {detailsData.category &&
                        (detailsData.category.id === 'F901' ||
                            detailsData.category.id === 'F601' ||
                            detailsData.category.id === 'F602' ||
                            detailsData.category?.id === 'F603') && (
                            <span
                                style={{ ...Styles.btn }}
                                onClick={() => {
                                    setModalType('setUp');
                                    childRef.current.showModal();
                                }}
                            >
                                设置
                            </span>
                        )}
                </Col>
                <Col span={24} style={{ background: '#fff', padding: '1rem' }}>
                    <div style={{ ...Styles.detailsTitle }}>设备详情</div>
                    <Row>
                        {detailsData &&
                            (detailsData.category?.id === 'F601' ||
                                detailsData.category?.id === 'F602' ||
                                detailsData.category?.id === 'F603') && (
                                <Col span={8}>
                                    <span style={{ ...Styles.detailsName }}>电机状态:</span>
                                    <p style={{ ...Styles.detailsValue }}>
                                        {(detailsData && (detailsData.status ? '开启' : '停止')) || '—'}
                                    </p>
                                </Col>
                            )}
                        {detailsData.category &&
                            detailsData.category.id !== 'E100' &&
                            detailsData.category.id !== 'F901' &&
                            detailsData.category.id !== 'F900' &&
                            detailsData.category.id !== 'FE00' &&
                            detailsData.category.id !== 'FE01' &&(
                                <Col span={8}>
                                    <span style={{ ...Styles.detailsName }}>设备功能：</span>
                                    <p style={{ ...Styles.detailsValue }}>
                                        {(detailsData.function && detailsData.function.name) || '—'}
                                    </p>
                                </Col>
                            )}
                        <Col span={8}>
                            <span style={{ ...Styles.detailsName }}>设备型号:</span>
                            <p style={{ ...Styles.detailsValue }}>
                                {(detailsData.category && detailsData.category.id) || '—'}
                            </p>
                        </Col>
                        <Col span={8}>
                            <span style={{ ...Styles.detailsName }}>设备ID:</span>
                            <p style={{ ...Styles.detailsValue }}>{(detailsData && detailsData.qr_code) || '—'}</p>
                        </Col>
                        {detailsData.category &&
                            detailsData.category.id !== 'E100' &&
                            detailsData.category.id !== 'F901' &&
                            detailsData.category.id !== 'F900' &&
                            detailsData.category.id !== 'FE01' && (
                                <Col span={8}>
                                    <span style={{ ...Styles.detailsName }}>网关:</span>
                                    <p style={{ ...Styles.detailsValue }}>
                                        {(detailsData.gateway && detailsData.gateway.name) || '—'}
                                    </p>
                                </Col>
                            )}
                        <Col span={8}>
                            <span style={{ ...Styles.detailsName }}>固件版本:</span>
                            <p style={{ ...Styles.detailsValue }}>{(detailsData && detailsData.version) || '—'}</p>
                        </Col>
                        <Col span={8}>
                            <span style={{ ...Styles.detailsName }}>状态:</span>
                            <p style={{ ...Styles.detailsValue }}>
                                {(detailsData && status[detailsData.status]) || '—'}
                            </p>
                        </Col>
                        {(detailsData.category && detailsData.category.id.slice(0, 4)) === 'E100' && (
                            <Col span={8}>
                                <span style={{ ...Styles.detailsName }}>IMEI编号:</span>
                                <p style={{ ...Styles.detailsValue }}>{(detailsData && detailsData.imei) || '—'}</p>
                            </Col>
                        )}
                        {detailsData && detailsData.multiple !== 1 && (
                            <Col span={8}>
                                <span style={{ ...Styles.detailsName }}>外接互感器:</span>
                                <p style={{ ...Styles.detailsValue }}>{detailsData.multiple}倍</p>
                            </Col>
                        )}
                    </Row>
                </Col>
            </Row>
            {detailsData &&
                (detailsData.category?.id === 'F601' ||
                    detailsData.category?.id === 'F602' ||
                    detailsData.category?.id === 'F603') && (
                    <Row style={{ background: '#fff', padding: '1rem', marginBottom: '1rem' }}>
                        <Col>
                            <div style={{ ...Styles.detailsTitle }}>电机配置</div>
                        </Col>
                        <Col span={24}>
                            <span style={{ ...Styles.detailsName }}>数据点数：</span>
                            <p style={{ ...Styles.detailsValue }}>{motorConfig.point + 'pcs' || '-'}</p>
                        </Col>
                        <Col span={24}>
                            <span style={{ ...Styles.detailsName }}>发送周期：</span>
                            <p style={{ ...Styles.detailsValue }}>{motorConfig.send_time + 's' || '-'}</p>
                        </Col>
                        <Col span={24}>
                            <span style={{ ...Styles.detailsName }}>采集频率：</span>
                            <p style={{ ...Styles.detailsValue }}>{motorConfig.collect + 'Hz' || '-'}</p>
                        </Col>
                    </Row>
                )}
            {detailsData &&
                detailsData.category?.id !== 'F601' &&
                detailsData.category?.id !== 'F602' &&
                detailsData.category?.id !== 'F603' && (
                    <Row style={{ background: '#fff', padding: '1rem', marginBottom: '1rem' }}>
                        <Col>
                            <div style={{ ...Styles.detailsTitle }}>采集时间</div>
                        </Col>
                        <Col span={24}>
                            <span style={{ ...Styles.detailsName }}>上次采集时间：</span>
                            <p style={{ ...Styles.detailsValue }}>
                                {(detailsData.last_time &&
                                    detailsData.last_time.slice(0, 10) + ' ' + detailsData.last_time.slice(11, 19)) ||
                                    '—'}
                            </p>
                        </Col>
                    </Row>
                )}
            {detailsData && detailsData.category?.id === 'F001' && (
                <Row style={{ background: '#fff', padding: '1rem', marginBottom: '1rem' }}>
                    <Col>
                        <div style={{ ...Styles.detailsTitle }}>连接设备</div>
                    </Col>
                    {detailsData &&
                        detailsData.son_device.map(item => {
                            return (
                                <Col span={24}>
                                    <span
                                        style={{
                                            ...Styles.detailsName,
                                            display: 'inline-block',
                                            width: '10rem',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            verticalAlign: 'middle',
                                        }}
                                    >
                                        {item.name}
                                    </span>
                                    <p
                                        style={{
                                            ...Styles.detailsValue,
                                            marginLeft: '4rem',
                                            fontSize: '0.8rem',
                                            color: status[item.status] === '离线' && '#F7A95A',
                                        }}
                                    >
                                        {status[item.status]}
                                    </p>
                                </Col>
                            );
                        })}
                </Row>
            )}
            {detailsData && !isEmpty(detailsData.history) && (
                <Row style={{ background: '#fff', padding: '1rem', marginBottom: '1rem' }}>
                    <Col>
                        <div style={{ ...Styles.detailsTitle }}>检修历史</div>
                    </Col>
                    {detailsData && isEmpty(detailsData.history) ? (
                        <Col span={24}>无</Col>
                    ) : (
                        detailsData &&
                        detailsData.history.map((item, index) => {
                            return (
                                <Col span={24} key={index}>
                                    <p style={{ ...Styles.detailsValue, marginLeft: '0' }}>
                                        {item.create_time.slice(0, 10) + ' ' + item.create_time.slice(11, 19)}
                                    </p>
                                    <span style={{ ...Styles.detailsName, marginLeft: '5rem' }}>{item.name}</span>
                                </Col>
                            );
                        })
                    )}
                </Row>
            )}
            {/* 用能趋势 */}
            {/* {(detailsData.category && (detailsData.category.id === 'F900' || detailsData.category.id === 'F901')) ===
                false && <ConsumptionTrend detailsData={detailsData} id={id} />} */}
            {/* 历史图片 */}
            {detailsData.category &&
                (detailsData.category.id === 'F900' || detailsData.category.id === 'F901' ? (
                    <HistoricalPictures detailsData={detailsData} id={id} /* 历史图片 */ />
                ) : detailsData.category.id === 'F200' ? (
                    <SmokeDetector detailsData={detailsData} id={id} /* 烟雾报警 */ />
                ) : detailsData.category.id === 'F400' ? (
                    <TemperatureHumidity detailsData={detailsData} id={id} /* 温湿度 */ />
                ) : detailsData.category.id === 'E100' ? (
                    <GateMagnetism detailsData={detailsData} id={id} /* 门磁动态 */ />
                ) : detailsData.category.id === 'F601' ||
                  detailsData.category.id === 'F602' ||
                  detailsData.category?.id === 'F603' ? (
                    <IndustrialMotor detailsData={detailsData} id={id} /* 工业电机 */ />
                ) : detailsData.category.id === 'F001' ? null : (
                    <ConsumptionTrend detailsData={detailsData} id={id} /* 用能趋势 */ />
                ))}
            <CommonModal
                title={modalType === 'setUp' ? '设置' : '编辑关联'}
                childRef={childRef}
                onOk={() => {
                    form.validateFields()
                        .then(values => {
                            detailsData.category &&
                            (detailsData.category.id === 'F601' ||
                                detailsData.category?.id === 'F602' ||
                                detailsData.category?.id === 'F603')
                                ? getMotorSetup(values)
                                : getSetUp(values);
                        })
                        .catch(() => {
                            childRef.current.endLoading();
                        });
                }}
            >
                {detailsData.category &&
                (detailsData.category.id === 'F601' ||
                    detailsData.category.id === 'F602' ||
                    detailsData.category?.id === 'F603') ? (
                    <MotorConfigSetup form={form} data={motorConfig} />
                ) : (
                    <SetUp form={form} data={detailsData} />
                )}
            </CommonModal>
        </div>
    );
};

//用电趋势
const ConsumptionTrend = ({ detailsData, id }) => {
    const [time, setTime] = useState(toDay); //搜索时间字段
    const [consumption, setConsumption] = useState({ data: null, isLoading: true });
    const [category, setCategory] = useState([]);
    const [categoryValue, setCategoryValue] = useState(category.length > 0 ? category[0] : undefined);
    const [unit, setUnit] = useState();
    useEffect(() => {
        categoryValue && detailDevice(time); //用电趋势
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, categoryValue, time]);
    const detailDevice = time => {
        let params =
            detailsData.category &&
            (detailsData.category.id === 'FE00' || detailsData.category.id === 'FE01'
                ? categoryValue
                : detailsData.category.id === 'FB00'
                ? '电流'
                : '用电量');
        setConsumption({ data: null, isLoading: false });
        detailsData?.category.id === 'FE01'
            ? electricityMeterDevice({
                  device_id: id.slice(3),
                  start_time: time[0],
                  category: params,
              })
                  .then(result => {
                      setConsumption({ data: result.data, isLoading: false });
                      setUnit(result.unit);
                  })
                  .catch()
            : equipmentDetailDevice({
                  id: id.slice(3),
                  start_time: time[0],
                  // end_time: moment(time[1]).endOf('day').valueOf(),
                  end_time: time[1],
                  category: params,
              })
                  .then(result => {
                      setConsumption({ data: result.data, isLoading: false });
                      setUnit(result.unit);
                  })
                  .catch();
    };
    useEffect(() => {
        workbenchCategory({ device_id: id.slice(3) })
            .then(result => {
                setCategory(result.data);
                setCategoryValue(result.data[0]);
                // detailDevice(toDay)
            })
            .catch();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    let yData = [],
        xTime = [];
    consumption.data &&
        consumption.data.forEach(item => {
            yData.push(toDecimal2(item.amount));
            xTime.push(item.time);
        });
    return (
        <Row style={{ background: '#fff', padding: '1rem' }}>
            <Col span={4}>
                <div style={{ ...Styles.detailsTitle }}>
                    {detailsData.category && (detailsData.category.id === 'FE00' || detailsData.category.id === 'FE01') ? '' :
                    (detailsData.category.id === 'FB00' ? '电流' : '用能趋势')}
                    <span style={{ ...Styles.mark }}>
                        {detailsData.status && ((detailsData.category.id === 'FE00' || detailsData.category.id === 'FE01') ? '' : (status[detailsData.status] === '离线' ? '(离线前采集数据)' : ''))}
                    </span>
                </div>
            </Col>
            <Col
                offset={4}
                span={
                    detailsData.category && (detailsData.category.id === 'FE00' || detailsData.category.id === 'FE01')
                        ? 13
                        : 16
                }
                style={{
                    paddingBottom: '1rem',
                    marginTop: '-0.5rem',
                }}
            >
                {detailsData.category && detailsData.category.id === 'FE01' ? (
                    <DatePicker
                        onChange={times => {
                            //电表只能使用开始时间进行查询，所以这里做特殊处理
                            setTime([times.valueOf()]);
                        }}
                        format="yyyy-MM-DD"
                        style={{
                            height: '30px',
                            fontSize: '12px',
                            position: 'absolute',
                            margin: '0.5rem',
                            zIndex: 999,
                            width: '11rem',
                            right: '0rem',
                            top: '-0.3rem',
                        }}
                        value={moment(time[0])}
                        allowClear={false}
                        disabledDate={disabledDate_30day}
                    />
                ) : (
                    <TimeSearch
                        timeValue={toDay}
                        onValueChange={times => {
                            const { time } = times;
                            setTime(time);
                        }}
                    />
                )}
            </Col>
            {detailsData.category && (detailsData.category.id === 'FE00' || detailsData.category.id === 'FE01') && (
                <Col span={3} style={{ marginTop: '0.1rem' }}>
                    <Select
                        placeholder="请选择"
                        virtual={false}
                        showArrow
                        onFocus={() => {
                            workbenchCategory({ device_id: id.slice(3) })
                                .then(result => {
                                    setCategory(result.data);
                                })
                                .catch();
                        }}
                        value={category.length > 0 ? categoryValue : undefined}
                        style={{ position: 'absolute', zIndex: 999, right: '0rem', top: '-0.5rem', width: '11rem' }}
                        onChange={e => setCategoryValue(e)}
                    >
                        {map(category, (value, key) => {
                            return (
                                <Option value={value} key={key}>
                                    {value}
                                </Option>
                            );
                        })}
                    </Select>
                </Col>
            )}
            <Col span={24} style={{ height: '50vh' }}>
                <EchartView
                    consumption={consumption}
                    yData={yData}
                    xTime={xTime}
                    detailsData={detailsData}
                    categoryValue={categoryValue}
                    unit={unit}
                />
            </Col>
        </Row>
    );
};

//历史图片
const HistoricalPictures = ({ detailsData, id }) => {
    const [data, setData] = useState({ data: null, isLoading: true });
    const [total, setTotal] = useState(0); //数据总条数
    const [page, setPage] = useState(1); //当前页
    const [page_size, setPageSize] = useState(16); //当前页显示条数
    const [time, setTime] = useState([moment().subtract(29, 'days').startOf(), moment().startOf()]); //搜索时间字段
    useEffect(() => {
        getCamera(time);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detailsData.category && detailsData.category.id, page, time]);
    const getCamera = time => {
        let searchParams = {};
        searchParams.page = page;
        searchParams.page_size = page_size;
        searchParams.start_time =
            time !== null && time !== undefined && time[0] !== '' && time[0] !== undefined ? time[0] : undefined;
        searchParams.end_time =
            time !== null && time !== undefined && time[1] !== '' && time[1] !== undefined ? time[1] : undefined;
        const params = omitBy(searchParams, value => {
            if (isNaN(value) || value === '') {
                return isUndefined(value) || isNull(value) || value === '';
            } else {
                return isNull(value);
            }
        });
        setData({ data: null, isLoading: true });
        getCameraDetail({ id: id.slice(3), ...params })
            .then(result => {
                setData({ data: result.data, isLoading: false });
                setTotal(result.data.count);
            })
            .catch(() => {
                setData({ data: null, isLoading: false });
            });
    };
    return (
        <Row style={{ background: '#fff', padding: '1rem 1rem 3rem' }}>
            <Col span={4}>
                <div style={{ ...Styles.detailsTitle }}>
                    历史图片
                    <span style={{ ...Styles.mark }}>
                        {detailsData.status && status[detailsData.status] === '离线' ? '(离线前采集数据)' : ''}
                    </span>
                </div>
            </Col>
            <Col
                offset={4}
                span={16}
                style={{
                    paddingBottom: '1rem',
                }}
            >
                <TimeSearch
                    timeValue={time}
                    onValueChange={times => {
                        const { time } = times;
                        setTime(time);
                        setPage(1);
                    }}
                    isShowButton={true}
                    isDisabledDate={true}
                    date={detailsData?.image_store === undefined ? 30 : detailsData.image_store}
                />
                {/* <RangePicker
                onOpenChange={onOpenChange}
                onChange={(value, dateString) => {
                    console.log(dateString,timestampToTime(dateString[0]),dateString[0],[Date.parse(dateString[0]),Date.parse(dateString[1])])
                    console.log(moment(), moment(dateString[0]).startOf('day').valueOf())
                    setTime([moment(dateString[0]).startOf('day').valueOf(),moment(dateString[1]).startOf('day').endOf()])
                }}
                format="yyyy-MM-DD"
                // value={[moment(timestampToTime(time[0]),'yyyy-MM-DD'), moment(timestampToTime(time[1]), 'yyyy-MM-DD')]}
                disabledDate={disabledDate_30day}
                /> */}
            </Col>
            {isEmpty(data.data) ? (
                <EmptyView />
            ) : (
                <Col span={24} className={'details'}>
                    <Slider
                        count={page_size}
                        data={data.data.camera_list}
                        click={false}
                        name={data.data.name}
                        rotate={detailsData.angle} //根据后端返回的旋转角度，对图片进行相应的角度更换
                    />
                    <div
                        span={24}
                        className="totalStyle"
                        style={{ left: '3%', bottom: '-15px', width: '16%', overflow: 'hidden' }}
                    >
                        <span
                            style={{
                                display: 'inline-block',
                                maxWidth: '70%',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                verticalAlign: 'middle',
                            }}
                        >
                            {total}
                        </span>
                        <span style={{ verticalAlign: 'middle' }}>张图片</span>
                    </div>
                    <div
                        className="paginations"
                        style={{
                            left: '2.5%',
                            width: '98.5%',
                            bottom: '-30px',
                            marginLeft: '0',
                            marginBottom: '1rem',
                        }}
                    >
                        <Page
                            count={page_size}
                            total={total}
                            page_size={page_size}
                            setPageSize={setPageSize}
                            page={page}
                            setPage={setPage}
                        />
                    </div>
                </Col>
            )}
        </Row>
    );
};

//烟宝
const SmokeDetector = ({ detailsData, id }) => {
    const [time, setTime] = useState(day30); //搜索时间字段
    const [data, setData] = useState({ data: null, isLoading: true });
    useEffect(() => {
        getSmoke(time);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detailsData.category && detailsData.category.id, time]);
    const getSmoke = time => {
        let searchParams = {};
        searchParams.start_time =
            time !== null && time !== undefined && time[0] !== '' && time[0] !== undefined ? time[0] : undefined;
        searchParams.end_time =
            time !== null && time !== undefined && time[1] !== '' && time[1] !== undefined ? time[1] : undefined;
        const params = omitBy(searchParams, value => {
            if (isNaN(value) || value === '') {
                return isUndefined(value) || isNull(value) || value === '';
            } else {
                return isNull(value);
            }
        });
        setData({ data: null, isLoading: true });
        smokerDetailDevice({ device_id: id.slice(3), ...params })
            .then(result => {
                setData({ data: result.data, isLoading: false });
            })
            .catch(() => {
                setData({ data: null, isLoading: false });
            });
    };
    return (
        <Row style={{ background: '#fff', padding: '1rem' }}>
            <Col span={4}>
                <div style={{ ...Styles.detailsTitle }}>
                    烟雾报警
                    <span style={{ ...Styles.mark }}>
                        {detailsData.status && status[detailsData.status] === '离线' ? '(离线前采集数据)' : '(近30日)'}
                    </span>
                </div>
            </Col>
            <Col
                offset={4}
                span={16}
                style={{
                    paddingBottom: '1rem',
                }}
            >
                <TimeSearch
                    timeValue={day30}
                    onValueChange={times => {
                        const { time } = times;
                        setTime(time);
                    }}
                    isShowButton={true}
                    isDisabledDate={true}
                />
            </Col>
            <Col span={24} style={{ height: '30vh' }}>
                <Loading show={data.isLoading}>
                    {isEmpty(data.data) ? (
                        <EmptyView />
                    ) : (
                        <ChartView
                            rawData={data.data}
                            name={'烟雾报警'}
                            style={{ height: '100%' }}
                            top={'-10px'}
                            bottom={'3%'}
                        />
                    )}
                </Loading>
            </Col>
        </Row>
    );
};

//温湿度
const TemperatureHumidity = ({ detailsData, id }) => {
    const colorsTemperature = [
        {
            rgbOpacity: 'rgba(189,210,253,0.3)',
            rgb: 'rgb(189,210,253)',
            color: '#BDD2FD',
            line: '#BDD2FD',
        },
        {
            rgbOpacity: 'rgba(163,232,205,0.3)',
            rgb: 'rgb(163,232,205)',
            color: '#BDEFDB',
            line: '#BDEFDB',
        },
    ];
    const colorsHumidity = [
        {
            rgbOpacity: 'rgba(182,182,242,0.3)',
            rgb: 'rgb(182,182,242)',
            color: '#C6C5F5',
            line: '#C6C5F5',
        },
        {
            rgbOpacity: 'rgba(187,186,200,0.3)',
            rgb: 'rgb(187,186,200)',
            color: '#BBBAC8',
            line: '#E1A760',
        },
    ];
    const [time, setTime] = useState(toDay); //搜索时间字段
    const [dataTemperature, setTemperature] = useState({ data: null, isLoading: true });
    const [dataHumidity, setHumidity] = useState({ data: null, isLoading: true });
    useEffect(() => {
        getSmoke(time);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detailsData.category && detailsData.category.id, time]);
    const getSmoke = time => {
        let searchParams = {};
        searchParams.start_time =
            time !== null && time !== undefined && time[0] !== '' && time[0] !== undefined ? time[0] : undefined;
        searchParams.end_time =
            time !== null && time !== undefined && time[1] !== '' && time[1] !== undefined ? time[1] : undefined;
        const params = omitBy(searchParams, value => {
            if (isNaN(value) || value === '') {
                return isUndefined(value) || isNull(value) || value === '';
            } else {
                return isNull(value);
            }
        });
        setTemperature({ data: null, isLoading: true });
        setHumidity({ data: null, isLoading: true });
        temperatureDetailDevice({ device_id: id.slice(3), ...params })
            .then(result => {
                let xTime = [];
                let yDataTemperature = [];
                let yDataHumidity = [];
                let yDataTemperatureMax = [];
                let yDataHumidityMax = [];
                let yDataTemperatureMin = [];
                let yDataHumidityMin = [];
                map(result.data, item => {
                    xTime.push(item.time);
                    yDataTemperatureMax.push(
                        item.temperature_max === null || isUndefined(item.temperature_max)
                            ? 0
                            : peakvalue(item.temperature_max),
                    ); //最大温度
                    yDataHumidityMax.push(
                        item.humidity_max === null || isUndefined(item.humidity_max) ? 0 : peakvalue(item.humidity_max),
                    ); //最大湿度
                    yDataTemperatureMin.push(
                        item.temperature_min === null || isUndefined(item.temperature_min)
                            ? 0
                            : peakvalue(item.temperature_min),
                    ); //最小温度
                    yDataHumidityMin.push(
                        item.humidity_min === null || isUndefined(item.humidity_min) ? 0 : peakvalue(item.humidity_min),
                    ); //最小湿度
                    yDataTemperature.push(
                        item.temperature_mean === null || isUndefined(item.temperature_mean)
                            ? 0
                            : peakvalue(item.temperature_mean),
                    ); //平均温度
                    yDataHumidity.push(
                        item.humidity_mean === null || isUndefined(item.humidity_mean)
                            ? 0
                            : peakvalue(item.humidity_mean),
                    ); //平均湿度
                });
                setTemperature({
                    data: isEmpty(result.data)
                        ? null
                        : time === toDay || time[0] === toDay[0]
                        ? [yDataTemperature]
                        : [yDataTemperatureMin, yDataTemperatureMax],
                    isLoading: false,
                    legend: time === toDay || time[0] === toDay[0] ? ['温度'] : ['最低温度', '最高温度'],
                    time: xTime,
                });
                setHumidity({
                    data: isEmpty(result.data)
                        ? null
                        : time === toDay || time[0] === toDay[0]
                        ? [yDataHumidity]
                        : [yDataHumidityMin, yDataHumidityMax],
                    isLoading: false,
                    legend: time === toDay || time[0] === toDay[0] ? ['湿度'] : ['最低湿度', '最高湿度'],
                    time: xTime,
                });
            })
            .catch(() => {
                setTemperature({ data: null, isLoading: false });
                setHumidity({ data: null, isLoading: false });
            });
    };
    return (
        <Row style={{ background: '#fff', padding: '1rem' }}>
            <Col span={4}>
                <div style={{ ...Styles.detailsTitle }}>
                    温湿度趋势
                    <span style={{ ...Styles.mark }}>
                        {detailsData.status && status[detailsData.status] === '离线' ? '(离线前采集数据)' : ''}
                    </span>
                </div>
            </Col>
            <Col
                offset={6}
                span={14}
                style={{
                    paddingBottom: '1rem',
                }}
            >
                <TimeSearch
                    timeValue={toDay}
                    timeType={['toDay', 'day7', 'day30', 'custom']}
                    onValueChange={times => {
                        const { time } = times;
                        setTime(time);
                    }}
                    isDisabledDate={true}
                />
            </Col>
            <Col span={24} style={{ height: '30vh' }}>
                <Loading show={dataTemperature.isLoading}>
                    {isEmpty(dataTemperature.data) ? (
                        <EmptyView />
                    ) : (
                        <LineChart data={dataTemperature} color={colorsTemperature} />
                    )}
                </Loading>
            </Col>
            <Col span={24} style={{ height: '30vh' }}>
                <Loading show={dataHumidity.isLoading}>
                    {isEmpty(dataHumidity.data) ? (
                        <EmptyView />
                    ) : (
                        <LineChart data={dataHumidity} color={colorsHumidity} />
                    )}
                </Loading>
            </Col>
        </Row>
    );
};

//门磁动态
const GateMagnetism = ({ detailsData, id }) => {
    const [time, setTime] = useState(toDay); //搜索时间字段
    const [data, setData] = useState({ data: null, isLoading: true });
    useEffect(() => {
        getDoor(time);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detailsData.category && detailsData.category.id, time]);
    const getDoor = time => {
        let searchParams = {};
        searchParams.start_time =
            time !== null && time !== undefined && time[0] !== '' && time[0] !== undefined ? time[0] : undefined;
        searchParams.end_time =
            time !== null && time !== undefined && time[1] !== '' && time[1] !== undefined ? time[1] : undefined;
        const params = omitBy(searchParams, value => {
            if (isNaN(value) || value === '') {
                return isUndefined(value) || isNull(value) || value === '';
            } else {
                return isNull(value);
            }
        });
        setData({ data: null, isLoading: true });
        doorDetailDevice({ device_id: id.slice(3), ...params })
            .then(result => {
                setData({ data: result.data, isLoading: false });
            })
            .catch(() => {
                setData({ data: null, isLoading: false });
            });
    };
    return (
        <Row style={{ background: '#fff', padding: '1rem' }}>
            <Col span={4}>
                <div style={{ ...Styles.detailsTitle }}>门磁动态</div>
            </Col>
            <Col
                offset={4}
                span={16}
                style={{
                    paddingBottom: '1rem',
                }}
            >
                <TimeSearch
                    timeValue={toDay}
                    onValueChange={times => {
                        const { time } = times;
                        setTime(time);
                    }}
                    isShowButton={true}
                    isDisabledDate={true}
                />
            </Col>
            <Col span={24} style={{ textAlign: 'left' }}>
                <Loading show={data.isLoading}>
                    {isEmpty(data.data) ? (
                        <EmptyView />
                    ) : (
                        <Timeline mode={'left'} style={{ width: '60%' }}>
                            {map(data.data, item => {
                                return (
                                    <Timeline.Item
                                        label={
                                            <>
                                                <p style={{ ...Styles.TimelineTime }}>
                                                    {item.time && item.time.slice(11, 19)}
                                                </p>
                                                <p style={{ ...Styles.TimelineDate }}>
                                                    {item.time && item.time.slice(0, 10)}
                                                </p>
                                            </>
                                        }
                                        dot={
                                            <p
                                                style={{
                                                    textAlign: 'center',
                                                    background: '#B3B3B3 ',
                                                    width: '1rem',
                                                    height: '1rem',
                                                    borderRadius: '50%',
                                                }}
                                            ></p>
                                        }
                                        color={item.type === '关闭' ? '#62C992' : '#FF867C'}
                                    >
                                        <p
                                            style={{
                                                ...Styles.TimelineState,
                                                color: item.type === '关闭' ? '#62C992' : '#FF867C',
                                            }}
                                        >
                                            门磁{item.type}
                                            <MyIcon
                                                type={
                                                    item.voltage <= 0
                                                        ? 'icondianchi-meidian'
                                                        : item.voltage > 0 && item.voltage <= 25
                                                        ? 'icondianchi-didianliang'
                                                        : item.voltage > 50 && item.voltage <= 75
                                                        ? 'icondianchi-zhongdianliang'
                                                        : item.voltage > 75 && item.voltage <= 99
                                                        ? 'icondianchi-gaodianliang'
                                                        : item.voltage === 100
                                                        ? 'icondianchi-mandian'
                                                        : 'icondianchi-meidian'
                                                }
                                                style={{
                                                    fontSize: '1.2rem',
                                                    verticalAlign: 'middle',
                                                    color: '#707070',
                                                    margin: '0 0.2rem 0 0.4rem',
                                                    marginTop: '-0.3rem',
                                                }}
                                            />
                                        </p>
                                        <p style={{ ...Styles.TimelineName }}>{item.address}</p>
                                    </Timeline.Item>
                                );
                            })}
                        </Timeline>
                    )}
                </Loading>
            </Col>
        </Row>
    );
};

//用电趋势图表
const EchartView = ({ consumption, yData, xTime, detailsData, categoryValue, unit }) => {
    return (
        <Loading show={consumption.isLoading}>
            {isEmpty(consumption.data) ? (
                <EmptyView />
            ) : (
                <EchartBar
                    yData={yData}
                    xTime={xTime}
                    names={
                        detailsData.category &&
                        (detailsData.category.id === 'FE00' || detailsData.category.id === 'FE01')
                            ? categoryValue
                            : detailsData.category && detailsData.category.id === 'FB00'
                            ? '电流'
                            : '用能趋势'
                    }
                    style={{ height: '100%' }}
                    top={'-10px'}
                    bottom={'3%'}
                    unit={unit}
                />
            )}
        </Loading>
    );
};

//工业电机 超出用：#E98164
const IndustrialMotor = ({ detailsData, id }) => {
    //傅里叶的上限 下限 实时三条折线的颜色
    const colors = [
        {
            color: '#E3F3F1',
            line: '#4BC890',
            shadowColor: '#F3F3F3',
            opacity: 1,
        },
        {
            color: '#fff',
            line: '#4BC890',
            shadowColor: '#F3F3F3',
            opacity: 1,
        },
        {
            color: 'transparent',
            line: '#FFC569',
            shadowColor: '#F3F3F3',
            opacity: 1,
        },
    ];
    //原始数据的折线的颜色
    const colors2 = [
        {
            color: 'transparent',
            line: '#1ed0c9',
            shadowColor: '#F3F3F3',
            opacity: 0.6,
        },
        {
            color: 'transparent',
            line: '#FFBE0F',
            shadowColor: '#F3F3F3',
            opacity: 1,
        },
        {
            color: 'transparent',
            line: '#FFBE0F',
            shadowColor: '#F3F3F3',
            opacity: 1,
        },
    ];
    // const [time, setTime] = useState(toDay); //搜索时间字段
    const [data, setData] = useState({ data: null, isLoading: true });
    const [categoryValue, setCategoryValue] = useState('rawData');
    useEffect(() => {
        // getSmoke(time);
        getSmoke();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detailsData.category && detailsData.category.id, categoryValue]);
    const getSmoke = time => {
        let searchParams = {};
        // searchParams.start_time =
        //     time !== null && time !== undefined && time[0] !== '' && time[0] !== undefined ? time[0] : undefined;
        // searchParams.end_time =
        //     time !== null && time !== undefined && time[1] !== '' && time[1] !== undefined ? time[1] : undefined;
        let temps = categoryValue === 'temp' ? 'temp' : undefined;
        searchParams.temp = temps;
        const params = omitBy(searchParams, value => {
            if (isNaN(value) || value === '') {
                return isUndefined(value) || isNull(value) || value === '';
            } else {
                return isNull(value);
            }
        });
        setData({ data: null, isLoading: true });
        MotorDetailDevice({ device_id: id.slice(3), ...params })
            .then(result => {
                //温度
                let xTime = [];
                let yTemp = [];
                // 电机原始值
                let oldData = { x: [], y: [], z: [] };
                //点击傅里叶数据
                let datasFly = { x: [], y: [], z: [] };
                let datasFlyS = { x: [], y: [], z: [] };
                let datasFlyX = { x: [], y: [], z: [] };
                //赫兹轴x和时间x轴的数据
                // let xData = [];
                let xData2 = [];
                if (categoryValue === 'rawData备份') {
                    // xData =
                    //     categoryValue === 'rawData' &&
                    //     result.data &&
                    //     result.data.history_mean[0].map(item => {
                    //         return peakvalue8(item);
                    //     });
                    xData2 =
                        categoryValue === 'rawData' &&
                        result.data &&
                        result.data.old_data[0].map(item => {
                            return item.slice(11, 26);
                        });
                    // 傅里叶
                    datasFly.x = result.data.v_fix[1].map((item, index) => {
                        // if (result.data.history_mean[1][index] <= item || item <= result.data.history_mean[2][index]) {
                        //     return (item = [result.data.v_fix[0][index],{
                        //         value: peakvalue8(item),
                        //         itemStyle: { color: '#E98164' },
                        //         lineStyle: {
                        //             color: '#E98164',
                        //         },
                        //     }]);
                        // } else {
                        //     return (item = [result.data.v_fix[0][index],{
                        //         value: peakvalue8(item),
                        //         itemStyle: { color: '#FFC569' },
                        //     }]);
                        // }
                        return [peakvalue8(result.data.v_fix[0][index]), peakvalue8(item)];
                    });
                    // 傅里叶上限
                    datasFlyS.x = result.data.history_mean[1].map((item, index) => {
                        return [peakvalue8(result.data.history_mean[0][index]), peakvalue8(item)];
                    });
                    // 傅里叶下限
                    datasFlyX.x = result.data.history_mean[2].map((item, index) => {
                        return [peakvalue8(result.data.history_mean[0][index]), peakvalue8(item)];
                    });
                    datasFly.y = result.data.v_fix[2].map((item, index) => {
                        if (result.data.history_mean[3][index] <= item || item <= result.data.history_mean[4][index]) {
                            return (item = [
                                result.data.v_fix[0][index],
                                {
                                    value: peakvalue8(item),
                                    itemStyle: { color: '#E98164' },
                                    index: index,
                                },
                            ]);
                        } else {
                            return (item = [
                                result.data.v_fix[0][index],
                                {
                                    value: peakvalue8(item),
                                    itemStyle: { color: '#FFC569' },
                                    index: index,
                                },
                            ]);
                        }
                    });
                    datasFly.z = result.data.v_fix[3].map((item, index) => {
                        if (result.data.history_mean[5][index] <= item || item <= result.data.history_mean[6][index]) {
                            return (item = [
                                result.data.v_fix[0][index],
                                {
                                    value: peakvalue8(item),
                                    itemStyle: { color: '#E98164' },
                                },
                            ]);
                        } else {
                            return (item = [
                                result.data.v_fix[0][index],
                                {
                                    value: peakvalue8(item),
                                    itemStyle: { color: '#FFC569' },
                                },
                            ]);
                        }
                    });
                    // 原始值
                    oldData.x = result.data.old_data[1].map((item, index) => {
                        if (result.data.upper_lower[0][0] <= item || item <= result.data.upper_lower[0][1]) {
                            return (item = {
                                value: peakvalue8(item),
                                // itemStyle: { color: '#1ed0c9' },
                            });
                        } else {
                            return (item = {
                                value: peakvalue8(item),
                                // itemStyle: { color: '#E98164' },
                            });
                        }
                    });
                    oldData.y = result.data.old_data[2].map((item, index) => {
                        if (result.data.upper_lower[0][0] <= item || item <= result.data.upper_lower[0][1]) {
                            return (item = {
                                value: peakvalue8(item),
                                // itemStyle: { color: '#1ed0c9' },
                            });
                        } else {
                            return (item = {
                                value: peakvalue8(item),
                                // itemStyle: { color: '#E98164' },
                            });
                        }
                    });
                    oldData.z = result.data.old_data[3].map((item, index) => {
                        if (result.data.upper_lower[0][0] <= item || item <= result.data.upper_lower[0][1]) {
                            return (item = {
                                value: peakvalue8(item),
                                // itemStyle: { color: '#1ed0c9' },
                            });
                        } else {
                            return (item = {
                                value: peakvalue8(item),
                                // itemStyle: { color: '#E98164' },
                            });
                        }
                    });
                } else if (categoryValue === 'rawData') {
                    xData2 =
                        categoryValue === 'rawData' &&
                        result.data &&
                        result.data.old_data[0].map(item => {
                            return item.slice(11, 26);
                        });
                } else {
                    map(result.data, item => {
                        xTime.push(item[0].slice(11, 19));
                        yTemp.push(item[1] === null || isUndefined(item[1]) ? undefined : peakvalue8(item[1])); //X
                    });
                }
                let sum =
                    result.data.old_data &&
                    result.data.old_data[1].map((item, index) => {
                        return peakvalue8(
                            Math.sqrt(
                                result.data.old_data[1][index] ** 2 +
                                    result.data.old_data[2][index] ** 2 +
                                    result.data.old_data[3][index] ** 2,
                            ),
                        );
                    });
                categoryValue === 'temp'
                    ? setData({
                          data: [
                              {
                                  data: [yTemp],
                                  legend: ['电机表面温度'],
                                  time: xTime,
                              },
                          ],
                          isLoading: false,
                      })
                    : setData({
                          //   data: [
                          //       {
                          //         //   data: [[result.data.history_mean[0],result.data.history_mean[1]], [result.data.history_mean[0],result.data.history_mean[2]], datasFly.x],
                          //         //   data: [datasFlyS.x, datasFlyX.x, datasFly.x],
                          //           data2: [oldData.x],
                          //           legend: ['X历史傅里叶上限', 'X历史傅里叶下限', 'X实时傅里叶'],
                          //           time: xData,
                          //           time2: xData2,
                          //           legend2: ['x原始数据'],
                          //           max:peakvalue8(result.data.upper_lower[0][0]),
                          //           min:peakvalue8(result.data.upper_lower[0][1]),
                          //       },
                          //       {
                          //         //   data: [result.data.history_mean[3], result.data.history_mean[4], datasFly.y],
                          //           data2: [oldData.y],
                          //           legend: ['Y历史傅里叶上限', 'Y历史傅里叶下限', 'Y实时傅里叶'],
                          //           time: xData,
                          //           time2: xData2,
                          //           legend2: ['y原始数据'],
                          //           max:peakvalue8(result.data.upper_lower[1][0]),
                          //           min:peakvalue8(result.data.upper_lower[1][1]),
                          //       },
                          //       {
                          //         //   data: [result.data.history_mean[5], result.data.history_mean[6], datasFly.z],
                          //           data2: [oldData.z],
                          //           legend: ['Z历史傅里叶上限', 'Z历史傅里叶下限', 'Z实时傅里叶'],
                          //           time: xData,
                          //           time2: xData2,
                          //           legend2: ['z原始数据'],
                          //           max:peakvalue8(result.data.upper_lower[2][0]),
                          //           min:peakvalue8(result.data.upper_lower[2][1]),
                          //       },
                          //   ],
                          data: [
                              {
                                  data2: [sum],
                                  time2: xData2,
                                  legend2: ['向量积'],
                              },
                              {
                                  data2: [result.data.old_data[1]],
                                  time2: xData2,
                                  legend2: ['x原始数据'],
                              },
                              {
                                  data2: [result.data.old_data[2]],
                                  time2: xData2,
                                  legend2: ['y原始数据'],
                              },
                              {
                                  data2: [result.data.old_data[3]],
                                  time2: xData2,
                                  legend2: ['z原始数据'],
                              },
                          ],
                          isLoading: false,
                      });
            })
            .catch(() => {
                setData({ data: null, isLoading: false });
            });
    };
    return (
        <Row style={{ background: '#fff', padding: '1rem' }}>
            <Col span={4}>
                <div style={{ ...Styles.detailsTitle }}>
                    电机监测
                    <span style={{ ...Styles.mark }}>
                        {detailsData.status && status[detailsData.status] === '离线' ? '(离线前采集数据)' : ''}
                    </span>
                </div>
            </Col>
            <Col
                offset={4}
                span={13}
                style={{
                    paddingBottom: '1rem',
                }}
            >
                {/* <TimeSearch
                    timeValue={toDay}
                    timeType={['toDay', 'day7', 'day30', 'custom']}
                    onValueChange={times => {
                        const { time } = times;
                        setTime(time);
                    }}
                    isDisabledDate={true}
                /> */}
            </Col>
            {/* 按要求先注释掉表面温度的切换----<Col span={3} style={{ marginTop: '0.6rem' }}>
                <Select
                    placeholder="请选择"
                    virtual={false}
                    showArrow
                    defaultValue={categoryValue}
                    // value={category.length > 0 ? categoryValue : undefined}
                    style={{ position: 'absolute', zIndex: 999, right: '0rem', top: '-0.5rem', width: '11rem' }}
                    onChange={e => setCategoryValue(e)}
                >
                    <Option value={'rawData'} key={1}>
                        电机振动原始数据
                    </Option>
                    <Option value={'temp'} key={2}>
                        电机表面温度
                    </Option>
                </Select>
            </Col> */}
            {categoryValue === 'rawData' ? (
                <Col span={24} style={{ minHeight: '40vh' }}>
                    <Loading show={data.isLoading}>
                        {isEmpty(data.data) ? (
                            <EmptyView />
                        ) : (
                            data.data.map((item, index) => {
                                return (
                                    <div
                                        style={{
                                            // height: '70vh',
                                            margin: '2rem 0 1rem',
                                            borderBottom: '1px solid #999',
                                        }}
                                        key={index}
                                    >
                                        {/* <AreaLineChart
                                            data={item}
                                            color={colors}
                                            dataZoom={false}
                                            unit={'m/s²'}
                                            height={'60%'}
                                        />
                                        <LineCharts
                                            data={{
                                                data: item.data2,
                                                legend: item.legend2,
                                                time: item.time2,
                                                max: item.max,
                                                min: item.min,
                                            }}
                                            color={colors2}
                                            dataZoom={false}
                                            unit={'m/s²'}
                                            height={'40%'}
                                        /> */}
                                        <Tooltip
                                            title="g=9.7913m/s2,g值受海拔、纬度影响"
                                            style={{ marginLeft: '10px', position: 'absolute' }}
                                        >
                                            <span style={{ verticalAlign: 'middle' }}>单位：g </span>
                                            <MyIcon
                                                type="icon_tishi"
                                                style={{ verticalAlign: 'middle', marginTop: '5px' }}
                                            />
                                        </Tooltip>
                                        <LineCharts
                                            data={{
                                                data: item.data2,
                                                legend: item.legend2,
                                                time: item.time2,
                                            }}
                                            color={colors2}
                                            dataZoom={false}
                                            height={'40%'}
                                        />
                                    </div>
                                );
                            })
                        )}
                    </Loading>
                </Col>
            ) : (
                <Col span={24} style={{ height: '40vh' }}>
                    <Loading show={data.isLoading}>
                        {isEmpty(data.data) ? (
                            <EmptyView />
                        ) : (
                            data.data.map((item, index) => {
                                return (
                                    <AreaLineChart data={item} color={colors} dataZoom={true} unit={'℃'} key={index} />
                                );
                            })
                        )}
                    </Loading>
                </Col>
            )}
        </Row>
    );
};

const Styles = {
    detailsName: {
        marginBottom: '0.2rem',
        fontSize: '0.93rem',
        lineHeight: '30px',
        color: '#666666',
    },
    detailsValue: {
        marginLeft: '1rem',
        fontSize: '1rem',
        lineHeight: '30px',
        color: '#595959',
        display: 'inline-block',
    },
    detailsTitle: {
        color: '#333',
        fontSize: '1.1rem',
        lineHeight: '30px',
        marginBottom: '0.2rem',
    },
    mark: {
        fontSize: '0.7rem',
        color: '#F8BF73',
        marginLeft: '0.5rem',
    },
    TimelineTime: {
        color: '#7A7A7A',
        fontSize: '0.875rem',
        fontWeight: 600,
    },
    TimelineDate: {
        color: '#707070',
        fontSize: '0.75rem',
    },
    TimelineState: {
        color: '#7A7A7A',
        fontSize: '0.875rem',
        fontWeight: 600,
    },
    TimelineName: {
        color: '#7A7A7A',
        fontSize: '0.75rem',
    },
    btn: {
        fontSize: '14px',
        background: '#02A7F0',
        color: '#fff',
        padding: '0 20px',
        lineHeight: '30px',
        height: '30px',
        borderRadius: '5px',
        cursor: 'pointer',
    },
};
