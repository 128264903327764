import React, { useState, useEffect, useMemo } from 'react';
import { Button, Col, Row, Table, Select, message } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { isEmpty, map, isUndefined } from 'lodash';
import { BreadcrumbCustom } from '../../../components/BreadcrumbCustom';
import { EmptyView } from '../../../components/EmptyView';
import { Loading } from '../../../components/Loading';
import { CommonTable } from '../../../components/CommonTable';
import { ChartBarLine } from '../EnergyConsumptionChart/Chart/ChartBarLine';
import { ReportPieEchart } from './ChartReport/reportPieEchart';
import { ReportBarEchart } from './ChartReport/reportBarEchart';
import { ReportLineEchart } from './ChartReport/reportLineEchart';
import { ReportPieRegionEchart } from '../Report/ChartReport/reportPieRegionEchart';
import { MoreLineEchart } from '../../../components/Echart/MoreLineEchart';
import { ChartRadar } from '../HiddenDanger/Chart/ChartRadar';
import { PieChart } from '../EnergyConsumptionChart/Chart/PieChart';
import { ChartBar } from '../HiddenDanger/Chart/ChartBar';
import { timestampToTime, peakvalue } from '../../../util/function-util';
import { MyIcon } from '../../../util/iconfont';
// import { downloadReport } from '../../../util/report-download';
import ReportBg from '../../../assets/images/reportBg.png';
import {
    report,
    PieChart_COLOR2,
    COLOR_PLATE_16,
    colorList,
    alarmLevel,
    BarChart_COLOR,
    Chart_COLOR,
    Chart_COLOR2,
    colorReport,
    colorReportRegion,
    colorAlarm,
} from '../../../global';
import {
    getCrontabDate,
    getReportTrend,
    getReportEvent,
    getReportGrade,
    getUsersInfo,
    getAreaPie,
    getPeakHour,
    getPatrolTag,
    requestPieChart,
    getEnterpriseOverview,
    getThreePhase,
    getReport,
    getReportFunction,
    getReportRegion,
    getReportPowerTrend
} from '../../../api/webServe';
import './style.less';
import healthReportStyle from './healthReport.module.less';

const Option = Select.Option;

export const HealthReport = () => {
    return (
        <div className="container clearfix ">
            <BreadcrumbCustom paths={['首页', '健康报表', '健康详情']} className="rumbCustom" />
            <div
                className="row clearfix"
                style={{ backgroundColor: '#fff', margin: '1rem', padding: '0', borderRadius: '10px' }}
            >
                <ReportComponents />
            </div>
        </div>
    );
};

const ReportComponents = () => {
    const [data, setData] = useState({ data: null, isLoading: true }); //报表时间数据集
    const [count, setCount] = useState(0); //数据总条数
    const [page, setPage] = useState(1); //当前页
    const [page_size, setPageSize] = useState(10); //当前页显示条数
    const [dateFilter, setDateFilter] = useState('m'); //报表时间类型
    const [selectRowIndex, setSelectRowIndex] = useState(0); //当前默认选中索引
    const [selectedDateTime, setSelectedDateTime] = useState(); //当前选中日期
    useEffect(() => {
        getDate();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateFilter]);
    const getDate = () => {
        setData({ data: null, isLoading: true });
        getCrontabDate({ date: dateFilter })
            .then(result => {
                result = result.data;
                setData({ data: result, isLoading: false });
                setCount(result.total);
                setSelectedDateTime(result && result[0]);
                setSelectRowIndex(0);
            })
            .catch(() => {
                setData({ data: null, isLoading: false });
                setSelectRowIndex(0);
            });
    };
    const columns = [
        {
            title: '报告日期',
            dataIndex: 'time',
            key: 'time',
            render: (_, record) => (
                <p
                    style={{
                        minWidth: '8rem',
                        maxWidth: '12rem',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                        cursor: 'pointer',
                    }}
                    title={record.time}
                    key={record.time}
                >
                    {record.time}
                </p>
            ),
        },
    ];
    /**
     *  表格分页
     */
    const pagination = {
        pageSize: page_size, //每页的条数
        current: page, //当前页
        total: count, //总数据
        showSizeChanger: true,
        onChange: page => {
            setPage(page);
        }, //点击分页号时触发的方法
        onShowSizeChange: (current, pageSize) => {
            setPageSize(pageSize);
        }, //pageSize 变化的回调
        hideOnSinglePage: true, //为true则数据条数不满或刚好一页，则隐藏分页器
        simple: true,
    };

    const rowClassName = (record, index) => {
        if (index === selectRowIndex) {
            // setSelectRowIndex(index);
            return 'selected_row';
        } else {
            return '';
        }
    };
    return (
        <>
            <Row
                gutter={32}
                style={{
                    width: '100%',
                    height: '50px',
                    lineHeight: '50px',
                    // background: '#f2f2f2',
                    boxShadow: '0px 2px 3px #e7ebeb',
                    background: 'linear-gradient(180deg, #fefefe 0%, #f5f6fa 100%)',
                    borderTopLeftRadius: '10px',
                    borderTopRightRadius: '10px',
                    margin: '0',
                }}
            >
                <Col
                    span={4}
                    // className="common_title"
                    className={healthReportStyle.container}
                >
                    <Select
                        value={dateFilter}
                        onChange={value => {
                            setDateFilter(value);
                        }}
                        virtual={false}
                        style={{width:'99%', textAlign: 'center'}}
                    >
                        {report.map(item => {
                            return (
                                <Option value={item.id} key={item.id}>
                                    {item.name}
                                </Option>
                            );
                        })}
                    </Select>
                </Col>
                <Col span={20} className="common_title" style={{ boxShadow: 'none', background: 'none' }}>
                    <Row>
                        <Col span={20}>
                            <MyIcon
                                type="icon_title_report_details"
                                style={{
                                    fontSize: '1.5rem',
                                    marginTop: '-0.1rem',
                                    verticalAlign: 'middle',
                                    paddingRight: '0.4rem',
                                    height: 50,
                                    lineHeight: '60px',
                                }}
                            />
                            <span style={{ verticalAlign: 'middle' }}>报表详情</span>
                        </Col>
                        <Col span={4} style={{ textAlign: 'right', marginTop: '-2px' }}>
                            <Button
                                onClick={() => {
                                    message.info('下载');
                                    //downloadReport();//前端解决下载pdf 功能
                                }}
                                style={{ background: '#64D399', border: '1px solid #64D399', color: '#fff' }}
                            >
                                <DownloadOutlined />
                                下载
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row gutter={32} style={{ width: '100%', padding: '1rem 0 1rem 1rem' }}>
                <Col span={4} style={{ height: '70vh' }}>
                    <Table
                        rowKey={record => record.time}
                        columns={columns}
                        dataSource={data.data}
                        loading={data.isLoading}
                        bordered={true}
                        scroll={{ x: '100%' }}
                        pagination={pagination}
                        locale={{
                            emptyText: '暂无数据',
                        }}
                        onRow={(record, index) => {
                            return {
                                onClick: () => {
                                    setSelectRowIndex(index);
                                    setSelectedDateTime(record);
                                }, // 点击行
                            };
                        }}
                        rowClassName={rowClassName}
                    />
                </Col>
                <Col
                    span={20}
                    style={{
                        borderRadius: '5px',
                        border: '1px solid #F0F0F0',
                        minHeight: '75vh',
                        padding: '1rem 4rem',
                        flex: '1',
                    }}
                >
                    <div className="commonStyle" id="HealthReport">
                        <Header selectedDateTime={selectedDateTime} />
                        <Section selectedDateTime={selectedDateTime} />
                    </div>
                </Col>
            </Row>
        </>
    );
};

const Header = ({ selectedDateTime }) => {
    const [data, setData] = useState({});
    useEffect(() => {
        getUsersInfo()
            .then(result => {
                setData(result.data);
            })
            .catch();
    }, []);
    return (
        <div
            className="header"
            style={{
                background: `URL(${ReportBg})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: '50% 50%',
            }}
        >
            <div className="info">
                <h1>智慧微电网管理系统</h1>
                <h2>综合健康报表</h2>
                <dl className="name">
                    <dt>企业名称：{data.enterprise_name}</dt>
                </dl>
                <dl className="address">
                    <dt>企业地址：{data.location}</dt>
                </dl>
                <dl className="date">
                    <dt>报告日期：{selectedDateTime && selectedDateTime.time}</dt>
                </dl>
            </div>
        </div>
    );
};

const Section = ({ selectedDateTime }) => {
    const [id] = useState(0);
    const [enterprise, setEnterprise] = useState({ isLoading: true, data: null }); //企业概况

    const [pieData, setPieData] = useState({ data: null, isLoading: true }); //能耗分析中的区域分布
    const [barData, setBarData] = useState({ data: null, isLoading: true }); //能耗分析中的功能排行
    const [piePowerTotal, setPiePowerTotal] = useState(null);
    const [powerData, setPowerData] = useState({ isLoading: true, data: null, color: BarChart_COLOR }); //能耗分析中的能耗趋势

    const [pieRegion, setPieRegion] = useState({ data: null, isLoading: true }); //电费分析中的区域分布
    const [piePeakData, setPiePeakData] = useState({ data: null, isLoading: true }); //电费分析中的时段分布
    const [pieExpenseTotal, setPieExpenseTotal] = useState(null);
    const [pieFunction, setPieFunction] = useState({ data: null, isLoading: true }); //电费分析中的功能分布
    const [expenseData, setExpenseData] = useState({ data: null, isLoading: true, color: BarChart_COLOR }); //电费趋势

    const [alarmEvent, setAlarmEvent] = useState({ isLoading: true, data: null }); //隐患分析中的告警分类
    const [gradeData, setGradeData] = useState({ isLoading: true, data: null }); //隐患分析中的告警级别
    const [alarmTrend, setAlarmTrend] = useState({ isLoading: true, data: null }); //隐患分析中的隐患趋势

    const [voltage, setVoltage] = useState({ data: null, isLoading: true }); //电路分析中的电压
    const [current, setCurrent] = useState({ data: null, isLoading: true }); //电路分析中的电流
    const [threeCurrent, setThreeCurrent] = useState({ data: null, isLoading: true }); //电路分析中的三相电流
    const [powerFactor, setPowerFactor] = useState({ isLoading: true, data: null }); //电路分析中的功率因数

    const [peakTableData, setTablePeakData] = useState({ data: null, isLoading: true }); //总结中峰谷数据（在能耗趋势中获取）
    const [reportData, setReportData] = useState({ data: null, isLoading: true });

    const times = useMemo(() => {
        if (isEmpty(selectedDateTime)) {
            return null;
        }
        return [selectedDateTime.start_timestamp, selectedDateTime.end_timestamp];
    }, [selectedDateTime]);
    useEffect(() => {
        if (isEmpty(selectedDateTime)) {
            return;
        }
        getEnterprise(times);
        getPeakData(times);
        getPieData(times);
        getBarData(times);
        getPowerData(times);
        getGradeData(times);
        getAlarmEventData(times);
        getAlarmTrendData(times);
        getCircuitAnalysis(times);
        getReportData();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDateTime]);
    //企业概况
    const getEnterprise = time => {
        setEnterprise({ data: null, isLoading: true });
        getEnterpriseOverview({ start_time: timestampToTime(time[0]), end_time: timestampToTime(time[1]) })
            .then(result => {
                result = result.data;
                //TODO 处理电费四舍五入的精度。（前端获取尖峰平谷数据处理为保留两位小数后相加得出expense）
                let newData = result.expense_list.map(item => {
                  return peakvalue(item)
                })
                result.expense = newData.reduce((pre,cur)=> pre + cur)
                setEnterprise({ data: [result], isLoading: false });
            })
            .catch(() => {
                setEnterprise({ data: null, isLoading: false });
            });
    };
    //区域分布（同能耗分析中用能分布）
    const getPieData = time => {
        setPieData({ data: null, isLoading: true });
        getAreaPie({ id, start_time: time[0], end_time: time[1], isCategory: true })
            .then(result => {
                result = result.data;
                setPieData({ data: result, isLoading: false });
            })
            .catch(() => {
                setPieData({ data: null, isLoading: false });
            });
    };
    //功能排行（同能耗统计中用能分布）
    const getBarData = time => {
        setBarData({ data: null, isLoading: true });
        requestPieChart({ id, start_time: time[0], end_time: time[1] })
            .then(result => {
                result = result.data;
                setBarData({ data: result.function, isLoading: false });
                setPiePowerTotal(result.total);
            })
            .catch(() => {
                setBarData({ data: null, isLoading: false });
            });
    };
    //能耗趋势 (同能耗分析中峰谷用电)
    const getPeakData = time => {
        setPowerData({ data: null, isLoading: true, color: Chart_COLOR });
        setTablePeakData({ data: null, isLoading: true }); //总结
        getPeakHour({ id, start_time: time[0], end_time: time[1] })
            .then(result => {
                let { trend, detail } = result.data,
                    legend,
                    data = [];
                if (!isUndefined(trend[0].data.tip_time)) {
                    legend = ['product', '平时', '谷时', '峰时', '尖时', '总用量'];
                    map(trend, (item, idx) => {
                        const everyOne = [];
                        everyOne.push(item.date);
                        everyOne.push(peakvalue(item.data.peacetime)); //平时
                        everyOne.push(peakvalue(item.data.valley)); //谷时
                        everyOne.push(peakvalue(item.data.peak_hour)); //峰时
                        everyOne.push(peakvalue(item.data.tip_time)); //尖时
                        everyOne.push(peakvalue(peakvalue(item.data.peacetime) + peakvalue(item.data.valley) + peakvalue(item.data.peak_hour) + peakvalue(item.data.tip_time))); //总用量
                        data.push(everyOne);
                    });
                } else {
                    //无尖时
                    legend = ['product', ' ', '谷时', '峰时', '总用量'];
                    map(trend, (item, idx) => {
                        const everyOne = [];
                        everyOne.push(item.date);
                        everyOne.push(peakvalue(item.data.peacetime)); //平时
                        everyOne.push(peakvalue(item.data.valley)); //谷时
                        everyOne.push(peakvalue(item.data.peak_hour)); //峰时
                        everyOne.push(peakvalue(peakvalue(item.data.peacetime) + peakvalue(item.data.valley) + peakvalue(item.data.peak_hour))); //总用量
                        data.push(everyOne);
                    });
                }
                data.unshift(legend);
                setPowerData({
                    data: isEmpty(trend) ? null : data,
                    isLoading: false,
                    color: Object.keys(trend[0].data).length === 5 ? Chart_COLOR : Chart_COLOR2,
                });
                setTablePeakData({ data: detail, isLoading: false }); //总结中峰谷数据
            })
            .catch(() => {
                setPowerData({ data: null, isLoading: false, color: Chart_COLOR });
                setTablePeakData({ data: null, isLoading: false });
            });
    };
    //电费分析
    const getPowerData = time => {
        setExpenseData({ data: null, isLoading: true, color: Chart_COLOR });
        setPieFunction({ data: null, isLoading: true });
        setPiePeakData({ data: null, isLoading: true });
        setPieRegion({ data: null, isLoading: true });
        setPieExpenseTotal(null);
        getReportPowerTrend({ start_time: time[0], end_time: time[1] })
            .then(result => {
                result = result.data;
                let peak_hour_expense = result.peak_hour_expense.map(item => {
                    item.value = peakvalue(item.value);
                    return item;
                });
                
                let total = peak_hour_expense.reduce((a,b)=> {return a+b.value},0)  
                setPiePeakData({ data: peak_hour_expense, isLoading: false }); //电费时段分布
                setPieExpenseTotal(total); //时段分布总数
                let legend,
                    data = [];
                if (!isUndefined(result.trend[0].data.tip_time)) {
                    legend = ['product', '平时', '谷时', '峰时', '尖时', '总用量'];
                    map(result.trend, (item, idx) => {
                        const everyOne = [];
                        everyOne.push(item.date);
                        everyOne.push(peakvalue(item.data.peacetime)); //平时
                        everyOne.push(peakvalue(item.data.valley)); //谷时
                        everyOne.push(peakvalue(item.data.peak_hour)); //峰时
                        everyOne.push(peakvalue(item.data.tip_time)); //尖时
                        everyOne.push(peakvalue(peakvalue(item.data.peacetime) + peakvalue(item.data.valley) + peakvalue(item.data.peak_hour) + peakvalue(item.data.tip_time))); //总用量
                        data.push(everyOne);
                    });
                } else {
                    //无尖时
                    legend = ['product', '平时', '谷时', '峰时', '总用量'];
                    map(result.trend, (item, idx) => {
                        const everyOne = [];
                        everyOne.push(item.date);
                        everyOne.push(peakvalue(item.data.peacetime)); //平时
                        everyOne.push(peakvalue(item.data.valley)); //谷时
                        everyOne.push(peakvalue(item.data.peak_hour)); //峰时
                        everyOne.push(peakvalue(peakvalue(item.data.peacetime) + peakvalue(item.data.valley) + peakvalue(item.data.peak_hour))); //总用量
                        data.push(everyOne);
                    });
                }
                data.unshift(legend);
                //电费趋势
                setExpenseData({
                    data: isEmpty(result.trend) ? null : data,
                    isLoading: false,
                    color: Object.keys(result.trend[0].data).length === 5 ? Chart_COLOR : Chart_COLOR2,
                });
            })
            .catch(() => {
                setExpenseData({ data: null, isLoading: false, color: Chart_COLOR });
                setPiePeakData({ data: null, isLoading: false });
                setPieExpenseTotal(null);
            });
        //电费区域分布
        getReportRegion({start_time: time[0], end_time: time[1]})
            .then(result => {
                result = result.data;
                let region = result.region.map(item => {
                    item.value = peakvalue(item.value);
                    return item;
                });
                setPieRegion({ data: region, isLoading: false }); //电费区域分布
            })
            .catch(() => {
                setPieRegion({ data: null, isLoading: false });
            });
        //电费功能分布
        getReportFunction({start_time: time[0], end_time: time[1]})
        .then(result => {
            result = result.data;
            let functions = result.function.map(item => {
                item.value = peakvalue(item.value);
                return item;
            });
            setPieFunction({ data: functions, isLoading: false }); //电费功能分布
        })
        .catch(() => {
            setPieFunction({ data: null, isLoading: false });
        });
    };
    //（同隐患分析）隐患等级
    const getGradeData = time => {
        setGradeData({ data: null, isLoading: true });
        getReportGrade({ id, start_time: timestampToTime(time[0]), end_time: timestampToTime(time[1]) })
            .then(result => {
                result = result.data;
                let dataResult = map(result, item => {
                    let name = alarmLevel[item.grade];
                    let value = item.proportion === null ? 0 : item.proportion;
                    return { name: name, value: value };
                });
                setGradeData({ data: isEmpty(result) ? null : dataResult, isLoading: false });
            })
            .catch(() => {
                setGradeData({ data: null, isLoading: false });
            });
    };
    //（同隐患分析）隐患类型
    const getAlarmEventData = time => {
        setAlarmEvent({ data: null, isLoading: true });
        getReportEvent({ id, start_time: timestampToTime(time[0]), end_time: timestampToTime(time[1]) })
            .then(result => {
                result = result.data;
                setAlarmEvent({ isLoading: false, data: result });
            })
            .catch(() => {
                setAlarmEvent({ data: null, isLoading: false });
            });
    };
    //（同隐患分析）隐患趋势
    const getAlarmTrendData = time => {
        setAlarmTrend({ data: null, isLoading: true });
        getReportTrend({ id, start_time: timestampToTime(time[0]), end_time: timestampToTime(time[1]) })
            .then(result => {
                result.data &&
                    result.data.map(item => {
                        if (result.rule === 'h') {
                            item.time = item.time.slice(11, 19);
                        } else {
                            item.time = item.time.slice(0, 10);
                        }
                        return item;
                    });
                setAlarmTrend({ isLoading: false, data: result.data });
            })
            .catch(() => {
                setAlarmTrend({ isLoading: false, data: null });
            });
    };
    //电路分析
    const getCircuitAnalysis = time => {
        setVoltage({ data: null, isLoading: true }); //电压
        getPatrolTag(id, { name: '电压', start_time: time[0], end_time: time[1] })
            .then(result => {
                setVoltage({ data: result.data, isLoading: false });
            })
            .catch(() => {
                setVoltage({ data: null, isLoading: false });
            });
        setCurrent({ data: null, isLoading: true }); //电流
        getPatrolTag(id, { name: '电流', start_time: time[0], end_time: time[1] })
            .then(result => {
                setCurrent({ data: result.data, isLoading: false });
            })
            .catch(() => {
                setCurrent({ data: null, isLoading: false });
            });
        setPowerFactor({ data: null, isLoading: true }); //功率因数
        getPatrolTag(id, { name: '功率因数', start_time: time[0], end_time: time[1] })
            .then(result => {
                setPowerFactor({ data: result.data, isLoading: false });
            })
            .catch(() => {
                setPowerFactor({ data: null, isLoading: false });
            });
        setThreeCurrent({ data: null, isLoading: true }); //三相电流
        getThreePhase({ start_time: time[0], end_time: time[1] })
            .then(result => {
                result = result.data;
                let legend,
                    data = [];
                legend = ['product', 'a相', 'b相', 'c相'];
                map(result, (item, idx) => {
                    const everyOne = [];
                    everyOne.push(item.date);
                    everyOne.push(peakvalue(item.data.a)); //平时
                    everyOne.push(peakvalue(item.data.b)); //谷时
                    everyOne.push(peakvalue(item.data.c)); //峰时
                    data.push(everyOne);
                });
                data.unshift(legend);
                setThreeCurrent({ isLoading: false, data: isEmpty(result) ? null : data });
            })
            .catch(() => {
                setThreeCurrent({ data: null, isLoading: false });
            });
    };
    //总结
    const getReportData = () => {
        setReportData({ data: null, isLoading: true });
        getReport()
            .then(result => {
                setReportData({ data: result.result, isLoading: false });
            })
            .catch(() => {
                setReportData({ data: null, isLoading: false });
            });
    };
    return (
        <div className="section">
            <Row>
                <Mode1 enterprise={enterprise} />
                <Mode2 barData={barData} pieData={pieData} powerData={powerData} piePowerTotal={piePowerTotal} />
                <Mode3
                    pieRegion={pieRegion}
                    piePeakData={piePeakData}
                    pieExpenseTotal={pieExpenseTotal}
                    pieFunction={pieFunction}
                    expenseData={expenseData}
                />
                <Mode4 gradeData={gradeData} alarmEvent={alarmEvent} alarmTrend={alarmTrend} />
                <Mode5 voltage={voltage} current={current} powerFactor={powerFactor} threeCurrent={threeCurrent} />
                <Mode6 peakTableData={peakTableData} reportData={reportData} />
            </Row>
        </div>
    );
};
//企业概况
const Mode1 = ({ enterprise }) => {
    const columns = [
        {
            title: '申报需量',
            dataIndex: 'declare_power',
            render: text => <span>{text || '— '}kW</span>,
        },
        {
            title: '用电总量',
            dataIndex: 'total_power',
            render: text => <span>{(text && text.toFixed(2)) || '— '} kWh</span>,
        },
        {
            title: '参考电费',
            dataIndex: 'expense',
            render: text => <span>{(text && text.toFixed(2)) || '— '} 元</span>,
        },
        {
            title: '设备总量',
            dataIndex: 'device_count',
            render: text => <span>{text || '— '} 个</span>,
        },
        {
            title: '隐患总量',
            dataIndex: 'alarm_count',
            render: text => <span>{text || '— '} 个</span>,
        },
    ];
    return (
        <Col span={24}>
            <div className="reportTitle">1.企业概况</div>
            <CommonTable peakValleyData={enterprise} columns={columns} className="reportSurveyTableStyle" />
        </Col>
    );
};
//能耗分析
const Mode2 = ({ pieData, barData, powerData, piePowerTotal }) => {
    return (
        <Col span={24}>
            <div className="reportTitle">2. 能耗分析</div>
            <Row gutter={16}>
                <Col span={24}>
                    <div className="reportSubtitle">2.1 能耗分布</div>
                </Col>
                <Col span={10}>
                    <Loading show={pieData.isLoading}>
                        <div style={{ textAlign: 'center', fontSize: '1rem', color: '#595959' }}>区域分布</div>
                        {isEmpty(pieData.data) ? (
                            <EmptyView />
                        ) : (
                            <PieChart
                                data={pieData.data}
                                color={colorReportRegion}
                                total={piePowerTotal}
                                style={{ height: '30vh' }}
                            />
                        )}
                    </Loading>
                </Col>
                <Col span={14}>
                    <Loading show={barData.isLoading}>
                        <div style={{ textAlign: 'center', fontSize: '1rem', color: '#595959' }}>功能排行</div>
                        {isEmpty(barData.data) ? (
                            <EmptyView />
                        ) : (
                            <ReportBarEchart rawData={barData.data} color={COLOR_PLATE_16} style={{ height: '30vh' }} unit={''} showColor={true}/>
                        )}
                    </Loading>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <div className="reportSubtitle">2.2 能耗趋势</div>
                </Col>
                <Col span={24} style={{ height: 500 }}>
                    <Loading show={powerData.isLoading}>
                        {isEmpty(powerData.data) ? (
                            <EmptyView />
                        ) : (
                            <ChartBarLine
                                rawData={powerData.data}
                                color={powerData.color}
                                stack={'stack'}
                                showSeries={true}
                                unit={'kWh'}
                            />
                        )}
                    </Loading>
                </Col>
            </Row>
        </Col>
    );
};
//电费分析
const Mode3 = ({ pieRegion, piePeakData, pieExpenseTotal, pieFunction, expenseData }) => {
    return (
        <Col span={24}>
            <div className="reportTitle">3. 电费分析</div>
            <Row gutter={16}>
                <Col span={24}>
                    <div className="reportSubtitle">3.1 电费分布</div>
                </Col>
                <Col span={8}>
                    <Loading show={pieRegion.isLoading}>
                        <div style={{ textAlign: 'center', fontSize: '1rem', color: '#595959' }}>区域分布</div>
                        {isEmpty(pieRegion.data) ? (
                            <EmptyView />
                        ) : (
                            <ReportPieRegionEchart
                                rawData={pieRegion.data}
                                color={colorReport}
                                style={{ height: '28vh' }}
                            />
                        )}
                    </Loading>
                </Col>
                <Col span={8}>
                    <Loading show={piePeakData.isLoading}>
                        <div style={{ textAlign: 'center', fontSize: '1rem', color: '#595959' }}>时段分布</div>
                        {isEmpty(piePeakData.data) ? (
                            <EmptyView />
                        ) : (
                            <PieChart
                                data={piePeakData.data}
                                color={Chart_COLOR}
                                total={pieExpenseTotal}
                                style={{ height: '28vh' }}
                            />
                        )}
                    </Loading>
                </Col>
                <Col span={8}>
                    <Loading show={pieFunction.isLoading}>
                        <div style={{ textAlign: 'center', fontSize: '1rem', color: '#595959' }}>功能分布</div>
                        {isEmpty(pieFunction.data) ? (
                            <EmptyView />
                        ) : (
                            <ReportPieEchart rawData={pieFunction.data} color={colorList} style={{ height: '28vh' }} />
                        )}
                    </Loading>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <div className="reportSubtitle">3.2 电费趋势</div>
                </Col>
                <Col span={24} style={{ height: 500 }}>
                    <Loading show={expenseData.isLoading}>
                        {isEmpty(expenseData.data) ? (
                            <EmptyView />
                        ) : (
                            <ChartBarLine
                                rawData={expenseData.data}
                                color={expenseData.color}
                                stack={'stack'}
                                unit={'元'}
                                showSeries={true}
                            />
                        )}
                    </Loading>
                </Col>
            </Row>
        </Col>
    );
};
//隐患分析
const Mode4 = ({ gradeData, alarmEvent, alarmTrend }) => {
    return (
        <Col span={24}>
            <div className="reportTitle">4. 隐患分析</div>
            <Row gutter={16}>
                <Col span={24}>
                    <div className="reportSubtitle">4.1 隐患分布</div>
                </Col>
                <Col span={12}>
                    <Loading show={gradeData.isLoading}>
                        <div style={{ textAlign: 'center', fontSize: '1rem', color: '#595959' }}>告警级别</div>
                        {isEmpty(gradeData.data) ? (
                            <EmptyView />
                        ) : (
                            <ReportPieEchart rawData={gradeData.data} color={colorAlarm} />
                        )}
                    </Loading>
                </Col>
                <Col span={12}>
                    <Loading show={alarmEvent.isLoading}>
                        <div style={{ textAlign: 'center', fontSize: '1rem', color: '#595959' }}>告警分类</div>
                        {isEmpty(alarmEvent.data) ? (
                            <EmptyView />
                        ) : (
                            <ChartRadar rawData={alarmEvent.data} color={PieChart_COLOR2} />
                        )}
                    </Loading>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <div className="reportSubtitle">4.2 隐患趋势</div>
                </Col>
                <Col span={24} style={{ height: '35vh' }}>
                    <Loading show={alarmTrend.isLoading}>
                        {isEmpty(alarmTrend.data) ? (
                            <EmptyView />
                        ) : (
                            <ChartBar
                                rawData={alarmTrend.data}
                                color={COLOR_PLATE_16}
                                bottom={'10%'}
                                showToolbox={false}
                                // name={'隐患趋势'}
                                unit={'个'}
                                showColor={true}
                            />
                        )}
                    </Loading>
                </Col>
            </Row>
        </Col>
    );
};
//电路分析
const Mode5 = ({ voltage, current, threeCurrent, powerFactor }) => {
    return (
        <Col span={24}>
            <div className="reportTitle">5. 电路分析</div>
            <Row gutter={16}>
                <Col span={24}>
                    <div className="reportSubtitle">5.1 电压</div>
                </Col>
                <Col span={24} style={{ height: '35vh' }}>
                    <Loading show={voltage.isLoading}>
                        {isEmpty(voltage.data) ? (
                            <EmptyView />
                        ) : (
                            <ReportLineEchart
                                rawData={voltage.data}
                                color={COLOR_PLATE_16}
                                bottom={'10%'}
                                showToolbox={false}
                                name={'电压'}
                            />
                        )}
                    </Loading>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <div className="reportSubtitle">5.2 电流</div>
                </Col>
                <Col span={24} style={{ height: '35vh' }}>
                    <Loading show={current.isLoading}>
                        {isEmpty(current.data) ? (
                            <EmptyView />
                        ) : (
                            <ReportLineEchart
                                rawData={current.data}
                                color={COLOR_PLATE_16}
                                bottom={'10%'}
                                showToolbox={false}
                                name={'电流'}
                            />
                        )}
                    </Loading>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <div className="reportSubtitle">5.3 三相电流</div>
                </Col>
                <Col span={24} style={{ height: '35vh' }}>
                    <Loading show={threeCurrent.isLoading}>
                        {' '}
                        {isEmpty(threeCurrent.data) ? (
                            <EmptyView />
                        ) : (
                            <MoreLineEchart
                                rawData={threeCurrent.data}
                                color={colorReport}
                                bottom={'10%'}
                                name={'三相电流'}
                            />
                        )}
                    </Loading>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <div className="reportSubtitle">5.4 功率因数</div>
                </Col>
                <Col span={24} style={{ height: '35vh' }}>
                    <Loading show={powerFactor.isLoading}>
                        {' '}
                        {isEmpty(powerFactor.data) ? (
                            <EmptyView />
                        ) : (
                            <ReportLineEchart
                                rawData={powerFactor.data}
                                color={colorList}
                                bottom={'10%'}
                                showToolbox={false}
                                showAreaStyle={true}
                                name={'功率因数'}
                            />
                        )}
                    </Loading>
                </Col>
            </Row>
        </Col>
    );
};
//总结
const Mode6 = ({ peakTableData, reportData }) => {
    const columnsPeakValley = [
        {
            title: '时段',
            dataIndex: 'name',
        },
        {
            title: '用电量',
            dataIndex: 'power',
            render: text => <span>{peakvalue(text)}kWh</span>,
        },
        {
            title: '电费',
            dataIndex: 'expense',
            render: text => <span>{peakvalue(text)}元</span>,
        },
    ];
    return (
        <Col span={24}>
            <div className="reportTitle">6. 总结</div>
            <Row gutter={16}>
                <Col span={12} style={{ fontSize: '1rem', color: '#595959' }}>
                    <Loading show={reportData.isLoading}>
                        {isEmpty(reportData.data) ? (
                            <EmptyView />
                        ) : (
                            reportData.data.map((report, index) => {
                                return (
                                    <div key={index + 'reportData'}>
                                        <span style={{ color: '#595959', fontWeight: 600, fontSize: '1.2rem' }}>
                                            {report.category}
                                        </span>
                                        {report.content.map((item, index) => {
                                            return (
                                                <p key={index + 'report'}>
                                                    {index + 1}. {item}
                                                </p>
                                            );
                                        })}
                                    </div>
                                );
                            })
                        )}
                    </Loading>
                </Col>
                <Col span={12}>
                    <CommonTable
                        peakValleyData={peakTableData}
                        columns={columnsPeakValley}
                        className="reportSummaryTableStyle"
                    />
                </Col>
            </Row>
        </Col>
    );
};
