import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import { BreadcrumbCustom } from '../../../components/BreadcrumbCustom';
import { Button, Col, Form, Row, Table, Popconfirm, Input, Divider, Typography, Space, Select } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { CommonTree } from '../../../components/CommonTree';
import { CommonModal } from '../../../components/CommoModal';
import { commonPageSize } from '../../../global';

const FormItem = Form.Item;
// const initialPanes = [
//     { title: 'A区', key: '1' },
//     { title: 'B区', key: '2' },
//     {
//         title: 'C区',
//         key: '3',
//     },
// ];

// const MonitorTab = () => {
//     const [panes, setPanes] = useState([]);
//     const [activeKey, setActiveKey] = useState('1');
//     useEffect(() => {
//         setPanes(initialPanes);
//     }, []);
//     return (
//         <div className="container clearfix">
//             <BreadcrumbCustom paths={['首页', '监测管理', '监测点管理']} className="rumbCustom" />
//             <div className="row clearfix">
//                 <Row gutter={24} style={{ width: '100%' }}>
//                     <Col span={24}>
//                         <Tabs
//                             type="editable-card"
//                             onChange={value => {
//                                 setActiveKey(value);
//                             }}
//                             activeKey={activeKey}
//                             onEdit={(targetKey, action) => {
//                                 const doSomething = {
//                                     remove: () => {
//                                         const result = filter(panes, item => {
//                                             return item.key !== targetKey;
//                                         });
//                                         setPanes(result);
//                                     },
//                                     add: () => {
//                                         const result = concat(panes, {});
//                                     },
//                                 };
//                             }}
//                         >
//                             {map(panes, pane => (
//                                 <TabPane tab={pane.title} key={pane.key} closable={pane.closable}>
//                                     <MonitorManagement areaCode={1} />
//                                 </TabPane>
//                             ))}
//                         </Tabs>
//                     </Col>
//                 </Row>
//             </div>
//         </div>
//     );
// };

export const MonitorManagement = () => {
    return (
        <div className="container clearfix">
            <BreadcrumbCustom paths={['首页', '监测管理', '监测点管理']} className="rumbCustom" />
            <div className="row clearfix">
                <Col span={24}>
                    <Row gutter={24} style={{ width: '100%' }}>
                        <Col span={4}>
                            <CommonTree />
                        </Col>
                        <Col span={20}>
                            <MonitorTable />
                        </Col>
                    </Row>
                </Col>
            </div>
        </div>
    );
};

const MonitorTable = () => {
    const childRef = useRef();
    const formChildrenRef = useRef();
    const [modalType, setModalType] = useState('edit'); //edit add
    const [data, setData] = useState([]);
    const [form] = Form.useForm();
    useEffect(() => {
        setData([
            {
                id: 1,
                grade: '异常',
                content: '照明01，过流',
                state: '未派发',
                category: '过流',
            },
            {
                id: 2,
                grade: '异常',
                content: '照明01，过流',
                state: '未派发',
                category: '过流',
            },
            {
                id: 3,
                grade: '异常',
                content: '照明01，过流',
                state: '未派发',
                category: '过流',
            },
            {
                id: 4,
                grade: '异常',
                content: '照明01，过流',
                state: '未派发',
                category: '过流',
            },
            {
                id: 5,
                grade: '异常',
                content: '照明01，过流',
                state: '未派发',
                category: '过流',
            },
            {
                id: 6,
                grade: '异常',
                content: '照明01，过流',
                state: '未派发',
                category: '过流',
            },
        ]);
    }, []);

    const renderForm = () => {
        return (
            <Form
                layout="horizontal"
                form={form}
            >
                <Row>
                    <Col offset={13} span={6}>
                        <FormItem
                            name="category"
                            label="监测点名称"
                            rules={[{ required: true, message: '请输入监测点名称' }]}
                        >
                            <Input />
                        </FormItem>
                    </Col>
                    <Col offset={1} span={2}>
                        <FormItem>
                            <Button type="primary" htmlType="submit">
                                搜索
                            </Button>
                        </FormItem>
                    </Col>
                    <Col span={2}>
                        <FormItem>
                            <Button
                                type="default"
                                style={{ backgroundColor: '#F59A23', color: '#fff' }}
                                onClick={() => {
                                    form.resetFields();
                                }}
                            >
                                重置
                            </Button>
                        </FormItem>
                    </Col>
                </Row>
            </Form>
        );
    };
    const columns = [
        {
            title: '序号',
            dataIndex: 'id',
            key: 'id',
            width: 100, //宽度
            render: (text, record, index) => `${index + 1}`,
        },
        {
            title: '监测点名称',
            dataIndex: 'grade',
        },
        {
            title: '监测点路径',
            dataIndex: 'content',
        },
        {
            title: '描述',
            dataIndex: 'category',
        },
        {
            title: '网关状态',
            dataIndex: 'state',
        },
        {
            title: '操作',
            dataIndex: 'opera',
            render: (text, record) => (
                <Space split={<Divider type="vertical" />}>
                    <Typography.Link className="opera">
                        <a
                            className="edit"
                            href=""
                            onClick={() => {
                                setModalType('edit');
                                childRef.current.showModal(0);
                            }}
                        >
                            <EditOutlined />
                            编辑
                        </a>
                    </Typography.Link>
                    <Typography.Link className="opera">
                        <Popconfirm
                            title="删除该监测点？"
                            okText="删除"
                            cancelText="取消"
                            onConfirm={() => {
                                //发起http请求删除
                            }}
                        >
                            <a style={{ color: '#fff' }} className="del" href="">
                                <DeleteOutlined />
                                删除
                            </a>
                        </Popconfirm>
                    </Typography.Link>
                </Space>
            ),
        },
    ];
    return (
        <div className="tableBox">
            <Row gutter={16}>
                <Col span={2}>
                    <Button
                        onClick={() => {
                            setModalType('add');
                            childRef.current.showModal();
                        }}
                        type="primary"
                        style={{ marginRight: '10px', marginBottom: '10px' }}
                    >
                        新增
                    </Button>
                </Col>
                <Col span={22}>{renderForm()}</Col>
            </Row>
            <Table
                columns={columns}
                dataSource={data}
                bordered
                scroll={{ x: '100%' }}
                onChange={page => {}}
                pagination={{ defaultPageSize: commonPageSize, total: 200 }}
            />
            <CommonModal
                onOk={() => {
                    formChildrenRef.current
                        .validateFields()
                        .then(value => {
                            //do something
                        })
                        .catch(err => {
                            childRef.endLoading();
                        });
                }}
                childRef={childRef}
                title={`${modalType === 'edit' ? '编辑' : '新增'}监测点`}
            >
                <MonitorForm childRef={formChildrenRef} />
            </CommonModal>
        </div>
    );
};

const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 18 },
};
const Option = Select.Option;
const MonitorForm = ({ childRef }) => {
    const [form] = Form.useForm();
    useImperativeHandle(childRef, () => ({
        validateFields: form.validateFields,
        resetFields: form.resetFields,
    }));
    const initValue = {
        name: 'wanghaidong',
        fatherPointer: 'demo',
        content: '这是一段描述',
    };
    return (
        <Form form={form} {...layout} initialValues={initValue}>
            <FormItem label="名称" name="name" rules={[{ required: true, message: '请输入监测点名称' }]}>
                <Input />
            </FormItem>
            <FormItem label="父节点" name="fatherPointer" rules={[{ required: true, message: '请选择父节点' }]}>
                <Select>
                    <Option value="demo">Demo</Option>
                </Select>
            </FormItem>
            <FormItem label="描述" name="content" rules={[{ required: true, message: '请输入站点描述' }]}>
                <Input.TextArea />
            </FormItem>
        </Form>
    );
};
