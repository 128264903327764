import { isUndefined, isNaN, isNull } from 'lodash';
import moment from 'moment';
/**
 * 根据传入的楼层数，生成楼层名
 * @param {string} strNum
 */
export function getFloorName(strNum) {
    if (strNum === '0') return '未设置楼层';
    if (strNum.length === 1) {
        return `0${strNum}楼`;
    }
    return `${strNum}楼`;
}
/**
 * 根据设备id判断设备类别
 * @param {*} deviceId
 */
export function getCategory(deviceId) {
    const twoChar = deviceId.slice(0, 2);
    switch (twoChar) {
        case 'F0':
            return 'GatewayDetail';
        case 'F1':
            return 'SwitchDetail';
        case 'F2':
            return 'SmokerDetail';
        default:
            return 'SwitchSub';
    }
}
/**
 * 根据设备id判断设备类别
 * @param {*} deviceId
 */
export function getDeviceSub(deviceId) {
    const twoChar = deviceId.slice(0, 2);
    switch (twoChar) {
        case 'F0':
            return 'GatewaySub';
        case 'F1':
            return 'SwitchSub';
        case 'F2':
            return 'SmokerSub';
        default:
            return 'SwitchSub';
    }
}
/**
 * 十六进制转换字符串
 * @param {*} hexCharCodeStr
 */
export function hexCharCodeToStr(hexCharCodeStr) {
    let trimedStr = hexCharCodeStr.trim();
    let rawStr = trimedStr.substr(0, 2).toLowerCase() === '0x' ? trimedStr.substr(2) : trimedStr;
    let len = rawStr.length;
    if (len % 2 !== 0) {
        // console.log('Illegal Format ASCII Code!');
        return '';
    }
    let curCharCode;
    var resultStr = [];
    for (var i = 0; i < len; i = i + 2) {
        curCharCode = parseInt(rawStr.substr(i, 2), 16); // ASCII Code Value
        resultStr.push(String.fromCharCode(curCharCode));
    }
    return resultStr.join('');
}

/**
 * 字符串转换成16进制
 * @param {*} str
 */
export function strToHexCharCode(str) {
    if (str === '') return '';
    let hexCharCode = [];
    for (var i = 0; i < str.length; i++) {
        hexCharCode.push(str.charCodeAt(i).toString(16));
    }
    return hexCharCode.join('');
}
/**
 * 10进制数转换成16进制字符串
 * @param {*} arr
 */
export function dec2HexString(arr) {
    let str = '';
    let _arr = arr;
    for (let i = 0; i < _arr.length; i++) {
        let hex = _arr[i].toString(16).toUpperCase();
        if (hex.length === 1) {
            hex = '0' + hex;
        }
        str += hex;
    }
    return str;
}
/**
 * 将字符串转换成Uint8Array
 * @param {*} str
 */
export function str2Uint8Array(str) {
    if (str.length % 2 !== 0) {
        // console.log('字符串必须是2的倍数');
        return '';
    }
    const len = str.length / 2;
    const u8array = new Uint8Array(len);
    for (let i = 0, j = 0; i < str.length; i = i + 2, j += 1) {
        u8array[j] = parseInt(str.substr(i, 2), 16);
    }
    return u8array;
}

//根据数值大小自动变换单位
export const reviseUnit = (data, company, regionData) => {
    var data1 = Number(data) || 0,
        danwei = '';
    switch (regionData !== '') {
        case data > 1000000: //G
            danwei = company[0];
            data1 = (data / 1000000).toFixed(2) || 0;
            break;
        case data > 10000 && data <= 1000000: //M
            danwei = company[1];
            data1 = (data / 1000).toFixed(2) || 0;
            break;
        case data > 1000 && data <= 10000: //k
            danwei = company[2];
            data1 = (data / 1000).toFixed(2) || 0;
            break;
        case data < 1 && data <= 1000: //A V
            danwei = company[4];
            data1 = (data * 1000).toFixed(2) || 0;
            break;
        default:
            //m
            danwei = company[3];
            data1 = data.toFixed(2) || 0;
    }
    return [data1, danwei];
};

//根据数值大小自动变换单位
export const reviseUnit1 = (data, company, regionData) => {
    var data1 = Number(data) || 0,
        danwei = '';
    switch (regionData !== '') {
        case data > 1000000: //G
            danwei = company[0];
            data1 = (data / 1000000).toFixed(2) || '--';
            break;
        case data > 1000 && data <= 1000000: //M
            danwei = company[1];
            data1 = (data / 1000).toFixed(2)  || '--';
            break;
        case data <= 1000: //k
            danwei = company[2];
            data1 = data.toFixed(2) || '--';
            break;
        default:
            data1 = Number(data) || 0;
            danwei = '';
    }
    return [data1, danwei];
};

//保留两位小数
export const peakvalue = power => {
    if (typeof power !== 'undefined') {
        // let max_power = parseInt(power * 100) / 100;
        let max_power = Math.round(power * 100) / 100;
        return max_power;
    }
};

//制保留8位小数，如：2，会在2后面补上00000000.即2.00000000
export const toDecimal2 = x => {
    // var f = parseFloat(x);
    var f = Math.round(x * 100000000) / 100000000;
    if (isNaN(f)) {
        return false;
    }
    var s = f.toString();
    var rs = s.indexOf('.');
    if (rs < 0) {
        rs = s.length;
        s += '.';
    }
    while (s.length <= rs + 8) {
        s += '0';
    }
    return s;
};

//保留八位小数
export const peakvalue8 = power => {
    if (typeof power !== 'undefined' && power !== null) {
        // let max_power = Number(power.toFixed(8));
        let max_power = Math.round(power * 100000000) / 100000000;
        return max_power;
    }
};

//格式化时间
export const peakTime = timeDate => {
    if (timeDate) {
        let date = timeDate.slice(0, 10);
        let time = timeDate.slice(11, 19);
        timeDate = date + ' ' + time;
        return timeDate;
    }
};

//空值处理
export const removeEmpty = value => {
    if (isNaN(value) || isNull(value) || isUndefined(value)) {
        return '-';
    }
    return value;
};

//用电单位对照表
export const unitControl = {
    电流: 'A',
    电压: 'V',
    功率: 'W',
    温度: '℃',
    湿度: '%RH',
    电流不平衡: '%',
    三相电流: 'A',
    剩余电流: 'A',
    线缆温度: '℃',
    功率因数: '',
};

/* 时间戳转换成日期
 * @param timestamp
 * @returns {*}
 */
export const timestampToTime = timestamp => {
    var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
    const Y = date.getFullYear() + '-';
    const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    const D = date.getDate() + ' ';
    const h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':';
    const m = date.getMinutes() < 10 ? '0' + date.getMinutes() + ':' : date.getMinutes() + ':';
    const s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    return Y + M + D + h + m + s;
};

/**
 * 把日期格式化
 * @param {*} dateData
 * @returns
 */
export const dateFormat = dateData => {
    let date = new Date(dateData);
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    m = m < 10 ? '0' + m : m;
    let d = date.getDate();
    d = d < 10 ? '0' + d : d;
    const time = y + '-' + m + '-' + d;
    return time;
};

/**
 * 把时间格式化
 * @param {*} timeData
 * @returns
 */
export const timeFormat = timeData => {
    let date = new Date(timeData);
    let h = date.getHours();
    let m = date.getMinutes();
    let s = date.getSeconds();
    m = m < 10 ? '0' + m : m;
    s = m < 10 ? '0' + s : s;
    const time = h + ':' + m + ':' + s;
    return time;
};

export const getArrayValue = (array, key) => {
    var keys = key || 'value';
    var res = [];
    if (array) {
        array.forEach(function (t) {
            res.push(t[keys]);
        });
    }
    return res;
};
export const array2obj = (array, key) => {
    var resObj = {};
    for (var i = 0; i < array.length; i++) {
        resObj[array[i][key]] = array[i];
    }
    return resObj;
};

//获取两日期之间日期列表函数1
export const getDayArr = (startDay, endDay) => {
    let startVal = moment(new Date(startDay)).format('YYYY-MM-DD');
    let dayArr = [];
    while (moment(startVal).isBefore(endDay)) {
        dayArr.push({ time: startVal });
        // 自增
        startVal = moment(new Date(startVal)).add(1, 'day').format('YYYY-MM-DD');
    }
    // 将结束日期的天放进数组
    dayArr.push({ time: moment(new Date(endDay)).format('YYYY-MM-DD') });
    return dayArr;
};

//获取两时间之间时间列表函数
export const getHourArr = (startDay, endDay) => {
    let startVal = moment(new Date(startDay)).format('YYYY-MM-DD HH:mm:ss');
    let dayArr = [];
    while (moment(startVal).isBefore(endDay)) {
        dayArr.push({ time: moment(new Date(startVal)).format('HH:mm:ss') });
        // 自增
        startVal = moment(new Date(startVal)).add(1, 'hours').format('YYYY-MM-DD HH:mm:ss');
    }
    // 将结束日期的天放进数组
    // dayArr.push({ time: moment(new Date(endDay)).format('HH:mm:ss') });
    return dayArr;
};

//获取两日期之间日期列表函数2
export const getdifflist = (startDay, endDay, type) => {
    // type 为 days(天) , months(月), years(年)
    var dateArray = []; // dateArray 起止日期中间的所有日期列表
    var currentDate = moment(startDay); //起止日期
    var stopDate = moment(endDay); //截止日期
    //开始日期小于等于结束日期,并循环
    while (currentDate <= stopDate) {
        if (type === 'days') dateArray.push({ time: moment(currentDate).format('YYYY-MM-DD') }); //两个日期间的所有日期
        if (type === 'months') dateArray.push({ time: moment(currentDate).format('YYYY-MM') }); //两个月份间的所有月份
        if (type === 'years') dateArray.push({ time: moment(currentDate).format('YYYY') }); //两个年份间的所有年份
        currentDate = moment(currentDate).add(1, type); //根据类型+1
    }
    return dateArray;
};


//根据起始日期和结束日期计算天数
export const DateDiff = ( sDate1,  sDate2) =>{
    var  oDate1,  oDate2,  iDays   
    oDate1  =  new Date(sDate1.substring(5,7) + sDate1.substring(7)  +  '-'  +  sDate1.substring(0,4));     
    oDate2  =  new Date(sDate2.substring(5,7) + sDate2.substring(7)  +  '-'  +  sDate2.substring(0,4));  
    iDays  =  parseInt(Math.abs(oDate1  -  oDate2) / 1000 / 60 / 60 /24);     
    return  iDays;
}

//获取数组对象中某一属性值的集合(map方法抽取成公共方法)
export const getAttrs = (array,attr) => {
    var arr = array.map((item)=>{
        return item[attr];
    })
    return arr;
}

//判断数组中是否有相同的元素
export const isRepeat = arr => {
    var hash = {};
    for (var i in arr) {
        if (hash[arr[i]]) {
            return true;
        }
        // 不存在该元素，则赋值为true，可以赋任意值，相应的修改if判断条件即可
        hash[arr[i]] = true;
    }
    return false;
};

// 获取数组中相同的元素
export const isAllEqual = (array) => {
    if (array.length > 0) {
        return !array.some(function (value, index) {
            return value !== array[0];
        });
    } else {
        return true;
    }
}