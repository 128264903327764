import React from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { CacheSwitch } from 'react-router-cache-route'
import { CSSTransition } from 'react-transition-group';
import Login from './components/Login/login';
import Forget from './components/Login/ForgetPwd';
import { DispatchRoute } from './router/PrivateRoute';
import { NotFound } from './components/NoMatch'; //404
import history from './api/history';
import './App.css';
import zhCN from 'antd/lib/locale/zh_CN';
import 'moment/locale/zh-cn';
import './style/table_style.less';
import { ENTER } from './const/location';
import { BigScreen } from './views/BigScreen';
import { ConfigProvider } from 'antd';
import { ErrorBoundary } from './components/ErrorBoundary'; //页面报错
const App = () => {
    return (
        <ConfigProvider locale={zhCN}>
            <ErrorBoundary>
                <Router history={history}>
                    <div className="entryWrap">
                        <CSSTransition classNames="fade" timeout={1000}>
                            <CacheSwitch>
                                <Route path="/forget" component={Forget} />
                                <Route path="/login" component={Login} />
                                <Route path="/bigScreen" component={BigScreen} />
                                <DispatchRoute path={ENTER} />
                                {/* 第一种  地址栏显示点击的路由 */}
                                <Route component={NotFound} />
                                {/* 第二种  地址栏显示/notFound */}
                                <Route path="/notFound" component={NotFound} />
                                <Redirect to="/notFound" />
                            </CacheSwitch>
                        </CSSTransition>
                    </div>
                </Router>
            </ErrorBoundary>
        </ConfigProvider>
    );
};
export default App;
