import React, { useState, useEffect, useRef, useImperativeHandle, useMemo } from 'react';
import { BreadcrumbCustom } from '../../../components/BreadcrumbCustom';
import { CommonModal } from '../../../components/CommoModal';
import { MyIcon } from '../../../util/iconfont';
import moment from 'moment';
import {
    Button,
    Col,
    Form,
    Row,
    Table,
    Popconfirm,
    Input,
    Divider,
    Checkbox,
    Select,
    DatePicker,
    Switch,
    Radio,
    message,
    InputNumber,
    ConfigProvider,
    Upload,
    Modal,
} from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import zhCN from 'antd/es/locale/zh_CN';
import { omitBy, isUndefined, isNull, trim } from 'lodash';
import ToastHelper from '../../../tools/ToastHelper';
import { switchText, account, accountDataAdmin } from '../../../global';
import {
    getUserInfo,
    editUserInfo,
    resetPassword,
    addUserInfo,
    delUserInfo,
    getUserList,
    getUserInfoDetails,
    BatchOperation,
} from '../../../api/adminServe';
import '../../../style/form.less';

const FormItem = Form.Item;
const Option = Select.Option;

const getBase64 = file => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
};

/*
 * 用户管理
 */
const AdminAccount = () => {
    return (
        <div className="container clearfix">
            <BreadcrumbCustom paths={['账号管理']} className="rumbCustom" />
            <div className="row clearfix" style={{ width: '100%' }}>
                <EquipmentTable />
            </div>
        </div>
    );
};

const EquipmentTable = () => {
    const childRef = useRef();
    const formChildrenRef = useRef();
    const [modalType, setModalType] = useState('add'); //edit add export other
    const [data, setData] = useState({ data: [], isLoading: true });
    const [count, setCount] = useState(0);
    const [userId, setUserId] = useState();
    const [update, setUpdate] = useState(0);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [page_size, setPageSize] = useState(12);
    const ref = useRef(null);
    const [searchParams, setSearchParams] = useState({}); //搜索
    const [userInfoDetails, setUserInfoDetails] = useState();
    // 获取用户列表数据
    const userUserInfoData = (page, pageSize) => {
        const params = omitBy(searchParams, value => {
            return isUndefined(value) || isNull(value) || value === '' || trim(value) === '';
        });
        setCurrentPage(page);
        setData({ data: [], isLoading: true });
        getUserInfo({ page, page_size: pageSize ? pageSize : page_size, ...params })
            .then(result => {
                setData({ data: result.data, isLoading: false });
                setCount(result.count);
            })
            .catch(() => {
                setData({ data: [], isLoading: true });
            });
    };
    useEffect(() => {
        userUserInfoData(currentPage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [update, searchParams]);
    // 编辑详情
    const getDetail = userId => {
        getUserInfoDetails(userId)
            .then(result => {
                let roles = [];
                setUserInfoDetails(result.data);
                result.data.roles &&
                    result.data.roles.forEach(item => {
                        return roles.push(item.id);
                    });
                if (formChildrenRef.current != null) {
                    formChildrenRef.current.setFieldsValue({
                        category: result.data.category,
                        is_staff: result.data.is_staff,
                        roles: roles || [],
                        enterprise: result.data.enterprise && result.data.enterprise.name,
                        declare_power: result.data.declare_power,
                        ram_count: result.data.ram_count,
                        expire_time:
                            result.data.expire_time === null
                                ? result.data.expire_time
                                : moment(result.data.expire_time, 'yyyy-MM-DD HH:mm:ss'),
                        // regions: result.data.regions || [],
                        username: result.data.username,
                        real_name: result.data.real_name,
                        mobile: result.data.mobile,
                        email: result.data.email,
                        location: result.data.location,
                        logo: result.data.logo,
                        system_name: result.data.system_name,
                        url:result.data.url,
                    });
                }
            })
            .catch();
    };
    const columns = useMemo(() => {
        return [
            {
                title: '序号',
                dataIndex: 'id',
                key: 'id',
                width: 100,
                render: (test, record, index) => `${index + 1}`,
            },
            {
                title: '账号',
                dataIndex: 'username',
                render: text => (
                    <p
                        style={{
                            minWidth: '4rem',
                            maxWidth: '8rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={text}
                    >
                        {text}
                    </p>
                ),
            },
            {
                title: '企业名称',
                dataIndex: 'enterprise',
                width: 150,
                render: text => (
                    <p
                        style={{
                            minWidth: '4rem',
                            maxWidth: '8rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={text && text.name}
                    >
                        {text && text.name}
                    </p>
                ),
            },
            {
                title: '类型',
                dataIndex: 'category',
                render: (text, index) => (
                    <p
                        style={{
                            minWidth: '4rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={account[text]}
                    >
                        {account[text]}
                    </p>
                ),
            },
            {
                title: '姓名',
                dataIndex: 'real_name',
                render: text => (
                    <p
                        style={{
                            minWidth: '3rem',
                            maxWidth: '8rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={text}
                    >
                        {text}
                    </p>
                ),
            },
            {
                title: '联系方式',
                dataIndex: 'mobile',
                width: 100,
            },
            {
                title: '账号限制',
                dataIndex: 'ram_count',
                render: text => (
                    <p
                        style={{
                            minWidth: '5rem',
                            maxWidth: '12rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={text}
                    >
                        {text}
                    </p>
                ),
            },
            {
                title: '有效期',
                dataIndex: 'expire_time',
                width: 180,
                render: (text, record, index) => <span>{text && text.slice(0, 10) + ' ' + text.slice(11, 19)}</span>,
            },
            {
                title: '状态',
                dataIndex: 'is_active',
                width: 85,
                render: (_, record) => (
                    <Switch
                        {...switchText}
                        onClick={value => {
                            editUserInfo({ id: record.id, is_active: value })
                                .then(result => {
                                    ToastHelper.successToast(`${value ? '开启' : '关闭'}成功`);
                                    setUpdate(result);
                                })
                                .catch();
                        }}
                        checked={record.is_active}
                        size="small"
                    />
                ),
            },
            {
                title: '创建时间',
                dataIndex: 'date_joined',
                width: 200,
                sorter: (a, b) => moment(a.createtime) - moment(b.createtime),
                render: (text, record, index) => <span>{text && text.slice(0, 10) + ' ' + text.slice(11, 19)}</span>,
            },
            {
                title: '操作',
                dataIndex: 'id',
                width: 180,
                render: (text, record) => (
                    <div className="opera">
                        <MyIcon
                            type={'icona-zu6914'}
                            style={{ fontSize: '1rem', verticalAlign: 'middle', color: 'rgba(0, 0, 0, 0.85)' }}
                            title={'编辑'}
                            onClick={() => {
                                setModalType('edit');
                                getDetail(record.id);
                                setUserId(record.id);
                                childRef.current.showModal();
                                setCurrentPage(currentPage)
                            }}
                        />
                        <Popconfirm
                            title="确定要删除吗?"
                            onConfirm={() => {
                                delUserInfo(record.id)
                                    .then(result => {
                                        message.success('删除成功');
                                        setUpdate(result);
                                    })
                                    .catch();
                            }}
                        >
                            <MyIcon
                                type="iconshanchu1"
                                style={{
                                    color: 'rgba(0, 0, 0, 0.85)',
                                    fontSize: '1rem',
                                    verticalAlign: 'middle',
                                    margin: '0 0.6rem',
                                }}
                                title={'删除'}
                            />
                        </Popconfirm>
                        <Popconfirm
                            title="确定要重置密码吗?"
                            onConfirm={() => {
                                resetPassword({ user_id: record.id })
                                    .then(result => {
                                        message.success('密码重置成功');
                                        setUpdate(result);
                                    })
                                    .catch();
                            }}
                        >
                            <MyIcon
                                type="iconchongzhimima"
                                style={{ color: 'rgba(0, 0, 0, 0.85)', fontSize: '1rem', verticalAlign: 'middle' }}
                                title={'重置密码'}
                            />
                        </Popconfirm>
                    </div>
                ),
            },
        ];
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);
    const paginationNo = {
        pageSize: page_size, //每页的条数
        current: currentPage, //当前页
        total: count, //总数据
        showSizeChanger: true,
        onChange: (page, pageSize) => {
            ref.current = page;
            userUserInfoData(page, pageSize);
        }, //点击分页号时触发的方法
        onShowSizeChange: (current, pageSize) => {
            setPageSize(pageSize);
        }, //pageSize 变化的回调
        hideOnSinglePage: true, //为true则数据条数不满或刚好一页，则隐藏分页器
        pageSizeOptions: ['12', '15', '20', '30', '50', '100'], // 分页类别
        showTotal: total => `共 ${total} 条`,
    };
    const onSelectChange = selectedRowKeys => {
        setSelectedRowKeys(selectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    /**
     * 全选
     * @param e
     */
    const onCheckAllChange = e => {
        setSelectedRowKeys(e.target.checked ? data.data.map((item, index) => item.id) : []);
    };

    return (
        <div className="tableBox">
            <Row gutter={24} style={{ height: '2rem' }}>
                <Col xs={2} span={8}>
                    <Button
                        type="primary"
                        onClick={() => {
                            setModalType('add');
                            childRef.current.showModal();
                            // if (formChildrenRef.current !== undefined) {
                            //     formChildrenRef.current.resetFields();
                            // }
                        }}
                        style={{ marginRight: '10px' }}
                    >
                        新增账号
                    </Button>
                </Col>
                <Col className="gutter-row" span={16} xs={22}>
                    <SearchForm setSearchParams={setSearchParams} />
                </Col>
            </Row>
            <ConfigProvider locale={zhCN}>
                <Table
                    rowKey={record => record.id}
                    columns={columns}
                    dataSource={data.data}
                    bordered={true}
                    scroll={{ x: '100%' }}
                    pagination={paginationNo}
                    rowSelection={rowSelection}
                    loading={data.isLoading}
                    style={{ marginTop: '1rem' }}
                />
            </ConfigProvider>
            <div style={data.data.length > 0 ? { position: 'inherit', marginTop: '1rem' } : { display: 'none' }}>
                <Checkbox
                    indeterminate={
                        //负责样式控制
                        data.data.length !== selectedRowKeys.length && selectedRowKeys.length !== 0
                    }
                    onChange={onCheckAllChange} //勾选时触发的方法
                    checked={data.data.length === selectedRowKeys.length} //勾选还是未勾选  options：所有的勾选子项，list
                >
                    全选
                </Checkbox>

                <Radio.Group style={{ marginBottom: 16 }}>
                    <Radio.Button value="off">
                        <Popconfirm
                            title={
                                selectedRowKeys.length === 0 ? '未选中' : `确定要将${selectedRowKeys.length}项,启用吗?`
                            }
                            onConfirm={() => {
                                BatchOperation({ active: true, user_ids: selectedRowKeys })
                                    .then(result => {
                                        message.success('批量启用成功');
                                        setUpdate(result);
                                        setSelectedRowKeys([]);
                                    })
                                    .catch(() => {
                                        message.error('批量启用失败');
                                    });
                            }}
                        >
                            启用{' '}
                        </Popconfirm>
                    </Radio.Button>
                    <Radio.Button value="del">
                        <Popconfirm
                            title={
                                selectedRowKeys.length === 0 ? '未选中' : `确定要将${selectedRowKeys.length}项,禁用吗?`
                            }
                            onConfirm={() => {
                                BatchOperation({ active: false, user_ids: selectedRowKeys })
                                    .then(result => {
                                        message.success('批量禁用成功');
                                        setUpdate(result);
                                        setSelectedRowKeys([]);
                                    })
                                    .catch(() => {
                                        message.error('批量禁用失败');
                                    });
                            }}
                        >
                            禁用{' '}
                        </Popconfirm>
                    </Radio.Button>
                </Radio.Group>
            </div>
            <CommonModal
                onOk={() => {
                    formChildrenRef.current
                        .validateFields()
                        .then(value => {
                            value.system_name = value.system_name === '' ? null : value.system_name;
                            //do something
                            //TODO 发起http请求提交当前设置的巡检
                            if (modalType === 'add') {
                                let logo = formChildrenRef.current.fileLists.file;
                                if (!isUndefined(logo)) {
                                    value.logo = logo.thumbUrl;
                                } else {
                                    value.logo = null;
                                }
                                addUserInfo(value)
                                    .then(result => {
                                        childRef.current.startLoading();
                                        message.success('新增成功');
                                        childRef.current.hiddenModal();
                                        setUpdate(result);
                                        formChildrenRef.current.resetFields();
                                    })
                                    .catch(() => {
                                        childRef.current.endLoading();
                                    });
                            } else {
                                value.id = userId;

                                let logo = formChildrenRef.current.fileLists || null;
                                // if (!isEmpty(logo.fileList)) {
                                value.logo =
                                    Array.prototype.isPrototypeOf(logo || logo.fileList) ||
                                    logo.length === 0 ||
                                    logo.fileList.length === 0
                                        ? null
                                        : formChildrenRef.current.fileLists.fileList[0].url ||
                                          formChildrenRef.current.fileLists.fileList[0].thumbUrl;
                                // }/
                                // console.log(logo,'111',formChildrenRef.current.fileLists.fileList[0])
                                editUserInfo(value)
                                    .then(result => {
                                        childRef.current.startLoading();
                                        message.success('编辑成功');
                                        childRef.current.hiddenModal();
                                        setUpdate(result);
                                        formChildrenRef.current.resetFields();
                                    })
                                    .catch(() => {
                                        childRef.current.endLoading();
                                    });
                            }
                        })
                        .catch();
                }}
                childRef={childRef}
                title={modalType === 'add' ? '新增账号' : '编辑账号'}
            >
                <EquipmentForm type={modalType} childRef={formChildrenRef} userInfoDetails={userInfoDetails} />
            </CommonModal>
        </div>
    );
};

const FormItemLayout = { labelCol: { span: 5 }, wrapperCol: { span: 16 } }; //表单一行显示 label - input
const Layout = { wrapperCol: { offset: 10, span: 14 } }; //登录按钮（提交）

const EquipmentForm = ({ childRef, type, userInfoDetails }) => {
    const [form] = Form.useForm();
    useImperativeHandle(childRef, () => ({
        validateFields: form.validateFields,
        resetFields: form.resetFields,
        setFieldsValue: form.setFieldsValue,
        fileLists: fileLists,
    }));
    useEffect(() => {
        getUserList()
            .then(result => {
                setRole(result.data);
            })
            .catch();
    }, []);
    let formItemShowStyle = { display: 'flex' };
    let formItemHideStyle = { display: 'none' };
    const [role, setRole] = useState([]);
    const [style, setStyle] = useState(formItemHideStyle);
    const [selectState, setSelectState] = useState(false); //全选状态
    const [loading, setLoading] = useState(false); //点击上传加载
    const [fileLists, setFileList] = useState({ fileList: [] }); //图片数据集
    const [previewVisible, setPreviewVisible] = useState(false); //显示查看大图
    const [previewImage, setPreviewImage] = useState(''); //当前大图
    const [previewTitle, setPreviewTitle] = useState(''); //当前大图的标题
    const onChangeIs_staff = e => {
        //判断新增的是 后台管理员 、 企业管理员
        if (e) {
            setStyle(formItemHideStyle);
        } else {
            setStyle(formItemShowStyle);
        }
    };

    let children = role.map(item => {
        return (
            <Option key={item.id} value={item.id} title={item.desc}>
                {item.name}
            </Option>
        );
    });

    useEffect(() => {
        if (type === 'edit' && !isUndefined(userInfoDetails)) {
            if (userInfoDetails.is_staff) {
                setStyle(formItemHideStyle);
                setFileList({ fileList: [] });
            } else {
                setStyle(formItemShowStyle);
                setFileList({
                    fileList:
                        userInfoDetails.logo === null
                            ? []
                            : [
                                  {
                                      uid: userInfoDetails.id,
                                      status: 'done',
                                      type: 'image/png',
                                      name: 'image.png',
                                      url: userInfoDetails.logo,
                                      image: userInfoDetails.logo,
                                  },
                              ],
                });
            }
        } else {
            //eslint-disable-next-line react-hooks/exhaustive-deps
            form.resetFields();
            userInfoDetails = [];
            setFileList({ fileList: [] });
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, userInfoDetails]);

    // 上传图片事件
    const handleChange = (info, fileList) => {
        setFileList(info); //fileList
        switch (info.file.status) {
            case 'uploading':
                setLoading(true);
                break;
            case 'done':
                setFileList(info);
                setLoading(false);
                // Get this url from response in real world.
                // getBase64(info.file.originFileObj, imageUrl => {setLoading(false); setImageUrl(imageUrl)});
                break;
            default:
                // error or removed
                setFileList([]);
        }
    };
    // 通过覆盖默认的上传行为，可以自定义自己的上传实现
    const uploadImageRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess('ok');
        }, 0);
    };
    //上传之前验证
    const beforeUpload = file => {
        const isJpgOrPng = file.type === 'image/png';
        const width = 273;
        const height = 39;
        const isLt2M = file.size / 1024 / 1024 < 2;
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.addEventListener(
                'load',
                () => {
                    let img = new Image();
                    img.src = reader.result;
                    img.onload = () => {
                        if (!isJpgOrPng) {
                            message.error('您只能上传PNG 文件!');
                        } else if (!isLt2M) {
                            message.error('图片大小必须小于2MB!');
                        } else if (img.width < width || img.height < height) {
                            message.error('请上传宽高大于等于 273*40 的LOGO图！');
                            reject(new Error('请上传宽高大于等于 273*40 的LOGO图！'));
                        } else {
                            resolve();
                        }
                    };
                },
                false,
            );
            reader.readAsDataURL(file);
        });
        // if (!isJpgOrPng) {
        //     message.error('您只能上传PNG 文件!');
        // }
        // const isLt2M = file.size / 1024 / 1024 < 2;
        // if (!isLt2M) {
        //     message.error('图片大小必须小于2MB!');
        // }
        // return isJpgOrPng && isLt2M;
    };
    // 上传按钮组件
    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>点击上传</div>
        </div>
    );

    //点击预览图标时的回调
    const handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewVisible(true);
        setPreviewImage(file.url || file.preview);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };
    // 查看照片框关闭事件
    const handleCancel = () => {
        setPreviewVisible(false);
    };
    return (
        <Form form={form} {...Layout} className="adminAccountStyle">
            <FormItem
                name="is_staff"
                label="用户类型"
                {...FormItemLayout}
                rules={[{ required: true, message: '请选择用户类型！' }]}
            >
                <Select
                    onChange={onChangeIs_staff}
                    placeholder={'请选择用户类型'}
                    key={1}
                    virtual={false}
                    showArrow
                    disabled={type === 'edit' ? true : false}
                >
                    {accountDataAdmin.map(item => {
                        return (
                            <Option value={item.selected} key={item.id}>
                                {item.value}
                            </Option>
                        );
                    })}
                </Select>
            </FormItem>
            <FormItem
                name="roles"
                label="角色分配"
                {...FormItemLayout}
                rules={[{ required: true, message: '请选择角色！' }]}
            >
                <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder={'请选择角色！'}
                    virtual={false}
                    showArrow
                    showSearch={false}
                    dropdownRender={menu => (
                        <div>
                            {menu}
                            <Divider style={{ margin: '2px 0' }} />
                            <div style={{ padding: '4px 8px 8px 8px', cursor: 'pointer' }}>
                                <Checkbox
                                    checked={selectState}
                                    onChange={e => {
                                        // 判断 是否 选中
                                        if (e.target.checked === true) {
                                            setSelectState(true); //选中时 给 checked 改变状态
                                            // 当选的时候 把所有列表值赋值给role
                                            form.setFieldsValue({
                                                roles: role?.map(item => item.id), //如果选中 给select 赋data里面所有的值
                                            });
                                        } else {
                                            setSelectState(false);
                                            form.setFieldsValue({
                                                roles: [], //如果取消全选 这清空select全部选中状态
                                            });
                                        }
                                    }}
                                >
                                    全选
                                </Checkbox>
                            </div>
                        </div>
                    )}
                >
                    {children}
                </Select>
            </FormItem>
            <FormItem
                style={style}
                // style={userInfoDetails && userInfoDetails.is_staff ? formItemHideStyle : formItemShowStyle}
                label="企业名称"
                name="enterprise"
                {...FormItemLayout}
                rules={[
                    {
                        required: style.display === formItemShowStyle.display ? true : false,
                        message: '请输入企业名称！',
                    },
                ]}
                getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}
            >
                <Input />
            </FormItem>
            {/* <FormItem
                style={style}
                // style={userInfoDetails && userInfoDetails.is_staff ? formItemHideStyle : formItemShowStyle}
                label="企业LOGO"
                name="logo"
                {...FormItemLayout}
                rules={[
                    {
                        // required: style.display === formItemShowStyle.display ? true : false,
                        required: false,
                        message: '请上传企业LOGO！',
                    },
                ]}
                getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}
            >
                <Input />
            </FormItem> */}
            <FormItem
                label="企业Logo"
                valuePropName="file"
                {...FormItemLayout}
                // rules={[{ required: true, message: '请上传图片！' }]}
                extra="建议图片比例7:1，大小不超过2MB的png图片"
                style={style}
            >
                <FormItem name="logo" noStyle rules={[{ required: false, message: '请上传图片！' }]}>
                    <Upload
                        // action={'/v1/media/banner/'}
                        className="avatar-uploader"
                        listType="picture-card"
                        fileList={fileLists.fileList}
                        onPreview={handlePreview} // 点击图片缩略图，进行预览
                        onChange={handleChange} // 每次上传图片时，都会触发这个方法
                        showUploadList={true} // 显示图片删除标识的配置
                        beforeUpload={beforeUpload}
                        customRequest={uploadImageRequest}
                    >
                        {fileLists.fileList && fileLists.fileList.length >= 1 ? null : uploadButton}
                    </Upload>
                </FormItem>
                <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
                    <img alt={previewImage} style={{ width: '100%' }} src={previewImage} />
                </Modal>
            </FormItem>
            <FormItem
                style={style}
                // style={userInfoDetails && userInfoDetails.is_staff ? formItemHideStyle : formItemShowStyle}
                label="系统名称"
                name="system_name"
                {...FormItemLayout}
                rules={[
                    {
                        // required: style.display === formItemShowStyle.display ? true : false,
                        required: false,
                        message: '请输入系统名称！',
                    },
                ]}
                getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}
            >
                <Input />
            </FormItem>
            <Form.Item
                label="申报需量"
                style={style}
                // style={userInfoDetails && userInfoDetails.is_staff ? formItemHideStyle : formItemShowStyle}
                hasFeedback
                {...FormItemLayout}
            >
                <Form.Item name="declare_power" rules={[{ required: false, message: '请输入申报需量！' }]} noStyle>
                    <InputNumber min={1} precision={0} />
                </Form.Item>
                <span className="ant-form-text"> kW</span>
            </Form.Item>
            <FormItem
                label="账号"
                {...FormItemLayout}
                name="username"
                rules={[{ required: true, message: '请输入账号!' }]}
                getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}
            >
                <Input />
            </FormItem>
            <Form.Item
                label="账号限制"
                style={style}
                // style={userInfoDetails && userInfoDetails.is_staff ? formItemHideStyle : formItemShowStyle}
                hasFeedback
                {...FormItemLayout}
                rules={[{ required: true, message: '请输入账号限制!' }]}
            >
                <Form.Item
                    name="ram_count"
                    rules={[
                        {
                            required: style.display === formItemShowStyle.display ? true : false,
                            message: '请输入账号限制!',
                        },
                    ]}
                    noStyle
                >
                    <InputNumber min={1} precision={0} />
                </Form.Item>
                <span className="ant-form-text"> 个</span>
            </Form.Item>
            <FormItem
                label="姓名"
                {...FormItemLayout}
                name="real_name"
                rules={[{ required: true, message: '请输入姓名!', whitespace: true }]}
                getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}
            >
                <Input />
            </FormItem>
            <FormItem
                label="联系电话"
                {...FormItemLayout}
                name="mobile"
                rules={[
                    { pattern: /^1(3|4|5|6|7|8|9)\d{9}$/, message: '请输入正确的手机号' },
                    { required: true, message: '请输入手机号' },
                ]}
            >
                <Input addonBefore={'+86'} style={{ width: '100%' }} maxLength={11} />
            </FormItem>
            <Form.Item
                style={style}
                // style={userInfoDetails && userInfoDetails.is_staff ? formItemHideStyle : formItemShowStyle}
                label="有效期"
                {...FormItemLayout}
                name="expire_time"
                placeholder="请选择有效期"
                rules={[
                    { required: style.display === formItemShowStyle.display ? true : false, message: '请选择有效期' },
                ]}
            >
                <DatePicker format="YYYY-MM-DD HH:mm:ss" showTime />
            </Form.Item>
            <FormItem
                label="邮箱"
                {...FormItemLayout}
                name="email"
                rules={[
                    { type: 'email', message: '邮箱格式不正确！' },
                    { required: false, message: '请输入邮箱！' },
                ]}
            >
                <Input />
            </FormItem>
            <FormItem
                label="推送地址"
                {...FormItemLayout}
                name="url"
            >
                <Input />
            </FormItem>
            <FormItem
                style={style}
                // style={userInfoDetails && userInfoDetails.is_staff ? formItemHideStyle : formItemShowStyle}
                label="地址"
                {...FormItemLayout}
                name="location"
                rules={[{ required: false, message: '请选择地址！' }]}
                getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}
            >
                <Input.TextArea />
            </FormItem>
        </Form>
    );
};
/**
 * 搜索功能
 * @param {*} setSearchParams
 * @returns
 */
const SearchForm = ({ setSearchParams }) => {
    const [form] = Form.useForm();
    return (
        <Form
            form={form}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
            onFinish={value => {
                setSearchParams(value);
            }} //搜索
        >
            <FormItem
                label="账号名称"
                name="username"
                getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}
            >
                <Input placeholder="请输入账号名称" />
            </FormItem>
            <FormItem name="category" label="用户类型" style={{ margin: '0 2rem' }}>
                <Select key={1} placeholder={'请选择用户类型！'} virtual={false} allowClear style={{width: '150px'}}>
                    {accountDataAdmin.map(item => {
                        return (
                            <Option value={item.id} key={item.id}>
                                {item.value}
                            </Option>
                        );
                    })}
                </Select>
            </FormItem>
            <FormItem>
                <Button type="primary" htmlType="submit">
                    搜索
                </Button>
            </FormItem>
        </Form>
    );
};

export default AdminAccount;
