import React from 'react';
import { Chart, Series, YAxis, XAxis, Legend, Dataset, Tooltip, Grid, DataZoom } from '@echarts-start/react-bridge';
import { peakvalue, peakTime } from '../../../../util/function-util';
import * as echarts from 'echarts';

//根据判断可修改为横线，纵向显示图表
export const ChartStatistics = ({ rawData, color, bottom, unit, showColor }) => {
    return (
        <Chart color={color} style={{ width: '100%', height: '100%' }} resize={true}>
            <Dataset source={rawData} dimensions={rawData[0]} />
            <Legend
                options={{
                    top: '2%',
                    left: 'center',
                    icon: 'circle',
                    itemWidth: 10,
                    textStyle: {
                        // color: '#c7c7c7',
                        fontSize: 12,
                    },
                }}
            />
            <Grid
                options={{
                    bottom: bottom || '5%',
                    // left: rawData.length >= 20 ? '10%' : '5%',
                    left: '5%',
                    right: '5%',
                    // containLabel: true,
                }}
            />
            <Tooltip
                options={{
                    trigger: 'axis',
                }}
            />
            <YAxis
                options={{
                    name: rawData.length >= 20 ? '' : '单位：'+ unit,
                    type: rawData.length >= 20 ? 'category' : 'value',
                    data:
                        rawData.length >= 20 &&
                        rawData.map(item => {
                            return item.name || peakTime(item.time);
                        }),
                    inverse: rawData.length >= 20 ? true : false,
                }}
            />
            <XAxis
                options={{
                    type: rawData.length >= 20 ? 'value' : 'category',
                    name: rawData.length >= 20 ? '单位：'+ unit : '',
                    data:
                        rawData.length <= 20 &&
                        rawData.map(item => {
                            return item.name || peakTime(item.time);
                        }),
                    axisLabel: {
                        //x轴文字的配置
                        show: true,
                        //interval: 0, //使x轴文字显示全
                    },
                }}
            />
            {rawData.length >= 20 && (
                <DataZoom
                    options={{
                        type: 'inside', //inside slider
                        show: true, //flase直接隐藏图形
                        yAxisIndex: [0],
                        left: '1%', //滚动条靠左侧的百分比
                        // top:'5%',
                        // bottom: '6%',
                        // bottom: 5,
                        start: 0, //滚动条的起始位置
                        end: rawData.length > 30 ? 10 : 40, //滚动条的截止位置（按比例分割你的柱状图x轴长度）
                        orient: 'vertical',
                    }}
                />
            )}
            {/* {map(servers, (_, idx) => ( */}
            <Series
                // key={idx}
                options={{
                    barGap: 0,
                    type: 'bar',
                    barMaxWidth: 15,
                    barMinWidth: 10,
                    data: rawData.map(item => {
                        return peakvalue(item.amount);
                    }),
                    itemStyle: {
                        borderRadius: [6, 6, 0, 0],
                        color: showColor && new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            { offset: 0, color: '#F7A892' },
                            { offset: 1, color: '#ED7958' },
                        ]),
                    },
                }}
            />
            {/* ))} */}
        </Chart>
    );
};
