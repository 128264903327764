import Storage from './Storage';

const TOKEN = "login_token";
export const fakeAuth = {
    authenticate() {
        const token = Storage.getItem(TOKEN);
        return token;
    },
    setToken(token) {
        Storage.setItem(TOKEN, token);
    },
    signout() {
        Storage.removeItem(TOKEN);
    },
    setStorage(key, value) {
        Storage.setItem(key, value);
        return true;
    },
    getStorage(keys) {
        const value = Storage.getItem(keys);
        return value;
    },
    logout(keys) {
        Storage.removeItem(keys);
    },
};