import React from 'react';
// import { Button, Col, Form, Row, Table, Popconfirm, Input, Select, DatePicker, Cascader } from 'antd';
import { BreadcrumbCustom } from '../../../components/BreadcrumbCustom';

export const EditHiddenAlarm = () => {
    return (
        <div className="container clearfix">
            <BreadcrumbCustom paths={['首页', '隐患管理', '隐患列表', '隐患编辑']} className="rumbCustom" />
            <div className="row clearfix" style={{padding:'1rem',margin: '1rem', backgroundColor: '#ffffff', borderRadius: '10px'}}>
                <div className="chart">
                    <div>功能暂未开发</div>
                </div>
            </div>
        </div>
    );
};