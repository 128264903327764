import React, { useState, useEffect, useMemo, useRef } from 'react';
import {Row, Col, Tooltip, Typography} from 'antd';
import {isEmpty, isUndefined, map, omitBy, isNull, isNumber} from 'lodash';
import moment from 'moment';
import { Trees } from './Tree';
import EchartView from './EchartView';
import { ChartView } from '../MonitorManagement/ChartView';
import { LineChart } from '../MonitorManagement/LineChart';
import { EmptyView } from '../../../components/EmptyView';
import { Loading } from '../../../components/Loading';
import { MyIcon } from '../../../util/iconfont';
import { getViewCurrent, getViewTree, smokerDetailDevice, temperatureDetailDevice } from '../../../api/webServe';
import { peakvalue } from '../../../util/function-util';
import noDevice from '../../../assets/images/noDevice.png';
import './style.less';
import _ from "lodash";
import { day30 } from '../../../util/timeUtil';
import { TimeSearch } from '../EnergyConsumptionChart/ECRCondition';


export const Current = () => {
    const [time, setTime] = useState([moment(day30[0]),moment(day30[1]).add(+1,'days').valueOf()]);
    const [data, setData] = useState({ data: undefined, isLoading: false });
    const [treeData, setTreeData] = useState([]);
    const [, setAllNode] = useState([]);
    const [areaInfo, setAreaInfo] = useState();
    const [treeId, setTreeId] = useState();
    const [location, setLocation] = useState();
    const id = useMemo(() => {
        if (isEmpty(areaInfo) && isEmpty(treeId)) {
            return null;
        }
        if (!isEmpty(areaInfo)) {
            return areaInfo.id;
        }
        return treeId;
    }, [areaInfo, treeId]);
    useEffect(() => {
        treeList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    let nodeList = [];

    //use this reference to trace the right layer of the recursive tree generation
    const currentNodeLayer = useRef(1);
    //关闭,打开,在线,告警
    const onlineStatus = ["0","1","2","4"]
    const calculateOnlineDeviceCount = (companyOrArea) => {
        if (companyOrArea.children === undefined) {
            return 0;
        }
        let onlineCount = 0;
        for (const eachChildNode of companyOrArea.children) {
            if (eachChildNode.dev_num === undefined) {
                //this node is device, determine if this device online
                if (eachChildNode.status !== undefined) {
                    //robustness
                }
                if (onlineStatus.includes(eachChildNode.status)) {
                    onlineCount +=1;
                }
            }else {
                //this node is area, so calculate online device count under this area
                onlineCount += calculateOnlineDeviceCount(eachChildNode);
            }
        }
        return  onlineCount;
    }

    const generateList = data => {
        currentNodeLayer.current += 1 ;
        return map(data, item => {
            const { id, name, dev, online, dev_cate } = item;
            nodeList.push({ id, name });
            let childrenNode = item.children;
            if (currentNodeLayer.current === 2) {
                //sort the second layer(company layer) by count of online device in each company
                childrenNode = childrenNode.map((eachCompany)=>{
                   const onlineDeviceCountOfCompany = calculateOnlineDeviceCount(eachCompany);
                   // console.log(`当前的公司名称为 : ${eachCompany.name} , 在线设备数量 : ${onlineDeviceCountOfCompany}`)
                    eachCompany.onlineDeviceCount = onlineDeviceCountOfCompany;
                    return eachCompany
                })
                childrenNode = _.orderBy(childrenNode, ['onlineDeviceCount'], [ 'desc']);
            }
            return {
                ...item,
                key: `${id}` || `${item.dev}`,
                title: name,
                children: generateList(childrenNode),
                online: online,
                dev_cate: dev_cate,
                id: `${id}` !== 'undefined' ? `${id}` : `${dev}`,
                disabled: `${dev}` ? false : true,
            };
        });
    };

    const ids = useRef([]);
    const deviceIDList = data => {
        return map(data, item => {
            const { id, online, dev_cate } = item;
            if(online){
                ids.current.push({id:id,dev_cate:dev_cate})
            }
                return {
                    children: deviceIDList(item.children),
                    id: id,
                };
        });
    };

    const treeList = () => {
        getViewTree()
            .then(result => {
                currentNodeLayer.current = 1;
                setTreeData(generateList(result.data));
                if (isUndefined(areaInfo)){
                    deviceIDList(result.data)
                    setTreeId(ids.current.length > 0 && ids.current[0].id)
                }else{
                    setTreeId()
                }
            })
            .catch();
    };
    useEffect(() => {
        if (!isUndefined(treeId) || ids.current.length !==0) {
            setData({ data: null, isLoading: true });
            if(areaInfo ? areaInfo.dev_cate === 'F3' || areaInfo?.dev_cate === 'FB' : ids.current?.[0]?.dev_cate === 'F3' || ids.current?.[0]?.dev_cate === 'FB'){
                getViewCurrent({ id: id, start_time: moment(time[0]).startOf('day').valueOf(), end_time: moment(time[1]).startOf('day').valueOf() })
                .then(result => {
                    setLocation(result.data.location);
                    setData({ data: result.data.information, isLoading: false });
                })
                .catch(() => {
                    setData({ data: [], isLoading: false });
                });
            } else if(areaInfo ? areaInfo.dev_cate === 'F2' : ids.current?.[0]?.dev_cate === 'F2'){
                let searchParams = {};
                searchParams.start_time =
                    time !== null && time !== undefined && time[0] !== '' && time[0] !== undefined ? time[0] : undefined;
                searchParams.end_time =
                    time !== null && time !== undefined && time[1] !== '' && time[1] !== undefined ? time[1] : undefined;
                const params = omitBy(searchParams, value => {
                    if (isNaN(value) || value === '') {
                        return isUndefined(value) || isNull(value) || value === '';
                    } else {
                        return isNull(value);
                    }
                });
                smokerDetailDevice({ device_id: id, ...params })
                    .then(result => {
                        setLocation(result.location);
                        setData({ data: result.data, isLoading: false });
                    })
                    .catch(() => {
                        setData({ data: null, isLoading: false });
                    });
            } else if(areaInfo ? areaInfo.dev_cate === 'F4' : ids.current?.[0]?.dev_cate === 'F4'){
                let searchParams = {};
                searchParams.start_time =
                    time !== null && time !== undefined && time[0] !== '' && time[0] !== undefined ? time[0] : undefined;
                searchParams.end_time =
                    time !== null && time !== undefined && time[1] !== '' && time[1] !== undefined ? time[1] : undefined;
                const params = omitBy(searchParams, value => {
                    if (isNaN(value) || value === '') {
                        return isUndefined(value) || isNull(value) || value === '';
                    } else {
                        return isNull(value);
                    }
                });
                temperatureDetailDevice({ device_id: id, ...params })
                    .then(result => {
                        let xTime = [];
                        let yDataTemperature = [];
                        let yDataHumidity = [];
                        setLocation(result.location);
                        map(result.data, item => {
                            xTime.push(item.time);
                            yDataTemperature.push(
                                item.t_mean === null || item.temperature_mean === null || isUndefined(item.t_mean || item.temperature_mean)
                                    ? null
                                    : peakvalue(item.t_mean || item.temperature_mean),
                            ); //平均温度
                            yDataHumidity.push(
                                item.h_mean === null || item.humidity_mean === null || isUndefined(item.h_mean || item.humidity_mean)
                                    ? null
                                    : peakvalue(item.h_mean || item.humidity_mean),
                            ); //平均湿度
                        });
                        setData({
                            data: [yDataTemperature, yDataHumidity],
                            isLoading: false,
                            legend: ['温度', '湿度'],
                            time: xTime,
                        });
                    })
                    .catch(() => {
                        setData({ data: null, isLoading: false });
                    });
            } else {
                setLocation('');
                setData({ data: null, isLoading: false }); 
            }
        }else{

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [treeId, time]);
    let yData = [],
        xTime = [];
    (areaInfo?.dev_cate === 'F3' || (treeId && ids.current?.[0]?.dev_cate === 'F3') || areaInfo?.dev_cate === 'FB' || (treeId && ids.current?.[0]?.dev_cate === 'FB')) && data.data &&
        data.data.forEach(item => {
            yData.push(peakvalue(item.number));
            xTime.push(item.time);
        });
    const colors = [
        {
            rgbOpacity: 'rgba(189,210,253,0.3)',
            rgb: 'rgb(189,210,253)',
            color: '#BDD2FD',
            line: '#BDD2FD',
        },
        {
            rgbOpacity: 'rgba(163,232,205,0.3)',
            rgb: 'rgb(163,232,205)',
            color: '#BDEFDB',
            line: '#BDEFDB',
        },
    ];
    return (
        <Row style={{ width: '100%', height: '35.5vh', marginBottom: '1rem', marginLeft: '0' }} gutter={32} className={'CurrentStyle'}>
            <Col className="gutter-row" span={24} style={{ paddingRight: '0', paddingLeft: '0' }}>
                <span className="common_title">
                    <MyIcon
                        type="icon_title_trend"
                        style={{
                            fontSize: '1.5rem',
                            marginTop: '-0.1rem',
                            verticalAlign: 'middle',
                            paddingRight: '0.6rem',
                        }}
                    />
                    运行趋势
                </span>
            </Col>
            <Col
                span={7}
                style={{
                    paddingRight: '0',
                    paddingLeft: '0',
                    boderLeftBottomRadius: '10px',
                    boderRightBottomRadius: '10px',
                    height: '100%',
                }}
            >
                <div
                    style={{
                        padding: '1rem',
                        backgroundColor: '#fff',
                        height: '90%',
                        borderBottomLeftRadius: '10px',
                        borderBottomRightRadius: '10px',
                    }}
                >
                    <Trees
                        treeData={treeData}
                        onClick={value => {
                            !isEmpty(treeData) && setTreeId(value.id);
                            if (!areaInfo || areaInfo.id !== value.id) {
                                setAreaInfo(value);
                            }
                        }}
                        getNode={all => {
                            setAllNode(all);
                        }}
                        height={'33vh'}
                    />
                </div>
            </Col>
            <Col span={17} style={{ paddingRight: '0' }}>
                <Row
                    style={{
                        ...Style.module,
                        margin: '0 0 1rem',
                        borderBottomLeftRadius: '10px',
                        borderBottomRightRadius: '10px',
                    }}
                >
                    <Col span={12}>
                        <span style={Style.title} title={location}>{location ? (location.length > 12 ? location.substr(0,12) + '... ': location) : ''} </span>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right', paddingRight: '1rem', paddingTop: '0.5rem' }}>
                        {/* <span
                            style={{
                                marginRight: '1rem',
                                color: '关闭' ? 'red' : 'green',
                                fontWeight: '600',
                                fontSize: '14px',
                                fontFamily: '楷体',
                            }}
                        >
                            {status1 && status[status1]}
                        </span> */}
                        {/* {!data.isLoading && (
                            <DatePicker
                                onChange={onChange}
                                format="yyyy-MM-DD"
                                style={{ height: '25px', fontSize: '12px' }}
                                // defaultValue={moment(new Date(), 'YYYY-MM-DD')}
                                value={moment(time[0], 'YYYY-MM-DD')}
                                allowClear={false}
                            />
                        )} */}

                            <div style={{ height: '25px', fontSize: '12px', marginBottom:' 1rem', marginRight: '-1rem', }}>
                                <TimeSearch
                                    timeValue={[time[0],moment(time[1]).add(-1,'days').valueOf()]}
                                    onValueChange={times => {
                                        if (!id && !isNumber(id)) {
                                            return;
                                        }
                                        const { time } = times;
                                        setTime(time);
                                    }}
                                    isShowButton={true}
                                    isDisabledDate={true}
                                />
                            </div>

                    </Col>
                    <Col
                        span={24}
                        style={{
                            backgroundColor: '#fff',
                            padding: '0 1rem',
                            borderBottomLeftRadius: '10px',
                            borderBottomRightRadius: '10px',
                        }}
                    >
                        <div
                            style={{
                                width: '100%',
                                borderTop: '1px solid #F0F0F0',
                                paddingTop: '0.5rem',
                                height: '25.5vh',
                                minHeight: '26.5vh',
                            }}
                        >
                            <Loading show={data.isLoading}>
                                {isEmpty(data.data) ? (
                                    <EmptyView description={data.data === undefined ? '请点击左侧设备' : (data.data === null ? '暂无设备在线' : '暂无数据')} image={data.data === undefined && noDevice}/>
                                ) :
                                    (areaInfo ? areaInfo.dev_cate === 'F3' || areaInfo.dev_cate === 'FB' : (treeId && ids.current?.[0]?.dev_cate === 'F3') || (treeId && ids.current[0].dev_cate === 'FB')) ?
                                    <>
                                        <span style={{position: 'absolute', fontSize: '10px', bottom: '0.2rem', width: '100%', textAlign: 'center', color: '#6c6c6c'}}>时间窗口最大30天</span>
                                        <EchartView yData={yData.slice(0,23)} xTime={xTime.slice(0,23)} names={'电流'} style={{ height: '100%' }} addname={'时间窗口最大30天'} /> 
                                    </>
                                    :
                                    ((areaInfo ? areaInfo.dev_cate === 'F4' : (treeId && ids.current?.[0]?.dev_cate === 'F4')) && data.time ?
                                    <>
                                        <span style={{position: 'absolute', fontSize: '10px', top: '0.8rem', left: 0, color: '#6c6c6c'}}>时间窗口最大30天</span>
                                        <LineChart data={data} color={colors} /> 
                                    </>
                                    :
                                    ((areaInfo ? areaInfo.dev_cate === 'F2' : (treeId && ids.current?.[0]?.dev_cate === 'F2')) ?
                                    <>
                                        <span style={{position: 'absolute', fontSize: '10px', top: '0.2rem', left: 0, color: '#6c6c6c'}}>时间窗口最大30天</span>
                                        <ChartView
                                            rawData={data.data}
                                            name={'烟雾报警'}
                                            height={'90%'}
                                            top={'-10px'}
                                            bottom={'3%'}
                                        /> 
                                    </>
                                    : <EmptyView description={data.data === undefined ? '请点击左侧设备' : (data.data === null ? '暂无设备在线' : '暂无数据')} image={data.data === undefined && noDevice}/>)
                                )}
                            </Loading>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

/**
 * 样式
 */
const Style = {
    title: {
        display: 'flex',
        width: '100%',
        height: '40px',
        lineHeight: '40px',
        fontSize: '1rem',
        color: '#333333',
        fontWeight: 500,
        padding: '0 1rem',
        verticalAlign: 'middle',
    },
    module: {
        background: '#ffffff',
        boxShadow: `0px 0px 3px 0px rgba(0,0,0,0.08)`,
        width: '100%',
    },
};
