import React, { useState, useEffect } from 'react';
import { useDidCache, useDidRecover, dropByCacheKey, getCachingKeys, clearCache } from 'react-router-cache-route'
import {
    Button,
    Col,
    Form,
    Row,
    Table,
    Popconfirm,
    Input,
    Select,
    message,
    Tooltip,
    Divider,
    Typography,
    Space,
} from 'antd';
import { BreadcrumbCustom } from '../../../components/BreadcrumbCustom';
import { removePatrolTask, getPatrolTask } from '../../../api/webServe';
import { omitBy, isUndefined, isNull, trim } from 'lodash';
import { dataMode, mode, patrol, date, week, dataStatus, patrolLevel, dateDatas /*statusTask*/ } from '../../../global';
import { MyIcon } from '../../../util/iconfont';
import RouteHelper from '../../../tools/RouteHelper';
// import { GlobalModal } from '../../../components/GlobalModal';
// import { ReadTasks } from './ReadTasks';

const FormItem = Form.Item;
const Option = Select.Option;

export const PatrolTask = () => {
    useDidCache(() => {
        // console.log('List cached 1')
        // console.log(getCachingKeys(),'1')
    })
    // support multiple effect 被缓存
    useDidCache(() => {
        // console.log('List cached 2')
        // console.log(getCachingKeys(),'2')
    })
    // 被恢复
    useDidRecover(() => {
        if(getCachingKeys() === ['MyComponent']){
            dropByCacheKey('MyComponent')
            clearCache()
        }
    })
    return (
        <div className="container clearfix">
            <BreadcrumbCustom paths={['首页', '巡检管理', '巡检任务']} className="rumbCustom" />
            <div className="row clearfix" style={{ backgroundColor: 'transparent', margin: '1rem', padding: '0' }}>
                <HiddenTable />
            </div>
        </div>
    );
};

const HiddenTable = () => {
    const [form] = Form.useForm();
    // const childRef = useRef();
    // const [modalType, setModalType] = useState('read'); //add 新增  //edit 编辑  //ready 查看
    const [data, setData] = useState({ data: null, isLoading: true }); //隐患列表数据
    const [count, setCount] = useState(0); //数据总条数
    const [page, setPage] = useState(1); //当前页
    const [page_size, setPageSize] = useState(10); //当前页显示条数
    const [searchParams, setSearchParams] = useState({}); //搜索
    const getList = () => {
        const params = omitBy(searchParams, value => {
            if (isNaN(value) || value === '') {
                return isUndefined(value) || isNull(value) || value === '' || trim(value) === '';
            } else {
                return isNull(value);
            }
        });
        setData({ data: [], isLoading: true });
        getPatrolTask({ page, page_size, ...params })
            .then(result => {
                setData({ data: result.data, isLoading: false });
                setCount(result.count);
            })
            .catch();
    };
    useEffect(() => {
        getList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams, page, page_size]);

    const renderForm = () => {
        return (
            <Form
                layout="horizontal"
                form={form}
                onFinish={value => {
                    setSearchParams(value);
                }}
                initialValues={{ manner: '', cycle: '', genre: '' }}
            >
                <Row gutter={16} style={{ marginLeft: '0px', marginRight: '0px', display: 'flex', flexWrap: 'nowrap',  justifyContent: 'space-between', width: '100%'}}>
                    <Col span={4} offset={6}>
                        <FormItem
                            name="name"
                            label="名称"
                            rules={[
                                { required: false, message: '请选择名称' },
                                {
                                    pattern: /^(?!\s)(?!.*\s$)/,
                                    message: '名称不允许有空格!',
                                },
                            ]}
                            getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}
                        >
                            <Input placeholder="请输入名称" />
                        </FormItem>
                    </Col>
                    <Col span={4}>
                        <FormItem name="manner" label="方式" rules={[{ required: false, message: '请选择方式' }]}>
                            <Select placeholder="请选择" allowClear={true}>
                                <Option value={''}>全部</Option>
                                {mode.map(item => {
                                    return (
                                        <Option value={item.id} key={item.id}>
                                            {item.value}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </FormItem>
                    </Col>
                    <Col span={4}>
                        <FormItem name="genre" label="类型" rules={[{ required: false, message: '请选择类型' }]}>
                            <Select placeholder="请选择巡检类型" allowClear={true} virtual={false}>
                                <Option value={''}>全部</Option>
                                {patrol.map(item => {
                                    return (
                                        <Option value={item.id} key={item.id}>
                                            {item.value}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </FormItem>
                    </Col>
                    <Col span={4}>
                        <FormItem name="cycle" label="周期" rules={[{ required: false, message: '请选择周期' }]}>
                            <Select placeholder="请选择" allowClear={true} virtual={false}>
                                <Option value={''}>全部</Option>
                                {dateDatas.map(item => {
                                    return (
                                        <Option value={item.id} key={item.id}>
                                            {item.value}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </FormItem>
                    </Col>
                    {/* <Col span={3}>
                        <FormItem name="status" label="状态" rules={[{ required: false, message: '请选择状态' }]}>
                            <Select placeholder="请选择" allowClear={true} virtual={false}>
                                {statusTask.map(item => {
                                    return (
                                        <Option value={item.id} key={item.id}>
                                            {item.value}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </FormItem>
                    </Col> */}
                    <Col style={{ textAlign: 'right', padding: '0px' }}>
                        <FormItem>
                            <Button type="primary" htmlType="submit">
                                搜索
                            </Button>
                        </FormItem>
                    </Col>
                </Row>
            </Form>
        );
    };
    const columns = [
        {
            title: '序号',
            dataIndex: 'id',
            key: 'id',
            width: 100, //宽度
            render: (text, record, index) => `${index + 1}`,
        },
        {
            title: '名称',
            dataIndex: 'name',
            render: text => (
                <p
                    style={{
                        minWidth: '8rem',
                        maxWidth: '12rem',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                    }}
                    title={text}
                >
                    {text}
                </p>
            ),
        },
        {
            title: '类型',
            dataIndex: 'genre',
            render: text => (
                <p
                    style={{
                        minWidth: '3rem',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                    }}
                    title={text === null ? '' : patrolLevel[text]}
                >
                    {text === null ? '' : patrolLevel[text]}
                </p>
            ),
        },
        {
            title: '周期',
            dataIndex: 'cycle',
            width: 80,
            render: text => <span>{text === null ? '' : date[text]}</span>,
        },
        {
            title: '巡检日期',
            dataIndex: 'date',
            render: (text, record) => (
                <p
                    style={{
                        minWidth: '3rem',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                    }}
                    title={text === null ? '-' : record.cycle === 1 ? week[text] : text + '号'}
                >
                    {text === null || text === 0 ? '-' : record.cycle === 1 ? week[text] : text + '号'}
                </p>
            ),
        },
        {
            title: '方式',
            dataIndex: 'manner',
            render: text => (
                <p
                    style={{
                        minWidth: '3rem',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                    }}
                    title={text}
                >
                    {text === null ? '' : dataMode[text]}
                </p>
            ),
        },
        {
            title: '巡检人',
            dataIndex: 'user',
            render: text => (
                <p
                    style={{
                        minWidth: '4rem',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                    }}
                    title={text}
                >
                    {text}
                </p>
            ),
        },
        {
            title: (
                <Tooltip title={Title}>
                    <span style={{ verticalAlign: 'middle' }}>状态</span>
                    <MyIcon
                        type="iconbangzhu"
                        style={{ marginLeft: '0.3rem', fontSize: '0.75rem', verticalAlign: 'middle' }}
                    />
                </Tooltip>
            ),
            dataIndex: 'status',
            width: 100,
            render: text => <span>{text === null ? '' : dataStatus[text]}</span>,
        },
        {
            title: '提交时间',
            dataIndex: 'time',
            width: 200,
            render: text => <span>{text === null ? '-' : text && text.slice(0, 10) + ' ' + text.slice(11, 19)}</span>,
        },
        {
            title: '操作',
            dataIndex: 'opera',
            width: 160,
            render: (text, record) => (
                <Space split={<Divider type="vertical" />}>
                    <Typography.Link
                        className="opera"
                        // target="_blank"
                        // href={`/web/patrol/patrolTasks/readTasks?id=${record.id}`}
                    >
                        <MyIcon
                            type={'iconchakan1'}
                            style={{ fontSize: '1rem', verticalAlign: 'middle', color: 'rgba(0, 0, 0, 0.85)' }}
                            title={'查看'}
                            onClick={() => {
                                RouteHelper.locationTo('/web/patrol/patrolTask2/readTasks', { id: record.id });
                                // setModalType('read');
                            }}
                        />
                    </Typography.Link>
                    <Typography.Link className="opera">
                        <Popconfirm
                            title="确定要删除吗?"
                            onConfirm={() => {
                                removePatrolTask(record.id)
                                    .then(() => {
                                        message.success('删除成功');
                                        getList();
                                    })
                                    .catch();
                            }}
                        >
                            <MyIcon
                                type='iconshanchu1'
                                style={{ color: 'rgba(0, 0, 0, 0.85)', fontSize: '1rem', verticalAlign: 'middle' }}
                                title={'删除'}
                            />
                        </Popconfirm>
                    </Typography.Link>
                </Space>
            ),
        },
    ];
    /**
     *  表格分页
     */
    const paginationNo = {
        pageSize: page_size, //每页的条数
        current: page, //当前页
        total: count, //总数据
        showSizeChanger: true,
        onChange: page => {
            setPage(page);
        }, //点击分页号时触发的方法
        onShowSizeChange: (current, pageSize) => {
            setPageSize(pageSize);
        }, //pageSize 变化的回调
        hideOnSinglePage: true, //为true则数据条数不满或刚好一页，则隐藏分页器
        pageSizeOptions: ['10', '15', '20', '30', '50', '100'], // 分页类别
        showTotal: total => `共 ${total} 条`,
        style: { marginTop: '2rem' },
    };

    return (
        <div
            className="tableBox"
            style={{
                width: '100%',
                height: '100%',
                backgroundColor: '#fff',
                borderRadius: '10px',
                padding: '1rem',
                boxSizing: 'border-box',
            }}
        >
            {/* 新增、搜索、导出按钮功能 */}
            <Row gutter={16}>
                <Col span={24}>{renderForm()}</Col>
            </Row>
            {/* 表格 */}
            <Table
                rowKey={record => record.id}
                columns={columns}
                dataSource={data.data}
                bordered={true}
                loading={data.isLoading}
                scroll={{ x: '100%' }}
                pagination={paginationNo}
                locale={{
                    cancelSort: '点击取消排序',
                    triggerAsc: '点击升序',
                    triggerDesc: '点击降序',
                    filterConfirm: '确定',
                    filterReset: '重置',
                    emptyText: '暂无数据',
                }}
            />
            {/* <GlobalModal
                title={'巡检任务信息'}
                childRef={childRef}
                onOk={() => {
                    if (modalType === 'read') {
                        // userList();
                    }
                }}
                footer={null}
            >
                <ReadTasks />
            </GlobalModal> */}
        </div>
    );
};

const Title = () => {
    return (
        <div>
            <p>逾期：超过5天不能再提交。</p>
            <p>已完成：已完成巡检任务。</p>
            <p>待完成：日期为今日任务还未提交。</p>
        </div>
    );
};
