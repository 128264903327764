import React, { useState, useEffect, useRef, useImperativeHandle, useMemo } from 'react';
import { BreadcrumbCustom } from '../../../components/BreadcrumbCustom';
import {
    Button,
    Col,
    Form,
    Row,
    Table,
    Popconfirm,
    Input,
    Divider,
    Typography,
    Space,
    Select,
    DatePicker,
    message,
    Upload,
    TreeSelect,
    Collapse,
    Switch,
    Checkbox,
    Radio,
} from 'antd';
import { map, get, omit, omitBy, isEmpty, isUndefined, debounce, toNumber, filter } from 'lodash';
import moment from 'moment';
import ToastHelper from '../../../tools/ToastHelper';
import DialogHelper from '../../../tools/DialogHelper';
import RouteHelper from '../../../tools/RouteHelper';
import { CommonTree } from '../../../components/CommonTree';
import { CommonModal } from '../../../components/CommoModal';
import { EmptyView } from '../../../components/EmptyView';
import { MyIcon } from '../../../util/iconfont';
import {
    addArea,
    addEquipment,
    editArea,
    editEquipment,
    equipmentDetail,
    exportArea,
    exportEquipment,
    getAreaList,
    // treeHasIcon,
    getEquipmentList,
    getFunctionList,
    importArea,
    importEquipment,
    qrCodeToEquipment,
    removeArea,
    removeEquipment,
    changeSwitch,
    dataExport,
    updateDevice,
    editActivation,
    getRisk,
} from '../../../api/webServe';
import { getDeviceCategory } from '../../../api/adminServe';
import { BASE_URL, commonPageSize, projectEquipment, status, switchText } from '../../../global';
import Style from './style.module.less';
import { disabledDate_3month } from '../../../util/timeUtil' 

const { RangePicker } = DatePicker;
const { SHOW_PARENT } = TreeSelect;
const FormItem = Form.Item;
const { Panel } = Collapse;

const recursion = data => {
    return map(data, item => {
        return { ...item, key: item.id, title: item.name, children: recursion(item.children) };
    });
};

export const EquipmentManagement = () => {
    const [treeNode, setTreeNode] = useState(null);
    const [allTree, setAllTree] = useState([]);
    const importFile = params => {
        importArea({ ...params })
            .then(() => {
                ToastHelper.successToast('导入成功');
            })
            .catch(() => {
                ToastHelper.errorToast('请检查文件格式');
            });
    };
    const exportData = () => {
        exportArea({ region_id: treeNode.id })
            .then(result => {
                window.open(BASE_URL + result.data.url);
            })
            .catch();
    };
    return (
        <div style={{ width: '100%' }} className={Style.container}>
            <BreadcrumbCustom paths={['首页', '监测管理', '设备管理']} className="rumbCustom" />
            <div style={{ margin: '1rem', height: 'calc(100vh - 150px)' }}>
                <Row style={{ width: '100%', borderRadius: '10px' }}>
                    <Col
                        className="gutter-row bgColorFFF"
                        span={4}
                        style={{
                            background: '#ffffff',
                            padding: '1rem',
                            borderRadius: '10px' /* marginBottom: '10px'*/,
                        }}
                    >
                        <>
                            <div style={{ height: '76vh', marginBottom: '0px' }}>
                                <AreaManage getAllTree={setAllTree} setTreeNode={setTreeNode} allTree={allTree} />
                            </div>
                            {allTree.length !== 0 && (
                                <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '20px' }}>
                                    <Upload
                                        customRequest={file => {
                                            importFile({ file: file.file });
                                        }}
                                        accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                        showUploadList={false}
                                    >
                                        <div
                                            onClick={() => {}}
                                            type="primary"
                                            style={{
                                                verticalAlign: 'middle',
                                            }}
                                        >
                                            <span
                                                style={{
                                                    color: 'rgba(0,0,0,0.85)',
                                                    verticalAlign: 'middle',
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                导入
                                            </span>
                                            <MyIcon
                                                type={'icondaoru'}
                                                style={{
                                                    marginLeft: '0.5rem',
                                                    fontSize: '0.8rem',
                                                    verticalAlign: 'middle',
                                                    color: 'rgba(0, 0, 0, 0.85)',
                                                    cursor: 'pointer',
                                                }}
                                                title={'导入'}
                                            />
                                        </div>
                                    </Upload>
                                    <div onClick={exportData} style={{ display: 'inline-block', cursor: 'pointer' }}>
                                        <span
                                            style={{
                                                color: 'rgba(0,0,0,0.85)',
                                                verticalAlign: 'middle',
                                            }}
                                        >
                                            导出
                                        </span>
                                        <MyIcon
                                            type={'icondaochu'}
                                            style={{
                                                marginLeft: '0.5rem',
                                                fontSize: '0.8rem',
                                                verticalAlign: 'middle',
                                                color: 'rgba(0, 0, 0, 0.85)',
                                            }}
                                            title={'导出'}
                                        />
                                    </div>
                                </div>
                            )}
                        </>
                        {/* ) : (
                            <div style={{ width: '100%', height: '80vh' }}>
                                <EmptyView />
                            </div>
                        )} */}
                    </Col>
                    <Col
                        span={20}
                        style={{
                            background: '#ffffff',
                            padding: '1em',
                            borderRadius: '10px',
                            boxSizing: 'border-box',
                        }}
                    >
                        {!isEmpty(treeNode) ? <EquipmentTable treeId={treeNode.id} allTree={allTree} /> : <EmptyView />}
                    </Col>
                </Row>
            </div>
        </div>
    );
};
const AreaManage = ({ setTreeNode, getAllTree, allTree }) => {
    const [modalType, setModalType] = useState('add'); //edit add export other
    const [treeData, setTreeData] = useState([]);
    const [nodeInfo, setNodeInfo] = useState(null);
    const childRef = useRef();
    const formRef = useRef();
    useEffect(() => {
        treeList();
    }, []);
    const treeList = () => {
        // treeHasIcon()
        getAreaList()
            .then(result => {
                setTreeData(result.data);
            })
            .catch();
    };
    const edit = node => {
        setNodeInfo(node);
        setModalType('edit');
        childRef.current.showModal();
    };
    const add = node => {
        setNodeInfo(node);
        setModalType('add');
        childRef.current.showModal();
    };
    const remove = node => {
        // const content = '本操作将删除监测点所有设备，数据无法恢复。';//被删除监测点有安装设备，请先移动或删除设备。
        const content = '是否删除监测点。';
        const callBack = hiddenDialog => {
            removeRequest(node.id, hiddenDialog);
        };
        DialogHelper.showDialog(content, '', callBack, 300);
    };
    const addRequest = params => {
        addArea(params)
            .then(() => {
                childRef.current.hiddenModal();
                ToastHelper.successToast('添加成功');
                treeList();
            })
            .catch(() => {
                childRef.current.hiddenModal();
            });
    };
    const editRequest = params => {
        editArea({ ...params, id: nodeInfo.id })
            .then(() => {
                childRef.current.hiddenModal();
                ToastHelper.successToast('编辑成功');
                treeList();
            })
            .catch(() => {
                childRef.current.hiddenModal();
            });
    };
    const removeRequest = (id, hiddenDialog) => {
        removeArea(id)
            .then(result => {
                ToastHelper.successToast('删除成功');
                treeList();
                hiddenDialog();
            })
            .catch(() => {
                hiddenDialog();
            });
    };
    return (
        <div style={{ minHeight: '300px' }}>
            <CommonTree
                treeData={treeData}
                add={add}
                edit={edit}
                remove={remove}
                onClick={setTreeNode}
                getAllTree={getAllTree}
            />
            <CommonModal
                title={`${modalType === 'edit' ? '编辑' : '新增'}监测点`}
                childRef={childRef}
                onOk={() => {
                    formRef.current
                        .validateFields()
                        .then(result => {
                            try {
                                const { name, fatherPointer, content, risk, lnglat, address } = result;
                                let newResult = {
                                    name,
                                    pid: fatherPointer,
                                    location: content === '' ? null : content,
                                    risk: risk === null ? risk : risk.length > 0 ? risk[0] : risk,
                                    longitude: lnglat === null ? null : lnglat.lng,
                                    latitude: lnglat === null ? null : lnglat.lat,
                                    address: address,
                                };
                                //格式化处理，字段值为空就不传递给后端
                                // let params = omitBy(newResult, value => {
                                //     return isUndefined(value) || isNull(value) || value === '' || trim(value) === '';
                                // });
                                if (modalType === 'add') {
                                    addRequest(newResult);
                                }
                                if (modalType === 'edit') {
                                    editRequest(newResult);
                                }
                            } catch (e) {
                                console.log('deepcode' + e.toString());
                            }
                        })
                        .catch(err => {
                            childRef.current.endLoading();
                        });
                }}
            >
                <PointForm type={modalType} nodeInfo={nodeInfo} childRef={formRef} allTree={allTree} />
            </CommonModal>
        </div>
    );
};
const EquipmentTable = ({ treeId, allTree }) => {
    const childRef = useRef();
    const ref = useRef(null);
    const formChildrenRef = useRef();
    const [total, setTotal] = useState(0);
    const [modalType, setModalType] = useState('edit'); //edit add export other
    const [searchParams, setSearchParams] = useState({});
    const [current, setCurrent] = useState(1);
    const [currentEquipment, setCurrentEquipment] = useState(null);
    const [dataSource, setDataSource] = useState({ data: [], isLoading: true });
    const [category, setCategory] = useState([]);
    const [functionList, setFunctionList] = useState([]);
    const [deviceName, setDeviceName] = useState();
    const [codeDisabled, setCodeDisabled] = useState(false);
    const getList = (page = 1) => {
        setDataSource({ ...dataSource, isLoading: true });
        setCurrent(page);
        const params = omitBy(searchParams, isUndefined);
        getEquipmentList({
            region_id: treeId,
            page,
            page_size: ref.current ? ref.current.pageSize : commonPageSize,
            ...params,
        })
            .then(result => {
                setDataSource({ data: result.data, isLoading: false });
                setTotal(result.count);
            })
            .catch();
    };
    useEffect(() => {
        getList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams, treeId]);
    const getDetail = id => {
        equipmentDetail({ id })
            .then(result => {
                setCurrentEquipment(result.data);
                childRef.current.showModal();
            })
            .catch();
    };
    //新增设备
    const add = params => {
        if (params.multiple === undefined) {
            params.multiple = 1;
        }else{
            params.multiple = Number(params.multiple)
        }
        addEquipment(params)
            .then(result => {
                ToastHelper.successToast('新增成功');
                getList();
                formChildrenRef.current.resetFields();
                childRef.current.hiddenModal();
            })
            .catch(() => {
                childRef.current.endLoading();
            });
    };
    //编辑设备信息
    const edit = params => {
        if (params.gateway_id) {
            params.gateway_id = params.gateway_id[1] || params.gateway_id;
        }
        if (params.multiple === undefined || params.multiple == 0) {
            params.multiple = 1;
        }else{
            params.multiple = Number(params.multiple)
        }
        editEquipment({ ...params, id: currentEquipment.id })
            .then(result => {
                ToastHelper.successToast('编辑成功');
                getList();
                formChildrenRef.current.resetFields();
                childRef.current.hiddenModal();
            })
            .catch(() => {
                childRef.current.endLoading();
            });
    };
    //删除设备
    const remove = id => {
        removeEquipment(id)
            .then(() => {
                ToastHelper.successToast('删除成功');
                getList();
            })
            .catch();
    };
    // 设备列表导出
    const exportData = () => {
        const params = omitBy(searchParams, isEmpty);
        exportEquipment({ region_id: treeId, ...params })
            .then(result => {
                window.open(BASE_URL + result.data.url);
            })
            .catch();
    };
    // 设备列表导入
    const importFile = params => {
        importEquipment({ region_id: treeId, ...params })
            .then(() => {
                ToastHelper.successToast('导入成功');
                getList();
            })
            .catch(() => {
                ToastHelper.errorToast('请检查文件格式');
            });
    };
    //单个设备的数据导出
    const exprotDataItem = params => {
        const data = omitBy(params, isEmpty);
        data.start_time =
            params.time !== null && params.time[0] !== '' ? moment(params.time[0]).startOf('day').valueOf() : undefined;
        data.end_time =
            params.time !== null && params.time[1] !== '' ? moment(params.time[1]).endOf('day').valueOf() : undefined;
        dataExport({ id: currentEquipment.id, ...data })
            .then(result => {
                ToastHelper.successToast('导出成功');
                window.open(BASE_URL + result.url);
                // getList();
                formChildrenRef.current.resetFields();
                childRef.current.hiddenModal();
            })
            .catch(() => {
                childRef.current.endLoading();
            });
    };
    //设备更换
    const devicesUpdate = params => {
        const data = omitBy(params, isEmpty);
        updateDevice({ id: currentEquipment.id, ...data })
            .then(() => {
                ToastHelper.successToast('更换成功');
                getList();
                formChildrenRef.current.resetFields();
                childRef.current.hiddenModal();
            })
            .catch(() => {
                childRef.current.endLoading();
            });
    };
    //设备激活
    const activate = params => {
        message.loading('设备激活中，请耐心等待...', 0);
        editActivation({ activation_list: [params.id] })
            .then(result => {
                let success = result.data.success;
                let fail = result.data.fail;
                if (fail.length === 0) {
                    message.destroy();
                    ToastHelper.successToast('激活成功');
                } else if (success.length === 0) {
                    message.destroy();
                    ToastHelper.errorToast('激活失败');
                }
                getList();
            })
            .catch(() => {
                message.destroy();
                ToastHelper.errorToast('激活失败');
                // childRef.current.endLoading();
            });
    };
    useEffect(() => {
        getDeviceCategory({ get_all: true })
            .then(result => {
                setCategory(result.data);
            })
            .catch(error => {
                message.error(error);
            });
        getFunctionList()
            .then(result => {
                setFunctionList(result.data);
            })
            .catch();
    }, []);
    const columns = [
        {
            title: '序号',
            dataIndex: 'id',
            key: 'id',
            width: 100,
            render: (text, record, index) => `${index + 1}`,
        },
        {
            title: '设备名称',
            dataIndex: 'name',
            width: 200,
        },
        {
            title: '设备功能',
            dataIndex: 'function',
            width: 120,
            render: item => {
                if (isEmpty(item)) {
                    return '-';
                }
                return item.name;
            },
        },
        {
            title: '型号',
            dataIndex: 'category',
            width: 120,
            render: item => {
                if (isEmpty(item)) {
                    return '-';
                }
                return item.id;
            },
        },
        {
            title: '网关',
            dataIndex: 'gateway',
            width: 120,
            render: text => {
                if (isEmpty(text)) {
                    return '-';
                }
                return text.name;
            },
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 85,
            render: (value, record) => {
                //网关、多路控制器、多路互感器本身不可控制开与关，则用文字展示,其他用图标展示开和关（可控制）
                return (
                    <div>
                        {record.category.id === 'F200' ||
                        record.category.id === 'F300' ||
                        record.category.id === 'F400' ||
                        record.category.id === 'F500' ? (
                            value === '0' || value === '1' ? (
                                <Switch
                                    {...switchText}
                                    onClick={val => {
                                        changeSwitch({
                                            action: val === true ? 1 : 0,
                                            fid: record.function.id,
                                            devices: [record.id],
                                        })
                                            .then(result => {
                                                result = result.data;
                                                // ToastHelper.successToast(`${(result && result[0].status) === '1' ? '关闭' : '开启'}指令发送成功`);
                                                ToastHelper.successToast(
                                                    `${val === true ? '开启' : '关闭'}指令发送成功`,
                                                );
                                                getList();
                                            })
                                            .catch();
                                    }}
                                    checked={value === '1' || value === true ? true : false}
                                    size="small"
                                />
                            ) : (
                                <p>{status[value]}</p>
                            )
                        ) : (
                            <p>{status[value]}</p>
                        )}
                    </div>
                );
            },
        },
        {
            title: '上次采集时间',
            dataIndex: 'last_time',
            width: 200,
            render: text => <span>{text && text.slice(0, 10) + ' ' + text.slice(11, 19)}</span>,
        },
        {
            title: '操作',
            dataIndex: 'opera',
            width: 280,
            render: (_, record) => (
                <Space split={<Divider type="vertical" />}>
                    <Typography.Link className="opera">
                        <MyIcon
                            type={'icona-zu6928'}
                            style={{
                                fontSize: '1rem',
                                verticalAlign: 'middle',
                                color: status[record.status] === '未激活' ? 'rgba(0, 0, 0, 0.85)' : '#CCD1D6',
                            }}
                            title={'激活'}
                            onClick={() => {
                                // RouteHelper.locationTo('/web/monitor/device2/readDevice', { id: record.id });
                                // setModalType('activate');
                                // childRef.current.showModal(0);
                                // message
                                //     .loading('设备激活中，请耐心等待...')
                                //     .then(() => message.success('激活成功'))
                                //     .then(() => message.error('部分设备激活失败，请稍后重试', 2.5))
                                //     .then(() => message.error('激活失败，请稍后重试', 2.5));
                                status[record.status] === '未激活' && activate(record);
                            }}
                        />
                    </Typography.Link>
                    <Typography.Link className="opera">
                        <MyIcon
                            type={'iconchakan1'}
                            style={{ fontSize: '1rem', verticalAlign: 'middle', color: 'rgba(0, 0, 0, 0.85)' }}
                            title={'查看'}
                            onClick={() => {
                                RouteHelper.locationTo('/web/monitor/device2/readDevice', { id: record.id });
                                setModalType('read');
                            }}
                        />
                    </Typography.Link>
                    <Typography.Link className="opera">
                        <MyIcon
                            type={'icona-zu6914'}
                            style={{ fontSize: '1rem', verticalAlign: 'middle', color: 'rgba(0, 0, 0, 0.85)' }}
                            title={'编辑'}
                            onClick={() => {
                                setModalType('edit');
                                setCodeDisabled(true);
                                getDetail(record.id);
                            }}
                        />
                    </Typography.Link>
                    <Typography.Link className="opera">
                        <Popconfirm
                            title="删除该设备？"
                            okText="删除"
                            cancelText="取消"
                            onConfirm={() => {
                                remove(record.id);
                            }}
                        >
                            <MyIcon
                                type="iconshanchu1"
                                style={{ color: 'rgba(0, 0, 0, 0.85)', fontSize: '1rem', verticalAlign: 'middle' }}
                                title={'删除'}
                            />
                        </Popconfirm>
                    </Typography.Link>
                    {record.category.id === 'F200' ||
                    record.category.id === 'F300' ||
                    record.category.id === 'F400' ||
                    record.category.id === 'F500' ? (
                        <Typography.Link className="opera">
                            <MyIcon
                                type="icongenghuan"
                                style={{ color: 'rgba(0, 0, 0, 0.85)', fontSize: '1rem', verticalAlign: 'middle' }}
                                title={'更换'}
                                onClick={() => {
                                    setModalType('replace');
                                    setCurrentEquipment(record);
                                    childRef.current.showModal();
                                }}
                            />
                        </Typography.Link>
                    ) : (
                        <Typography.Link className="opera">
                            <MyIcon
                                type="icongenghuan"
                                style={{ color: '#CCD1D6', fontSize: '1rem', verticalAlign: 'middle', cursor: 'auto' }}
                                title={'更换'}
                                disabled
                            />
                        </Typography.Link>
                    )}
                    <Typography.Link className="opera">
                        <MyIcon
                            type="iconshujudaochu"
                            style={{ color: 'rgba(0, 0, 0, 0.85)', fontSize: '1rem', verticalAlign: 'middle' }}
                            title={'数据导出'}
                            onClick={() => {
                                setModalType('export');
                                setDeviceName(record.name);
                                setCurrentEquipment(record);
                                childRef.current.showModal();
                            }}
                        />
                    </Typography.Link>
                </Space>
            ),
        },
    ];
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    // 表格多选事件
    const onSelectChange = selectedRowKeys => {
        setSelectedRowKeys(selectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    /**
     * 全选
     * @param e
     */
    const onCheckAllChange = e => {
        setSelectedRowKeys(e.target.checked ? dataSource.data.map((item, index) => item.id) : []);
    };
    return (
        <div className="tableBox">
            <Row
                style={{
                    marginLeft: '0px',
                    marginRight: '0px',
                    display: 'flex',
                    flexWrap: 'nowrap',
                    justifyContent: 'space-between',
                    width: '100%',
                }}
            >
                <Col>
                    <Button
                        onClick={() => {
                            setModalType('add');
                            setCodeDisabled(false);
                            childRef.current.showModal();
                        }}
                        type="primary"
                        style={{
                            // marginRight: '10px',
                            marginBottom: '10px',
                            background: 'transparent',
                            color: '#fff',
                            borderColor: '#E77B5E',
                            textShadow: 'none',
                            boxShadow: 'none',
                            backgroundColor: '#E77B5E',
                        }}
                    >
                        新增
                    </Button>
                </Col>
                <Col>
                    <Upload
                        customRequest={file => {
                            importFile({ file: file.file });
                        }}
                        accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        showUploadList={false}
                    >
                        <Button
                            onClick={() => {}}
                            type="primary"
                            style={{
                                marginRight: '10px',
                                marginBottom: '10px',
                                background: 'transparent',
                                textShadow: 'none',
                                boxShadow: 'none',
                                color: '#fff',
                                backgroundColor: '#439BE3',
                                borderColor: '#439BE3',
                            }}
                        >
                            导入
                        </Button>
                    </Upload>
                </Col>
                <Col span={20}>
                    <SearchForm
                        category={category}
                        setSearchParams={setSearchParams}
                        functionList={functionList}
                        exportData={exportData}
                    />
                </Col>
            </Row>
            <Table
                rowKey={record => record.id}
                bordered
                loading={dataSource.isLoading}
                onChange={page => {
                    ref.current = page;
                    getList(page.current);
                }}
                pagination={{
                    defaultPageSize: commonPageSize,
                    total,
                    current,
                    pageSizeOptions: [10, 20, 30, 50, 100],
                    showTotal: total => `共 ${total} 条`,
                }}
                rowSelection={rowSelection}
                scroll={{ y: 'calc(100vh - 360px)' }}
                dataSource={dataSource.data}
                columns={columns}
            />
            <div
                style={
                    dataSource.data.length > 0
                        ? { display: 'block', position: 'inherit', marginTop: '2rem' }
                        : { display: 'none' }
                }
            >
                <Checkbox
                    indeterminate={
                        //负责样式控制
                        dataSource.data.length !== selectedRowKeys.length && selectedRowKeys.length !== 0
                    }
                    onChange={onCheckAllChange} //勾选时触发的方法
                    checked={dataSource.data.length === selectedRowKeys.length} //勾选还是未勾选  options：所有的勾选子项，list
                >
                    全选
                </Checkbox>

                <Radio.Group style={{ marginBottom: 16 }}>
                    <Radio.Button value="on">
                        <Popconfirm
                            title={
                                selectedRowKeys.length === 0 ? '未选中' : `确定要将${selectedRowKeys.length}项,激活吗?`
                            }
                            onConfirm={() => {
                                message.loading('设备激活中，请耐心等待...', 0);
                                editActivation({ activation_list: selectedRowKeys })
                                    .then(result => {
                                        let success = result.data.success;
                                        let fail = result.data.fail;
                                        if (fail.length === 0) {
                                            message.destroy();
                                            ToastHelper.successToast('批量激活成功');
                                        } else if (success.length === 0) {
                                            message.destroy();
                                            ToastHelper.errorToast('批量激活失败');
                                        } else if (selectedRowKeys.length !== success.length && fail.length !== 0) {
                                            message.destroy();
                                            ToastHelper.errorToast('部分设备激活失败，请稍后重试...');
                                        }
                                        setSelectedRowKeys([]);
                                        getList();
                                    })
                                    .catch(() => {
                                        message.destroy();
                                        ToastHelper.errorToast('批量激活失败');
                                    });
                            }}
                        >
                            激活{' '}
                        </Popconfirm>
                    </Radio.Button>
                </Radio.Group>
            </div>
            <CommonModal
                onOk={() => {
                    formChildrenRef.current
                        .validateFields()
                        .then(values => {
                            const max_tem = get(values, ['temperature', 'max'], undefined);
                            const min_tem = get(values, ['temperature', 'min'], undefined);
                            const max_hum = get(values, ['humidity', 'max'], undefined);
                            const min_hum = get(values, ['humidity', 'min'], undefined);
                            let channels = map(get(values, 'channels', []), (item, idx) => {
                                if (item) {
                                    return { ...item, idx };
                                }
                                return undefined;
                            });
                            let resultChannels = filter(channels, item => item);
                            if (resultChannels) {
                                resultChannels = map(resultChannels, item => {
                                    return { ...omit(item, 'idx'), qr_code: values.qr_code + `_${item.idx}` };
                                });
                            }
                            if (values.channels) {
                                values = {
                                    ...omit(values, ['temperature', 'humidity', 'channels']),
                                    channels: resultChannels,
                                    min_tem,
                                    max_tem,
                                    min_hum,
                                    max_hum,
                                };
                            } else {
                                values = {
                                    ...omit(values, ['temperature', 'humidity']),
                                    min_tem,
                                    max_tem,
                                    min_hum,
                                    max_hum,
                                };
                            }
                            if (modalType === 'add') {
                                add(values);
                            }
                            if (modalType === 'edit') {
                                edit(values);
                            }
                            if (modalType === 'replace') {
                                devicesUpdate(values);
                            }
                            if (modalType === 'export') {
                                exprotDataItem(values);
                            }
                            if (modalType === 'activate') {
                                // exprotDataItem(values);
                            }
                        })
                        .catch(() => {
                            childRef.current.endLoading();
                        });
                }}
                childRef={childRef}
                onCancel={() => {
                    formChildrenRef.current.resetFields();
                }}
                title={
                    modalType === 'export'
                        ? '数据导出'
                        : modalType === 'replace'
                        ? '设备更换'
                        : `${modalType === 'edit' ? '编辑' : '新增'}设备`
                }
            >
                <EquipmentForm
                    functionList={functionList}
                    setFunctionList={setFunctionList}
                    type={modalType}
                    currentEquipment={currentEquipment}
                    childRef={formChildrenRef}
                    category={category}
                    allTree={allTree}
                    treeId={treeId}
                    deviceName={deviceName}
                    setCodeDisabled={setCodeDisabled}
                    codeDisabled={codeDisabled}
                />
            </CommonModal>
        </div>
    );
};

const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 18,
    },
};
const Option = Select.Option;
const EquipmentForm = ({
    childRef,
    type,
    category,
    currentEquipment,
    functionList,
    allTree,
    treeId,
    deviceName,
    setFunctionList,
    setCodeDisabled,
    codeDisabled,
}) => {
    const [form] = Form.useForm();
    const [model, setModel] = useState('');
    const [equipment, setEquipment] = useState([]);
    // const gateway = useMemo(() => {
    //     return filter(category, item => {
    //         return startsWith(item.id, 'F0');
    //     });
    // }, category);
    let tree = allTree.length > 0 && allTree[0];
    useEffect(() => {
        getEquipmentList({ category_id: 'F0', region_id: tree.id })
            .then(result => {
                setEquipment(result.data);
            })
            .catch();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [treeId]);

    const formTemplate = useMemo(() => {
        return {
            qrCode: (
                <FormItem
                    label="设备条码"
                    name="qr_code"
                    rules={[{ required: true, message: '请输入设备条码' }]}
                    getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}
                >
                    <Input
                        placeholder="请输入设备条码"
                        disabled={type === 'edit' ? true : false}
                        onChange={debounce(e => {
                            const value = e.target.value;
                            qrCodeToEquipment({ qr_code: value })
                                .then(result => {
                                    result = result.data;
                                    const eqId = result.id.substr(0, 2);
                                    const eqNum = toNumber(result.id.substr(2));
                                    form.setFieldsValue({
                                        category_id: result.id,
                                        channels: map(new Array(eqNum), item => {}),
                                    });
                                    setModel(eqId);
                                })
                                .catch();
                        }, 1000)}
                    />
                </FormItem>
            ),
            model: (
                <FormItem label="型号" name="category_id" rules={[{ required: true, message: '请选择设备型号' }]}>
                    <Select disabled placeholder="请选择设备型号" virtual={false} showArrow>
                        {map(category, item => {
                            return (
                                <Option key={item.id} value={item.id}>
                                    {item.name}
                                </Option>
                            );
                        })}
                    </Select>
                </FormItem>
            ),
            name: (
                <FormItem
                    label="设备名称"
                    name="name"
                    rules={[
                        { required: true, message: '请输入设备名称' },
                        {
                            pattern: /^.{1,12}$/,
                            message: '设备名称长度为1-12位字符!',
                        },
                    ]}
                    getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}
                >
                    <Input placeholder="请输入设备名称" />
                </FormItem>
            ),
            point: (
                <FormItem label="监测点" name="region_id" rules={[{ required: true, message: '请选择监测点' }]}>
                    <TreeSelect
                        treeData={recursion(allTree)}
                        showCheckedStrategy={SHOW_PARENT}
                        dropdownStyle={{ maxHeight: 350, overflow: 'auto' }}
                        placeholder="请选择监测点"
                    />
                </FormItem>
            ),
            gateway: (
                <FormItem label="网关" name="gateway_id" rules={[{ required: true, message: '请选择网关' }]}>
                    <Select
                        placeholder="请选择网关"
                        virtual={false}
                        showArrow
                        onFocus={() => {
                            getEquipmentList({ category_id: 'F0', region_id: tree.id })
                                .then(result => {
                                    setEquipment(result.data);
                                })
                                .catch();
                        }}
                    >
                        {map(equipment, item => (
                            <Option key={item.id} value={item.id}>
                                {item.name}
                            </Option>
                        ))}
                    </Select>
                </FormItem>
            ),
            function: (
                <FormItem label="功能" name="function_id" rules={[{ required: true, message: '请选择功能' }]}>
                    <Select
                        placeholder="请选择设备功能"
                        virtual={false}
                        showArrow
                        onFocus={() => {
                            getFunctionList()
                                .then(result => {
                                    setFunctionList(result.data);
                                })
                                .catch();
                        }}
                    >
                        {map(functionList, item => {
                            return (
                                <Option key={item.id} value={item.id}>
                                    {item.name}
                                </Option>
                            );
                        })}
                    </Select>
                </FormItem>
            ),
            multiple: (
                <FormItem label="外接互感器">
                    <Form.Item
                        name="multiple"
                        noStyle
                        rules={[{ pattern: new RegExp(/^[1-9]\d*$/, 'g'), message: '只能输入数字并且为正整数' }]}
                    >
                        <Input min={1} max={99999} maxLength={5} style={{ width: '90%' }} />
                    </Form.Item>
                    <span className="ant-form-text" style={{ marginLeft: '0.5rem' }}>
                        倍
                    </span>
                </FormItem>
            ),
            temperature: (
                <FormItem label="温度阈值" rules={[{ required: false }]}>
                    <FormItem
                        name={['temperature', 'min']}
                        style={{ display: 'inline-block', width: 'calc(50% - 30px)', marginBottom: 0 }}
                    >
                        <Input placeholder="最小值" />
                    </FormItem>
                    <span style={{ display: 'inline-block', width: '60px', lineHeight: '32px', textAlign: 'center' }}>
                        ~
                    </span>
                    <FormItem
                        name={['temperature', 'max']}
                        style={{ display: 'inline-block', width: 'calc(50% - 30px)', marginBottom: 0 }}
                    >
                        <Input placeholder="最大值" />
                    </FormItem>
                </FormItem>
            ),
            humidity: (
                <FormItem label="湿度阈值" rules={[{ required: false }]}>
                    <FormItem
                        name={['humidity', 'min']}
                        style={{ display: 'inline-block', width: 'calc(50% - 30px)', marginBottom: 0 }}
                    >
                        <Input placeholder="最小值" />
                    </FormItem>
                    <span style={{ display: 'inline-block', width: '60px', lineHeight: '32px', textAlign: 'center' }}>
                        ~
                    </span>
                    <FormItem
                        name={['humidity', 'max']}
                        style={{ display: 'inline-block', width: 'calc(50% - 30px)', marginBottom: 0 }}
                    >
                        <Input placeholder="最大值" />
                    </FormItem>
                </FormItem>
            ),
            power: (
                <FormItem label="最大功率">
                    <FormItem
                        style={{ display: 'inline-block', width: '80%', marginBottom: 0 }}
                        name="max_p"
                        rules={[{ required: true, message: '请输入最大功率' }]}
                    >
                        <Input placeholder="请输入最大功率" />
                    </FormItem>
                    <span style={{ lineHeight: '34px', marginLeft: '10px' }}>W</span>
                </FormItem>
            ),
            multicenter: (
                <Form.List name="channels">
                    {fields => {
                        return (
                            <FormItem label="通道配置">
                                <div style={{ maxHeight: '20vh', overflow: 'auto' }}>
                                    <Collapse defaultActiveKey={[0]}>
                                        {fields.map((field, idx) => {
                                            return (
                                                <Panel header={`通道 ${idx + 1}`} key={idx}>
                                                    <Form.Item
                                                        labelCol={{ span: 6 }}
                                                        wrapperCol={{ span: 18 }}
                                                        label="监测点"
                                                        name={[field.name, 'region_id']}
                                                        // rules={[{ required: true, message: 'Missing sight' }]}
                                                    >
                                                        <TreeSelect
                                                            treeData={recursion(allTree)}
                                                            dropdownStyle={{ maxHeight: 350, overflow: 'auto' }}
                                                            placeholder="请选择监测点"
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        label="设备名称"
                                                        labelCol={{ span: 6 }}
                                                        wrapperCol={{ span: 18 }}
                                                        name={[field.name, 'name']}
                                                        // rules={[{ required: true, message: 'Missing sight' }]}
                                                        getValueFromEvent={event =>
                                                            event.target.value.replace(/\s+/g, '')
                                                        }
                                                    >
                                                        <Input placeholder="请输入设备名称" />
                                                    </Form.Item>
                                                    <Form.Item
                                                        label="功能"
                                                        labelCol={{ span: 6 }}
                                                        wrapperCol={{ span: 18 }}
                                                        name={[field.name, 'function_id']}
                                                        // rules={[{ required: true, message: 'Missing price' }]}
                                                    >
                                                        <Select placeholder="请选择设备功能" virtual={false} showArrow>
                                                            {map(functionList, item => {
                                                                return (
                                                                    <Option key={item.id} value={item.id}>
                                                                        {item.name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </Form.Item>
                                                </Panel>
                                            );
                                        })}
                                    </Collapse>
                                </div>
                            </FormItem>
                        );
                    }}
                </Form.List>
            ),
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [equipment, type]);

    useImperativeHandle(childRef, () => ({
        validateFields: form.validateFields,
        resetFields: () => {
            form.resetFields();
            setModel('');
        },
    }));
    useEffect(() => {
        if (type === 'add') {
            form.resetFields();
            setModel('');
            setCodeDisabled(false);
        }
        if (type === 'edit' && currentEquipment) {
            const {
                category,
                name,
                qr_code,
                region,
                max_tem,
                min_tem,
                min_hum,
                max_hum,
                max_p,
                multiple,
            } = currentEquipment;
            setModel(category.id.substr(0, 2));
            const formValue = {
                name,
                category_id: category.id,
                qr_code,
                // gateway_id: get(currentEquipment, ['gateway', 'name'], undefined),
                gateway_id:
                    currentEquipment.gateway === null
                        ? undefined
                        : [
                              (currentEquipment.gateway && currentEquipment.gateway.name) || undefined,
                              currentEquipment.gateway && currentEquipment.gateway.id,
                          ],
                region_id: region.id,
                function_id: get(currentEquipment, ['function', 'id'], undefined),
                temperature: { min: min_tem, max: max_tem },
                humidity: { min: min_hum, max: max_hum },
                power: max_p,
                multiple: multiple,
            };
            form.setFieldsValue(formValue);
            setCodeDisabled(true);
        }
        if (type === 'export' && deviceName) {
            // setModel('');
            form.setFieldsValue({
                equipmentName: deviceName || currentEquipment.name,
            });
        }
        if (type === 'replace') {
            setModel('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, currentEquipment]);
    return (
        <Form form={form} {...layout} initialValues={{ equipmentName: currentEquipment && currentEquipment.name }}>
            {type === 'export' ? (
                <>
                    <FormItem
                        name="equipmentName"
                        label="设备名称"
                        rules={[{ required: true, message: '请输入设备名称' }]}
                    >
                        <Input placeholder="请输入设备名称" />
                    </FormItem>
                    <FormItem
                        name="time"
                        label="日期"
                        help={<p style={{ fontSize: '0.2rem', marginTop: '0.2rem' }}>注：只能选择近三个月的数据</p>}
                        rules={[{ required: true, message: '请选择日期' }]}
                    >
                        <RangePicker disabledDate={disabledDate_3month} />
                    </FormItem>
                </>
            ) : type === 'replace' ? (
                <>
                    <FormItem
                        name="qr_code"
                        label="设备条码"
                        extra={
                            <p style={{ fontSize: '0.2rem', marginTop: '0.2rem' }}>
                                注：更换新设备后，原设备数据不会丢失
                            </p>
                        }
                        rules={[{ required: true, message: '请输入设备条码' }]}
                    >
                        <Input placeholder="请输入设备条码" />
                    </FormItem>
                </>
            ) : (
                <>
                    {projectEquipment[model]
                        ? map(projectEquipment[model], (item, idx) => {
                              //TODO: 判断FE01不需要网关，FE00需要配置网关的问题
                              return <React.Fragment key={idx}>{form.getFieldValue('qr_code').substr(0, 4) === 'FE01' && item !=='' ? formTemplate[item !=='gateway' && item] : formTemplate[item]}</React.Fragment>;
                          })
                        : formTemplate.qrCode}
                </>
            )}
        </Form>
    );
};
const searchLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const SearchForm = ({ category, functionList, setSearchParams, exportData }) => {
    return (
        <Form
            layout="horizontal"
            {...searchLayout}
            onFinish={values => {
                setSearchParams(values);
            }}
        >
            <Row style={{ display: 'flex', flexWrap: 'nowrap', justifyContent: 'space-between', width: '100%' }}>
                <Col span={5}>
                    <FormItem
                        name="name"
                        label="名称"
                        getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}
                    >
                        <Input placeholder="请输入设备名称" />
                    </FormItem>
                </Col>
                <Col span={5}>
                    <FormItem name="status" label="状态">
                        <Select placeholder="请选择" allowClear={true} virtual={false} showArrow>
                            {map(status, (value, key) => {
                                return (
                                    <Option value={key} key={key}>
                                        {value}
                                    </Option>
                                );
                            })}
                        </Select>
                    </FormItem>
                </Col>
                <Col span={5}>
                    <FormItem name="category_id" label="型号">
                        <Select placeholder="请选择" allowClear={true} virtual={false} showArrow>
                            {map(category, item => {
                                return (
                                    <Option value={item.id} key={item.id}>
                                        {item.id}
                                    </Option>
                                );
                            })}
                        </Select>
                    </FormItem>
                </Col>
                <Col span={5}>
                    <FormItem name="function_id" label="功能">
                        <Select placeholder="请选择" allowClear={true} virtual={false} showArrow>
                            {map(functionList, item => {
                                return (
                                    <Option value={item.id} key={item.id}>
                                        {item.name}
                                    </Option>
                                );
                            })}
                        </Select>
                    </FormItem>
                </Col>
                <Col style={{ margin: '0 1%' }}>
                    <FormItem>
                        <Button type="primary" htmlType="submit">
                            搜索
                        </Button>
                    </FormItem>
                </Col>
                <Col style={{ textAlign: 'right' }}>
                    <Button
                        onClick={exportData}
                        type="primary"
                        style={{
                            // marginRight: '10px',
                            marginBottom: '10px',
                            background: 'transparent',
                            textShadow: 'none',
                            boxShadow: 'none',
                            color: '#fff',
                            backgroundColor: '#439BE3',
                            borderColor: '#439BE3',
                        }}
                    >
                        导出
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

const PointForm = ({ childRef, type, nodeInfo, allTree }) => {
    const [form] = Form.useForm();
    useImperativeHandle(childRef, () => ({
        validateFields: form.validateFields,
    }));
    useEffect(() => {
        getRisk()
            .then(result => {
                setRisk(result.data); //后端返回格式：[[0, "高风险"], [1, "中风险"], [2, "低风险"]]
            })
            .catch();
    }, []);
    useEffect(() => {
        if (nodeInfo) {
            if (type === 'edit') {
                form.setFieldsValue({
                    name: nodeInfo.name,
                    fatherPointer: nodeInfo.pid,
                    content: nodeInfo.location,
                    lnglat: nodeInfo.longitude && nodeInfo.latitude && `${nodeInfo.longitude},${nodeInfo.latitude}`,
                    risk: [nodeInfo.risk],
                    address: nodeInfo.address /*address: regeoCode(`${nodeInfo.longitude},`+`${nodeInfo.latitude}`)*/,
                });
                return;
            }
            // form.setFieldsValue({ fatherPointer: nodeInfo.name, content: '', name: '' });//解决禅道id=694 取消默认选中的父节点
            form.setFieldsValue({
                fatherPointer: null,
                content: null,
                name: null,
                lnglat: null,
                risk: null,
                address: null,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nodeInfo, type]);
    var geocoder = new window.AMap.Geocoder({
        //city: "010", //城市设为北京，默认：“全国”
    });
    var marker = new window.AMap.Marker();
    //地址查询经纬度
    const geoCode = address => {
        geocoder.getLocation(address, (status, result) => {
            if (status === 'complete' && result.info === 'OK') {
                var lnglat = result.geocodes[0].location;
                marker.setPosition(lnglat);
                form.setFieldsValue({ lnglat: lnglat });
            } else if (address === '' || address === null) {
                // message.error('监测点地址为空');
                // form.setFieldsValue({ lnglat: null,address: null });
            } else {
                message.error('根据地址查询位置失败');
                // form.setFieldsValue({ lnglat: null,address: null });
            }
        });
    };
    const [riskLevel, setRisk] = useState();
    return (
        <Form form={form} {...layout} initialValues={{}}>
            <FormItem
                label="名称"
                name="name"
                rules={[
                    { required: true, message: '请输入监测点名称' },
                    {
                        pattern: /^.{1,32}$/,
                        message: '名称长度为1-32位字符!',
                    },
                ]}
                getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}
            >
                <Input />
            </FormItem>
            <FormItem label="父节点" name="fatherPointer" rules={[{ required: true, message: '请选择父节点' }]}>
                <TreeSelect
                    treeData={recursion(allTree)}
                    showCheckedStrategy={SHOW_PARENT}
                    dropdownStyle={{ maxHeight: 500, overflow: 'auto' }}
                    placeholder="请选择父节点"
                />
            </FormItem>
            <FormItem
                label="监测点地址"
                name="address"
                getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}
                // normalize={(value, prevValue, prevValues) => {
                //     return geoCode(value);
                // }}
            >
                <Input
                    placeholder="请填写监测点地址"
                    onBlur={e => {
                        geoCode(e.target.value);
                    }}
                    onPressEnter={e => {
                        geoCode(e.target.value);
                    }}
                />
            </FormItem>
            <FormItem label="经纬度" name="lnglat">
                <Input disabled placeholder="根据监测点地址解析经纬度" />
            </FormItem>
            <FormItem label="风险等级" name="risk">
                <Select
                    placeholder="请选择风险等级"
                    virtual={false}
                    showArrow
                    onFocus={() => {
                        getRisk()
                            .then(result => {
                                setRisk(result.data); //后端返回格式：[[0, "高风险"], [1, "中风险"], [2, "低风险"]]
                            })
                            .catch();
                    }}
                >
                    {map(riskLevel, (value, key) => {
                        return (
                            <Option value={value[0]} key={key}>
                                {value[1]}
                            </Option>
                        );
                    })}
                </Select>
            </FormItem>
            <FormItem label="描述" name="content">
                <Input.TextArea />
            </FormItem>
        </Form>
    );
};
