import React, { useEffect, useState, useRef, useMemo } from 'react';
import moment from 'moment';
import { BreadcrumbCustom } from '../../../components/BreadcrumbCustom';
import { Button, Col, Form, Row, Table, Select, DatePicker, Upload } from 'antd';
import { CommonTree } from '../../../components/CommonTreeRegion';
import { omitBy, isUndefined, isNull, map } from 'lodash';
import { BASE_URL } from '../../../global';
import { getAlarmTree, getRegionReport, getFunctionList } from '../../../api/webServe';
import { peakvalue } from '../../../util/function-util';
import '../../../style/index.less';
import lessStyle from './eCRCondition.module.less';

const FormItem = Form.Item;
const Option = Select.Option;
// const { RangePicker } = DatePicker;
/**
 * 能耗分析 - 能耗报表
 * @returns
 */
export const ECRReport = () => {
    return (
        <div className={'container clearfix ' + lessStyle.containers}>
            <BreadcrumbCustom paths={['首页', '能耗分析', '能耗报表']} className="rumbCustom" />
            <div className="row clearfix" style={{ backgroundColor: 'transparent', margin: '1rem', padding: '0' }}>
                <Report />
            </div>
        </div>
    );
};

const Report = () => {
    const [treeData, setTreeData] = useState([]);
    const [areaInfo, setAreaInfo] = useState();
    useEffect(() => {
        getAlarmTree()
            .then(result => {
                setTreeData(result.data);
            })
            .catch();
    }, []);
    return (
        <Row style={{ marginLeft: '0rem', width: '100%', height: '60vh' }} gutter={32}>
            <Col
                span={4}
                className="gutter-row"
                style={{ padding: '1rem', background: '#ffffff', borderRadius: '10px', height: '84vh' }}
            >
                <CommonTree
                    treeData={treeData}
                    operable={false}
                    onClick={info => {
                        if (!areaInfo || areaInfo.id !== info.id) {
                            setAreaInfo(info);
                        }
                    }}
                    multiples={true}
                />
            </Col>
            <Col span={20} style={{ paddingRight: '0rem' }}>
                <ReportTable areaInfo={areaInfo} />
            </Col>
        </Row>
    );
};

const ReportTable = ({ areaInfo }) => {
    const [form] = Form.useForm();
    const [modalType] = useState('add'); //edit add assign
    const [data, setData] = useState({ data: [], isLoading: true }); //隐患列表数据
    const [dataEnd, setDataEnd] = useState({ data: [], isLoading: true }); //隐患列表数据
    const [count, setCount] = useState(0); //数据总条数
    const [page_size, setPageSize] = useState(10); //当前页显示条数
    const [currentPage, setCurrentPage] = useState(1);
    const ref = useRef(null);
    const [time, setTime] = useState(null); //搜索时间字段
    const [searchParams, setSearchParams] = useState({}); //搜索
    const [functionList, setFunctionList] = useState([]);
    const [orderWay, setOrderWay] = useState();
    const [order_index, setOrderIndex] = useState();
    // 获取产品列表数据
    const userData = (page = 1, pageSize) => {
        setCurrentPage(page);
        searchParams.start_time =
            // time !== null && time[0] !== '' ? moment(time[0]).startOf('day').valueOf() : undefined;
            time !== null && time !== '' ? moment(time).startOf('day').valueOf() : undefined;
        // searchParams.end_time = time !== null && time[1] !== '' ? moment(time[1]).endOf('day').valueOf() : undefined;
        searchParams.order = orderWay !== null && orderWay !== undefined ? orderWay : undefined;
        searchParams.order_index = order_index !== null && order_index !== undefined ? order_index : undefined;
        const params = omitBy(searchParams, value => {
            if (isNaN(value) || value === '') {
                return isUndefined(value) || isNull(value) || value === '';
            } else {
                return isNull(value);
            }
        });
        setDataEnd({ data: [], isLoading: true });
        setData({ data: [], isLoading: true });
        if (!isUndefined(areaInfo)) {
            getRegionReport({ id: areaInfo.id, page, page_size: pageSize ? pageSize : page_size, ...params })
                .then(result => {
                    setDataEnd({
                        data: result.data && result.data.slice(result.data.length - 1, result.data.length),
                        isLoading: false,
                    });
                    setData({ data: result.data && result.data.slice(0, result.data.length - 1), isLoading: false });
                    setCount(result.count);
                })
                .catch();
        }
    };
    useEffect(() => {
        getFunctionList({ get_all: true })
            .then(result => {
                setFunctionList(result.data);
            })
            .catch();
    }, []);
    useEffect(() => {
        userData();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [areaInfo, modalType, searchParams, orderWay, order_index]);
    //导入
    const importFile = params => {
        // importArea({ ...params })
        //     .then(() => {
        //         ToastHelper.successToast('导入成功');
        //     })
        //     .catch(() => {
        //         ToastHelper.errorToast('请检查文件格式');
        //     });
    };
    //导出
    const exportReports = page_size => {
        searchParams.start_time =
            // time !== null && time[0] !== '' ? moment(time[0]).startOf('day').valueOf() : undefined;
            time !== null && time !== '' ? moment(time).startOf('day').valueOf() : undefined;
        // searchParams.end_time =
        //     time !== null && time[1] !== '' ? moment(time[1]).endOf('day').valueOf() : undefined;
        const params = omitBy(searchParams, value => {
            if (isNaN(value) || value === '') {
                return isUndefined(value) || isNull(value) || value === '';
            } else {
                return isNull(value);
            }
        });
        getRegionReport({ id: areaInfo.id, currentPage, page_size, ...params, download: true })
            .then(result => {
                window.open(BASE_URL + result.url);
            })
            .catch();
    };
    const onChange = (value, dateString) => {
        setTime(dateString);
    };
    const renderForm = () => {
        return (
            <Form
                layout="horizontal"
                form={form}
                onFinish={value => {
                    setSearchParams(value);
                }}
            >
                <Row gutter={16}>
                    <Col span={6} offset={10}>
                        <FormItem
                            name="function_id"
                            label="设备分类"
                            rules={[{ required: false, message: '请选择设备分类' }]}
                        >
                            <Select placeholder="请选择" allowClear={true} virtual={false}>
                                {functionList.map(item => {
                                    return (
                                        <Option value={item.id} key={item.id}>
                                            {item.name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </FormItem>
                    </Col>
                    <Col span={4}>
                        {' '}
                        <FormItem label="日期" name="start_time" rules={[{ required: false, message: '请选择日期' }]}>
                            {/* <RangePicker format="yyyy-MM-DD" onChange={onChange} /> */}
                            <DatePicker onChange={onChange} format="yyyy-MM-DD" />
                        </FormItem>
                    </Col>
                    <Col span={2} style={{ textAlign: 'right' }}>
                        <FormItem>
                            <Button type="primary" htmlType="submit">
                                搜索
                            </Button>
                        </FormItem>
                    </Col>
                    <Col span={2} style={{ textAlign: 'right' }}>
                        <FormItem>
                            <Button
                                type="default"
                                style={{
                                    color: '#fff',
                                    backgroundColor: '#439BE3',
                                    borderColor: '#439BE3',
                                }}
                                onClick={exportReports}
                            >
                                导出
                            </Button>
                        </FormItem>
                    </Col>
                </Row>
            </Form>
        );
    };
    const handleTableChange = (pagination, filters, sorter) => {
        //第三个参数 就是排序信息
        if (sorter.order === 'descend') {
            //降序
            // values.orderWay = "desc";
            setOrderWay(0);
        }
        if (sorter.order === 'ascend') {
            //升序
            setOrderWay(1);
            //orderBy和orderWay就是传给后台的排序参数
            //orderBy是表格选中的列的表头属性名
            // values.orderWay = "asc";
        }
        // values.orderBy = sorter.field;
        setOrderIndex(
            sorter.column &&
                (sorter.column.title.slice(0, 1) === '0'
                    ? sorter.column.title.slice(1, 2)
                    : sorter.column.title.slice(0, 2)),
        );
    };
    const columns = useMemo(() => {
        return [
            {
                title: '序号',
                dataIndex: 'id',
                key: 'id',
                width: 90, //宽度
                fixed: 'left',
                render: (text, record, index) => `${index + 1}`,
            },
            {
                title: '名称',
                dataIndex: 'name',
                fixed: 'left',
                render: text => (
                    <p
                        style={{
                            maxWidth: '8rem',
                            minWidth: '4rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={text}
                    >
                        {text}
                    </p>
                ),
            },
            {
                title: '类型',
                dataIndex: 'category',
                fixed: 'left',
                render: text => (
                    <p
                        style={{
                            maxWidth: '6rem',
                            minWidth: '3rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={text}
                    >
                        {text}
                    </p>
                ),
            },
            {
                key: '0',
                title: '00时',
                dataIndex: 'data',
                render: text => (
                    <p
                        style={{
                            maxWidth: '8rem',
                            minWidth: '6rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={
                            text[0] &&
                            (text[0].amount === null || text[0].amount === 0 ? '' : peakvalue(text[0].amount))
                        }
                    >
                        {text[0] && (text[0].amount === null || text[0].amount === 0 ? '' : peakvalue(text[0].amount))}
                    </p>
                ),
                // sorter: (a, b) => a.data[0].amount - b.data[0].amount,
                sorter: true,
            },
            {
                key: '1',
                title: '01时',
                dataIndex: 'data',
                render: text => (
                    <p
                        style={{
                            maxWidth: '8rem',
                            minWidth: '6rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={
                            text[1] &&
                            (text[1].amount === null || text[1].amount === 0 ? '' : peakvalue(text[1].amount))
                        }
                    >
                        {text[1] && (text[1].amount === null || text[1].amount === 0 ? '' : peakvalue(text[1].amount))}
                    </p>
                ),
                // sorter: (a, b) => a.data[1].amount - b.data[1].amount,
                sorter: true,
            },
            {
                key: '2',
                title: '02时',
                dataIndex: 'data',
                render: text => (
                    <p
                        style={{
                            maxWidth: '8rem',
                            minWidth: '6rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={
                            text[2] &&
                            (text[2].amount === null || text[2].amount === 0 ? '' : peakvalue(text[2].amount))
                        }
                    >
                        {text[2] && (text[2].amount === null || text[2].amount === 0 ? '' : peakvalue(text[2].amount))}
                    </p>
                ),
                sorter: true,
            },
            {
                key: '3',
                title: '03时',
                dataIndex: 'data',
                render: text => (
                    <p
                        style={{
                            maxWidth: '8rem',
                            minWidth: '6rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={
                            text[3] &&
                            (text[3].amount === null || text[3].amount === 0 ? '' : peakvalue(text[3].amount))
                        }
                    >
                        {text[3] && (text[3].amount === null || text[3].amount === 0 ? '' : peakvalue(text[3].amount))}
                    </p>
                ),
                // sorter: (a, b) => a.data[3].amount - b.data[3].amount,
                sorter: true,
            },
            {
                key: '4',
                title: '04时',
                dataIndex: 'data',
                render: text => (
                    <p
                        style={{
                            maxWidth: '8rem',
                            minWidth: '6rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={
                            text[4] &&
                            (text[4].amount === null || text[4].amount === 0 ? '' : peakvalue(text[4].amount))
                        }
                    >
                        {text[4] && (text[4].amount === null || text[4].amount === 0 ? '' : peakvalue(text[4].amount))}
                    </p>
                ),
                // sorter: (a, b) => a.data[4].amount - b.data[4].amount,
                sorter: true,
            },
            {
                key: '5',
                title: '05时',
                dataIndex: 'data',
                render: text => (
                    <p
                        style={{
                            maxWidth: '8rem',
                            minWidth: '6rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={
                            text[5] &&
                            (text[5].amount === null || text[5].amount === 0 ? '' : peakvalue(text[5].amount))
                        }
                    >
                        {text[5] && (text[5].amount === null || text[5].amount === 0 ? '' : peakvalue(text[5].amount))}
                    </p>
                ),
                // sorter: (a, b) => a.data[5].amount - b.data[5].amount,
                sorter: true,
            },
            {
                key: '6',
                title: '06时',
                dataIndex: 'data',
                render: text => (
                    <p
                        style={{
                            maxWidth: '8rem',
                            minWidth: '6rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={
                            text[6] &&
                            (text[6].amount === null || text[6].amount === 0 ? '' : peakvalue(text[6].amount))
                        }
                    >
                        {text[6] && (text[6].amount === null || text[6].amount === 0 ? '' : peakvalue(text[6].amount))}
                    </p>
                ),
                // sorter: (a, b) => a.data[6].amount - b.data[6].amount,
                sorter: true,
            },
            {
                key: '7',
                title: '07时',
                dataIndex: 'data',
                render: text => (
                    <p
                        style={{
                            maxWidth: '8rem',
                            minWidth: '6rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={
                            text[7] &&
                            (text[7].amount === null || text[7].amount === 0 ? '' : peakvalue(text[7].amount))
                        }
                    >
                        {text[7] && (text[7].amount === null || text[7].amount === 0 ? '' : peakvalue(text[7].amount))}
                    </p>
                ),
                // sorter: (a, b) => a.data[7].amount - b.data[7].amount,
                sorter: true,
            },
            {
                key: '8',
                title: '08时',
                dataIndex: 'data',
                render: text => (
                    <p
                        style={{
                            maxWidth: '8rem',
                            minWidth: '6rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={
                            text[8] &&
                            (text[8].amount === null || text[8].amount === 0 ? '' : peakvalue(text[8].amount))
                        }
                    >
                        {text[8] && (text[8].amount === null || text[8].amount === 0 ? '' : peakvalue(text[8].amount))}
                    </p>
                ),
                // sorter: (a, b) => a.data[8].amount - b.data[8].amount,
                sorter: true,
            },
            {
                key: '9',
                title: '09时',
                dataIndex: 'data',
                render: text => (
                    <p
                        style={{
                            maxWidth: '8rem',
                            minWidth: '6rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={
                            text[9] &&
                            (text[9].amount === null || text[9].amount === 0 ? '' : peakvalue(text[9].amount))
                        }
                    >
                        {text[9] && (text[9].amount === null || text[9].amount === 0 ? '' : peakvalue(text[9].amount))}
                    </p>
                ),
                // sorter: (a, b) => a.data[9].amount - b.data[9].amount,
                sorter: true,
            },
            {
                key: '10',
                title: '10时',
                dataIndex: 'data',
                render: text => (
                    <p
                        style={{
                            maxWidth: '8rem',
                            minWidth: '6rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={
                            text[10] &&
                            (text[10].amount === null || text[10].amount === 0 ? '' : peakvalue(text[10].amount))
                        }
                    >
                        {text[10] &&
                            (text[10].amount === null || text[10].amount === 0 ? '' : peakvalue(text[10].amount))}
                    </p>
                ),
                // sorter: (a, b) => a.data[10].amount - b.data[10].amount,
                sorter: true,
            },
            {
                key: '11',
                title: '11时',
                dataIndex: 'data',
                render: text => (
                    <p
                        style={{
                            maxWidth: '8rem',
                            minWidth: '6rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={
                            text[11] &&
                            (text[11].amount === null || text[11].amount === 0 ? '' : peakvalue(text[11].amount))
                        }
                    >
                        {text[11] &&
                            (text[11].amount === null || text[11].amount === 0 ? '' : peakvalue(text[11].amount))}
                    </p>
                ),
                // sorter: (a, b) => a.data[11].amount - b.data[11].amount,
                sorter: true,
            },
            {
                key: '12',
                title: '12时',
                dataIndex: 'data',
                render: text => (
                    <p
                        style={{
                            maxWidth: '8rem',
                            minWidth: '6rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={
                            text[12] &&
                            (text[12].amount === null || text[12].amount === 0 ? '' : peakvalue(text[12].amount))
                        }
                    >
                        {text[12] &&
                            (text[12].amount === null || text[12].amount === 0 ? '' : peakvalue(text[12].amount))}
                    </p>
                ),
                sorter: true,
            },
            {
                key: '13',
                title: '13时',
                dataIndex: 'data',
                render: text => (
                    <p
                        style={{
                            maxWidth: '8rem',
                            minWidth: '6rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={
                            text[13] &&
                            (text[13].amount === null || text[13].amount === 0 ? '' : peakvalue(text[13].amount))
                        }
                    >
                        {text[13] &&
                            (text[13].amount === null || text[13].amount === 0 ? '' : peakvalue(text[13].amount))}
                    </p>
                ),
                sorter: true,
            },
            {
                key: '14',
                title: '14时',
                dataIndex: 'data',
                render: text => (
                    <p
                        style={{
                            maxWidth: '8rem',
                            minWidth: '6rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={
                            text[14] &&
                            (text[14].amount === null || text[14].amount === 0 ? '' : peakvalue(text[14].amount))
                        }
                    >
                        {text[14] &&
                            (text[14].amount === null || text[14].amount === 0 ? '' : peakvalue(text[14].amount))}
                    </p>
                ),
                sorter: true,
            },
            {
                key: '15',
                title: '15时',
                dataIndex: 'data',
                render: text => (
                    <p
                        style={{
                            maxWidth: '8rem',
                            minWidth: '6rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={
                            text[15] &&
                            (text[15].amount === null || text[15].amount === 0 ? '' : peakvalue(text[15].amount))
                        }
                    >
                        {text[15] &&
                            (text[15].amount === null || text[15].amount === 0 ? '' : peakvalue(text[15].amount))}
                    </p>
                ),
                sorter: true,
            },
            {
                key: '16',
                title: '16时',
                dataIndex: 'data',
                render: text => (
                    <p
                        style={{
                            maxWidth: '8rem',
                            minWidth: '6rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={
                            text[16] &&
                            (text[16].amount === null || text[16].amount === 0 ? '' : peakvalue(text[16].amount))
                        }
                    >
                        {text[16] &&
                            (text[16].amount === null || text[16].amount === 0 ? '' : peakvalue(text[16].amount))}
                    </p>
                ),
                sorter: true,
            },
            {
                key: '17',
                title: '17时',
                dataIndex: 'data',
                render: text => (
                    <p
                        style={{
                            maxWidth: '8rem',
                            minWidth: '6rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={
                            text[17] &&
                            (text[17].amount === null || text[17].amount === 0 ? '' : peakvalue(text[17].amount))
                        }
                    >
                        {text[17] &&
                            (text[17].amount === null || text[17].amount === 0 ? '' : peakvalue(text[17].amount))}
                    </p>
                ),
                sorter: true,
            },
            {
                key: '18',
                title: '18时',
                dataIndex: 'data',
                render: text => (
                    <p
                        style={{
                            maxWidth: '8rem',
                            minWidth: '6rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={
                            text[18] &&
                            (text[18].amount === null || text[18].amount === 0 ? '' : peakvalue(text[18].amount))
                        }
                    >
                        {text[18] &&
                            (text[18].amount === null || text[18].amount === 0 ? '' : peakvalue(text[18].amount))}
                    </p>
                ),
                sorter: true,
            },
            {
                key: '19',
                title: '19时',
                dataIndex: 'data',
                render: text => (
                    <p
                        style={{
                            maxWidth: '8rem',
                            minWidth: '6rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={
                            text[19] &&
                            (text[19].amount === null || text[19].amount === 0 ? '' : peakvalue(text[19].amount))
                        }
                    >
                        {text[19] &&
                            (text[19].amount === null || text[19].amount === 0 ? '' : peakvalue(text[19].amount))}
                    </p>
                ),
                sorter: true,
            },
            {
                key: '20',
                title: '20时',
                dataIndex: 'data',
                render: text => (
                    <p
                        style={{
                            maxWidth: '8rem',
                            minWidth: '6rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={
                            text[20] &&
                            (text[20].amount === null || text[20].amount === 0 ? '' : peakvalue(text[20].amount))
                        }
                    >
                        {text[20] &&
                            (text[20].amount === null || text[20].amount === 0 ? '' : peakvalue(text[20].amount))}
                    </p>
                ),
                sorter: true,
            },
            {
                key: '21',
                title: '21时',
                dataIndex: 'data',
                render: text => (
                    <p
                        style={{
                            maxWidth: '8rem',
                            minWidth: '6rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={
                            text[21] &&
                            (text[21].amount === null || text[21].amount === 0 ? '' : peakvalue(text[21].amount))
                        }
                    >
                        {text[21] &&
                            (text[21].amount === null || text[21].amount === 0 ? '' : peakvalue(text[21].amount))}
                    </p>
                ),
                sorter: true,
            },
            {
                key: '22',
                title: '22时',
                dataIndex: 'data',
                render: text => (
                    <p
                        style={{
                            maxWidth: '8rem',
                            minWidth: '6rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={
                            text[22] &&
                            (text[22].amount === null || text[22].amount === 0 ? '' : peakvalue(text[22].amount))
                        }
                    >
                        {text[22] &&
                            (text[22].amount === null || text[22].amount === 0 ? '' : peakvalue(text[22].amount))}
                    </p>
                ),
                sorter: true,
            },
            {
                key: '23',
                title: '23时',
                dataIndex: 'data',
                render: text => (
                    <p
                        style={{
                            maxWidth: '8rem',
                            minWidth: '6rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={
                            text[23] &&
                            (text[23].amount === null || text[23].amount === 0 ? '' : peakvalue(text[23].amount))
                        }
                    >
                        {text[23] &&
                            (text[23].amount === null || text[23].amount === 0 ? '' : peakvalue(text[23].amount))}
                    </p>
                ),
                sorter: true,
            },
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);
    /**
     *  表格分页
     */
    const pagination = {
        pageSize: page_size, //每页的条数
        current: currentPage, //当前页
        total: count, //总数据
        showSizeChanger: true,
        onChange: (page, pageSize) => {
            ref.current = page;
            userData(page, pageSize);
        }, //点击分页号时触发的方法
        onShowSizeChange: (current, pageSize) => {
            setPageSize(pageSize);
        }, //pageSize 变化的回调
        hideOnSinglePage: true, //为true则数据条数不满或刚好一页，则隐藏分页器
        pageSizeOptions: ['10', '15', '20', '30', '50', '100'], // 分页类别
        showTotal: total => `共 ${total} 条`,
    };
    return (
        <div
            className="tableBox"
            style={{
                width: '100%',
                height: '100%',
                backgroundColor: '#fff',
                borderRadius: '10px',
                padding: '1rem',
                boxSizing: 'border-box',
            }}
        >
            {/* 导入按钮功能 */}
            <Row gutter={16}>
                <Col span={2}>
                    <Upload
                        customRequest={file => {
                            importFile({ file: file.file });
                        }}
                        accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        showUploadList={false}
                    >
                        {/* <Button
                            onClick={() => {}}
                            type="primary"
                            style={{
                                marginRight: '10px',
                                marginBottom: '10px',
                                background: 'transparent',
                                color: '#64D399',
                                textShadow: 'none',
                                boxShadow: 'none',
                            }}
                        >
                            导入 //TODO: 目前导入报表功能未实现，需求待定
                        </Button> */}
                    </Upload>
                </Col>
                <Col span={22} style={{ marginBottom: '-0.5rem' }}>
                    {renderForm()}
                </Col>
            </Row>
            {/* 表格 */}
            <Table
                rowKey={record => record.name}
                columns={columns}
                dataSource={data.data}
                bordered={true}
                loading={data.isLoading}
                // scroll={{ x: '100%' }}
                scroll={{ x: 3500 }}
                pagination={pagination}
                locale={{
                    cancelSort: '点击取消排序',
                    triggerAsc: '点击升序',
                    triggerDesc: '点击降序',
                    filterConfirm: '确定',
                    filterReset: '重置',
                    emptyText: '暂无数据',
                }}
                className="styleTableReportData"
                onChange={handleTableChange} //排序
                summary={() => (
                    <>
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={27}></Table.Summary.Cell>
                        </Table.Summary.Row>
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={3}>
                                {dataEnd.data.length > 0 && dataEnd.data[0].name}
                            </Table.Summary.Cell>
                            {dataEnd.data.length > 0 &&
                                map(dataEnd.data[0].data, (item, index) => {
                                    return (
                                        <Table.Summary.Cell index={index + 3} key={index}>
                                            {item.amount === null || item.amount === 0 ? '' : peakvalue(item.amount)}
                                        </Table.Summary.Cell>
                                    );
                                })}
                        </Table.Summary.Row>
                    </>
                )}
            />
        </div>
    );
};
