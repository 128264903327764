import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import { Row, Col, Checkbox, Form, Divider, Button, Select, Input, message } from 'antd';
import { isEmpty } from 'lodash';
import { CommonTreeSwitch } from '../../../components/CommonTreeSwitch';
import { CommonModal } from '../../../components/CommoModal';
import { MyIcon } from '../../../util/iconfont';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { EmptyView } from '../../../components/EmptyView';
import {
    getAreaList,
    changeSwitch,
    getEquipmentList,
    getPreControl,
    addPreControl,
    getPreControlDetail,
    delPreControlDetail,
    getFunctionList,
} from '../../../api/webServe';
import switchStyle from './onBtnSwitch.module.less';

const FormItem = Form.Item;
const CheckboxGroup = Checkbox.Group;
const { Option } = Select;

const OneBtnSwitch = ({ type }) => {
    const childRef = useRef();
    const formChildrenRef = useRef();
    const [modalType, setModalType] = useState('add'); //edit add assign

    //region 获取区域树列表
    /**
     * origin left tree-panel data
     * 原点左树面板数据
     */
    const [areaTreeData, setAreaTreeData] = useState([]);
    /**
     * 照明功能id
     */
    const [lightFunctionId, setLightFunctionId] = useState(undefined);
    useEffect(() => {
        getFunctionList({ get_all: true })
            .then(result => {
                result.data.forEach(item => {
                    if (item.name === '照明') {
                        setLightFunctionId(item.id);
                    }
                });
            })
            .catch();
    }, []);
    /**
     * when first launch , request area list
     * 首次启动时，请求区域列表
     */
    useEffect(() => {
        if (lightFunctionId !== undefined) {
            getAreaList({ fids: lightFunctionId }) //获取区域列表
                .then(result => {
                    setAreaTreeData(result.data);
                })
                .catch();
        }
    }, [lightFunctionId]);
    //endregion

    //region 选中区域以及区域中的设备
    /**
     * left panel selected area data
     * 左面板选定区域数据
     */
    const [selectedAreaTreeData, setSelectedAreaTreeData] = useState({});
    /**
     * devices in current area , not with checked state
     * {
            "id":217,
            "name":"hahah",
            "function":{
                "id":1,
                "name":"照明"
            },
            "category":{
                "id":"F300",
                "name":"控制器"
            },
            "gateway":null,
            "status":null,
            "region":{
                "id":52,
                "name":"A区again"
            }
        }[]
     */
    const [devicesInCurrentArea, setDevicesInCurrentArea] = useState([]);
    /**
     * when left tree-panel select id change , request newest equipment list
     * 当左树形面板选择id更改时，请求最新设备列表
     * must ensure light function id have value
     * 必须确保light函数id有值
     */
    useEffect(() => {
        if (!isEmpty(selectedAreaTreeData) && lightFunctionId !== undefined) {
            getEquipmentList({ region_id: selectedAreaTreeData.id, function_id: lightFunctionId, get_all: true}) //获取照明设备
                .then(result => {
                    setDevicesInCurrentArea(result.data);
                })
                .catch();
        }
    }, [selectedAreaTreeData, lightFunctionId]);
    //endregion

    //region 预设列表，第一次进入，删除预设，修改预设的时候会重新拉取最新预设列表
    /**
     * use this state listen preControl info change
     * 使用此状态侦听预控信息更改
     */
    const [update, setUpdate] = useState(undefined);
    /**
     * pre-control list defined by user
     * 用户定义的预控列表
     */
    const [preControls, setPreControls] = useState([]);
    /**
     * when first launch and update or delete proControl , refresh the newest preControl list
     * 第一次启动并更新或删除控制器时，刷新最新的预控列表
     */
    useEffect(() => {
        getPreControl() //预设列表
            .then(result => {
                setPreControls(result.data);
            })
            .catch();
    }, [update]);
    //endregion

    //region 关于选中预设的操作，其实针对预设的添加删除操作也是一种主观意愿
    /**
     * {"id":45,"routes":{"9":[3],"436":[3,9]},"name":"AAF5"}[]
     * this array composed by multi-preControl
     * 这个数组由多个预控组成
     * 自己选中的预设
     */
    const [composedControls, setComposedControls] = useState([]);
    /**
     * when select a proControl , set the detail to comprehensive control
     * 当选择一个控制器时，将细节设置为综合控制
     * @param preControlId
     */
    const onSelectProControl = preControlId => {
        getPreControlDetail(preControlId)
            .then(result => {
                let preControl = result.data;
                setComposedControls(composedControls.concat(preControl));
                //when add a new preControl , select all device
                //添加新的预控时，选择所有设备
                let copyedUserOperated = new Map(operatedDeviceOutSideComposedControl);
                Object.keys(preControl.routes).forEach(eachDeviceId => {
                    copyedUserOperated.set(Number(eachDeviceId), {
                        allAreaIds: preControl.routes[eachDeviceId],
                        checked: true,
                    });
                });
                setOperatedDeviceOutSideComposedControl(copyedUserOperated); //预设中的设备id数组
            })
            .catch();
    };
    /**
     * when deselect a proControl , remove proControl from state
     * 取消选择控制时，从状态中删除proControl
     * @param preControlId
     */
    const onDeselectProControl = preControlId => {
        let removedControl = JSON.parse(JSON.stringify(composedControls.find(value => value.id === preControlId)));
        setComposedControls(composedControls.filter(value => value.id !== preControlId));
        //remove correspond user operated intent
        //删除相应的用户操作意图
        //when preControl remove , all devices in selected list should be un-selected
        //删除预控时，应取消选中所选列表中的所有设备
        let copyedUserOperated = new Map(operatedDeviceOutSideComposedControl);
        Object.keys(removedControl.routes).forEach(eachDeviceId => {
            copyedUserOperated.set(Number(eachDeviceId), {
                allAreaIds: removedControl.routes[eachDeviceId],
                checked: false,
            });
        });
        setOperatedDeviceOutSideComposedControl(copyedUserOperated);
    };
    //endregion

    //region 我的主观意愿，是设备对意愿的映射，每个意愿包含了设备所属的所有区域id以及当前设备是否被选中
    /**
     * selected device outside already selected composed pro-controls
     * 选定的设备在已选定的pro控件之外
     * key is device id (number), value is {  键是设备id（编号），值是{
     *     allAreaIds:number[] //all parent id with self area id 具有自身区域id的所有父id
     *     checked:extra operate (select or unselect)优先级比选中的预设高  额外操作（选择或取消选择）
     * }
     */
    const [operatedDeviceOutSideComposedControl, setOperatedDeviceOutSideComposedControl] = useState(new Map());

    /***
     * generate all id array of all area that is specified area of child area
     * 生成子区域指定区域的所有区域的所有id数组
     * @param parentAreaIdArray initial array  ，[] 父区域阵列
     * @param areaId directBelongAreaId 区域id子区域id
     * @returns {any} all area id array
     */
    const generateAllAreaIdFromChildAreaId = (parentAreaIdArray, areaId) => {
        let arrayCopy = JSON.parse(JSON.stringify(parentAreaIdArray));
        let targetAreaObject = fetchTargetAreaObjectByAreaId(areaTreeData, areaId);
        if (targetAreaObject === undefined) {
            return arrayCopy;
        }
        let areaIdsArrayWithParentId = arrayCopy.concat(areaId);
        if (targetAreaObject.pid === null) {
            return areaIdsArrayWithParentId;
        }
        return generateAllAreaIdFromChildAreaId(areaIdsArrayWithParentId, targetAreaObject.pid);
    };
    const fetchTargetAreaObjectByAreaId = (areaTree, nodeId) => {
        let targetAreaObject = undefined;
        for (let areaTreeDatum of areaTree) {
            if (areaTreeDatum.id === nodeId) {
                targetAreaObject = areaTreeDatum;
                break;
            }
            if (areaTreeDatum.children !== undefined) {
                targetAreaObject = fetchTargetAreaObjectByAreaId(areaTreeDatum.children, nodeId);
                if (targetAreaObject !== undefined) {
                    break;
                }
            }
        }
        return targetAreaObject;
    };

    /**
     * id of all areas include device cache ,use for performance improve
     * 所有区域的id包括设备缓存，用于提高性能
     * each element have profile {deviceId:string,areaIds:number[]}
     * 每个元素都有配置文件{deviceId:string，areaid:number[]}
     */
    const [deviceToAreaIdCache, setDeviceToAreaIdCache] = useState([]);

    /**
     * this function only for the sake of useState can konw the ES6_Map change
     * 此函数仅用于useState才能知道ES6\U映射的更改
     * @param deviceId
     * @param directlyBelongAreaId area id the device directly belong to 设备直接属于的区域id
     * @param checked current device if checked 当前设备（如果选中）
     */
    const setOperatedDeviceOutSideComposePreControlWithIntentDetermine = (deviceId, directlyBelongAreaId, checked) => {
        let targetMapping = deviceToAreaIdCache.find(eachMapping => eachMapping.deviceId === deviceId);
        if (targetMapping === undefined) {
            let allAreaIds = generateAllAreaIdFromChildAreaId([], directlyBelongAreaId);
            setDeviceToAreaIdCache(
                deviceToAreaIdCache.concat({
                    deviceId: deviceId,
                    areaIds: allAreaIds,
                }),
            );
            let newestMap = operatedDeviceOutSideComposedControl.set(deviceId, {
                allAreaIds: allAreaIds,
                checked: checked,
            });
            setOperatedDeviceOutSideComposedControl(new Map(newestMap));
        } else {
            let newestMap = operatedDeviceOutSideComposedControl.set(deviceId, {
                allAreaIds: targetMapping.areaIds,
                checked: checked,
            });
            setOperatedDeviceOutSideComposedControl(new Map(newestMap));
        }
    };
    /**
     * when user click device in right panel , determine operate is select or unselect
     * 当用户单击右面板中的设备时，确定操作是选择还是取消选择
     * then add this operate to extra operate
     * 然后将此操作添加到额外操作
     * @param checkDeviceIds all checked device ids with number format 所有已检查的设备ID（数字格式
     */
    const onClickDeviceInRightPanel = checkDeviceIds => {
        devicesInCurrentAreaWithCheckState.forEach(eachDeviceWithState => {
            if (checkDeviceIds.includes(eachDeviceWithState.value)) {
                //this device checked //this device checked 此设备已检查
                setOperatedDeviceOutSideComposePreControlWithIntentDetermine(
                    eachDeviceWithState.value,
                    eachDeviceWithState.directBelongAreaId,
                    true,
                );
            } else {
                setOperatedDeviceOutSideComposePreControlWithIntentDetermine(
                    eachDeviceWithState.value,
                    eachDeviceWithState.directBelongAreaId,
                    false,
                );
            }
        });
    };
    /**
     * when click check-all button , change all selected state of devices in current selected area
     * 单击“全选”按钮时，更改当前选定区域中设备的所有选定状态
     * @param wantToCheckAll  select-all operation or deselect-all operation 选择所有操作或取消选择所有操作
     */
    const onClickCheckAllButton = wantToCheckAll => {
        devicesInCurrentAreaWithCheckState.forEach(eachDeviceWithState => {
            setOperatedDeviceOutSideComposePreControlWithIntentDetermine(
                eachDeviceWithState.value,
                eachDeviceWithState.directBelongAreaId,
                wantToCheckAll,
            );
        });
    };
    //endregion

    //region 根据所有当前区域中的设备 加上 全局选中设备的id(由预设和主观意愿组成)，得出当前区域选中的设备（带选中状态以及直属区域id）
    /**
     * devices in current select left-panel area with check state
     * 设备在当前选中状态下选择左面板区域
     * used display in right panel
     * 右面板中使用的显示
     * 每个元素都有配置文件:
     * each element have profile : {value: ${deviceId}, label: ${deviceName},checked:${haveCheck},directBelongAreaId:number}
     */
    const [devicesInCurrentAreaWithCheckState, setDevicesInCurrentAreaWithCheckState] = useState([]);
    /**
     * when dependencies change , recalculate devices states in current area
     * 当依赖项更改时，重新计算当前区域中的设备状态
     */
    useEffect(() => {
        let allSelectedDeviceIds = generateAllSelectedDeviceId();
        let newestDevicesWithState = devicesInCurrentArea.map(eachDevice => {
            return {
                value: eachDevice.id,
                label: eachDevice.name,
                checked: allSelectedDeviceIds.includes(String(eachDevice.id)),
                directBelongAreaId: eachDevice.region.id,
            };
        });
        setDevicesInCurrentAreaWithCheckState(newestDevicesWithState);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [devicesInCurrentArea, composedControls, operatedDeviceOutSideComposedControl]);

    /**
     * compose user intentional selected device(operatedDeviceOutSideComposedControl) and proControl devices(composedControl)
     * 如果用户希望有意取消选择此设备，请按照用户的说明编写用户有意选择的设备（OperationedDeviceOutsideComposedControl）和控制设备（composedControl）
     * @returns selected device id array (string[])
     */
    const generateAllSelectedDeviceId = () => {
        //proControl intent 中央控制
        let selectedDeviceInComposedControl = [
            ...new Set(composedControls.map(eachPreControl => Object.keys(eachPreControl.routes)).flat(1)),
        ];
        //if user want to unselect this device intentionally , follow the user
        //如果用户有意取消选择此设备，请跟随用户
        selectedDeviceInComposedControl = selectedDeviceInComposedControl.filter(eachPreControlSelectedId => {
            let correspondDevice = operatedDeviceOutSideComposedControl.get(Number(eachPreControlSelectedId));
            return correspondDevice !== undefined && correspondDevice.checked !== false;
        });
        //add user selected devices not in proControl
        //添加用户选择的不在proControl中的设备
        for (let [key, value] of operatedDeviceOutSideComposedControl) {
            if (
                value.checked === true &&
                selectedDeviceInComposedControl.find(
                    eachPreControlSelectedId => eachPreControlSelectedId === String(key),
                ) === undefined
            ) {
                selectedDeviceInComposedControl.push(String(key));
            }
        }
        return selectedDeviceInComposedControl;
    };
    //endregion

    //region 根据原始区域树，对照 预设和主观意愿 生成  带选中设备数量的区域树
    const [areaTreeDataWithSelectNumField, setAreaTreeDataWithSelectNumField] = useState([]);
    /**
     * when dependencies change , add selectNum field to each area data
     * 当依赖项更改时，向每个区域数据添加selectNum字段
     */
    useEffect(() => {
        let areaIdToDeviceIdsMap = generateAreaIdToSelectNumMap();
        let areaWithSelectNumField = fillSelectNumFieldRecursion(
            JSON.parse(JSON.stringify(areaTreeData)),
            areaIdToDeviceIdsMap,
        );
        setAreaTreeDataWithSelectNumField(areaWithSelectNumField);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [areaTreeData, composedControls, operatedDeviceOutSideComposedControl]);

    /**
     * 递归填充selectNum字段
     * @param areaArray 左侧大区域树
     * @param areaIdToDeviceIdsMap 区域到区域中选中所有设备的映射 ，是一个ES6 Map
     * @returns {*}
     */
    const fillSelectNumFieldRecursion = (areaArray, areaIdToDeviceIdsMap) => {
        for (let eachArea of areaArray) {
            let maybeExistDeviceIds = areaIdToDeviceIdsMap.get(String(eachArea.id));
            if (maybeExistDeviceIds !== undefined) {
                eachArea.selectNum = maybeExistDeviceIds.length;
            }
            if (eachArea.children !== undefined) {
                eachArea.children = fillSelectNumFieldRecursion(
                    JSON.parse(JSON.stringify(eachArea.children)),
                    areaIdToDeviceIdsMap,
                );
            }
        }
        return areaArray;
    };

    /**
     * generate area id to selected devices in this area map
     * 为此区域地图中的选定设备生成区域id
     * @returns {Map<string, string[]>} areaId to id of selected devices mapping 区域id -> 该区域中选中设备的id集合
     */
    const generateAreaIdToSelectNumMap = () => {
        let areaIdToSelectNumMap = new Map();
        //transfer composed controls to mapping 将选中的预设转化为映射
        JSON.parse(JSON.stringify(composedControls)).forEach(eachPreControl => {
            let deviceToAreaMapping = eachPreControl.routes;
            for (let eachSelectDeviceIdInProControl of Object.keys(deviceToAreaMapping)) {
                //{"9":[3],"436":[3,9]}
                let correspondAreaIdArray = deviceToAreaMapping[eachSelectDeviceIdInProControl];
                correspondAreaIdArray.forEach(eachAreaId => {
                    let correspondAreaId = String(eachAreaId);
                    let maybeExistAreaToSelectedDeviceMapping = areaIdToSelectNumMap.get(correspondAreaId);
                    if (maybeExistAreaToSelectedDeviceMapping === undefined) {
                        areaIdToSelectNumMap.set(correspondAreaId, [String(eachSelectDeviceIdInProControl)]);
                    } else {
                        //this area already have some selected devices(maybe selected by other proControl and so on), De-duplicate the devices
                        //这个区域已经有一些选定的设备（可能被其他的控制器等选择），消除重复的设备
                        areaIdToSelectNumMap.set(correspondAreaId, [
                            ...new Set([
                                ...maybeExistAreaToSelectedDeviceMapping,
                                String(eachSelectDeviceIdInProControl),
                            ]),
                        ]);
                    }
                });
            }
        });
        //add user selected device (outside composed pre-control)
        //添加用户选择的设备（在预控制之外）
        for (let [deviceIdStringFormat, value] of new Map(operatedDeviceOutSideComposedControl).entries()) {
            if (value.checked) {
                value.allAreaIds
                    .map(eachAreaIdNumber => String(eachAreaIdNumber))
                    .forEach(eachAreaIdString => {
                        // console.log('find a select device : '+deviceIdStringFormat+' : '+eachAreaIdString)
                        //only deal selected device
                        //仅处理所选设备
                        let maybeExistAreaToSelectedDeviceMapping = areaIdToSelectNumMap.get(eachAreaIdString);
                        if (maybeExistAreaToSelectedDeviceMapping === undefined) {
                            areaIdToSelectNumMap.set(eachAreaIdString, [String(deviceIdStringFormat)]);
                        } else {
                            //this area already have some selected devices(maybe selected by other proControl and so on), De-duplicate the devices
                            //这个区域已经有一些选定的设备（可能被其他的控制器等选择），消除重复的设备
                            areaIdToSelectNumMap.set(eachAreaIdString, [
                                ...new Set([...maybeExistAreaToSelectedDeviceMapping, String(deviceIdStringFormat)]),
                            ]);
                        }
                    });
            } else {
                //deal non-selected device
                //处理未选择的设备
                value.allAreaIds
                    .map(eachAreaIdNumber => String(eachAreaIdNumber))
                    .forEach(eachAreaIdString => {
                        let maybeExistAreaToSelectedDeviceMapping = areaIdToSelectNumMap.get(eachAreaIdString);
                        if (maybeExistAreaToSelectedDeviceMapping === undefined) {
                            //do nothing
                        } else {
                            //this area already have some selected devices(maybe selected by other proControl and so on), De-duplicate the devices
                            //这个区域已经有一些选定的设备（可能被其他的控制器等选择），消除重复的设备
                            areaIdToSelectNumMap.set(
                                eachAreaIdString,
                                maybeExistAreaToSelectedDeviceMapping.filter(
                                    eachDeviceId => eachDeviceId !== String(deviceIdStringFormat),
                                ),
                            );
                        }
                    });
            }
        }
        return areaIdToSelectNumMap;
    };
    //endregion

    return (
        <>
            <FormItem label="预设" style={{ minWidth: 500, marginBottom: '1rem' }} noStyle>
                <Select
                    // style={{ width: 200, position: 'absolute', left: '14rem', top: '0.8rem' }}
                    style={{ minWidth: 300, marginBottom: '1rem' }}
                    placeholder="请选择预设列表"
                    optionFilterProp="children"
                    onDeselect={onDeselectProControl}
                    onSelect={onSelectProControl}
                    allowClear
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    mode={'multiple'}
                    showArrow
                    virtual={false}
                    dropdownRender={menu => (
                        <div
                            style={{ display: 'flex', height: '256px', overflowY: 'scroll' }}
                            className={switchStyle.container}
                        >
                            {menu}
                            <div style={{ float: 'right' }}>
                                {preControls &&
                                    preControls.map(item => (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                minHeight: '32px',
                                                lineHeight: '22px',
                                                padding: '5px 12px',
                                                color: 'rgba(0, 0, 0, 0.85)',
                                                fontWeight: 'normal',
                                                fontSize: '14px',
                                                cursor: 'pointer',
                                            }}
                                            key={item.id}
                                        >
                                            <span
                                                style={{ display: 'block', marginRight: '1rem', cursor: 'pointer' }}
                                                onClick={() => {
                                                    setModalType('edit');
                                                    childRef.current.showModal();
                                                    getPreControlDetail(item.id)
                                                        .then(result => {
                                                            formChildrenRef.current.setFieldsValue({
                                                                name: result.data.name, //初始化默认值时处理数据
                                                            });
                                                        })
                                                        .catch();
                                                }}
                                            >
                                                <EditOutlined />
                                            </span>
                                            <span
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    delPreControlDetail(item.id)
                                                        .then(result => {
                                                            message.success('删除成功!');
                                                            setUpdate(result);
                                                        })
                                                        .catch();
                                                }}
                                            >
                                                <DeleteOutlined />
                                            </span>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    )}
                >
                    {preControls &&
                        preControls.map(item => (
                            <Option key={item.id} value={item.id} style={{ width: '100%' }} title={item.name}>
                                {/* <Input value={item.name}/> */}
                                {item.name}
                            </Option>
                        ))}
                </Select>
            </FormItem>
            <Row style={{ border: '1px solid #d9d9d9', padding: '1rem' }}>
                <Col span={6} style={{ height: '100%', maxHeight: '700px', overflowY: 'scroll' }}>
                    <CommonTreeSwitch
                        treeData={areaTreeDataWithSelectNumField}
                        onClick={value => {
                            if (!isEmpty(areaTreeDataWithSelectNumField) && value !== undefined) {
                                setSelectedAreaTreeData(value);
                            }
                        }}
                    />
                </Col>
                <Col
                    offset={1}
                    span={17}
                    style={{
                        border: '1px solid #d9d9d9',
                        padding: '1rem',
                        height: '100%',
                        maxHeight: '700px',
                        overflowY: 'scroll',
                    }}
                >
                    <AssignForm
                        devices={devicesInCurrentAreaWithCheckState}
                        onClickCheckAllButton={checkAll => {
                            onClickCheckAllButton(checkAll);
                        }}
                        onClickDevice={checkDeviceIds => {
                            onClickDeviceInRightPanel(checkDeviceIds);
                        }}
                    />
                </Col>
                <Col offset={8} span={8} style={{ display: 'flex', justifyContent: 'space-around', marginTop: '1rem' }}>
                    <Button
                        type="primary"
                        onClick={() => {
                            setModalType('add');
                            childRef.current.showModal();
                            // formChildrenRef.current.resetFields();
                        }}
                    >
                        存为预设
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => {
                            //TODO 发起http请求提交一键开、关灯的设置 (确认按钮)
                            const action = type === 'on' ? 1 : 0;
                            const ids = generateAllSelectedDeviceId();
                            changeSwitch({ action: action, fid: 1, devices: ids.map(Number) }) //TODO devices的name要转为id （bug）
                                .then(result => {
                                    message.success(type === 'on' ? '开灯成功' : '关灯成功');
                                })
                                .catch();
                        }}
                    >
                        确认
                    </Button>
                </Col>
            </Row>
            <CommonModal
                onOk={() => {
                    formChildrenRef.current
                        .validateFields()
                        .then(value => {
                            //TODO 发起http请求提交存为预设 (存为预设)
                            const ids = generateAllSelectedDeviceId();
                            value.devices = ids.map(Number);
                            addPreControl(value)
                                .then(result => {
                                    message.success('预设存储成功');
                                    formChildrenRef.current.resetFields();
                                    childRef.current.hiddenModal();
                                    setUpdate(result);
                                })
                                .catch();
                        })
                        .catch();
                }}
                childRef={childRef}
                // title="预存规则命名"
                title={`${modalType === 'edit' ? '编辑' : '新增'}预存规则名称`}
            >
                <HardForm childRef={formChildrenRef} />
            </CommonModal>
        </>
    );
};

/**
 * right panel , current devices
 * @param devices should have specified profile : {value: ${deviceId}, label: ${deviceName},checked:${haveCheck}}
 *        if not have devices , pass a empty array , rather than "undefined"
 * @param onClickCheckAllButton (select:boolean)=>void
 * @param onClickDevice (checkIds:number[])=>void
 * @returns {JSX.Element}
 * @constructor
 */
const AssignForm = ({ devices, onClickCheckAllButton, onClickDevice }) => {
    const interOnClickCheckAllButton = e => {
        onClickCheckAllButton(e.target.checked);
    };
    return (
        <>
            <Row>
                <Col span={6}>
                    <span style={{ fontSize: '1rem' }}>
                        <MyIcon
                            // type={newItem.name === '照明' ? 'icon_lighting' : 'icon_socket'}
                            type={'icon_lighting'}
                            style={{ color: '#000', fontSize: '1.5rem', verticalAlign: 'middle' }}
                        />
                        {'照明'}
                    </span>
                    ({devices.length})
                </Col>
                <Col offset={14} span={4}>
                    {devices.length === 0 ? (
                        ''
                    ) : (
                        <Checkbox
                            onChange={interOnClickCheckAllButton}
                            checked={devices.find(value => value.checked !== true) === undefined}
                        >
                            全选
                        </Checkbox>
                    )}
                </Col>
            </Row>
            <Divider />
            {devices.length === 0 ? (
                <EmptyView description={'暂无设备,请选择左侧的区域'} />
            ) : (
                <CheckboxGroup
                    options={devices}
                    value={devices.filter(value => value.checked === true).map(eachDevice => eachDevice.value)}
                    onChange={checkedIds => {
                        onClickDevice(checkedIds);
                    }}
                    key={devices}
                />
            )}
            <Divider />
        </>
    );
};
//存为预设弹框内容
const HardForm = ({ childRef }) => {
    const [form] = Form.useForm();
    const FormItemLayout = {
        labelCol: { span: 5 },
        wrapperCol: { span: 16 },
    };
    useImperativeHandle(childRef, () => ({
        validateFields: form.validateFields,
        resetFields: form.resetFields,
        setFieldsValue: form.setFieldsValue,
    }));
    return (
        <Form form={form} layout="horizontal">
            <FormItem
                name="name"
                label="规则名称"
                {...FormItemLayout}
                rules={[{ required: true, message: '请输入预存规则名称！' }]}
            >
                <Input maxLength={32} />
            </FormItem>
        </Form>
    );
};

export default OneBtnSwitch;
