import React, { useState, useMemo, useEffect } from 'react';
import { Tree } from 'antd';
import { map, get } from 'lodash';
import treeStyle from './commonTree.module.less';
import { EmptyView } from './EmptyView';
import { MyIcon } from '../util/iconfont';
/**
 * 包含区域和设备的组件 （主要用于巡检管理）
 * × 不可多选
 * √ 可默认渲染第一条数据
 *
 * @param {*} treeData   区域树的数据
 * @param {*} onClick    点击事件触发
 * @param {*} operable   编辑节点
 * @param {*} add        新增节点
 * @param {*} edit       编辑节点
 * @param {*} remove     删除节点
 * @param {*} selectNum  节点title旁边的数量
 * @param {*} getAllTree
 * @returns
 */
export const CommonCameraTree = ({ treeData = [], onClick, operable = true, add, edit, remove, getNode }) => {
   const { dataList, parentRegionIds } = useMemo(() => {
        const nodeList = [];
        const generateList = data => {
            return map(data, item => {
                const { id, name } = item;
                nodeList.push({ id, name });
                return { ...item, key: `${id}`, title: name, children: generateList(item.children) };
            });
        };
        const dataList = generateList(treeData);
        const parentRegionIds = get(dataList, '[0].id');
        getNode && getNode(nodeList);
        onClick && onClick(dataList[0]);
        return { dataList, parentRegionIds };
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [treeData]);
    const onDrop = (event, node, dragNode, dragNodesKeys) => {
        // console.log(event);
        // console.log(node);
        // console.log(dragNode);
        // console.log(dragNodesKeys, 'dragNodesKeys');
    };
    //点击树节点触发
    const onSelect = (selectedKeys, info) => {
        let keys = { id: selectedKeys[0] };
        onClick && onClick(keys);
    };
    const [expandedKeys, setExpandedKeys] = useState([`${parentRegionIds}`]);
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    useEffect(() => {
        setExpandedKeys([`${parentRegionIds}`]);
    }, [parentRegionIds]);
    //展开的回调
    const onExpand = expandedKeysValue => {
        //展开/收起节点时触发
        //展开的状态
        setExpandedKeys(expandedKeysValue);
        setAutoExpandParent(false);
    };
    return dataList.length > 0 ? (
        <div className={treeStyle.container}>
            <Tree
                onExpand={onExpand} //展开事件
                onDrop={onDrop}
                onSelect={onSelect}
                expandedKeys={expandedKeys} //默认展开的key
                autoExpandParent={autoExpandParent} //是否自动展开父节点
                treeData={dataList}
                titleRender={operable ? node => <TreeTitle node={node} /> : null}
                defaultSelectedKeys={[dataList[0].key]}
            />
        </div>
    ) : (
        <div style={{ height: '76vh' }}>
            <EmptyView />
        </div>
    );
};

const TreeTitle = ({ node }) => {
    if (node.level === 1) {
        return <span>{node.title}</span>;
    }
    return (
        <div
            style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
            <div>
                <MyIcon type="icon_address" />
                <span style={{ verticalAlign: 'middle', whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: '95px', display: 'inline-block', overflow: 'hidden',  }}>{' ' + node.title}</span>
                <span style={{ verticalAlign: 'middle' }}>
                    {node.total !== undefined ? '(' + node.total + ')' : ''}
                </span>
            </div>
        </div>
    );
};
