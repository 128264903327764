import { useEffect, useMemo, useRef, useState } from 'react';
import { Col, Row, Radio, DatePicker } from 'antd';
import {isEmpty, isBoolean, includes, toNumber, isNumber} from 'lodash';
import moment from 'moment';
import { BreadcrumbCustom } from '../../../components/BreadcrumbCustom';
import { CommonTree } from '../../../components/CommonTree';
import { PieChart } from './Chart/PieChart';
import { SankeyChart } from './Chart/SankeyChart';
import { CartesianThermogramChart } from './Chart/CartesianThermogramChart';
import { HeatmapChart } from './Chart/HeatmapChart';
import { MyIcon } from '../../../util/iconfont';
import { day30, day7, month12, toDay, disabledDate_30day, disabledDate_days } from '../../../util/timeUtil';
import { removeEmpty, peakvalue, reviseUnit1 } from '../../../util/function-util';
import { EmptyView } from '../../../components/EmptyView';
import { Loading } from '../../../components/Loading';
import { Chart_COLOR, PieChart_COLOR3, poweer_consumption } from '../../../global';
import {
    getAreaList,
    getContrast,
    requestHotChart,
    requestPieChart,
    requestSankeyDiagram,
    getReportPowerTrend,
} from '../../../api/webServe';
import lessStyle from './eCRCondition.module.less';

const { RangePicker } = DatePicker;
/**
 * 能耗概况
 * @param {*} props
 * @returns
 */
export const ECRCondition = props => {
    const [areaInfo, setAreaInfo] = useState();
    const [treeData, setTreeData] = useState([]);
    const [treeType, setTreeType] = useState(false);
    useEffect(() => {
        // treeHasIcon()
        //     .then(result => {

        //     })
        //     .catch();
        getAreaList()
            .then(result => {
                setTreeData(result.data);
            })
            .catch();
    }, []);
    return (
        <div className={'container clearfix ' + lessStyle.containers}>
            <BreadcrumbCustom paths={['首页', '能耗分析', '能耗概况']} className="rumbCustom" />
            <div className="row clearfix" style={{ backgroundColor: 'transparent', margin: '1rem', padding: '0' }}>
                <Row style={{ marginLeft: '0rem', width: '100%' }} gutter={32}>
                    <Col
                        className="gutter-row"
                        span={4}
                        style={{ padding: '1rem', background: '#fff', borderRadius: '10px' }}
                    >
                        <CommonTree
                            operable={false}
                            treeData={treeData}
                            onClick={info => {
                                if (!areaInfo || areaInfo.id !== info.id) {
                                    setAreaInfo(info);
                                    setTreeType(info && info.dev_cate ? true : false)
                                }
                            }}
                        />
                    </Col>
                    <Col
                        span={20}
                        style={{
                            borderRadius: '10px',
                            boxSizing: 'border-box',
                            paddingRight: '0',
                        }}
                    >
                        <ChartView areaInfo={areaInfo} treeType={treeType}/>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
const gutter = [16, 16];
const ChartView = ({ areaInfo, treeType }) => {
    const id = useMemo(() => {
        if (isEmpty(areaInfo)) {
            return null;
        }
        return areaInfo.id;
    }, [areaInfo]);
    const [diffDay, setDiffDay] = useState(7);
    const [contrastData, setContrast] = useState({ isLoading: true, data: null });
    const [pieData, setPieData] = useState({ isLoading: true, data: null });
    const [piePeakData, setPiePeakData] = useState({ isLoading: true, data: null });
    const [sankeyDiagram, setSankeyDiagram] = useState({ isLoading: true, data: null });
    const [hotData, setHotData] = useState({ isLoading: true, data: null });//能耗热力图
    const [hotType, setHotType] = useState('d'); //日期类型
    //用能对比
    const contrastRequest = () => {
        setContrast({ data: null, isLoading: true });
        getContrast(treeType ? { device_id: id } : { region_id: id })
            .then(result => {
                result = result.data;
                setContrast({ data: result, isLoading: false });
            })
            .catch(() => {
                setContrast({ data: null, isLoading: false });
            });
    };
    //用能分布
    const getPie = time => {
        setPieData({ data: null, isLoading: true });
        requestPieChart(treeType ? { device_id: id, start_time: time[0], end_time: time[1] } : { region_id: id, start_time: time[0], end_time: time[1] })
            .then(result => {
                result = result.data;
                setPieData({ isLoading: false, data: result });
            })
            .catch(() => {
                setPieData({ isLoading: false, data: null });
            });
        getReportPowerTrend({ id, start_time: time[0], end_time: time[1] })
            .then(result => {
                result = result.data;
                let peak_hour_expense = result.peak_hour_expense.map(item => {
                    item.value = peakvalue(item.value);
                    return item;
                });
                setPiePeakData({ isLoading: false, data: peak_hour_expense });
            })
            .catch(() => {
                setPiePeakData({ isLoading: false, data: null });
            });
    };
    //能耗流向
    const getSankeyDiagram = time => {
        setSankeyDiagram({ data: null, isLoading: true });
        requestSankeyDiagram(treeType ? { device_id: id, start_time: time[0], end_time: time[1] } : { region_id: id, start_time: time[0], end_time: time[1] })
            .then(result => {
                result = result.data;
                setSankeyDiagram({ data: result, isLoading: false });
            })
            .catch(() => {
                setSankeyDiagram({ data: null, isLoading: false });
            });
    };
    //能耗热力图
    const getHotChart = time => {
        setHotData({ data: null, isLoading: true });
        requestHotChart(treeType ? { device_id: id, start_time: time[0], end_time: time[1] } : { region_id: id, start_time: time[0], end_time: time[1] })
            .then(result => {
                setHotType(result.rule);
                result = result.data;
                setHotData({ data: result, isLoading: false });
            })
            .catch(() => {
                setHotData({ data: null, isLoading: false });
            });
    };
    useEffect(() => {
        if (isEmpty(areaInfo)) {
            return;
        }
        if (!id && !isNumber(id)) {
            return;
        }
        setDiffDay(7);
        contrastRequest(); //用能对比
        getPie(toDay); //用能分布
        getSankeyDiagram(toDay); //能耗流向
        // getHotChart([moment(day7[0]).startOf('day'),moment(day7[1]).endOf('day')]); //能耗热力图
        getHotChart(day7); //能耗热力图
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [areaInfo]);
    return (
        <>
            <Row gutter={gutter}>
                <Col xxl={12} xl={24} lg={24} md={24} ms={24} xs={24}>
                    <div style={{ ...Style.module, height: '230px' }}>
                        <Row
                            style={{
                                height: 50,
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                                lineHeight: '50px',
                                borderTopLeftRadius: '10px',
                                borderTopRightRadius: '10px',
                                boxShadow: '0px 2px 3px #e7ebeb',
                                background: 'linear-gradient(180deg, #fefefe 0%, #f5f6fa 100%)',
                            }}
                        >
                            <Col span={8}>
                                <span style={Style.title}>
                                    {' '}
                                    <MyIcon
                                        type="icon_title_contrast"
                                        style={{
                                            fontSize: '1.5rem',
                                            marginTop: '-0.1rem',
                                            verticalAlign: 'middle',
                                            paddingRight: '0.4rem',
                                            height: 50,
                                            lineHeight: '60px',
                                        }}
                                    />
                                    用能对比
                                </span>
                            </Col>
                        </Row>
                        <Loading show={contrastData.isLoading} height={'80%'}>
                            {isEmpty(contrastData.data) ? (
                                <EmptyView />
                            ) : (
                                <div
                                    style={{
                                        height: 180,
                                        display: 'flex',
                                        fontSize: '0.95rem',
                                        flexDirection: 'column',
                                        justifyContent: 'space-around',
                                        padding: '1rem',
                                        borderRadius: '10px',
                                    }}
                                >
                                    <Row gutter={32}>
                                        <Col flex="210px">
                                            今日用电
                                            <span style={{ fontSize: '1.25rem', color: '#595959' }}>
                                                {/* {removeEmpty(contrastData.data.today)} */}
                                                {reviseUnit1(removeEmpty(contrastData.data.today),poweer_consumption,contrastData)}
                                            </span>
                                        </Col>
                                        <Col flex="20px" />
                                        <Col flex="auto">
                                            昨日同期
                                            <span style={{ fontSize: '1.25rem', color: '#595959' }}>
                                                {/* {removeEmpty(contrastData.data.yesterday)} */}
                                                {reviseUnit1(removeEmpty(contrastData.data.yesterday),poweer_consumption,contrastData)}
                                            </span>
                                        </Col>
                                        <Col flex="20px" />
                                        <Col
                                            flex="200px"
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                // justifyContent: 'space-between',
                                            }}
                                        >
                                            {contrastData.data.dom ? (
                                                <>
                                                    <span>
                                                        环比&nbsp;&nbsp;
                                                        <span style={{ fontSize: '1.25rem', color: '#595959' }}>
                                                            {removeEmpty(contrastData.data.dom)}
                                                        </span>
                                                        %
                                                    </span>
                                                    {toNumber(contrastData.data.dom) >= 0 ? (
                                                        <MyIcon
                                                            type="icon_rise"
                                                            style={{
                                                                color: '#E5756E',
                                                                fontSize: '1.2rem',
                                                                marginLeft: '1rem',
                                                            }}
                                                        />
                                                    ) : (
                                                        <MyIcon
                                                            type="icon_decline"
                                                            style={{
                                                                color: '#64D399',
                                                                fontSize: '1.2rem',
                                                                marginLeft: '1rem',
                                                            }}
                                                        />
                                                    )}
                                                </>
                                            ) : (
                                                <span>
                                                    环比&nbsp;&nbsp;
                                                    <MyIcon
                                                        type="icon_flat"
                                                        style={{
                                                            color: '#595959',
                                                            fontSize: '1.2rem',
                                                            marginLeft: '1rem',
                                                        }}
                                                    />
                                                </span>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row gutter={32}>
                                        <Col flex="210px">
                                            本月用电
                                            <span style={{ fontSize: '1.25rem', color: '#595959' }}>
                                                {/* {removeEmpty(contrastData.data.mouth)} */}
                                                {reviseUnit1(removeEmpty(contrastData.data.mouth),poweer_consumption,contrastData)}
                                            </span>
                                        </Col>
                                        <Col flex="20px" />
                                        <Col flex="auto">
                                            上月同期
                                            <span style={{ fontSize: '1.25rem', color: '#595959' }}>
                                                {/* {removeEmpty(contrastData.data.last_mouth)} */}
                                                {reviseUnit1(removeEmpty(contrastData.data.last_mouth),poweer_consumption,contrastData)}
                                            </span>
                                        </Col>
                                        <Col flex="20px" />
                                        <Col
                                            flex="200px"
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                // justifyContent: 'space-between',
                                            }}
                                        >
                                            {contrastData.data.mom ? (
                                                <>
                                                    <span>
                                                        环比&nbsp;&nbsp;
                                                        <span style={{ fontSize: '1.25rem', color: '#595959' }}>
                                                            {removeEmpty(contrastData.data.mom)}
                                                        </span>
                                                        %
                                                    </span>
                                                    {toNumber(contrastData.data.mom) >= 0 ? (
                                                        <MyIcon
                                                            type="icon_rise"
                                                            style={{
                                                                color: '#E5756E',
                                                                fontSize: '1.2rem',
                                                                marginLeft: '1rem',
                                                            }}
                                                        />
                                                    ) : (
                                                        <MyIcon
                                                            type="icon_decline"
                                                            style={{
                                                                color: '#64D399',
                                                                fontSize: '1.2rem',
                                                                marginLeft: '1rem',
                                                            }}
                                                        />
                                                    )}
                                                </>
                                            ) : (
                                                <span>
                                                    环比&nbsp;&nbsp;
                                                    <MyIcon
                                                        type="icon_flat"
                                                        style={{
                                                            color: '#595959',
                                                            fontSize: '1.2rem',
                                                            marginLeft: '1rem',
                                                        }}
                                                    />
                                                </span>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row gutter={32}>
                                        <Col flex="210px">
                                            本年用电
                                            <span style={{ fontSize: '1.25rem', color: '#595959' }}>
                                                {reviseUnit1(removeEmpty(contrastData.data.year),poweer_consumption,contrastData)}
                                            </span>
                                        </Col>
                                        <Col flex="20px" />
                                        <Col flex="auto">
                                            去年同期
                                            <span style={{ fontSize: '1.25rem', color: '#595959' }}>
                                                {/* {removeEmpty(contrastData.data.last_year)} */}
                                                {reviseUnit1(removeEmpty(contrastData.data.last_year),poweer_consumption,contrastData)}
                                            </span>
                                        </Col>
                                        <Col flex="20px" />
                                        <Col
                                            flex="200px"
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                // justifyContent: 'space-between',
                                            }}
                                        >
                                            {contrastData.data.yoy ? (
                                                <>
                                                    <span>
                                                        环比&nbsp;&nbsp;
                                                        <span style={{ fontSize: '1.25rem', color: '#595959' }}>
                                                            {removeEmpty(contrastData.data.yoy)}
                                                        </span>
                                                        %
                                                    </span>
                                                    {toNumber(contrastData.data.yoy) >= 0 ? (
                                                        <MyIcon
                                                            type="icon_rise"
                                                            style={{
                                                                color: '#E5756E',
                                                                fontSize: '1.2rem',
                                                                marginLeft: '1rem',
                                                            }}
                                                        />
                                                    ) : (
                                                        <MyIcon
                                                            type="icon_decline"
                                                            style={{
                                                                color: '#64D399',
                                                                fontSize: '1.2rem',
                                                                marginLeft: '1rem',
                                                            }}
                                                        />
                                                    )}
                                                </>
                                            ) : (
                                                <span>
                                                    环比&nbsp;&nbsp;
                                                    <MyIcon
                                                        type="icon_flat"
                                                        style={{
                                                            color: '#595959',
                                                            fontSize: '1.2rem',
                                                            marginLeft: '1rem',
                                                        }}
                                                    />
                                                </span>
                                            )}
                                        </Col>
                                    </Row>
                                </div>
                            )}
                        </Loading>
                    </div>
                </Col>
                <Col xxl={12} xl={24} lg={24} md={24} ms={24} xs={24}>
                    <div style={{ ...Style.module }} className={lessStyle.timeSearch}>
                        <Row
                            style={{
                                height: 50,
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                                lineHeight: '50px',
                                borderTopLeftRadius: '10px',
                                borderTopRightRadius: '10px',
                                boxShadow: '0px 2px 3px #e7ebeb',
                                background: 'linear-gradient(180deg, #fefefe 0%, #f5f6fa 100%)',
                            }}
                        >
                            <Col span={8}>
                                <span style={Style.title}>
                                    <MyIcon
                                        type="icon_title_spread"
                                        style={{
                                            fontSize: '1.5rem',
                                            marginTop: '-0.1rem',
                                            verticalAlign: 'middle',
                                            paddingRight: '0.4rem',
                                            height: 50,
                                            lineHeight: '60px',
                                        }}
                                    />
                                    用能分布
                                </span>
                            </Col>
                            <Col
                                span={16}
                                style={{ display: 'flex', justifyContent: 'space-between', lineHeight: '50px' }}
                            >
                                <TimeSearch
                                    timeValue={toDay}
                                    id={id}
                                    onValueChange={times => {
                                        if (!id && !isNumber(id)) {
                                            return;
                                        }
                                        const { time } = times;
                                        getPie(time);
                                    }}
                                />
                            </Col>
                        </Row>
                        <Loading show={pieData.isLoading}>
                            <Row style={{ height: 180 }}>
                                {isEmpty(pieData.data) || !pieData.data.total ? (
                                    <EmptyView />
                                ) : (
                                    <>
                                        <Col span={12}>
                                            <PieChart
                                                data={pieData.data ? pieData.data.function : []}
                                                color={PieChart_COLOR3}
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <PieChart
                                                data={piePeakData.data ? piePeakData.data : []}
                                                color={Chart_COLOR}
                                            />
                                        </Col>
                                    </>
                                )}
                            </Row>
                        </Loading>
                    </div>
                </Col>
            </Row>
            <Row style={{ marginBottom: '1rem' }}>
                <Col span={24}>
                    <Row
                        style={{
                            ...Style.module,
                            marginTop: 16,
                            width: '100%',
                            borderBottomLeftRadius: '0px',
                            borderBottomRightRadius: '0px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            lineHeight: '50px',
                            boxShadow: '0px 2px 3px #e7ebeb',
                            background: 'linear-gradient(180deg, #fefefe 0%, #f5f6fa 100%)',
                        }}
                    >
                        <Col
                            span={8}
                            style={{
                                ...Style.title,
                            }}
                        >
                            <MyIcon
                                type="icon_title_sankey"
                                style={{
                                    fontSize: '1.5rem',
                                    marginTop: '-0.1rem',
                                    verticalAlign: 'middle',
                                    paddingRight: '0.6rem',
                                    height: 50,
                                    lineHeight: '60px',
                                }}
                            />
                            能耗流向
                        </Col>
                        <Col
                            span={16}
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                lineHeight: '50px',
                            }}
                        >
                            <TimeSearch
                                id={id}
                                timeValue={toDay}
                                onValueChange={times => {
                                    if (!id && !isNumber(id)) {
                                        return;
                                    }
                                    const { time } = times;
                                    getSankeyDiagram(time);
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Row
                        style={{
                            ...Style.module,
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            borderTopLeftRadius: '0px',
                            borderTopRightRadius: '0px',
                            borderBottomLeftRadius: '10px',
                            borderBottomRightRadius: '10px',
                        }}
                    >
                        <Col style={{ height: 420, paddingTop: '1rem' }} span={24}>
                            <Loading show={sankeyDiagram.isLoading}>
                                {isEmpty(sankeyDiagram.data) ? (
                                    <EmptyView />
                                ) : (
                                    <SankeyChart rawData={sankeyDiagram.data} />
                                )}
                            </Loading>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Row
                        style={{
                            ...Style.module,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%',
                            borderBottomLeftRadius: '0px',
                            borderBottomRightRadius: '0px',
                            boxShadow: '0px 2px 3px #e7ebeb',
                            background: 'linear-gradient(180deg, #fefefe 0%, #f5f6fa 100%)',
                        }}
                    >
                        <Col
                            span={8}
                            style={{
                                ...Style.title,
                            }}
                        >
                            <MyIcon
                                type="icon_title_heatmap"
                                style={{
                                    fontSize: '1.5rem',
                                    marginTop: '-0.1rem',
                                    verticalAlign: 'middle',
                                    paddingRight: '0.6rem',
                                    height: 50,
                                    lineHeight: '60px',
                                }}
                            />
                            能耗热力图
                        </Col>
                        <Col span={16} style={{ display: 'flex', justifyContent: 'space-between', lineHeight: '50px' }}>
                            <TimeSearch
                                timeValue={day7}
                                id={id}
                                onValueChange={times => {
                                    if (!id && !isNumber(id)) {
                                        return;
                                    }
                                    const { time } = times;
                                    const day = moment(time[1]).diff(moment(time[0]), 'day');
                                    setDiffDay(day);
                                    // getHotChart([moment(time[0]).startOf('day'),moment(time[1]).add(1,'day').startOf('day')]);
                                    getHotChart(time);
                                }}
                                isDefault={false}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col
                    span={24}
                    style={{ backgroundColor: '#fff', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}
                >
                    <Row
                        style={{
                            ...Style.module,
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            borderTopLeftRadius: '0px',
                            borderTopRightRadius: '0px',
                            borderBottomLeftRadius: '10px',
                            borderBottomRightRadius: '10px',
                        }}
                    >
                        <Loading show={hotData.isLoading}>
                            <Col
                                style={{
                                    height: diffDay > 31 ? 300 : 37.5 * (diffDay < 2 ? 2 : diffDay),
                                    width: '100%',
                                }}
                            >
                                {isEmpty(hotData.data) ? (
                                    <EmptyView />
                                ) : (
                                    <>
                                        {hotType === 'd' ? (
                                            <CartesianThermogramChart rawData={hotData.data} />
                                        ) : (
                                            <HeatmapChart rawData={hotData.data} />
                                        )}
                                    </>
                                )}
                            </Col>
                        </Loading>
                    </Row>
                </Col>
            </Row>
        </>
    );
};
/**
 *
 * @onValueChange {*} 切换时间事件
 * @timeType {*} 筛选时间公共组件
 * @id {*}
 * @timeValue {*} 默认显示时间
 * @returns
 */
export const TimeSearch = ({
    onValueChange,
    timeType = ['toDay', 'day7', 'day30', 'month12', 'custom'],
    id,
    timeValue,
    isDefault,
    isShowButton,
    isDisabledDate,
    date
}) => {

    //1,6(近7日）,29(近30日）, 364(近12个月)
    // const [initializeDayRange] = useState(29);

    const [startDate, setStartDate] = useState(moment(timeValue[0] || toDay[0]));
    const [endDate, setEndDate] = useState(moment(timeValue[1] ||toDay[1]));

    // useEffect(()=>{
    //     console.log(`current startDate : ${startDate} , endDate : ${endDate}`);
    //     console.log(`左边界 : ${moment(endDate).subtract(29, 'day').format('YYYY-MM-DD')}`)
    //     console.log(`右边界 : ${moment(startDate).add(29, 'days').format('YYYY-MM-DD')}`)
    // },[startDate,endDate])


    const [value, setValue] = useState(timeValue || JSON.stringify(toDay));
    // const time = useRef([]);
    // const [open, setOpen] = useState();


    useEffect(() => {
        if (endDate < startDate) {
            console.log(`current endTimeStamp : ${endTimeStamp} , startTimeStamp : ${startTimeStamp}`);
            setStartDate(endDate);
        }
        const startTimeStamp = moment((endDate < startDate ? endDate : startDate)).startOf('day').valueOf()
        const endTimeStamp = moment(endDate).add(+1,'days').startOf('day').valueOf()
        console.log(`feed date to outside :  ${moment(startTimeStamp).format('YYYY-MM-DD')} ${moment(endTimeStamp).format('YYYY-MM-DD')}`);
        onValueChange({ time: [startTimeStamp,endTimeStamp] });
    }, [startDate, endDate]);

    // 时间组件限制
    // const disabledDate = current => {
    //     if (!dates || dates.length === 0) {
    //         return false;
    //     }
    //     const tooLate = dates[0] && current.diff(dates[0], 'days') < 1;
    //     const tooEarly = dates[1] && dates[1].diff(current, 'days') < 1;
    //     return tooEarly || tooLate;
    // };

    // useEffect(() => {
    //     if (!open && isBoolean(open)) {
    //         // console.log(`feed date to outside :  ${moment(time.current[0]).format('YYYY-MM-DD')} ${moment(time.current[1]).format('YYYY-MM-DD')}`);
    //         !isEmpty(time.current) && onValueChange({ time: time.current });
    //     }
    //     //eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [open]);
    useEffect(() => {
        !isDefault && setValue(JSON.stringify(timeValue));
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    return (
        <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: isShowButton ? 'flex-end' : 'space-between' }}>
            {!isShowButton && <Radio.Group
                style={{ marginTop: 6 }}
                value={value}
                onChange={e => {
                    setValue(e.target.value);
                    const time = JSON.parse(e.target.value);
                    if (moment(time[1]).diff(moment(time[0]), 'days') > 30) {
                        //12个月不影响右边的range,直接返回给外部
                        onValueChange({ time: [moment(time[0]).valueOf(),moment(time[1]).valueOf()] });
                    }else {
                        setStartDate(moment(time[0]));
                        setEndDate(moment(time[1]));
                    }
                }}
            >
                {includes(timeType, 'toDay') && <Radio.Button value={JSON.stringify(toDay)}>今日</Radio.Button>}
                {includes(timeType, 'day7') && <Radio.Button value={JSON.stringify(day7)}>近7日</Radio.Button>}
                {includes(timeType, 'day30') && <Radio.Button value={JSON.stringify(day30)}>近30日</Radio.Button>}
                {includes(timeType, 'month12') && <Radio.Button value={JSON.stringify(month12)}>近12月</Radio.Button>}
            </Radio.Group>}
            {includes(timeType, 'custom') && (
                <RangePicker
                    style={{ marginLeft: 16, marginRight: 16 }}
                    // onCalendarChange={val => setDates(val)}
                    showToday
                    onOpenChange={(open)=>{
                        // setOpen(open)
                    }}
                    onChange={(times, dateString) => {
                        // console.log(`callback date :  ${dateString}`);
                        // time.current = [moment(times[0]).startOf('day').valueOf(), moment(times[1]).add(1,'day').startOf('day').valueOf()];
                    }}
                    onCalendarChange={currentdate => {
                        console.log('trigger')
                        setStartDate(value =>
                            currentdate && currentdate[0] ? moment(currentdate[0]) : value
                        );
                        setEndDate(value =>
                            currentdate && currentdate[1] ? moment(currentdate[1]) : value
                        );
                    }}
                    value={[startDate,endDate]}
                    disabledDate={current =>
                        // 可选今天以前时间跨度30天以内的日期。
                        !(
                            current < moment().endOf('day') &&
                            current >= moment(endDate).subtract(29, 'day') &&
                            current <= moment(startDate).add(29, 'days')
                        )

                    }
                    allowClear={false}
                />
            )}
        </div>
    );
};

/**
 * 样式
 */
const Style = {
    title: {
        display: 'flex',
        width: '100%',
        height: '50px',
        lineHeight: '50px',
        fontSize: '1.2rem',
        color: '#333333',
        fontWeight: 500,
        padding: '0 1rem',
        verticalAlign: 'middle',
    },
    module: {
        background: '#ffffff',
        borderRadius: '10px',
        boxShadow: `0px 0px 3px 0px rgba(0,0,0,0.08)`,
    },
};
