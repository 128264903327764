import React from 'react';
import { Chart, Series } from '@echarts-start/react-bridge';
import { COLOR_PLATE_16 } from '../global';
export const InstrumentPanelChart = ({ data, type = 1 }) => {
    const maxValue = type === 1 ? 120 : 100;
    const splitNumber = type === 1 ? 3 : 4;
    const color =
        type === 1
            ? [
                  [0.33, '#58D9F9'],
                  [0.67, '#7CFFB2'],
                  [1, '#FF6E76'],
              ]
            : [
                  [0.25, '#FF6E76'],
                  [0.5, '#FDDD60'],
                  [0.75, '#58D9F9'],
                  [1, '#7CFFB2'],
              ];
    return (
        <Chart color={COLOR_PLATE_16} noCoordinate={true} resize style={{ width: '100%', height: '100%' }}>
            <Series
                options={{
                    type: 'gauge',
                    startAngle: 180,
                    radius: '90%',
                    endAngle: 0,
                    min: 0,
                    max: maxValue,
                    center: ['50%', '80%'],
                    splitNumber: splitNumber,
                    axisLine: {
                        lineStyle: {
                            width: 4,
                            color,
                        },
                    },
                    // axisLabel: {
                    //     color: 'auto'
                    // },内圈字体样式
                    pointer: {
                        width: 2,
                        length: '50%',
                        itemStyle: {
                            color: 'auto',
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        length: 12,
                        lineStyle: {
                            color: 'auto',
                            width: 1,
                        },
                    },
                    title: {
                        offsetCenter: [0, '18%'],
                        fontSize: 14,
                        color: '#ffffff',
                    },
                    detail: {
                        fontSize: 14,
                        offsetCenter: [0, '36%'],
                        valueAnimation: true,
                        formatter: function (value) {
                            return Math.round(value);
                        },
                        color: 'auto',
                    },
                    data: [
                        {
                            value: data.number,
                            name: data.name,
                        },
                    ],
                }}
            />

            <Series
                options={{
                    type: 'gauge',
                    startAngle: 180,
                    endAngle: 0,
                    splitNumber: 8,
                    min: 0,
                    max: maxValue,
                    radius: '100%',
                    center: ['50%', '80%'],
                    axisLine: {
                        lineStyle: {
                            width: 2,
                            color: [[1, '#018897']],
                        },
                    },
                    pointer: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    },
                    title: {
                        show: false,
                    },
                    axisLabel: {
                        color: '#ffffff',
                        fontSize: 14,
                        distance: -50,
                        formatter: function (value) {
                            let name = '';
                            if (type === 1) {
                                if (value === 105) {
                                    name = '偏热';
                                } else if (value === 60) {
                                    name = '舒适';
                                } else if (value === 15) {
                                    name = '偏冷';
                                }
                                return name;
                            }

                            if (value === 87.5) {
                                name = '优';
                            } else if (value === 62.5) {
                                name = '良';
                            } else if (value === 37.5) {
                                name = '中';
                            } else if (value === 12.5) {
                                name = '差';
                            }
                            return name;
                        },
                    },
                    detail: {
                        show: false,
                    },
                    data: [
                        {
                            value: data.number,
                            name: data.name,
                        },
                    ],
                }}
            />
        </Chart>
    );
};
