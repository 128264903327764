import React, { useState } from 'react';
import { Col, Row, Image } from 'antd';
import { isNull, isUndefined } from 'lodash';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
// import BMap from 'BMap';
import RouteHelper from '../../../tools/RouteHelper';
import { MyIcon } from '../../../util/iconfont';
import Img from '../../../assets/images/look.png';
import NoImg from '../../../assets/images/noImg.png';
import '../../../style/CommonSlider.less';

/*
 * 图片裁剪
 * image 图片地址
 * crop 图片需要的属性
 * fileName 文件名称
 */
const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY,
    );

    return new Promise((resolve, reject) => {
        canvas.toBlob(
            blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(this.fileUrl);
            },
            'image/jpeg',
            1,
        );
    });
};

export const Slider = ({ count, data, click, name, width, gutter, rotate }) => {
    const [crop, setCrop] = useState({ aspect: 16 / 9, width: 1080, unit: 'px' });
    const onCropComplete = (img, crop) => {
        makeClientCrop(img, crop);
    };
    async function makeClientCrop(img, crop) {
        if (this.imageRef && crop.width && crop.height) {
            img = await getCroppedImg(this.imageRef, crop, 'newFile.jpg');
            //   this.setState({ img });
        }
    }
    let src = false;
    /*
     * 根据经纬度解析地址名称
     * @parameter1 lng 传入的经度
     * @parameter2 lat 传入的纬度
     */
    var BMap = window.BMap;
    var map = new BMap.Map('allmap');
    const map_click = (lng, lat, index) => {
        var point = new BMap.Point(lng, lat);
        map.centerAndZoom(point, 12);
        var geoc = new BMap.Geocoder('allmap');
        geoc.getLocation(point, rs => {
            var addComp = rs.addressComponents;
            // alert(addComp.province  + addComp.city  + addComp.district + addComp.street + addComp.streetNumber);
            let location = addComp.province + addComp.city + addComp.district + addComp.street + addComp.streetNumber;
            // console.log(index, 'index');
            // console.log(location, 'location');
            document.getElementsByClassName('location')[index] &&
                (document.getElementsByClassName('location')[index].innerHTML = isUndefined(location)
                    ? '无'
                    : location);
        });
    };
    return (
        <div className="modal_box" style={{ marginBottom: '1.5rem' }}>
            <Row gutter={gutter || [8, 8]} style={{ width: width || '95%' }}>
                {data &&
                    data.map((item, index) => {
                        return (
                            <Col
                                span={count === 1 ? 24 : (count === 4 ? 12 : count === 9 ? 8 : 6)}
                                style={{ position: 'relative', overflow: 'hidden' }}
                                key={Math.random() * 10}
                                title={item.name}
                            >
                                {isNull(item.img) || isUndefined(item.img) ? (
                                    <div className="img_placeholder"><img title={`进入设备详情可查看更多历史数据`} src={NoImg} alt={'暂无图片'} style={{width: '100%', height: '100%'}}/></div>
                                ) : (
                                    <>
                                        <div
                                            className="img_wap"
                                            onClick={() => {
                                                click &&
                                                    // history.push({ pathname: '/web/monitor/device2/readDevice', state: { id: item.id } });
                                                    RouteHelper.locationTo('/web/monitor/device2/readDevice', {
                                                        id: item.id,
                                                    });
                                            }}
                                        >
                                            {src && (
                                                <ReactCrop
                                                    src={item.img}
                                                    crop={crop}
                                                    onComplete={() => onCropComplete(crop)}
                                                    onChange={newCrop => setCrop(newCrop)}
                                                    style={{ width: '100%', transform: `rotate(${rotate}deg)` }}
                                                />
                                            )}
                                            {item.img ? (
                                                <img
                                                    src={item.img}
                                                    alt={item.name}
                                                    title={`${item?.name || ''}\n进入设备详情可查看更多历史数据`}
                                                    onError={e => {
                                                        e.target.onerror = null;
                                                        e.target.src = `${NoImg}`;
                                                    }}
                                                    style={{transform: `rotate(${rotate}deg)`}}
                                                />
                                            ) : (
                                                <img src={NoImg} alt={'暂无影像数据'} title={'进入设备详情可查看更多历史数据'}/>
                                            )}
                                        </div>
                                        <Row className={'mengban mengbanTop'}>
                                            <Col span={12} className={'mengban_time'}>
                                                {item.time}
                                            </Col>
                                            <Col span={12} style={{ textAlign: 'right' }}>
                                                {item.temp_bat !== null && <span style={{margin: '0 5px'}} className={'mengban_time'}>{item.temp_bat}℃</span>}
                                                {item.rssi_raw === null ? <MyIcon
                                                    type={
                                                        item.rssi <= 0 || item.wifi_rssi <= 0
                                                            ? 'icon_xinhao0'
                                                            : (item.rssi < 116 || item.wifi_rssi < 116) && (item.rssi >= 99|| item.wifi_rssi >= 99)
                                                            ? 'icon_xinhao1'
                                                            : (item.rssi < 99 || item.wifi_rssi < 99) && (item.rssi >= 81 || item.wifi_rssi >= 81)
                                                            ? 'icon_xinhao2'
                                                            : (item.rssi < 81 || item.wifi_rssi < 81) && (item.rssi >= 63 || item.wifi_rssi >= 63)
                                                            ? 'icon_xinhao3'
                                                            : (item.rssi < 63 || item.wifi_rssi < 63) && (item.rssi >= 26 || item.wifi_rssi >= 26)
                                                            ? 'icon_xinhao4'
                                                            : item.rssi === 25 || item.wifi_rssi === 25
                                                            ? 'icon_xinhao5'
                                                            : 'icon_xinhao0'
                                                    }
                                                    style={{
                                                        fontSize: '1.2rem',
                                                        verticalAlign: 'middle',
                                                    }}
                                                /> : <MyIcon type={item.rssi_raw === -1 ? 'icon_xinhao0' : `icon_xinhao${item.rssi_raw}`}/>}
                                                <MyIcon
                                                    type={
                                                        item.voltage <= 0
                                                            ? 'icondianchi-meidian'
                                                            : item.voltage > 0 && item.voltage <= 25
                                                            ? 'icondianchi-didianliang'
                                                            : item.voltage > 50 && item.voltage <= 75
                                                            ? 'icondianchi-zhongdianliang'
                                                            : item.voltage > 75 && item.voltage <= 99
                                                            ? 'icondianchi-gaodianliang'
                                                            : item.voltage === 100
                                                            ? 'icondianchi-mandian'
                                                            : 'icondianchi-meidian'
                                                    }
                                                    style={{
                                                        fontSize: '1.4rem',
                                                        verticalAlign: 'middle',
                                                        color: '#fff',
                                                        margin: '0 0.2rem 0 0.4rem',
                                                    }}
                                                />
                                                {/* <MyIcon
                                                    type="iconfangdajing"
                                                    style={{
                                                        fontSize: '1.4rem',
                                                        verticalAlign: 'middle',
                                                        color: '#fff',
                                                    }}
                                                    onClick={() => {
                                                        window.open(`${item.img}`); //打开新窗口查看大图
                                                    }}
                                                /> */}
                                                <Image
                                                    width={22}
                                                    src={Img}
                                                    preview={{
                                                        src: item.img,
                                                    }}
                                                    style={{ verticalAlign: 'middle', display: 'inline-block' }}
                                                    alt={item?.name || name}
                                                    title={`${item?.name || name}\n进入设备详情可查看更多历史数据`}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className={'mengban mengbanBottom'}>
                                            <Col span={16} className="mengban_location">
                                                <MyIcon
                                                    type={'iconweizhi1'}
                                                    className="icon"
                                                    style={{ color: '#fff', fontSize: '1.3rem' }}
                                                />
                                                <span className="location" id="address">
                                                    {/* {map_click(item.longitude, item.latitude, index)} */}
                                                    {/* <div id='allmap'>{map_click('103.964119', '30.736832', index)}</div> */}
                                                    {map_click(item.longitude === null ? 0 : item.longitude, item.latitude === null ? 0 : item.latitude, index)}
                                                    {/* {map_click('103.964119', '30.736832', index)} */}
                                                </span>
                                            </Col>
                                            <Col span={8} className="mengban_name">
                                                {item.name || name}
                                            </Col>
                                        </Row>
                                    </>
                                )}
                            </Col>
                        );
                    })}
            </Row>
        </div>
    );
};
