import React from 'react';
import { Chart, Series, YAxis, XAxis, Legend, Dataset, Tooltip, Grid, DataZoom } from '@echarts-start/react-bridge';
import { isEmpty } from 'lodash';
import * as echarts from 'echarts';
import { peakvalue, peakTime } from '../../../../util/function-util';

export const ReportBarEchart = ({ rawData, color, style, unit, showColor }) => {
    return (
        <Chart color={color} style={{ width: '100%', height: style.height || '100%' }} resize={true}>
            <Dataset source={rawData} dimensions={rawData[0]} />
            <Legend
                options={{
                    // top: '2%',
                    bottom: '5%',
                    icon: 'circle',
                    itemWidth: 10,
                    textStyle: {
                        color: '#595959',
                        fontSize: 12,
                    },
                }}
            />
            <Grid
                options={{
                    // bottom: '15%',
                    // left: '5%',
                    // right: '5%',
                    top: '8%',
                }}
            />
            <Tooltip
                options={{
                    trigger: 'axis',
                }}
            />
            <YAxis
                options={{
                    // name: 'kW·h',
                    // type: 'value',
                    type: 'category',
                    data:
                        rawData &&
                        rawData.map(item => {
                            return item.name || peakTime(item.time);
                        }),
                }}
            />
            <XAxis
                options={{
                    type: 'value',
                    // type: 'category',
                    name: unit,
                    // data: rawData && rawData.map(item => {
                    //     return item.name || peakTime(item.time);
                    // }),
                    axisLabel: {
                        //x轴文字的配置
                        show: true,
                        //interval: 0, //使x轴文字显示全
                    },
                }}
            />
            <DataZoom
                options={{
                    type: 'inside', //inside slider
                    show: true, //flase直接隐藏图形
                    xAxisIndex: [0],
                    left: '1%', //滚动条靠左侧的百分比
                    start: 0, //滚动条的起始位置
                    end: 100, //滚动条的截止位置（按比例分割你的柱状图x轴长度）
                    orient: 'vertical',
                }}
            />
            {/* {map(servers, (_, idx) => ( */}
            <Series
                // key={idx}
                options={{
                    barGap: 0,
                    type: 'bar',
                    barMaxWidth: 15,
                    barMinWidth: 10,
                    itemStyle: {
                        // bordRadius: [6, 6, 0, 0],
                        borderRadius: [0, 6, 6, 0],
                        opacity: 0.8,
                        color: showColor && new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            { offset: 0, color: '#18CEC7' },
                            { offset: 1, color: '#66EAE0' },
                        ]),
                    },
                    data:
                        rawData &&
                        rawData.map(item => {
                            return !isEmpty(item.value) ? peakvalue(item.value) : peakvalue(item.amount);
                        }),
                }}
            />
            {/* ))} */}
        </Chart>
    );
};
