import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Divider, Form, Input, Popconfirm, Row, Space, Table, Typography } from 'antd';
import { CommonModal } from '../../../components/CommoModal';
import { BreadcrumbCustom } from '../../../components/BreadcrumbCustom';
import { addFunction, editFunction, getFunctionList, removeFunction } from '../../../api/webServe';
import { toDate } from '../../../util/timeUtil';
import ToastHelper from '../../../tools/ToastHelper';
import { commonPageSize } from '../../../global';
import { MyIcon } from '../../../util/iconfont';

const FormItem = Form.Item;
export const ClassificationManagement = () => {
    const childRef = useRef();
    const [form] = Form.useForm();
    const ref = useRef(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [dataSource, setDataSource] = useState({ data: [], isLoading: true });
    const [current, setCurrent] = useState(null);
    const [modalType, setModalType] = useState('add'); //add edit
    useEffect(() => {
        if (modalType === 'edit') {
            form.setFieldsValue({ name: current.name });
            return;
        }
        form.setFieldsValue({ name: '' });
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [current, modalType]);
    useEffect(() => {
        getList();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const getList = (page = 1) => {
        setDataSource({ ...dataSource, isLoading: true });
        setCurrentPage(page);
        getFunctionList({ page, page_size: ref.current ? ref.current.pageSize : commonPageSize })
            .then(result => {
                setTotal(result.count);
                setDataSource({ data: result.data, isLoading: false });
            })
            .catch(() => {
                setDataSource({ data: [], isLoading: false });
            });
    };
    const edit = params => {
        editFunction({ ...params, id: current.id })
            .then(() => {
                ToastHelper.successToast('编辑成功');
                getList();
                childRef.current.hiddenModal();
            })
            .catch();
    };
    const add = params => {
        addFunction(params)
            .then(() => {
                ToastHelper.successToast('新增成功');
                getList();
                childRef.current.hiddenModal();
            })
            .catch(() => {
                childRef.current.hiddenModal();
            });
    };
    const remove = id => {
        removeFunction(id)
            .then(() => {
                ToastHelper.successToast('删除成功');
                getList();
            })
            .catch();
    };
    const columns = [
        {
            title: '序号',
            dataIndex: 'idx',
            width: 100,
            key: 'idx',
            render: (_, __, idx) => idx + 1,
        },
        {
            title: '功能名称',
            dataIndex: 'name',
        },
        {
            title: '创建时间',
            dataIndex: 'create_time',
            render: time => {
                return <span>{toDate(time)}</span>;
            },
        },
        {
            title: '操作',
            dataIndex: 'operate',
            render: (_, item) => {
                return (
                    <>
                        {item.built_in ? (
                            <span>-</span>
                        ) : (
                            <Space split={<Divider type="vertical" />} key={item}>
                                {/*<Typography.Link className="opera">*/}
                                {/*    <span className="read" style={{ padding: '0 0.6rem' }}>*/}
                                {/*        查看*/}
                                {/*    </span>*/}
                                {/*</Typography.Link>*/}
                                <Typography.Link className="opera">
                                    {/* <span
                                        className="edit"
                                        onClick={() => {
                                            setCurrent(item);
                                            setModalType('edit');
                                            childRef.current.showModal();
                                        }}
                                    >
                                        编辑
                                    </span> */}
                                    <MyIcon
                                        type={'icona-zu6914'}
                                        style={{
                                            fontSize: '1rem',
                                            verticalAlign: 'middle',
                                            color: 'rgba(0, 0, 0, 0.85)',
                                        }}
                                        title={'编辑'}
                                        onClick={() => {
                                            setCurrent(item);
                                            setModalType('edit');
                                            childRef.current.showModal();
                                        }}
                                    />
                                </Typography.Link>
                                <Typography.Link className="opera">
                                    <Popconfirm
                                        title="删除该功能呢？"
                                        okText="删除"
                                        cancelText="取消"
                                        onConfirm={() => {
                                            remove(item.id);
                                        }}
                                    >
                                        {/* <span style={{ color: '#fff', padding: '0 0.6rem' }} className="del">
                                            删除
                                        </span> */}
                                        <MyIcon
                                            type="iconshanchu1"
                                            style={{
                                                color: 'rgba(0, 0, 0, 0.85)',
                                                fontSize: '1rem',
                                                verticalAlign: 'middle',
                                            }}
                                            title={'删除'}
                                        />
                                    </Popconfirm>
                                </Typography.Link>
                            </Space>
                        )}
                    </>
                );
            },
        },
    ];
    return (
        <div>
            <BreadcrumbCustom paths={['首页', '监测管理', '功能管理']} className="rumbCustom" />
            <div
                style={{
                    margin: '1rem',
                    backgroundColor: '#ffffff',
                    height: 'calc(100vh - 150px)',
                    padding: '0 1rem',
                    borderRadius: '10px',
                }}
            >
                <Row gutter={24} style={{ width: '100%' }}>
                    <Col span={24}>
                        <div style={{ padding: '20px 0' }}>
                            <Button
                                onClick={() => {
                                    setModalType('add');
                                    childRef.current.showModal();
                                }}
                                type="primary"
                            >
                                新增功能
                            </Button>
                        </div>
                        <Table
                            rowKey={record => record.id}
                            bordered
                            loading={dataSource.isLoading}
                            onChange={page => {
                                ref.current = page;
                                getList(page.current);
                            }}
                            pagination={{
                                defaultPageSize: commonPageSize,
                                total,
                                current: currentPage,
                                pageSizeOptions: [10, 20, 30, 50, 100],
                                showTotal: total => `共 ${total} 条`,
                            }}
                            scroll={{ y: 'calc(100vh - 360px)' }}
                            dataSource={dataSource.data}
                            columns={columns}
                        />
                    </Col>
                </Row>
            </div>
            <CommonModal
                title={modalType === 'add' ? '新增功能' : '编辑功能'}
                childRef={childRef}
                onOk={() => {
                    form.validateFields()
                        .then(values => {
                            if (modalType === 'add') {
                                add({ name: values.name });
                            }
                            if (modalType === 'edit') {
                                edit({ name: values.name });
                            }
                        })
                        .catch(() => {
                            childRef.current.endLoading();
                        });
                }}
            >
                <Form form={form} initialValues={{}}>
                    <FormItem label="功能名称" name="name" rules={[{ required: true, message: '请输入功能名称' }]}>
                        <Input placeholder="请输入功能名称" />
                    </FormItem>
                </Form>
            </CommonModal>
        </div>
    );
};
