import { parse, stringify } from 'querystring';
let history = null;

function setHistory(_history) {
    history = _history;
}

/**
 * 界面跳转
 * @param action    '/somewhere'
 * @param search    '?some=search-string'
 */
function locationTo(action, query = {}) {
    const s = stringify(query);
    const search = s ? `?${stringify(query)}` : '';
    let aim = { pathname: action, search };
    history && history.push(aim);
}

/**
 * 替换当前页面
 * @param action
 * @param search
 */
function locationReplace(action, query) {
    const s = stringify(query);
    const search = s ? `?${stringify(query)}` : '';
    let aim = { pathname: action, search };
    history && history.replace(aim);
}

/**
 * 返回上级
 */
function locationBack() {
    history && history.goBack();
}

/**
 * 获取当前页面url中的参数
 * @param name
 * @returns {}
 */

function parseSearchString() {
    if (history) {
        let search = history.location.search;
        if (search.startsWith('?')) {
            search = search.slice(1);
        }
        return parse(search);
    }
    return '';
}

/**
 * 打开新页面
 * @param action
 * @param target
 */
// function navigateTo(action, target) {
//     const openWindow = window.open(action, target);
// }

// export default {
//     setHistory,
//     locationTo,
//     locationReplace,
//     locationBack,
//     parseSearchString,
// };
const exports = {
    setHistory,
    locationTo,
    locationReplace,
    locationBack,
    parseSearchString,
}
export default exports;
