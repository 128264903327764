import React, { useEffect, useRef, useState } from 'react';
import {
    Button,
    Col,
    Divider,
    Form,
    Input,
    Popconfirm,
    Row,
    Space,
    Table,
    Typography,
    Switch,
    InputNumber,
    Tooltip,
    TreeSelect,
} from 'antd';
import { map } from 'lodash';
import { BreadcrumbCustom } from '../../../components/BreadcrumbCustom';
import { getRelation, addRelation, editRelation, removeRelation, getRelationTree } from '../../../api/webServe';
import { CommonModal } from '../../../components/CommoModal';
import { MyIcon } from '../../../util/iconfont';
import { toDate } from '../../../util/timeUtil';
import ToastHelper from '../../../tools/ToastHelper';
import { commonPageSize, switchText } from '../../../global';

const FormItem = Form.Item;
const { SHOW_PARENT } = TreeSelect;

// 关联设置
export const Relation = () => {
    const childRef = useRef();
    const [form] = Form.useForm();
    const ref = useRef(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [dataSource, setDataSource] = useState({ data: [], isLoading: true });
    const [current, setCurrent] = useState(null);
    const [modalType, setModalType] = useState('add'); //add edit
    useEffect(() => {
        if (modalType === 'edit') {
            let ret_deviceData = [];
            let ret_facilityData = [];
            current &&
                current.ret_device.forEach((item, index) => {
                    return ret_deviceData.push(item.topic_id);
                });
            current &&
                current.ret_facility.forEach((item, index) => {
                    return ret_facilityData.push(item.topic_id);
                });
            form.setFieldsValue({
                name: current.name,
                trigger: current.trigger,
                appoint: current.appoint,
                ret_device: ret_deviceData,
                ret_facility: ret_facilityData,
            });
            return;
        }
        form.setFieldsValue({ name: '', trigger: undefined, appoint: '', ret_device: [], ret_facility: [] });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [current, modalType]);
    useEffect(() => {
        getList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const getList = (page = 1) => {
        setDataSource({ ...dataSource, isLoading: true });
        setCurrentPage(page);
        getRelation({ page, page_size: ref.current ? ref.current.pageSize : commonPageSize })
            .then(result => {
                setTotal(result.count);
                setDataSource({ data: result.data, isLoading: false });
            })
            .catch(() => {
                setDataSource({ data: [], isLoading: false });
            });
    };
    const edit = params => {
        editRelation({ ...params, id: current.id })
            .then(() => {
                ToastHelper.successToast('编辑成功');
                getList();
                form.resetFields();
                childRef.current.hiddenModal();
            })
            .catch();
    };
    const add = params => {
        addRelation(params)
            .then(() => {
                ToastHelper.successToast('新增成功');
                getList();
                form.resetFields();
                childRef.current.hiddenModal();
            })
            .catch(() => {
                childRef.current.hiddenModal();
            });
    };
    const remove = id => {
        removeRelation(id)
            .then(() => {
                ToastHelper.successToast('删除成功');
                getList();
            })
            .catch();
    };
    const columns = [
        {
            title: '序号',
            dataIndex: 'idx',
            width: 100,
            key: 'idx',
            render: (_, __, idx) => idx + 1,
        },
        {
            title: '关联名称',
            dataIndex: 'name',
        },
        {
            title: '生产设备',
            dataIndex: 'ret_device',
            render: (text, index) => {
                const newStr = text ? text.map(({ name }) => `${name}`).join('、') : '—';
                return (
                    <Tooltip title={<span key={newStr}>{newStr}</span>}>
                        <div
                            style={{
                                minWidth: '6rem',
                                maxWidth: '20rem',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                margin: '0 auto',
                            }}
                        >
                            {newStr}
                        </div>
                    </Tooltip>
                );
            },
        },
        {
            title: '排污设备',
            dataIndex: 'ret_facility',
            render: (text, index) => {
                const newStr = text ? text.map(({ name }) => `${name}`).join('、') : '—';
                return (
                    <Tooltip title={<span key={newStr}>{newStr}</span>}>
                        <div
                            style={{
                                minWidth: '6rem',
                                maxWidth: '20rem',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                margin: '0 auto',
                            }}
                        >
                            {newStr}
                        </div>
                    </Tooltip>
                );
            },
        },
        {
            title: '时效性',
            dataIndex: 'appoint',
            render: text => <span>{text + '分钟'}</span>,
        },
        {
            title: (
                <div>
                    <Tooltip title="开启后，根据执行条件判断是否异常，如果异常上报数据；关闭后，不上报任何执行条件的数据。">
                        <span style={{ verticalAlign: 'middle' }}>状态&nbsp;</span>
                        <MyIcon
                            type="icon_tishi"
                            style={{ verticalAlign: 'middle', fontSize: '1rem', color: '#666666' }}
                        />
                    </Tooltip>
                </div>
            ),
            dataIndex: 'status',
            width: 105,
            render: (_, record) => {
                return (
                    <Switch
                        {...switchText}
                        onClick={value => {
                            editRelation({
                                status: value === true ? 0 : 1,
                                id: record.id,
                                device_list:
                                    record.ret_device &&
                                    record.ret_device.map(item => {
                                        return item.topic_id;
                                    }),
                                facility_list:
                                    record.ret_facility &&
                                    record.ret_facility.map(item => {
                                        return item.topic_id;
                                    }),
                                name: record.name,
                            })
                                .then(() => {
                                    ToastHelper.successToast(`${value ? '开启' : '关闭'}成功`);
                                    getList();
                                })
                                .catch();
                        }}
                        checked={record.status === 0 ? true : false}
                        size="small"
                    />
                );
            },
        },
        {
            title: '创建时间',
            dataIndex: 'create_time',
            render: time => {
                return <span>{toDate(time)}</span>;
            },
        },
        {
            title: '操作',
            dataIndex: 'operate',
            render: (_, item) => {
                return (
                    <Space split={<Divider type="vertical" />} key={item}>
                        <Typography.Link className="opera">
                            <MyIcon
                                type={'icona-zu6914'}
                                style={{
                                    fontSize: '1rem',
                                    verticalAlign: 'middle',
                                    color: 'rgba(0, 0, 0, 0.85)',
                                }}
                                title={'编辑'}
                                onClick={() => {
                                    setCurrent(item);
                                    setModalType('edit');
                                    childRef.current.showModal();
                                }}
                            />
                        </Typography.Link>
                        <Typography.Link className="opera">
                            <Popconfirm
                                title="删除该关联呢？"
                                okText="删除"
                                cancelText="取消"
                                onConfirm={() => {
                                    remove(item.id);
                                }}
                            >
                                <MyIcon
                                    type="iconshanchu1"
                                    style={{
                                        color: 'rgba(0, 0, 0, 0.85)',
                                        fontSize: '1rem',
                                        verticalAlign: 'middle',
                                    }}
                                    title={'删除'}
                                />
                            </Popconfirm>
                        </Typography.Link>
                    </Space>
                );
            },
        },
    ];

    const recursion = data => {
        return map(data, item => {
            return {
                ...item,
                key: item.id,
                title: item.name,
                children: recursion(item.children),
                disabled: `${item.pid}` !== 'undefined' ? false : true,
            };
        });
    };
    const [allTree, setAllTree] = useState([]);
    useEffect(() => {
        getRelationTree()
            .then(result => {
                setAllTree(recursion(result.data));
            })
            .catch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // let data = []
    // const onChange = (value, label, extra) => {
    //     data.push({id: extra.triggerValue, devId: extra.triggerNode.props.pid+ '-'+ extra.triggerValue, selected: extra.selected})
    //     if(!extra.selected){
    //         let newData = data.filter(item => item.id !== extra.triggerValue)
    //         data = newData
    //     }
    //   };
    return (
        <div>
            <BreadcrumbCustom paths={['系统设置', '关联设置']} className="rumbCustom" />
            <div
                style={{
                    margin: '1rem',
                    backgroundColor: '#ffffff',
                    height: 'calc(100vh - 150px)',
                    padding: '0 1rem',
                    borderRadius: '10px',
                }}
            >
                <Row gutter={24} style={{ width: '100%' }}>
                    <Col span={24}>
                        <div style={{ padding: '20px 0' }}>
                            <Button
                                onClick={() => {
                                    setModalType('add');
                                    childRef.current.showModal();
                                    // data = [];
                                }}
                                type="primary"
                            >
                                新增关联
                            </Button>
                        </div>
                        <Table
                            bordered
                            loading={dataSource.isLoading}
                            onChange={page => {
                                ref.current = page;
                                getList(page.current);
                            }}
                            pagination={{
                                defaultPageSize: commonPageSize,
                                total,
                                current: currentPage,
                                pageSizeOptions: [10, 20, 30, 50, 100],
                                showTotal: total => `共 ${total} 条`,
                            }}
                            scroll={{ y: 'calc(100vh - 360px)' }}
                            dataSource={dataSource.data}
                            columns={columns}
                            rowKey={record => record.id}
                        />
                    </Col>
                </Row>
            </div>
            <CommonModal
                title={modalType === 'add' ? '新增关联' : '编辑关联'}
                childRef={childRef}
                onOk={() => {
                    form.validateFields()
                        .then(values => {
                            if (modalType === 'add') {
                                add({
                                    name: values.name,
                                    appoint: values.appoint,
                                    device_list: values.ret_device,
                                    // facility_list: data.map(item => { return item.devId; }),
                                    facility_list: values.ret_facility,
                                });
                            }
                            if (modalType === 'edit') {
                                edit({
                                    name: values.name,
                                    appoint: values.appoint,
                                    device_list: values.ret_device,
                                    facility_list: values.ret_facility,
                                });
                            }
                        })
                        .catch(() => {
                            childRef.current.endLoading();
                        });
                }}
            >
                <Form form={form}>
                    <FormItem
                        label="关联名称"
                        name="name"
                        rules={[
                            { required: true, message: '请输入关联名称' },
                            {
                                pattern: /^.{1,30}$/,
                                message: '关联名称长度为1-30位字符!',
                            },
                        ]}
                    >
                        <Input placeholder="请输入关联名称" style={{ width: '350px' }} />
                    </FormItem>
                    <FormItem
                        label="生产设备"
                        name="ret_device"
                        rules={[{ required: true, message: '请选择生产设备' }]}
                    >
                        <Space>
                            <FormItem name="ret_device" noStyle>
                                <TreeSelect
                                    multiple
                                    treeData={recursion(allTree)}
                                    showCheckedStrategy={SHOW_PARENT}
                                    dropdownStyle={{ maxHeight: 350, overflow: 'auto' }}
                                    placeholder="请选择生产设备"
                                    style={{ width: '350px' }}
                                />
                            </FormItem>
                            <Tooltip title="需全部选择生产设备，否则会降低报警准确率。">
                                <MyIcon
                                    type="icon_tishi"
                                    style={{
                                        verticalAlign: 'middle',
                                        fontSize: '1.2rem',
                                        marginRight: '0.7rem',
                                        color: '#666666',
                                    }}
                                />
                            </Tooltip>
                        </Space>
                    </FormItem>
                    <FormItem
                        label="排污设备"
                        name="ret_facility"
                        rules={[{ required: true, message: '请选择排污设备' }]}
                    >
                        <Space>
                            <FormItem name="ret_facility" noStyle>
                                <TreeSelect
                                    multiple
                                    treeData={recursion(allTree)}
                                    showCheckedStrategy={SHOW_PARENT}
                                    dropdownStyle={{ maxHeight: 350, overflow: 'auto' }}
                                    placeholder="请选择排污设备"
                                    style={{ width: '350px' }}
                                    // onChange={onChange}
                                />
                            </FormItem>
                            <Tooltip title="需全部选择排污设备，否则会降低报警准确率。">
                                <MyIcon
                                    type="icon_tishi"
                                    style={{
                                        verticalAlign: 'middle',
                                        fontSize: '1.2rem',
                                        marginRight: '0.7rem',
                                        color: '#666666',
                                    }}
                                />
                            </Tooltip>
                        </Space>
                    </FormItem>
                    <FormItem
                        label={'时效选择'}
                        name="appoint"
                        rules={[
                            { required: true, message: '请选择时效性' },
                            { pattern: new RegExp(/^[1-9]\d*$/, 'g'), message: '只能输入数字并且为整数' },
                        ]}
                    >
                        <Space>
                            <FormItem name="appoint" noStyle>
                                <InputNumber
                                    min={1}
                                    max={120}
                                    precision={0}
                                    placeholder="请选择时效性"
                                    style={{ width: '318px' }}
                                />
                            </FormItem>
                            <span>分钟</span>
                            <Tooltip title="在时效范围内(1~120分钟)，如生产与排污设备状态不一致，系统不会上报隐患消息。">
                                <MyIcon
                                    type="icon_tishi"
                                    style={{
                                        verticalAlign: 'middle',
                                        fontSize: '1.2rem',
                                        marginRight: '0.7rem',
                                        color: '#666666',
                                    }}
                                />
                            </Tooltip>
                        </Space>
                    </FormItem>
                </Form>
            </CommonModal>
        </div>
    );
};
