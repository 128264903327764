import React, { useState, useRef, useMemo, useEffect } from 'react';
import { map } from 'lodash';
import { Table, Button, Popconfirm, Row, Col, Switch, Space, Typography, Divider } from 'antd';
import { GlobalModal } from '../../../components/GlobalModal';
import { EditRole } from './EditRole';
import { editUser, deleteUser, getUserList } from '../../../api/adminServe';
import ToastHelper from '../../../tools/ToastHelper';
import { commonPageSize, switchText } from '../../../global';
import { toDate } from '../../../util/timeUtil';
import { BreadcrumbCustom } from '../../../components/BreadcrumbCustom';
import { MyIcon } from '../../../util/iconfont';

const RoleManagement = () => {
    const childRef = useRef();
    const [modalType, setModalType] = useState('add'); //add 新增  //edit 编辑  //ready 查看
    const ref = useRef(null);
    const [userId, setUserId] = useState(0);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [dataSource, setDataSource] = useState([]);
    const userList = (page) => {
        setCurrentPage(page);
        getUserList({ page, page_size: ref.current ? ref.current.pageSize : commonPageSize })
            .then(result => {
                setTotal(result.count);
                result = map(result.data, item => {
                    return { ...item, key: item.id };
                });
                setDataSource(result);
            })
            .catch();
    };
    useEffect(() => {
        userList(currentPage);
    }, []);
    const title = useMemo(() => {
        if (modalType === 'add') {
            return '新增角色';
        }
        if (modalType === 'edit') {
            return '编辑角色';
        }
        return '查看角色';
    }, [modalType]);
    const columns = useMemo(() => {
        return [
            {
                title: '序号',
                dataIndex: 'idx',
                width: 100,
                render: (_, __, index) => {
                    return <span>{index + 1}</span>;
                },
            },
            {
                title: '角色名称',
                dataIndex: 'name',
                render: text => (
                    <p
                        style={{
                            minWidth: '5rem',
                            maxWidth: '16rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={text}
                    >
                        {text}
                    </p>
                ),
            },
            {
                title: '角色描述',
                dataIndex: 'desc',
                render: text => (
                    <p
                        style={{
                            minWidth: '5rem',
                            maxWidth: '16rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={text}
                    >
                        {text}
                    </p>
                ),
            },
            {
                title: '开启状态',
                width: 100,
                dataIndex: 'is_active',
                render: (_, record) => {
                    return (
                        <Switch
                            {...switchText}
                            checked={record.is_active}
                            size="small"
                            onClick={value => {
                                editUser({ id: record.id, is_active: value })
                                    .then(() => {
                                        ToastHelper.successToast(`${value ? '开启' : '关闭'}成功`);
                                        userList(currentPage);
                                    })
                                    .catch();
                            }}
                        />
                    );
                },
            },
            {
                title: '创建时间',
                dataIndex: 'create_time',
                width: 200,
                render: time => {
                    return toDate(time);
                },
            },
            {
                title: '操作',
                dataIndex: 'operation',
                width: 180,
                render: (_, item) => {
                    return (
                        <Space split={<Divider type="vertical" />}>
                            <Typography.Link
                                className="opera"
                                onClick={() => {
                                    setModalType('ready');
                                    setUserId(item.id);
                                    childRef.current.showModal();
                                }}
                            >
                                <MyIcon
                                    type={'iconchakan1'}
                                    style={{ fontSize: '1rem', verticalAlign: 'middle', color: 'rgba(0, 0, 0, 0.85)' }}
                                    title={'查看'}
                                />
                            </Typography.Link>
                            <Typography.Link
                                className="opera"
                                onClick={() => {
                                    setModalType('edit');
                                    setUserId(item.id);
                                    childRef.current.showModal();
                                }}
                            >
                                <MyIcon
                                    type={'icona-zu6914'}
                                    style={{ fontSize: '1rem', verticalAlign: 'middle', color: 'rgba(0, 0, 0, 0.85)' }}
                                    title={'编辑'}
                                />
                            </Typography.Link>
                            <Typography.Link className="opera">
                                <Popconfirm
                                    title="删除该角色？"
                                    okText="删除"
                                    cancelText="取消"
                                    onConfirm={() => {
                                        deleteUser(item.id)
                                            .then(result => {
                                                ToastHelper.successToast('删除成功');
                                                userList(currentPage);
                                            })
                                            .catch();
                                    }}
                                >
                                    <MyIcon
                                        type="iconshanchu1"
                                        style={{
                                            color: 'rgba(0, 0, 0, 0.85)',
                                            fontSize: '1rem',
                                            verticalAlign: 'middle',
                                        }}
                                        title={'删除'}
                                    />
                                </Popconfirm>
                            </Typography.Link>
                        </Space>
                    );
                },
            },
        ];
    }, [currentPage]);
    return (
        <div style={{ width: '100%', height: '100%' }}>
            <BreadcrumbCustom paths={['角色管理']} className="rumbCustom" />
            <Row
                style={{
                    margin: '1rem',
                    backgroundColor: '#ffffff',
                    height: 'calc(100vh - 150px)',
                    padding: '20px',
                    borderRadius: '10px',
                }}
            >
                <Col span={24}>
                    <Button
                        onClick={() => {
                            setModalType('add');
                            childRef.current.showModal();
                        }}
                        type="primary"
                        style={{
                            marginBottom: 16,
                        }}
                    >
                        添加角色
                    </Button>
                    <Table
                        bordered
                        dataSource={dataSource}
                        onChange={page => {
                            ref.current = page;
                            userList(page.current);
                        }}
                        columns={columns}
                        pagination={{
                            defaultPageSize: commonPageSize,
                            total,
                            current: currentPage,
                            pageSizeOptions: [10, 20, 30, 50, 100],
                            showTotal: total => `共 ${total} 条`,
                        }}
                        scroll={{ y: 'calc(100vh - 360px)' }}
                    />
                </Col>
            </Row>
            <GlobalModal
                title={title}
                childRef={childRef}
                onOk={() => {
                    if (modalType === 'edit') {
                        userList(currentPage);
                    }
                }}
                footer={null}
            >
                <EditRole type={modalType} userId={userId} setDataSource={setDataSource} />
            </GlobalModal>
        </div>
    );
};
export default RoleManagement;
