/*
 * @Descripttion:折线图组件(多条折线图)
 * @Author: lipei
 * @Date: 2020-05-Ju 15:46:06
 * @Last Modified by:   lipei
 * @Last Modified time: 2021-05-Ju 15:46:06
 */
import React, { useMemo } from 'react';
import ReactEcharts from 'echarts-for-react';
import { map } from 'lodash';
import { unitControl } from '../../util/function-util';

/**
 * MoreLineEchart 健康报表中的三相电流
 * @param {*} rawData  数据集
 * @param {*} color 颜色集
 * @param {*} rawData  单位
 * @param {*} rawData  名称
 * @returns
 */
export const MoreLineEchart = ({ rawData, color, name }) => {
    const servers = useMemo(() => {
        return new Array(rawData[0].length - 1);
    }, [rawData]);
    const getOption = () => {
        const option = {
            color: color,
            legend: {
                top: '5%',
                left: '80%',
                icon: 'circle',
                itemWidth: 10,
                textStyle: {
                    color: '#595959',
                    fontSize: 12,
                },
            },
            grid: {
                bottom: '10%',
                left: '5%',
                right: '5%',
            },
            animationType: 'scale',
            animationEasing: 'cubicInOut', //cubicInOut elasticOut  progressive
            animationEasingUpdate: 'cubicInOut',
            animationDelay: function (idx) {
                return Math.random() * 200;
            },
            animationDuration: 1000,
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#B3CDE3',
                    },
                },
                formatter: function (datas) {
                    var res = datas[0].name + '<br/>';
                    for (var i = 0, length = datas.length; i < length; i++) {
                        res += datas[i].seriesName + '：' + datas[i].data[i + 1] + ' ' + unitControl[name] + '<br/>';
                    }
                    return res;
                },
            },
            dataset: {
                source: rawData,
            },
            xAxis: {
                type: 'category',
                axisLabel: {
                    show: true,
                    //interval: 0, //使x轴文字显示全
                },
            },
            yAxis: [
                {
                    name: `单位：(${unitControl[name] || 'kW·h'})`,
                    type: 'value',
                    min: 0,
                    position: 'left',
                },
            ],
            dataZoom: [
                {
                    type: 'inside', //inside slider
                    show: true, //flase直接隐藏图形
                    xAxisIndex: [0],
                    start: 0, //滚动条的起始位置
                    // end: rawData.length > 40 ? 10 : 50, //滚动条的截止位置（按比例分割你的柱状图x轴长度）
                    end: 100, //滚动条的截止位置（按比例分割你的柱状图x轴长度）
                    orient: 'vertical',
                },
            ],
            series: map(servers, (_, idx) => {
                return {
                    key: idx,
                    type: 'line',
                    barMaxWidth: 15,
                    smooth: true, //线条的平滑 平滑曲线
                };
            }),
        };
        return option;
    };
    let option = getOption();
    return <ReactEcharts option={option} style={{ width: '100%', height: '100%' }} resize={true} />;
};
