import React, { useState, useEffect } from 'react';
import { Row, Col, Select, DatePicker } from 'antd';
import { isEmpty, isUndefined, map } from 'lodash';
import SurveyList from './SurveyList';
import Power from './PowerSurvey';
import QuickTag from './QuickTag';
import Echartforecast from './Echart/echartForecast';
import EchartBar from '../../../components/Echart/OneBarEchart';
import GradeEchart from './Echart/echartGrade';
import Message from './Message';
import { getPermissions } from '../../../tools/StorageHelper';
import {
    getEnterprise_profile,
    getAlarmStatistic,
    getRunStatus,
    getElectricityOver,
    getTrend,
    getMessage,
    workbenchCategory,
} from '../../../api/webServe';
import { MyIcon } from '../../../util/iconfont';
import { alarmLevel } from '../../../global';
import { Loading } from '../../../components/Loading';
import { EmptyView } from '../../../components/EmptyView'; //空数据占位组件
import { peakvalue } from '../../../util/function-util';
import moment from 'moment';
import { toDay, disabledDate_30day } from '../../../util/timeUtil';

const { Option } = Select;
/**
 * 工作台组件
 */
const Workbench = () => {
    const [data, setData] = useState({ data: null, isLoading: true });
    const [trendData, setTrendData] = useState({ data: null, isLoading: true });
    const [alarmStatistic, setAlarmStatistic] = useState({ data: null, isLoading: true });
    const [runStatus, setRunStatus] = useState({ data: null, isLoading: true });
    const [electricityOver, setElectricityOver] = useState({ data: null, isLoading: true });
    const [messages, setMessages] = useState({ data: null, isLoading: true });
    const [category, setCategory] = useState([]);
    const [categoryValue, setCategoryValue] = useState(category && (category.length > 0 ? category[0] : undefined));
    const [time, setTime] = useState(moment(toDay[0]));
    const onChange = (value) => {
        setTime(value.valueOf());
    };
    const getData = () => {
        getEnterprise_profile()
            .then(result => {
                setData({ data: result.data, isLoading: false });
            })
            .catch(() => {
                setData({ data: null, isLoading: false });
            });
        getAlarmStatistic()
            .then(result => {
                setAlarmStatistic({ data: result, isLoading: false });
            })
            .catch(() => {
                setAlarmStatistic({ data: null, isLoading: false });
            });
        getRunStatus()
            .then(result => {
                setRunStatus({ data: result.data, isLoading: false });
            })
            .catch(() => {
                setRunStatus({ data: null, isLoading: false });
            });
        getElectricityOver()
            .then(result => {
                setElectricityOver({ data: result.data, isLoading: false });
            })
            .catch(() => {
                setElectricityOver({ data: null, isLoading: false });
            });
        getMessage()
            .then(result => {
                setMessages({ data: result.data, isLoading: false });
            })
            .catch(() => {
                setMessages({ data: null, isLoading: false });
            });
    };

    useEffect(() => {
        getData();
    }, []);
    useEffect(() => {
        workbenchCategory()
            .then(result => {
                setCategory(result.data);
                setCategoryValue(result.data && result.data[0]);
            })
            .catch();
    }, []);

    useEffect(() => {
        if (!isUndefined(categoryValue)) {
            setTrendData({ data: null, isLoading: true });
            getTrend({ category: categoryValue, start_time: time })
                .then(result => {
                    setTrendData({ data: result.data, isLoading: false });
                })
                .catch(() => {
                    setTrendData({ data: null, isLoading: false });
                });
        }
    }, [categoryValue, time]);
    let yData = [],
        xTime = [];
    trendData.data &&
        trendData.data.total.forEach(item => {
            yData.push(peakvalue(item.amount));
            xTime.push(item.time);
        });
    let dataPath =
        getPermissions() != 'undefined' &&
        JSON.parse(getPermissions()).filter(item => {
            return item.name === '监测管理';
        });
    let show = dataPath.length > 0 ? true : false;
    return (
        <div className="container clearfix">
            <div
                className="row clearfix"
                style={{
                    backgroundColor: 'transparent',
                    justifyContent: 'center',
                    width: '100%',
                    paddingLeft: '0px',
                    paddingRight: '1rem',
                }}
            >
                <Row style={{ width: '100%' }} gutter={32}>
                    <Col
                        className="gutter-row"
                        xxl={8}
                        xl={12}
                        lg={12}
                        md={24}
                        sm={24}
                        xs={24}
                        style={{ marginBottom: '1rem', paddingRight: '0px' }}
                    >
                        <Power.PowerList rawData={electricityOver} />
                    </Col>
                    <Col
                        className="gutter-row"
                        xxl={8}
                        xl={12}
                        lg={12}
                        md={24}
                        sm={24}
                        xs={24}
                        style={{ marginBottom: '1rem', paddingRight: '0px' }}
                    >
                        <SurveyList rawData={data} title={'企业概况'} />
                    </Col>
                    <Col
                        className="gutter-row"
                        xxl={8}
                        xl={24}
                        lg={24}
                        md={24}
                        sm={24}
                        xs={24}
                        style={{ marginBottom: '1rem', paddingRight: '0px' }}
                    >
                        <Power.PowerSurvey rawData={runStatus} />
                    </Col>
                </Row>
                <Row style={{ width: '100%', height: '70vh' }} gutter={32}>
                    <Col
                        className="gutter-row"
                        xxl={15}
                        xl={15}
                        md={24}
                        style={{ marginBottom: '1rem', paddingLeft: '16px', paddingRight: '0px' }}
                    >
                        <Row style={{ backgroundColor: 'transparent' }}>
                            <Col className="gutter-row" span={24} style={{ marginBottom: show ? '1rem' : '0rem' }}>
                                {show && <QuickTag />}
                            </Col>
                            <Col
                                className="gutter-row"
                                span={24}
                                style={{
                                    backgroundColor: '#fff',
                                    borderRadius: '10px',
                                    minHeight: show ? '490px' : '617px',
                                }}
                            >
                                <span className="common_title">
                                    <MyIcon
                                        type="icon_power_current"
                                        style={{
                                            fontSize: '1.5rem',
                                            marginTop: '-0.1rem',
                                            verticalAlign: 'middle',
                                            paddingRight: '0.6rem',
                                        }}
                                    />
                                    运行状态
                                </span>
                                <>
                                    <Select
                                        placeholder="请选择"
                                        virtual={false}
                                        showArrow
                                        onFocus={() => {
                                            workbenchCategory()
                                                .then(result => {
                                                    setCategory(result.data);
                                                })
                                                .catch();
                                        }}
                                        value={category && category.length > 0 ? categoryValue : undefined}
                                        style={{
                                            position: 'absolute',
                                            margin: '0.5rem',
                                            zIndex: 999,
                                            width: '11rem',
                                            left: '0rem',
                                            top: '3.2rem',
                                        }}
                                        onChange={e => {
                                            setCategoryValue(e);
                                        }}
                                        onSelect={e => {
                                            setCategoryValue(e);
                                        }}
                                    >
                                        {map(category, (value, key) => {
                                            return (
                                                <Option value={value} key={key}>
                                                    {value}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                    <DatePicker
                                        onChange={onChange}
                                        format="yyyy-MM-DD"
                                        style={{
                                            height: '30px',
                                            fontSize: '12px',
                                            position: 'absolute',
                                            margin: '0.5rem',
                                            zIndex: 999,
                                            width: '11rem',
                                            right: '0rem',
                                            top: '3.2rem',
                                        }}
                                        value={moment(time)}
                                        allowClear={false}
                                        disabledDate={disabledDate_30day}
                                    />
                                    <Loading show={trendData.isLoading}>
                                        {isEmpty(trendData.data && trendData.data.total) ? (
                                            <EmptyView />
                                        ) : categoryValue === '总有功电能' ? (
                                            <Echartforecast
                                                predictions={trendData.data}
                                                style={{ height: show ? '47.5vh' : '61vh' }}
                                                title={'运行状态'}
                                                names={categoryValue}
                                                unit={trendData.data.unit}
                                            />
                                        ) : (
                                            <EchartBar
                                                yData={yData}
                                                xTime={xTime}
                                                names={categoryValue}
                                                style={{ height: '93%' }}
                                                top={'8px'}
                                                bottom={'5%'}
                                                unit={trendData.data.unit}
                                                right={'188'}
                                                legendRight={'300'}
                                            />
                                        )}
                                    </Loading>
                                </>
                            </Col>
                        </Row>
                    </Col>
                    <Col
                        className="gutter-row"
                        xxl={9}
                        xl={9}
                        md={24}
                        style={{ marginBottom: '1rem', paddingRight: '16px', paddingLeft: '32px' }}
                    >
                        <Row gutter={32}>
                            <Col
                                className="gutter-row"
                                span={24}
                                style={{
                                    paddingRight: '0',
                                    paddingLeft: '0px',
                                    height: '260px',
                                    background: '#fff',
                                    borderRadius: '10px',
                                }}
                            >
                                <div
                                    style={{
                                        backgroundColor: '#fff',
                                        borderRadius: '10px',
                                        height: '82%',
                                    }}
                                >
                                    <span className="common_title">
                                        <MyIcon
                                            type="icon_alrm_statistics"
                                            style={{
                                                fontSize: '1.5rem',
                                                marginTop: '-0.1rem',
                                                verticalAlign: 'middle',
                                                paddingRight: '0.6rem',
                                            }}
                                        />
                                        今日告警
                                    </span>
                                    <Loading show={alarmStatistic.isLoading}>
                                        {isEmpty(alarmStatistic.data) ? (
                                            <EmptyView />
                                        ) : (
                                            <Row gutter={32}>
                                                <Col offset={1} span={12}>
                                                    <GradeEchart
                                                        title={'今日告警'}
                                                        style={{ height: '13.3rem' }}
                                                        data={alarmStatistic.data.data}
                                                        total={alarmStatistic.data.total}
                                                    />
                                                </Col>
                                                <Col span={11} className="grade">
                                                    <p>
                                                        <span className="grade_name">告警总量</span>
                                                        <span className="grade_value_total">
                                                            <span
                                                                className="grade_number"
                                                                title={alarmStatistic.data.total}
                                                            >
                                                                {alarmStatistic.data.total || 0}
                                                            </span>
                                                            <span className="grade_company">次</span>
                                                        </span>
                                                    </p>
                                                    {alarmStatistic.data.data &&
                                                        alarmStatistic.data.data.map(item => {
                                                            return (
                                                                <p key={item.grade}>
                                                                    <span
                                                                        className={
                                                                            'grade_name' +
                                                                            ' ' +
                                                                            `grade_name_dot${item.grade}`
                                                                        }
                                                                    >
                                                                        {alarmLevel[item.grade]}
                                                                    </span>
                                                                    <span className="grade_value">
                                                                        <span
                                                                            className="grade_number"
                                                                            title={item.number}
                                                                        >
                                                                            {item.number || 0}
                                                                        </span>
                                                                        <span className="grade_company">次</span>
                                                                    </span>
                                                                </p>
                                                            );
                                                        })}
                                                </Col>
                                            </Row>
                                        )}
                                    </Loading>
                                </div>
                            </Col>
                            <Col
                                className="gutter-row"
                                span={24}
                                style={{ marginTop: '1rem', paddingRight: '0', paddingLeft: '0px', height: '100%' }}
                            >
                                <Message messages={messages} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default Workbench;
