import React, { useEffect, useMemo, useState } from 'react';
import { BreadcrumbCustom } from '../../../components/BreadcrumbCustom';
import { Col, Row, Radio, Tabs, message, Select } from 'antd';
import { isEmpty, map, isNumber } from 'lodash';
import { CommonTree } from '../../../components/CommonTree';
import { ChartStatistics } from './Chart/ChartStatistics';
import { TimeSearch } from './ECRCondition';
import { Loading } from '../../../components/Loading';
import { EmptyView } from '../../../components/EmptyView';
import { colorsRanking, selectTop } from '../../../global';
import { toDay } from '../../../util/timeUtil';
import {
    getAreaList,
    getRegionFunctionList,
    getRegionFunction,
    getRegionDevices,
    getFunctionRegion,
} from '../../../api/webServe';
import lessStyle from './eCRCondition.module.less';

const { TabPane } = Tabs;
const { Option } = Select;
/**
 * 能耗分析 - 能耗排行
 * @returns
 */
export const ECRRanking = () => {
    return (
        <div className={'container clearfix ' + lessStyle.containers}>
            <BreadcrumbCustom paths={['首页', '能耗分析', '能耗排行']} className="rumbCustom" />
            <div className="row clearfix" style={{ backgroundColor: 'transparent', margin: '1rem', padding: '0' }}>
                <Tabs type="card" style={{ width: '100%' }}>
                    <TabPane tab="按区域统计" key={1}>
                        <ClassificationStatistics />
                    </TabPane>
                    <TabPane tab="按功能统计" key={2}>
                        <AreaStatistics />
                    </TabPane>
                    <TabPane tab="按设备统计" key={3}>
                        <DeviceStatistics />
                    </TabPane>
                </Tabs>
            </div>
        </div>
    );
};

/**
 *
 * @returns 按区域统计
 */
const ClassificationStatistics = () => {
    const [categoryInfo, setCategoryInfo] = useState();
    return (
        <Row style={{ marginLeft: '0rem', width: '100%', height: '78vh' }} gutter={32}>
            <Col
                span={4}
                className="gutter-row"
                style={{
                    padding: '1rem',
                    background: '#ffffff',
                    borderRadius: '10px',
                    height: '78vh',
                }}
            >
                <ClassificationList onSelect={info => setCategoryInfo(info)} />
            </Col>
            <Col
                span={20}
                style={{
                    borderRadius: '10px',
                    boxSizing: 'border-box',
                    paddingRight: '0',
                }}
            >
                <ChartArea categoryInfo={categoryInfo} isCategory={true} />
            </Col>
        </Row>
    );
};

/**
 *
 * @returns 按功能统计
 */
const AreaStatistics = () => {
    const [areaInfo, setAreaInfo] = useState();
    const [treeData, setTreeData] = useState([]);
    useEffect(() => {
        getAreaList()
            .then(result => {
                setTreeData(result.data);
            })
            .catch();
    }, []);
    return (
        <Row style={{ marginLeft: '0rem', width: '100%', height: '78vh' }} gutter={32}>
            <Col
                span={4}
                className="gutter-row"
                style={{
                    padding: '1rem',
                    background: '#ffffff',
                    borderRadius: '10px',
                    height: '78vh',
                }}
            >
                <CommonTree
                    treeData={treeData}
                    operable={false}
                    onClick={info => {
                        if (!areaInfo || areaInfo.id !== info.id) {
                            setAreaInfo(info);
                        }
                    }}
                />
            </Col>
            <Col
                span={20}
                style={{
                    borderRadius: '10px',
                    boxSizing: 'border-box',
                    paddingRight: '0',
                }}
            >
                <ChartFunction areaInfo={areaInfo} />
            </Col>
        </Row>
    );
};

/**
 *
 * @returns 按设备统计
 */
const DeviceStatistics = () => {
    const [areaInfo, setAreaInfo] = useState();
    const [treeData, setTreeData] = useState([]);
    useEffect(() => {
        getAreaList()
            .then(result => {
                setTreeData(result.data);
            })
            .catch();
    }, []);
    return (
        <Row style={{ marginLeft: '0rem', width: '100%', height: '78vh' }} gutter={32}>
            <Col
                span={4}
                className="gutter-row"
                style={{
                    padding: '1rem',
                    background: '#ffffff',
                    borderRadius: '10px',
                    height: '78vh',
                }}
            >
                <CommonTree
                    treeData={treeData}
                    operable={false}
                    onClick={info => {
                        if (!areaInfo || areaInfo.id !== info.id) {
                            setAreaInfo(info);
                        }
                    }}
                />
            </Col>
            <Col
                span={20}
                style={{
                    borderRadius: '10px',
                    boxSizing: 'border-box',
                    paddingRight: '0',
                }}
            >
                <ChartDevice areaInfo={areaInfo} />
            </Col>
        </Row>
    );
};

/**
 * 左侧查看类型筛选（全部、照明、插座）
 * @onSelect {*} 默认选中项
 * @returns
 */
const ClassificationList = ({ onSelect }) => {
    const [category, setCategory] = useState('');
    const [buttons, setButtons] = useState([]);
    useEffect(() => {
        getRegionFunctionList()
            .then(result => {
                // setButtons([{ id: 0, name: '全部' }, ...result.data]);
                setButtons([...result.data]);
                const defaultValue = 0;
                setCategory(defaultValue);
                onSelect({ id: defaultValue });
            })
            .catch(error => {
                message.error(error);
            });
            //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div style={{ padding: 16 }}>
            {map(buttons, item => (
                <Radio.Group
                    key={item.id}
                    onChange={e => {
                        const id = e.target.value;
                        setCategory(id);
                        onSelect({ id });
                    }}
                    style={{ display: 'flex', flexDirection: 'column' }}
                    value={category}
                    size="large"
                >
                    <Radio.Button
                        value={item.id}
                        style={{
                            marginBottom: 20,
                            textAlign: 'center',
                            borderRadius: 5,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {item.name}
                    </Radio.Button>
                </Radio.Group>
            ))}
        </div>
    );
};

/**
 * 右侧图表组件
 * @areaInfo {*} 区域
 * @categoryInfo {*} 类别
 * @isCategory {*} 是否选中类型
 * @returns
 */

//区域统计图表
const ChartArea = ({ areaInfo, categoryInfo, isCategory }) => {
    const id = useMemo(() => {
        if (isEmpty(areaInfo) && isEmpty(categoryInfo)) {
            return null;
        }
        if (!isEmpty(areaInfo)) {
            return areaInfo.id;
        }
        return categoryInfo.id;
    }, [areaInfo, categoryInfo]);
    const [barData, setBarData] = useState({ isLoading: true, data: null });
    const [top, setTop] = useState(10);
    const [datatime, setDatatime] = useState(toDay);
    const getBarData = () => {
        setBarData({ data: null, isLoading: true });
        getFunctionRegion({ id, top, start_time: datatime[0], end_time: datatime[1], isCategory })
            .then(result => {
                setBarData({ isLoading: false, data: isEmpty(result.data) ? null : result.data });
            })
            .catch(() => {
                setBarData({ data: null, isLoading: false });
            });
    };
    useEffect(() => {
        if (!id && !isNumber(id)) {
            return;
        }
        getBarData();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, top, datatime]);
    const handleChange = value => {
        setTop(value);
        // getBarData();
    };
    return (
        <Row style={{ height: '100%' }}>
            <Col span={24}>
                <div style={{ ...Style.module }}>
                    <Row
                        style={{
                            height: 50,
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            lineHeight: '50px',
                            borderTopLeftRadius: '10px',
                            borderTopRightRadius: '10px',
                            boxShadow: '0px 2px 3px #e7ebeb',
                            background: 'linear-gradient(180deg, #fefefe 0%, #f5f6fa 100%)',
                            paddingLeft: '1rem',
                        }}
                    >
                        <Col span={8}>
                            <Select value={top} onChange={handleChange} virtual={false}>
                                {selectTop.map(item => {
                                    return (
                                        <Option value={item.id} key={item.id}>
                                            {item.value}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Col>
                        <Col
                            span={16}
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginTop: '-0.1rem',
                            }}
                        >
                            <TimeSearch
                                timeValue={toDay}
                                onValueChange={times => {
                                    if (!id && !isNumber(id)) {
                                        return;
                                    }
                                    const { time } = times;
                                    setDatatime(time);
                                    // getBarData(time);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row
                        style={{
                            height: '72vh',
                            width: '100%',
                        }}
                    >
                        <Col span={24} style={{ height: '100%' }}>
                            <Loading show={barData.isLoading}>
                                {isEmpty(barData.data) ? (
                                    <EmptyView />
                                ) : (
                                    <ChartStatistics rawData={barData.data} color={colorsRanking.region} unit={'kWh'}/>
                                )}
                            </Loading>
                        </Col>
                        {/* {!isEmpty(barData.data) ? (
                            <Col span={24} style={{ padding: '1rem', color: '#aaa', fontSize: '12px' }}>
                                注：数据未显示完时,图表可上下拖动查看全部
                            </Col>
                        ) : (
                            ''
                        )} */}
                    </Row>
                </div>
            </Col>
        </Row>
    );
};

//功能统计图表
const ChartFunction = ({ areaInfo, categoryInfo, isCategory }) => {
    const id = useMemo(() => {
        if (isEmpty(areaInfo) && isEmpty(categoryInfo)) {
            return null;
        }
        if (!isEmpty(areaInfo)) {
            return areaInfo.id;
        }
        return categoryInfo.id;
    }, [areaInfo, categoryInfo]);
    const [barData, setBarData] = useState({ isLoading: true, data: null });
    const [top, setTop] = useState(10);
    const [datatime, setDatatime] = useState(toDay);
    const getBarData = () => {
        setBarData({ data: null, isLoading: true });
        getRegionFunction({ id, top, start_time: datatime[0], end_time: datatime[1], isCategory })
            .then(result => {
                setBarData({ isLoading: false, data: isEmpty(result.data) ? null : result.data });
            })
            .catch(() => {
                setBarData({ data: null, isLoading: false });
            });
    };
    useEffect(() => {
        if (!id && !isNumber(id)) {
            return;
        }
        getBarData();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, datatime, top]);
    const handleChange = value => {
        setTop(value);
    };
    return (
        <Row style={{ height: '100%' }}>
            <Col span={24}>
                <div style={{ ...Style.module }}>
                    <Row
                        style={{
                            height: 50,
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            lineHeight: '50px',
                            paddingLeft: '1rem',
                            borderTopLeftRadius: '10px',
                            borderTopRightRadius: '10px',
                            boxShadow: '0px 2px 3px #e7ebeb',
                            background: 'linear-gradient(180deg, #fefefe 0%, #f5f6fa 100%)',
                        }}
                    >
                        <Col span={8}>
                            <Select value={top} onChange={handleChange} virtual={false}>
                                {selectTop.map(item => {
                                    return (
                                        <Option value={item.id} key={item.id}>
                                            {item.value}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Col>
                        <Col
                            span={16}
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginTop: '-0.1rem',
                            }}
                        >
                            <TimeSearch
                                timeValue={toDay}
                                onValueChange={times => {
                                    if (!id && !isNumber(id)) {
                                        return;
                                    }
                                    const { time } = times;
                                    setDatatime(time);
                                    // getBarData(time);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row
                        style={{
                            height: '72vh',
                            width: '100%',
                        }}
                    >
                        <Col span={24} style={{ height: '100%' }}>
                            <Loading show={barData.isLoading}>
                                {isEmpty(barData.data) ? (
                                    <EmptyView />
                                ) : (
                                    <ChartStatistics rawData={barData.data} color={colorsRanking.function} unit={'kWh'}/>
                                )}
                            </Loading>
                        </Col>
                        {/* {!isEmpty(barData.data) ? (
                            <Col span={24} style={{ padding: '1rem', color: '#aaa', fontSize: '12px' }}>
                                注：数据未显示完时,图表可上下拖动查看全部
                            </Col>
                        ) : (
                            ''
                        )} */}
                    </Row>
                </div>
            </Col>
        </Row>
    );
};

//设备统计图表
const ChartDevice = ({ areaInfo, categoryInfo, isCategory }) => {
    const id = useMemo(() => {
        if (isEmpty(areaInfo) && isEmpty(categoryInfo)) {
            return null;
        }
        if (!isEmpty(areaInfo)) {
            return areaInfo.id;
        }
        return categoryInfo.id;
    }, [areaInfo, categoryInfo]);
    const [barData, setBarData] = useState({ isLoading: true, data: null });
    const [top, setTop] = useState(10);
    const [datatime, setDatatime] = useState(toDay);
    const getBarData = () => {
        setBarData({ data: null, isLoading: true });
        getRegionDevices({ id, top, start_time: datatime[0], end_time: datatime[1], isCategory })
            .then(result => {
                setBarData({ isLoading: false, data: isEmpty(result.data) ? null : result.data });
            })
            .catch(() => {
                setBarData({ data: null, isLoading: false });
            });
    };
    useEffect(() => {
        if (!id && !isNumber(id)) {
            return;
        }
        getBarData();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, datatime, top]);
    const handleChange = value => {
        setTop(value);
    };
    return (
        <Row style={{ height: '100%' }}>
            <Col span={24}>
                <div style={{ ...Style.module }}>
                    <Row
                        style={{
                            height: 50,
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            lineHeight: '50px',
                            paddingLeft: '1rem',
                            borderTopLeftRadius: '10px',
                            borderTopRightRadius: '10px',
                            boxShadow: '0px 2px 3px #e7ebeb',
                            background: 'linear-gradient(180deg, #fefefe 0%, #f5f6fa 100%)',
                        }}
                    >
                        <Col span={8}>
                            <Select value={top} onChange={handleChange} virtual={false}>
                                {selectTop.map(item => {
                                    return (
                                        <Option value={item.id} key={item.id}>
                                            {item.value}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Col>
                        <Col
                            span={16}
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginTop: '-0.1rem',
                            }}
                        >
                            <TimeSearch
                                timeValue={toDay}
                                onValueChange={times => {
                                    if (!id && !isNumber(id)) {
                                        return;
                                    }
                                    const { time } = times;
                                    // getBarData(time);
                                    setDatatime(time);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row
                        style={{
                            height: '72vh',
                            width: '100%',
                        }}
                    >
                        <Col span={24} style={{ height: '100%' }}>
                            <Loading show={barData.isLoading}>
                                {isEmpty(barData.data) ? (
                                    <EmptyView />
                                ) : (
                                    <ChartStatistics rawData={barData.data} color={colorsRanking.device} unit={'kWh'}/>
                                )}
                            </Loading>
                        </Col>
                        {/* {!isEmpty(barData.data) ? (
                            <Col span={24} style={{ padding: '1rem', color: '#aaa', fontSize: '12px' }}>
                                注：数据未显示完时,图表可上下拖动查看全部
                            </Col>
                        ) : (
                            ''
                        )} */}
                    </Row>
                </div>
            </Col>
        </Row>
    );
};

/**
 * 样式
 */
const Style = {
    title: {
        display: 'flex',
        width: '100%',
        height: '50px',
        lineHeight: '50px',
        fontSize: '1.2rem',
        color: '#595959',
        fontWeight: 500,
        padding: '0 1rem',
        verticalAlign: 'middle',
    },
    module: {
        background: '#ffffff',
        borderRadius: '10px',
        boxShadow: `0px 0px 3px 0px rgba(0,0,0,0.08)`,
    },
};
