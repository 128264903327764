import React, { Component } from 'react';
import ReactEcharts from 'echarts-for-react';

export default class GradeEchart extends Component {
    // constructor(props) {
    //     super(props);
    // }
    componentDidMount() {}

    getOption = () => {
        const { data } = this.props;
        let newData =
            data === undefined
                ? []
                : data.map(item => {
                      let name = '异常';
                      let title, detail, itemStyle;
                      if (item.grade === 0) {
                          name = '异常';
                          title = {
                              offsetCenter: ['0%', '-50%'],
                          };
                          detail = {
                              offsetCenter: ['0%', '-30%'],
                          };
                          itemStyle = {
                              color: '#F7CD85',
                          };
                      } else if (item.grade === 1) {
                          name = '高危';
                          title = {
                              offsetCenter: ['0%', '-10%'],
                          };
                          detail = {
                              offsetCenter: ['0%', '10%'],
                          };
                          itemStyle = {
                              color: '#EF9076',
                          };
                      } else if (item.grade === 2) {
                          name = '故障';
                          title = {
                              offsetCenter: ['0%', '30%'],
                          };
                          detail = {
                              offsetCenter: ['0%', '50%'],
                          };
                          itemStyle = {
                              color: '#C7C7C7',
                          };
                      }
                      return {
                          name: name,
                          grade: item.grade,
                          value: item.proportion === null || item.proportion === undefined ? 0 : item.proportion,
                          proportion: item.number,
                          title: title,
                          detail: detail,
                          itemStyle: itemStyle,
                      };
                  });
        let option = {
            animationType: 'scale',
            animationEasing: 'elasticOut',
            animationDelay: function (idx) {
                return Math.random() * 200;
            },
            series: [
                {
                    type: 'gauge',
                    startAngle: 90,
                    endAngle: -270,
                    pointer: {
                        show: false,
                    },
                    progress: {
                        //展示当前进度
                        show: true,
                        overlap: false,
                        roundCap: true,
                        clip: false,
                        itemStyle: {
                            borderWidth: 0,
                            borderColor: 'inherit',
                        },
                    },
                    axisLine: {
                        lineStyle: {
                            width: 28, //进度条宽度
                        },
                    },
                    splitLine: {
                        show: false,
                        distance: 0,
                        length: 10,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        show: false,
                        distance: 10,
                    },
                    data: newData,
                    title: {
                        fontSize: 10,
                        color: '#595959',
                    },
                    detail: {
                        width: 20,
                        height: 5,
                        fontSize: 10,
                        color: 'inherit',
                        borderColor: 'inherit',
                        borderRadius: 10,
                        borderWidth: 0.5,
                        formatter: '{value}%',
                    },
                    animation: true,
                },
            ],
        };
        return option;
    };
    render() {
        const option = this.getOption();
        return (
            <ReactEcharts
                ref={ref => (this.echarts = ref)}
                option={option}
                style={this.props.style}
                className="react_for_echarts"
            />
        );
    }
}
