/**
 * @@@@@@@@@@ 后端ip
 * http://web.finsiot.com:8888      徐哥(后端)
 * http://192.168.1.117:8000        邓行(后端)
 * http://192.168.1.2:8000          井松(测试)
 * https://power.finsiot.com        线上数据
 * http://dashboard.finsiot.com     测试环境
 * window.location.origin           根据前端URL动态使用
 */

export const BASE_URL = window.location.origin;
export const commonPageSize = 20; //每页展示数量
export const isDevelop = false; //判断使用后端的路由配置还是前端的路由配置（权限控制）

export const switchText = { checkedChildren: '开启', unCheckedChildren: '关闭' }; //switch 文字
//1、qrCode:条形码, 2、model:型号 3、name:名称  4、point:监测点 5、function:功能 6、gateway 网关 7、temperature:温度阈值 8、humidity:湿度阈值 power:最大功率 multicenter:通道配置
export const projectEquipment = {
    F0: ['qrCode', 'model', 'point', 'name'], //网关         无线通信网关
    F1: [], //开关面板            RF433无线微动开关
    F2: ['qrCode', 'model', 'point', 'name', 'gateway'], //烟感         无线烟感节点
    F3: ['qrCode', 'model', 'point', 'name', 'gateway', 'function'], //单路控制器    无线开关收发控制端节点
    F4: ['qrCode', 'model', 'point', 'name', 'gateway', 'temperature', 'humidity'], //温湿度计     无线温湿度节点
    F5: ['qrCode', 'model', 'point', 'name', 'gateway', 'function', 'power'], //插座        //无线墙壁插座
    F6: ['qrCode', 'model', 'point', 'name', 'gateway'], //TEG节点 //工业电机F601
    F7: ['qrCode', 'model', 'point', 'name', 'function', 'multicenter'], //多路控制器            //多路集中控制开关接收器
    F8: ['qrCode', 'model', 'point', 'name', 'multicenter'], //互感器     //多路互感器采集设备
    F9: ['qrCode', 'model', 'point', 'name'], //摄像头
    FB: ['qrCode', 'model', 'point', 'name', 'gateway','multiple'], //CT互感器   //新互感器,无需单独供电的设备
    FC: ['qrCode', 'model', 'point', 'name', 'gateway'], //母排
    FE: ['qrCode', 'model', 'point', 'name', 'gateway'], //CT电表
    E1: ['qrCode', 'model', 'point', 'name'], //门磁
    E2: ['qrCode', 'model', 'point', 'name'], //燃气报警器
};

//左侧区域树中使用到的设备图标
export const treeIcon = {
    F0: 'icon_tree_gateway',
    F1: 'icon_tree_switch_controller',
    F2: 'icon_tree_smoke',
    F3: 'icon_tree_single_channel_controller',
    F4: 'icon_tree_sensor',
    F5: 'icon_tree_socket_selected',
    F6: 'icon_tree_dianji',
    F7: 'icon_tree_multiple_controllers',
    F8: 'icon_tree_transformer',
    F9: 'icon_tree_camera',
    FA: 'icona-zu6947',
    FB: 'icon_tree_transformer',
    FE: 'icon_tree_dianbiao',
    E1: 'icon_tree_menci',
    FC: 'icon_tree_mupai',
    E2: 'icon_tree_tianranqi',
};

export const COLOR_PLATE_16 = [
    '#1890FF',
    '#41D9C7',
    '#2FC25B',
    '#FACC14',
    '#E6965C',
    '#223273',
    '#7564CC',
    '#8543E0',
    '#5C8EE6',
    '#13C2C2',
    '#5CA3E6',
    '#3436C7',
    '#B381E6',
    '#F04864',
    '#D598D9',
];

//export const PieChart_COLOR = ['#4BC890', '#F4BE40', '#72D0DC', '#678AA6', '#A5DAD4', '#21BEb2'];
// export const colorTest = ['#55cd9f', '#5688ed', '#FDD56A', '#FDB36A', '#73ACFF', '#FD866A', '#9E87FF', '#58D5FF'];
//export const gradeColor = ['#63DAAA', '#F0502E', '#F8C685', '#00e473', '#009DFF', '#0034ff']; //隐患等级颜色 '#FF8700', '#ffc300', '#00e473', '#009DFF', '#0034ff'
//export const colorPower = ['#72D0DC', '#4BC890', '#E98164', '#F0C33F', '#6188A9', '#6394F9', '#7666F9', '#58D5FF'];

export const PieChart_COLOR3 = ['#4BC890', '#678AA6', '#91D2CC', '#F4BE40', '#A5DAD4', '#21BEb2']; //用能分布类型

export const HistogramChart_COLOR1 = ['#678AA6', '#31BEB7']; //用能对比(昨日今日)

export const HistogramChart_COLOR2 = [
    '#678AA6',
    '#5AD9A6',
    '#CDF3E4',
    '#C4E5F8',
    '#F4BE40',
    '#FCEAB8',
    '#30BA98',
    '#65ADCC',
    '#31BEB7',
]; //用能趋势（功能区域）

export const COLOR3 = [
    '#4BC890',
    '#21ab88',
    '#30BA98',
    '#5AD9A6',
    '#31BEB7',
    '#21BEb2',
    '#81D5CC',
    '#61D5CC',
    '#72D0DC',
    '#A5DAD4',
]; //能耗流向气泡图

export const HeatmapChart_COLOR = ['#C6DBF0', '#9CCAE2', '#4AA0CE']; //能耗热力图

export const PieChart_COLOR2 = [
    '#119ADA ',
    '#17C1BF',
    '#4C689B',
    '#DC5F4F',
    '#55CD9F',
    '#AB90DF',
    '#E9B316',
    '#F29961',
    '#ac5e1F',
]; //监测大厅告警统计

export const BarChart_COLOR = ['#43A8F0', '#0077C0', '#F0502E', '#FFD457', '#00e473']; //监测大厅设备统计

export const Chart_COLOR = ['#65ADCC', '#C4E5F8', '#5AD9A6', '#F0C33F', '#21CFF8']; //峰谷平尖用电

export const Chart_COLOR2 = ['#65ADCC', '#C4E5F8', '#5AD9A6', '#21CFF8']; //峰谷平用电

export const colorsRanking = { region: ['#1ACFC7'], function: ['#62D4E2'], device: ['#F0CD67'] }; //能耗排行中区域 功能 设备

export const colorAlarm = ['#F7CD85', '#EF9076', '#C7C7C7', '#73ACFF', '#58D5FF', '#55cd9f']; //告警等级

//功率因数
export const colorList = [
    '#74CAED',
    '#62DAAA',
    '#F27070',
    '#388df6',
    '#F29961',
    '#6394F9',
    '#E8DD93',
    '#7666F9',
    '#F0502E',
    '#657797',
    '#0034ff',
];
//健康报表区域分布（平谷峰尖总）
export const colorReportRegion = [
    '#72D0DC',
    '#4BC890',
    '#E98164',
    '#F0C33F',
    '#6188A9',
    '#6394F9',
    '#7666F9',
    '#99CC33',
];
//健康报表 （区域分布，三相电流）
export const colorReport = [
    '#FF8700',
    '#70D5A0',
    '#64D3FF',
    '#99CC33',
    '#62DAAA',
    '#7666F9',
    '#F6C021',
    '#00e473',
    '#4C689B',
    '#FF9966',
];

export const ringColor2 = [
    '#43DDFF',
    '#88D5FF',
    '#73ACFF',
    '#5377F4',
    '#9E87FF',
    '#FDD56A',
    '#FDB36A',
    '#FD866A',
    '#00BFA5',
    '#4DCB73',
];

export const ringColor = [
    '#38C489',
    '#C4E5F8',
    '#91ADC4',
    '#FFCF56',
    '#40E1F3',
    '#00A0E9 ',
    '#CCE198',
    '#89F1D7',
    '#FCA25F',
    '#FDEECA',
];

export const annularColorRank = {
    wrong: ['#F59A23', '#dddddd'],
    well: ['#99D5FD', '#dddddd'],
    fine: ['#68A54A', '#dddddd'],
    good: ['#25A890', '#dddddd'],
};

//隐患状态
export const dataConvert = { 0: '未指派', 1: '处理中', 2: '已处理', 3: '已关闭' };
export const convert = [
    { id: 0, value: '未指派' },
    { id: 1, value: '处理中' },
    { id: 2, value: '已处理' },
    { id: 3, value: '已关闭' },
];

//隐患等级
export const alarmLevel = { 0: '异常', 1: '高危', 2: '故障' };

//设备状态类型
export const legend = ['打开/在线', '关闭', '告警', '离线'];

//设备状态设定状态规则
export const status = {
    0: '关闭',
    1: '打开',
    2: '在线',
    3: '离线',
    4: '告警',
    5: '未激活',
};

// ['0']: '关闭',
// ['1']: '打开',
// ['2']: '在线',
// ['3']: '离线',
// ['4']: '告警',

// 账号角色
export const account = { 1: '后台用户', 2: '企业管理员', 3: '企业用户', 4: '运维用户' };
export const accountDataAdmin = [
    { id: 1, value: '后台用户', selected: true },
    { id: 2, value: '企业管理员', selected: false },
];
export const accountDataWeb = [
    { id: 3, value: '企业用户' },
    { id: 4, value: '运维用户' },
];

//健康报表报表类型
export const report = [
    { id: 'm', name: '月报' },
    { id: 'q', name: '季报' },
    { id: 'y', name: '年报' },
];

//巡检计划中的巡检类型
export const patrol = [{ id: 0, value: '电路' }];
export const patrolLevel = { 0: '电路' };

//巡检计划中的巡检方式
export const mode = [
    { id: 0, value: '人工' },
    { id: 1, value: '自动' },
];
export const dataMode = { 0: '人工', 1: '自动' };

//巡检任务中的任务状态
export const dataStatus = { 0: '待完成', 1: '已完成', 2: '逾期', 3: '失效' };
export const statusTask = [
    { id: 0, value: '待完成' },
    { id: 1, value: '已完成' },
    { id: 2, value: '逾期' },
    { id: 3, value: '失效' },
];

//巡检计划中的计划周期
export const dateDatas = [
    { id: 0, value: '日' },
    { id: 1, value: '周' },
    { id: 2, value: '月' },
];
export const date = { 0: '日', 1: '周', 2: '月' };

//巡检计划中的巡检时间周
export const week = { 1: '周一', 2: '周二', 3: '周三', 4: '周四', 5: '周五', 6: '周六', 7: '周日' };
export const weekData = [
    { id: 1, value: '周一' },
    { id: 2, value: '周二' },
    { id: 3, value: '周三' },
    { id: 4, value: '周四' },
    { id: 5, value: '周五' },
    { id: 6, value: '周六' },
    { id: 7, value: '周日' },
];

//app管理中的banner位置
export const appImgLocationData = [{ id: 0, name: '个人中心' }];
export const appImgLocation = { 0: '个人中心' };

//数据显示条数筛选查询显示（隐患分析-告警排行，能耗分析-对比分析、能耗排行）
export const selectTop = [
    { id: 0, value: '全部' },
    { id: 10, value: 'Top10' },
    { id: 20, value: 'Top20' },
    { id: 30, value: 'Top30' },
];

export const hiddenDanger = { voltage: '电压', current: '电流', power: '功率', power_factor: '功率因素' };

export const poweer_consumption = ['GWh', 'MWh', 'kWh']; //用电量单位

export const load_consumption = ['GW', 'MW', 'kW']; //负荷单位

export const showCamera = [
    { id: 4, value: '4宫格' },
    { id: 9, value: '9宫格' },
    { id: 16, value: '16宫格' },
];

// export const riskLevel = [{id: 0, name: '高风险'},{id: 1, name: '中风险'},{id: 2, name: '低风险'}]
