import React from 'react';
import { Breadcrumb, Row, Col, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import RouteHelper from '../../tools/RouteHelper';
import '../../style/common.less';
// import { MyIcon } from '../../util/iconfont';

export const BreadcrumbCustom = ({paths, showCloseBtn}) => {
    const Breadcrumbs = () => {
        // const { paths, showCloseBtn } = this.props;
        let v = paths.map(function (item, index) {
            return (
                <Breadcrumb.Item key={index}>
                    {/* {item === "首页" ? <Link to={"/"}>{item}</Link> : item} */}
                    {/* {item === "首页" ? <a href="/web">{item}</a> :  <a href="">{item}</a> } */}
                    {/* {item === '首页' ? (
                        <>
                            <MyIcon type="icon_home" />
                            <span>{item}</span>
                        </>
                    ) : (
                        item
                    )} */}
                    {item}
                </Breadcrumb.Item>
            );
        });
        return v;
    }

        return (
            <>
                {showCloseBtn ? (
                    <Row className="rumbCustom">
                        <Col span={22}>
                            <Breadcrumb>{Breadcrumbs()}</Breadcrumb>
                        </Col>
                        <Col span={2} style={{ textAlign: 'right' }}>
                            <Button
                                className="read"
                                // style={{ position: 'absolute', right: '1rem', top: '4.6rem' }}
                                onClick={() => {
                                    RouteHelper.locationBack();
                                }}
                                style={{marginTop: '-0.2rem'}}
                            >
                                <CloseOutlined />
                                关闭
                            </Button>
                        </Col>
                    </Row>
                    
                ) : (
                    <Breadcrumb className="rumbCustom">{Breadcrumbs()}</Breadcrumb>
                )}
            </>
        )
}
