import React, { useEffect, useState, useMemo, useRef } from 'react';
import { BreadcrumbCustom } from '../../../components/BreadcrumbCustom';
import { Col, Row, Radio, DatePicker } from 'antd';
import moment from 'moment';
import { isEmpty, map, isNumber, isBoolean, includes, isUndefined } from 'lodash';
import { Loading } from '../../../components/Loading';
import { EmptyView } from '../../../components/EmptyView';
import { CommonTree } from '../../../components/CommonTree';
import { CommonTable } from '../../../components/CommonTable';
import { ChartBarLine } from './Chart/ChartBarLine';
import { ReportPieEchart2 } from '../Report/ChartReport/reportPieEchart2';
import { Chart_COLOR, Chart_COLOR2 } from '../../../global';
import { disabledDate_day, toDay } from '../../../util/timeUtil';
import { getAreaList, getPeakHour } from '../../../api/webServe';
import { MyIcon } from '../../../util/iconfont';
import { peakvalue } from '../../../util/function-util';
import lessStyle from './eCRCondition.module.less';

const { RangePicker } = DatePicker;
/**
 * 能耗分析 - 峰谷用电
 * @returns
 */
export const PeakVallePower = () => {
    return (
        <div className={'container clearfix ' + lessStyle.containers}>
            <BreadcrumbCustom paths={['首页', '能耗分析', '峰谷用电']} className="rumbCustom" />
            <div className="row clearfix" style={{ backgroundColor: 'transparent', margin: '1rem', padding: '0' }}>
                <PeakComponent />
            </div>
        </div>
    );
};

const PeakComponent = () => {
    const [areaInfo, setAreaInfo] = useState();
    const [treeData, setTreeData] = useState([]);
    useEffect(() => {
        getAreaList()
            .then(result => {
                setTreeData(result.data);
            })
            .catch();
    }, []);
    return (
        <Row style={{ marginLeft: '0rem', width: '100%', height: '83vh' }} gutter={32}>
            <Col
                span={4}
                className="gutter-row"
                style={{ padding: '1rem', background: '#ffffff', borderRadius: '10px', height: '84vh' }}
            >
                <CommonTree
                    treeData={treeData}
                    operable={false}
                    onClick={info => {
                        if (!areaInfo || areaInfo.id !== info.id) {
                            setAreaInfo(info);
                        }
                    }}
                />
            </Col>
            <Col
                span={20}
                style={{
                    borderRadius: '10px',
                    boxSizing: 'border-box',
                    paddingRight: '0',
                }}
            >
                <PeakChartView areaInfo={areaInfo} />
            </Col>
        </Row>
    );
};

const PeakChartView = ({ areaInfo }) => {
    const [peakEchartData, setEchartPeakData] = useState({ data: null, isLoading: true, color: Chart_COLOR });
    const [peakTableData, setTablePeakData] = useState({ data: null, isLoading: true });
    const [piePeakValleyData, setPiePeakValleyData] = useState({ data: null, isLoading: true });
    const [piePeakValleyTotal, setPiePeakValleyTotal] = useState(null);
    const [pieExpenseData, setPieExpenseData] = useState({ data: null, isLoading: true });
    const [pieExpenseTotal, setPieExpenseTotal] = useState(null);
    const [timeValue, setTimeValue] = useState(toDay);
    const id = useMemo(() => {
        if (isEmpty(areaInfo)) {
            return null;
        }
        if (!isEmpty(areaInfo)) {
            return areaInfo.id;
        }
        return areaInfo.id;
    }, [areaInfo]);
    //峰谷用电趋势
    const getPeakData = time => {
        setEchartPeakData({ data: null, isLoading: true, color: Chart_COLOR });
        setPiePeakValleyData({ data: null, isLoading: true });
        setPieExpenseData({ data: null, isLoading: true });
        setTablePeakData({ data: null, isLoading: true });
        getPeakHour({ id, start_time: time[0], end_time: moment(time[1]).endOf('day').valueOf() })
        // getPeakHour({ id, start_time: time[0], end_time: moment(time[1]).subtract(1,'day').endOf('day').valueOf() })
            .then(result => {
                let { total_expense, total_power, trend, detail } = result.data;
                setTablePeakData({ data: detail, isLoading: false }); //峰谷趋势
                let dataPower = [],
                    dataExpense = [];
                //用电占比
                map(detail, item => {
                    dataPower.push({ name: item.name, value: peakvalue(item.power) });
                });
                //电费占比
                map(detail, item => {
                    dataExpense.push({ name: item.name, value: peakvalue(item.expense) });
                });
                setPiePeakValleyData({ data: dataPower, isLoading: false }); //用电占比
                setPieExpenseData({ data: dataExpense, isLoading: false }); //电费占比
                setPiePeakValleyTotal(total_power); //总用电量
                setPieExpenseTotal(total_expense); //总电费
                let legend,
                    data = [];
                if (!isUndefined(trend[0].data.tip_time)) {
                    legend = ['product', '谷时', '峰时', '平时', '尖时', '总用量'];
                    map(trend, (item, idx) => {
                        const everyOne = [];
                        everyOne.push(item.date);
                        everyOne.push(peakvalue(item.data.valley)); //谷时
                        everyOne.push(peakvalue(item.data.peak_hour)); //峰时
                        everyOne.push(peakvalue(item.data.peacetime)); //平时
                        everyOne.push(peakvalue(item.data.tip_time)); //尖时
                        everyOne.push(peakvalue(item.data.peacetime + item.data.valley + item.data.peak_hour + item.data.tip_time)); //总用量
                        data.push(everyOne);
                    });
                } else {
                    //无尖时
                    legend = ['product', '谷时', '峰时', '平时', '总用量'];
                    map(trend, (item, idx) => {
                        const everyOne = [];
                        everyOne.push(item.date);
                        everyOne.push(peakvalue(item.data.valley)); //谷时
                        everyOne.push(peakvalue(item.data.peak_hour)); //峰时
                        everyOne.push(peakvalue(item.data.peacetime)); //平时
                        // everyOne.push(peakvalue(peakvalue(item.data.peacetime) + peakvalue(item.data.valley) + peakvalue(item.data.peak_hour))); //总用量
                        everyOne.push(peakvalue(item.data.peacetime + item.data.valley + item.data.peak_hour)); //总用量
                        data.push(everyOne);
                    });
                }
                data.unshift(legend);
                setEchartPeakData({
                    data: isEmpty(trend) ? null : data,
                    isLoading: false,
                    color: Object.keys(trend[0].data).length === 5 ? Chart_COLOR : Chart_COLOR2,
                });
            })
            .catch(() => {
                setEchartPeakData({ data: null, isLoading: false, color: Chart_COLOR });
                setPiePeakValleyData({ data: null, isLoading: false });
                setPieExpenseData({ data: null, isLoading: false });
                setTablePeakData({ data: null, isLoading: false });
            });
    };
    useEffect(() => {
        if (!id && !isNumber(id)) {
            return;
        }
        getPeakData(toDay); //峰谷用电趋势
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const isStack =  useMemo(() => {
        return (timeValue[1]-timeValue[0] <= 86400000) || peakEchartData?.data?.length === 2 ? true : false
    },[timeValue, peakEchartData])
    const columnsPeakValleyPower = [
        {
            title: '时段',
            dataIndex: 'name',
            render: text => <p style={{ minWidth: '2rem' }}>{text}</p>,
        },
        {
            title: '用电量(kWh)',
            dataIndex: 'power',
            render: text => <p>{text ? peakvalue(text) : '—'}</p>,
        },
        {
            title: '用电占比',
            dataIndex: 'power_percentage',
            render: text => <p>{text ? `${peakvalue(text)}%` : '—'}</p>,
        },
        {
            title: '电费（元）',
            dataIndex: 'expense',
            render: text => <p>{text ? peakvalue(text) : '—'}</p>,
        },
        {
            title: '电费占比',
            dataIndex: 'expense_percentage',
            render: text => <p>{text ? `${peakvalue(text)}%` : '—'}</p>,
        },
    ];
    return (
        <Row style={{ height: '100%' }}>
            <Col span={24}>
                <div style={{ ...Style.module }}>
                    <Row
                        style={{
                            height: 50,
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            lineHeight: '50px',
                            borderTopLeftRadius: '10px',
                            borderTopRightRadius: '10px',
                            boxShadow: '0px 2px 3px #e7ebeb',
                            background: 'linear-gradient(180deg, #fefefe 0%, #f5f6fa 100%)',
                        }}
                    >
                        <Col span={9}>
                            <span style={Style.title}>
                                <MyIcon
                                    type="icon_title_peak_valley"
                                    style={{
                                        fontSize: '1.5rem',
                                        marginTop: '-0.1rem',
                                        verticalAlign: 'middle',
                                        paddingRight: '0.4rem',
                                        height: 50,
                                        lineHeight: '60px',
                                    }}
                                />
                                峰谷用电
                            </span>
                        </Col>
                        <Col
                            span={15}
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginTop: '-0.1rem',
                            }}
                        >
                            <TimeSearch
                                timeValue={toDay}
                                onValueChange={times => {
                                    const { time } = times;
                                    setTimeValue(time);
                                    getPeakData(time);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row
                        style={{
                            height: '78.5vh',
                            width: '100%',
                            marginLeft: '8px',
                            paddingRight: '16px',
                        }}
                        gutter={16}
                    >
                        <Col span={24} style={{ height: '60%' }}>
                            <Loading show={peakEchartData.isLoading}>
                                {isEmpty(peakEchartData.data) ? (
                                    <EmptyView />
                                ) : (
                                    <ChartBarLine
                                        rawData={peakEchartData.data}
                                        color={peakEchartData.color}
                                        stack={'stack'}
                                        showStack={isStack}
                                        unit={'kWh'}
                                        showSeries={true}
                                    />
                                )}
                            </Loading>
                        </Col>
                        <Col span={12} style={{ height: '39%' }}>
                            <CommonTable
                                peakValleyData={peakTableData}
                                columns={columnsPeakValleyPower}
                                className="peakValleyTableStyle"
                            />
                        </Col>
                        <Col span={6} style={{ height: '37%' }}>
                            <Loading show={piePeakValleyData.isLoading}>
                                <div style={Style.iconTitle}>
                                    <span style={Style.title}>
                                        <MyIcon type="icon_title_spread" style={Style.myIcon} />
                                        用电占比
                                    </span>
                                </div>
                                {isEmpty(piePeakValleyData.data) ? (
                                    <EmptyView />
                                ) : (
                                    <ReportPieEchart2
                                        rawData={piePeakValleyData.data}
                                        total={piePeakValleyTotal}
                                        color={Chart_COLOR}
                                        style={{ height: '24vh', border: '1px solid #f1f2fe' }}
                                    />
                                )}
                            </Loading>
                        </Col>
                        <Col
                            span={6}
                            style={{ height: '37%', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
                        >
                            <Loading show={pieExpenseData.isLoading}>
                                <div style={Style.iconTitle}>
                                    <span style={Style.title}>
                                        <MyIcon type="icon_title_spread" style={Style.myIcon} />
                                        电费占比
                                    </span>
                                </div>
                                {isEmpty(pieExpenseData.data) ? (
                                    <EmptyView />
                                ) : (
                                    <ReportPieEchart2
                                        rawData={pieExpenseData.data}
                                        total={pieExpenseTotal}
                                        color={Chart_COLOR}
                                        style={{ height: '24vh', border: '1px solid #f1f2fe' }}
                                    />
                                )}
                            </Loading>
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
    );
};

/**
 * 样式
 */
const Style = {
    iconTitle: {
        height: 50,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        lineHeight: '50px',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        boxShadow: '0px 2px 3px #e7ebeb',
        background: 'linear-gradient(180deg, #f1f1fe 0%, #f5f6fa 100%)',
    },
    myIcon: {
        fontSize: '1.5rem',
        marginTop: '-0.1rem',
        verticalAlign: 'middle',
        paddingRight: '0.4rem',
        height: 50,
        lineHeight: '60px',
    },
    title: {
        display: 'flex',
        width: '100%',
        height: '50px',
        lineHeight: '50px',
        fontSize: '1.1rem',
        color: '#333333',
        fontWeight: 500,
        padding: '0 1rem',
        verticalAlign: 'middle',
    },
    module: {
        background: '#ffffff',
        borderRadius: '10px',
        boxShadow: `0px 0px 3px 0px rgba(0,0,0,0.08)`,
    },
};

/**
 *
 * @onValueChange {*} 切换时间事件
 * @timeType {*} 筛选时间公共组件
 * @id {*}
 * @timeValue {*} 默认显示时间
 * @returns
 */
export const day7 = [moment().add(-6, 'd').startOf('day').valueOf(), moment().valueOf()];
export const day30 = [moment().add(-29, 'd').startOf('day').valueOf(), moment().valueOf()];
export const month12 = [moment().subtract(12, 'months').startOf('day').valueOf(), moment().valueOf()];
export const TimeSearch = ({ onValueChange, timeType = ['toDay', 'day7', 'day30', 'month12', 'custom'], id, timeValue }) => {
    const [open, setOpen] = useState();
    const [value, setValue] = useState(JSON.stringify(toDay));
    // const [dates, setDates] = useState([]);
    const [dateValue, setDateValue] = useState([moment(toDay[0]), moment(toDay[1])]);
    const time = useRef([]);
    const onOpenChange = open => {
        setOpen(open);
        if (open) {
            // setDates([]);
        }
    };

    useEffect(() => {
        if (!open && isBoolean(open)) {
            value && setValue('');
            !isEmpty(time.current) && onValueChange({ time: time.current });
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);
    useEffect(() => {
        setValue(JSON.stringify(timeValue))
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    return (
        <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
            <Radio.Group
                style={{ marginTop: 6 }}
                value={value}
                onChange={e => {
                    setValue(e.target.value);
                    const time = JSON.parse(e.target.value);
                    setDateValue([moment(time[0]), moment(time[1])]);
                    onValueChange({ time });
                }}
            >
                {includes(timeType, 'toDay') && <Radio.Button value={JSON.stringify(toDay)}>今日</Radio.Button>}
                {includes(timeType, 'day7') && <Radio.Button value={JSON.stringify(day7)}>近7日</Radio.Button>}
                {includes(timeType, 'day30') && <Radio.Button value={JSON.stringify(day30)}>近30日</Radio.Button>}
                {includes(timeType, 'month12') && <Radio.Button value={JSON.stringify(month12)}>近12月</Radio.Button>}
            </Radio.Group>
            {includes(timeType, 'custom') && (
                <RangePicker
                    style={{ marginLeft: 16, marginRight: 16, backgroundColor: 'transparent' }}
                    // onCalendarChange={val => setDates(val)}
                    showToday
                    disabledDate={disabledDate_day}
                    onOpenChange={onOpenChange}
                    value={dateValue}
                    onChange={times => {
                        if (!isEmpty(times)) {
                            setDateValue(times);
                            times = [moment(times[0]).startOf('day').valueOf(), moment(times[1]).endOf().valueOf()];
                            time.current = times;
                        } else {
                            setDateValue([]);
                        }
                    }}
                />
            )}
        </div>
    );
};
