import React from 'react';
import ReactEcharts from 'echarts-for-react';
import { alarmLevel } from '../../../../global'
export const ChartPies = ({ data, color }) => {
    const getOption = () => {
        let dashedPic =
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAM8AAAAOBAMAAAB6G1V9AAAAD1BMVEX////Kysrk5OTj4+TJycoJ0iFPAAAAG0lEQVQ4y2MYBaNgGAMTQQVFOiABhlEwCugOAMqzCykGOeENAAAAAElFTkSuQmCC';
        let arrName = [];
        let arrValue = [];
        let arrNumber = [];
        let sum = 0;
        let pieSeries = [],
            lineYAxis = [];

        // 数据处理
        data &&
            data.forEach((v, i) => {
                arrName.push(alarmLevel[v.grade]);
                arrValue.push(v.proportion);
                arrNumber.push(v.number);
                sum = sum + v.proportion;
            });

        // 图表option整理
        data &&
            data.forEach((v, i) => {
                pieSeries.push({
                    name: '隐患',
                    type: 'pie',
                    clockWise: false,
                    hoverAnimation: false,
                    radius: [75 - i * 15 + '%', 67 - i * 15 + '%'],
                    center: ['40%', '50%'],
                    label: {
                        show: false,
                    },
                    data: [
                        {
                            value: v.proportion,
                            name: alarmLevel[v.grade],
                            number: v.number,
                        },
                        {
                            value: sum - v.proportion,
                            name: '',
                            itemStyle: {
                                color: 'rgba(0,0,0,0)',
                            },
                        },
                    ],
                });
                pieSeries.push({
                    name: '',
                    type: 'pie',
                    silent: true,
                    z: 1,
                    clockWise: false, //顺时加载
                    hoverAnimation: false, //鼠标移入变大
                    radius: [75 - i * 15 + '%', 67 - i * 15 + '%'],
                    center: ['40%', '50%'],
                    label: {
                        show: false,
                    },
                    data: [
                        {
                            value: 7.5,
                            itemStyle: {
                                color: '#E3F0FF',
                            },
                        },
                        {
                            value: 2.5,
                            name: '',
                            itemStyle: {
                                color: 'rgba(0,0,0,0)',
                            },
                        },
                    ],
                });
                v.percent = (v.proportion / sum) * 100 + '%';
                lineYAxis.push({
                    value: i,
                    textStyle: {
                        rich: {
                            circle: {
                                color: color[i],
                                padding: [0, 5],
                            },
                        },
                    },
                });
            });
        let option = {
            color: color,
    //         tooltip: {
    //             show: true,
    //             // trigger: 'item',
    //             // axisPointer: {
    //             //     type: 'cross',
    //             //     label: {
    //             //         backgroundColor: '#6a7985',
    //             //     },
    //             // },
    //             // formatter: "{a}<br>{b}:{c}({d}%)",
    //             trigger: 'item',
    //     formatter: function (data) {
    //     var res = data.name + '<br/>' + data.number + '个' + '<br/>' + data.value + '%'
    //     console.log(data)
    //     for (var i = 0, length = data.length; i < length; i++) {
    //        res += data[i].seriesName + '：' 
    //            + data[i].data.originValue + '<br/>'
    //      }
    //      return res
    //    }
    //         },
            grid: {
                top: '10%',
                bottom: '66%',
                left: '40%',
                containLabel: false,
            },
            // legend: {
            //     top: '2%',
            //     left: '80%',
            //     // x: 'right',
            //     itemWidth: 12, // 设置宽度
            //     itemHeight: 8, // 设置高度
            //     icon: 'circle',
            //     // data: [
            //     //     {
            //     //         // name: names,
            //     //         orient: 'horizontal',
            //     //         textStyle: {
            //     //             color: '#c7c7c7',
            //     //             fontSize: 12,
            //     //         },
            //     //     },
            //     // ],
            //     data: getArrayValue(data, "name"),
            //     textStyle: {
            //         color: '#c7c7c7',
            //         fontSize: 12,
            //     },
            //     formatter: function(name) {
            //         console.log(name)
            //         return "{title|" + name + "} {value|}  {title|%}"
            //     },
            // },
            xAxis: [
                {
                    show: false,
                },
            ],
            yAxis: [
                {
                    type: 'category',
                    inverse: true,
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        formatter: function (params) {
                            let item = data && data[params];
                            return (
                                '{line|}{circle|●}{name|' +
                                alarmLevel[item.grade] +
                                '}{bd||}{value|' +
                                item.number +
                                '次}{bd||}{percent|' +
                                (item.proportion === null ? 0 : item.proportion) +
                                '%' +
                                '}'
                            );
                        },
                        interval: 0,
                        inside: true,
                        textStyle: {
                            color: '#333',
                            fontSize: 12,
                            rich: {
                                line: {
                                    width: 150,
                                    height: 10,
                                    backgroundColor: { image: dashedPic },
                                },
                                name: {
                                    color: '#666',
                                    fontSize: 12,
                                },
                                bd: {
                                    color: '#ccc',
                                    padding: [0, 5],
                                    fontSize: 12,
                                },
                                percent: {
                                    color: '#333',
                                    fontSize: 12,
                                },
                                value: {
                                    color: '#333',
                                    fontSize: 12,
                                    fontWeight: 500,
                                    padding: [0, 0, 0, 10],
                                },
                                unit: {
                                    fontSize: 12,
                                },
                            },
                        },
                        show: true,
                    },
                    data: lineYAxis,
                },
            ],
            animationType: 'scale',
            animationEasing: 'elasticOut',
            animationDelay: function (idx) {
                return Math.random() * 200;
            },
            animationDuration: 1000,
            series: pieSeries,
        };
        return option;
    };
    const option = getOption();
    return <ReactEcharts option={option} />;
};
