import React, {useEffect, useRef, useState} from 'react';
import { Carousel, Col, Row, Select } from 'antd';
import styles from './Insurance.module.less';
import moment from 'moment';
import { map, isEmpty, ceil } from 'lodash';
import { LineChart3 } from '../../../components/BarChart';
import RouteHelper from '../../../tools/RouteHelper';
import { HorizontalBarChart, GradientRingChart1, Pie3D2 } from './Chart/HorizontalBarChart';
import { Loading } from '../../../components/Loading';
import icon from '../../../assets/images/insurance_icon.png';
import titleIcon from '../../../assets/images/title_icon.png';
import Icon1 from '../../../assets/images/jinrigaojing_bg.png';
import Icon2 from '../../../assets/images/shebeizongliang_bg.png';
import Icon3 from '../../../assets/images/zaixianshebei_bg.png';
import FullScrenn from '../../../util/fullScreen';
import {
    getInsuranceRunStatus,
    getInsuranceDeviceStatistics,
    getInsuranceRegionStatistics,
    getInsuranceRegionAlarm,
    getInsuranceRegionSon,
    getInsuranceAlarmTrend,
    getInsuranceAlarmInfo,
    getInsuranceNavigation,
} from '../../../api/webServe';
import { EmptyView } from '../../../components/EmptyView'; //空数据占位组件
import { MyIcon } from '../../../util/iconfont'; //阿里图标组件
import { alarmLevel } from '../../../global'; //颜色集
import { removeEmpty } from '../../../util/function-util';
import { Map, Markers } from 'react-amap';

const { Option, OptGroup } = Select;

const emptyTest = () => {
    return <div style={{ color: '#ffffff' }}>暂无数据</div>;
};

const Timer = () => {
    const [time, setTime] = useState(moment(new Date()).format('llll:ss'));
    useEffect(() => {
        //当前时间日期
        const interval = setInterval(() => {
            setTime(moment(new Date()).format('llll:ss'));
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, []);
    return (
        <div className={styles.right}>
            {time}
            {/*<span style={{ marginLeft: '20px', fontWeight: 'bold', fontSize: '20px' }}>10:00:00</span>*/}
        </div>
    );
};

export const Insurance = () => {
    const [navigation, setNavigation] = useState({ data: null, isLoading: true });
    const [statusData, setRunStatusData] = useState({ data: null, isLoading: true });
    const [deviceStatistics, setDeviceStatistics] = useState({ data: null, isLoading: true });
    const [regionStatistics, setRegionStatistics] = useState({ data: null, isLoading: true });
    const [regionAlarm, setRegionAlarm] = useState({ data: null, isLoading: true });
    const [regionSon, setRegionSon] = useState({ data: null, isLoading: true });
    const [alarmTrend, setAlarmTrend] = useState({ data: null, isLoading: true });
    const [alarmInfo, setAlarmInfo] = useState({ data: null, isLoading: true });
    /**
     * 监听浏览器是否全屏
     * */
    const [isScreenFull, setIsScreenFull] = useState(false); //是否全屏
    useEffect(() => {
        //初始化
        FullScrenn.init(screenChange);
    }, [isScreenFull]);
    //屏幕变化
    const screenChange = isFull => {
        // console.log('是否全屏', isFull);
        setIsScreenFull(isFull);
    };
    //运行状态
    const RunStatus = () => {
        setRunStatusData({ data: null, isLoading: true });
        getInsuranceRunStatus()
            .then(result => {
                setRunStatusData({ data: result.data, isLoading: false });
            })
            .catch(() => {
                setRunStatusData({ data: null, isLoading: false });
            });
    };
    //设备统计
    const DeviceStatistics = () => {
        setDeviceStatistics({ data: null, isLoading: true });
        getInsuranceDeviceStatistics()
            .then(result => {
                setDeviceStatistics({ data: result.data, isLoading: false });
            })
            .catch(() => {
                setDeviceStatistics({ data: null, isLoading: false });
            });
    };
    //区域统计
    const RegionStatistics = () => {
        setRegionStatistics({ data: null, isLoading: true });
        getInsuranceRegionStatistics()
            .then(result => {
                setRegionStatistics({ data: result.data, isLoading: false });
            })
            .catch(() => {
                setRegionStatistics({ data: null, isLoading: false });
            });
    };
    //用电趋势
    const RegionAlarm = () => {
        setRegionAlarm({ data: null, isLoading: true });
        getInsuranceRegionAlarm()
            .then(result => {
                setRegionAlarm({ data: result.data, isLoading: false });
            })
            .catch(() => {
                setRegionAlarm({ data: null, isLoading: true });
            });
    };
    //地图上展示的数据
    const RegionSon = () => {
        setRegionSon({ data: null, isLoading: true });
        getInsuranceRegionSon()
            .then(result => {
                let newData = {};
                let data =
                    result.data &&
                    result.data.map(item => {
                        if (JSON.parse(item.longitude) !== null && JSON.parse(item.latitude) !== null) {
                            newData = {
                                id: item.id,
                                name: item.name,
                                risk: item.risk,
                                position: {
                                    longitude: JSON.parse(item.longitude),
                                    latitude: JSON.parse(item.latitude),
                                },
                                assetCount: '',
                                address: item.address,
                                alarm: item.alarm,
                                content: `<div className='area'>${item.address}</div>`,
                            };
                        }
                        return newData;
                    });
                setRegionSon({ data: data, isLoading: false });
            })
            .catch(() => {
                setRegionSon({ data: null, isLoading: false });
            });
    };
    //左上角的区域树
    const Navigation = () => {
        setNavigation({ data: null, isLoading: true });
        getInsuranceNavigation()
            .then(result => {
                setNavigation({ data: result.data, isLoading: false });
            })
            .catch(() => {
                setNavigation({ data: null, isLoading: false });
            });
    };
    //告警趋势
    const getAlarmTrend = () => {
        setAlarmTrend({ data: null, isLoading: true });
        getInsuranceAlarmTrend()
            .then(result => {
                setAlarmTrend({ data: result.data, isLoading: false });
            })
            .catch(() => {
                setAlarmTrend({ data: null, isLoading: false });
            });
    };
    //告警信息
    const getAlarmInfo = () => {
        setAlarmInfo({ data: null, isLoading: true });
        getInsuranceAlarmInfo()
            .then(result => {
                setAlarmInfo({ data: result.data, isLoading: false });
            })
            .catch(() => {
                setAlarmInfo({ data: null, isLoading: false });
            });
    };
    useEffect(() => {
        Navigation();
        RunStatus();
        DeviceStatistics();
        RegionStatistics();
        RegionAlarm();
        RegionSon();
        getAlarmTrend();
        getAlarmInfo();
    }, []);


    //resolution : https://github.com/ElemeFE/react-amap/issues/52
    const mapRef = useRef(null);
    const markerEvents = {
        created:(markers)=> {
            if (mapRef.current !== null) {
                mapRef.current.setFitView(markers);
            }
        },
        click: (mapsOption, marker) => {
            // console.log(marker,marker.De)
            marker &&
            // marker.Ce &&
            RouteHelper.locationTo('/web/bigScreen/insuranceChildren', { id: marker.De.extData.id });
        },
    }
    const mapEvents = {
        created: ins => {
            mapRef.current = ins
        },
    };


    //自定义icon代码
    const renderLayout = extData => {
        if (extData.risk === 0) {
            return (
                <div style={{ position: 'relative' }}>
                    <div style={{ width: '100px', height: '100px' }}>
                        {extData.alarm ? (
                            <div className={styles.alert_point}>
                                <div className={styles.circle} style={{ backgroundColor: '#ac002f' }}></div>
                                <div className={styles.circle_bottom} style={{ backgroundColor: '#ac002f' }}></div>
                                <div className={styles.circle_bottom2} style={{ backgroundColor: '#ac002f' }}></div>
                            </div>
                        ) : (
                            <MyIcon type={'iconyuandian'} style={{ color: '#ff0000', fontSize: '2rem' }} />
                        )}
                    </div>
                </div>
            );
        }
        if (extData.risk === 1) {
            return (
                <div style={{ position: 'relative' }}>
                    <div style={{ width: '100px', height: '100px' }}>
                        {extData.alarm ? (
                            <div className={styles.alert_point}>
                                <div className={styles.circle} style={{ backgroundColor: '#fa6a00' }}></div>
                                <div className={styles.circle_bottom} style={{ backgroundColor: '#fa6a00' }}></div>
                                <div className={styles.circle_bottom2} style={{ backgroundColor: '#ac7a2f' }}></div>
                            </div>
                        ) : (
                            <MyIcon type={'iconyuandian'} style={{ color: '#FF7A00', fontSize: '2rem' }} />
                        )}
                    </div>
                </div>
            );
        }
        if (extData.risk === 2) {
            return (
                <div style={{ position: 'relative' }}>
                    <div style={{ width: '100px', height: '100px' }}>
                        {extData.alarm ? (
                            <div className={styles.alert_point}>
                                <div className={styles.circle} style={{ backgroundColor: '#0cff00' }}></div>
                                <div className={styles.circle_bottom} style={{ backgroundColor: '#0cff00' }}></div>
                                <div className={styles.circle_bottom2} style={{ backgroundColor: '#0cff00' }}></div>
                            </div>
                        ) : (
                            <MyIcon type={'iconyuandian'} style={{ color: '#0CA919', fontSize: '2rem' }} />
                        )}
                    </div>
                </div>
            );
        }
    };

    let total = regionStatistics.data && regionStatistics.data.reduce((p, e) => p + e.number, 0); //区域统计中用到的数量总和
    return (
        <Row>
            <Col span={24} className={styles.bigScreenContainer}>
                <div className={styles.bigScreenHeader}>
                    <div className={styles.left}>
                        <MyIcon type="icon_location" style={{ fontSize: '26px', marginRight: '5px' }} />
                        <Select
                            // defaultValue={navigation.data && navigation.data.name}
                            value={navigation.data && navigation.data.name}
                            style={{ width: 240 }}
                            onSelect={e => {
                                RouteHelper.locationTo('/web/bigScreen/insuranceChildren', { id: e });
                            }}
                            dropdownClassName={styles.insuranceSelectStyle}
                            dropdownStyle={{
                                backgroundColor: 'rgba(0, 63, 91, 0.95)',
                            }}
                            virtual={false}
                        >
                            <OptGroup
                                label={navigation.data && navigation.data.name}
                                key={navigation.data && navigation.data.id}
                            >
                                {navigation.data &&
                                    navigation.data.children.map(childrenItem => {
                                        return (
                                            <Option value={childrenItem.id} key={childrenItem.id}>
                                                <MyIcon
                                                    type={'iconaddress'}
                                                    style={{
                                                        fontSize: '1.2rem',
                                                        marginRight: '0.1rem',
                                                        color: 'rgba(255, 255, 255, 0.85)',
                                                        verticalAlign: 'middle',
                                                    }}
                                                />
                                                <span style={{ verticalAlign: 'middle' }}>{childrenItem.name}</span>
                                            </Option>
                                        );
                                    })}
                            </OptGroup>
                        </Select>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '40vw' }}>
                        <div
                            className={styles.center}
                            style={{ marginTop: '-0.6rem', position: 'relative', display: 'flex' }}
                        >
                            <img src={titleIcon} alt="" />
                            <span style={{ verticalAlign: 'middle', color: '#DBE5F8' }}>智慧监测中心</span>
                        </div>
                    </div>
                    <Timer />
                </div>
                <div className={styles.bigScreenContent}>
                    <div className={styles.contentCenter}>
                        <div className={styles.left}>
                            <div className={styles.top}>
                                <div className={styles.title}>
                                    <div className={styles.titleName}>
                                        <img src={icon} alt="" />
                                        运行状态
                                    </div>
                                </div>
                                <Loading show={statusData.isLoading} hiddenShade={true}>
                                    {isEmpty(statusData.data) ? (
                                        <EmptyView description={emptyTest()} />
                                    ) : (
                                        <div className={styles.content}>
                                            <div className={styles.leftCenter}>
                                                <div className={styles.leftCenterItem}>
                                                    <div
                                                        className={styles.text}
                                                        style={{ backgroundImage: 'url(' + Icon1 + ')' }}
                                                    >
                                                        <div>{removeEmpty(ceil(statusData.data.alarm_count, 2))}</div>
                                                        <span>次</span>
                                                    </div>
                                                    <div className={styles.titleName}>
                                                        <p>今日告警</p>
                                                    </div>
                                                </div>

                                                <div className={styles.leftCenterItem}>
                                                    <div
                                                        className={styles.text}
                                                        style={{ backgroundImage: 'url(' + Icon2 + ')' }}
                                                    >
                                                        <div>{removeEmpty(statusData.data.device_count)}</div>
                                                        <span>台</span>
                                                    </div>
                                                    <div className={styles.titleName}>
                                                        <p>设备总量</p>
                                                    </div>
                                                </div>
                                                <div className={styles.leftCenterItem}>
                                                    <div
                                                        className={styles.text}
                                                        style={{ backgroundImage: 'url(' + Icon3 + ')' }}
                                                    >
                                                        <div>{removeEmpty(statusData.data.device_online)}</div>
                                                        <span>台</span>
                                                    </div>
                                                    <div className={styles.titleName}>
                                                        <p>在线设备</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Loading>
                            </div>
                            <div
                                className={styles.bottom}
                                style={{
                                    width: '85%',
                                    marginTop:
                                        '1.8rem' /*background: `url("${Pies}") 80px 22px no-repeat`, backgroundSize:'31%'*/,
                                }}
                            >
                                <div className={styles.title}>
                                    <div className={styles.titleName}>
                                        <img src={icon} alt="" />
                                        设备统计
                                    </div>
                                </div>
                                <Loading show={deviceStatistics.isLoading} hiddenShade={true}>
                                    {isEmpty(deviceStatistics.data) || deviceStatistics.data.length === 0 ? (
                                        <EmptyView description={emptyTest()} />
                                    ) : (
                                        <Pie3D2
                                            data={deviceStatistics.data}
                                            color={['#EC6A1A', '#F79A0D', '#FFDA2A', '#97D6F6', '#2564B8', '#138280']}
                                            show={true}
                                            distance={300}
                                        />
                                    )}
                                </Loading>
                            </div>
                        </div>
                        <div className={styles.center}>
                            <Loading show={regionSon.isLoading} hiddenShade={true}>
                                {isEmpty(regionSon.data) || regionSon.data.length === 0 ? (
                                    <EmptyView description={emptyTest()} />
                                ) : (
                                    <Map
                                        pitch={65}
                                        // amapkey={'ba274313b5c3d833e5e622af05804beb'} //加载高德 API 使用的 Key
                                        amapkey={'6dbbe70bd95ec66b33f1940879b2fa35'} //加载高德 API 使用的 Key
                                        buildingAnimation={true}
                                        // mapStyle={'amap://styles/grey'} //设置地图显示样式
                                        mapStyle={'amap://styles/f7cdd25f7323969294bf7d1453c45107'} //设置地图显示样式
                                        zoom={6} //地图显示的缩放级别,若center与level未赋值，地图初始化默认显示用户所在城市范围
                                        zooms={[1,20]} // 设置缩放级别范围1,6级
                                        events={mapEvents} //以键值对的形式提供绑定的事件对象
                                        // viewMode={'3D'} //开启3D视图,默认为关闭
                                        // plugins={['ToolBar']}//提供要加载的插件列表
                                        // center={center}//地图中心点坐标值
                                        loading={<Loading show={regionSon.isLoading}></Loading>} //用于在地图加载成功前渲染
                                    >
                                        <Markers
                                            markers={regionSon.data} //数组每一项都是都应标记点的属性或者其他自定义数据配置
                                            render={renderLayout} //render 属性接受一个 React 组件，或者一个方法返回一个 React 组件，会在初始化时渲染为组件的外观。并且会覆盖children，content 等的设置。
                                            events={markerEvents} //以键值对形式提供绑定的事件对象(markeyEvent鼠标移入移出)
                                            //useCluster={true} //是否启用标记点聚合插件；如果是MarkerClustererOptions对象，表明启用
                                            //content={'<div id="alert_point" style={{width: "20px", height: "20px", position: "relative"}}><div class="circle" style={{background-color: "red";border-radius: "50%";position: "absolute", width: "10px", height: "10px"}}></div><div class="circle_bottom animation " style={{animation: "twinkling 2.1s infinite ease-in-out",animation-fill-mode: "both"}}></div><div class="circle_bottom2 animation2 " style={{animation: "twinkling 2.1s infinite ease-in-out",animation-fill-mode: "both"}}></div></div>'}
                                        />
                                        {/* 弹框显示 */}
                                        {/* {regionSon.data.map(item => {
                                              return (
                                                <InfoWindow
                                                  position={item.position}
                                                  visible={item.id === visible}
                                                  // isCustom={false}
                                                  // isCustom
                                                  content={`<div><h4>Greetings</h4><p>This is content of this info window</p><p>Click 'Change Value' Button: ${item.name}</p></div>`}
                                                  // autoMove={true}
                                                  // size={this.state.size}
                                                  // offset={this.state.offset}
                                                  // events={this.windowEvents}
                                              />
                                              )
                                        })}*/}
                                    </Map>
                                )}
                            </Loading>
                        </div>
                        <div className={styles.right}>
                            <div className={styles.top}>
                                <div className={styles.title}>
                                    <div className={styles.titleName}>
                                        <img src={icon} alt="" />
                                        区域统计
                                        <span
                                        style={{
                                            fontSize: '12px',
                                            verticalAlign: 'bottom',
                                            marginTop: '0.5rem',
                                            marginLeft: '0.3rem',
                                        }}
                                    >
                                        (今日)
                                    </span>
                                    </div>
                                </div>
                                <Loading show={regionStatistics.isLoading} hiddenShade={true}>
                                    {isEmpty(regionStatistics.data) || regionStatistics.data.length === 0 ? (
                                        <EmptyView description={emptyTest()} />
                                    ) : (
                                        <Row>
                                            {/* {regionStatistics.data &&
                                                regionStatistics.data.reverse().map((item, index) => {
                                                    return (
                                                        <Col
                                                            span={8}
                                                            style={{ textAlign: 'center', marginTop: '1.5rem' }}
                                                            key={Math.random() * 10}
                                                        >
                                                            <GradientRingChart1
                                                                total={ceil((item.number / total) * 100, 2)}
                                                                data={item.number}
                                                                name={item.risk}
                                                                color={
                                                                    item.risk === '高风险'
                                                                        ? '#DC5F4F'
                                                                        : item.risk === '中风险'
                                                                        ? '#FFBC24'
                                                                        : '#3F97D7'
                                                                }
                                                                style={{ width: '100%', height: '200px' }}
                                                            />
                                                        </Col>
                                                    );
                                                })} */}
                                            <Col
                                                span={8}
                                                style={{ textAlign: 'center', marginTop: '1.5rem' }}
                                                key={Math.random() * 10}
                                            >
                                                <GradientRingChart1
                                                    total={
                                                        regionStatistics.data.length > 2 &&
                                                        ceil((regionStatistics.data[2].number / total) * 100, 2)
                                                    }
                                                    data={
                                                        regionStatistics.data.length > 2
                                                            ? regionStatistics.data[2].number
                                                            : 0
                                                    }
                                                    name={
                                                        regionStatistics.data.length > 2
                                                            ? regionStatistics.data[2].risk
                                                            : '高风险'
                                                    }
                                                    color={
                                                        regionStatistics.data.length > 2 &&
                                                        regionStatistics.data[2].risk === '高风险'
                                                            ? '#DC5F4F'
                                                            : regionStatistics.data.length > 2 &&
                                                              regionStatistics.data[2].risk === '中风险'
                                                            ? '#FFBC24'
                                                            : '#3F97D7'
                                                    }
                                                    style={{ width: '100%', height: '200px' }}
                                                />
                                            </Col>
                                            <Col
                                                span={8}
                                                style={{ textAlign: 'center', marginTop: '1.5rem' }}
                                                key={Math.random() * 10}
                                            >
                                                <GradientRingChart1
                                                    total={
                                                        regionStatistics.data.length > 1 &&
                                                        ceil((regionStatistics.data[1].number / total) * 100, 2)
                                                    }
                                                    data={
                                                        regionStatistics.data.length > 1
                                                            ? regionStatistics.data[1].number
                                                            : 0
                                                    }
                                                    name={
                                                        regionStatistics.data.length > 1
                                                            ? regionStatistics.data[1].risk
                                                            : '中风险'
                                                    }
                                                    color={
                                                        regionStatistics.data.length > 1 &&
                                                        regionStatistics.data[1].risk === '高风险'
                                                            ? '#DC5F4F'
                                                            : regionStatistics.data.length > 1 &&
                                                              regionStatistics.data[1].risk === '中风险'
                                                            ? '#FFBC24'
                                                            : '#3F97D7'
                                                    }
                                                    style={{ width: '100%', height: '200px' }}
                                                />
                                            </Col>
                                            <Col
                                                span={8}
                                                style={{ textAlign: 'center', marginTop: '1.5rem' }}
                                                key={Math.random() * 10}
                                            >
                                                <GradientRingChart1
                                                    total={
                                                        regionStatistics.data.length >= 1 &&
                                                        ceil((regionStatistics.data[0].number / total) * 100, 2)
                                                    }
                                                    data={
                                                        regionStatistics.data.length >= 1
                                                            ? regionStatistics.data[0].number
                                                            : 0
                                                    }
                                                    name={
                                                        regionStatistics.data.length >= 1
                                                            ? regionStatistics.data[0].risk
                                                            : '低分险'
                                                    }
                                                    color={
                                                        regionStatistics.data.length >= 1 &&
                                                        regionStatistics.data[0].risk === '高风险'
                                                            ? '#DC5F4F'
                                                            : regionStatistics.data.length >= 1 &&
                                                              regionStatistics.data[0].risk === '中风险'
                                                            ? '#FFBC24'
                                                            : '#3F97D7'
                                                    }
                                                    style={{ width: '100%', height: '200px' }}
                                                />
                                            </Col>
                                        </Row>
                                    )}
                                </Loading>
                            </div>
                            <div className={styles.bottom}>
                                <div className={styles.title}>
                                    <div className={styles.titleName}>
                                        <img src={icon} alt="" />
                                        高危区域
                                        <span
                                        style={{
                                            fontSize: '12px',
                                            verticalAlign: 'bottom',
                                            marginTop: '0.5rem',
                                            marginLeft: '0.3rem',
                                        }}
                                    >
                                        (今日)
                                    </span>
                                    </div>
                                </div>
                                <Loading show={regionAlarm.isLoading} hiddenShade={true}>
                                    {isEmpty(regionAlarm.data) ? (
                                        <EmptyView description={emptyTest()} />
                                    ) : (
                                        <HorizontalBarChart data={regionAlarm.data.reverse()} />
                                    )}
                                </Loading>
                            </div>
                        </div>
                    </div>
                    <div className={styles.contentBottom}>
                        <div className={styles.center}>
                            <div className={styles.title}>
                                <div className={styles.titleName}>
                                    <img src={icon} alt="" />
                                    告警趋势
                                    <span
                                        style={{
                                            fontSize: '12px',
                                            verticalAlign: 'bottom',
                                            marginTop: '0.5rem',
                                            marginLeft: '0.3rem',
                                        }}
                                    >
                                        (近30日)
                                    </span>
                                </div>
                            </div>
                            <Loading show={alarmTrend.isLoading} hiddenShade={true}>
                                {isEmpty(alarmTrend.data) ? (
                                    <EmptyView description={emptyTest()} />
                                ) : (
                                    <LineChart3 data={alarmTrend.data} />
                                )}
                            </Loading>
                        </div>
                        <div className={styles.right}>
                            <div className={styles.title} style={{ marginLeft: 0 }}>
                                <div className={styles.titleName}>
                                    <img src={icon} alt="" />
                                    告警列表
                                    <span
                                        style={{
                                            fontSize: '12px',
                                            verticalAlign: 'bottom',
                                            marginTop: '0.5rem',
                                            marginLeft: '0.3rem',
                                        }}
                                    >
                                        (近30条)
                                    </span>
                                </div>
                                {/* <Switch defaultChecked /> 目前我们没有语音，先暂时隐藏按钮*/}
                            </div>
                            <Loading show={alarmInfo.isLoading} hiddenShade={true}>
                                {isEmpty(alarmInfo.data) ? (
                                    <EmptyView description={'暂无最新告警数据'} />
                                ) : (
                                    <div className={styles.swiper}>
                                        <SwiperNews data={alarmInfo.data} />
                                    </div>
                                )}
                            </Loading>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    );
};

//告警信息轮流循环播放组件
const SwiperNews = ({ data }) => {
    return (
        <Carousel dotPosition="left" dots={false} infinite={data.length > 6} autoplay={data.length > 6}>
            {map(data, (item, idx) => {
                return (
                    <div key={idx} className={styles.swiperItem}>
                        <div className={styles.message} title={item.location}>
                            {item.location}
                        </div>
                        <div className={styles.statusAlarm}>
                            <span
                                className={styles.alarm}
                                style={{
                                    backgroundColor:
                                        alarmLevel[item.grade] || item.grade === '高危'
                                            ? '#DC5F4F'
                                            : alarmLevel[item.grade] || item.grade === '异常'
                                            ? '#FFBC24'
                                            : '#c7c7c7',
                                }}
                            >
                                {item.grade || alarmLevel[item.grade]}
                            </span>
                            <span className={styles.status}>
                                {item.update_time.slice(0, 10) + ' ' + item.update_time.slice(11, 19)}
                            </span>
                        </div>
                    </div>
                );
            })}
        </Carousel>
    );
};
