import React from 'react';
import { Pagination } from 'antd';
import '../style/CommonPagination.less';
export const Page = ({ setPage, setPageSize, total, count, page, setStart, setEnd }) => {
    // console.log(count, 'count');
    // console.log(page_size, 'page_size');
    const handleChangePage = value => {
        // console.log(value,'value')
        // console.log((value - 1) * count,'value2')
        // console.log((value - 1) * count + count,'value3')
        if (value <= 1) {
            setPage(1);
            setPageSize(count);
        } else {
            setPage(value);
            // setPage((value - 1) * count);
            // setStart((value - 1) * count);
            // setEnd((value - 1) * count + count);
        }
    };
    return (
        <Pagination
            onChange={handleChangePage}
            simple
            total={total}
            hideOnSinglePage={true}
            // defaultPageSize={page_size}
            pageSize={count}
            current={page}
            defaultCurrent={page}
        />
    );
};
