import React, { useEffect, useState } from 'react';
import { Select, Form, Button, Space, Switch, InputNumber, Tooltip, message } from 'antd';
import { MyIcon } from '../../../util/iconfont';
import { switchText } from '../../../global';
import { updateCamera } from '../../../api/webServe';

const FormItem = Form.Item;

export const SetUp = ({ form, data }) => {
    let rotationAngle = [
        { id: 0, name: '0°' },
        { id: 90, name: '90°' },
        { id: 180, name: '180°' },
        { id: 270, name: '270°' },
    ];
    let interval = [
        { id: 7200, name: '2h' },
        { id: 14400, name: '4h' },
        { id: 21600, name: '6h' },
        { id: 28800, name: '8h' },
        { id: 36000, name: '10h' },
        { id: 43200, name: '12h' },
        { id: 50400, name: '14h' },
        { id: 57600, name: '16h' },
        { id: 64800, name: '18h' },
        { id: 72000, name: '20h' },
        { id: 79200, name: '22h' },
        { id: 86400, name: '24h' },
    ];
    const [disabled, setDisabled] = useState(data.web_update === null ? true : false);
    useEffect(() => {
        form.setFieldsValue({
            led: data.led,
            angle: data.angle,
            wakeup_cycle: data.wakeup_cycle,
            image_store: data.image_store,
            web_update: data.web_update,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);
    // 更新升级
    const getUpdateCamera = () => {
        updateCamera({ device_list: [data.id], firmware: data.web_update })
            .then(() => {
                message.success('更新成功');
                setDisabled(true);
            })
            .catch();
    };

    return (
        <Form form={form}>
            <FormItem label={<>　　　 　<Tooltip title="设置后，将在传输2次图像后生效">
                        <MyIcon
                            type="icon_tishi"
                            style={{
                                verticalAlign: 'middle',
                                fontSize: '1rem',
                                marginRight: '0.4rem',
                                color: '#666666',
                            }}
                        />
                    </Tooltip>{"LED灯"}</>} name="led">
                <Switch {...switchText} key={data.led} defaultChecked={data.led} valuePropName="checked" />
            </FormItem>
            <FormItem label="　　　 　旋转图像" name="angle">
                <Select
                    placeholder="请选择旋转角度"
                    virtual={false}
                    showArrow
                    allowClear
                    style={{ minWidth: '3rem', width: '280px' }}
                >
                    {rotationAngle &&
                        rotationAngle.map(item => {
                            return (
                                <Select.Option value={item.id} key={item.id}>
                                    {item.name}
                                </Select.Option>
                            );
                        })}
                </Select>
            </FormItem>
            <FormItem label="　　 设备唤醒周期" name="wakeup_cycle">
                <Select
                    placeholder="请选择设备唤醒周期"
                    virtual={false}
                    showArrow
                    allowClear
                    style={{ minWidth: '3rem', width: '280px' }}
                >
                    {interval &&
                        interval.map(item => {
                            return (
                                <Select.Option value={item.id} key={item.id}>
                                    {item.name}
                                </Select.Option>
                            );
                        })}
                </Select>
            </FormItem>
            <FormItem label={<>&nbsp;&nbsp;<Tooltip title="存储时间达到上限后，超过上限日期的数据无法查看">
                        <MyIcon
                            type="icon_tishi"
                            style={{
                                verticalAlign: 'middle',
                                fontSize: '1rem',
                                marginRight: '0.4rem',
                                color: '#666666',
                            }}
                        />
                    </Tooltip>{'图像存储上限'}</>} name="image_store" /*rules={[{required: false, pattern: new RegExp(/^[0-9]\d*$/,'g'), message: '请输入大于30小于365的正整数'}]}*/>
                <Space>
                    <FormItem name="image_store" noStyle>
                        <InputNumber
                            min={30}
                            max={365}
                            precision={0}
                            placeholder="请选择图像存储上限"
                            style={{ width: '230px' }}
                        />
                    </FormItem>
                    <span>天</span>
                </Space>
            </FormItem>
            <FormItem label="　　　　　固件包" name="web_update">
                <Button
                    disabled={data.web_update === null || disabled || data.web_update === -1 ? true : false}
                    style={{
                        width: '120px',
                        marginRight: '0.5rem',
                        background: (data.web_update === null || disabled) || (data.web_update === -1 || disabled) ? '#ddd' : '#F59A23',
                        borderColor: (data.web_update === null || disabled) || (data.web_update === -1 || disabled) ? '#ddd' : '#F59A23',
                        verticalAlign: 'middle',
                    }}
                    type="primary"
                    onClick={() => {
                        data.web_update !== null && getUpdateCamera();
                    }}
                >
                    {data.web_update === null || disabled ? '已是最新版本' : data.web_update === -1 || disabled ? '系统升级处理中' : '点击更新'}
                </Button>
                {(data.web_update !== null && data.web_update !== -1) && (
                    <Tooltip title={data.firmware_info}>
                        <MyIcon
                            type="iconxiangqing"
                            style={{
                                verticalAlign: 'middle',
                                fontSize: '1.5rem',
                                marginRight: '0.4rem',
                                color: '#666666',
                            }}
                        />
                    </Tooltip>
                )}
            </FormItem>
        </Form>
    );
};

// 电机配置
export const MotorConfigSetup = ({ form, data }) => {
    const point = [
        { id: 60, name: '60pcs' },
        { id: 120, name: '120pcs' },
        { id: 240, name: '240pcs' },
    ];
    const sendTime = [
        { id: 30, name: '30s' },
        { id: 60, name: '60s' },
        { id: 300, name: '300s' },
    ];
    const collect = [{ id: 1000, name: '1000Hz' }];
    useEffect(() => {
        form.setFieldsValue({
            point: data.point,
            send_time: data.send_time,
            collect: data.collect,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);
    return (
        <Form form={form}>
            <FormItem label="数据点数" name="point">
                <Select
                    placeholder="请选择数据点数"
                    virtual={false}
                    showArrow
                    allowClear
                    style={{ minWidth: '3rem', width: '280px' }}
                >
                    {point &&
                        point.map(item => {
                            return (
                                <Select.Option value={item.id} key={item.id}>
                                    {item.name}
                                </Select.Option>
                            );
                        })}
                </Select>
            </FormItem>
            <FormItem label="发送周期" name="send_time">
                <Select
                    placeholder="请选发送周期"
                    virtual={false}
                    showArrow
                    allowClear
                    style={{ minWidth: '3rem', width: '280px' }}
                >
                    {sendTime &&
                        sendTime.map(item => {
                            return (
                                <Select.Option value={item.id} key={item.id}>
                                    {item.name}
                                </Select.Option>
                            );
                        })}
                </Select>
            </FormItem>
            <FormItem label="采集频率" name="collect">
                <Select
                    placeholder="请选择采集频率"
                    virtual={false}
                    showArrow
                    allowClear
                    style={{ minWidth: '3rem', width: '280px' }}
                >
                    {collect &&
                        collect.map(item => {
                            return (
                                <Select.Option value={item.id} key={item.id}>
                                    {item.name}
                                </Select.Option>
                            );
                        })}
                </Select>
            </FormItem>
        </Form>
    );
};
