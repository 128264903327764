import React, { Component } from 'react';
import { map, filter } from 'lodash';
import { withRouter, Route, Redirect } from 'react-router-dom';
import CacheRoute, { CacheSwitch } from 'react-router-cache-route';
import { getPermissions, getUser, getHomePagePath } from '../../tools/StorageHelper';
import { requestWebRoute, webRoute, requestRoute, baseRouter } from '../../router/demo';
import { BaseView } from '../BaseView';
import { DialogBox } from '../DialogBox';
import DialogHelper from '../../tools/DialogHelper';
import { isDevelop } from '../../global';
import { NotFound } from '../NoMatch';
import './index.less';
import '../../style/common.less';
class ContentMain extends Component {
    state = {
        isAdmin: JSON.parse(getUser()).is_staff,
    };
    renderRoute = menus => {
        const { handleSlider, handleHeader, listenRouterPath } = this.props;
        const { isAdmin } = this.state;
        const routes = isAdmin ? baseRouter : webRoute;
        const client_type = isAdmin ? 1 : 2;
        //解决缓存页面中的bug (菜单异常)
        const whenFunction = apply_route => {
            // return () => apply_route !== undefined && apply_route.includes(window.location.pathname);
            //let apply_routeArr = eval('(' + apply_route + ')')//将数组格式的字符串转换成数组,使用 1.eval() 函数转换 2.可以用JSON.parse
            return () => apply_route && JSON.parse(apply_route).includes(window.location.pathname);
        };
        return map(menus, (item, idx) => {
            const routerMap = routes[item.path];
            if (!routerMap) {
                return null;
            }
            const Components = routerMap.component;
            const redirect = routerMap.redirect;
            if (item.children && item.children.length > 0) {
                return this.renderRoute(filter(item.children, com => com.client_type === client_type));
            }
            if (item.path === '/' || routerMap.third) {
                return null;
            }
            if (Components) {
                if (item.cache_route) {
                    //typeof item.cache_route !== 'undefined'
                    return (
                        <CacheRoute
                            path={item.path}
                            key={item.path}
                            cacheKey={'MyComponent'}
                            exact
                            when={whenFunction(item.apply_route)}
                            render={route => (
                                <BaseView
                                    key={idx}
                                    {...route}
                                    handleHeader={handleHeader}
                                    handleSlider={handleSlider}
                                    listenRouterPath={listenRouterPath}
                                >
                                    <Components {...route} />
                                </BaseView>
                            )}
                        />
                    );
                } else {
                    return (
                        <Route
                            path={item.path}
                            key={item.path}
                            exact
                            render={route => (
                                <BaseView
                                    key={idx}
                                    {...route}
                                    handleHeader={handleHeader}
                                    handleSlider={handleSlider}
                                    listenRouterPath={listenRouterPath}
                                >
                                    <Components {...route} />
                                </BaseView>
                            )}
                        />
                    );
                }
            }
            return <Redirect path={item.path} to={redirect} />;
        });
    };

    render() {
        const { isAdmin } = this.state;
        var reg = new RegExp('"', 'g');
        var homePage = getHomePagePath() && getHomePagePath().replace(reg, '');
        const redirect = isAdmin ? '/admin' : homePage || '/web/homePage';
        const client_type = isAdmin ? 1 : 2;
        //isDevelop 为ture allRouters = 使用前端写的路由，使用后端返回的getPermissions中的name来判断控制
        //isDevelop 为false allRouters = 使用后端返回的getPermissions来判断权限控制
        const allRouters = getPermissions() != 'undefined' && JSON.parse(getPermissions());
        const routers = filter(allRouters, item => item.client_type === client_type);
        return (
            <div className="routeWrap">
                <DialogBox
                    childRef={component => {
                        DialogHelper.setDialog(component);
                    }}
                />
                <CacheSwitch>
                    {/*{this.renderRoute(isAdmin ? requestRoute : requestWebRoute)}*/}
                    {isDevelop ? this.renderRoute(isAdmin ? requestRoute : requestWebRoute) : this.renderRoute(routers)}
                    <Redirect path="/" exact to={redirect} />
                    <Redirect path="/web" exact to={redirect} />
                    {/* 第一种  地址栏显示点击的路由 */}
                    <Route component={NotFound} />
                    {/* 第二种  地址栏显示/notFound */}
                    <Route path="/notFound" component={NotFound} />
                    <Redirect to="/notFound" />
                </CacheSwitch>
            </div>
        );
    }
}

export default withRouter(ContentMain);
