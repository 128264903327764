import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import {
    Button,
    Col,
    Form,
    Row,
    Table,
    Popconfirm,
    Input,
    Select,
    DatePicker,
    Checkbox,
    Radio,
    message,
    TreeSelect,
} from 'antd';
import moment from 'moment';
import { BreadcrumbCustom } from '../../../components/BreadcrumbCustom';
import { CommonTree } from '../../../components/CommonTreeHidden';
import { CommonModal } from '../../../components/CommoModal';
import '../../../style/index.less';
import {
    getAlarm,
    getRegionDevice,
    changeAlarm,
    getAlarmUser,
    addAlarm,
    alarmExport,
    getAlarmSetsCategory,
    getStatusCategory,
    getGradeCategory,
} from '../../../api/webServe';
import { isEmpty, omitBy, isUndefined, map, isNull } from 'lodash';
import RouteHelper from '../../../tools/RouteHelper';
import { BASE_URL, dataConvert, alarmLevel } from '../../../global';
import Jiantou from '../../../assets/images/jiantou.png';
import { MyIcon } from '../../../util/iconfont';
import { toDay } from '../../../util/timeUtil';
import { timestampToTime } from '../../../util/function-util';

const FormItem = Form.Item;
const Option = Select.Option;
const { RangePicker } = DatePicker;
const { SHOW_PARENT } = TreeSelect;
/**
 *
 * @returns 隐患管理 --- 隐患列表
 */
export const HiddenRecord = () => {
    const [treeData, setTreeData] = useState([]);
    const [allNode, setAllNode] = useState([]);
    const [treeId, setTreeId] = useState(undefined);
    const [order, setOrder] = useState('True');
    const [searchParams, setSearch] = useState({}); //搜索
    const [time, setParentTimes] = useState(null); //搜索时间字段
    const [treeType, setTreeType] = useState(false);
    useEffect(() => {
        treeList();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order, searchParams]);
    const treeList = () => {
        searchParams.start_time = time !== null ? time[0] : undefined;
        searchParams.end_time = time !== null ? time[1] : undefined;
        const params = omitBy(searchParams, value => {
            if (isNaN(value) || value === '') {
                return isUndefined(value) || isNull(value) || value === '';
            } else {
                return isNull(value);
            }
        });
        getRegionDevice({ order: order, ...params })
            .then(result => {
                setTreeData(result.data);
            })
            .catch();
    };
    return (
        <div className="container clearfix">
            <BreadcrumbCustom paths={['首页', '隐患管理', '隐患列表']} className="rumbCustom" />
            <div className="row clearfix" style={{ backgroundColor: 'transparent', margin: '1rem', padding: '0' }}>
                <Row style={{ marginLeft: '0rem', width: '100%' }} gutter={32}>
                    <Col
                        className="gutter-row"
                        span={4}
                        style={{
                            padding: '1rem',
                            background: '#fff',
                            borderRadius: '10px',
                            overflow: 'hidden',
                            boxSizing: 'border-box',
                        }}
                    >
                        <Select
                            placeholder="按异常排序"
                            style={{ marginBottom: '0.5rem', display: 'flex', justifyContent: 'flex-end' }}
                            onChange={values => {
                                setOrder(values);
                            }}
                            virtual={false}
                        >
                            <Option value={'True'}>按异常降序</Option>
                            <Option value={'False'}>按异常升序</Option>
                        </Select>
                        <CommonTree
                            treeData={treeData}
                            onClick={value => {
                                !isEmpty(treeData) && setTreeId(value.id);
                                // treeList()
                                // console.log(value,'value')
                                setTreeType(value.dev_cate ? true : false)
                            }}
                            getNode={all => {
                                setAllNode(all);
                            }}
                        />
                    </Col>
                    <Col span={20} style={{ paddingRight: '0' }}>
                        <HiddenTable
                            allNode={allNode}
                            treeId={treeId}
                            treeType={treeType}
                            setParentTimes={setParentTimes}
                            setSearch={setSearch}
                            treeData={treeData}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
};

const HiddenTable = ({ treeId, setParentTimes, setSearch, treeData, treeType }) => {
    const [form] = Form.useForm();
    const childRef = useRef(); //弹框
    const formChildrenRef = useRef(); //指派表单
    const formChildrenRef2 = useRef(); //隐患表单
    const [modalType, setModalType] = useState('add'); //edit add assign
    const [data, setData] = useState({ data: [], isLoading: true }); //隐患列表数据
    const [user, setUser] = useState([]); //指派人数据
    const [count, setCount] = useState(0); //数据总条数
    const [page, setPage] = useState(1); //当前页
    const [page_size, setPageSize] = useState(10); //当前页显示条数
    const [update, setUpdate] = useState(); //更新
    const [userId, setUserId] = useState(); //当前数据的id
    const [handlerName, setHandlerName] = useState(); //当前指派人
    const [time, setTime] = useState([timestampToTime(toDay[0]),timestampToTime(toDay[1])]); //搜索时间字段
    const [searchParams, setSearchParams] = useState({}); //搜索
    const [selectedRowKeys, setSelectedRowKeys] = useState([]); //全选
    const [alarmSet, setAlarmSet] = useState([]); //告警分类
    const [alarmGrade, setAlarmGrade] = useState([]); //告警等级
    const [alarmConvert, setAlarmConvert] = useState([]); //告警状态
    //解决点击时间触发两次请求的bug、修复点击区域选择搜索后触发两次请求：默认监测点id或者点击后的监测点id
    treeId = treeId || (treeData.length > 0 && treeData[0].id);
    const users = () => {
        if (!isUndefined(userId)) {
            getAlarmUser({ alarm_id: userId })
                .then(result => {
                    setUser(result.data);
                })
                .catch();
        }
    };
    useEffect(() => {
        users();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, modalType]);

    useEffect(() => {
        searchParams.start_time = time !== null && time[0] !== '' ? time[0] : undefined;
        searchParams.end_time = time !== null && time[1] !== '' ? time[1] : undefined;
        const params = omitBy(searchParams, value => {
            if (isNaN(value) || value === '') {
                return isUndefined(value) || isNull(value) || value === '';
            } else {
                return isNull(value);
            }
        });
        setData({ data: [], isLoading: true });
        // if (!isUndefined(treeId)) {
        if (treeId) {
            getAlarm(treeType ? { device_id: treeId, page, page_size, ...params } : { region_id: treeId, page, page_size, ...params })//判断是区域region_id还是设备device_id
                .then(result => {
                    setData({ data: result.data, isLoading: false });
                    setCount(result.count);
                })
                .catch();
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [treeId, update, modalType, searchParams, page, page_size]);

    const onChange2 = (value, dateString) => {
        setTime(dateString);
        setParentTimes(dateString);
    };
    const exportData = () => {
        const params = omitBy(searchParams, isUndefined);
        alarmExport(treeId, { ...params })
            .then(result => {
                window.open(BASE_URL + result.data.url);
            })
            .catch();
    };
    const renderForm = () => {
        return (
            <Form
                layout="horizontal"
                form={form}
                onFinish={value => {
                    // onFinish(value);
                    // value.time = moment(value.time, 'yyyy-MM-DD'),
                    setSearchParams(value);
                    setSearch(value);
                }}
                initialValues={{start_time: [moment(timestampToTime(time[0]),'yyyy-MM-DD'), moment(timestampToTime(time[1]), 'yyyy-MM-DD')]}}
            >
                <Row gutter={16} style={{marginRight: '0px',display: 'flex', flexWrap: 'nowrap',  justifyContent: 'space-between', width: '100%'}}>
                    <Col span={4}>
                        <FormItem name="grade" label="级别" rules={[{ required: false, message: '请选择级别' }]}>
                            <Select placeholder="请选择" allowClear={true} virtual={false} onFocus={() => {
                                getGradeCategory()
                                    .then(result => {
                                        setAlarmGrade(result.data);
                                    })
                                    .catch();
                            }}>
                                {alarmGrade.length > 0 && alarmGrade.map(item => {
                                    return (
                                        <Option value={item[0]} key={item[0]}>
                                            {item[1]}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </FormItem>
                    </Col>
                    <Col span={5}>
                        <FormItem name="event" label="分类" rules={[{ required: false, message: '请选择分类' }]}>
                            <Select placeholder="请选择" allowClear={true} virtual={false} onFocus={() => {
                                 getAlarmSetsCategory()
                                    .then(result => {
                                        setAlarmSet(result.data);
                                    })
                                    .catch();
                            }}>
                                {alarmSet.map(item => {
                                    return (
                                        <Option value={item[0]} key={item[0]}>
                                            {item[0]}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </FormItem>
                    </Col>
                    <Col span={5}>
                        <FormItem
                            name="handler_status"
                            label="状态"
                            rules={[{ required: false, message: '请选择状态' }]}
                        >
                            <Select placeholder="请选择" allowClear={true} virtual={false} onFocus={() => {
                                getStatusCategory()
                                .then(result => {
                                    setAlarmConvert(result.data)
                                })
                                .catch()
                            }}>
                                {alarmConvert.length > 0 && alarmConvert.map(item => {
                                    return (
                                        <Option value={item[0]} key={item[0]}>
                                            {item[1]}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </FormItem>
                    </Col>
                    <Col span={5}>
                        {' '}
                        <FormItem label="日期" name="start_time" rules={[{ required: false, message: '请选择日期' }]}>
                            {/* <DatePicker format="YYYY-MM-DD" placeholder="请选择日期" /> */}
                            <RangePicker format="yyyy-MM-DD" onChange={onChange2}/>
                        </FormItem>
                    </Col>
                    <Col style={{ textAlign: 'right' }}>
                        <FormItem>
                            <Button type="primary" htmlType="submit">
                                搜索
                            </Button>
                        </FormItem>
                    </Col>
                    <Col style={{ textAlign: 'right' }}>
                        <FormItem>
                            <Button
                                type="default"
                                style={{
                                    color: '#fff',
                                    backgroundColor: '#439BE3',
                                    borderColor: '#439BE3',
                                }}
                                onClick={exportData}
                            >
                                导出
                            </Button>
                        </FormItem>
                    </Col>
                </Row>
            </Form>
        );
    };
    const columns = [
        {
            title: '序号',
            dataIndex: 'id',
            key: 'id',
            width: 90, //宽度
            render: (text, record, index) => `${index + 1}`,
        },
        {
            title: '级别',
            dataIndex: 'grade',
            width: 90,
            render: text => <span>{alarmLevel[text]}</span>,
        },
        {
            title: '内容',
            dataIndex: 'content',
            render: text => (
                <p
                    style={{
                        maxWidth: '8rem',
                        minWidth: '3rem',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                    }}
                    title={text}
                >
                    {text}
                </p>
            ),
        },
        {
            title: '分类',
            dataIndex: 'event',
            render: text => (
                <p
                    style={{
                        minWidth: '3rem',
                        maxWidth: '8rem',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                    }}
                    title={text}
                >
                    {text}
                </p>
            ),
        },
        {
            title: '状态',
            dataIndex: 'handler_status',
            width: 100,
            render: text => <span>{text === null ? '' : dataConvert[text]}</span>,
        },
        {
            title: '位置',
            dataIndex: 'location',
            render: text => (
                <p
                    style={{
                        minWidth: '3rem',
                        maxWidth: '8rem',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                    }}
                    title={text}
                >
                    {text}
                </p>
            ),
        },
        {
            title: '设备名称',
            dataIndex: 'device_name',
            render: text => (
                <p
                    style={{
                        minWidth: '3rem',
                        maxWidth: '8rem',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                    }}
                    title={text}
                >
                    {text}
                </p>
            ),
        },
        {
            title: '时间',
            dataIndex: 'time',
            width: 180,
            render: text => <span>{text && text.slice(0, 10) + ' ' + text.slice(11, 19)}</span>,
        },
        {
            title: '处理人',
            dataIndex: 'handler_name',
            render: text => (
                <p
                    style={{
                        minWidth: '3rem',
                        maxWidth: '5rem',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                    }}
                    title={text}
                >
                    {text}
                </p>
            ),
        },
        {
            title: '操作',
            dataIndex: 'opera',
            width: 150,
            render: (text, record) => (
                <div className="opera" style={{ textAlign: 'center' }}>
                    <MyIcon
                        type="iconchakan1"
                        style={{ fontSize: '1rem', verticalAlign: 'middle', color: 'rgba(0, 0, 0, 0.85)' }}
                        title={'查看'}
                        onClick={() => {
                            RouteHelper.locationTo('/web/hiddenDanger/list2/readHiddenAlarm', { id: record.id });
                        }}
                    />
                    {/* <span
                        className="edit"
                        onClick={() => {
                            setModalType('edit');
                            childRef.current.showModal(0);
                            RouteHelper.locationTo('/web/hiddenDanger/editHiddenAlarm', { id: record.id });
                        }}
                    >
                        编辑
                    </span> */}
                    <MyIcon
                        type="iconzhipai"
                        style={{ color: 'rgba(0, 0, 0, 0.85)', fontSize: '1rem', verticalAlign: 'middle' }}
                        title={'指派'}
                        onClick={() => {
                            setModalType('assign');
                            childRef.current.showModal(0);
                            setUserId(record.id);
                            setHandlerName(record.handler_name);
                        }}
                    />
                    <Popconfirm
                        title="确定要关闭吗?"
                        onConfirm={() => {
                            let id = record.id;
                            changeAlarm({ handler_status: [id] })
                                .then(result => {
                                    message.success('关闭成功');
                                    setUpdate(result);
                                })
                                .catch();
                        }}
                    >
                        <MyIcon
                            type={'iconguanbi'}
                            style={{ fontSize: '1rem', verticalAlign: 'middle', color: 'rgba(0, 0, 0, 0.85)' }}
                            title={'关闭'}
                        />
                    </Popconfirm>
                    <Popconfirm
                        title="确定要删除吗?"
                        onConfirm={() => {
                            let id = record.id;
                            changeAlarm({ is_del: [id] })
                                .then(result => {
                                    message.success('删除成功');
                                    setUpdate(result);
                                })
                                .catch();
                        }}
                    >
                        <MyIcon
                            type="iconshanchu1"
                            style={{ color: 'rgba(0, 0, 0, 0.85)', fontSize: '1rem', verticalAlign: 'middle' }}
                            title={'删除'}
                        />
                    </Popconfirm>
                </div>
            ),
        },
    ];
    /**
     *  表格分页
     */
    const paginationNo = {
        pageSize: page_size, //每页的条数
        current: page, //当前页
        total: count, //总数据
        showSizeChanger: true,
        onChange: page => {
            setPage(page);
        }, //点击分页号时触发的方法
        onShowSizeChange: (current, pageSize) => {
            setPageSize(pageSize);
        }, //pageSize 变化的回调
        hideOnSinglePage: true, //为true则数据条数不满或刚好一页，则隐藏分页器
        pageSizeOptions: ['10', '15', '20', '30', '50', '100'], // 分页类别
        showTotal: total => `共 ${total} 条`,
    };

    /**
     *  勾选时触发的方法 (全选)
     * @param e
     */
    const onSelectChange = selectedRowKeys => {
        setSelectedRowKeys(selectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const onCheckAllChange = e => {
        setSelectedRowKeys(e.target.checked ? data.data.map((item, index) => item.id) : []);
    };
    return (
        <div
            className="tableBox"
            style={{
                width: '100%',
                height: '100%',
                backgroundColor: '#fff',
                borderRadius: '10px',
                padding: '1rem',
                boxSizing: 'border-box',
            }}
        >
            {/* 新增、搜索、导出按钮功能 */}
            <Row gutter={16}>
                {/* <Col span={2}>
                    <Button
                        onClick={() => {
                            setModalType('add');
                            childRef.current.showModal();
                            //TODO 新增需求删除:(删除原因)I期II期系统以用电监测为主，与运维KPI考核相关的辅助功能暂不做
                        }}
                        type="primary"
                        style={{ marginRight: '10px', marginBottom: '10px' }}
                    >
                        新增
                    </Button>
                </Col> */}

                <Col span={24} offset={0} style={{paddingRight: '0px'}}>
                    {renderForm()}
                </Col>
                <Col
                    span={24}
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        width: '100%',
                        marginBottom: '1.5rem',
                    }}
                    className="hiddenRecord"
                >
                    <p>发现隐患 </p>
                    <img src={Jiantou} alt={'箭头'} style={{ margin: '0 1rem 0 0.5rem' }} />
                    <p>隐患指派 </p>
                    <img src={Jiantou} alt={'箭头'} style={{ margin: '0 1rem 0 0.5rem' }} />
                    <p>隐患处理 </p>
                    <img src={Jiantou} alt={'箭头'} style={{ margin: '0 1rem 0 0.5rem' }} />
                    <p>隐患关闭</p>
                </Col>
            </Row>
            {/* 表格 */}
            <Table
                rowKey={record => record.id}
                columns={columns}
                dataSource={data.data}
                bordered={true}
                loading={data.isLoading}
                scroll={{ x: '100%' }}
                pagination={paginationNo}
                rowSelection={rowSelection}
                tableLayout={'auto'}
                locale={{
                    cancelSort: '点击取消排序',
                    triggerAsc: '点击升序',
                    triggerDesc: '点击降序',
                    filterConfirm: '确定',
                    filterReset: '重置',
                    emptyText: '暂无数据',
                }}
            />
            {/* 批量操作 */}
            <div
                style={
                    data.data.length > 0
                        ? { display: 'block', position: 'inherit', marginTop: '0.8rem' }
                        : { display: 'none' }
                }
            >
                <Checkbox
                    indeterminate={
                        //负责样式控制
                        data.data.length !== selectedRowKeys.length && selectedRowKeys.length !== 0
                    }
                    onChange={onCheckAllChange} //勾选时触发的方法
                    checked={data.data.length === selectedRowKeys.length} //勾选还是未勾选  options：所有的勾选子项，list
                >
                    全选
                </Checkbox>
                <Radio.Group style={{ marginBottom: 6 }}>
                    <Radio.Button value="off">
                        <Popconfirm
                            title={
                                selectedRowKeys.length === 0
                                    ? '未选中'
                                    : `确定要将${selectedRowKeys.length}项,全部关闭吗?`
                            }
                            onConfirm={() => {
                                changeAlarm({ handler_status: selectedRowKeys })
                                    .then(result => {
                                        message.success('批量修改成功');
                                        setUpdate(result);
                                        setSelectedRowKeys([]);
                                    })
                                    .catch(err => {
                                        message.error(err);
                                    });
                            }}
                        >
                            关闭{' '}
                        </Popconfirm>
                    </Radio.Button>
                    <Radio.Button value="del">
                        <Popconfirm
                            title={
                                selectedRowKeys.length === 0
                                    ? '未选中'
                                    : `确定要将${selectedRowKeys.length}项,全部删除吗?`
                            }
                            onConfirm={() => {
                                changeAlarm({ is_del: selectedRowKeys })
                                    .then(result => {
                                        message.success('批量删除成功');
                                        setUpdate(result);
                                        setSelectedRowKeys([]);
                                    })
                                    .catch(err => {
                                        message.error(err);
                                    });
                            }}
                        >
                            删除{' '}
                        </Popconfirm>
                    </Radio.Button>
                </Radio.Group>
            </div>
            <CommonModal
                onOk={() => {
                    modalType === 'assign'
                        ? formChildrenRef.current
                              .validateFields()
                              .then(value => {
                                  let id = user.filter(item => {return value.handler_name === item.real_name})
                                  value.handler_id=id[0].id;
                                  value.alarm_id = userId;
                                  changeAlarm({ designate: [value] })
                                      .then(result => {
                                          message.success('指派成功');
                                          childRef.current.hiddenModal();
                                          setUpdate(result);
                                          formChildrenRef.current.resetFields();
                                          formChildrenRef.current.setFieldsValue({
                                              handler_name: value.real_name,
                                          });
                                      })
                                      .catch();
                              })
                              .catch(err => {
                                  message.error(err);
                                  // childRef.endLoading();
                              })
                        : formChildrenRef2.current.validateFields().then(value => {
                              addAlarm(value)
                                  .then(result => {
                                      childRef.current.hiddenModal();
                                      setUpdate(result);
                                      message.success('新增成功');
                                  })
                                  .catch(err => {
                                      message.success(err);
                                  });
                          });
                }}
                childRef={childRef}
                title={`${modalType === 'assign' ? '指派' : modalType === 'edit' ? '编辑隐患' : '新增隐患'}`}
            >
                {modalType === 'assign' ? (
                    <AssignForm childRef={formChildrenRef} userId={userId} user={user} handlerName={handlerName} />
                ) : (
                    <HiddenForm childRef={formChildrenRef2} user={user} alarmSet={alarmSet} alarmGrade={alarmGrade} />
                )}
            </CommonModal>
        </div>
    );
};

//新增、编辑弹框内容显示
const HiddenForm = ({ childRef, user, alarmSet, alarmGrade }) => {
    const [form] = Form.useForm();
    useImperativeHandle(childRef, () => ({
        validateFields: form.validateFields,
        resetFields: form.resetFields,
    }));
    const [value, setValue] = useState(null);
    const [permission, setPermission] = useState([]);
    const onChange2 = value => {
        setValue(value);
    };
    const recursion = data => {
        return map(data, item => {
            return { ...item, key: item.id, title: item.name, children: recursion(item.children) };
        });
    };
    useEffect(() => {
        getRegionDevice()
            .then(result => {
                setPermission(recursion(result.data));
            })
            .catch();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const tProps = {
        treeData: permission,
        value: value,
        onChange: onChange2,
        treeCheckable: true,
        showCheckedStrategy: SHOW_PARENT,
        placeholder: '请选择数据权限',
        style: {
            width: '100%',
        },
    };
    return (
        <Form layout="horizontal" form={form}>
            <Row>
                <Col offset={4} span={18}>
                    <FormItem name="grade" label="级别" rules={[{ required: true, message: '请选择级别' }]}>
                        <Select placeholder="请选择级别" allowClear={true} virtual={false}>
                            {alarmGrade.map(item => {
                                return (
                                    <Option value={item.grade} key={item.grade}>
                                        {alarmLevel[item.grade]}
                                    </Option>
                                );
                            })}
                        </Select>
                    </FormItem>
                </Col>
                <Col offset={4} span={18}>
                    <FormItem name="event" label="分类" rules={[{ required: true, message: '请选择分类' }]}>
                        <Select placeholder="请选择分类" allowClear={true} virtual={false}>
                            {alarmSet.map(item => {
                                return (
                                    <Option value={item.name} key={item.id}>
                                        {item.name}
                                    </Option>
                                );
                            })}
                        </Select>
                    </FormItem>
                </Col>
                <Col offset={4} span={18}>
                    <FormItem name="location" label="区域" rules={[{ required: true, message: '请选择区域' }]}>
                        <TreeSelect {...tProps} />
                    </FormItem>
                </Col>
                <Col offset={4} span={18}>
                    <FormItem label="内容" name="content" rules={[{ required: true, message: '请输入描述内容' }]}>
                        <Input placeholder="请输入描述内容" />
                    </FormItem>
                </Col>
                <Col offset={4} span={18}>
                    <FormItem name="handler_name" label="指派" rules={[{ required: false, message: '请选择受理人' }]}>
                        <Select placeholder="请选择受理人" allowClear virtual={false}>
                            {user.map(item => {
                                return (
                                    <Option value={item.real_name} key={item.id}>
                                        {item.real_name}
                                    </Option>
                                );
                            })}
                        </Select>
                    </FormItem>
                </Col>
            </Row>
        </Form>
    );
};
//指派弹框
const AssignForm = ({ childRef, user, handlerName }) => {
    const [form] = Form.useForm();
    useImperativeHandle(childRef, () => ({
        validateFields: form.validateFields,
        resetFields: form.resetFields,
        setFieldsValue: form.setFieldsValue,
    }));
    form.setFieldsValue({ handler_name: user.length !== 0 && !isNull(handlerName) ? handlerName || user[0].real_name : '' });
    return (
        <Form layout="horizontal" form={form} name={'指派'} scrollToFirstError>
            <Row>
                <Col offset={4} span={18}>
                    <FormItem name="handler_name" label="受理人" rules={[{ required: false, message: '请选择受理人' }]}>
                        <Select placeholder="请选择" allowClear={true} showArrow virtual={false}>
                            {user.map(item => {
                                return (
                                    <Option value={item.real_name} key={item.id}>
                                        {item.real_name}
                                    </Option>
                                );
                            })}
                        </Select>
                    </FormItem>
                </Col>
            </Row>
        </Form>
    );
};
