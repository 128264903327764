/**
 * 账号登录
 */
import React from 'react';
import { Form, Input, Button, message } from 'antd';
import history from '../../api/history';
import { MyIcon } from '../../util/iconfont';
import { fakeAuth } from '../../util/fakeAuth';
import {
    setPermissions,
    setUser,
    setHomePagePath,
} from '../../tools/StorageHelper';
import { login } from '../../api/webServe';
import './index.less';

const AccountLogin = () => {
    const [form] = Form.useForm();
    const forgetClick = () => {
        //忘记密码
        history.push('/forget');
    };
    const onFinish = values => {
        login(values)
            .then(result => {
                message.success('登录成功');
                fakeAuth.setToken('JWT ' + result.data.token); //储存token到本地
                setUser(JSON.stringify(result.data)); //用户信息
                setPermissions(JSON.stringify(result.data.permission));
                let data = result.data.permission.filter(item => {
                    return item.name === '工作台'
                })
                setHomePagePath(data.length > 0 ? (data[0].children.length > 0 ? data[0].children[0].path : data[0].path) : '/noPermission')
                result.data.is_staff ? history.push('/admin') : history.push(data.length > 0 ? (data[0].children.length > 0 ? data[0].children[0].path : data[0].path) : '/noPermission');
                // setPermissionsData(JSON.stringify(data));
                // console.log(result.data.permission,'result.data.permission')
            })
            .catch(error => {
                // message.error(error);
                // message.error('账号或者密码错误')
            });
    };
    const onFinishFailed = errorInfo => {
        // console.log('Failed:', errorInfo);
    };
    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    return (
        <div className="dataWrap">
            <Form
                className="login-form logins"
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                name="horizontal_login"
                layout="inline"
                initialValues={{
                    remember: true,
                }}
                {...layout}
            >
                <Form.Item
                    name="username"
                    rules={[
                        { required: true, message: '请输入账号' },
                        {
                            pattern: /^(?!\s)(?!.*\s$)/,
                            message: '账号前后不能有空格!',
                        },
                        // {
                        //     pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_]{1,20}$/,
                        //     message: '名称只能包含1-20位的汉字、字母、数字、下划线!',
                        // },
                    ]}
                >
                    <Input
                        placeholder="账号"
                        prefix={<MyIcon type="icon_login_user" className="site-form-item-icon" />}
                    />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: '请输入密码!',
                        },
                        {
                            pattern: /^.{6,20}$/,
                            message: '密码长度为6-20位!',
                        },
                        {
                            pattern: /^(?!\s)(?!.*\s$)/,
                            message: '密码前后不能有空格!',
                        },
                        // ({ getFieldValue }) => ({
                        //     validator(_, value) {
                        //         if (value.length >= 6 && value.length < 20) {
                        //             return Promise.resolve();
                        //         }else if(value.length === 0 || value.length == undefined){
                        //             return Promise.reject(new Error('请输入密码'));
                        //         }
                        //         return Promise.reject(new Error('密码长度为6-20位!'));
                        //     },
                        // }),
                    ]}
                >
                    <Input.Password
                        type="password"
                        placeholder="密码"
                        // ref="password"
                        prefix={<MyIcon className="site-form-item-icon" type="icon_login_password" />}
                    />
                </Form.Item>
                <Form.Item className="formForgot">
                    <a className="login-form-forgot" href="/forget" onClick={forgetClick}>
                        忘记密码?
                    </a>
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                        style={{
                            background: '#10A7B9',
                            border: '1px solid #10A7B9',
                        }}
                    >
                        登录
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};
export default AccountLogin;
