/*
 * @Descripttion:折线图组件(用电分析--- 数据预测 数据历史)
 * @Author: lipei
 * @Date: 2020-12-Th 09:46:06
 * @Last Modified by:   lipei
 * @Last Modified time: 2020-12-Th 09:46:06
 */
import React from 'react';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts';
import { peakvalue } from '../../../../util/function-util';

const Echartforecast = ({ predictions, style, names }) => {

    // getSource = (history, prediction) => {
    //     var source = [['data'], ['今日用电'], ['预测用电']]; //['无功功耗']
    //     var obj = {};
    //     history &&
    //         history.map(row => {
    //             obj[row.time] = { history: row.data };
    //         });
    //     prediction &&
    //         prediction.map(row => {
    //             obj[row.time] = { prediction: row.data };
    //         });
    //     for (var key in obj) {
    //         source[0].push(key);
    //         source[1].push(obj[key].history);
    //         source[2].push(obj[key].prediction);
    //     }
    //     return source;
    // };
    const getOption = predictions => {
        // const history = [],
        //     prediction = [];
        // if (predictions.total && predictions.prediction) {
        //     history = predictions.total.map(item => {
        //         return { time: item.time, data: item.amount };
        //     });
        //      prediction = predictions.prediction.map(item => {
        //         return { time: item.time, data: item.amount };
        //     });
        // }
        let prodictionTime = [];
        let totalTime = [];
        prodictionTime =
            predictions.prediction ?
            predictions.prediction.map(item => {
                return item.time;
            }) : [];
        totalTime =
            predictions.total &&
            predictions.total.map(item => {
                return item.time;
            });
        const times =
            totalTime && totalTime.length > 0
                ? prodictionTime.concat(totalTime)
                : [
                      '0:00',
                      '1:00',
                      '2:00',
                      '3:00',
                      '4:00',
                      '5:00',
                      '6:00',
                      '7:00',
                      '8:00',
                      '9:00',
                      '10:00',
                      '11:00',
                      '12:00',
                      '13:00',
                      '14:00',
                      '15:00',
                      '16:00',
                      '17:00',
                      '18:00',
                      '19:00',
                      '20:00',
                      '21:00',
                      '22:00',
                      '23:00',
                  ];
        const option = {
            legend: {
                data: [names, '预测用电'],
                orient: 'horizontal',
                x: 'center',
                y: 'top',
                top: '10',
                itemGap: 10,
                itemWidth: 12, // 设置宽度
                itemHeight: 8, // 设置高度
                icon: 'roundRect',
                textStyle: {
                    color: '#c7c7c7',
                    fontSize: 12,
                },
            },
            toolbox: {
                show: true,
                showTitle: true,
                orient: 'horizontal',
                itemGap: 15,
                itemSize: 16,
                zlevel: 10,
                z: 2,
                top: '5',
                right: '26',
                feature: {
                    restore: {
                        show: true,
                        title: '还原',
                        icon:
                            'path://M1011.324889 1023.859937H3.198687V9.384216a9.337528 9.337528 0 0 1 9.337528-9.337528 9.337528 9.337528 0 0 1 9.337528 9.337528v995.800665h989.404459a9.337528 9.337528 0 0 1 9.337527 9.337528 9.337528 9.337528 0 0 1-9.29084 9.337528z ' +
                            'M165.438234 883.283454a9.337528 9.337528 0 0 1-9.337528-9.337528v-282.600282a9.337528 9.337528 0 0 1 9.337528-9.337528 9.337528 9.337528 0 0 1 9.337528 9.337528v282.600282a9.337528 9.337528 0 0 1-9.337528 9.337528z ' +
                            'M664.295664 883.283454a9.337528 9.337528 0 0 1-9.337528-9.337528v-282.600282a9.337528 9.337528 0 0 1 9.337528-9.337528 9.337528 9.337528 0 0 1 9.337528 9.337528v282.600282a9.337528 9.337528 0 0 1-9.337528 9.337528z ' +
                            'M415.030356 901.911822a9.337528 9.337528 0 0 1-9.337528-9.337528V392.409611a9.337528 9.337528 0 0 1 9.337528-9.337528 9.337528 9.337528 0 0 1 9.337528 9.337528v500.164683a9.337528 9.337528 0 0 1-9.337528 9.337528z ' +
                            'M913.841098 901.95851a9.337528 9.337528 0 0 1-9.337528-9.337528v-421.682761a9.337528 9.337528 0 0 1 9.337528-9.337528 9.337528 9.337528 0 0 1 9.337528 9.337528v421.682761a9.337528 9.337528 0 0 1-9.337528 9.337528z ' +
                            'M165.438234 401.793827a9.337528 9.337528 0 0 1-7.376647-3.641636 9.337528 9.337528 0 0 1 1.680755-13.119227l252.953632-196.088086 253.140382 141.977112 225.314549-225.5013a9.337528 9.337528 0 0 1 13.212602 0 9.337528 9.337528 0 0 1 0.233438 13.212602l-28.012584 29.0864-207.573246 206.68618-254.681074-142.864177-243.149227 188.524689a9.337528 9.337528 0 0 1-5.74258 1.727443z ' +
                            'M912.440468 297.820453a9.337528 9.337528 0 0 1-9.337527-9.337528v-177.41303h-177.413031a9.337528 9.337528 0 0 1-9.337528-9.337528 9.337528 9.337528 0 0 1 9.337528-9.337528h196.088086v196.088086a9.337528 9.337528 0 0 1-9.337528 9.337528z',
                        // iconStyle:{
                        //     color: '#64D399',
                        //     borderColor: '#64D399',
                        // },
                        emphasis: {
                            color: '#64D399',
                            borderColor: '#64D399',
                        },
                    },
                    magicType: {
                        show: true,
                        type: ['line', 'bar'],
                        title: { line: '切换为折线图', bar: '切换为柱状图' },
                        icon: {
                            line:
                                'path://M1011.301541 1023.859937H3.175339V9.384216a9.337528 9.337528 0 0 1 9.337528-9.337528 9.337528 9.337528 0 0 1 9.337527 9.337528v995.800665h989.451147a9.337528 9.337528 0 0 1 9.337528 9.337528 9.337528 9.337528 0 0 1-9.337528 9.337528z M165.414886 691.070442a9.337528 9.337528 0 0 1-7.376647-3.594948 9.337528 9.337528 0 0 1 1.634068-13.119227l252.953631-196.088087 253.18707 141.977112 225.314549-225.501299a9.337528 9.337528 0 0 1 13.212602 0 9.337528 9.337528 0 0 1 0.233438 13.212602l-28.012584 29.0864-207.573246 206.639493-254.681074-142.864178-243.149227 188.524689a9.337528 9.337528 0 0 1-5.74258 1.727443z',
                            bar:
                                'path://M1006.948898 1023.953728H3.123503V13.881609a13.881609 13.881609 0 1 1 27.763217 0v982.355174h976.062178a13.881609 13.881609 0 1 1 0 27.763217z M168.592279 893.744239a23.136014 23.136014 0 0 1-23.136015-23.136015v-280.130863a23.136014 23.136014 0 0 1 46.272029 0v280.130863a23.136014 23.136014 0 0 1-23.136014 23.136015z M662.962635 893.744239a23.136014 23.136014 0 0 1-23.136014-23.136015v-280.130863a23.136014 23.136014 0 0 1 46.272029 0v280.130863a23.136014 23.136014 0 0 1-23.136015 23.136015z M415.916273 912.206778a23.136014 23.136014 0 0 1-23.136014-23.136014V393.358518a23.136014 23.136014 0 0 1 46.272029 0v495.712246a23.136014 23.136014 0 0 1-23.136015 23.136014z M910.379174 912.25305a23.136014 23.136014 0 0 1-23.136014-23.136014v-417.928966a23.136014 23.136014 0 0 1 46.272029 0v417.928966a23.136014 23.136014 0 0 1-23.136015 23.136014z',
                        },
                    },
                    saveAsImage: {
                        show: true,
                        title: '保存为图片',
                        icon:
                            'path://M510.1895 812.403663l-137.92293-137.876507a9.284613 9.284613 0 0 1 0-13.137728 9.284613 9.284613 0 0 1 13.184151 0l124.738779 124.738779 124.73878-124.738779a9.284613 9.284613 0 0 1 13.137728 0 9.284613 9.284613 0 0 1 0 13.137728z M1038.205458 1024H0v-402.441563a9.284613 9.284613 0 0 1 9.284613-9.284613 9.284613 9.284613 0 0 1 9.284614 9.284613v383.825913h1000.974159v-383.825913a9.284613 9.284613 0 0 1 9.284613-9.284613 9.284613 9.284613 0 0 1 9.284613 9.284613z M510.1895 791.46686a9.284613 9.284613 0 0 1-9.284613-9.284613V9.284613a9.284613 9.284613 0 0 1 9.284613-9.284613 9.284613 9.284613 0 0 1 9.284614 9.284613v772.897634a9.284613 9.284613 0 0 1-9.284614 9.284613z',
                    },
                },
                iconStyle: {
                    emphasis: {
                        color: '#64D399',
                        borderColor: '#64D399',
                    },
                },
                // emphasis: {
                //     color: '#64D399',
                //     iconStyle: {
                //         color: '#64D399',
                //         borderColor: '#64D399',
                //     },
                //     borderColor: '#64D399',
                // },
            },
            grid: {
                left: '5%',
                top: '18%',
                right: '5%',
                bottom: '8%',
                containLabel: true,
            },
            // animation:"auto",
            animationType: 'scale',
            animationEasing: 'cubicInOut', //cubicInOut elasticOut  progressive
            animationEasingUpdate: 'cubicInOut',
            animationDelay: function (idx) {
                return Math.random() * 200;
            },
            animationDuration: 1000,
            //animationDurationUpdate: 500, //数据更新动画的时长
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#B3CDE3',
                    },
                },
            },
            xAxis: {
                type: 'category',
                //minInterval: 1, //设置成1保证坐标轴分割刻度显示成整数。
                // data: predictions.total.map(item => {
                //     return item.time;
                // }),
                data: [...new Set(times)],
                min: 0, // 起始
                axisLabel: {
                    inside: false,
                    color: '#666',
                    fontSize: '12',
                    itemSize: '',
                    // interval: 0, //使x轴文字显示全
                },
                splitLine: {
                    show: false,
                    lineStyle: {
                        type: 'dotted',
                        opacity: '0.3',
                        color: '#666',
                    },
                },
                axisPointer: {
                    type: 'shadow',
                },
                axisLine: {
                    lineStyle: {
                        color: '#999',
                    },
                },
            },
            yAxis: {
                name: '单位:(kWh)',
                gridIndex: 0,
                // minInterval: 1, //设置成1保证坐标轴分割刻度显示成整数。
                min: 0, // 起始
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#999',
                    },
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        type: 'dotted',
                        opacity: '0.3',
                        color: '#666',
                    },
                },
            },
            dataZoom: [
                {
                    type: 'inside',
                    show: true,
                    start: 0,
                    end: 100,
                },
            ],
            series: [
                {
                    type: 'bar',
                    name: names,
                    // animation: true,
                    showBackground: true,
                    barWidth: 12,
                    barMinWidth: 12,
                    lineStyle: {
                        // normal: {
                        color: {
                            type: 'linear',
                            colorStops: [
                                {
                                    offset: 1,
                                    color: '#12dbff',
                                },
                            ],
                        },
                        // },
                    },
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            { offset: 0, color: '#ACF7EF' },
                            { offset: 0.5, color: '#8FEBE5' },
                            { offset: 1, color: '#0FCCC5' },
                        ]),
                        bordRadius: [10, 10, 0, 0], //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
                        borderRadius: [10, 10, 0, 0], //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
                    },
                    emphasis: {
                        bordRadius: [10, 10, 0, 0],
                        borderRadius: [10, 10, 0, 0],
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: '#0FCCC5' },
                                { offset: 0.7, color: '#8FEBE5' },
                                { offset: 1, color: '#ACF7EF' },
                            ]),
                        },
                    },
                    barGap: '0%',
                    data:
                        predictions.total &&
                        predictions.total.map(item => {
                            return peakvalue(item.amount);
                        }),
                    markPoint: {
                        itemStyle: {
                            normal: {
                                label: {
                                    show: true,
                                    color: '#fff', //气泡中字体颜色
                                },
                            },
                        },
                        data: [
                            { type: 'max', name: '最大值' },
                            { type: 'min', name: '最小值' },
                        ],
                    },
                    zlevel: 444,
                    z: 22,
                },
                {
                    type: 'line',
                    name: '预测用电',
                    icon: 'circle',
                    smooth: true, //线条的平滑 平滑曲线
                    symbolSize: 3,
                    // animation: true,
                    // coordinateSystem: 'cartesian2d',
                    animationEasing: 'linear',
                    progressive: 400, //渐进式渲染时每一帧绘制图形数量，设为 0 时不启用渐进式渲染，支持每个系列单独配置。
                    progressiveThreshold: 3000, //启用渐进式渲染的图形数量阈值，在单个系列的图形数量超过该阈值时启用渐进式渲染。
                    // hoverLayerThreshold: Infinity,
                    barWidth: 12,
                    barMinWidth: 12,
                    lineStyle: {
                        // normal: {
                        color: {
                            type: 'linear',
                            colorStops: [
                                {
                                    offset: 1,
                                    color: '#F0CF42',
                                },
                            ],
                        },
                        // },
                    },
                    itemStyle: {
                        color: '#F0CF42',
                        bordRadius: [10, 10, 0, 0],
                    },
                    emphasis: {
                        bordRadius: [10, 10, 0, 0],
                    },
                    markPoint: {
                        itemStyle: {
                            normal: {
                                label: {
                                    show: true,
                                    color: '#fff', //气泡中字体颜色
                                },
                            },
                        },
                        data: [
                            { type: 'max', name: '最大值' },
                            { type: 'min', name: '最小值' },
                        ],
                    },
                    data: predictions.prediction &&
                        predictions.prediction.map(item => {
                            return peakvalue(item.amount);
                        }),
                },
            ],
        };
        return option;
    };
    const option = getOption(predictions);
    if (!option) {
        return <></>;
    }
    return <ReactEcharts option={option} style={style} className="react_for_echarts" />;
};
export default Echartforecast;
