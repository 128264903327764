import { message } from 'antd';
const errorToast = (msg, callback) => {
    message.error(msg, 2, callback);
};
const successToast = (msg, callback) => {
    message.success(msg, 2, callback);
};
const loadingToast = (msg, callback) => {
    message.loading(msg, 2, callback);
};

// export default {
//     errorToast,
//     successToast,
// };

const exports = {
    errorToast,
    successToast,
    loadingToast,
}
export default exports;
