import React, { useState, useEffect } from 'react';
import { Table, ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import { BreadcrumbCustom } from '../../components/BreadcrumbCustom';
import { getLog } from '../../api/webServe';

// 操作日志
const Operationlog = () => {
    return (
        <div className="container clearfix">
            <BreadcrumbCustom paths={['操作日志']} className="rumbCustom" />
            <div className="row clearfix" style={{ width: '100%' }}>
                {/* <div className="row clearfix" style={{padding:'1rem 1rem 0',margin: '1rem', backgroundColor: '#ffffff', borderRadius: '10px'}}> */}
                <LogTable />
            </div>
        </div>
    );
};

const LogTable = () => {
    const [data, setData] = useState({ data: [], isLoading: true });
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [page_size, setPageSize] = useState(15);
    useEffect(() => {
        setData({ data: [], isLoading: true });
        getLog(page, page_size)
            .then(result => {
                setData({ data: result.data, isLoading: false });
                setCount(result.count);
            })
            .catch();
    }, [page, page_size]);
    // const stateContrast = {
    //     10001: '登录',
    //     10002: '退出登录',
    //     20001: '创建设备分类',
    //     20002: '删除设备分类',
    //     20011: '新增设备',
    //     20012: '删除设备',
    //     20013: '编辑设备',
    //     20014: '查看设备',
    //     20021: '新增用户',
    //     20022: '删除用户',
    //     20023: '修改用户',
    //     20024: '查看用户',
    // };
    const columns = [
        {
            title: '序号',
            dataIndex: 'id',
            key: 'id',
            width: 100, //宽度
            render: (text, record, index) => `${index + 1}`,
        },
        {
            title: '用户',
            dataIndex: 'uname', //enterprise uname
            render: text => (
                <p
                    style={{
                        minWidth: '4rem',
                        maxWidth: '8rem',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                    }}
                    title={text}
                >
                    {text}
                </p>
            ),
        },
        {
            title: '终端',
            dataIndex: 'client',
            render: text => (
                <p
                    style={{
                        minWidth: '4rem',
                        maxWidth: '8rem',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                    }}
                    title={text}
                >
                    {text}
                </p>
            ),
        },
        {
            title: 'IP地址',
            dataIndex: 'source_addr',
            render: text => (
                <p
                    style={{
                        minWidth: '6rem',
                        maxWidth: '12rem',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                    }}
                    title={text}
                >
                    {text}
                </p>
            ),
        },
        {
            title: '操作',
            dataIndex: 'action',
            // render: text => <span>{stateContrast[text]}</span>,
            width: 160,
            render: text => <span>{text}</span>,
        },
        {
            title: '创建时间',
            dataIndex: 'create_time',
            width: 200,
            render: text => <span>{text && text.slice(0, 10) + ' ' + text.slice(11, 19)}</span>,
        },
        // {
        //   title: '操作',
        //   dataIndex: 'opera',
        //   render: (text, record) =>
        //     <div className='opera'>
        //       {/* <span onClick={() => editClick(record.key)}> */}
        //       <span>
        //         <EditOutlined /> 编辑
        //           </span>
        //       <span><Popconfirm title="确定要删除吗?" onConfirm={() => this.onDelete(record.key)}> <DeleteOutlined />删除 </Popconfirm></span>
        //     </div>
        // }
    ];
    const paginationNo = {
        pageSize: page_size, //每页的条数
        current: page, //当前页
        total: count, //总数据
        onChange: page => {
            setPage(page);
        }, //点击分页号时触发的方法
        onShowSizeChange: (current, pageSize) => {
            setPageSize(pageSize);
        }, //pageSize 变化的回调
        hideOnSinglePage: true, //为true则数据条数不满或刚好一页，则隐藏分页器
        pageSizeOptions: ['10', '15', '20', '30', '50', '100'], // 分页类别
        showTotal: total => `共 ${total} 条`,
    };
    return (
        <div className="tableBox">
            <ConfigProvider locale={zhCN}>
                <Table
                    // rowSelection={rowSelection}
                    rowKey={record => record.id}
                    columns={columns}
                    dataSource={data.data}
                    bordered={true}
                    scroll={{ x: '100%' }}
                    className="formTable"
                    loading={data.isLoading}
                    style={{ textAlign: 'center' }}
                    pagination={paginationNo}
                />
            </ConfigProvider>
        </div>
    );
};

export default Operationlog;
