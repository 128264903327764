import React, { Component } from 'react';
import { Tabs } from 'antd';

import AccountLogin from './account-login';
import MessageLogin from './message-login';
import history from '../../api/history';
import './index.less';
import {LoginLogo} from '../../assets/image'

const { TabPane } = Tabs;

class LoginForm extends Component {
    state = {
        tagKey: '1'
    }

    /**
     * 本地缓存（localStorage） 刷新后，账号登录和验证码登录的显示状态
    */
    callbackTagKey = (key) => {
        localStorage.setItem('login_tag_key', key);
        this.setState({
            tagKey: localStorage.getItem('login_tag_key')
        });
    }

    /**
     * 渲染tag组件
     * AccountLogin
     * MessageLogin
     */
    renderTag = () => {
        return (
            <Tabs
                defaultActiveKey={1}
                // activeKey={localStorage.getItem('login_tag_key')}
                onTabClick={this.callbackTagKey}
                animated={false}
                // tabBarGutter={30} 
                tabPosition={'top'}
                tabBarStyle={{ color: 'rgba(28, 174, 250, 0.4)', margin: 'auto auto', textAlign: 'center', }}
            >
                <TabPane tab="账号登录" key="1">
                    <AccountLogin history={history} />
                </TabPane>
                <TabPane tab="验证码登录" key="2">
                    <MessageLogin />
                </TabPane>
            </Tabs>
        )
    }

    render() {
        return (
            <div className="loginWrap">
                <div className="contentWrap">
                    <div className="leftSide">
                        {/* <img src={loginBg} className="loginBg" alt="login"/> */}
                    </div>
                    <div className="loginForm">
                        <div className="title">
                            <img src={LoginLogo} alt="Finsiot" className="logo" />
                        </div>
                        {this.renderTag()}
                    </div>
                </div>
            </div>
        )
    }
}
export default LoginForm;