import { omit } from 'lodash';
import { get, post, put, remove } from '../util/NetUtils';

export const getUserInfo = params => get('/v1/oauth/user/', params); //用户管理 //用户名模糊查询
export const getUserInfoDetails = params => get(`/v1/oauth/user/${params}/`); //用户管理详情
export const addUserInfo = params => post('/v1/oauth/user/', params);
export const editUserInfo = params => put(`/v1/oauth/user/${params.id}/`, omit(params, 'id'));
export const delUserInfo = params => remove('/v1/oauth/user/', params);
export const resetPassword = params => post('/v1/oauth/reset_password/', params); //重置密码
export const BatchOperation = params => put('/v1/oauth/user/', params);

export const getUserList = params => get('/v1/oauth/role/', params); //角色分配
export const addUser = params => post('/v1/oauth/role/', params);
export const getPermissionList = params => get('/v1/oauth/permission/', params);
export const getUserDetail = id => get('/v1/oauth/role/', { id }, { isDetail: true });
export const deleteUser = id => remove('/v1/oauth/role/', id);
export const editUser = params => put(`/v1/oauth/role/${params.id}/`, omit(params, 'id'));

export const getCategory = params => get('/v1/device/category/', params); //获取分类列表
export const getCategoryDetails = id => get('/v1/device/category/', { id }, { isDetail: true });
export const addCategory = params => post('/v1/device/category/', params);
export const changeCategory = params => put(`/v1/device/category/${params.id}/`, omit(params, 'id'));
export const delCategory = id => remove('/v1/device/category/', id);

export const getDevice = params => get('/v1/device/', params); // 获取设备列表
export const getDeviceRefresh = params => get(`/v1/device/${params.id}/`, omit(params, 'id')); // 获取单个设备刷新
export const getDeviceCategory = params => get('/v1/device/category/', params);
export const getDeviceDetails = id => get(`/v1/device/${id}/`, { isDetail: true });
export const getDeviceDetailsIcc = () => get('/v1/device/icc/');//卡状态列表
export const getFirmware = params => get('/v1/device/firmware/', params); // 获取固件列表
export const getEnterprise = params => get('/v1/device/enterprise/', params); // 获取公司列表
export const getUpdateStatus = params => get('/v1/device/update/status/', params); // 获取升级状态列表
export const addDevice = params => post('/v1/device/', params);
export const updateDevice = params => put('/v1/device/update/', params);//版本批量升级（自动升级）
export const upgradeFirmware = params => put('/v1/device/specify/update/', params);//自主升级
export const changeDevice = params => put(`/v1/device/${params.id}/`, omit(params, 'id'));
export const deployCamera = params => put(`/v1/device/${params.id}/config/camera/`, omit(params, 'id'));//设备配置
export const delDevice = id => remove('/v1/device/', id);
export const getDeviceExport = params => get('/v1/device/export/', params); // 设备导出
export const getDeviceImport = params => post('/v1/device/import/', params, { file: true }); // 设备导入
export const getVersion = params => get('/v1/device/version/', params); // 获取版本
export const addFirmware = params => post('/v1/device/firmware/', params);
export const changeFirmware = params => put(`/v1/device/firmware/${params.id}/`, omit(params, 'id'));
export const delFirmware = id => remove('/v1/device/firmware/', id);

// app管理
export const getFeedback = params => get('/v1/sys_setting/feedback/', params);
export const getFeedbackDetails = params => get(`/v1/sys_setting/feedback/${params.id}/`, omit(params, 'id'));
export const removeFeedback = params => remove('/v1/sys_setting/feedback/', params);

export const getBanner = params => get('/v1/sys_setting/banner/', params);
export const getBannerDetails = params => get(`/v1/sys_setting/banner/${params}/`);
export const addBanner = params => post('/v1/sys_setting/banner/', params, { file: true });
export const editBanner = params => put(`/v1/sys_setting/banner/${params.id}/`, omit(params, 'id'), { file: true });
export const removeBanner = params => remove('/v1/sys_setting/banner/', params);

// 图片上传阿里云先获取key
export const getImageKey = params => get('/v1/apps/image/', params);//参数说明：0 告警 1广告 2反馈 3系统logo 4巡检  5头像 6固件包
