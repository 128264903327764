import React from 'react';
import ReactEcharts from 'echarts-for-react';

export const ReportPieEchart = ({ rawData, color, style }) => {
    const getOption = () => {
        let option = {
            tooltip: {
                trigger: 'item',
                axisPointer: {
                    // 坐标轴指示器，坐标轴触发有效
                    type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
                },
            },
            grid: {
                top: '-5%',
            },
            legend: {
                type: 'scroll',
                orient: 'horizontal', //vertical
                data:
                    rawData &&
                    rawData.map(item => {
                        return item.name;
                    }),
                left: 'center',
                bottom: 'bottom',
                itemWidth: 12,
                itemHeight: 8,
                itemGap: 8,
                icon: 'circle',
                textStyle: {
                    color: '#595959',
                    fontSize: 12,
                },
                // formatter: function (name) {
                //     var index = 0;
                //     var clientlabels = data.map(item => {
                //         return item.name;
                //     });
                //     var clientcounts = data.map(item => {
                //         return item.value;
                //     });
                //     clientlabels.forEach(function (value, i) {
                //         if (value === name) {
                //             index = i;
                //         }
                //     });
                //     return name + '  ' + clientcounts[index] + '个';
                // },
            },
            series: [
                {
                    type: 'pie',
                    radius: '50%',
                    data:
                        rawData &&
                        rawData.map(item => {
                            return { name: item.name, value: item.value || item.amount };
                        }),
                    center: ['50%', '50%'],
                    itemStyle: {
                        normal: {
                            color: function (params) {
                                return color[params.dataIndex % color.length];
                            },
                        },
                    },
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)',
                        },
                    },
                    // roseType: 'radius',
                    label: {
                        color: '#666666',
                        formatter: '{b}：{d}%',
                    },
                    labelLine: {
                        smooth: 0.2,
                        length: 10,
                        length2: 20,
                    },

                    animationType: 'scale',
                    animationEasing: 'elasticOut',
                    animationDelay: function (idx) {
                        return Math.random() * 200;
                    },
                },
            ],
        };
        return option;
    };
    const option = getOption();
    return (
        <div style={{ backgroundColor: '#fff' }}>
            <ReactEcharts option={option} className="react_for_echarts" style={style} />
        </div>
    );
};
