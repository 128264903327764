import React, {useState, useEffect} from 'react';
import {Card, Row, Col, Typography, Tooltip} from 'antd';
import {isEmpty} from 'lodash';
import AnnularEchart from '../../../components/Echart/AnnularEchart';
import {Page} from '../../../components/Pagination';
import {EmptyView} from '../../../components/EmptyView';
import {Loading} from '../../../components/Loading';
import {getViewDevice} from '../../../api/webServe';
import {annularColorRank} from '../../../global';
import {peakvalue} from '../../../util/function-util';

export const SliderCard = () => {
    let name = ['监测设备在线率', '监测设备在线量'];
    const [page, setPage] = useState(1); //当前页
    const [page_size, setPageSize] = useState(6);
    const [data, setData] = useState({data: [], isLoading: true});
    const [total, setTotal] = useState(0); //数据总条数
    // const [start, setStart] = useState(0); //起始页
    // const [end, setEnd] = useState(6); //结束页
    useEffect(() => {
        getViewDevice({page: page, page_size: page_size})
            .then(result => {
                setData({data: result.data.data_list, isLoading: false});
                setTotal(result.data.count);
            })
            .catch(() => {
                setData({data: [], isLoading: false});
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);
    return (
        <div style={{minHeight: '32vh'}}>
            <Loading show={data.isLoading}>
                {isEmpty(data.data) ? (
                    <EmptyView description={'暂无数据'}/>
                ) : (
                    <>
                        <Row gutter={[16, 16]} style={{padding: '16px 40px'}}>
                            {data.data &&
                            data.data.map(item => {
                                return (
                                    <Col span={8} key={item.name}>
                                        <Card
                                            title={
                                                <div className="cardBox">
                                                    <Typography.Text ellipsis className="title" style={{width: '100%'}}
                                                                     title={item.name}>
                                                        {item.name}
                                                    </Typography.Text>
                                                    <div className="cardItem">
                                                        <div className="cardTitle">
                                                            <p className="cardItemName">{name[0]}</p>
                                                            <p className="cardItemValue">{peakvalue(item.proportion)}%</p>
                                                        </div>
                                                        <AnnularEchart
                                                            color={
                                                                item.proportion >= 0 && item.proportion < 60
                                                                    ? annularColorRank.wrong
                                                                    : item.proportion >= 60 && item.proportion < 75
                                                                        ? annularColorRank.well
                                                                        : item.proportion >= 75 && item.proportion < 85
                                                                            ? annularColorRank.fine
                                                                            : annularColorRank.good
                                                            }
                                                            seriesData={item}
                                                            style={{width: '55px', height: '55px'}}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            // style={{ width: 300 }}
                                        >
                                            <p className="cardItemName">
                                                {name[1]} : <span className="cardItemValue">{item.total}</span>
                                            </p>
                                        </Card>
                                    </Col>
                                );
                            })}
                        </Row>
                        <div className="sliderCardpaginations">
                            <Page
                                count={page_size}
                                total={total}
                                setPageSize={setPageSize}
                                page={page}
                                setPage={setPage}
                                // setStart={setStart}
                                // setEnd={setEnd}
                            />
                        </div>
                    </>
                )}
            </Loading>
        </div>
    );
};
