import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { fakeAuth } from '../util/fakeAuth';
import Index from '../views/Index';

const PrivateRoute = ({ Component, ...otherProps }) => {
    return (
        <Route
            {...otherProps}
            render={route => {
                return fakeAuth.authenticate() ? (
                    <Component {...route} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: route.location },
                        }}
                    />
                );
            }}
        />
    );
};
export default PrivateRoute;

export const DispatchRoute = props => {
    return (
        <Route
            {...props}
            render={route => {
                if (!!fakeAuth.authenticate()) {
                    return <Index {...route} />;
                }
                return (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: route.location },
                        }}
                    />
                );
            }}
        />
    );
};
