import React from 'react';
import ReactEcharts from 'echarts-for-react';
import { peakvalue } from '../../../../util/function-util';

export const ReportPieEchart2 = ({ rawData, color, style, total }) => {
    const getOption = () => {
        let option = {
            title: {
                text: total && `总\n\r{active|${(total ? peakvalue(total) : 0)}}`,
                top: 'center',
                left: 'center',
                textStyle: {
                    rich: {
                        total: {
                            fontSize: 14,
                            fontWeight: 'normal',
                            color: '#595959',
                            padding: [10, 0],
                        },
                        active: {
                            fontSize: 18,
                            lineHeight: 30,
                            fontWeight: 'bold',
                            color: '#333333',
                        },
                    },
                },
            },
            tooltip: {
                trigger: 'item',
                axisPointer: {
                    // 坐标轴指示器，坐标轴触发有效
                    type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
                },
            },
            grid: {
                top: '-5%',
            },
            legend: {
                type: 'scroll',
                orient: 'horizontal', //vertical
                data: rawData && rawData.map(item => {
                    return item.name;
                }),
                left: 'center',
                bottom: 'bottom',
                itemWidth: 12,
                itemHeight: 8,
                itemGap: 8,
                icon: 'circle',
                textStyle: {
                    color: '#595959',
                    fontSize: 12,
                },
                // formatter: function (name) {
                //     var index = 0;
                //     var clientlabels = data.map(item => {
                //         return item.name;
                //     });
                //     var clientcounts = data.map(item => {
                //         return item.value;
                //     });
                //     clientlabels.forEach(function (value, i) {
                //         if (value === name) {
                //             index = i;
                //         }
                //     });
                //     return name + '  ' + clientcounts[index] + '个';
                // },
            },
            series: [
                {
                    type: 'pie',
                    center: ['50%', '50%'],
                    radius: ['70%', '45%'],
                    data: rawData,
                    avoidLabelOverlap: false,
                    itemStyle: {
                        borderRadius: 5,
                        borderColor: '#fff',
                        borderWidth: 2,
                        color: function (params) {
                            return color[params.dataIndex % color.length];
                        },
                    },
                    emphasis: {
                        label: {
                            // show: true,
                            // fontSize: '40',
                            // fontWeight: 'bold',
                        },
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)',
                        },
                    },
                    label: {
                        show: true,
                        position: 'outside',
                        // color: '#666666',
                        formatter: `{d}%`,
                    },
                    labelLine: {
                        show: true,
                        smooth: 0.2,
                        length: 15,
                        length2: 20,
                    },
                    animationType: 'scale',
                    animationEasing: 'elasticOut',
                    animationDelay: function (idx) {
                        return Math.random() * 200;
                    },
                },
            ],
        };
        return option;
    };
    const option = getOption();
    return (
        <div style={{ backgroundColor: '#fff' }}>
            <ReactEcharts option={option} className="react_for_echarts" style={style} />
        </div>
    );
};
