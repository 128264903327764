import React, { useMemo } from 'react';
import { map, ceil } from 'lodash';
import { Chart, Series, Tooltip, Calendar, VisualMap } from '@echarts-start/react-bridge';
import { COLOR_PLATE_16, HeatmapChart_COLOR } from '../../../../global';
// const data = [
//     ['2017-01-01', 3767],
//     ['2017-01-02', 1734],
//     ['2017-01-03', 2535],
//     ['2017-01-04', 1516],
//     ['2017-01-05', 5847],
//     ['2017-01-06', 7995],
//     ['2017-01-07', 3580],
//     ['2017-01-08', 7854],
//     ['2017-01-09', 2516],
//     ['2017-01-10', 1064],
//     ['2017-01-11', 8474],
//     ['2017-01-12', 2726],
//     ['2017-01-13', 8801],
//     ['2017-01-14', 7399],
//     ['2017-01-15', 3219],
//     ['2017-01-16', 5305],
//     ['2017-01-17', 6831],
//     ['2017-01-18', 9849],
//     ['2017-01-19', 6184],
//     ['2017-01-20', 9044],
//     ['2017-01-21', 705],
//     ['2017-01-22', 7273],
//     ['2017-01-23', 4822],
//     ['2017-01-24', 11],
//     ['2017-01-25', 1943],
//     ['2017-01-26', 9830],
//     ['2017-01-27', 6997],
//     ['2017-01-28', 1508],
//     ['2017-01-29', 6120],
//     ['2017-01-30', 934],
//     ['2017-01-31', 929],
//     ['2017-02-01', 8207],
//     ['2017-02-02', 3315],
//     ['2017-02-03', 3910],
//     ['2017-02-04', 5020],
//     ['2017-02-05', 1274],
//     ['2017-02-06', 661],
//     ['2017-02-07', 8090],
//     ['2017-02-08', 1820],
//     ['2017-02-09', 9662],
//     ['2017-02-10', 4227],
//     ['2017-02-11', 9036],
//     ['2017-02-12', 6620],
//     ['2017-02-13', 4688],
//     ['2017-02-14', 9229],
//     ['2017-02-15', 1670],
//     ['2017-02-16', 9266],
//     ['2017-02-17', 238],
//     ['2017-02-18', 3750],
//     ['2017-02-19', 9171],
//     ['2017-02-20', 577],
//     ['2017-02-21', 6630],
//     ['2017-02-22', 4662],
//     ['2017-02-23', 6347],
//     ['2017-02-24', 4490],
//     ['2017-02-25', 5899],
//     ['2017-02-26', 3403],
//     ['2017-02-27', 4650],
//     ['2017-02-28', 708],
//     ['2017-03-01', 1018],
//     ['2017-03-02', 8257],
//     ['2017-03-03', 9937],
//     ['2017-03-04', 2537],
//     ['2017-03-05', 5112],
//     ['2017-03-06', 7536],
//     ['2017-03-07', 7272],
//     ['2017-03-08', 1668],
//     ['2017-03-09', 7187],
//     ['2017-03-10', 4144],
//     ['2017-03-11', 3000],
//     ['2017-03-12', 3402],
//     ['2017-03-13', 150],
//     ['2017-03-14', 8669],
//     ['2017-03-15', 6129],
//     ['2017-03-16', 8272],
//     ['2017-03-17', 3841],
//     ['2017-03-18', 7678],
//     ['2017-03-19', 6391],
//     ['2017-03-20', 9507],
//     ['2017-03-21', 4053],
//     ['2017-03-22', 8126],
//     ['2017-03-23', 7746],
//     ['2017-03-24', 502],
//     ['2017-03-25', 5371],
//     ['2017-03-26', 3663],
//     ['2017-03-27', 7738],
//     ['2017-03-28', 1116],
//     ['2017-03-29', 646],
//     ['2017-03-30', 4586],
//     ['2017-03-31', 4134],
//     ['2017-04-01', 9023],
//     ['2017-04-02', 4151],
//     ['2017-04-03', 4663],
//     ['2017-04-04', 1055],
//     ['2017-04-05', 9289],
//     ['2017-04-06', 8532],
//     ['2017-04-07', 5867],
//     ['2017-04-08', 9903],
//     ['2017-04-09', 3033],
//     ['2017-04-10', 7354],
//     ['2017-04-11', 4172],
//     ['2017-04-12', 3054],
//     ['2017-04-13', 843],
//     ['2017-04-14', 4791],
//     ['2017-04-15', 449],
//     ['2017-04-16', 9716],
//     ['2017-04-17', 3871],
//     ['2017-04-18', 7506],
//     ['2017-04-19', 7593],
//     ['2017-04-20', 7178],
//     ['2017-04-21', 322],
//     ['2017-04-22', 2814],
//     ['2017-04-23', 7415],
//     ['2017-04-24', 1370],
//     ['2017-04-25', 3136],
//     ['2017-04-26', 345],
//     ['2017-04-27', 2115],
//     ['2017-04-28', 8962],
//     ['2017-04-29', 7234],
//     ['2017-04-30', 6461],
//     ['2017-05-01', 5844],
//     ['2017-05-02', 141],
//     ['2017-05-03', 3495],
//     ['2017-05-04', 1923],
//     ['2017-05-05', 5331],
//     ['2017-05-06', 9653],
//     ['2017-05-07', 5765],
//     ['2017-05-08', 3008],
//     ['2017-05-09', 1300],
//     ['2017-05-10', 3908],
//     ['2017-05-11', 8751],
//     ['2017-05-12', 1747],
//     ['2017-05-13', 4058],
//     ['2017-05-14', 4017],
//     ['2017-05-15', 4141],
//     ['2017-05-16', 9780],
//     ['2017-05-17', 8609],
//     ['2017-05-18', 3372],
//     ['2017-05-19', 7646],
//     ['2017-05-20', 5974],
//     ['2017-05-21', 6111],
//     ['2017-05-22', 2801],
//     ['2017-05-23', 53],
//     ['2017-05-24', 880],
//     ['2017-05-25', 8544],
//     ['2017-05-26', 6582],
//     ['2017-05-27', 6835],
//     ['2017-05-28', 935],
//     ['2017-05-29', 2539],
//     ['2017-05-30', 6001],
//     ['2017-05-31', 6823],
//     ['2017-06-01', 617],
//     ['2017-06-02', 9759],
//     ['2017-06-03', 359],
//     ['2017-06-04', 2317],
//     ['2017-06-05', 4756],
//     ['2017-06-06', 6932],
//     ['2017-06-07', 5426],
//     ['2017-06-08', 2990],
//     ['2017-06-09', 5804],
//     ['2017-06-10', 3424],
//     ['2017-06-11', 8588],
//     ['2017-06-12', 3111],
//     ['2017-06-13', 8588],
//     ['2017-06-14', 7513],
//     ['2017-06-15', 9740],
//     ['2017-06-16', 9019],
//     ['2017-06-17', 1245],
//     ['2017-06-18', 2843],
//     ['2017-06-19', 1472],
//     ['2017-06-20', 3274],
//     ['2017-06-21', 4412],
//     ['2017-06-22', 4704],
//     ['2017-06-23', 8519],
//     ['2017-06-24', 7882],
//     ['2017-06-25', 8407],
//     ['2017-06-26', 3395],
//     ['2017-06-27', 6791],
//     ['2017-06-28', 4452],
//     ['2017-06-29', 2489],
//     ['2017-06-30', 9764],
//     ['2017-07-01', 7609],
//     ['2017-07-02', 895],
//     ['2017-07-03', 3294],
//     ['2017-07-04', 513],
//     ['2017-07-05', 8200],
//     ['2017-07-06', 8489],
//     ['2017-07-07', 6056],
//     ['2017-07-08', 495],
//     ['2017-07-09', 2731],
//     ['2017-07-10', 3332],
//     ['2017-07-11', 3470],
//     ['2017-07-12', 6179],
//     ['2017-07-13', 165],
//     ['2017-07-14', 3204],
//     ['2017-07-15', 70],
//     ['2017-07-16', 9852],
//     ['2017-07-17', 8388],
//     ['2017-07-18', 9528],
//     ['2017-07-19', 5443],
//     ['2017-07-20', 4850],
//     ['2017-07-21', 4776],
//     ['2017-07-22', 7730],
//     ['2017-07-23', 6857],
//     ['2017-07-24', 4893],
//     ['2017-07-25', 9453],
//     ['2017-07-26', 5899],
//     ['2017-07-27', 97],
//     ['2017-07-28', 9398],
//     ['2017-07-29', 8657],
//     ['2017-07-30', 4726],
//     ['2017-07-31', 3465],
//     ['2017-08-01', 4330],
//     ['2017-08-02', 3642],
//     ['2017-08-03', 2693],
//     ['2017-08-04', 6906],
//     ['2017-08-05', 7356],
//     ['2017-08-06', 611],
//     ['2017-08-07', 6123],
//     ['2017-08-08', 4931],
//     ['2017-08-09', 3884],
//     ['2017-08-10', 3277],
//     ['2017-08-11', 7980],
//     ['2017-08-12', 1977],
//     ['2017-08-13', 8263],
//     ['2017-08-14', 7353],
//     ['2017-08-15', 5749],
//     ['2017-08-16', 4475],
//     ['2017-08-17', 1538],
//     ['2017-08-18', 7701],
//     ['2017-08-19', 6683],
//     ['2017-08-20', 4562],
//     ['2017-08-21', 9223],
//     ['2017-08-22', 9618],
//     ['2017-08-23', 3538],
//     ['2017-08-24', 178],
//     ['2017-08-25', 2521],
//     ['2017-08-26', 6410],
//     ['2017-08-27', 8496],
//     ['2017-08-28', 4467],
//     ['2017-08-29', 9997],
//     ['2017-08-30', 2733],
//     ['2017-08-31', 4300],
//     ['2017-09-01', 319],
//     ['2017-09-02', 8552],
//     ['2017-09-03', 9271],
//     ['2017-09-04', 4252],
//     ['2017-09-05', 3529],
//     ['2017-09-06', 4715],
//     ['2017-09-07', 7156],
//     ['2017-09-08', 1216],
//     ['2017-09-09', 8056],
//     ['2017-09-10', 2504],
//     ['2017-09-11', 4786],
//     ['2017-09-12', 2763],
//     ['2017-09-13', 642],
//     ['2017-09-14', 1965],
//     ['2017-09-15', 303],
//     ['2017-09-16', 6926],
//     ['2017-09-17', 6375],
//     ['2017-09-18', 974],
//     ['2017-09-19', 9366],
//     ['2017-09-20', 8274],
//     ['2017-09-21', 496],
//     ['2017-09-22', 9109],
//     ['2017-09-23', 9792],
//     ['2017-09-24', 1702],
//     ['2017-09-25', 9847],
//     ['2017-09-26', 3341],
//     ['2017-09-27', 8115],
//     ['2017-09-28', 1886],
//     ['2017-09-29', 8397],
//     ['2017-09-30', 2395],
//     ['2017-10-01', 7558],
//     ['2017-10-02', 3430],
//     ['2017-10-03', 4124],
//     ['2017-10-04', 6535],
//     ['2017-10-05', 8311],
//     ['2017-10-06', 8479],
//     ['2017-10-07', 6724],
//     ['2017-10-08', 4714],
//     ['2017-10-09', 1316],
//     ['2017-10-10', 80],
//     ['2017-10-11', 8233],
//     ['2017-10-12', 9486],
//     ['2017-10-13', 5788],
//     ['2017-10-14', 769],
//     ['2017-10-15', 4548],
//     ['2017-10-16', 9907],
//     ['2017-10-17', 6351],
//     ['2017-10-18', 536],
//     ['2017-10-19', 1916],
//     ['2017-10-20', 5877],
//     ['2017-10-21', 4884],
//     ['2017-10-22', 1281],
//     ['2017-10-23', 7725],
//     ['2017-10-24', 4248],
//     ['2017-10-25', 7031],
//     ['2017-10-26', 2273],
//     ['2017-10-27', 9594],
//     ['2017-10-28', 1326],
//     ['2017-10-29', 5105],
//     ['2017-10-30', 7598],
//     ['2017-10-31', 4540],
//     ['2017-11-01', 4066],
//     ['2017-11-02', 7484],
//     ['2017-11-03', 7780],
//     ['2017-11-04', 2612],
//     ['2017-11-05', 5042],
//     ['2017-11-06', 1866],
//     ['2017-11-07', 6718],
//     ['2017-11-08', 2907],
//     ['2017-11-09', 8416],
//     ['2017-11-10', 6229],
//     ['2017-11-11', 9911],
//     ['2017-11-12', 1267],
//     ['2017-11-13', 5700],
//     ['2017-11-14', 5416],
//     ['2017-11-15', 6591],
//     ['2017-11-16', 9391],
//     ['2017-11-17', 6056],
//     ['2017-11-18', 1169],
//     ['2017-11-19', 1894],
//     ['2017-11-20', 7012],
//     ['2017-11-21', 2926],
//     ['2017-11-22', 5109],
//     ['2017-11-23', 6191],
//     ['2017-11-24', 7993],
//     ['2017-11-25', 909],
//     ['2017-11-26', 9415],
//     ['2017-11-27', 4329],
//     ['2017-11-28', 3853],
//     ['2017-11-29', 4067],
//     ['2017-11-30', 1911],
//     ['2017-12-01', 7553],
//     ['2017-12-02', 7703],
//     ['2017-12-03', 373],
//     ['2017-12-04', 3731],
//     ['2017-12-05', 4307],
//     ['2017-12-06', 5732],
//     ['2017-12-07', 8011],
//     ['2017-12-08', 5377],
//     ['2017-12-09', 7408],
//     ['2017-12-10', 8716],
//     ['2017-12-11', 7448],
//     ['2017-12-12', 2669],
//     ['2017-12-13', 3289],
//     ['2017-12-14', 2059],
//     ['2017-12-15', 6914],
//     ['2017-12-16', 3697],
//     ['2017-12-17', 2737],
//     ['2017-12-18', 4138],
//     ['2017-12-19', 465],
//     ['2017-12-20', 7584],
//     ['2017-12-21', 6155],
//     ['2017-12-22', 890],
//     ['2017-12-23', 6509],
//     ['2017-12-24', 9403],
//     ['2017-12-25', 6169],
//     ['2017-12-26', 6928],
//     ['2017-12-27', 7423],
//     ['2017-12-28', 3120],
//     ['2017-12-29', 6006],
//     ['2017-12-30', 6403],
//     ['2017-12-31', 6030],
// ];
const month = new Array(12);
const monthLabel = {
    nameMap: map(month, (_, index) => `${index + 1}月`),
    position: 'end',
    fontSize: 18,
    fontWeight: 800,
};
const dayLabel = { nameMap: 'cn', firstDay: 1 };
export const HeatmapChart = ({ rawData }) => {
    const { source, year, max } = useMemo(() => {
        let max = 0;
        const source = [];
        const len = rawData.length;
        map(rawData, item => {
            map(item.data, everyOne => {
                if (max < everyOne.amount) {
                    max = ceil(everyOne.amount);
                }
                source.push([`${item.date}-${everyOne.interval}`, everyOne.amount]);
            });
        });
        const strMax = max + '';
        const length = strMax.length;
        max = ceil(max, -(length - 2));
        const minData = rawData[0].data;
        const maxData = rawData[len - 1].data;
        const minTime = minData[0].interval;
        const maxTime = maxData[maxData.length - 1].interval;
        const year = [rawData[0].date + '-' + minTime, rawData[len - 1].date + '-' + maxTime];
        return { source, year, max };
    }, [rawData]);
    return (
        <Chart color={COLOR_PLATE_16} noCoordinate={true} style={{ width: '100%', height: '100%' }} resize={true}>
            <Tooltip trigger="item" />
            <Calendar
                options={{
                    id: 'option',
                    range: year,
                    monthLabel,
                    dayLabel,
                    // left: '10%',
                    // top: '8%',
                    cellSize: ['auto', 25],
                }}
            />
            <VisualMap
                options={{
                    id: 'option',
                    min: 0,
                    max,
                    itemSymbol: 'circle',
                    type: 'piecewise',
                    orient: 'horizontal',
                    inRange: {
                        symbol: 'circle',
                        color: HeatmapChart_COLOR,
                    },
                    left: 'center',
                    bottom: 20,
                }}
            />
            <Series
                options={{
                    id: 'option',
                    type: 'heatmap',
                    coordinateSystem: 'calendar',
                    data: source,
                }}
            />
            {/*<Dataset id="heatmapChart" source={source} />*/}
        </Chart>
    );
};
