import React, { useImperativeHandle, useState } from 'react';
import { Button, Modal } from 'antd';
import { isEmpty } from 'lodash';
let onOK = null;
export const DialogBox = ({ childRef }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [title, setTitle] = useState('提示');
    const [width, setWidth] = useState();
    const [content, setContent] = useState('');
    const [loading, setLoading] = useState(false);
    const onShow = (content, title, _onOK, width) => {
        onOK = _onOK;
        setContent(content);
        !isEmpty(title) && setTitle(title);
        setIsModalVisible(true);
        setWidth(width)
    };
    const onHidden = () => {
        setIsModalVisible(false);
    };
    const showLoading = () => {
        setLoading(true);
    };
    const hiddenLoading = () => {
        setLoading(false);
    };
    const _onOk = () => {
        showLoading();
        onOK &&
            onOK(() => {
                hiddenLoading();
                onHidden();
            });
    };
    useImperativeHandle(childRef, () => ({
        onShow,
        onHidden,
        showLoading,
        hiddenLoading,
    }));
    return (
        <Modal
            onCancel={onHidden}
            maskClosable={false}
            keyboard={false}
            closable={false}
            style={{ top: '20vh' }}
            width={width}
            visible={isModalVisible}
            title={<Title title={title} />}
            footer={
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ padding: '0 15px' }}>
                        <Button disabled={loading} onClick={onHidden}>
                            取消
                        </Button>
                    </div>
                    <div style={{ padding: '0 15px' }}>
                        <Button loading={loading} onClick={_onOk} type="primary">
                            确定
                        </Button>
                    </div>
                </div>
            }
        >
            <div style={{ fontSize: '14px', color: '#333333' }}>{content}</div>
        </Modal>
    );
};

const Title = ({ title }) => {
    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{title}</div>;
};
