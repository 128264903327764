import React, { useMemo } from 'react';
import { map, some } from 'lodash';
import { Chart, Series, Tooltip } from '@echarts-start/react-bridge';
import { COLOR_PLATE_16 } from '../../../../global';
export const SankeyChart = ({ rawData }) => {
    const { node, links } = useMemo(() => {
        const links = [];
        const node = [{ name: rawData[0].name }];
        const loop = (data, source) => {
            map(data, item => {
                !some(node, ['name', item.name]) && node.push({ name: item.name });
                links.push({ source, target: item.name, value: Math.round(item.amount*100)/100 });
                loop(item.data, item.name);
            });
        };
        loop(rawData[0].data, rawData[0].name);
        return { node, links };
    }, [rawData]);
    //过滤区域和设备同名的数据
    // var nodes = [...new Set(node)]
    // var link = links.filter((item,index) => {
    //     if(item.source !== item.target){
    //         return links
    //     }else if(item.source == item.target){
    //         console.log(item,index)
    //     }
    // });
    return (
        <Chart color={COLOR_PLATE_16} noCoordinate={true} style={{ width: '100%', height: '100%' }} resize>
            <Tooltip trigger="item" formatter={params => { return '<p>' + params.name + ' :   ' + (params.value !== 0 ? params.value.toFixed(2) : 0) + '</p>' }}/>
            <Series
                options={{
                    left: 30,
                    top: 80,
                    right: 100,
                    bottom: 10,
                    data: node,
                    links,
                    type: 'sankey',
                    emphasis: {
                        focus: 'adjacency',
                    },
                    lineStyle: {
                        curveness: 0.5,
                    },
                    levels: [
                        {
                            depth: 0,
                            itemStyle: {
                                color: '#A5DAD4',
                            },
                            lineStyle: {
                                color: 'source',
                                opacity: 0.6,
                            },
                        },
                        {
                            depth: 1,
                            itemStyle: {
                                color: '#B3CDE3',
                            },
                            lineStyle: {
                                color: 'source',
                                opacity: 0.6,
                            },
                        },
                        {
                            depth: 2,
                            itemStyle: {
                                color: '#9CCAE2',
                            },
                            lineStyle: {
                                color: 'source',
                                opacity: 0.6,
                            },
                        },
                        {
                            depth: 3,
                            itemStyle: {
                                color: '#88AFC0',
                            },
                            lineStyle: {
                                color: 'source',
                                opacity: 0.6,
                            },
                        },
                    ],
                }}
            />
        </Chart>
    );
};
