/*
 * @Descripttion:环形组件
 * @Author: lipei
 * @Date: 2021-16-Th 09:46:06
 * @Last Modified by:   lipei
 * @Last Modified time: 2021-16-Th 09:46:06
 */
import React, { Component } from 'react';
import ReactEcharts from 'echarts-for-react';
// import * as echarts from 'echarts';
// import { unitControl } from '../../util/function-util';
import { peakvalue } from '../../util/function-util';

/**
 * 企业概览- 设备分布
 */
export default class RingEchart extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    getOption = () => {
        let { seriesData, text, name, color, legend, total } = this.props;
        let option = {
            // animationType: 'scale',
            // animationEasing: 'elasticOut',
            // animationDelay: function (idx) {
            //     return Math.random() * 200;
            // },
            // animationDuration: 1000,
            color: color,
            grid: {
                top: '5%',
                left: 0,
                right: '1%',
                bottom: 5,
                containLabel: true,
            },
            title: {
                text: text,
                subtext: total + '个',
                textStyle: {
                    fontSize: 18,
                    color: 'black',
                },
                subtextStyle: {
                    fontSize: 18,
                    color: 'black',
                },
                textAlign: 'center',
                x: '39.5%',
                y: '39%',
            },
            tooltip: {
                trigger: 'item',
                formatter: function (parms) {
                    var str =
                        parms.seriesName +
                        '</br>' +
                        parms.marker +
                        '' +
                        parms.data.name +
                        '</br>' +
                        '数量：' +
                        peakvalue(parms.data.value) +
                        '</br>' +
                        '占比：' +
                        peakvalue(parms.percent) +
                        '%';
                    return str;
                },
            },
            legend: {
                orient: 'vertical',
                top: 'center',
                right: 40,
                itemGap: 6,
                itemWidth: 16,
                itemHeight: 10,
                textStyle: {
                    align: 'left',
                    verticalAlign: 'middle',
                    rich: {
                        name: {
                            color: 'rgba(0,0,0,0.6)',
                            fontSize: 12,
                        },
                        value: {
                            color: 'rgba(0,0,0,0.9)',
                            fontSize: 14,
                        },
                        rate: {
                            color: 'rgba(0,0,0,0.9)',
                            fontSize: 14,
                        },
                    },
                },
                data: legend,
                formatter: name => {
                    if (seriesData.length) {
                        const item = seriesData.filter(item => item.name === name)[0];
                        return `{name|${name.length > 6 ? name.substr(0,6) + '... ' : name }}{value| ${item.value }} {rate| ${peakvalue(item.value === 0 ? item.value : (item.value / total)*100)}%}`;
                    }
                },
            },
            series: [
                {
                    name: name,
                    type: 'pie',
                    center: ['40%', '50%'],
                    radius: ['45%', '70%'],
                    label: {
                        normal: {
                            show: false,
                            position: 'center',
                            formatter: '{value|{c}}\n{label|{b}}',
                            rich: {
                                value: {
                                    padding: 5,
                                    align: 'center',
                                    verticalAlign: 'middle',
                                    fontSize: 32,
                                },
                                label: {
                                    align: 'center',
                                    verticalAlign: 'middle',
                                    fontSize: 16,
                                },
                            },
                        },
                        // emphasis: {//鼠标移入显示对应的信息
                        //     show: true,
                        //     textStyle: {
                        //         fontSize: '12',
                        //     },
                        // },
                    },
                    labelLine: {
                        show: false,
                        length: 0,
                        length2: 0,
                    },
                    itemStyle: {
                        // borderRadius: 3,
                        // borderColor: '#fff',
                        // borderWidth: 2,
                    },
                    data: seriesData,
                },
            ],
        };
        return option;
    };
    render() {
        const option = this.getOption();
        return (
            <ReactEcharts
                ref={ref => (this.echarts = ref)}
                option={option}
                style={this.props.style}
                // className='react_for_echarts'
            />
        );
    }
}
