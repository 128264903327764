import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Row, Col, Tabs, Radio, DatePicker, ConfigProvider } from 'antd';
import { isEmpty, omitBy, isUndefined, isBoolean, includes } from 'lodash';
import moment from 'moment';
import { MyIcon } from '../../../util/iconfont';
import { BreadcrumbCustom } from '../../../components/BreadcrumbCustom';
import EchartBar from '../../../components/Echart/OneBarEchart';
import { CommonModal } from '../../../components/CommoModal';
import { CommonTree } from '../../../components/CommonTree2';
import PatrolItem from '../../../components/PatrolItem';
import EditForm from './EditForm';
import { EmptyView } from '../../../components/EmptyView';
import { Loading } from '../../../components/Loading';
import { getPatrol, getPatrolCategoryTag, getPatrolTag, treeHasIcon } from '../../../api/webServe';
import { day30, day7, month12, toDay } from '../../../util/timeUtil';
import { removeEmpty } from '../../../util/function-util';
import zhCN from 'antd/lib/locale/zh_CN';
import 'moment/locale/zh-cn';
import patrolStyles from './patrol.module.less';
const { TabPane } = Tabs;
const style = {
    fontSize: '1.3rem',
    verticalAlign: 'middle',
    marginRight: '0.3rem',
};
const { RangePicker } = DatePicker;

const List = data => {
    return (
        <Row gutter={[32, 16]} style={{ paddingRight: '1rem' }}>
            <PatrolItem
                data={[
                    {
                        num: removeEmpty(data.data.day_power) + ' kWh',
                        names: '今日用电',
                    },
                    {
                        num: removeEmpty(data.data.month_power) + ' kWh',
                        names: '本月用电',
                    },
                ]}
            />
            <PatrolItem name={'单相电流'} value={removeEmpty(data.data.single_phase_current) + 'A'} />
            <PatrolItem
                name={'电流'}
                value={[
                    {
                        num: removeEmpty(data.data.la_current) + ' A',
                        names: 'la',
                    },
                    {
                        num: removeEmpty(data.data.lb_current) + ' A',
                        names: 'lb',
                    },
                    {
                        num: removeEmpty(data.data.lc_current) + ' A',
                        names: 'lc',
                    },
                ]}
            />
            <PatrolItem name={'剩余电流'} value={removeEmpty(data.data.last_current) + ' A'} />
            <PatrolItem name={'电压'} value={removeEmpty(data.data.voltage) + ' V'} />
            <PatrolItem name={'功率因数'} value={removeEmpty(data.data.power_factor)} />
            <PatrolItem name={'线缆温度'} value={removeEmpty(data.data.cable_temperature) + ' ℃'} />
            <PatrolItem
                name={'智能烟宝'}
                value={isUndefined(data.data.smoke_state) ? '-' : data.data.smoke_state ? '正常' : '异常'}
            />
        </Row>
    );
};

const renderEchart = (tag, category, setParams, params) => {
    //Tabs
    const callback = key => {
        // setParams({ name: key, ...params });
        setParams({ name: key });
    };
    let yData = [],
        xTime = [];
    tag.data &&
        tag.data.forEach(item => {
            yData.push(item.amount);
            xTime.push(item.time);
        });
    return (
        <Tabs
            onChange={callback}
            type="card"
            style={{ minHeight: '52vh', maxHeight: '60vh', height: '52vh', paddingLeft: '0rem' }}
            tabBarStyle={{
                borderTopLeftRadius: '10px',
                borderTopRightRadius: '10px',
                paddingTop: '1rem',
                paddingLeft: '1rem',
                boxShadow: '0px 2px 3px #e7ebeb',
                background: 'linear-gradient(180deg, #fefefe 0%, #f5f6fa 100%)',
            }}
        >
            {category &&
                category.map((item, index) => {
                    return (
                        <TabPane tab={item.name} key={item.name} style={{ height: '100%', float: 'left' }}>
                            <Col
                                span={24}
                                style={{
                                    // boxShadow: '0px 2px 3px #e7ebeb',
                                    // background: 'linear-gradient(180deg, #f5f6fa 0%, #f5f6fa 100%)',
                                    paddingBottom: '1rem',
                                    paddingTop: '1rem',
                                }}
                            >
                                <TimeSearch
                                    onValueChange={times => {
                                        const { time } = times;
                                        // getPie(time);
                                        setParams({ ...params, start_time: time[0], end_time: time[1] });
                                    }}
                                />
                            </Col>
                            <Loading show={tag.isLoading}>
                                {isEmpty(tag.data) ? (
                                    <EmptyView />
                                ) : (
                                    <EchartBar
                                        yData={yData}
                                        xTime={xTime}
                                        names={item.name}
                                        style={{ height: '93%' }}
                                    />
                                )}
                            </Loading>
                        </TabPane>
                    );
                })}
        </Tabs>
    );
};

export const OnlinePatrol = () => {
    const childRef = useRef();
    const formChildrenRef = useRef();
    //const [modalType, setModalType] = useState('edit'); //edit add
    const [data, setData] = useState({});
    const [treeData, setTreeData] = useState([]);
    const [allNode, setAllNode] = useState([]);
    const [areaInfo, setAreaInfo] = useState();
    const [treeId, setTreeId] = useState();
    const [category, setCategory] = useState([]);
    const [tag, setTag] = useState({ data: null, isLoading: true });
    const [params, setParams] = useState();
    const id = useMemo(() => {
        if (isEmpty(areaInfo) && isEmpty(treeId)) {
            return null;
        }
        if (!isEmpty(areaInfo)) {
            return areaInfo.id;
        }
        return treeId;
    }, [areaInfo, treeId]);
    useEffect(() => {
        treeList();
    }, []);
    const treeList = () => {
        treeHasIcon()
            .then(result => {
                setTreeData(result.data);
            })
            .catch();
    };
    useEffect(() => {
        if (!isUndefined(treeId)) {
            getPatrol(id)
                .then(result => {
                    setData(result.data);
                })
                .catch();
            getPatrolCategoryTag(id)
                .then(result => {
                    setCategory(result.data);
                })
                .catch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [treeId, areaInfo]);
    useEffect(() => {
        if (!isUndefined(treeId)) {
            const newParams = omitBy(params, isUndefined);
            setTag({ data: null, isLoading: true });
            getPatrolTag(id, { ...newParams })
                .then(result => {
                    setTag({ data: result.data, isLoading: false });
                })
                .catch(() => {
                    setTag({ data: null, isLoading: false });
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [treeId, params, areaInfo]);
    const renderInfo = () => {
        return (
            <Row
                gutter={16}
                style={{
                    width: '100%',
                    textAlign: 'left',
                    marginBottom: '0.5rem',
                    display: 'flex',
                    justifyContent: 'space-around',
                }}
            >
                <Col className="gutter-row" span={14}>
                    <MyIcon type="icon_location" style={style} />
                    <span style={{ verticalAlign: 'middle' }}>区域：</span>
                    <span style={{ fontSize: '1.2rem', color: '#595858', fontWeight: '500', verticalAlign: 'middle' }}>
                        {removeEmpty(data.location)}
                    </span>
                </Col>
                <Col className="gutter-row" span={5} style={{ textAlign: 'right' }}>
                    网关量：
                    <span style={{ fontSize: '1.2rem', color: '#595858', fontWeight: '500' }}>
                        {removeEmpty(data.gateway)}
                    </span>{' '}
                    个
                </Col>
                <Col className="gutter-row" span={5} style={{ textAlign: 'right' }}>
                    在线量：
                    <span style={{ fontSize: '1.2rem', color: '#47C181', fontWeight: '500' }}>
                        {removeEmpty(data.gateway_online)}{' '}
                    </span>
                    个
                </Col>
                {/* <Col className="gutter-row" span={2}>
                    <Button
                        type="primary"
                        onClick={() => {
                            setModalType('edit');
                            childRef.current.showModal(0);
                        }}
                    >
                        提交巡检
                    </Button>
                </Col> */}
            </Row>
        );
    };
    return (
        <div className="container clearfix">
            <BreadcrumbCustom paths={['首页', '巡检管理', '在线巡检']} className="rumbCustom" />
            <div className="row clearfix" style={{ backgroundColor: 'transparent', margin: '1rem', padding: '0' }}>
                <Row style={{ marginLeft: '0rem', width: '100%' }} gutter={32} className={patrolStyles.container}>
                    <Col
                        className="gutter-row"
                        span={4}
                        style={{ padding: '1rem', background: '#fff', borderRadius: '10px' }}
                    >
                        <CommonTree
                            treeData={treeData}
                            onClick={value => {
                                !isEmpty(treeData) && setTreeId(value.id);
                                if (!areaInfo || areaInfo.id !== value.id) {
                                    setAreaInfo(value);
                                }
                            }}
                            getNode={all => {
                                setAllNode(all);
                            }}
                        />
                    </Col>
                    <Col span={20} style={{ paddingRight: '0' }}>
                        <Row>
                            <Col span={24} style={{ backgroundColor: '#fff', borderRadius: '10px', padding: '1rem' }}>
                                {renderInfo()}
                                <List data={data} />
                            </Col>
                            <Col span={24} style={{ backgroundColor: '#fff', borderRadius: '10px', marginTop: '1rem' }}>
                                {/* <Loading show={tag.isLoading}>
                                    {isEmpty(tag.data) ? (
                                        <EmptyView />
                                    ) : ( */}
                                        <div className="card-container">
                                            {' '}
                                            {renderEchart(tag, category, setParams, params)}
                                        </div>
                                    {/* )}
                                </Loading> */}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <CommonModal
                onOk={() => {
                    formChildrenRef.current
                        .validateFields()
                        .then(value => {
                            //do something
                            //TODO 发起http请求提交当前设置的巡检
                        })
                        .catch(err => {
                            childRef.endLoading();
                        });
                }}
                childRef={childRef}
                // title={`${modalType === 'edit' ? '提交' : '新增'}巡检`}
            >
                <EditForm childRef={formChildrenRef} allNode={allNode} treeId={treeId} />
            </CommonModal>
        </div>
    );
};
// 时间筛选组件
export const TimeSearch = ({ onValueChange, timeType = ['toDay', 'day7', 'day30', 'month12', 'custom'] }) => {
    const [open, setOpen] = useState();
    const [value, setValue] = useState(JSON.stringify(toDay));
    const [dateValue, setDateValue] = useState([]);
    const time = useRef([]);
    useEffect(() => {
        if (!open && isBoolean(open)) {
            value && setValue('');
            onValueChange({ time: time.current });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);
    return (
        <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'flex-end' }}>
            <Radio.Group
                value={value}
                onChange={e => {
                    setValue(e.target.value);
                    const time = JSON.parse(e.target.value);
                    setDateValue([moment(time[0]), moment(time[1])]);
                    onValueChange({ time });
                }}
            >
                {includes(timeType, 'toDay') && <Radio.Button value={JSON.stringify(toDay)}>今日</Radio.Button>}
                {includes(timeType, 'day7') && <Radio.Button value={JSON.stringify(day7)}>近7日</Radio.Button>}
                {includes(timeType, 'day30') && <Radio.Button value={JSON.stringify(day30)}>近30日</Radio.Button>}
                {includes(timeType, 'month12') && <Radio.Button value={JSON.stringify(month12)}>近12月</Radio.Button>}
            </Radio.Group>
            {includes(timeType, 'custom') && (
                <ConfigProvider locale={zhCN}>
                    <RangePicker
                        style={{ marginLeft: '14rem', marginRight: '1rem' }}
                        onOpenChange={open => {
                            setOpen(open);
                        }}
                        value={dateValue}
                        onChange={times => {
                            setDateValue(times);
                            // times = [moment(times[0]).valueOf(), moment(times[1]).valueOf()];
                            times = [moment(times[0]).startOf('day').valueOf(), moment(times[1]).add(1,'day').startOf('day').valueOf()];
                            time.current = times;
                        }}
                    />
                </ConfigProvider>
            )}
        </div>
    );
};
