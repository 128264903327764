/**
 * 手机号验证码登录
 */
import React, { useState, useEffect } from 'react';
import { Form, Input, Button, message } from 'antd';
import history from '../../api/history';
import { setCookie } from '../../util/cookies';
import { MyIcon } from '../../util/iconfont';
import './index.less';
import { forgetPassword, getMobile } from '../../api/webServe';
let timeChange;
const ForgetPassword = () => {
    const [form] = Form.useForm();
    const [time, setTime] = useState(60);
    const [mobile, setMobile] = useState({});
    const [isGetCode, setIsGetCode] = useState(true);
    const [session_key, setSession_key] = useState();
    const layout = {
        wrapperCol: { offset: 7, span: 10 },
    };

    // 登录成功
    const onFinish = values => {
        values.session_key = session_key;
        forgetPassword(values)
            .then(() => {
                message.success('密码设置成功');
                history.push('/login'); //跳转到登录页面
            })
            .catch();
    };

    useEffect(() => {
        clearInterval(timeChange);
        return () => clearInterval(timeChange);
    }, []);

    useEffect(() => {
        if (time > 0 && time < 60) {
            setIsGetCode(false);
        } else {
            clearInterval(timeChange);
            setTime(60);
            setIsGetCode(true);
        }
    }, [time]);

    /**
     * 获取验证码
     */
    const getMsgCode = async () => {
        // if (isGetCode) {
        //     // 倒计时未结束,不能重复点击
        //     return;
        // }
        // if (!form.getFieldValue('mobile')) {
        //     message.error('请先输入手机号');
        //     return;
        // }
        mobile.purpose = 'reset';
        mobile.mobile = form.getFieldValue('mobile');
        await getMobile(mobile)
            .then((result) => {
                message.success('发送成功,请填写收到的验证码');
                setSession_key(result.data.session_id)
                setCookie('sessionid','sessionid='+result.data.session_id)
            })
            .catch();
        timeChange = setInterval(() => setTime(t => --t), 1000); // 注意，不要使用 setTime(t-1) ： 闭包问题会导致time一直为59
    };

    return (
        <div className="forgetWrap loginWrap dataWrap">
            {/* <div className="forget-title">Finsiot 智慧用电一体化管理平台</div> */}
            <div className="forget-title"> 智慧用电一体化管理平台</div>
            <div className="cs-forget">
                <span className="forget-title-sub">忘记密码</span>
                <Form
                    className="login-form logins forgot"
                    form={form}
                    onFinish={onFinish}
                    onValuesChange={changedFields => setMobile(changedFields)}
                    name="horizontal_login"
                    layout="inline"
                    initialValues={{
                        remember: true,
                    }}
                    {...layout}
                >
                    <Form.Item
                        {...layout}
                        style={{ width: '100%', margin: '0 auto' }}
                        name="mobile"
                        rules={[
                            {
                                required: true,
                                message: '请输入手机号',
                            },
                            {
                                pattern: new RegExp(/^1[3-9]\d{9}$/, 'g'),
                                message: '手机号格式不正确',
                            },
                        ]}
                    >
                        <Input
                            prefix={<MyIcon type="icon_login_user" className="site-form-item-icon" />}
                            placeholder="请输入手机号"
                            maxLength={11}
                            onChange={mobile => console.log(mobile, '手机号')}
                        />
                    </Form.Item>
                    <Form.Item
                        {...layout}
                        style={{ width: '100%' }}
                        name="code"
                        rules={[
                            {
                                required: true,
                                message: '请输入验证码!',
                            },
                        ]}
                    >
                        <Input
                            prefix={<MyIcon type="icon_login_yzm" className="site-form-item-icon" />}
                            placeholder="请输入验证码"
                            style={{ width: '16rem' }}
                            // suffix={
                            //     <span
                            //         className="checkBtn"
                            //         style={{ color: isGetCode ? '#0189FF' : '#B2B4B7' }}
                            //         onClick={isGetCode ? getMsgCode : null}
                            //     >
                            //         {isGetCode ? '获取验证码' : `重新发送(${time}s)`}
                            //     </span>
                            // }
                        />
                    </Form.Item>
                    <Form.Item noStyle>
                        <Button
                            onClick={isGetCode ? getMsgCode : null}
                            style={{
                                background: 'transparent',
                                color: isGetCode ? '#0189FF' : '#B2B4B7',
                                position: 'absolute',
                                right: '48.2rem',
                                zIndex: 11111,
                                margin: 0,
                                fontSize: '0.8rem',
                                // height: '2rem',
                                width: '7%',
                                top: '25.4rem',
                            }}
                            className={'forgetBtn'}
                        >
                            {isGetCode ? '获取验证码' : `重新发送(${time}s)`}
                        </Button>
                    </Form.Item>
                    <Form.Item
                        {...layout}
                        style={{ width: '100%' }}
                        name="new_password"
                        rules={[
                            {
                                required: true,
                                message: '请输入新密码!',
                            },
                        ]}
                    >
                        <Input.Password
                            prefix={<MyIcon type="icon_login_password" className="site-form-item-icon" />}
                            type="password"
                            placeholder="请输入新密码"
                        />
                    </Form.Item>
                    <Form.Item
                        {...layout}
                        style={{ width: '100%' }}
                        name="confirm_password"
                        rules={[
                            {
                                required: true,
                                message: '请再次输入新密码!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('new_password') === value) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(new Error('两次密码不一致!'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password
                            prefix={<MyIcon type="icon_login_password" className="site-form-item-icon" />}
                            type="password"
                            placeholder="请再次输入新密码"
                        />
                    </Form.Item>
                    <Form.Item className="formBtn formForgot">
                        <Button
                            onClick={() => {
                                history.push('/login');
                            }}
                            className="login-form-button"
                            size="small"
                            style={{
                                fontSize: '0.8rem',
                                height: '35px',
                                lineHeight: '35px',
                            }}
                        >
                            返回登录
                        </Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="login-form-button"
                            size="small"
                            style={{
                                fontSize: '0.8rem',
                                background: '#10A7B9',
                                border: '1px solid #10A7B9',
                                height: '35px',
                                lineHeight: '35px',
                                color: '#fff',
                            }}
                        >
                            确认修改
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};
export default ForgetPassword;