import React, { useEffect } from 'react';
import RouteHelper from '../tools/RouteHelper';
import { getUser } from '../tools/StorageHelper';
import { baseRouter, webRoute } from '../router/demo';
export const BaseView = ({ children, handleSlider, handleHeader, listenRouterPath, ...otherProps }) => {
    useEffect(() => {
        const isAdmin = JSON.parse(getUser()).is_staff;
        const applyRoute = isAdmin ? baseRouter : webRoute;
        const { history } = otherProps;
        const pathname = history.location.pathname;
        if (applyRoute[pathname]) {
            handleSlider(applyRoute[pathname].hidden);
            handleHeader(applyRoute[pathname].hiddenHeader);
            // console.log(pathname, '@@@@@@@@@');
            listenRouterPath(pathname);
        }
        RouteHelper.setHistory(history);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return <>{children}</>;
};
