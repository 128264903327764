/*
 * @Descripttion:折线图组件(用电分析--- 数据预测 数据历史)
 * @Author: lipei
 * @Date: 2020-12-Th 09:46:06
 * @Last Modified by:   lipei
 * @Last Modified time: 2020-12-Th 09:46:06
 */
import React, { useMemo } from 'react';
import ReactEcharts from 'echarts-for-react';
import { map } from 'lodash';

export const ChartBarLine = ({ rawData, color, stack, unit, showStack }) => {
    const servers = useMemo(() => {
        return new Array(rawData[0].length - 1);
    }, [rawData]);
    const getOption = () => {
        const option = {
            color: color,
            legend: {
                top: '2%',
                left: 'center',
                icon: 'circle',
                itemWidth: 10,
                textStyle: {
                    // color: '#c7c7c7',
                    fontSize: 12,
                },
            },
            grid: {
                bottom: '10%',
                left: '6%',
                right: '10%',
            },
            // animation:"auto",
            animationType: 'scale',
            animationEasing: 'cubicInOut', //cubicInOut elasticOut  progressive
            animationEasingUpdate: 'cubicInOut',
            animationDelay: function (idx) {
                return Math.random() * 200;
            },
            animationDuration: 1000,
            //animationDurationUpdate: 500, //数据更新动画的时长
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#B3CDE3',
                    },
                },
            },
            dataset: {
                source: rawData,
            },
            xAxis: {
                type: 'category',
                // name: '时间',
                axisLabel: {
                    //x轴文字的配置
                    show: true,
                    //interval: 0, //使x轴文字显示全
                },
            },
            yAxis: [
                {
                    name: `单位：(${unit || 'kWh'})`,
                    type: 'value',
                    min: 0,
                    position: 'left',
                },
                {
                    name: `总用量 (${unit || 'kWh'})`,
                    type: 'value',
                    min: 0,
                    position: 'right',
                },
            ],
            dataZoom: [
                {
                    type: 'inside', //inside slider
                    show: true, //flase直接隐藏图形
                    xAxisIndex: [0],
                    start: 0, //滚动条的起始位置
                    // end: rawData.length > 40 ? 10 : 50, //滚动条的截止位置（按比例分割你的柱状图x轴长度）
                    end: 100, //滚动条的截止位置（按比例分割你的柱状图x轴长度）
                    orient: 'vertical',
                },
            ],
            series: map(servers, (_, idx) => {
                return showStack ? {
                    type: idx === servers.length - 1 ? 'line' : 'bar',
                    smooth: true, //线条的平滑 平滑曲线
                    barMaxWidth: 15,
                    // yAxisIndex: idx === servers.length - 1 ? 1 : 0,
                } : {
                    type: idx === servers.length - 1 ? 'line' : 'bar',
                    smooth: true, //线条的平滑 平滑曲线
                    barMaxWidth: 15,
                    stack: idx === servers.length - 1 ? '' : stack,
                    yAxisIndex: idx === servers.length - 1 ? 1 : 0,
                };
            }),
        };
        return option;
    };
    let option = getOption();
    return <ReactEcharts option={option} style={{ width: '100%', height: '100%' }} resize={true} />;
};
