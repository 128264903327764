import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { MyIcon } from '../util/iconfont';
import Logo from '../assets/images/logo.png';
import Favicon from '../assets/images/favicon.png';
import { map, includes, filter, size } from 'lodash';
import { requestWebRoute, requestRoute, baseRouter, webRoute } from '../router/demo';
import { getPermissions, getUser } from '../tools/StorageHelper';
import { isDevelop } from '../global';
import siderNavsStyle from './siderNavs.module.less';
import history from '../api/history';

const { Sider } = Layout;
const { SubMenu } = Menu;

export default class SiderNav extends Component {
    constructor(props) {
        super(props);
        const { location } = props;
        let defaultOpenKey = location.pathname;
        defaultOpenKey = defaultOpenKey.split('/');
        defaultOpenKey.pop();
        defaultOpenKey = defaultOpenKey.join('/');
        this.state = {
            defaultSelectKey: location.pathname,
            defaultOpenKey: [defaultOpenKey],
            isAdmin: JSON.parse(getUser()).is_staff,
            selectedKeys: [],
            openKeys: [],
        };
    }
    componentDidMount() {
        this.props.onRef(this);
        history.listen(routeInfo => {
            const pathname = routeInfo.pathname.split('/');
            const path = '/' + pathname[pathname.length - 1];
            console.log(pathname, path);
            // setSelectKey(path);
        });
    }

    toggle = () => {
        this.props.onToggle();
    };
    menuClick = ({ key }) => {
        const { history } = this.props;
        if (includes(key, 'bigScreen')) {
            window.open(key);
            return;
        }
        if (includes(key, 'http')) {
            window.open(key);
            return;
        }
        history.push(key);
    };
    onOpenChange = keys => {
        this.setState({
            openKeys: keys,
        });
    };
    pathnameChange = pathname => {
        const { isAdmin } = this.state;
        const allRouters = JSON.parse(getPermissions());
        const client_type = isAdmin ? 1 : 2;
        const developRoutes = isAdmin ? requestRoute : requestWebRoute;
        const routes = isDevelop ? developRoutes : filter(allRouters, item => item.client_type === client_type);
        const correspondObj = filter(routes, item => {
            return pathname.indexOf(item.path) !== -1;
        });
        const openObj = correspondObj[size(correspondObj) - 1] ? correspondObj[size(correspondObj) - 1] : {};
        //解决：打开二级页面时菜单要保持选中状态【修改了一下子页面的路由，在路由的前面加了一个数字2，方便统一截取并判断】
        let selected =
            pathname.indexOf('2') !== -1 ? pathname.substring(0, pathname.indexOf('2')) : window.location.pathname;
        this.setState({
            selectedKeys: [selected],
            openKeys: [openObj.path],
        });
    };

    render() {
        const { isAdmin, openKeys, selectedKeys } = this.state;
        const routesMap = isAdmin ? baseRouter : webRoute;
        const { toggle, collapsed } = this.props;
        //isDevelop 为ture allRouters = 使用前端写的路由，使用后端返回的getPermissions中的name来判断权限控制
        //isDevelop 为false allRouters = 使用后端返回的getPermissions来判断权限控制
        const allRouters = getPermissions() != 'undefined' && JSON.parse(getPermissions());
        const client_type = isAdmin ? 1 : 2;
        const developRoutes = isAdmin ? requestRoute : requestWebRoute;
        const routes = isDevelop ? developRoutes : filter(allRouters, item => item.client_type === client_type);
        return (
                <Sider
                    breakpoint="lg"
                    collapsedWidth="80"
                    trigger={null}
                    collapsible
                    collapsed={collapsed}
                    onCollapse={toggle}
                    className={siderNavsStyle.container}
                >
                    <div className="logoIcon">
                        <img src={collapsed ? Favicon : (JSON.parse(getUser()).logo ? (JSON.parse(getUser()).logo) : Logo)} alt="Logo" style={collapsed ? style.smallLogo : style.logo} />
                        <span onClick={this.toggle} style={{ color: '#fff', fontSize: '25px', cursor: 'pointer' }}>
                            {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined)}
                        </span>
                    </div>
                    <Menu
                        mode="inline"
                        theme="dark"
                        collapsed={`${collapsed}`}
                        collapsedWidth="80"
                        onClick={this.menuClick}
                        onOpenChange={this.onOpenChange}
                        openKeys={openKeys}
                        selectedKeys={selectedKeys}
                    >
                        {map(routes, item => {
                            if (item.children && item.children.length > 0) {
                                return (
                                    <SubMenu
                                        key={item.path}
                                        icon={
                                                item.icon ? (
                                                <MyIcon type={item.icon} />
                                            ) : null
                                        }
                                        title={<span>{item.name}</span>}
                                    >
                                        {map(item.children, child => {
                                            if (!routesMap[child.path]) {
                                                return null;
                                            }
                                            if (child.menu && !child.hidden) {
                                                return (
                                                    <Menu.Item
                                                        key={child.path}
                                                        icon={
                                                            routesMap[child.path].icon ? (
                                                                <MyIcon type={child.icon} />
                                                            ) : null
                                                        }
                                                    >
                                                        {child.name}
                                                    </Menu.Item>
                                                );
                                            }
                                        })}
                                    </SubMenu>
                                );
                            }
                                return (
                                    <Menu.Item
                                        key={item.path}
                                        icon={
                                            item.icon ? (
                                                <MyIcon type={item.icon} />
                                            ) : (
                                                <MyIcon type={'icon_report'} />
                                            )
                                        }
                                    >
                                        <Link to={item.path}>
                                            <span className="navTitle">{item.name}</span>
                                        </Link>
                                    </Menu.Item>
                                );
                        })}
                    </Menu>
                </Sider>
            )
    }
}

const style = {
    title: {
        color: '#fff',
        fontSize: '1.1rem',
        paddingLeft: ' 0.2rem',
    },
    logo: {
        width: '129px',
        // height: '22px',
        height: 'auto',
        marginRight: '1rem',
    },
    smallLogo: {
        width: '30px',
        // height: '30px',
        display: 'none',
    },
};
