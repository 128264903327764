import React, { useMemo } from 'react';
import { Chart, Legend, XAxis, YAxis, Series, Dataset, Tooltip, Grid, Graphic, DataZoom } from '@echarts-start/react-bridge';
import { map, concat } from 'lodash';
import * as echarts from 'echarts';
import { COLOR_PLATE_16, BarChart_COLOR, legend } from '../global';
import { peakvalue } from '../util/function-util';
import Pies from '../assets/images/pie.gif';

const XYStyle = {
    axisLine: {
        lineStyle: {
            color: '#5F7D9C',
        },
    },
};
export const BarChart = ({ data }) => {
    const { y, seriesData } = useMemo(() => {
        const y = [];
        const work = [];
        const dormant = [];
        const alert = [];
        const offine = [];
        map(data, item => {
            y.push(item.name);
            work.push(item.work);
            dormant.push(item.dormant);
            alert.push(item.alert);
            offine.push(item.offine);
        });
        const seriesData = concat([], [work], [dormant], [alert], [offine]);
        return { y, seriesData };
    }, [data]);
    return (
        <Chart color={BarChart_COLOR} style={{ width: '100%', height: '100%', marginLeft: '2rem' }} resize={true}>
            <Tooltip
                options={{
                    trigger: 'axis',
                }}
            />
            <Legend
                options={{
                    right: '10%',
                    top: '10%',
                    data: legend,
                    textStyle: {
                        color: '#c7c7c7',
                        fontSize: 12,
                    },
                }}
            />
            <XAxis
                options={{
                    type: 'value',
                    axisLine: { show: false },
                    axisTick: { show: false },
                    axisLabel: { show: false },
                    splitLine: { show: false },
                }}
            />
            <YAxis
                options={{
                    type: 'category',
                    data: y,
                    axisTick: { show: false },
                    splitLine: { show: false },
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: '#FFFFFF',
                        },
                    },
                    axisLabel: {
                        //重点在这一块，其余可以忽略
                        interval: 0, //这个一定要有，别忘记了
                        rotate: 0,
                        textStyle: {
                            color: '#fff',
                            fontSize: 10,
                        },
                    },
                }}
            />
            {/* <Grid bottom="-5%" /> */}
            <Grid
                options={{
                    top: '18%',
                    left: '12%',
                    // right: 30,
                    bottom: '0%',
                }}
            />
            {map(legend, (item, idx) => {
                return (
                    <Series
                        key={idx}
                        options={{
                            name: item,
                            type: 'bar',
                            stack: 'total',
                            emphasis: {
                                focus: 'series',
                            },
                            barWidth: 6,
                            data: seriesData[idx],
                        }}
                    />
                );
            })}
        </Chart>
    );
};

export const MixedGraph = ({ data }) => {
    const colors = {
        rgb: 'rgba(197,149,39, 0.7)',
        rgbOpacity: 'rgba(197,149,39, 0.1)',
        color: '#C59527',
    };
    return (
        <Chart color={COLOR_PLATE_16} style={{ width: '100%', height: '100%' }} resize={true}>
            <Tooltip
                options={{
                    trigger: 'axis',
                }}
            />
            <Legend
                options={{
                    data: ['总功耗', '预测功耗'],
                    textStyle: {
                        color: '#c7c7c7',
                        fontSize: 12,
                    },
                    right: 40,
                    top: 40,
                }}
            />
            <XAxis
                options={{
                    type: 'category',
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#5F7D9C',
                        },
                    },
                    data: [
                        '0:00',
                        '1:00',
                        '2:00',
                        '3:00',
                        '4:00',
                        '5:00',
                        '6:00',
                        '7:00',
                        '8:00',
                        '9:00',
                        '10:00',
                        '11:00',
                        '12:00',
                        '13:00',
                        '14:00',
                        '15:00',
                        '16:00',
                        '17:00',
                        '18:00',
                        '19:00',
                        '20:00',
                        '21:00',
                        '22:00',
                        '23:00',
                    ],
                }}
            />
            <YAxis
                options={{
                    type: 'value',
                    name: '单位(kWh)',
                    splitLine: { show: false },
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#5F7D9C',
                        },
                    },
                }}
            />
            <Grid
                options={{
                    top: '15%',
                    left: 65,
                    right: 30,
                    bottom: 30,
                }}
            />
            <Series
                options={{
                    type: 'line',
                    name: '预测功耗',
                    // itemStyle: {
                    //     color: '#FFBB00',
                    // },
                    showSymbol: false,
                    lineStyle: {
                        color: colors.color,
                    },
                    itemStyle: {
                        color: colors.color,
                    },
                    smooth: true, //线条的平滑 平滑曲线
                    z: 1,
                    areaStyle: {
                        opacity: '0.6',
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: colors.rgb,
                            },
                            {
                                offset: 1,
                                color: colors.rgbOpacity,
                            },
                        ]),
                    },
                    data: map(data.prediction, item => peakvalue(item.amount)),
                }}
            />
            <Series
                options={{
                    type: 'bar',
                    name: '总功耗',
                    symbol: 'circle',
                    symbolSize: 10,
                    lineStyle: {
                        color: '#0A8F7D',
                    },
                    itemStyle: {
                        color: '#0A8F7D',
                    },
                    z: 2,
                    barMaxWidth: 15,
                    data: map(data.total, item => peakvalue(item.amount)),
                }}
            />
        </Chart>
    );
};

export const OnlyBarChart = ({ data }) => {
    return (
        <Chart color={COLOR_PLATE_16} style={{ width: '100%', height: '100%' }} resize={true}>
            <Tooltip
                options={{
                    trigger: 'axis',
                }}
            />
            <XAxis
                options={{
                    type: 'category',
                    axisTick: {
                        show: false,
                    },
                    ...XYStyle,
                    data: [
                        '0:00',
                        '1:00',
                        '2:00',
                        '3:00',
                        '4:00',
                        '5:00',
                        '6:00',
                        '7:00',
                        '8:00',
                        '9:00',
                        '10:00',
                        '11:00',
                        '12:00',
                        '13:00',
                        '14:00',
                        '15:00',
                        '16:00',
                        '17:00',
                        '18:00',
                        '19:00',
                        '20:00',
                        '21:00',
                        '22:00',
                        '23:00',
                    ],
                }}
            />
            <YAxis
                options={{
                    // name: '单位(%)',
                    min: 0,
                    max: 1,
                    interval: 0.2,
                    splitLine: { show: false },
                    ...XYStyle,
                }}
            />
            <Grid
                options={{
                    left: 55,
                    right: 30,
                    top: '30%',
                    bottom: 30,
                }}
            />
            <Graphic
                options={{
                    type: 'image', // 图形元素类型
                    id: 'empty', // 更新或删除图形元素时指定更新哪个图形元素，如果不需要用可以忽略。
                    right: 'center', // 根据父元素进行定位 （居中）
                    bottom: 'center', // 根据父元素进行定位   （0%）, 如果bottom的值是 0，也可以删除该bottom属性值。
                    z: 0, // 层叠
                    bounding: 'all', // 决定此图形元素在定位时，对自身的包围盒计算方式
                    style: {
                        //image: 'https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg', // 这里一定要注意、注意，必须是https开头的图片路径地址
                        width: 100,
                        height: 100,
                    },
                }}
            />
            <Series
                options={{
                    type: 'bar',
                    itemStyle: {
                        // bordRadius: [6, 6, 0, 0],
                        borderRadius: [6, 6, 0, 0],
                        opacity: 0.8,
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            { offset: 0, color: '#10A0D7' },
                            { offset: 1, color: '#071E2F' },
                        ]),
                    },
                    barWidth: 12,
                    data: map(data, item => item.amount),
                }}
            />
        </Chart>
    );
};

export const LineChart = ({ data, company, colors, height }) => {
    return (
        <Chart color={COLOR_PLATE_16} style={{ width: '100%', height: height || '100%' }} resize={true}>
            <Tooltip
                options={{
                    trigger: 'axis',
                }}
            />
            <Legend
                options={{
                    textStyle: {
                        color: '#c7c7c7',
                        fontSize: 12,
                    },
                    right: 50,
                    top: 25,
                }}
            />
            <XAxis
                options={{
                    type: 'category',
                    axisTick: {
                        show: false,
                    },
                    ...XYStyle,
                    data: [
                        '0:00',
                        '1:00',
                        '2:00',
                        '3:00',
                        '4:00',
                        '5:00',
                        '6:00',
                        '7:00',
                        '8:00',
                        '9:00',
                        '10:00',
                        '11:00',
                        '12:00',
                        '13:00',
                        '14:00',
                        '15:00',
                        '16:00',
                        '17:00',
                        '18:00',
                        '19:00',
                        '20:00',
                        '21:00',
                        '22:00',
                        '23:00',
                    ],
                }}
            />
            <YAxis
                options={{
                    name: `单位(${company})`,
                    min: 0,
                    // max: 15,
                    splitLine: { show: false },
                    ...XYStyle,
                }}
            />
            <Grid
                options={{
                    left: 55,
                    right: 30,
                    top: '30%',
                    bottom: 30,
                }}
            />
            <Graphic
                options={{
                    type: 'image',
                    id: 'empty',
                    right: 'center',
                    bottom: 'center',
                    z: 0,
                    bounding: 'all',
                    style: {
                        image: 'Empty.PRESENTED_IMAGE_DEFAULT',
                        width: 100,
                        height: 100,
                    },
                }}
            />
            {map(data, (item, idx) => {
                return (
                    <Series
                        key={idx}
                        options={{
                            type: 'line',
                            smooth: true,
                            showSymbol: false,
                            name: item.name,
                            lineStyle: {
                                color: colors[idx].color,
                            },
                            itemStyle: {
                                color: colors[idx].color,
                            },
                            symbol: 'circle',
                            areaStyle: {
                                opacity: '0.6',
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: colors[idx].rgb,
                                    },
                                    {
                                        offset: 1,
                                        color: colors[idx].rgbOpacity,
                                    },
                                ]),
                            },
                            data: map(item.data, values => values.amount),
                        }}
                    />
                );
            })}
            {/*<Series*/}
            {/*    options={{*/}
            {/*        type: 'line',*/}
            {/*        smooth: true,*/}
            {/*        showSymbol: false,*/}
            {/*        lineStyle: {*/}
            {/*            color: '#43419F',*/}
            {/*        },*/}
            {/*        areaStyle: {*/}
            {/*            opacity: '0.6',*/}
            {/*            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [*/}
            {/*                {*/}
            {/*                    offset: 0,*/}
            {/*                    color: 'rgba(67,65,159,0.1)',*/}
            {/*                },*/}
            {/*                {*/}
            {/*                    offset: 1,*/}
            {/*                    color: 'rgba(67,65,159)',*/}
            {/*                },*/}
            {/*            ]),*/}
            {/*        },*/}
            {/*        data: map(new Array(24), () => random(3, 15)),*/}
            {/*    }}*/}
            {/*/>*/}
        </Chart>
    );
};

export const PieChart = ({ data, color, isScreenFull, insurance }) => {
    const source = useMemo(() => {
        const source = [];
        const legend = [''];
        map(data.data, item => {
            const servesData = [];
            legend.push(item.event);
            servesData.push(item.event);
            servesData.push(item.number);
            source.push(servesData);
        });
        source.unshift(legend);
        return source;
    }, [data]);
    return (
        <Chart
            color={color}
            noCoordinate={true}
            style={{
                width: '100%',
                height: '100%',
                background:
                    data.data.length === 0
                        ? 'transparent'
                        : isScreenFull
                        ? `url("${Pies}") 29.5% 62% / ${insurance ? '27.5%' : '25.5%'} no-repeat`
                        : `url("${Pies}") 30.5% 62% / ${insurance ? '24%' : '22%'} no-repeat`,
            }}
            resize
        >
            <Tooltip
                options={{
                    trigger: 'item',
                }}
            />
            <Legend
                icon="circle"
                options={{
                    orient: 'vertical',
                    top: '20%',
                    right: '2%',
                    textStyle: {
                        color: '#c7c7c7',
                        fontSize: 12,
                    },
                    formatter: name => {
                        if (data.data.length) {
                            const item = data.data.filter(item => item.event === name || item.name === name)[0];
                            return `${name}    ${item.number || item.value || 0}`;
                        }
                    },
                }}
            />
            <Dataset source={source} />
            <Series
                options={{
                    type: 'pie',
                    radius: ['33%', '45%'],
                    center: ['35%', '55%'],
                    labelLine: {
                        show: true,
                        //         lineStyle:{
                        //            color:'#7A7A7A',
                        // 　　　　}
                    },
                    label: {
                        show: true,
                        color: 'auto',
                        // position: 'center',
                    },
                }}
            />
        </Chart>
    );
};

export const BarChartGradient = () => {
    return (
        <Chart color={COLOR_PLATE_16} style={{ width: '100%', height: '100%' }} resize={true}>
            <Tooltip
                options={{
                    trigger: 'axis',
                }}
            />
            <XAxis
                options={{
                    type: 'value',
                    axisLine: { show: false },
                    axisTick: { show: false },
                    axisLabel: { show: false },
                    splitLine: { show: false },
                }}
            />
            <YAxis
                options={{
                    type: 'category',
                    data: ['软件组', '固件组', '结构组', '办公区', '过道'],
                    axisTick: { show: false },
                    splitLine: { show: false },
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: '#FFFFFF',
                        },
                    },
                }}
            />
            <Grid left="12%" top="20%" bottom="5%" />
            <Series
                options={{
                    name: '离线',
                    type: 'bar',
                    showBackground: true,
                    itemStyle: {
                        opacity: 0.8,
                        // bordRadius: [6, 6, 6, 6],
                        borderRadius: [6, 6, 6, 6],
                        color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                            { offset: 0, color: '#2773E9' },
                            { offset: 1, color: '#06ADD3' },
                        ]),
                    },
                    realtime: true,
                    barWidth: 12,
                    data: [150, 210, 201, 154, 500],
                    backgroundStyle: {
                        borderRadius: [6, 6, 6, 6],
                        color: '#0A487E',
                    },
                }}
            />
        </Chart>
    );
};

export const LineChart2 = ({ data }) => {
    const colors = [
        {
            rgbOpacity: 'rgba(253,187,88,0.1)',
            rgb: 'rgb(253,187,88)',
            color: '#FDBB58',
        },
        {
            rgbOpacity: 'rgba(18,219,255,0.1)',
            rgb: 'rgb(88,209,245)',
            color: '#12DBFF',
        },
    ];
    const seriesData = useMemo(() => {
        const seriesData = [];
        map(data, item => {
            const timeData = [];
            map(item.data, timeItem => {
                timeData.push(timeItem.number);
            });
            seriesData.push({ name: item.name, number: timeData });
        });
        return seriesData;
    }, [data]);
    return (
        <Chart color={COLOR_PLATE_16} style={{ width: '100%', height: '100%' }} resize={true}>
            <Tooltip
                options={{
                    trigger: 'axis',
                }}
            />
            <Legend
                options={{
                    textStyle: {
                        color: '#c7c7c7',
                        fontSize: 12,
                    },
                    right: 40,
                    top: 10,
                }}
            />
            <XAxis
                options={{
                    type: 'category',
                    axisTick: {
                        show: false,
                    },
                    ...XYStyle,
                    data: [
                        '0:00',
                        '1:00',
                        '2:00',
                        '3:00',
                        '4:00',
                        '5:00',
                        '6:00',
                        '7:00',
                        '8:00',
                        '9:00',
                        '10:00',
                        '11:00',
                        '12:00',
                        '13:00',
                        '14:00',
                        '15:00',
                        '16:00',
                        '17:00',
                        '18:00',
                        '19:00',
                        '20:00',
                        '21:00',
                        '22:00',
                        '23:00',
                    ],
                }}
            />
            <YAxis
                options={{
                    // name: '单位(%)',
                    min: 0,
                    // max: 15,
                    splitLine: { show: false },
                    ...XYStyle,
                }}
            />
            <Grid
                options={{
                    left: 60,
                    top: 70,
                    bottom: 40,
                    right: 30,
                }}
            />
            {map(seriesData, (item, idx) => {
                return (
                    <Series
                        key={idx}
                        options={{
                            type: 'line',
                            name: item.name,
                            showSymbol: false,
                            smooth: true,
                            itemStyle: {
                                color: colors[idx].color,
                            },
                            lineStyle: {
                                color: idx ? '#12DBFF' : '#FDBB58',
                            },
                            areaStyle: {
                                opacity: '0.6',
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: colors[idx].rgbOpacity,
                                    },
                                    {
                                        offset: 1,
                                        color: colors[idx].rgb,
                                    },
                                ]),
                            },
                            data: item.number,
                        }}
                    />
                );
            })}
        </Chart>
    );
};

export const LineChart3 = ({ data }) => {
    const {seriesData, timeData, numberData} = useMemo(() => {
        const seriesData = [];
        const timeData = [];
        const numberData = []
        map(data, item => {
            seriesData.push({name: '', value: item.number === null ? 0 : item.number});
            timeData.push(item.time);
            numberData.push(item.number)
        });
        return {seriesData, timeData, numberData};
    }, [data]);
    const Max = Math.max(...numberData);
    const Min = Math.min(...numberData);
    return (
        <Chart color={COLOR_PLATE_16} style={{ width: '100%', height: '100%' }} resize={true}>
            <Tooltip
                options={{
                    trigger: 'axis',
                }}
            />
            <Legend
                options={{
                    textStyle: {
                        color: '#c7c7c7',
                        fontSize: 12,
                    },
                    right: 40,
                    top: 10,
                }}
            />
            <XAxis
                options={{
                    type: 'category',
                    axisTick: {
                        show: false,
                    },
                    ...XYStyle,
                    data: timeData || [
                        '0:00',
                        '1:00',
                        '2:00',
                        '3:00',
                        '4:00',
                        '5:00',
                        '6:00',
                        '7:00',
                        '8:00',
                        '9:00',
                        '10:00',
                        '11:00',
                        '12:00',
                        '13:00',
                        '14:00',
                        '15:00',
                        '16:00',
                        '17:00',
                        '18:00',
                        '19:00',
                        '20:00',
                        '21:00',
                        '22:00',
                        '23:00',
                    ],
                }}
            />
            <DataZoom
                    options={{
                        type: 'inside', //inside slider
                        show: true, //flase直接隐藏图形
                        xAxisIndex: [0],
                        left: '1%', //滚动条靠左侧的百分比
                        // top:'5%',
                        // bottom: '6%',
                        // bottom: 5,
                        start: 0, //滚动条的起始位置
                        end: 100, //滚动条的截止位置（按比例分割你的柱状图x轴长度）
                    }}
                />
            <YAxis
                options={{
                    // name: '单位(%)',
                    min: Min-1,
                    max: Max+1,
                    splitLine: { show: false },
                    ...XYStyle,
                }}
            />
            <Grid
                options={{
                    left: 60,
                    top: 70,
                    bottom: 40,
                    right: 30,
                }}
            />
            <Series
                options={{
                    type: 'line',
                    // name: item.name,
                    showSymbol: true,
                    symbolSize:2,
                    smooth: true,
                    itemStyle: {
                        color: '#12DBFF',
                    },
                    lineStyle: {
                        color: '#12DBFF',
                        width: 1,
                    },
                    areaStyle: {
                        opacity: '0.6',
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: 'rgba(71,170,172,0.1)',
                            },
                            {
                                offset: 1,
                                color: 'rgb(71,170,172)',
                            },
                        ]),
                    },
                    data: seriesData,
                }}
            />
        </Chart>
    );
};
