import React, { Component } from 'react';
import { Button } from 'antd';
import Empty_error from '../assets/images/empty_error.png';
export class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    static getDerivedStateFromError(error) {
        return { hasError: true };
    }
    componentDidCatch(error, errorInfo) {
        // console.log(error, '?????');
        // console.log(errorInfo, '错误信息=====>');
    }

    render() {
        const { children } = this.props;
        const { hasError } = this.state;
        if (hasError) {
            return (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignContent: 'center',
                        flexDirection: 'column',
                        height: '100vh',
                        background: '#fff',
                    }}
                >
                    {/* <p>页面出错了~请联系管理员</p> */}
                    <img src={Empty_error} width="400px" alt='页面出错了'/>
                    <Button
                        type="button"
                        style={{ marginTop: '20px', backgroundColor: '#1890FF', color: '#fff', borderRadius: '2px' }}
                        onClick={() => window.location.reload()}
                    >
                        刷新页面
                    </Button>
                </div>
            );
        }
        return children;
    }
}
