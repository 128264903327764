import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Form, Select, Button, DatePicker, Table } from 'antd';
import { omitBy, isUndefined, isNull, trim } from 'lodash';
import moment from 'moment';
import { BreadcrumbCustom } from '../../../components/BreadcrumbCustom';
import { disabledDate_day } from '../../../util/timeUtil';

const FormItem = Form.Item;

export const ActionLog = () => {
    const [searchParams, setSearchParams] = useState(); //搜索
    const [data, setData] = useState({ data: [], isLoading: true }); //日志列表数据
    const [count, setCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const ref = useRef(null);
    const [page_size, setPageSize] = useState(12);
    const getData = (page = 1, pageSize) => {
        setCurrentPage(page);
        const params = omitBy(searchParams, value => {
            return isUndefined(value) || isNull(value) || value === '' || trim(value) === '';
        });
        setData({
            data: [
                {
                    id: 1,
                    object: '设备',
                    object_id: '123',
                    desc: '',
                    terminal: 'web',
                    ip: '100,20,145,41',
                    time: '2022-01-21 09:08:10',
                    account: 'demo',
                    category: '删除',
                },
                {
                    id: 2,
                    object: '设备',
                    object_id: '123',
                    desc: "名称:旧值:'会议区',新值:'会议室水电费所发生的防守打法所发生的防守打法'",
                    terminal: 'web',
                    ip: '100,20,145,41',
                    time: '2022-01-21 09:08:10',
                    account: 'finsiot',
                    category: '新增',
                },
                {
                    id: 3,
                    object: '区域',
                    object_id: '123323-333-88888833',
                    desc:
                        "名称:旧值:'会议区232',新值:'会议室2323sfsfs dfsfsdfsdfs是否是发斯蒂芬斯蒂芬所发生的防守打法'",
                    terminal: 'app',
                    ip: '100,20,145,41',
                    time: '2022-01-21 19:08:10',
                    account: 'admin',
                    category: '修改',
                },
            ],
            isLoading: false,
        });
        setCount(15);
        // getAlarm({ page, page_size: pageSize ? pageSize : page_size, ...params } )
        // .then(result => {
        //     setData({ data: result.data, isLoading: false });
        //     setCount(result.count);
        // })
        // .catch(() => {
        //     setData({ data: [], isLoading: true });
        // });
    };
    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);
    const columns = useMemo(() => {
        return [
            {
                title: '序号',
                dataIndex: 'id',
                key: 'id',
                width: 100, //宽度
                fixed: 'left',
                render: (text, record, index) => `${index + 1}`,
            },
            {
                title: '操作账号',
                dataIndex: 'account',
                render: (text, record) => (
                    <p
                        style={{
                            minWidth: '6rem',
                            maxWidth: '10rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={text}
                    >
                        {text}
                    </p>
                ),
            },
            {
                title: '操作类型',
                dataIndex: 'category',
                render: text => (
                    <p
                        style={{
                            minWidth: '4rem',
                            maxWidth: '8rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={text}
                    >
                        {text}
                    </p>
                ),
            },
            {
                title: '操作对象',
                dataIndex: 'object',
                render: text => (
                    <p
                        style={{
                            minWidth: '4rem',
                            maxWidth: '8rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={text}
                    >
                        {text}
                    </p>
                ),
            },
            {
                title: '对象ID',
                dataIndex: 'object_id',
                width: 180,
                render: text => <span>{text}</span>,
            },
            {
                title: '操作详情',
                dataIndex: 'desc',
                render: text => (
                    <p
                        style={{
                            minWidth: '10rem',
                            maxWidth: '14rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={text}
                    >
                        {text}
                    </p>
                ),
            },
            {
                title: '终端',
                dataIndex: 'terminal',
                render: text => (
                    <p
                        style={{
                            minWidth: '3rem',
                            maxWidth: '6rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={text}
                    >
                        {text}
                    </p>
                ),
            },
            {
                title: 'IP地址',
                dataIndex: 'ip',
                width: 140,
                render: text => <span>{text}</span>,
            },
            {
                title: '操作时间',
                dataIndex: 'time',
                width: 180,
                render: text => <span>{text && text.slice(0, 10) + ' ' + text.slice(11, 19)}</span>,
            },
        ];
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);
    // 分页配置
    const paginationNo = {
        pageSize: page_size, //每页的条数
        current: currentPage, //当前页
        total: count, //总数据
        showSizeChanger: true,
        onChange: (page, pageSize) => {
            ref.current = page;
            getData(page, pageSize);
        }, //点击分页号时触发的方法
        onShowSizeChange: (current, pageSize) => {
            setPageSize(pageSize);
        }, //pageSize 变化的回调
        hideOnSinglePage: false, //为true则数据条数不满或刚好一页，则隐藏分页器
        pageSizeOptions: ['12', '15', '20', '30', '50', '100'], // 分页类别
        showTotal: total => `共 ${total} 条`,
    };
    return (
        <div className="container clearfix">
            <BreadcrumbCustom paths={['首页', '系统设置', '操作日志']} className="rumbCustom" />
            <div
                className="row clearfix"
                style={{
                    background: '#fff',
                    margin: '1rem',
                    borderRadius: '10px',
                    width: '98%',
                    padding: '1rem',
                }}
            >
                <SearchForm setSearchParams={setSearchParams} />
                <Table
                    rowKey={record => record.id}
                    pagination={paginationNo}
                    columns={columns}
                    loading={data.isLoading}
                    dataSource={data.data}
                    bordered={true}
                    scroll={{ x: '100%' }}
                    className="actionlogStyle"
                    style={{ textAlign: 'center', position: 'relative' }}
                />
            </div>
        </div>
    );
};

const SearchForm = ({ setSearchParams }) => {
    const [form] = Form.useForm();
    let terminal = [
        { id: 1, name: 'app' },
        { id: 2, name: 'web' },
    ];
    let account = [
        { id: 1, name: 'demo' },
        { id: 2, name: 'finsiot' },
        { id: 3, name: 'admin' },
    ];
    let category = [
        { id: 1, name: '增加' },
        { id: 2, name: '删除' },
        { id: 3, name: '修改' },
        { id: 4, name: '登录' },
        { id: 5, name: '退出' },
    ];
    return (
        <Form
            form={form}
            onFinish={value => {
                value.time =
                    value.time !== null && value.time !== '' ? moment(value.time).startOf('day').valueOf() : undefined;
                setSearchParams(value); //2022-01-05 00:00:00
            }}
            style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
            className={'hardwareDevice'}
        >
            <FormItem label="操作账号" name="account">
                <Select
                    placeholder="请选择账号"
                    virtual={false}
                    showArrow
                    allowClear
                    style={{ minWidth: '3rem', width: '10rem' }}
                >
                    {account &&
                        account.map(item => {
                            return (
                                <Select.Option value={item.id} key={item.id}>
                                    {item.name}
                                </Select.Option>
                            );
                        })}
                </Select>
            </FormItem>
            <FormItem label="操作类型" name="category" style={{ margin: '0 1rem' }}>
                <Select
                    placeholder="请选择类型"
                    virtual={false}
                    showArrow
                    allowClear
                    style={{ minWidth: '3rem', width: '10rem' }}
                >
                    {category &&
                        category.map(item => {
                            return (
                                <Select.Option value={item.id} key={item.id}>
                                    {item.name}
                                </Select.Option>
                            );
                        })}
                </Select>
            </FormItem>
            <FormItem label="终端" name="terminal">
                <Select
                    placeholder="请选择终端"
                    virtual={false}
                    showArrow
                    allowClear
                    style={{ minWidth: '3rem', width: '10rem' }}
                >
                    {terminal &&
                        terminal.map(item => {
                            return (
                                <Select.Option value={item.id} key={item.id}>
                                    {item.name}
                                </Select.Option>
                            );
                        })}
                </Select>
            </FormItem>
            <FormItem label="操作日期" name="time" style={{ margin: '0 1rem' }}>
                <DatePicker format="yyyy-MM-DD" disabledDate={disabledDate_day} />
            </FormItem>
            <Button type="primary" htmlType="submit" style={{ marginBottom: '24px' }}>
                搜索
            </Button>
        </Form>
    );
};
