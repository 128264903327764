import Storage from '../util/Storage';

const USER = 'user';
const PERMISSIONS = 'permissions';
const PERMISSIONSDATA = 'permissions_data';
const PERMISSIONSDATACHILDREN = 'permissions_data_children';
const PERMISSIONSPERMISSION = 'permissions_permission';
const HOMEPAGEPATH = 'home_page_path';
export const setUser = value => {
    Storage.setItem(USER, value);
};
export const getUser = () => {
    return Storage.getItem(USER);
};

export const removeUser = () => {
    Storage.removeItem(USER);
    removePermissions();
    removePermissionsData();
    removePermissionsDataChildren();
    removePermissionsPermission();
    removeHomePagePath();
};

export const setPermissions = value => {
    Storage.setItem(PERMISSIONS, value);
};
export const getPermissions = () => {
    return Storage.getItem(PERMISSIONS);
};
export const removePermissions = () => {
    return Storage.removeItem(PERMISSIONS);
};

export const setPermissionsData = value => {
    Storage.setItem(PERMISSIONSDATA, value);
};
export const getPermissionsData = () => {
    return Storage.getItem(PERMISSIONSDATA);
};
export const removePermissionsData = () => {
    return Storage.removeItem(PERMISSIONSDATA);
};

export const setPermissionsDataChildren = value => {
    Storage.setItem(PERMISSIONSDATACHILDREN, value);
};
export const getPermissionsDataChildren = () => {
    return Storage.getItem(PERMISSIONSDATACHILDREN);
};
export const removePermissionsDataChildren = () => {
    return Storage.removeItem(PERMISSIONSDATACHILDREN);
};

export const setPermissionsPermission = value => {
    Storage.setItem(PERMISSIONSPERMISSION, value);
};
export const getPermissionsPermission = () => {
    return Storage.getItem(PERMISSIONSPERMISSION);
};
export const removePermissionsPermission = () => {
    return Storage.removeItem(PERMISSIONSPERMISSION);
};

export const setHomePagePath = value => {
    Storage.setItem(HOMEPAGEPATH, value);
};
export const getHomePagePath = () => {
    return Storage.getItem(HOMEPAGEPATH);
};
export const removeHomePagePath = () => {
    return Storage.removeItem(HOMEPAGEPATH);
};

