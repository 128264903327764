import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import zhCN from 'antd/es/locale/zh_CN';
import moment from 'moment';
import { MyIcon } from '../../../util/iconfont';
import {
    Button,
    Col,
    Form,
    Row,
    Table,
    Popconfirm,
    Input,
    Checkbox,
    Select,
    Switch,
    Radio,
    message,
    ConfigProvider,
    TreeSelect,
    Divider,
    Tooltip,
    Upload,
} from 'antd';
import { map, get, omitBy, isEmpty, isUndefined, isNull, trim } from 'lodash';
import { BreadcrumbCustom } from '../../../components/BreadcrumbCustom';
import { CommonModal } from '../../../components/CommoModal';
import {
    getWebuser,
    getWebuserDetail,
    addWebuser,
    editWebuser,
    delWebuser,
    changeWebuserActive,
    getAreaList,
    batchActive,
    EnterpriseNameOauth,
    getWebuserCategory,
    importWebuser,
    exportWebuser,
} from '../../../api/webServe';
import { getUserList } from '../../../api/adminServe';
import ToastHelper from '../../../tools/ToastHelper';
import { BASE_URL, switchText, account } from '../../../global';

const FormItem = Form.Item;
const Option = Select.Option;
/*
 * 账号管理
 */
const AccountManagement = () => {
    return (
        <div className="container clearfix">
            <BreadcrumbCustom paths={['首页', '账号管理']} className="rumbCustom" />
            <div
                className="row clearfix"
                style={{ padding: '1rem 1rem 0', margin: '1rem', backgroundColor: '#ffffff', borderRadius: '10px' }}
            >
                <EquipmentTable />
            </div>
        </div>
    );
};

const EquipmentTable = () => {
    const ref = useRef(null);
    const childRef = useRef();
    const formChildrenRef = useRef();
    const [searchParams, setSearchParams] = useState({});
    const [modalType, setModalType] = useState('add'); //edit add export other
    const [data, setData] = useState({ data: [], isLoading: true });
    const [total, setTotal] = useState(0);
    const [current, setCurrent] = useState(1);
    const [page_size, setPageSize] = useState(10);
    const [userId, setUserId] = useState();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [webuserDetail, setWebuserDetail] = useState();
    const getList = (page = 1, pageSize) => {
        setData({ data: [], isLoading: true });
        setCurrent(page);
        const params = omitBy(searchParams, value => {
            return isUndefined(value) || isNull(value) || value === '' || trim(value) === '';
        });
        getWebuser({
            page,
            page_size: pageSize ? pageSize : page_size,
            ...params,
        })
            .then(result => {
                setData({ data: result.data, isLoading: false });
                setTotal(result.count);
            })
            .catch(() => {
                setData({ data: [], isLoading: true });
            });
    };
    useEffect(() => {
        getList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);
    // 编辑详情
    const getDetail = id => {
        getWebuserDetail(id)
            .then(result => {
                setWebuserDetail(result.data);
                // childRef.current.showModal();
            })
            .catch();
    };
    // 账号列表导出
    const exportData = () => {
        const params = omitBy(searchParams, isEmpty);
        exportWebuser({ ...params })
            .then(result => {
                window.open(BASE_URL + result.data.url);
            })
            .catch();
    };
    // 账号列表导入
    const importFile = params => {
        importWebuser({ ...params })
            .then(() => {
                ToastHelper.successToast('导入成功');
                getList();
            })
            .catch(() => {
                ToastHelper.errorToast('请检查文件格式');
            });
    };
    const columns = [
        {
            title: '序号',
            dataIndex: 'id',
            key: 'id',
            width: 100,
            render: (test, record, index) => `${index + 1}`,
        },
        {
            title: '账号',
            dataIndex: 'username',
            render: text => (
                <p
                    style={{
                        minWidth: '4rem',
                        maxWidth: '8rem',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                    }}
                    title={text}
                >
                    {text}
                </p>
            ),
        },
        {
            title: '企业名称',
            dataIndex: 'regions',
            render: text => (
                <p
                    style={{
                        minWidth: '4rem',
                        maxWidth: '8rem',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                    }}
                    title={text[0] && text[0].name}
                >
                    {text[0] && text[0].name}
                </p>
            ),
        },
        {
            title: '账号类型',
            dataIndex: 'category',
            width: 90,
            render: text => (
                <p
                    style={{
                        minWidth: '4rem',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                    }}
                    title={account[text]}
                >
                    {account[text]}
                </p>
            ),
        },
        {
            title: '姓名',
            dataIndex: 'real_name',
            render: text => (
                <p
                    style={{
                        minWidth: '3rem',
                        maxWidth: '8rem',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                    }}
                    title={text}
                >
                    {text}
                </p>
            ),
        },
        {
            title: '联系方式',
            dataIndex: 'mobile',
            width: 100,
        },
        {
            title: '角色',
            dataIndex: 'roles',
            render: (text, index) => {
                const newStr = text ? text.map(({ name }) => `${name}`).join('、') : '——';
                return (
                    <Tooltip title={<span key={newStr}>{newStr}</span>}>
                        <div
                            style={{
                                minWidth: '6rem',
                                maxWidth: '20rem',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                margin: '0 auto',
                            }}
                        >
                            {newStr}
                        </div>
                    </Tooltip>
                );
            },
        },
        {
            title: '状态',
            dataIndex: 'is_active',
            width: 85,
            render: (_, record) => (
                <Switch
                    {...switchText}
                    onClick={value => {
                        changeWebuserActive({ is_active: value, id: record.id })
                            .then(() => {
                                ToastHelper.successToast(`${value ? '开启' : '关闭'}成功`);
                                // userList(ref.current);
                                getList();
                            })
                            .catch();
                    }}
                    checked={record.is_active}
                    size="small"
                />
            ),
        },
        {
            title: '创建时间',
            dataIndex: 'date_joined',
            width: 200,
            sorter: (a, b) => moment(a.date_joined) - moment(b.date_joined),
            render: text => <span>{text && text.slice(0, 10) + ' ' + text.slice(11, 19)}</span>,
        },
        {
            title: '操作',
            width: 200,
            render: (text, record) => (
                <div className="opera">
                    <MyIcon
                        type={'icona-zu6914'}
                        style={{
                            fontSize: '1rem',
                            verticalAlign: 'middle',
                            color: 'rgba(0, 0, 0, 0.85)',
                            marginRight: '0.6rem',
                        }}
                        title={'编辑'}
                        onClick={() => {
                            // setModalType(record.is_staff ? 'editEnter' : 'edit');
                            setModalType('edit');
                            getDetail(record.id);
                            setUserId(record.id);
                            childRef.current.showModal(0);
                        }}
                    />
                    <Popconfirm
                        title="确定要删除吗?"
                        onConfirm={() => {
                            delWebuser(record.id)
                                .then(result => {
                                    message.success('删除成功');
                                    getList();
                                })
                                .catch();
                        }}
                    >
                        <MyIcon
                            type="iconshanchu1"
                            style={{ color: 'rgba(0, 0, 0, 0.85)', fontSize: '1rem', verticalAlign: 'middle' }}
                            title={'删除'}
                        />
                    </Popconfirm>
                </div>
            ),
        },
    ];
    //分页
    const paginationNo = {
        pageSize: page_size, //每页的条数
        current: current, //当前页
        total: total, //总数据
        showSizeChanger: true,
        onChange: (page, pageSize) => {
            ref.current = page;
            getList(page, pageSize);
        }, //点击分页号时触发的方法
        onShowSizeChange: (current, pageSize) => {
            setPageSize(pageSize);
        }, //pageSize 变化的回调
        hideOnSinglePage: true, //为true则数据条数不满或刚好一页，则隐藏分页器
        pageSizeOptions: ['10', '15', '20', '30', '50', '100'], // 分页类别
        showTotal: total => `共 ${total} 条`,
    };
    // 表格多选事件
    const onSelectChange = selectedRowKeys => {
        setSelectedRowKeys(selectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    /**
     * 全选
     * @param e
     */
    const onCheckAllChange = e => {
        setSelectedRowKeys(e.target.checked ? data.data.map((item, index) => item.id) : []);
    };
    return (
        <div className="tableBox">
            <Row
                gutter={16}
                style={{
                    marginLeft: '0px',
                    marginRight: '0px',
                    display: 'flex',
                    flexWrap: 'nowrap',
                    justifyContent: 'space-between',
                    width: '100%',
                }}
            >
                <Col span={6}>
                    <Row>
                        <Col style={{ marginRight: '2%' }}>
                            <Button
                                onClick={() => {
                                    childRef.current.showModal();
                                    setModalType('add');
                                }}
                                type="primary"
                                style={{ marginBottom: '1rem' }}
                            >
                                新增账号
                            </Button>
                        </Col>
                        <Col style={{ margin: '0 2%' }}>
                            <Upload
                                customRequest={file => {
                                    importFile({ file: file.file });
                                }}
                                accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                showUploadList={false}
                            >
                                <Button
                                    onClick={() => {}}
                                    type="primary"
                                    style={{
                                        marginRight: '10px',
                                        marginBottom: '10px',
                                        background: 'transparent',
                                        textShadow: 'none',
                                        boxShadow: 'none',
                                        color: '#fff',
                                        backgroundColor: '#439BE3',
                                        borderColor: '#439BE3',
                                    }}
                                >
                                    导入
                                </Button>
                            </Upload>
                        </Col>
                    </Row>
                </Col>
                <Col span={16} style={{ paddingRight: 0 }}>
                    <SearchForm setSearchParams={setSearchParams} exportData={exportData} />
                </Col>
            </Row>
            <ConfigProvider locale={zhCN}>
                <Table
                    rowKey={record => record.id}
                    columns={columns}
                    dataSource={data.data}
                    bordered={true}
                    scroll={{ x: '100%' }}
                    pagination={paginationNo}
                    rowSelection={rowSelection}
                    loading={data.isLoading}
                    style={{ textAlign: 'center', paddingBottom: '1rem' }}
                    locale={{
                        cancelSort: '点击取消排序',
                        triggerAsc: '点击升序',
                        triggerDesc: '点击降序',
                        filterConfirm: '确定',
                        filterReset: '重置',
                        emptyText: '暂无数据',
                    }}
                />
            </ConfigProvider>
            <div
                style={
                    data.data.length > 0
                        ? { display: 'block', position: 'inherit' }
                        : { display: 'none' }
                }
            >
                <Checkbox
                    indeterminate={
                        //负责样式控制
                        data.data.length !== selectedRowKeys.length && selectedRowKeys.length !== 0
                    }
                    onChange={onCheckAllChange} //勾选时触发的方法
                    checked={data.data.length === selectedRowKeys.length} //勾选还是未勾选  options：所有的勾选子项，list
                >
                    全选
                </Checkbox>

                <Radio.Group style={{ marginBottom: 16 }}>
                    {/* <Radio.Button value="del">
                        <Popconfirm
                            title={
                                selectedRowKeys.length == 0 ? '未选中' : `确定要将${selectedRowKeys.length}项,删除吗?`
                            }
                            onConfirm={() =>  
                                 BatchOperation({ active: false, user_ids: selectedRowKeys })
                                //     .then(result => {
                                //         message.success('批量删除成功');
                                //         getList();
                                //         setSelectedRowKeys([]);
                                //     })
                                //     .catch(err => {
                                //         message.error('批量删除失败');
                                //     });
                             }}
                        >
                            删除{' '}
                        </Popconfirm>
                    </Radio.Button> */}
                    <Radio.Button value="on">
                        <Popconfirm
                            title={
                                selectedRowKeys.length === 0 ? '未选中' : `确定要将${selectedRowKeys.length}项,开启吗?`
                            }
                            onConfirm={() => {
                                batchActive({ is_active: true, user_ids: selectedRowKeys })
                                    .then(result => {
                                        message.success('批量开启成功');
                                        getList();
                                        setSelectedRowKeys([]);
                                    })
                                    .catch(err => {
                                        message.error('批量开启失败');
                                    });
                            }}
                        >
                            开启{' '}
                        </Popconfirm>
                    </Radio.Button>
                    <Radio.Button value="off">
                        <Popconfirm
                            title={
                                selectedRowKeys.length === 0 ? '未选中' : `确定要将${selectedRowKeys.length}项,关闭吗?`
                            }
                            onConfirm={() => {
                                batchActive({ is_active: false, user_ids: selectedRowKeys })
                                    .then(result => {
                                        message.success('批量关闭成功');
                                        getList();
                                        setSelectedRowKeys([]);
                                    })
                                    .catch(err => {
                                        message.error('批量关闭失败');
                                    });
                            }}
                        >
                            关闭{' '}
                        </Popconfirm>
                    </Radio.Button>
                </Radio.Group>
            </div>
            <CommonModal
                onOk={() => {
                    formChildrenRef.current
                        .validateFields()
                        .then(value => {
                            //TODO 发起http请求提交当前设置的巡检
                            let newRegions =
                                value.regions &&
                                value.regions.map(item => {
                                    return item.value || item;
                                });
                            value.regions = newRegions || [];
                            value.id = userId;
                            modalType === 'add'
                                ? addWebuser(value)
                                      .then(result => {
                                          getList();
                                          message.success('新增成功');
                                          childRef.current.hiddenModal();
                                          formChildrenRef.current.resetFields();
                                      })
                                      .catch()
                                : editWebuser(value)
                                      .then(result => {
                                          getList();
                                          message.success('编辑成功');
                                          childRef.current.hiddenModal();
                                          formChildrenRef.current.resetFields();
                                      })
                                      .catch();
                        })
                        .catch();
                }}
                childRef={childRef}
                title={modalType === 'add' ? '新增账号' : '编辑账号'}
            >
                <EquipmentForm
                    type={modalType}
                    childRef={formChildrenRef}
                    webuserDetail={webuserDetail}
                />
            </CommonModal>
        </div>
    );
};

const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 18,
    },
};
const EquipmentForm = ({ type, childRef, webuserDetail }) => {
    const [form] = Form.useForm();
    useImperativeHandle(childRef, () => ({
        validateFields: form.validateFields,
        resetFields: form.resetFields,
        setFieldsValue: form.setFieldsValue,
    }));
    const [show, setShow] = useState(3);
    const [permission, setPermission] = useState([]);
    const recursion = data => {
        return map(data, item => {
            return { ...item, key: item.id, title: item.name, children: recursion(item.children) };
        });
    };
    const [roles, setRoles] = useState([]);
    const [selectState, setSelectState] = useState(false); //全选状态
    //企业总览
    const [enterpriseName, setEnterpriseName] = useState();
    //企业名称
    useEffect(() => {
        EnterpriseNameOauth()
            .then(result => {
                setEnterpriseName(result.enterprise_name);
            })
            .catch();
    }, []);
    useEffect(() => {
        getAreaList()
            .then(result => {
                setPermission(recursion(result.data));
            })
            .catch();
        getUserList({ get_all: true })
            .then(result => {
                setRoles(result.data);
            })
            .catch();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onChangeIs_staff = e => {
        //判断新增的是 企业用户3 、 运维用户4
        // if (e == 3) {
        //     setShow(e);
        // } else {
            setShow(e);
        // }
        EnterpriseNameOauth()
            .then(result => {
                setEnterpriseName(result.enterprise_name);
            })
            .catch();
    };
    // 角色分配中的角色数据
    let children = roles.map(item => {
        return (
            <Option key={item.id} value={item.id} title={item.desc}>
                {item.name}
            </Option>
        );
    });
    useEffect(() => {
        if (type === 'add' && enterpriseName) {
            const formValue = {
                enterprise: enterpriseName,
                category: null,
                roles: [],
                regions: [],
                username: null,
                mobile: null,
                email: null,
                location: null,
                real_name: null,
            };
            form.setFieldsValue(formValue);
        }
        if (type === 'edit' && webuserDetail) {
            const { category, username, real_name, regions, mobile, email, location, roles } = webuserDetail;
            let rolesData = [];
            let regionsData = [];
            roles &&
                roles.forEach((item, index) => {
                    return rolesData.push(item.id);
                });
            regions &&  regions.forEach((item, index) => {
                    return regionsData.push(item.id);
                });
            const formValue = {
                category,
                roles: rolesData || [],
                enterprise: get(webuserDetail, ['enterprise', 'name'], undefined),
                regions: regionsData || [],
                username,
                real_name,
                mobile,
                email,
                location,
            };
            form.setFieldsValue(formValue);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, enterpriseName, webuserDetail]);
    if(type==='add'){
        form.setFieldsValue({enterprise: enterpriseName});
    }
    return (
        <Form form={form} {...layout}>
            <FormItem label="账号类型" name="category" rules={[{ required: true, message: '请选择账号类型' }]}>
                <Select
                    onChange={onChangeIs_staff}
                    key={3}
                    placeholder="请选择账号类型"
                    disabled={type === 'edit' ? true : false}
                    virtual={false}
                >
                    <Option value={3}>企业用户</Option>
                    <Option value={4}>运维用户</Option>
                </Select>
            </FormItem>
            <FormItem label="角色分配" name="roles" rules={[{ required: true, message: '请选择角色分配' }]}>
                <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder={'请选择角色'}
                    virtual={false}
                    showArrow
                    dropdownRender={menu => (
                        <div>
                            {menu}
                            <Divider style={{ margin: '2px 0' }} />
                            <div style={{ padding: '4px 8px 8px 8px', cursor: 'pointer' }}>
                                <Checkbox
                                    checked={selectState}
                                    onChange={e => {
                                        // 判断 是否 选中
                                        if (e.target.checked === true) {
                                            setSelectState(true); //选中时 给 checked 改变状态
                                            // 当选的时候 把所有列表值赋值给role
                                            form.setFieldsValue({
                                                roles: roles?.map(item => item.id), //如果选中 给select 赋data里面所有的值
                                            });
                                        } else {
                                            setSelectState(false);
                                            form.setFieldsValue({
                                                roles: [], //如果取消全选 这清空select全部选中状态
                                            });
                                        }
                                    }}
                                >
                                    全选
                                </Checkbox>
                            </div>
                        </div>
                    )}
                >
                    {children}
                </Select>
            </FormItem>
            <FormItem label="数据权限" name="regions" rules={[{ required: true, message: '请输入数据权限' }]}>
                <TreeSelect
                    treeData={permission}
                    multiple
                    treeCheckStrictly={true}
                    dropdownStyle={{ maxHeight: 350, overflow: 'auto' }}
                    placeholder="请选择数据权限"
                />
            </FormItem>
            {(type === 'edit' && webuserDetail && webuserDetail.category == 3) || (type === 'add' && show == 3) ? (
                <FormItem label="企业名称" name="enterprise" rules={[{ required: true, message: '请输入企业名称' }]} style={{display: 'none'}}>
                    <Input placeholder="请输入企业名称" disabled />
                </FormItem>
            ) : (
                ''
            )}
            <FormItem label="账号" name="username" rules={[{ required: true, message: '请输入账号' }]}>
                <Input placeholder="请输入账号" disabled={type === 'edit' ? true : false} />
            </FormItem>
            <FormItem
                label="姓名"
                {...layout}
                name="real_name"
                rules={[{ required: true, message: '请输入姓名', whitespace: true }]}
            >
                <Input placeholder="请输入姓名" />
            </FormItem>
            <FormItem
                label="联系电话"
                {...layout}
                name="mobile"
                rules={[
                    { pattern: /^1(3|4|5|7|8)\d{9}$/, message: '请输入正确的手机号' },
                    { required: true, message: '请输入手机号' },
                ]}
            >
                <Input addonBefore={'+86'} style={{ width: '100%' }} maxLength={11} />
            </FormItem>
            <FormItem
                label="邮箱"
                {...layout}
                name="email"
                rules={[
                    { type: 'email', message: '邮箱格式不正确！' },
                    { required: false, message: '请输入邮箱！' },
                ]}
            >
                <Input />
            </FormItem>
            <FormItem label="地址" name="location" rules={[{ required: false, message: '请输入地址' }]}>
                <Input.TextArea />
            </FormItem>
        </Form>
    );
};

// 搜索
const SearchForm = ({ setSearchParams, exportData }) => {
    const searchLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const [category, setCategory] = useState([]);
    return (
        <Form
            layout="horizontal"
            {...searchLayout}
            onFinish={values => {
                setSearchParams(values);
            }}
        >
            <Row style={{ display: 'flex', flexWrap: 'nowrap', justifyContent: 'space-between', width: '100%' }}>
                <Col span={6}>
                    <FormItem
                        name="username"
                        label="账号名称"
                        getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}
                    >
                        <Input placeholder="请输入账号名称" />
                    </FormItem>
                </Col>
                <Col span={6}>
                    <FormItem
                        name="mobile"
                        label="联系方式"
                        getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}
                    >
                        <Input placeholder="请输入联系方式" />
                    </FormItem>
                </Col>
                <Col span={6}>
                    <FormItem name="category_id" label="账号类型">
                        <Select placeholder="请选择账号类型" allowClear={true} virtual={false} showArrow onFocus={() => {
                            getWebuserCategory()
                                .then((result) => {
                                    setCategory(result.data)
                                })
                                .catch()
                        }}>
                            {map(category, item => {
                                return (
                                    <Option value={item[0]} key={item[0]}>
                                        {item[1]}
                                    </Option>
                                );
                            })}
                        </Select>
                    </FormItem>
                </Col>
                <Col style={{ margin: '0 1%' }}>
                    <FormItem>
                        <Button type="primary" htmlType="submit">
                            搜索
                        </Button>
                    </FormItem>
                </Col>
                <Col style={{ textAlign: 'right' }}>
                    <Button
                        onClick={exportData}
                        type="primary"
                        style={{
                            marginBottom: '10px',
                            background: 'transparent',
                            textShadow: 'none',
                            boxShadow: 'none',
                            color: '#fff',
                            backgroundColor: '#439BE3',
                            borderColor: '#439BE3',
                        }}
                    >
                        导出
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export default AccountManagement;
