import React, { useEffect, useState } from 'react';
import { BreadcrumbCustom } from '../../../components/BreadcrumbCustom';
import { Col, Row } from 'antd';
import { getPatrolRecordDetail } from '../../../api/webServe';
import { MyIcon } from '../../../util/iconfont';
import { week, date, dataMode, patrolLevel } from '../../../global';

export const ReadRecords = () => {
    return (
        <div className="container clearfix">
            <BreadcrumbCustom paths={['首页', '巡检管理', '巡检计划', '查看计划']} className="rumbCustom" showCloseBtn={true}/>
            <div className="row clearfix" style={{ borderRadius: '10px', paddingBottom: '1rem', width: '100%' }}>
                <DeviceDetailsRecords />
            </div>
        </div>
    );
};

const DeviceDetailsRecords = () => {
    var id = window.location.search.slice(window.location.search.lastIndexOf('?') + 1);
    const [detailsData, setDetailsData] = useState([]);
    useEffect(() => {
        getDetail(id.slice(3)); //设备详情
    }, [id]);
    const getDetail = id => {
        getPatrolRecordDetail({ id })
            .then(result => {
                setDetailsData(result.data);
            })
            .catch();
    };

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <Row style={{ marginBottom: '1rem' }}>
                <Col span={24} className="common_title">
                    <MyIcon
                        type="icon_title_patrol_plan"
                        style={{ marginRight: '0.5rem', fontSize: '1.5rem', verticalAlign: 'middle' }}
                    />
                    <span style={{ verticalAlign: 'middle' }}>巡检计划信息</span>
                </Col>
                <Col
                    span={24}
                    style={{
                        background: '#fff',
                        padding: '1rem',
                        borderBottomLeftRadius: '10px',
                        borderBottomRightRadius: '10px',
                    }}
                >
                    <Row>
                        <Col span={12} style={{ ...Styles.colClassName }}>
                            <span style={{ ...Styles.detailsName }}>计划名称：</span>
                            <p style={{ ...Styles.detailsValue }}>{(detailsData && detailsData.name) || '—'}</p>
                        </Col>
                        <Col span={12} style={{ ...Styles.colClassName }}>
                            <span style={{ ...Styles.detailsName }}>巡检周期：</span>
                            <p style={{ ...Styles.detailsValue }}>{(detailsData && date[detailsData.cycle]) || '—'}</p>
                        </Col>
                        <Col span={12} style={{ ...Styles.colClassName }}>
                            <span style={{ ...Styles.detailsName }}>巡检类型：</span>
                            <p style={{ ...Styles.detailsValue }}>
                                {(detailsData && patrolLevel[detailsData.genre]) || '—'}
                            </p>
                        </Col>
                        <Col span={12} style={{ ...Styles.colClassName }}>
                            <span style={{ ...Styles.detailsName }}>巡检日期：</span>
                            <p style={{ ...Styles.detailsValue }}>
                                {detailsData &&
                                    (detailsData.date !== null && detailsData.cycle === 1
                                        ? week[detailsData.date]
                                        : detailsData.date !== null && detailsData.cycle === 2
                                        ? detailsData.date + '号'
                                        : '—')}
                            </p>
                        </Col>
                        <Col span={24} style={{ ...Styles.colClassName }}>
                            <span style={{ ...Styles.detailsName }}>巡检区域：</span>
                            <p style={{ ...Styles.detailsValue, width: '90%', verticalAlign: 'top' }}>
                                {(detailsData.location &&
                                    detailsData.location.map(item => {
                                        return <span key={item.region_id}>{item.region_name},</span>;
                                    })) ||
                                    '—'}
                            </p>
                        </Col>
                        <Col span={12} style={{ ...Styles.colClassName }}>
                            <span style={{ ...Styles.detailsName }}>开始时间：</span>
                            <p style={{ ...Styles.detailsValue }}>
                                {(detailsData.start_date &&
                                    detailsData.start_date.slice(0, 10) + ' ' + detailsData.start_date.slice(11, 19)) ||
                                    '—'}
                            </p>
                        </Col>
                        <Col span={12} style={{ ...Styles.colClassName }}>
                            <span style={{ ...Styles.detailsName }}>结束时间：</span>
                            <p style={{ ...Styles.detailsValue }}>
                                {(detailsData.end_date &&
                                    detailsData.end_date.slice(0, 10) + ' ' + detailsData.end_date.slice(11, 19)) ||
                                    '—'}
                            </p>
                        </Col>
                        <Col span={12} style={{ ...Styles.colClassName }}>
                            <span style={{ ...Styles.detailsName }}>巡检方式：</span>
                            <p style={{ ...Styles.detailsValue }}>
                                {(detailsData && dataMode[detailsData.manner]) || '—'}
                            </p>
                        </Col>
                        <Col span={12} style={{ ...Styles.colClassName }}>
                            <span style={{ ...Styles.detailsName }}>巡检人：</span>
                            <p style={{ ...Styles.detailsValue }}>
                                {(detailsData && detailsData.patrol_username) || '—'}
                            </p>
                        </Col>
                        <Col span={12} style={{ ...Styles.colClassName }}>
                            <span style={{ ...Styles.detailsName }}>巡检时间：</span>
                            <p style={{ ...Styles.detailsValue }}>
                                {(detailsData.start_time &&
                                    detailsData.start_time.slice(0, 10) + ' ' + detailsData.start_time.slice(11, 19)) ||
                                    '—'}
                            </p>
                            {detailsData.end_time && (
                                <p style={{ ...Styles.detailsValue }}>
                                    ~　
                                    {(detailsData.end_time &&
                                        detailsData.end_time.slice(0, 10) + ' ' + detailsData.end_time.slice(11, 19)) ||
                                        '—'}
                                </p>
                            )}
                        </Col>
                        <Col span={12} style={{ ...Styles.colClassName }}>
                            <span style={{ ...Styles.detailsName }}>巡检内容：</span>
                            <p style={{ ...Styles.detailsValue }}>{(detailsData && detailsData.content) || '—'}</p>
                        </Col>
                        <Col span={12} style={{ ...Styles.colClassName }}>
                            <span style={{ ...Styles.detailsName }}>巡检备注：</span>
                            <p style={{ ...Styles.detailsValue }}>{(detailsData && detailsData.remark) || '—'}</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

const Styles = {
    detailsName: {
        marginBottom: '0.2rem',
        fontSize: '0.93rem',
        lineHeight: '30px',
        color: '#666666',
    },
    detailsValue: {
        marginLeft: '1rem',
        fontSize: '1rem',
        lineHeight: '30px',
        color: '#595959',
        display: 'inline-block',
    },
    colClassName: {
        marginTop: '0.5rem',
        marginBottom: '0.5rem',
    },
};
