import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { fakeAuth } from '../../util/fakeAuth';
import { getUser, removeUser, getPermissions } from '../../tools/StorageHelper';
import { Avatar, Menu, Dropdown, message } from 'antd';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import './index.less';
import { MyIcon } from '../../util/iconfont';
class Avatars extends Component {
    state = {
        name: '智慧微电网管理系统',
        userName: 'admin',
    };
    logout = () => {
        fakeAuth.signout(); // 清除token
        fakeAuth.logout('selectedKey');
        fakeAuth.logout('login_tag_key');
        removeUser();
        this.props.history.push('/login'); //跳转到登录页面
    };

    //给右上角的图标点击配置对应的权限访问页面
    onPath = (show) => {
        let path =  getPermissions() != 'undefined' && JSON.parse(getPermissions()).filter(item => {
            return item.name === show
        })
        return path
    }

    onLink = () => {
        const user = getUser();
        if (!user) {
            return null;
        }
        const history = JSON.parse(user).is_staff ? '/admin/operationLog' : '/web/hiddenDanger/list';
        // this.props.history.push(history);
        // fakeAuth.setStorage('selectedKey', history);
        let dataPath = this.onPath('隐患管理').length > 0 && this.onPath('隐患管理')[0].children.filter(item => {
            return item.name === '隐患列表'
        })
        let dataPath2 = this.onPath('系统设置').length > 0 && this.onPath('系统设置')[0].children.filter(item => {
            return item.name === '操作日志'
        })
        dataPath.length > 0 || dataPath2.length > 0
            ? this.props.history.push(history)
            : message.error('很抱歉！您暂无权限访问');
        fakeAuth.setStorage('selectedKey', history);
    };

    render() {
        const user = getUser();
        document.title =
            JSON.parse(user) && JSON.parse(user).system_name
                ? JSON.parse(user).system_name
                // : 'Finsiot智慧监测系统'; //设备全局title
                : '智慧监测系统'; //设备全局title
        const menu = (
            <Menu>
                <Menu.Item>
                    {JSON.parse(user).is_staff ? (
                        <Link
                            to="/admin/changePassword"
                            onClick={value => {
                                let dataPath = this.onPath('系统设置').length > 0 && this.onPath('系统设置')[0].children.filter(item => {
                                    return item.name === '修改密码'
                                })
                                dataPath.length > 0 ? fakeAuth.setStorage('selectedKey', value.view.location.pathname) : message.error('很抱歉！您暂无权限访问该页面内容404');
                                this.props.history.push(value);
                            }}
                        >
                            <UserOutlined className="icon" />
                            <span className="label">修改密码</span>
                        </Link>
                    ) : (
                        <Link
                            to="/web/systemSetting/personal"
                            onClick={value => {
                                let dataPath = this.onPath('系统设置').length > 0 && this.onPath('系统设置')[0].children.filter(item => {
                                    return item.name === '个人中心'
                                })
                                dataPath.length > 0 ? fakeAuth.setStorage('selectedKey', value.view.location.pathname) : message.error('很抱歉！您暂无权限访问该页面内容404');
                                this.props.history.push(value);
                            }}
                        >
                            <UserOutlined className="icon" />
                            <span className="label">个人中心</span>
                        </Link>
                    )}
                </Menu.Item>
                <Menu.Item>
                    <div>
                        <LogoutOutlined className="icon" />
                        <span className="label" onClick={this.logout}>
                            退出登录
                        </span>
                    </div>
                </Menu.Item>
            </Menu>
        );

        const { userName } = this.state;
        const name = getUser();
        if (!name) {
            return null;
        }
        return (
            <div className="userInfo">
                <div className="header_title">
                    <h1>{JSON.parse(name).system_name || this.state.name}</h1>
                </div>
                <div className="header_info">
                    <Avatar
                        shape="square"
                        icon={<MyIcon type="icon_information" />}
                        size="small"
                        onClick={this.onLink}
                        style={{
                            backgroundColor: 'transparent',
                            color: '#000',
                            fontSize: '1.4rem',
                            marginRight: '25px',
                        }}
                    />
                    <span className="userName">{JSON.parse(name).username || userName}</span>
                    {/* 运维用户没有企业名称 显示账号名称(姓名)*/}
                    <Dropdown
                        overlay={menu}
                        placement="bottomCenter"
                        className="dropMenu"
                        style={{ marginLeft: '100px' }}
                    >
                        <Avatar
                            icon={<MyIcon type="icon_account" style={{ fontSize: '25px' }} />}
                            shape="circle"
                            // size="small"
                            style={{ marginLeft: '10px', backgroundColor: 'transparent' }}
                        />
                    </Dropdown>
                </div>
            </div>
        );
    }
}
export default withRouter(Avatars);
