import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import { isEmpty } from 'lodash';
import { Loading } from '../../../components/Loading';
import { EmptyView } from '../../../components/EmptyView'; //空数据占位组件
import { ItemView } from './ItemView';
import { Current } from './Current';
import { SliderCard } from './SliderCard';
import RingEchart from '../../../components/Echart/RingEchart';
import Message from '../Workbench/Message';
import {  getViewInfo, getViewDevice, getViewStatistics } from '../../../api/webServe';
import { MyIcon } from '../../../util/iconfont';
import { ringColor } from '../../../global';
import { getAttrs } from '../../../util/function-util';
/**
 * 工作台组件
 */
export const GeneralView = () => {
    const [messages, setMessage] = useState({ data: null, isLoading: true });
    const [devicePie, setDevicePie] = useState({ data: null, isLoading: true })
    const [statistics, setStatistics] = useState({ data: null, isLoading: true });
    const [total, setTotal] = useState(0);
    useEffect(() => {
        setMessage({ data: null, isLoading: true });
        setDevicePie({ data: null, isLoading: true });
        setStatistics({ data: null, isLoading: true });
        getViewInfo()
            .then(result => {
                setMessage({ data: result.data, isLoading: false });
            })
            .catch(() => {
                setMessage({ data: null, isLoading: false });
            });
        getViewDevice({all: false})
            .then(result => {
                setDevicePie({ data: result.data.info, isLoading: false });
                setTotal(result.data.totals)
            })
            .catch(() => {
                setDevicePie({ data: null, isLoading: false });
            });
        getViewStatistics()
            .then(result => {
                setStatistics({ data: result.data, isLoading: false });
            })
            .catch(() => {
                setStatistics({ data: null, isLoading: false });
            });
    }, []);
    return (
        <div className="container clearfix">
            <div
                className="row clearfix"
                style={{
                    backgroundColor: 'transparent',
                    justifyContent: 'center',
                    width: '100%',
                    paddingLeft: '0px',
                    paddingRight: '1rem',
                    marginBottom: '0px',
                }}
            >
                <ItemView datas={statistics}/>
                <Row style={{ width: '100%' }} gutter={32}>
                    <Col
                        className="gutter-row"
                        xxl={14}
                        xl={14}
                        md={24}
                        style={{ paddingLeft: '16px', paddingRight: '0px', marginBottom: '1rem', }}
                    >
                        <Row style={{ backgroundColor: 'transparent' }}>
                        <Col className="gutter-row" span={24} style={{ marginBottom: '1rem'  }}><Current /></Col>
                        <Col
                                className="gutter-row"
                                span={24}
                                style={{ backgroundColor: '#fff', borderRadius: '10px' }}
                            >
                                <span className="common_title">
                                    <MyIcon
                                        type="icon_title_report_details"
                                        style={{
                                            fontSize: '1.5rem',
                                            marginTop: '-0.1rem',
                                            verticalAlign: 'middle',
                                            paddingRight: '0.6rem',
                                        }}
                                    />
                                    区域概览
                                </span>
                                <SliderCard/>
                            </Col>
                        </Row>
                    </Col>
                    <Col
                        className="gutter-row"
                        xxl={10}
                        xl={10}
                        md={24}
                        style={{ paddingRight: '16px', paddingLeft: '32px' }}
                    >
                        <Row gutter={32}>
                        <Col className="gutter-row" span={24} style={{ marginBottom: '1rem', paddingRight: '0', paddingLeft: '0px', height: '37vh', background: '#fff', borderRadius: '10px'  }}>
                                <span className="common_title">
                                    <MyIcon
                                        type="icon_title_spread"
                                        style={{
                                            fontSize: '1.5rem',
                                            marginTop: '-0.1rem',
                                            verticalAlign: 'middle',
                                            paddingRight: '0.6rem',
                                        }}
                                    />
                                    设备分布
                                </span>
                                <Loading show={devicePie.isLoading}>
                                    {isEmpty(devicePie.data) ? (
                                        <EmptyView />
                                    ) : (
                                        <RingEchart
                                            style={{
                                                height: '30vh',
                                                backgroundColor: '#fff',
                                                borderBottomLeftRadius: '10px',
                                                borderBottomRightRadius: '10px',
                                            }}
                                            title={'今日用电趋势'}
                                            name={'设备分布占比'}
                                            text={'总量'}
                                            color={ringColor}
                                            legend={getAttrs(devicePie.data, 'name')}
                                            seriesData={devicePie.data}
                                            total={total}
                                        />
                                    )}
                                </Loading>
                            </Col>
                        <Col
                            className="gutter-row"
                            span={24}
                            style={{ paddingRight: '0', paddingLeft: '0px', height: '100%' }}
                        >
                            <Message height={'430px'} messages={messages}/>
                        </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
