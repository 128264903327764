import React from 'react';
import { Spin } from 'antd';
import loadingStyle from './loading.module.less';
export const Loading = ({ show, text, children, hiddenShade = false, height }) => {
    return (
        <div className={loadingStyle.container} style={{height: height}}>
            {hiddenShade ? (
                <div className={loadingStyle.content}>
                    <Spin spinning={show} indicator={text ? text : '加载中...'}>
                        {children}
                    </Spin>
                </div>
            ) : (
                <Spin spinning={show} indicator={text ? text : '加载中...'}>
                    {children}
                </Spin>
            )}
        </div>
    );
};
