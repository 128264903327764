import React, { useEffect, useState } from 'react';
import { BreadcrumbCustom } from '../../../components/BreadcrumbCustom';
import { Col, Row } from 'antd';
import { getPatrolTaskDetail } from '../../../api/webServe';
import { MyIcon } from '../../../util/iconfont';
import { date, patrolLevel, dataMode } from '../../../global';
import { isEmpty } from 'lodash';
import { EmptyView } from '../../../components/EmptyView';

export const ReadTasks = () => {
    return (
        <div className="container clearfix">
            <BreadcrumbCustom
                paths={['首页', '巡检管理', '巡检任务', '查看任务']}
                className="rumbCustom"
                showCloseBtn={true}
            />
            <div className="row clearfix" style={{ borderRadius: '10px', paddingBottom: '1rem', width: '100%' }}>
                <DeviceDetailsTask />
            </div>
        </div>
    );
};

const DeviceDetailsTask = () => {
    var id = window.location.search.slice(window.location.search.lastIndexOf('?') + 1);
    const [detailsData, setDetailsData] = useState([]);
    useEffect(() => {
        getDetail(id.slice(3)); //设备详情
    }, [id]);
    const getDetail = id => {
        getPatrolTaskDetail({ id })
            .then(result => {
                setDetailsData(result.data);
            })
            .catch();
    };
    return (
        <div style={{ width: '100%', height: '100%' }}>
            <Row style={{ marginBottom: '1rem' }}>
                <Col span={24} className="common_title">
                    <MyIcon
                        type={detailsData.status === 2 ? 'icon_title_alarm' : 'icon_title_patrol_details'}
                        style={{ marginRight: '0.5rem', fontSize: '1.5rem', verticalAlign: 'middle' }}
                    />
                    <span style={{ verticalAlign: 'middle' }}>巡检任务信息</span>
                </Col>
                <Col
                    span={24}
                    style={{
                        background: '#fff',
                        padding: '1rem',
                        borderBottomLeftRadius: '10px',
                        borderBottomRightRadius: '10px',
                    }}
                >
                    <Row>
                        {detailsData && detailsData.status === 2 && (
                            <Col span={24} style={{ position: 'absolute', right: '0rem', top: '0.5rem' }}>
                                {/* <p
                                    style={{
                                        background: '#DF2923',
                                        width: '70px',
                                        height: '70px',
                                        borderRadius: '50%',
                                        textAlign: 'center',
                                        fontSize: '1.5rem',
                                        lineHeight: '66px',
                                        color: ' #fff',
                                        fontWeight: 500,
                                    }}
                                >
                                    逾期
                                </p> */}
                                <p>
                                    <MyIcon
                                        type="iconyiguoqi"
                                        style={{ marginRight: '0.5rem', fontSize: '5rem', verticalAlign: 'middle' }}
                                    />
                                </p>
                            </Col>
                        )}
                        <Col span={12} style={{ ...Styles.colClassName }}>
                            <span style={{ ...Styles.detailsName }}>任务名称：</span>
                            <p style={{ ...Styles.detailsValue }}>{(detailsData && detailsData.name) || '—'}</p>
                        </Col>
                        <Col span={12} style={{ ...Styles.colClassName }}>
                            <span style={{ ...Styles.detailsName }}>巡检周期：</span>
                            <p style={{ ...Styles.detailsValue }}>{(detailsData && date[detailsData.cycle]) || '—'}</p>
                        </Col>
                        <Col span={12} style={{ ...Styles.colClassName }}>
                            <span style={{ ...Styles.detailsName }}>巡检类型：</span>
                            <p style={{ ...Styles.detailsValue }}>
                                {(detailsData && patrolLevel[detailsData.genre]) || '—'}
                            </p>
                        </Col>
                        <Col span={12} style={{ ...Styles.colClassName }}>
                            <span style={{ ...Styles.detailsName }}>巡检方式：</span>
                            <p style={{ ...Styles.detailsValue }}>
                                {(detailsData && dataMode[detailsData.manner]) || '—'}
                            </p>
                        </Col>
                        <Col span={24} style={{ ...Styles.colClassName }}>
                            <span style={{ ...Styles.detailsName }}>巡检区域：</span>
                            <p style={{ ...Styles.detailsValue, width: '90%', verticalAlign: 'top' }}>
                                {(detailsData.location &&
                                    detailsData.location.map(item => {
                                        return <span key={item.id}>{item.name},</span>;
                                    })) ||
                                    '—'}
                            </p>
                        </Col>
                        <Col span={12} style={{ ...Styles.colClassName }}>
                            <span style={{ ...Styles.detailsName }}>负责人：</span>
                            <p style={{ ...Styles.detailsValue }}>{(detailsData && detailsData.username) || '—'}</p>
                        </Col>
                        <Col span={12} style={{ ...Styles.colClassName }}>
                            <span style={{ ...Styles.detailsName }}>巡检内容：</span>
                            <p style={{ ...Styles.detailsValue }}>{(detailsData && detailsData.content) || '—'}</p>
                        </Col>
                        <Col span={12} style={{ ...Styles.colClassName }}>
                            <span style={{ ...Styles.detailsName }}>备注：</span>
                            <p style={{ ...Styles.detailsValue }}>{(detailsData && detailsData.remark) || '—'}</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {detailsData && detailsData.status === 1 && (
                <Row style={{ marginBottom: '1rem' }}>
                    <Col span={24} className="common_title">
                        <MyIcon
                            type="icon_title_patrol_result"
                            style={{ marginRight: '0.5rem', fontSize: '1.5rem', verticalAlign: 'middle' }}
                        />
                        <span style={{ verticalAlign: 'middle' }}>巡检任务结果</span>
                    </Col>
                    <Col
                        span={24}
                        style={{
                            background: '#fff',
                            padding: '1rem',
                            borderBottomLeftRadius: '10px',
                            borderBottomRightRadius: '10px',
                        }}
                    >
                        {isEmpty(detailsData.result) ? (
                            <EmptyView />
                        ) : (
                            detailsData.result.map((item, index) => {
                                return (
                                    <Row style={{ margin: '1rem auto', fontSize: '0.93rem' }} key={"ids"+ Math.random()}>
                                        <Col style={{ minWidth: '10rem' }}>
                                            {item +
                                                (item === '正常'
                                                    ? ''
                                                    : detailsData.result.length === 1
                                                    ? '；'
                                                    : index === detailsData.result.length - 1
                                                    ? ' 。'
                                                    : ' ，')}
                                        </Col>
                                    </Row>
                                );
                            })
                        )}
                    </Col>
                </Row>
            )}
        </div>
    );
};

const Styles = {
    detailsName: {
        marginBottom: '0.2rem',
        fontSize: '0.93rem',
        lineHeight: '30px',
        color: '#666666',
    },
    detailsValue: {
        marginLeft: '1rem',
        fontSize: '1rem',
        lineHeight: '30px',
        color: '#595959',
        display: 'inline-block',
    },
    detailsTitle: {
        color: '#333',
        fontSize: '1.1rem',
        lineHeight: '30px',
        marginBottom: '0.2rem',
    },
    colClassName: {
        marginTop: '0.5rem',
        marginBottom: '0.5rem',
    },
};
