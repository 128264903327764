import { omit } from 'lodash';
import { get, post, put, remove, patch } from '../util/NetUtils';

export const login = params => post('/v1/oauth/login/', params); //登录
export const getMobile = params => get('/v1/oauth/mobile_code/', params); //获取验证码
export const changePassword = params => post('/v1/oauth/change_password/', params); //修改密码
export const forgetPassword = params => post('/v1/oauth/forget_password/', params); //忘记密码

export const getWebuser = params => get('/v1/oauth/webuser/', params); //账户管理
export const getWebuserCategory = params => get('/v1/oauth/webuser/category/', params); //账户类型
export const getWebuserDetail = params => get(`/v1/oauth/webuser/${params}/`);
export const getPermission = params => get('/v1/oauth/permission/', params);
export const addWebuser = params => post('/v1/oauth/webuser/', params);
export const editWebuser = params => put(`/v1/oauth/webuser/${params.id}/`, omit(params, 'id'));
export const changeWebuserActive = params => put(`/v1/oauth/webuser/${params.id}/active/`, omit(params, 'id'));
export const delWebuser = id => remove('/v1/oauth/webuser/', id);
export const batchActive = params => put('/v1/oauth/webuser/batch_active/', params);
export const EnterpriseNameOauth = () => get(`/v1/oauth/enterprise_name/`); //账号管理中的企业名称
export const importWebuser = params => post(`/v1/oauth/webuser/import/`, params, { file: true }); //账号导入
export const exportWebuser = () => get(`/v1/oauth/webuser/export/`); //账号导出

export const getEnterprise_profile = () => get('/v1/workbench/enterprise_profile/'); //企业概况
export const getMessage = () => get('/v1/workbench/alarm_infonew/'); //告警信息
export const getTrend = params => get('/v1/workbench/tlectricity_trend/', params); //今日用电趋势
export const getElectricityOver = () => get('/v1/workbench/electricity_over/'); //用电概况
export const getRunStatus = () => get('/v1/workbench/run_status/'); //今日运行情况
export const getAlarmStatistic = () => get('/v1/workbench/alarm_statistics/'); //告警统计
export const workbenchCategory = params => get(`/v1/workbench/category/`, params); //用电趋势中的类型

export const getLog = (page, page_size) => get('/v1/audit/log/', { page, page_size }); //操作日志

//主页
export const getViewInfo = () => get('/v1/enterprise_overview/alarm/info/'); //主页中的告警信息
export const getViewDevice = params => get('/v1/enterprise_overview/region/device/count/', params); //主页中的区域设备数据
export const getViewStatistics = () => get('/v1/enterprise_overview/statistics/'); //获取主页中的二级区域数量
export const getViewTree = () => get('/v1/enterprise_overview/tree/'); //主页区域树
export const getViewCurrent = params => get(`/v1/enterprise_overview/current/${params.id}/`, omit(params, 'id')); //主页区域树中的电流

//大屏
export const bigScreen_1 = () => get(`/v1/monitor_hall/electricity/enterprise_overview/`);
export const bigScreen_2 = () => get(`/v1/monitor_hall/electricity/enterprise_exponent/`);
export const bigScreen_3 = () => get(`/v1/monitor_hall/electricity/device_statistics/`);
export const bigScreen_4 = () => get(`/v1/monitor_hall/electricity/electricity_trend/`);
export const bigScreen_5 = () => get(`/v1/monitor_hall/electricity/power_factor/`);
export const bigScreen_6 = () => get(`/v1/monitor_hall/electricity/three_unbalance/`);
export const bigScreen_7 = () => get(`/v1/monitor_hall/electricity/alarm_statistics/`);
export const bigScreen_8 = () => get(`/v1/monitor_hall/electricity/alarm_rank/`);
export const bigScreen_9 = () => get(`/v1/monitor_hall/electricity/alarm_trend/`);
export const bigScreen_10 = () => get(`/v1/monitor_hall/electricity/alarm_info/`);
//监测管理
export const getAreaList = params => get('/v1/region/', params); //获取区域列表
export const addArea = params => post(`/v1/region/`, params);
export const editArea = params => put(`/v1/region/${params.id}/`, omit(params, 'id'));
export const removeArea = id => remove(`/v1/region/`, id);
export const searchArea = params => get(`/v1/region/search/`, params); //区域列表搜索
export const exportArea = params => get(`/v1/region/export/`, params);
export const importArea = params => post(`/v1/region/import/`, params, { file: true });
//设备管理
export const getEquipmentList = params => get(`/v1/region/device/`, params, { isSpecial: true });
export const equipmentDetail = params => get(`/v1/region/device/${params.id}/`);
export const addEquipment = params => post(`/v1/region/device/`, params);
export const editEquipment = params => put(`/v1/region/device/${params.id}/`, omit(params, 'id'));
export const editActivation = params => put(`/v1/device/activation/`, params, { timeout: 130000 }); //设备激活
export const removeEquipment = id => remove(`/v1/region/device/`, id);
export const exportEquipment = params => get(`/v1/region/device/export/`, params);
export const importEquipment = params => post(`/v1/region/device/import/`, params, { file: true });
export const qrCodeToEquipment = params => get(`/v1/region/device/category/`, params);
export const equipmentDetailDevice = params => get(`/v1/energycon/device/${params.id}/`, omit(params, 'id'));
export const electricityMeterDevice = params => get(`/v1/region/device/electricity/meter/`, params);
export const smokerDetailDevice = params => get(`/v1/region/device/smoker/timing/`, omit(params, 'id')); //烟雾报警
export const temperatureDetailDevice = params => get(`/v1/device/temperature/timing/`, omit(params, 'id')); //温湿度
export const doorDetailDevice = params => get(`/v1/region/device/door/timing/`, params); //门磁动态
export const MotorDetailDevice = params => get(`/v1/region/device/industrial/timing/`, params); //工业电机时序
export const editCamera = params => put(`/v1/region/device/${params.id}/config/camera/`, omit(params, 'id')); //摄像头配置
export const updateCamera = params => put(`/v1/region/device/firmware/update/`, params); //摄像头配置中的更新升级
export const getMotorConfig = params => get(`/v1/region/device/${params.id}/config/`); // 获取工业电机配置
export const editMotorConfig = params => put(`/v1/region/device/${params.id}/config/camera/`, omit(params, 'id')); // 设置工业电机配置
export const dataExport = params =>
    get(`/v1/energycon/download/${params.id}/`, omit(params, ['id', 'time', 'equipmentName'])); //数据导出
export const updateDevice = params => put(`/v1/device/update/${params.id}/`, omit(params, 'id')); //设备更换
export const getRisk = params => get(`/v1/region/risk/`, params); //风险等级
//功能管理
export const getFunctionList = params => get(`/v1/device/function/`, params); //功能列表
export const editFunction = params => put(`/v1/device/function/${params.id}/`, omit(params, 'id')); //功能编辑
export const addFunction = params => post(`/v1/device/function/`, params);
export const removeFunction = id => remove(`/v1/device/function/`, id);
//能耗分析
export const getContrast = params => get(`/v1/energycon/contrast/`, params);
export const requestPieChart = params => get(`/v1/energycon/distribute/`, params);
export const requestSankeyDiagram = params => get(`/v1/energycon/flow/`, params);
export const requestHotChart = params => get(`/v1/energycon/trend/`, params);
export const treeHasIcon = params => get(`/v1/energycon/region_device/`, params);
export const EnterpriseName = () => get(`/v1/energycon/enterprise_name/`); //企业名称
//区域统计
export const getRegionFunctionList = params => get(`/v1/energycon/region_function/`, params); //区域功能列表
export const getAreaPie = params =>
    get(
        `/v1/energycon/distribute/${params.id}/${params.isCategory ? 'category' : 'region'}/`,
        omit(params, ['id', 'isCategory']),
    );
export const getAreaBar = params =>
    get(
        `/v1/energycon/contrast/${params.id}/${params.isCategory ? 'category' : 'region'}/`,
        omit(params, ['id', 'isCategory']),
    );
export const getAreaGraph = params =>
    get(
        `/v1/energycon/flow/${params.id}/${params.isCategory ? 'category' : 'region'}/`,
        omit(params, ['id', 'isCategory']),
    );
export const getAreaTrend = params =>
    get(
        `/v1/energycon/trend/${params.id}/${params.isCategory ? 'category' : 'region'}/`,
        omit(params, ['id', 'isCategory']),
    );
//能耗排名 --按功能统计
export const getRegionFunction = params =>
    get(`/v1/energycon/region_function/${params.id}/`, omit(params, ['id', 'isCategory']));
//能耗排名 --按设备统计
export const getRegionDevices = params =>
    get(`/v1/energycon/region_device/${params.id}/`, omit(params, ['id', 'isCategory']));
//能耗排名 --按区域统计
export const getFunctionRegion = params =>
    get(`/v1/energycon/function_region/${params.id}/`, omit(params, ['id', 'isCategory']));
//对比分析
export const getContrasts = params => get(`/v1/energycon/contrast/analyze/`, omit(params, ['id', 'isCategory']));
//能耗报表
export const getRegionReport = params => get(`/v1/energycon/report/${params.id}/`, omit(params, 'id'));
//能耗报表导入
export const importReport = params => get('/v1/energycon/report/', params, { file: true });
//峰谷用电
export const getPeakHour = params => get(`/v1/energycon/peak_hour/${params.id}/`, omit(params, 'id'));

//系统设置
export const getPachhours = () => get('/v1/sys_setting/peakhours/');
export const addPachhours = params => patch('/v1/sys_setting/peakhours/', params);
export const getNoticesets = () => get('/v1/sys_setting/noticesets/');
export const addNoticesets = params => post('/v1/sys_setting/noticesets/', params); //新增告警通知设置
export const editNoticesets = () => patch('/v1/sys_setting/noticesets/'); //更新告警通知设置
export const delNoticesets = () => remove('/v1/sys_setting/noticesets/'); //删除告警通知设置
export const getUsers = params => get(`/v1/sys_setting/users/${params}/`);
export const changeAlarmSets = params => put('/v1/alarm/sets/', params); //告警等级设置

export const getRelation = () => get('/v1/sys_setting/scene/'); //关联设置
export const getRelationDetail = params => get(`/v1/sys_setting/scene/${params}`); //关联设置详情
export const editRelation = params => put(`/v1/sys_setting/scene/${params.id}/`, omit(params, ['id', 'ret_device'])); //修改关联设置
export const addRelation = params => post('/v1/sys_setting/scene/', omit(params, 'ret_device')); //新增关联设置
export const removeRelation = params => remove('/v1/sys_setting/scene/', params); //删除单个关联设置
export const getRelationTree = params => get('/v1/sys_setting/scene/tree/', params); //删除单个关联设置

// 个人中心
export const getUsersInfo = () => get('/v1/sys_setting/users/one/');
export const editUsersInfo = params => patch(`/v1/sys_setting/users/one/?location=${params}`);
//定时开关
export const getCrontab = params => get('/v1/sys_setting/crontab/', params);
export const getCrontabDetail = params => get(`/v1/sys_setting/crontab/${params}/`, {});
export const addCrontab = params => post('/v1/sys_setting/crontab/', params);
export const editCrontab = params => put(`/v1/sys_setting/crontab/${params.id}/`, omit(params, 'id'));
export const delCrontab = params => remove('/v1/sys_setting/crontab/', params);
export const crontabMultiDelete = ids => remove('/v1/sys_setting/crontab/multi_delete/?ids=', ids);
export const crontabMultiUpdate = params => put('/v1/sys_setting/crontab/multi_update/', params);
// 一键开关
export const changeSwitch = params => post('/v1/workbench/switch/', params);
export const getPreControl = () => get('/v1/workbench/pre_control/');
export const getPreControlDetail = params => get(`/v1/workbench/pre_control/${params}/`);
export const addPreControl = params => post('/v1/workbench/pre_control/', params);
export const editPreControlDetail = params => put(`/v1/workbench/pre_control/${params}/`);
export const delPreControlDetail = params => remove('/v1/workbench/pre_control/', params);
export const getRegion = params => get(`/v1/region/${params}/`); //获取区域列表

//隐患管理
export const getAlarmTree = params => get('/v1/alarm/tree/', params); //只有区域
export const searchAreaRegion = params => get(`/v1/alarm/tree/?search=${params}/`); //只能搜索到区域
export const getRegionDevice = params => get('/v1/alarm/region_device/', params); //区域和设备都有
export const searchArea2 = params => get(`/v1/alarm/region_device/?search=${params}`); //隐患列表搜索（包含区域和设备）
// export const getAlarm = params => get(`/v1/alarm/${params.id}/`, omit(params, 'id'));
export const getAlarm = params => get(`/v1/alarm/`, params);
export const changeAlarm = params => patch('/v1/alarm/', params);
export const addAlarm = params => post('/v1/alarm/', params);
export const alarmExport = (region_id, params) => get(`/v1/alarm/download/${region_id}/`, params);
export const getAlarmUser = params => get('/v1/alarm/users/', params); // 指派人
export const getAlarmSets = () => get('/v1/alarm/sets/'); //获取告警等级设置
export const getAlarmDetail = params => get(`/v1/alarm/one/${params.id}/`); //告警详情
export const getAlarmGrade = params => get(`/v1/alarm/grade/`, params); //告警等级
export const getGrade = params => get(`/v1/alarm/grade/`, params); //告警等级
export const getAlarmSetsCategory = () => get('/v1/alarm/event/category/'); //获取告警类型
export const getGradeCategory = params => get(`/v1/alarm/grade/category/`, params); //告警等级
export const getStatusCategory = params => get(`/v1/alarm/status/category/`, params); //告警状态
export const getAlarmEvent = params => get(`/v1/alarm/event/`, params); //告警异常类型
export const getAlarmRank = params => get(`/v1/alarm/rank/${params.id}/`, omit(params, 'id')); //告警排行
export const getAlarmTrend = params => get(`/v1/alarm/trend/`, params); //告警趋势
//export const getAlarmPicture = () => get(`/test/`); //隐患附件图片

//巡检管理
export const getPatrol = params => get(`/v1/patrol/${params}/`);
export const getPatrolCategoryTag = params => get(`/v1/patrol/category_tag/${params}/`);
export const getPatrolTag = (id, params) => get(`/v1/patrol/tag/${id}/`, params);
//巡检管理 -- 巡检计划
export const getPatrolRecord = params => get('/v1/patrol/plan/', params);
export const getPatrolRecordDetail = params => get(`/v1/patrol/plan/${params.id}/`);
export const addPatrolRecord = params => post('/v1/patrol/plan/', omit(params, 'times'));
export const editPatrolRecord = params => put(`/v1/patrol/plan/${params.id}/`, omit(params, ['id', 'times']));
export const removePatrolRecord = params => remove('/v1/patrol/plan/', params);
export const removePatrolRecordBatch = params => remove('/v1/patrol/plan/?id=', params);
export const getPatrolUser = () => get('/v1/patrol/user/'); //巡检人
//巡检管理 --  巡检任务
export const getPatrolTask = params => get('/v1/patrol/task/', params);
export const getPatrolTaskDetail = params => get(`/v1/patrol/task/${params.id}/`);
export const removePatrolTask = params => remove('/v1/patrol/task/', params);

//健康报表
export const getCrontabDate = params => get('/v1/report/crontab_date/', params);
export const getPowerExpense = params => get('/v1/report/power_expense/', params);
export const getEnterpriseOverview = params => get('/v1/report/enterprise_overview/', params);
export const getThreePhase = params => get('/v1/report/three_phase/', params);
export const getReport = params => get('/v1/report/', params);
export const getReportTrend = params => get(`/v1/report/alarm/trend/${params.id}/`, omit(params, 'id')); //告警趋势
export const getReportEvent = params => get(`/v1/report/alarm/event/${params.id}/`, omit(params, 'id')); //告警异常类型
export const getReportGrade = params => get(`/v1/report/alarm/grade/${params.id}/`, omit(params, 'id')); //告警等级
export const getReportFunction = params => get('/v1/report/power_expense/function/', params); //电费功能分布
export const getReportRegion = params => get('/v1/report/power_expense/region/', params); //电费区域分布
export const getReportPowerTrend = params => get('/v1/report/power_expense/trend/', params); //电费趋势和时段

// 摄像头
export const getRegionCamera = params => get('/v1/camera/region/tree/', params); //获取区域列表
export const getCamera = params => get(`/v1/camera/`, params); //摄像头
export const getCameraDetail = params => get(`/v1/camera/${params.id}/`, omit(params, 'id')); //摄像头详情页

// 保险业务
export const getInsuranceRunStatus = params => get('/v1/monitor_hall/centre/run_status/', params); //运行状态
export const getInsuranceDeviceStatistics = params => get('/v1/monitor_hall/centre/device_statistics/', params); //设备统计
export const getInsuranceRegionStatistics = params => get('/v1/monitor_hall/centre/region_statistics/', params); //区域统计
export const getInsuranceRegionAlarm = params => get('/v1/monitor_hall/centre/region_alarm/', params); //高危统计
export const getInsuranceRegionSon = params => get('/v1/monitor_hall/centre/region_son/', params); //区域信息（地图）
export const getInsuranceNavigation = params => get('/v1/monitor_hall/centre/navigation/', params); //区域信息
export const getInsuranceAlarmTrend = params => get('/v1/monitor_hall/centre/alarm_trend/', params); //告警趋势
export const getInsuranceAlarmInfo = params => get('/v1/monitor_hall/centre/alarm_info/', params); //最新告警

export const getInsuranceChildrenNavigation = params => get('/v1/monitor_hall/matter/navigation/', params); //区域信息
export const getInsuranceChildrenRunStatus = params => get('/v1/monitor_hall/matter/run_status/', params); //运行状态
export const getInsuranceChildrenTemperature = params => get('/v1/monitor_hall/matter/temperature/', params); //温度湿度
export const getInsuranceChildrenAlarmStatistics = params => get('/v1/monitor_hall/matter/alarm_statistics/', params); //告警统计
export const getInsuranceChildrenAlarmRank = params => get('/v1/monitor_hall/matter/alarm_rank/', params); //告警排行
export const getInsuranceChildrenCamera = params => get('/v1/monitor_hall/matter/camera/', params); //摄像头
export const getInsuranceChildrenAlarmTrend = params => get('/v1/monitor_hall/matter/alarm_trend/', params); //告警趋势
export const getInsuranceChildrenAlarmInfo = params => get('/v1/monitor_hall/matter/alarm_info/', params); //告警信息
