import React from 'react';
import {Link} from 'react-router-dom';
import {Carousel, Row, Col, message} from 'antd';
import {EmptyView} from '../../../components/EmptyView';
import {Loading} from '../../../components/Loading';
import {MyIcon} from '../../../util/iconfont';
import {dataConvert, alarmLevel} from '../../../global';
import {isEmpty} from 'lodash';
import {getPermissions} from '../../../tools/StorageHelper';

/**
 * 用在了以下两个组件的右下角:
 * 工作台 -> 智慧微电网
 * 工作台 -> 主页
 * @param messages
 * @param height
 * @returns {JSX.Element}
 * @constructor
 */
const Message = ({messages, height}) => {
    //给CT环保中最新告警右侧的更多按钮事件配置相应的权限控制
    const onPath = (show) => {
        let path = getPermissions() != 'undefined' && JSON.parse(getPermissions()).filter(item => {
            return item.name === show
        })
        return path
    }
    let dataPath = onPath('隐患管理').length > 0 && onPath('隐患管理')[0].children.filter(item => {
        return item.name === '隐患列表'
    })
    //根据配置的权限触发对应的点击事件
    const handle = (e, value) => {
        if (dataPath.length === 0) {
            e.preventDefault();
            message.error('暂无访问权限');
            //e.stopPropagation(); //阻止冒泡
        }
    };
    return (
        <div style={{backgroundColor: '#fff', borderRadius: '10px'}}>
            <span className="common_title" style={{marginBottom: '0.3rem',display:'flex',flexDirection:'row',justifyContent:'space-between' ,alignItems: 'center', }}>
                <span style={{ display: 'flex',flexDirection:'row', alignItems: 'center',width:'400px',margin:0}}>
                    <MyIcon
                        type="icon_alrm_info"
                        style={{
                            fontSize: '1.5rem',
                            marginTop: '-0.1rem',
                            verticalAlign: 'middle',
                            paddingRight: '0.6rem',
                        }}
                    />
                    告警列表
                    <span
                        style={{
                            fontSize: '12px',
                            verticalAlign: 'bottom',
                            marginTop: '0.5rem',
                            marginLeft: '0.3rem',
                        }}
                    >
                       (近30条)
                    </span>
                </span>
                {height && (
                    <span className={'moreText'}>
                        {messages.data && messages.data.length !== 0 && (
                            <Link
                                to="/web/hiddenDanger/list"
                                onClick={e => {
                                    handle(e, '隐患列表');
                                }}
                                style={{color: '#666'}}
                            >
                                更多&gt;
                            </Link>
                        )}
                    </span>
                )}
            </span>
            <div className="workbench_title">
                <Row gutter={16} style={{width: '100%'}}>
                    <Col
                        span={height ? 16 : 12}
                        style={{
                            textAlign: 'center',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            verticalAlign: 'middle',
                        }}
                    >
                        告警消息
                    </Col>
                    <Col
                        span={2}
                        style={{
                            textAlign: 'center',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            verticalAlign: 'middle',
                        }}
                    >
                        等级
                    </Col>
                    {!height && (
                        <Col
                            span={4}
                            style={{
                                textAlign: 'center',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                verticalAlign: 'middle',
                            }}
                        >
                            状态
                        </Col>
                    )}
                    <Col
                        span={6}
                        style={{
                            textAlign: 'center',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            verticalAlign: 'middle',
                        }}
                    >
                        告警时间
                    </Col>
                </Row>
            </div>
            <div className={height ? 'slider-data message generalViewMessage' : 'slider-data message'}>
                <Loading show={messages.isLoading}>
                    {isEmpty(messages.data) ? (
                        <EmptyView description={'暂无告警信息'}/>
                    ) : (
                        <Carousel dotPosition="left" dots={false} autoplay={true}>
                            {messages.data.map((item, index) => {
                                return (
                                    <li
                                        style={{width: '100%', borderBottom: '1px solid #F0F0F0'}}
                                        className="workbench_message"
                                        key={index}
                                    >
                                        <Row gutter={16}>
                                            <Col
                                                span={height ? 16 : 12}
                                                style={{
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis',
                                                    verticalAlign: 'middle',
                                                }}
                                            >
                                                {height ? item.content : item.location}
                                            </Col>
                                            <Col
                                                span={2}
                                                className="grade"
                                                style={
                                                    item.grade === 0
                                                        ? styles.hiddenDanger
                                                        : item.grade === 1
                                                            ? styles.highRisk
                                                            : styles.fault
                                                }
                                            >
                                                {alarmLevel[item.grade]}
                                            </Col>
                                            {!height && (
                                                <Col
                                                    span={4}
                                                    className="state"
                                                    style={{
                                                        textAlign: 'center',
                                                        overflow: 'hidden',
                                                        whiteSpace: 'nowrap',
                                                        textOverflow: 'ellipsis',
                                                        verticalAlign: 'middle',
                                                    }}
                                                >
                                                    {item.handler_status === null
                                                        ? '无'
                                                        : dataConvert[item.handler_status]}
                                                </Col>
                                            )}
                                            <Col
                                                span={6}
                                                className="time"
                                                style={{
                                                    verticalAlign: 'middle',
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis',
                                                }}
                                            >
                                                {item.time && item.time.slice(0, 10) + ' ' + item.time.slice(11, 19)}
                                            </Col>
                                        </Row>
                                    </li>
                                );
                            })}
                        </Carousel>
                    )}
                </Loading>
            </div>
        </div>
    );
};

export default Message;

const styles = {
    hiddenDanger: {
        borderRadius: '0.8rem',
        padding: '0 0.5rem',
        fontSize: '12px',
        alignItems: 'center',
        textAlign: 'center',
        color: '#F7CD85',
        border: '1px solid #F7CD85',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        verticalAlign: 'middle',
    },
    highRisk: {
        borderRadius: '0.8rem',
        fontSize: '12px',
        alignItems: 'center',
        textAlign: 'center',
        color: '#EF9076',
        border: '1px solid #EF9076',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        verticalAlign: 'middle',
    },
    fault: {
        borderRadius: '0.8rem',
        fontSize: '12px',
        alignItems: 'center',
        textAlign: 'center',
        color: '#C7C7C7',
        border: '1px solid #C7C7C7',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        verticalAlign: 'middle',
    },
    antTable: {
        fontSize: '1rem !important',
        lineHeight: '0.9 !important',
    },
};
