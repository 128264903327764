import React, { useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { get, isEmpty, isNull, map, toString, isArray, size } from 'lodash';
import { BreadcrumbCustom } from '../../../components/BreadcrumbCustom';
import {
    Button,
    Checkbox,
    Col,
    ConfigProvider,
    DatePicker,
    Divider,
    Form,
    Input,
    message,
    Popconfirm,
    Radio,
    Row,
    Select,
    Space,
    Switch,
    Table,
    TimePicker,
    Tooltip,
} from 'antd';
import moment from 'moment';
import zhCN from 'antd/es/locale/zh_CN';
import locale from 'antd/es/date-picker/locale/zh_CN';
import { commonPageSize, switchText } from '../../../global';
import ToastHelper from '../../../tools/ToastHelper';
import { GlobalModal } from '../../../components/GlobalModal';
import { CommonTreeSwitch } from '../../../components/CommonTreeSwitch';
import { MyIcon } from '../../../util/iconfont';
import {
    addCrontab,
    crontabMultiDelete,
    crontabMultiUpdate,
    delCrontab,
    editCrontab,
    getAreaList,
    getCrontab,
    getCrontabDetail,
    getEquipmentList,
    getFunctionList,
} from '../../../api/webServe';
import lessStyle from './style.module.less';
import { disabledDate_endDay } from '../../../util/timeUtil'

const FormItem = Form.Item;
const format = 'HH:mm';
const CheckboxGroup = Checkbox.Group;
const { Option } = Select;

/**
 * 把定时任务的关闭日期格式化
 * @param {*} dateData
 * @returns
 */
const dateFormat = dateData => {
    let date = new Date(dateData);
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    m = m < 10 ? '0' + m : m;
    let d = date.getDate();
    d = d < 10 ? '0' + d : d;
    const time = y + '-' + m + '-' + d;
    return time;
};

/**
 * 把定时任务的开启时间和结束时间格式化
 * @param {*} timeData
 * @returns
 */
const timeFormat = timeData => {
    let date = new Date(timeData);
    let h = date.getHours();
    let m = date.getMinutes();
    m = m < 10 ? '0' + m : m;
    const time = h + ':' + m;
    return time;
};

/**
 * 定时开关组件
 * @returns
 */
export const TimeSwitchV1 = () => {
    return (
        <div className={`container clearfix ${lessStyle.containerForm}`}>
            <BreadcrumbCustom paths={['首页', '系统设置', '定时开关']} className="rumbCustom" />
            <div
                className="row clearfix"
                style={{ backgroundColor: '#fff', padding: '1rem 1rem 0', margin: '1rem', borderRadius: '10px' }}
            >
                <HiddenTab />
            </div>
        </div>
    );
};

const HiddenTab = () => {
    const childRef = useRef();
    const ref = useRef(null);
    const formChildrenRef = useRef();
    const [currentPage, setCurrentPage] = useState(1);
    const [page_size, setPageSize] = useState(10);
    const [onOffId, setOnOffId] = useState(null);
    const [modalType, setModalType] = useState('add'); //edit add assign
    const [total, setTotal] = useState(0);
    const [data, setData] = useState({ data: [], isLoading: true }); //数据集
    console.log(currentPage)
    const getList = (page = 1, pageSize) => {
        setCurrentPage(page);
        setData({ data: [], isLoading: true });
        getCrontab({page, page_size: pageSize ? pageSize : page_size})
            .then(result => {
                setTotal(result.count);
                setData({ data: result.data, isLoading: false });
            })
            .catch();
    };
    useEffect(() => {
        getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const columns = [
        {
            title: '序号',
            dataIndex: 'id',
            key: 'id',
            width: 100,
            render: (text, record, index) => `${index + 1}`,
        },
        {
            title: '规则名称',
            key: 'name',
            dataIndex: 'name',
            render: (text, index) => (
                <Tooltip key={'name' + index} placement="topLeft" title={text}>
                    <span
                        style={{
                            display: 'inline-block',
                            minWidth: '6rem',
                            maxWidth: '12rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                    >
                        {text}
                    </span>
                </Tooltip>
            ),
        },
        {
            title: '分类',
            key: 'category',
            dataIndex: 'category',
            render: (text, index) => (
                <Tooltip key={'category' + index} placement="topLeft" title={text}>
                    <span
                        style={{
                            display: 'inline-block',
                            minWidth: '4rem',
                            maxWidth: '12rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                    >
                        {text}{' '}
                    </span>
                </Tooltip>
            ),
        },
        {
            title: '开启时间',
            key: 'on_time',
            dataIndex: 'on_time',
            width: 140,
            render: (text, index) => (
                <Tooltip key={'on_time' + index} placement="topLeft" title={text}>
                    {text == null ? '无' : text && text.slice(0, 5)}
                </Tooltip>
            ),
        },
        {
            title: '关闭时间',
            key: 'off_time',
            dataIndex: 'off_time',
            width: 140,
            render: (text, index) => (
                <Tooltip key={'off_time' + index} placement="topLeft" title={text}>
                    {text == null ? '无' : text && text.slice(0, 5)}
                </Tooltip>
            ),
        },
        {
            title: '停止日期',
            key: 'disable_time',
            dataIndex: 'disable_time',
            width: 140,
            sorter: (a, b) => {
                let aTimeString = a.disable_time;
                let bTimeString = b.disable_time;
                let aTime = new Date(aTimeString).getTime();
                let bTime = new Date(bTimeString).getTime();
                return aTime - bTime;
            },
            render: (text, index) => (
                <Tooltip key={'disable_time' + index} placement="topLeft" title={text}>
                    {text == null ? '无' : text && text.slice(0, 10)}
                </Tooltip>
            ),
        },
        {
            title: '状态',
            width: 100,
            key: 'enabled',
            dataIndex: 'enabled',
            render: (_, record) => (
                <Switch
                    {...switchText}
                    onClick={value => {
                        editCrontab({
                            id: record.id,
                            enabled: value,
                        })
                            .then(() => {
                                ToastHelper.successToast(`${value ? '开启' : '关闭'}成功`);
                                getList();
                            })
                            .catch();
                    }}
                    checked={record.enabled}
                    size="small"
                />
            ),
        },
        {
            title: '操作',
            dataIndex: 'id',
            width: 160,
            render: (text, record) => (
                <div className="opera">
                    <MyIcon
                        type={'icona-zu6914'}
                        style={{ fontSize: '1rem', verticalAlign: 'middle', color: 'rgba(0, 0, 0, 0.85)', marginRight: '0.6rem' }}
                        title={'编辑'}
                        onClick={() => {
                            setModalType('edit');
                            setOnOffId(record.id);
                            // setUpdate(Math.random())
                            childRef.current.showModal(0);
                        }}
                    />
                    <Popconfirm
                        title="确定要删除吗?"
                        onConfirm={() => {
                            delCrontab(record.id)
                                .then(() => {
                                    message.success('删除成功');
                                    getList();
                                })
                                .catch();
                        }}
                    >
                        <MyIcon
                            type='iconshanchu1'
                            style={{ color: 'rgba(0, 0, 0, 0.85)',fontSize: '1rem', verticalAlign: 'middle' }}
                            title={'删除'}
                        />
                    </Popconfirm>
                </div>
            ),
        },
    ];
    /**
     * 表格分页配置
     */
    const paginationNo = useMemo(() => {
        return {
            pageSize: page_size, //每页的条数
            current: currentPage, //当前页
            total: total, //总数据
            showSizeChanger: true,
            onChange: (page,pageSize) => {
                ref.current = page;
                getList(page, pageSize);
            }, //点击分页号时触发的方法
            onShowSizeChange: (current, pageSize) => {
                setPageSize(pageSize);
            }, //pageSize 变化的回调
            hideOnSinglePage: true, //为true则数据条数不满或刚好一页，则隐藏分页器
            pageSizeOptions: ['10', '20', '30', '50', '100'], // 数量显示分页类别
            showTotal: total => `共 ${total} 条`,
        };
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage,total,page_size]);

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    /**
     * 表格多选框事件
     */
    const onSelectChange = selectedRowKeys => {
        setSelectedRowKeys(selectedRowKeys);
    };
    /**
     * 表格多选框
     */
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    /**
     * 全选
     * @param e
     */
    const onCheckAllChange = e => {
        setSelectedRowKeys(e.target.checked ? data.data.map((item, index) => item.id) : []);
    };
    return (
        <div className="tableBox">
            <Row gutter={16}>
                <Col span={2}>
                    <Button
                        onClick={() => {
                            setModalType('add');
                            childRef.current.showModal();
                        }}
                        type="primary"
                        style={{ marginRight: '1rem', marginBottom: '1rem' }}
                    >
                        新增
                    </Button>
                </Col>
            </Row>
            <ConfigProvider locale={zhCN}>
                <Table
                    rowKey={record => record.id}
                    columns={columns}
                    dataSource={data.data}
                    bordered={true}
                    scroll={{ x: '100%' }}
                    pagination={paginationNo}
                    rowSelection={rowSelection}
                    loading={data.isLoading}
                    locale={{
                        cancelSort: '点击取消排序',
                        triggerAsc: '点击升序',
                        triggerDesc: '点击降序',
                        filterConfirm: '确定',
                        filterReset: '重置',
                        emptyText: '暂无数据',
                        pageText: '页',
                    }}
                />
            </ConfigProvider>
            <div
                style={
                    data.data.length > 0
                        ? { display: 'block', position: 'inherit', marginTop: '1rem' }
                        : { display: 'none' }
                }
            >
                <Checkbox
                    indeterminate={
                        //负责样式控制
                        data.data.length !== selectedRowKeys.length && selectedRowKeys.length !== 0
                    }
                    onChange={onCheckAllChange} //勾选时触发的方法
                    checked={data.data.length === selectedRowKeys.length} //勾选还是未勾选  options：所有的勾选子项，list
                >
                    全选
                </Checkbox>

                <Radio.Group style={{ marginBottom: 16 }}>
                    <Radio.Button value="off">
                        <Popconfirm
                            title={
                                selectedRowKeys.length === 0
                                    ? '未选中'
                                    : `确定要将${selectedRowKeys.length}项,全部关闭吗?`
                            }
                            onConfirm={() => {
                                crontabMultiUpdate({ action: false, ids: selectedRowKeys.join(',') })
                                    .then(() => {
                                        message.success('批量修改成功');
                                        getList();
                                        setSelectedRowKeys([]);
                                    })
                                    .catch(err => {
                                        message.error('批量修改失败');
                                    });
                            }}
                        >
                            关闭
                        </Popconfirm>
                    </Radio.Button>
                    <Radio.Button value="on">
                        <Popconfirm
                            title={
                                selectedRowKeys.length === 0
                                    ? '未选中'
                                    : `确定要将${selectedRowKeys.length}项,全部开启吗?`
                            }
                            onConfirm={() => {
                                crontabMultiUpdate({ action: true, ids: selectedRowKeys.join(',') })
                                    .then(() => {
                                        message.success('批量修改成功');
                                        getList();
                                        setSelectedRowKeys([]);
                                    })
                                    .catch(err => {
                                        message.error('批量修改失败');
                                    });
                            }}
                        >
                            开启
                        </Popconfirm>
                    </Radio.Button>
                    <Radio.Button value="del">
                        <Popconfirm
                            title={
                                selectedRowKeys.length === 0
                                    ? '未选中'
                                    : `确定要将${selectedRowKeys.length}项,全部删除吗?`
                            }
                            onConfirm={() => {
                                crontabMultiDelete(selectedRowKeys.join(','))
                                    .then(() => {
                                        message.success('批量删除成功');
                                        getList();
                                        setSelectedRowKeys([]);
                                    })
                                    .catch(err => {
                                        message.error('批量删除失败');
                                    });
                            }}
                        >
                            删除
                        </Popconfirm>
                    </Radio.Button>
                </Radio.Group>
            </div>
            <GlobalModal
                onOk={() => {
                    formChildrenRef.current
                        .validateFields()
                        .then(value => {
                            let selectList = [];
                            const loopTree = treeNode => {
                                map(treeNode, item => {
                                    if (isArray(item.lighting)) {
                                        selectList = [...selectList, ...item.lighting];
                                    }
                                    if (isArray(item.onOff)) {
                                        selectList = [...selectList, ...item.onOff];
                                    }
                                    loopTree(item.children);
                                });
                            };
                            value.day_of_week = value.day_of_week.toString();
                            if (modalType === 'add') {
                                value.disable_time =
                                    value.disable_time === undefined || value.disable_time === null
                                        ? null
                                        : dateFormat(new Date(value.disable_time));
                                value.off_time =
                                    value.off_time === undefined || value.off_time === null ? null : timeFormat(new Date(value.off_time));
                                value.on_time =
                                    value.on_time === undefined || value.on_time === null ? null : timeFormat(new Date(value.on_time));
                                if (value.on_time === undefined && value.off_time === undefined) {
                                    message.error('开启时间与关闭时间必须填写一个!');
                                    return;
                                }
                                if (value.on_time === value.off_time) {
                                    message.error('开始时间和关闭时间不能相同');
                                    return;
                                }
                                const finalTree = formChildrenRef.current.finalTree();
                                loopTree(finalTree);
                                if (!size(selectList)) {
                                    message.error('请选择区域设备');
                                    return;
                                }
                                value.devs = selectList; //选中的设备
                                addCrontab(value)
                                    .then(result => {
                                        message.success('新增成功');
                                        getList();
                                        childRef.current.hiddenLoading();
                                        childRef.current.hiddenModal();
                                        formChildrenRef.current.resetFields();
                                    })
                                    .catch();
                            }
                            if (modalType === 'edit') {
                                value.disable_time =
                                    value.disable_time === undefined || value.disable_time === null
                                        ? null
                                        : dateFormat(new Date(value.disable_time));
                                value.off_time =
                                    value.off_time === undefined || value.off_time === null ? null : timeFormat(new Date(value.off_time));
                                value.on_time =
                                    value.on_time === undefined || value.on_time === null ? null : timeFormat(new Date(value.on_time));
                                value.id = onOffId;
                                if (value.on_time === undefined && value.off_time === undefined) {
                                    message.error('开启时间与关闭时间必须填写一个!');
                                    return;
                                }
                                if (value.on_time === value.off_time) {
                                    message.error('开始时间和关闭时间不能相同');
                                    return;
                                }
                                loopTree(formChildrenRef.current.finalTree());
                                if (!size(selectList)) {
                                    message.error('请选择区域设备');
                                    return;
                                }
                                value.devs = selectList; //选中的设备，例如：[12,34,128,324]
                                editCrontab(value)
                                    .then(result => {
                                        message.success('编辑成功');
                                        childRef.current.hiddenLoading();
                                        childRef.current.hiddenModal();
                                        formChildrenRef.current.resetFields();
                                        getList();
                                    })
                                    .catch();
                            }
                            setOnOffId(null);
                        })
                        .catch();
                }}
                onCancel={() => {
                    setOnOffId(null);
                }}
                childRef={childRef}
                title={`${modalType === 'edit' ? '编辑' : '新增'}定时开关`}
                refresh={onOffId}
                height={'94vh'}
            >
                <HiddenForm childRef={formChildrenRef} type={modalType} onOffId={onOffId} />
            </GlobalModal>
        </div>
    );
};
const HiddenForm = ({ childRef, type, onOffId }) => {
    const [form] = Form.useForm();
    const ref = useRef();
    const FormItemLayout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 16 },
    };
    useImperativeHandle(childRef, () => ({
        validateFields: form.validateFields,
        resetFields: form.resetFields,
        setFieldsValue: form.setFieldsValue,
        finalTree: ref.current.finalTree,
    }));
    const [equipmentList, setEquipmentList] = useState([]);
    const [treeData, setTreeData] = useState([]);
    const [treeNode, setTreeNode] = useState({});
    const [allTreeNode, setAllTreeNode] = useState([]);
    const [device, setDevice] = useState({});
    const [selectDevice, setSelectDevice] = useState({ lighting: [], onOff: [] });
    const [updateNode, setUpdateNode] = useState({});
    const [function_id, setFunction_id] = useState(undefined);
    const setTreeEquipment = () => {
        const loopTree = (treeNodes, id, equipment) => {
            return map(treeNodes, item => {
                if (id === toString(item.id)) {
                    let selectNum = 0;
                    if (isArray(equipment[1])) {
                        selectNum += equipment[1].length;
                    }
                    if (isArray(equipment[3])) {
                        selectNum += equipment[3].length;
                    }
                    return {
                        ...item,
                        lighting: equipment[1],
                        onOff: equipment[3],
                        selectNum,
                        children: loopTree(item.children, id, equipment),
                    };
                } else {
                    return { ...item, children: loopTree(item.children, id, equipment) };
                }
            });
        };
        let tree = allTreeNode;
        map(equipmentList, (value, key) => {
            tree = loopTree(tree, key, value);
        });
        ref.current.setDataList(tree);
    };
    useEffect(() => {
        !isEmpty(allTreeNode) && !isEmpty(equipmentList) && setTreeEquipment();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allTreeNode, equipmentList]);

    const getDetail = id => {
        getCrontabDetail(id)
            .then(result => {
                setEquipmentList(result.data.dev_routers);
                setTreeEquipment();
                const newData = result.data.day_of_week.split(',').map(item => {
                    return item === '*' ? '*' : parseInt(item);
                });
                form.setFieldsValue({
                    //初始化默认值时处理数据
                    name: result.data.name,
                    category: result.data.category,
                    location: result.data.location,
                    enabled: result.data.enabled,
                    day_of_week: newData,
                    on_time: result.data.on_time === null ? result.data.on_time : moment(result.data.on_time, 'HH:mm'),
                    off_time:
                        result.data.off_time === null ? result.data.off_time : moment(result.data.off_time, 'HH:mm'),
                    disable_time:
                        result.data.disable_time === null
                            ? result.data.disable_time
                            : moment(result.data.disable_time, 'yyyy-MM-DD'),
                    id: result.data.id,
                });
            })
            .catch();
    };
    /**
     * ID of all selected devices
     * 选中的所有设备的id
     * @param {*} devices
     */
    const selectEquipment = devices => {
        const allDevice = [...devices.lighting, ...devices.onOff];
        setUpdateNode({ ...treeNode, lighting: devices.lighting, onOff: devices.onOff, selectNum: allDevice.length }); //给选中设备的对应区域计数
    };
    useEffect(() => {
        getFunctionList({ get_all: true })
            .then(result => {
                let ids = [],
                    names = [];
                result.data.forEach(item => {
                    if (item.name === '照明' || item.name === '插座') {
                        ids.push(item.id);
                        names.push(item.name);
                        setFunction_id(ids.join(','));
                    }
                });
            })
            .catch();
    }, []);
    useEffect(() => {
        if (function_id !== undefined && (function_id.length >= 2 || function_id.length > 0)) {
            getAreaList({ fids: function_id }) //获取区域列表
                .then(result => {
                    setTreeData(result.data);
                    type === 'edit' && getDetail(onOffId);
                })
                .catch();
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [function_id, type, onOffId]);

    useEffect(() => {
        selectEquipment({ lighting: [], onOff: [] });
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [treeNode]);
    /**
     * 右侧设备：获取指定区域的照明和插座设备
     * gets the lighting and socket devices for the specified area
     */
    const newEquipmentList = () => {
        if (isEmpty(treeNode) || !function_id) {
            return;
        }
        let ids = function_id.split(',');
        getEquipmentList({ region_id: treeNode.id, function_id: ids, get_all: true })
            .then(result => {
                let lighting = [],
                    socket = [];
                result.data.forEach(item => {
                    if (!isNull(item.function)) {
                        if (item.function.name === '照明') {
                            item.value = item.id;
                            item.label = item.name;
                            lighting.push(item);
                        } else if (item.function.name === '插座') {
                            item.value = item.id;
                            item.label = item.name;
                            socket.push(item);
                        }
                    }
                });
                setDevice({ lighting: lighting, socket: socket });
                setSelectDevice({ lighting: get(treeNode, 'lighting', []), onOff: get(treeNode, 'onOff', []) });
                setUpdateNode({ ...treeNode, totalEquipment: result.data.length });
            })
            .catch();
    };
    /**
     * treeId改变，重新请求对应的设备
     */
    useEffect(() => {
        newEquipmentList();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [treeNode, function_id, type]);

    /**
     * 星期选中后排序
     * @param {*} value
     */
    const handleChange = value => {
        var newArr = value.sort(function (a, b) {
            return a - b;
        });
        return newArr;
    };

    /**
     * 星期选择互斥处理
     * @param {*} args
     * @returns
     */
    // const getValueFromEvent = args => {
    //     if (args.some(val => val === '*')) {
    //         return ['*'];
    //     } else if (args.some(val => val === '1,2,3,4,5')) {
    //         return ['1,2,3,4,5'];
    //     } else if (args.some(val => val === '0,6')) {
    //         return ['0,6'];
    //     }
    //     return args;
    // };

    const getValueFromEvent1 = args => {
        const defaultVals = ['*', '1,2,3,4,5', '0,6'];
        if (args && args.length) {
            const lastVal = args[args.length - 1];
            if (defaultVals.includes(lastVal)) {
                return [lastVal];
            } else {
                return args.filter(val => !defaultVals.includes(val));
            }
        }
        return args;
    };

    /**
     * 星期列表，渲染到下拉框中
     */
    const plainOptions = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];
    const children = [];
    for (let i = 0; i < plainOptions.length; i++) {
        children.push(
            <Option key={i} value={i}>
                {plainOptions[i]}
            </Option>,
        );
    }
    return (
        <Form layout="horizontal" form={form}>
            <Row>
                <Col span={24}>
                    <FormItem
                        label="规则名称"
                        name="name"
                        {...FormItemLayout}
                        rules={[
                            { required: true, message: '请输入规则名称' },
                            {
                                pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_]{1,20}$/,
                                message: '名称只能包含1-20位的汉字、字母、数字、下划线!',
                            },
                            {
                                whitespace: true,
                                message: '不能输入空格',
                            },
                        ]}
                        style={{ margin: '0.5rem 0' }}
                    >
                        <Input placeholder="请输入规则名称" style={{width: '335px'}}/>
                    </FormItem>
                </Col>
                <Col span={24}>
                    <FormItem
                        {...FormItemLayout}
                        name="on_time"
                        label="开启时间"
                        rules={[{ required: false, message: '请选择开启时间' }]}
                        style={{ margin: '0.5rem 0' }}
                    >
                        <TimePicker format={format} locale={locale} placeholder="请选择开启时间"  style={{width: '335px'}}/>
                    </FormItem>

                    <FormItem
                        {...FormItemLayout}
                        name="off_time"
                        label="关闭时间"
                        rules={[
                            { required: false, message: '请选择关闭时间' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (getFieldValue('on_time') !== value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('开始时间和关闭时间不能相同!'));
                                },
                            }),
                        ]}
                        style={{ margin: '0.5rem 0' }}
                    >
                        <TimePicker format={format} locale={locale} placeholder="请选择关闭时间" style={{width: '335px'}}/>
                    </FormItem>
                </Col>
                <Col span={24}>
                    <FormItem
                        {...FormItemLayout}
                        label="重复"
                        name="day_of_week"
                        rules={[{ required: true, message: '请选择重复周期' }]}
                        getValueFromEvent={e => getValueFromEvent1(e)}
                        style={{ margin: '0.5rem 0' }}
                    >
                        <Select
                            mode="multiple"
                            showArrow
                            allowClear
                            virtual={false}
                            style={{ width: '100%' }}
                            onChange={handleChange}
                            tokenSeparators={[',']}
                            placeholder={'请选择重复周期'}
                            dropdownRender={menu => <div key={menu}>{menu}</div>}
                            style={{width: '334px'}}
                        >
                            <Option value={'*'} key={'*'}>
                                每天
                            </Option>
                            <Option value={'1,2,3,4,5'} key={'1,2,3,4,5'}>
                                工作日
                            </Option>
                            <Option value={'0,6'} key={'0,6'}>
                                周末
                            </Option>
                            {children}
                        </Select>
                    </FormItem>
                </Col>
                <Col span={24}>
                    <FormItem {...FormItemLayout} label="停止日期" style={{ margin: '0.5rem 0' }}>
                        <Space>
                            <Form.Item name="disable_time" noStyle>
                                <DatePicker
                                    renderExtraFooter={() => '停止日期只能选择未来的日期'}
                                    disabledDate={disabledDate_endDay}
                                    placeholder="请选择停止日期"
                                    locale={locale}
                                    style={{width: '335px'}}
                                />
                            </Form.Item>
                        </Space>
                    </FormItem>
                </Col>
                <Col span={24}>
                    <FormItem {...FormItemLayout} name="location" label="区域" style={{ margin: '0.5rem 0' }}>
                        <Row style={{ border: '1px solid #d9d9d9', padding: '1rem' }}>
                            <Col span={6} style={{ height: '310px', overflowY: 'scroll' }}>
                                <CommonTreeSwitch
                                    getAllTree={setAllTreeNode}
                                    childRef={ref}
                                    treeData={treeData}
                                    updateNode={updateNode}
                                    onClick={node => {
                                        !isEmpty(treeData) && setTreeNode(node);
                                    }}
                                />
                            </Col>
                            <Col
                                offset={1}
                                span={17}
                                style={{
                                    border: '1px solid #d9d9d9',
                                    padding: '1rem',
                                    height: '310px',
                                    overflowY: 'scroll',
                                }}
                            >
                                <AssignForm
                                    newItem={device}
                                    selectDevice={selectDevice}
                                    selectEquipment={selectEquipment}
                                    treeNode={treeNode}
                                />
                            </Col>
                        </Row>
                    </FormItem>
                </Col>
            </Row>
        </Form>
    );
};

/**
 * 右侧设备
 * @param {*} newItem  设备数据集
 * @param {*} selectEquipment 选中的设备按类型放在一起
 * @param {*} selectDevice 选中的设备
 * @param {*} treeNode
 * @returns
 */
const AssignForm = ({ newItem, selectEquipment, selectDevice, treeNode }) => {
    const [checkedList1, setCheckedList1] = React.useState([]); //选中的照明设备
    const [checkedList2, setCheckedList2] = React.useState([]); //选中的插座设备
    const [indeterminate1, setIndeterminate1] = React.useState(false);
    const [indeterminate2, setIndeterminate2] = React.useState(false);
    const [ifall1, setAll1] = React.useState(false);
    const [ifall2, setAll2] = React.useState(false);
    useEffect(() => {
        if (!isEmpty(selectDevice)) {
            setCheckedList1(selectDevice.lighting);
            setCheckedList2(selectDevice.onOff);
        }
    }, [selectDevice]);
    /**
     * 照明和插座数据列表
     */
    const devices = [
        {
            name: '照明',
            icon: 'icon_lighting',
            data: (newItem && newItem.lighting) || [], //照明的设备数据
            arr: checkedList1,
            ifall: ifall1,
            indeterminate: indeterminate1,
        },
        {
            name: '插座',
            icon: 'icon_socket',
            data: (newItem && newItem.socket) || [], //插座的设备数据
            arr: checkedList2,
            ifall: ifall2,
            indeterminate: indeterminate2,
        },
    ];

    // useEffect(() => {
    //     if(!isEmpty(treeNode) && treeNode.lighting || treeNode.onOff){
    //         if(treeNode.lighting.length === 0 || treeNode.lighting.length < treeNode.device_num || isUndefined(treeNode.lighting)){
    //             setIndeterminate1(false)
    //             setAll1(false)
    //         }
    //         if(treeNode.onOff.length === 0 || treeNode.onOff.length < treeNode.device_num || isUndefined(treeNode.onOff)){
    //             setIndeterminate2(false)
    //             setAll2(false)
    //         }
    //         if((treeNode.lighting.length + treeNode.onOff.length) === treeNode.device_num){
    //             setIndeterminate1(false)
    //             setIndeterminate2(false)
    //             setAll1(true)
    //             setAll2(true)
    //         }
    //     }

    // setIndeterminate1(false)
    // setIndeterminate2(false)
    // setAll1(false)
    // setAll2(false)
    // }, [treeNode]);
    /**
     *
     * @param {*} e
     * 设备全选
     */
    const onCheckAllChange = (e, index) => {
        if (!index) {
            let noarr = [];
            setAll1(e.target.checked);
            devices[index]['data'].forEach(element => {
                noarr.push(element.value);
            });
            setCheckedList1(e.target.checked ? noarr : []);
            setIndeterminate1(false);
        } else if (index === 1) {
            let noarrs = [];
            setAll2(e.target.checked);
            devices[index]['data'].forEach(element => {
                noarrs.push(element.value);
            });
            setCheckedList2(e.target.checked ? noarrs : []);
            setIndeterminate2(false);
        }
    };
    //选中的照明设备和插座设备id合并成一个数
    useEffect(() => {
        selectEquipment({ lighting: checkedList1, onOff: checkedList2 });
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkedList1, checkedList2]);

    return (
        devices &&
        devices.map((item, index) => {
            return (
                <div
                    style={
                        (item.data && item.data.length === 0 ? { display: 'none' } : { display: 'block' },
                        { marginBottom: '1rem' })
                    }
                    key={index}
                >
                    <Row>
                        <Col span={6}>
                            <div>
                                <MyIcon
                                    type={item.icon}
                                    style={{ color: '#000', fontSize: '1.5rem', verticalAlign: 'middle' }}
                                />
                                <span style={{ verticalAlign: 'middle' }}>{item.name}</span>
                                <span style={{ verticalAlign: 'middle' }}>({item.data && item.data.length})</span>
                            </div>
                        </Col>
                        <Col offset={14} span={4}>
                            <Checkbox
                                indeterminate={item.indeterminate}
                                onChange={e => onCheckAllChange(e, index)}
                                checked={item.ifall}
                            >
                                全选
                            </Checkbox>
                        </Col>
                    </Row>
                    <Divider />
                    <CheckboxGroup
                        options={item.data} //指定可选项(设备列表)
                        value={item.arr} //指定选中的选项
                        onChange={value => {
                            //选中设备事件
                            if (!index) {
                                //照明
                                setAll1(devices[index].data.length === value.length);
                                setCheckedList1(value);
                                setIndeterminate1(!!value.length && value.length < devices[index].data.length);
                            } else {
                                //插座
                                setAll2(devices[index].data.length === value.length);
                                setCheckedList2(value);
                                setIndeterminate2(!!value.length && value.length < devices[index].data.length);
                            }
                        }}
                        key={item.data}
                    />
                </div>
            );
        })
    );
};
