import React from 'react';
import ReactEcharts from 'echarts-for-react';

export const ReportPieRegionEchart = ({ rawData, color, style }) => {
    const getOption = () => {
        let option = {
            color: color,
            legend: {
              type: 'scroll',
              orient: 'horizontal', //vertical
              data: rawData.map(item => {
                  return item.name;
              }),
              left: 'center',
              bottom: 'bottom',
              itemWidth: 12,
              itemHeight: 8,
              textStyle: {
                  color: '#595959',
                  fontSize: 12,
              },
                itemGap: 8,
              icon: 'circle',
          },
            tooltip: {
              trigger: 'item',
              formatter: "{b} : {c}元 ({d}%)",
              textStyle: {
                  fontSize: 12,
                  color: '#595959',
              },
          },
            series: [{
              type: 'pie',
              clockwise: false,
              startAngle: 90,
              radius: '60%',
              center: ['44%', '50%'],
              roseType: 'radius', //area
              data: rawData,
              itemStyle: {
                  normal: {
                      borderColor: '#fff',
                      borderWidth: '3',
                  },
              },
              label: {
                  show: true,
                  position: 'outside',
                  // formatter: '{a|{b}：{d}%}\n{hr|}',
                  formatter: '{d}%',
                  rich: {
                      hr: {
                          backgroundColor: 't',
                          borderRadius: 100,
                          width: 0,
                          height: 10,
                          padding: [3, 3, 0, -16],
                          shadowColor: '#fff',
                          shadowBlur: 1,
                          shadowOffsetX: '0',
                          shadowOffsetY: '2',
                      },
                      a: {
                          padding: [-35, 15, -20, 5],
                      }
                  }
              },
              labelLine: {
                  normal: {
                      length: 5,
                      length2: 25,
                      lineStyle: {
                          width: 1,
                      }
                  }
              },
              // label: {
              //     normal: {
              //         show: true, 
              //         position: 'inside', 
              //         formatter: '{d}%', 
              //         formatter: function(data){
              //             return data.percent.toFixed(0)+"%"; 
              //         },
              //         textStyle : { 
              //             align : 'center',
              //             baseline : 'middle',
              //             fontFamily : '微软雅黑',
              //             fontSize : 15,
              //             fontWeight : 'bolder'
              //         }
              //     },
              // },
          }],
        };
        return option;
    };
    const option = getOption();
    return (
        <div style={{ backgroundColor: '#fff' }}>
            <ReactEcharts option={option} className="react_for_echarts" style={style} />
        </div>
    );
};
