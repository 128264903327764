import React, { useMemo } from 'react';
import { isEmpty, map } from 'lodash';
import { Chart, Dataset, Legend, Series, Tooltip, Title } from '@echarts-start/react-bridge';
import { peakvalue } from '../../../../util/function-util';
// const source = [
//     ['电费分布', '照明', '插座', '通风'],
//     ['照明', 1048],
//     ['插座', 735],
//     ['通风', 580],
// ];
export const PieChart = ({ data, color, total, style }) => {
    const source = useMemo(() => {
        if (isEmpty(data)) {
            return [];
        }
        const source = [];
        const legend = ['用能分布'];
        map(data, item => {
            legend.push(item.name);
            const legendData = [item.name, item.amount || item.value];
            source.push(legendData);
        });
        source.unshift(legend);
        return source;
    }, [data]); 
    return (
        <Chart color={color} noCoordinate={true} style={{ width: '100%', height: (style && style.height) || '95%' }} resize>
        <Title
            options={{
                text: total && `总\n\r{active|${(total ? peakvalue(total) : 0)}}`,
                top: 'center',
                left: 'center',
                textStyle: {
                    rich: {
                        total: {
                            fontSize: 14,
                            fontWeight: 'normal',
                            color: '#595959',
                            padding: [10, 0],
                        },
                        active: {
                            fontSize: 18,
                            lineHeight: 30,
                            fontWeight: 'bold',
                            color: '#333333',
                        },
                    },
                },
            }}
        />
            <Tooltip trigger="item" />
            <Dataset source={source} />
            <Legend
                type="scroll"
                orient="horizontal" //vertical
                bottom="-1%"
                left="center"
                icon="circle"
                itemHeight="10"
                options={{
                    textStyle: {
                        fontSize: '10',
                    },
                }}
            />
            <Series
                options={{
                    type: 'pie',
                    radius: ['40%', '55%'],
                    label: {
                        formatter: '{d}%',
                    },
                }}
            />
        </Chart>
    );
};
