import React, { useEffect, useMemo, useState } from 'react';
import { BreadcrumbCustom } from '../../../components/BreadcrumbCustom';
import { Col, Row } from 'antd';
import moment from 'moment';
import { isEmpty, map, isNumber, isUndefined, isNull } from 'lodash';
import { CommonTree } from '../../../components/CommonTreeMultiples';
import { HistogramChart } from './Chart/HistogramChart';
import { TimeSearch } from './ECRCondition';
import { Loading } from '../../../components/Loading';
import { EmptyView } from '../../../components/EmptyView';
import { COLOR_PLATE_16 } from '../../../global';
import { toDay } from '../../../util/timeUtil';
import { timestampToTime, peakvalue, getdifflist, getHourArr } from '../../../util/function-util';
import { getAreaList, getContrasts } from '../../../api/webServe';
import lessStyle from './eCRCondition.module.less';

/**
 * 能耗分析 - 对比分析
 * @returns
 */
export const ComparativeAnalysis = () => {
    return (
        <div className={'container clearfix ' + lessStyle.containers}>
            <BreadcrumbCustom paths={['首页', '能耗分析', '对比分析']} className="rumbCustom" />
            <div className="row clearfix" style={{ backgroundColor: 'transparent', margin: '1rem', padding: '0' }}>
                <Statistics />
            </div>
        </div>
    );
};

const Statistics = () => {
    const [treeData, setTreeData] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState();
    useEffect(() => {
        getAreaList()
            .then(result => {
                setTreeData(result.data);
            })
            .catch();
    }, []);
    return (
        <Row style={{ marginLeft: '0rem', width: '100%', height: '60vh' }} gutter={32}>
            <Col
                span={4}
                className="gutter-row"
                style={{ padding: '1rem', background: '#ffffff', borderRadius: '10px', height: '84vh' }}
            >
                <CommonTree
                    treeData={treeData}
                    operable={false}
                    onClick={(info, selectedKey) => {
                        // if (!areaInfo || areaInfo.id !== info.id) {
                        //     setAreaInfo(info);
                        // }
                        if (selectedKey) {
                            if (selectedKey.length >= 0 && selectedKey.length <= 5) {
                                setSelectedKeys(selectedKey);
                            }
                        } else if (info) {
                            setSelectedKeys([info.id]);
                        }
                    }}
                    multiples={true}
                />
                {!isEmpty(treeData) ? (
                    <>
                    <p style={{ padding: '0.1rem 0.5rem', color: '#aaa', fontSize: '12px' }}>
                        1. 点击选中区域，再次点击取消选中
                    </p>
                    <p style={{ padding: '0.1rem 0.5rem', color: '#aaa', fontSize: '12px' }}>
                        2. 最大支持5个区域
                    </p>
                    </>
                ) : (
                    ''
                )}
            </Col>
            <Col
                span={20}
                style={{
                    borderRadius: '10px',
                    boxSizing: 'border-box',
                    paddingRight: '0',
                }}
            >
                <ChartView selectedKeys={selectedKeys} />
            </Col>
        </Row>
    );
};

const ChartView = ({ categoryInfo, isCategory, selectedKeys }) => {
    let region_list = useMemo(() => {
        if (isEmpty(selectedKeys) && isEmpty(categoryInfo)) {
            return null;
        }
        if (!isEmpty(selectedKeys)) {
            return selectedKeys.join('-');
        }
        return categoryInfo.id;
    }, [categoryInfo, selectedKeys]);
    const [barData, setBarData] = useState({ isLoading: false, data: null });
    const [datatime, setDatatime] = useState([moment(toDay[0]).startOf('day').valueOf(),moment(toDay[1]).startOf('day').add(1,'days').valueOf()]);
    // const [datatime, setDatatime] = useState(toDay);
    const getBarData = () => {
        setBarData({ data: null, isLoading: true });
        getContrasts({
            region_list,
            start_time: moment(datatime[0]).startOf('day').valueOf(),
            end_time: moment(datatime[1]).startOf('day').valueOf(),
            isCategory,
        })
            .then(result => {
                const legend = ['product'];
                const data = [];
                let rule = result.rule
                result = result.data;
                //如果结束时间大于等于当前时间 end=当天晚上23:59:59 否则 end=结束时间的endOf('day')并且-1天
                let end = (moment(datatime[1]).endOf('day').add(-1,'days').valueOf()) >= (moment(new Date()).endOf('day').valueOf()) ? moment(new Date()).valueOf() : moment(datatime[1]).endOf('day').add(-1,'days').valueOf() 
                map(result, (item, idx) => {
                    legend.push(item.name);
                    let newDate = rule === 'h' ? getHourArr(timestampToTime(datatime[0]),timestampToTime(end)) : getdifflist(
                                  timestampToTime(datatime[0]).slice(0, 10),
                                  timestampToTime(end).slice(0, 10), rule === 'm' ? 'months' : 'days',
                              );
                    item.data = item.data.length === 0 ? newDate : item.data;
                    map(item.data, (res, index) => {
                        if (!idx) {
                            const everyOne = [];
                            everyOne.push(res.time);
                            everyOne.push(peakvalue(res.amount));
                            data.push(everyOne);
                        } else {
                            data[index].push(peakvalue(res.amount));
                        }
                    });
                });
                data.unshift(legend);
                setBarData({ isLoading: false, data: isEmpty(result) ? null : data });
            })
            .catch(() => {
                setBarData({ data: null, isLoading: false });
            });
    };
    useEffect(() => {
        if (!region_list && !isNumber(region_list)) {
            return;
        }
        getBarData(); //用能趋势
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [region_list, datatime]);
    return (
        <Row style={{ height: '84vh' }}>
            <Col span={24}>
                <div style={{ ...Style.module }}>
                    <Row
                        style={{
                            height: 50,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%',
                            lineHeight: '50px',
                            borderTopLeftRadius: '10px',
                            borderTopRightRadius: '10px',
                            boxShadow: '0px 2px 3px #e7ebeb',
                            background: 'linear-gradient(180deg, #fefefe 0%, #f5f6fa 100%)',
                            paddingLeft: '1rem',
                        }}
                    >
                        <Col
                            span={16}
                            offset={8}
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginTop: '-0.1rem',
                            }}
                        >
                            <TimeSearch
                                timeValue={toDay}
                                onValueChange={times => {
                                    const { time } = times;
                                    // getBarData(time);
                                    let newTime = [moment(time[0]).startOf('day').valueOf(),moment(time[1]).startOf('day').add(1,'days').valueOf()]
                                    setDatatime(newTime);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row
                        style={{
                            height: '77vh',
                            width: '100%',
                        }}
                    >
                        <Col span={24} style={{ height: '100%' }}>
                            <Loading show={barData.isLoading}>
                                {isEmpty(barData.data) ? (
                                    <EmptyView description={<div><p>暂无数据</p><p>请点击左侧区域进行数据对比</p></div>} />
                                ) : selectedKeys.length === 0 ? (
                                    <EmptyView description="请点击左侧区域进行数据对比" />
                                ) : (
                                    <HistogramChart
                                        rawData={
                                            (!isUndefined(selectedKeys) || !isNull(selectedKeys)) &&
                                            selectedKeys.length === 0
                                                ? null
                                                : barData.data
                                        }
                                        color={COLOR_PLATE_16}
                                        showDataZoom={true}
                                    />
                                )}
                            </Loading>
                        </Col>
                        {/* {!isEmpty(barData.data) ? (
                            <Col span={24} style={{ padding: '1rem', color: '#aaa', fontSize: '12px' }}>
                                注：数据未显示完时,图表可上下拖动查看全部
                            </Col>
                        ) : (
                            ''
                        )} */}
                    </Row>
                </div>
            </Col>
        </Row>
    );
};

/**
 * 样式
 */
const Style = {
    title: {
        display: 'flex',
        width: '100%',
        height: '50px',
        lineHeight: '50px',
        fontSize: '1.2rem',
        color: '#595959',
        fontWeight: 500,
        padding: '0 1rem',
        verticalAlign: 'middle',
    },
    module: {
        background: '#ffffff',
        borderRadius: '10px',
        boxShadow: `0px 0px 3px 0px rgba(0,0,0,0.08)`,
    },
};
