import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import './style.less';
import { MyIcon } from '../../../util/iconfont';
import { Loading } from '../../../components/Loading';
import { EmptyView } from '../../../components/EmptyView'; //空数据占位组件
import { isEmpty } from 'lodash';
import { poweer_consumption, load_consumption } from '../../../global';
import { removeEmpty, reviseUnit1 } from '../../../util/function-util';

// 工作台-- 今日运行概况
const PowerSurvey = props => {
    const [data, setData] = useState(props.rawData);
    useEffect(() => {
        setData(props.rawData);
    }, [props]);
    return (
        <div className="powerSurvey">
            <span className="common_title">
                <MyIcon
                    type="icon_working"
                    style={{
                        fontSize: '1.5rem',
                        marginTop: '-0.1rem',
                        verticalAlign: 'middle',
                        paddingRight: '0.6rem',
                    }}
                />
                今日运行概况
            </span>
            <div className="powerSurvey_item">
                <Loading show={data.isLoading} height={'80%'}>
                    {isEmpty(data.data) ? (
                        <EmptyView />
                    ) : (
                        <Row style={{ width: '100%' }}>
                            <Col span={8} style={{ marginBottom: '0.4rem' }}>
                                <span className="name">在线设备量</span>
                                <span className="number">{data.data.online_device || 0}</span>
                                <span className="company">个</span>
                            </Col>
                            <Col span={8} style={{ marginBottom: '0.4rem' }}>
                                <span className="name">报警次数</span>
                                <span className="number">{data.data.alarm_num || 0}</span>
                                <span className="company">个</span>
                            </Col>
                            <Col span={8} style={{ marginBottom: '0.4rem' }}>
                                <span className="name">巡检任务</span>
                                <span className="number">{data.data.inspection_num || 0}</span>
                                <span className="company">个</span>
                            </Col>
                            <Col span={8}>
                                <span className="name">报警量</span>
                                <span className="number" style={{ color: 'red' }}>
                                    {data.data.device_alarm || 0}
                                </span>
                            </Col>
                            <Col span={8}>
                                <span className="name">高危量</span>
                                <span className="number" style={{ color: 'red' }}>
                                    {data.data.alarm_level || 0}
                                </span>
                            </Col>
                            <Col span={8}>
                                <span className="name">已完成</span>
                                <span className="number">{data.data.complete_inspection_num || 0}</span>
                            </Col>
                        </Row>
                    )}
                </Loading>
            </div>
        </div>
    );
};
//工作台--用电概况
const PowerList = props => {
    const [data, setData] = useState(props.rawData);
    useEffect(() => {
        setData(props.rawData);
    }, [props]);
    return (
        <div className="powerSurvey">
            <span className="common_title">
                <MyIcon
                    type="icon_title_power_survey"
                    style={{
                        fontSize: '1.5rem',
                        marginTop: '-0.1rem',
                        verticalAlign: 'middle',
                        paddingRight: '0.6rem',
                    }}
                />
                用电概况
            </span>
            <Loading show={data.isLoading} height={'80%'}>
                {isEmpty(data.data) ? (
                    <EmptyView />
                ) : (
                    <div className="powerSurvey_item" style={{ textAlign: 'left' }}>
                        <Row style={{ padding: '4px 0' }} gutter={16}>
                            <Col span={11} offset={2} style={{ marginBottom: '0.22rem' }}>
                                <span style={{ color: '#595959' }}>今日用电</span>
                                <span style={{ marginLeft: '0.5rem' }} className="number">
                                    {/* {parseInt(data.data.now_amount * 100) / 100 || 0} */}
                                    {reviseUnit1(removeEmpty(data.data.now_amount),poweer_consumption,data.data)}
                                </span>
                                {/* <span className="company">kWh</span> */}
                            </Col>
                            <Col span={11}>
                                <span style={{ color: '#595959' }}>今日预测</span>
                                <span style={{ marginLeft: '0.5rem' }} className="number">
                                    {/* {parseInt(data.data.now_prediction * 100) / 100 || 0} */}
                                    {reviseUnit1(removeEmpty(data.data.now_prediction),poweer_consumption,data.data)}
                                </span>
                                {/* <span className="company">kWh</span> */}
                            </Col>
                        </Row>
                        <Row gutter={16} style={{ padding: '5px 0px 10px 0px' }}>
                            <Col
                                span={11}
                                offset={2}
                                // flex="140px"
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
                            >
                                <span>
                                    <span style={{ color: '#595959' }}>环比&nbsp;&nbsp;&nbsp;</span>
                                    <span style={{ marginLeft: '2.2rem' }} className="number">
                                        {parseInt(data.data.dom * 100) / 100 || 0}
                                    </span>
                                    <span className="company">%</span>
                                </span>
                                {/*环比增长率=（本期数-上期数）/上期数×100%*/}
                                {data.data.dom > 0 ? (
                                    <MyIcon
                                        type="icon_rise"
                                        style={{ color: '#E5756E', fontSize: '1.2rem', marginLeft: '1.2rem' }}
                                    />
                                ) : data.data.dom < 0 ? (
                                    <MyIcon
                                        type="icon_decline"
                                        style={{ color: '#64D399', fontSize: '1.2rem', marginLeft: '1.2rem' }}
                                    />
                                ) : (
                                    <MyIcon
                                        type="icon_flat"
                                        style={{ color: '#000', fontSize: '1.2rem', marginLeft: '1.2rem' }}
                                    />
                                )}
                            </Col>
                            <Col
                                span={11}
                                // flex="140px"
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
                            >
                                <span>
                                    <span style={{ color: '#595959' }}>同比</span>
                                    <span style={{ marginLeft: '2.2rem' }} className="number">
                                        {parseInt(data.data.yoy * 100) / 100 || 0}
                                    </span>
                                    <span className="company">%</span>
                                </span>
                                {/*同比增长率=（本期发展水平-去年同期水平）/去年同期水平×100%*/}
                                {data.data.yoy > 0 ? (
                                    <MyIcon type="icon_rise" style={{ color: '#E5756E', fontSize: '1.2rem' }} />
                                ) : (
                                    (data.data.yoy = 0 ? (
                                        <MyIcon type="icon_decline" style={{ color: '#64D399', fontSize: '1.2rem' }} />
                                    ) : (
                                        <MyIcon type="icon_flat" style={{ color: '#000', fontSize: '1.2rem' }} />
                                    ))
                                )}
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={11} offset={2} style={{ marginBottom: '0.4rem' }}>
                                <span style={{ color: '#595959' }}>最大负荷时间</span>
                                <span style={{ marginLeft: '0.5rem' }} className="number">
                                    {data.data.max_power_time || '--'}
                                </span>
                            </Col>
                            <Col span={11}>
                                <span style={{ color: '#595959' }}>最大负荷</span>
                                <span style={{ marginLeft: '0.5rem' }} className="number">
                                    {/* {parseInt(data.data.max_power * 100) / 100 || 0} */}
                                    {reviseUnit1(removeEmpty(data.data.max_power),load_consumption,data.data)}
                                </span>
                                {/* <span className="company">kW</span> */}
                            </Col>
                        </Row>
                    </div>
                )}
            </Loading>
        </div>
    );
};

const power = {
    PowerSurvey,
    PowerList,
};

export default power;
