/**
 * 硬件管理分类列表
 */
import React, { useRef, useState, useEffect, useImperativeHandle, useMemo } from 'react';
import { Table, Popconfirm, Button, Form, Input, ConfigProvider, message, Row, Col } from 'antd';
import { MyIcon } from '../../../util/iconfont';
import zhCN from 'antd/es/locale/zh_CN';
import { BreadcrumbCustom } from '../../../components/BreadcrumbCustom';
import { CommonModal } from '../../../components/CommoModal';
import { getCategory, getCategoryDetails, addCategory, changeCategory, delCategory } from '../../../api/adminServe';
import { omitBy, isUndefined, isNull, trim } from 'lodash';
import './adminTablePagination.less';

const FormItem = Form.Item;
const HardwareClassify = () => {
    return (
        <div className="container clearfix">
            <BreadcrumbCustom paths={['硬件管理', '分类管理']} className="rumbCustom" />
            <div className="row clearfix" style={{ width: '100%' }}>
                <ClassifyTable />
            </div>
        </div>
    );
};

const ClassifyTable = () => {
    const [form] = Form.useForm();
    const childRef = useRef();
    const formChildrenRef = useRef();
    const [modalType, setModalType] = useState('add'); //edit add assign
    const [data, setData] = useState({ data: [], isLoading: true });
    const [count, setCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [page_size, setPageSize] = useState(12);
    const ref = useRef(null);
    const [update, setUpdate] = useState(0);
    const [searchParams, setSearchParams] = useState({});
    const columns = useMemo(() => {
        return [
            {
                title: '序号',
                dataIndex: 'id',
                key: 'id',
                width: 100, //宽度
                render: (text, record, index) => `${index + 1}`,
            },
            {
                title: '产品名称',
                dataIndex: 'product_name',
                render: text => (
                    <p
                        style={{
                            minWidth: '4rem',
                            maxWidth: '8rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={text}
                    >
                        {text}
                    </p>
                ),
            },
            {
                title: '产品型号(分类名称)',
                dataIndex: 'name',
                render: text => (
                    <p
                        style={{
                            minWidth: '8rem',
                            maxWidth: '16rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={text}
                    >
                        {text}
                    </p>
                ),
            },
            {
                title: '分类ID',
                dataIndex: 'id',
                width: 120,
            },
            {
                title: '备注',
                dataIndex: 'desc',
                render: text => (
                    <p
                        style={{
                            minWidth: '4rem',
                            maxWidth: '8rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={text}
                    >
                        {text}
                    </p>
                ),
            },
            {
                title: '创建时间',
                dataIndex: 'create_time',
                width: 200,
                render: text => <span>{text && text.slice(0, 10) + ' ' + text.slice(11, 19)}</span>,
            },
            {
                title: '操作',
                dataIndex: 'id',
                width: 160,
                render: (text, record) => (
                    <div className="opera">
                        <MyIcon
                            type={'icona-zu6914'}
                            style={{
                                fontSize: '1rem',
                                verticalAlign: 'middle',
                                color: 'rgba(0, 0, 0, 0.85)',
                                marginRight: '0.6rem',
                            }}
                            title={'编辑'}
                            onClick={() => {
                                getDetail(record.id);
                                setModalType('edit');
                                childRef.current.showModal(0);
                            }}
                        />
                        <Popconfirm
                            title="确定要删除吗?"
                            onConfirm={() => {
                                delCategory(record.id)
                                    .then(result => {
                                        message.success('删除成功');
                                        setUpdate(record.id);
                                    })
                                    .catch();
                            }}
                        >
                            <MyIcon
                                type="iconshanchu1"
                                style={{ color: 'rgba(0, 0, 0, 0.85)', fontSize: '1rem', verticalAlign: 'middle' }}
                                title={'删除'}
                            />
                        </Popconfirm>
                    </div>
                ),
            },
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);
    const userClassify = (page, pageSize) => {
        setCurrentPage(page);
        const params = omitBy(searchParams, value => {
            return isUndefined(value) || isNull(value) || value === '' || trim(value) === '';
        });
        setData({ data: [], isLoading: true });
        getCategory({ page, page_size: pageSize ? pageSize : page_size, ...params })
            .then(result => {
                setData({ data: result.data, isLoading: false });
                setCount(result.count);
            })
            .catch(error => {
                setData({ data: [], isLoading: true });
            });
    };
    useEffect(() => {
        userClassify(currentPage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [update, searchParams]);
    // 编辑详情
    const getDetail = userId => {
        getCategoryDetails(userId + '/')
            .then(result => {
                formChildrenRef.current.setFieldsValue({
                    product_name: result.data.product_name,
                    name: result.data.name,
                    id: result.data.id,
                    desc: result.data.desc,
                });
            })
            .catch();
    };
    const paginationNo = {
        pageSize: page_size, //每页的条数
        current: currentPage, //当前页
        total: count, //总数据
        showSizeChanger: true,
        onChange: (page, pageSize) => {
            ref.current = page;
            userClassify(page, pageSize);
        }, //点击分页号时触发的方法
        onShowSizeChange: (current, pageSize) => {
            setPageSize(pageSize);
        }, //pageSize 变化的回调
        hideOnSinglePage: true, //为true则数据条数不满或刚好一页，则隐藏分页器
        pageSizeOptions: ['12', '15', '20', '30', '50', '100'], // 分页类别
        showTotal: total => `共 ${total} 条`,
    };
    return (
        <div className="tableBox">
            {/* <Button
                type="primary"
                onClick={() => {
                    setModalType('add');
                    childRef.current.showModal();
                    formChildrenRef.current.resetFields();
                }}
                style={{ marginRight: '10px', marginBottom: '10px' }}
            >
                新增分类
            </Button> */}
            <Row>
                <Col span={8} className="gutter-row">
                    <Button
                        type="primary"
                        onClick={() => {
                            setModalType('add');
                            childRef.current.showModal();
                            if (formChildrenRef.current !== undefined || formChildrenRef.current !== null) {
                                formChildrenRef.current.resetFields();
                            }
                        }}
                        style={{ marginRight: '10px' }}
                    >
                        新增
                    </Button>
                </Col>
                <Col span={16} className="gutter-row" style={{ textAlign: 'right' }}>
                    <Form
                        form={form}
                        onFinish={value => {
                            setSearchParams(value);
                        }}
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                        <FormItem
                            label="型号"
                            name="name"
                            getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}
                        >
                            <Input placeholder="请输入要搜索的型号" />
                        </FormItem>
                        <FormItem
                            label="分类ID"
                            name="id"
                            style={{ margin: '0 2rem' }}
                            getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}
                        >
                            <Input placeholder="请输入要搜索的分类ID" />
                        </FormItem>
                        <Button type="primary" htmlType="submit">
                            搜索
                        </Button>
                    </Form>
                </Col>
            </Row>
            <ConfigProvider locale={zhCN}>
                <Table
                    rowKey={record => record.id}
                    columns={columns}
                    dataSource={data.data}
                    bordered={true}
                    scroll={{ x: '100%' }}
                    pagination={paginationNo}
                    loading={data.isLoading}
                    className="formTable"
                    locale={{
                        cancelSort: '点击取消排序',
                        triggerAsc: '点击升序',
                        triggerDesc: '点击降序',
                        filterConfirm: '确定',
                        filterReset: '重置',
                        emptyText: '暂无数据',
                        pageText: '页',
                    }}
                />
            </ConfigProvider>
            <CommonModal
                onOk={() => {
                    formChildrenRef.current
                        .validateFields()
                        .then(value => {
                            const params = omitBy(value, val => {
                                if (isNaN(val) || val === '') {
                                    return isUndefined(val) || isNull(val) || val === '';
                                } else {
                                    return isNull(val);
                                }
                            });
                            //TODO 发起http请求提交当前设置的巡检
                            modalType === 'add'
                                ? addCategory(params)
                                      .then(result => {
                                          message.success('新增成功');
                                          childRef.current.hiddenModal();
                                          setUpdate(result);
                                          formChildrenRef.current.resetFields();
                                      })
                                      .catch()
                                : changeCategory(params)
                                      .then(result => {
                                          message.success('编辑成功');
                                          childRef.current.hiddenModal();
                                          setUpdate(result);
                                          //   formChildrenRef.current.resetFields();
                                      })
                                      .catch();
                        })
                        .catch();
                }}
                childRef={childRef}
                title={`${modalType === 'edit' ? '编辑' : '新增'}产品`}
            >
                <HardForm childRef={formChildrenRef} type={modalType} />
            </CommonModal>
        </div>
    );
};

const HardForm = ({ childRef, type }) => {
    const [form] = Form.useForm();
    const FormItemLayout = {
        labelCol: { span: 5 },
        wrapperCol: { span: 16 },
    };
    useImperativeHandle(childRef, () => ({
        validateFields: form.validateFields,
        resetFields: form.resetFields,
        setFieldsValue: form.setFieldsValue,
    }));
    return (
        <Form form={form} layout="horizontal">
            <FormItem
                name="product_name"
                label="产品名称"
                {...FormItemLayout}
                rules={[{ required: true, message: '请输入产品名称！' }]}
                getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}
            >
                <Input placeholder="请输入产品名称" />
            </FormItem>
            <FormItem
                name="name"
                label="产品型号"
                {...FormItemLayout}
                rules={[{ required: true, message: '请输入产品型号！' }]}
                getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}
            >
                <Input placeholder="请输入产品型号" />
            </FormItem>
            <FormItem
                name="id"
                label="分类ID"
                {...FormItemLayout}
                rules={[
                    { required: true, message: '请输入分类编码！' },
                    { pattern: /^[0-9a-zA-Z]*$/g, message: '分类ID只能包含字母数字，并且长度为4' },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (value.length === 4) {
                                return Promise.resolve();
                            }

                            return Promise.reject(new Error('分类ID长度固定为4位!'));
                        },
                    }),
                ]}
                getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}
            >
                <Input
                    maxLength={4}
                    minLength={4}
                    disabled={type === 'edit' ? true : false}
                    placeholder="请输入分类编码"
                />
            </FormItem>
            <FormItem
                name="desc"
                label="备注"
                {...FormItemLayout}
                getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}
            >
                <Input />
            </FormItem>
        </Form>
    );
};

export default HardwareClassify;
