/*
 * @Descripttion:柱状图组件
 * @Author: lipei
 * @Date: 2021-08-Th 16:46:06
 * @Last Modified by:   lipei
 * @Last Modified time: 2021-12-Th 09:46:06
 */
import React, { useMemo } from 'react';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts';
import { map } from 'lodash';

export const ChartView = ({ rawData, name, height }) => {
    const { seriesData, xData } = useMemo(() => {
        const seriesData = [];
        const xData = [];
        const data = [];
        /**
         * number  0 离线 1 正常 2 告警
         * 页面展示柱状图高度一致
         * 1.number等于2 用红色显示柱状图，并且把值写入
         * 2.number等级1 用绿色显示柱状图，并且把值改为2写入
         * 3.number等级0 不显示
         * 隐藏Y轴，隐藏图例，自定义标签写图例
         */
        map(rawData, item => {
            if (item.number === 2) {
                data.push({
                    value: item.number,
                    itemStyle: {
                        color: 'rgba(255,128, 128, 1)',
                    },
                });
            } else if (item.number === 1) {
                data.push({
                    value: 2,
                    itemStyle: {
                        color: 'rgba(97, 233, 193, 1)',
                    },
                });
            } else {
                data.push(item.number);
            }
            xData.push(item.time);
        });
        seriesData.push({ name: name, value: data });
        return { seriesData, xData };
    }, [rawData]);
    var colors = [
        new echarts.graphic.LinearGradient(0, 1, 0, 0, [
            {
                offset: 0,
                color: 'rgba(97, 233, 193, 1)',
            },
            {
                offset: 1,
                color: 'rgba(11, 201, 145, 1)',
            },
        ]),
        new echarts.graphic.LinearGradient(0, 1, 0, 0, [
            {
                offset: 0,
                color: 'rgba(255,128, 128, 1)',
            },
            {
                offset: 1,
                color: 'rgba(255, 88, 108, 1)',
            },
        ]),
        new echarts.graphic.LinearGradient(0, 1, 0, 0, [
            {
                offset: 0,
                color: 'rgba(10, 121, 223, 1)',
            },
            {
                offset: 1,
                color: 'rgba(78, 164, 243, 1)',
            },
        ]),
    ];
    const getOption = data => {
        var seriesData = []; //{ name: '烟宝正常', type: 'bar' }
        let dataBar = {
            name: '烟雾报警',
            type: 'bar',
            data: data[0].value,
            barWidth: 15,
            itemStyle: {
                //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
                normal: {
                    //柱形图圆角，初始化效果
                    barBorderRadius: [15, 15, 0, 0],
                },
            },
            barGap: '0%',
        };
        seriesData.push(dataBar);
        var option = {
            color: colors,
            tooltip: {
                trigger: 'item',
                show: true,
                formatter: values => {
                    return `${values.name} ${values.color === 'rgba(255,128, 128, 1)' ? '烟雾报警': '烟雾正常'}`;
                },
            },
            grid: {
                top: 30,
                right: 10,
                left: 10,
                bottom: 15,
                containLabel: true,
            },
            legend: {
                show: false,
                selectedMode: true,
                orient: 'horizontal',
                top: 0,
                right: 0,
                itemWidth: 12,
                itemHeight: 12,
                textStyle: {
                    color: 'rgba(102, 102, 102, 1)',
                    fontSize: 12,
                },
                data: [
                    {
                        name: '烟雾正常',
                        icon: 'rect',
                    },
                    {
                        name: '烟雾报警',
                        icon: 'rect',
                    },
                ],
            },
            dataZoom: [
                {
                    type: 'inside', //inside slider
                    show: true, //flase直接隐藏图形
                    xAxisIndex: [0],
                    start: 0, //滚动条的起始位置
                    // end: rawData.length > 40 ? 10 : 50, //滚动条的截止位置（按比例分割你的柱状图x轴长度）
                    end: 100, //滚动条的截止位置（按比例分割你的柱状图x轴长度）
                    orient: 'vertical',
                },
            ],
            xAxis: [
                {
                    type: 'category',
                    data: xData,
                    axisLabel: {
                        textStyle: {
                            color: '#555555',
                            fontSize: 12,
                        },
                        margin: 10, //刻度标签与轴线之间的距离。
                    },
                    axisLine: {
                        show: true, //不显示x轴
                    },
                    axisTick: {
                        show: false, //不显示刻度
                        // intetval: 0,
                        // inside: true,
                        alignWithLabel: true,
                    },
                    boundaryGap: true,
                    splitLine: {
                        show: false,
                        width: 0.08,
                        lineStyle: {
                            type: 'solid',
                            color: '#03202E',
                        },
                    },
                },
            ],
            yAxis: [
                {
                    type: 'value',
                    show: false,
                    minInterval: 1, //设置成1保证坐标轴分割刻度显示成整数。
                    splitLine: {
                        // show: false,
                        lineStyle: {
                            color: '#999',
                            type: 'solid',
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#999',
                        },
                    },
                    axisLabel: {
                        textStyle: {
                            color: '#555555',
                        },
                    },
                },
            ],
            series: seriesData,
        };
        return option;
    };
    var option = getOption(seriesData);
    let legend = [
        {
            name: '烟雾正常',
            color: 'rgba(97, 233, 193, 1)',
        },
        {
            name: '烟雾报警',
            color: 'rgba(255,128, 128, 1)',
        },
    ];
    //自定义图例
    const Legend = () => {
        return (
            <div style={{ textAlign: 'right', marginRight: '1rem' }}>
                {map(legend, item => {
                    return (
                        <span style={{ marginLeft: '1rem' }}>
                            <i
                                style={{
                                    background: item.color,
                                    display: 'inline-block',
                                    width: '20px',
                                    height: '10px',
                                    borderRadius: '5px',
                                    marginRight: '0.3rem',
                                }}
                            ></i>
                            {item.name}
                        </span>
                    );
                })}
            </div>
        );
    };

    return (
        <>
            <Legend />
            <ReactEcharts option={option} style={{ width: '100%', height: height || '100%' }} resize={true} />
        </>
    );
};
