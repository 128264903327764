import React from 'react';
import { Chart, Series, YAxis, XAxis, Legend, Dataset, Tooltip, Toolbox, Grid } from '@echarts-start/react-bridge';
import * as echarts from 'echarts';
import { peakvalue, peakTime, unitControl } from '../../../../util/function-util';

export const ReportLineEchart = ({ rawData, color, showToolbox, showAreaStyle, name }) => {
    return (
        <Chart color={color} style={{ width: '100%', height: '100%' }} resize={true}>
            <Dataset source={rawData} dimensions={rawData[0]} />
            <Legend
                options={{
                    top: '5%',
                    left: '80%',
                    icon: 'circle',
                    itemWidth: 10,
                    textStyle: {
                        color: '#595959',
                        fontSize: 12,
                    },
                    name: name,
                }}
            />
            <Grid
                options={{
                    bottom: '15%',
                    left: '5%',
                    right: '5%',
                    containLabel: true,
                }}
            />
            <Tooltip
                options={{
                    trigger: 'axis',
                }}
            />
            <YAxis
                options={{
                    name: name === '功率因数' ? '' : `单位(${unitControl[name]})`,
                    type: 'value',
                }}
            />
            <XAxis
                options={{
                    type: 'category',
                    boundaryGap: false,
                    data: rawData.map(item => {
                        return item.name || peakTime(item.time);
                    }),
                    axisLabel: {
                        //x轴文字的配置
                        show: true,
                        //interval: 0, //使x轴文字显示全
                    },
                }}
            />
            {showToolbox && (
                <Toolbox
                    options={{
                        show: true,
                        itemGap: 15,
                        itemSize: 16,
                        zlevel: 10,
                        z: 2,
                        top: '5',
                        right: '10',
                        feature: {
                            magicType: {
                                show: true,
                                type: ['line', 'bar'],
                                title: { line: '切换为折线图', bar: '切换为柱状图' },
                                icon: {
                                    line:
                                        'path://M1011.301541 1023.859937H3.175339V9.384216a9.337528 9.337528 0 0 1 9.337528-9.337528 9.337528 9.337528 0 0 1 9.337527 9.337528v995.800665h989.451147a9.337528 9.337528 0 0 1 9.337528 9.337528 9.337528 9.337528 0 0 1-9.337528 9.337528z M165.414886 691.070442a9.337528 9.337528 0 0 1-7.376647-3.594948 9.337528 9.337528 0 0 1 1.634068-13.119227l252.953631-196.088087 253.18707 141.977112 225.314549-225.501299a9.337528 9.337528 0 0 1 13.212602 0 9.337528 9.337528 0 0 1 0.233438 13.212602l-28.012584 29.0864-207.573246 206.639493-254.681074-142.864178-243.149227 188.524689a9.337528 9.337528 0 0 1-5.74258 1.727443z',
                                    bar:
                                        'path://M1006.948898 1023.953728H3.123503V13.881609a13.881609 13.881609 0 1 1 27.763217 0v982.355174h976.062178a13.881609 13.881609 0 1 1 0 27.763217z M168.592279 893.744239a23.136014 23.136014 0 0 1-23.136015-23.136015v-280.130863a23.136014 23.136014 0 0 1 46.272029 0v280.130863a23.136014 23.136014 0 0 1-23.136014 23.136015z M662.962635 893.744239a23.136014 23.136014 0 0 1-23.136014-23.136015v-280.130863a23.136014 23.136014 0 0 1 46.272029 0v280.130863a23.136014 23.136014 0 0 1-23.136015 23.136015z M415.916273 912.206778a23.136014 23.136014 0 0 1-23.136014-23.136014V393.358518a23.136014 23.136014 0 0 1 46.272029 0v495.712246a23.136014 23.136014 0 0 1-23.136015 23.136014z M910.379174 912.25305a23.136014 23.136014 0 0 1-23.136014-23.136014v-417.928966a23.136014 23.136014 0 0 1 46.272029 0v417.928966a23.136014 23.136014 0 0 1-23.136015 23.136014z',
                                },
                            },
                        },
                        iconStyle: {
                            // color: '#64D399',
                            // borderColor: '#64D399'
                            emphasis: {
                                color: '#64D399',
                                borderColor: '#64D399',
                            },
                        },
                    }}
                />
            )}
            {/* <DataZoom
                options={{
                    type: 'inside', //inside slider
                    show: true, //flase直接隐藏图形
                    xAxisIndex: [0],
                    left: '1%', //滚动条靠左侧的百分比
                    start: 0, //滚动条的起始位置
                    end: 100, //滚动条的截止位置（按比例分割你的柱状图x轴长度）
                    orient: 'vertical',
                }}
            /> */}
            {/* {map(servers, (_, idx) => ( */}
            {!showAreaStyle && (
                <Series
                    // key={idx}
                    options={{
                        type: 'line',
                        name: name,
                        smooth: true,
                        data: rawData.map(item => {
                            return peakvalue(item.amount);
                        }),
                    }}
                />
            )}
            {showAreaStyle && (
                <Series
                    // key={idx}
                    options={{
                        type: 'line',
                        barGap: 0,
                        smooth: true,
                        name: name,
                        areaStyle: {
                            opacity: 0.8,
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: 'rgba(116, 202, 237)',
                                },
                                {
                                    offset: 1,
                                    color: 'rgba(189, 210, 253,0.1)',
                                },
                            ]),
                        },
                        data: rawData.map(item => {
                            return peakvalue(item.amount);
                        }),
                    }}
                />
            )}
            {/* ))} */}
        </Chart>
    );
};
