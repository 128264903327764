import React from 'react';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts';

export const ChartRadar = ({ rawData, color, bottom }) => {
    const getOption = rawData => {
        let textEvent = rawData.map(item => {
            let max = Math.max.apply(
                Math,
                rawData.map(item => {
                    return item.number;
                }),
            ); //获取数组对象中的number属性的最大值
            let name = { text: item.event, max: max };
            return name;
        });
        let value = rawData.map(item => {
            return item.number;
        });
        let option = {
            tooltip: {
                show: true,
                trigger: 'item',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#6a7985',
                    },
                },
            },
            // legend: {
            //     // top: '2%',
            //     // left: '80%',
            //     itemWidth: 12, // 设置宽度
            //     itemHeight: 8, // 设置高度
            //     icon: 'circle',
            //     data: name,
            //     // orient: 'horizontal',
            //     textStyle: {
            //         color: '#c7c7c7',
            //         fontSize: 12,
            //     },
            // },
            radar: {
                indicator: textEvent,
                center: ['40%', '48%'],
                radius: 90,
                startAngle: 90,
                splitNumber: 4,
                shape: 'circle',
                name: {
                    formatter: '{value}',
                    textStyle: {
                        color: '#fff',
                        backgroundColor: '#C8D7F4',
                        borderRadius: 3,
                        padding: [5, 5],
                    },
                },
                splitArea: {
                    areaStyle: {
                        color: ['rgba(255,255,255,0.3)'],
                        shadowColor: 'rgba(0, 0, 0, 0.2)',
                        shadowBlur: 10,
                    },
                },
                axisLabel: {
                    show: false,
                    fontSize: 18,
                    color: '#fff',
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                },
                axisLine: {
                    show: true,
                    lineStyle: {
                        // color: 'rgba(211, 253, 250, 0.8)',
                    },
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        // color: 'rgba(211, 253, 250, 0.8)',
                    },
                },
            },
            animationType: 'scale',
            animationEasing: 'elasticOut',
            animationDelay: function (idx) {
                return Math.random() * 200;
            },
            animationDuration: 1000,
            series: {
                type: 'radar',
                name: '告警异常',
                // radarIndex: 1,
                emphasis: {
                    lineStyle: {
                        width: 2,
                    },
                },
                itemStyle: {
                    borderColor: 'rgba(255, 255, 255, 0.9)',
                    borderWidth: 0.5,
                },
                lineStyle: {
                    // normal: {
                    type: 'dashed',
                    width: 2,
                    // },
                },
                data: [
                    {
                        value: value,
                        // name: name,
                        areaStyle: {
                            color: new echarts.graphic.RadialGradient(0.1, 0.6, 1, [
                                {
                                    color: 'rgba(95, 145, 224, 0.1)',
                                    offset: 0,
                                },
                                {
                                    color: 'rgba(95, 146, 249, 0.6)',
                                    offset: 1,
                                },
                            ]),
                        },
                        symbolSize: 10,
                        label: {
                            show: true,
                            formatter: function (params) {
                                return params.value;
                            },
                        },
                    },
                ],
            },
        };
        return option;
    };
    const option = getOption(rawData);
    return <ReactEcharts option={option} />;
};
