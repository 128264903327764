import React, { useState, useEffect } from 'react';
import { Row, Col, Tooltip } from 'antd';
import './style.less';
import { MyIcon } from '../../../util/iconfont';
import { peakvalue } from '../../../util/function-util';
import { isEmpty } from 'lodash';
import zheJTP from '../../../assets/images/zheJTP.png';

export const ItemView = ({ datas }) => {
    const [data, setData] = useState(datas);
    useEffect(() => {
        setData(datas);
    }, [datas]);
    return (
        <Row gutter={32} className="ItemViewStyle">
            <Col
                className="gutter-row"
                span={6}
                style={{ marginBottom: '1rem', paddingLeft: '16px', paddingRight: '0px' }}
            >
                <div
                    className="colBox"
                    style={{ background: `url(${zheJTP}) no-repeat,linear-gradient(-45deg, #7AC2E7 , #599BDE)` }}
                >
                    <div className="col">
                        <span className="title">区域数量</span>
                        <Tooltip title="只统计二级区域数量">
                            <MyIcon
                                type="icon_tishi"
                                style={{
                                    verticalAlign: 'middle',
                                    fontSize: '1.2rem',
                                    marginRight: '0.7rem',
                                    // color: '#666666',
                                    color: '#fff',
                                }}
                            />
                        </Tooltip>
                    </div>
                    <div className="col">
                        <span className="value">
                            {isEmpty(data.data) ? 0 : data.data.region}
                            <i className="company">个</i>
                        </span>
                        <MyIcon
                            type="icontree"
                            style={{
                                verticalAlign: 'middle',
                                fontSize: '2.8rem',
                                marginTop: '1rem', // color: '#61879C',
                                color: '#fff',
                            }}
                        />
                    </div>
                </div>
            </Col>
            <Col className="gutter-row" span={6} style={{ marginBottom: '1rem', paddingRight: '0px' }}>
                {/* <div className="colBox" style={{background: `url(${zheJTP}) left center no-repeat,linear-gradient(-45deg, #ff7ea2, #ff466d)`}}> */}
                <div
                    className="colBox"
                    style={{
                        background: `url(${zheJTP}) left center no-repeat,linear-gradient(-45deg, #41C995, #2EB675)`,
                    }}
                >
                    <div className="col">
                        <span className="title">设备数量</span>
                        <Tooltip title="设备数量=网关数量+CT数量">
                            <MyIcon
                                type="icon_tishi"
                                style={{
                                    verticalAlign: 'middle',
                                    fontSize: '1.2rem',
                                    marginRight: '0.7rem',
                                    // color: '#666666',
                                    color: '#fff',
                                }}
                            />
                        </Tooltip>
                    </div>
                    <div className="col">
                        <span className="value">
                            {isEmpty(data.data) ? 0 : data.data.device_all}
                            <i className="company">个</i>
                        </span>
                        <MyIcon
                            type="iconshebei1"
                            style={{
                                verticalAlign: 'middle',
                                fontSize: '2.8rem',
                                marginTop: '1rem', // color: '#61879C',
                                color: '#fff',
                            }}
                        />
                    </div>
                </div>
            </Col>
            <Col className="gutter-row" span={6} style={{ marginBottom: '1rem', paddingRight: '0px' }}>
                <div
                    className="colBox"
                    style={{
                        background: `url(${zheJTP}) left bottom no-repeat,linear-gradient(-45deg, #F4D05F , #E9B348)`,
                    }}
                >
                    <div className="col">
                        <span className="title">设备在线量</span>
                        <Tooltip title="设备在线量=在线网关量+在线CT量">
                            <MyIcon
                                type="icon_tishi"
                                style={{
                                    verticalAlign: 'middle',
                                    fontSize: '1.2rem',
                                    marginRight: '0.7rem',
                                    // color: '#666666',
                                    color: '#fff',
                                }}
                            />
                        </Tooltip>
                    </div>
                    <div className="col">
                        <span className="value">
                            {isEmpty(data.data) ? 0 : data.data.device_online}
                            <i className="company">个</i>
                        </span>
                        <MyIcon
                            type="iconzaixianyonghu"
                            style={{
                                verticalAlign: 'middle',
                                fontSize: '2.5rem',
                                marginTop: '1.2rem',
                                // color: '#61879C',
                                color: '#fff',
                            }}
                        />
                    </div>
                </div>
            </Col>
            <Col className="gutter-row" span={6} style={{ marginBottom: '1rem', paddingRight: '0px' }}>
                <div
                    className="colBox"
                    style={{
                        background: `url(${zheJTP}) left bottom no-repeat,linear-gradient(-45deg, #A7B9C6, #839EAB)`,
                    }}
                >
                    <div className="col">
                        <span className="title">设备在线率</span>
                        <Tooltip title="设备在线率=(在线网关量+在线CT量)/设备总数量">
                            <MyIcon
                                type="icon_tishi"
                                style={{
                                    verticalAlign: 'middle',
                                    fontSize: '1.2rem',
                                    marginRight: '0.7rem',
                                    // color: '#666666',
                                    color: '#fff',
                                }}
                            />
                        </Tooltip>
                    </div>
                    <div className="col">
                        <span className="value">
                            {isEmpty(data.data) ? 0 : peakvalue(data.data.online_rate * 100)}
                            <i className="company">%</i>
                        </span>
                        <MyIcon
                            type="iconline-doughnutcharthuanxingshujutu-02"
                            style={{
                                verticalAlign: 'middle',
                                fontSize: '2.8rem',
                                marginTop: '1rem', //color: '#61879C',
                                color: '#fff',
                            }}
                        />
                    </div>
                </div>
            </Col>
        </Row>
    );
};
