import React, { useMemo } from 'react';
import { map } from 'lodash';
import {
    Chart,
    Series,
    YAxis,
    XAxis,
    Legend,
    Dataset,
    Tooltip,
    Toolbox,
    Grid,
    DataZoom,
} from '@echarts-start/react-bridge';

export const HistogramChart = ({ rawData, showToolbox, color, showDataZoom, stack, unit, showSeries }) => {
    const servers = useMemo(() => {
        return new Array(rawData[0].length - 1);
    }, [rawData]);
    return (
        <Chart color={color} style={{ width: '100%', height: '100%' }} resize={true}>
            <Dataset source={rawData} dimensions={rawData[0]} />
            <Legend
                options={{
                    top: '2%',
                    left: 'center',
                    icon: 'circle',
                    itemWidth: 10,
                    textStyle: {
                        // color: '#c7c7c7',
                        fontSize: 12,
                    },
                }}
            />
            <Grid
                options={{
                    bottom: '10%',
                    left: '6%',
                    right: '6%',
                }}
            />
            <Tooltip
                options={{
                    trigger: 'axis',
                }}
            />
            {showToolbox && (
                <Toolbox
                    options={{
                        show: true,
                        itemGap: 15,
                        itemSize: 16,
                        zlevel: 10,
                        z: 2,
                        top: '5',
                        right: '10',
                        feature: {
                            magicType: {
                                show: true,
                                type: ['line', 'bar', 'stack'],
                                title: { bar: '切换为柱状图', line: '切换为折线图', stack: '切换为堆叠图' },
                            },
                        },
                        iconStyle: {
                            // color: '#64D399',
                            // borderColor: '#64D399'
                            emphasis: {
                                color: '#64D399',
                                borderColor: '#64D399',
                            },
                        },
                    }}
                />
            )}
            <YAxis
                // gridIndex={0}
                options={{
                    name: `单位：(${unit || 'kW·h'})`,
                    type: 'value',
                    min: 0,
                    position: 'left',
                    gridIndex: 0,
                }}
            />
            {/* <YAxis
                // gridIndex={1}
                options={{
                    name: '单位：(' + `${unit || 'kW·h'})`,
                    type: 'value',
                    min: 0,
                    position: 'right',
                    gridIndex: 1,
                }}
            /> */}
            <XAxis
                options={{
                    type: 'category',
                    name: '时间',
                    axisLabel: {
                        //x轴文字的配置
                        show: true,
                        //interval: 0, //使x轴文字显示全
                    },
                }}
            />
            {showDataZoom && (
                <DataZoom
                    options={{
                        type: 'inside', //inside slider
                        show: true, //flase直接隐藏图形
                        xAxisIndex: [0],
                        start: 0, //滚动条的起始位置
                        end: rawData.length > 20 ? 60 : 100, //滚动条的截止位置（按比例分割你的柱状图x轴长度）
                        orient: 'vertical',
                    }}
                />
            )}
            {showSeries
                ? map(servers, (_, idx) => (
                      <Series
                          key={idx}
                          options={{
                              //   barGap: 0,
                              //   name: idx === servers.length - 1 ? '总用量' : '',
                              type: idx === servers.length - 1 ? 'line' : 'bar',
                              barMaxWidth: 15,
                              stack: idx === servers.length - 1 ? '' : stack,
                              //   yAxisIndex: idx === servers.length - 1 ? 1 : 0
                          }}
                        //   yAxisIndex={idx === servers.length - 1 ? 1 : 0}
                        //   type={idx === servers.length - 1 ? 'line' : 'bar'}
                        //   stack={idx === servers.length - 1 ? '' : 'stack'}
                      />
                  ))
                : map(servers, (_, idx) => (
                      <Series
                          key={idx}
                          options={{
                              barGap: 0,
                              type: 'bar',
                              barMaxWidth: 15,
                              stack: stack,
                          }}
                      />
                  ))}
        </Chart>
    );
};
