import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import { Table, Popconfirm, Form, Input, message, Typography, Image } from 'antd';
import { BreadcrumbCustom } from '../../../components/BreadcrumbCustom';
import { CommonModal } from '../../../components/CommoModal';
import RouteHelper from '../../../tools/RouteHelper';
import { MyIcon } from '../../../util/iconfont';
import { getFeedback, removeFeedback } from '../../../api/adminServe';

const FormItem = Form.Item;
const FormItemLayout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 16 },
};

const Feedback = () => {
    return (
        <div className="container clearfix">
            <BreadcrumbCustom paths={['APP管理', '意见反馈']} className="rumbCustom" />
            <div
                className="row clearfix"
                style={{ padding: '1rem', margin: '1rem', backgroundColor: '#ffffff', borderRadius: '10px' }}
            >
                <FeedbackTable />
            </div>
        </div>
    );
};
const FeedbackTable = () => {
    const childRef = useRef();
    const formChildrenRef = useRef();
    //const [modalType, setModalType] = useState('edit'); //edit add export other
    const [data, setData] = useState({ data: null, isLoading: true });
    const [count, setCount] = useState(0); //数据总条数
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(12);
    const getList = () => {
        setData({ data: [], isLoading: true });
        getFeedback({ page, pageSize })
            .then(result => {
                setData({ data: result.data, isLoading: false });
                setCount(result.count);
            })
            .catch();
    };
    useEffect(() => {
        getList();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, pageSize]);
    const columns = [
        {
            title: '序号',
            dataIndex: 'id',
            key: 'id',
            width: 100, //宽度
            render: (text, record, index) => `${index + 1}`,
        },
        {
            title: '标题',
            dataIndex: 'title',
            render: text => (
                <p
                    style={{
                        minWidth: '4rem',
                        maxWidth: '8rem',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                    }}
                    title={text}
                >
                    {text}
                </p>
            ),
        },
        {
            title: '内容',
            dataIndex: 'content',
            render: text => (
                <p
                    style={{
                        minWidth: '8rem',
                        maxWidth: '16rem',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        margin: '0 auto',
                    }}
                    title={text}
                >
                    {text}
                </p>
            ),
        },
        {
            title: '联系方式',
            dataIndex: 'mobile',
            width: 100,
        },
        {
            title: '反馈时间',
            dataIndex: 'create_time',
            width: 200,
            render: (text, record, index) => <span>{text && text.slice(0, 10) + ' ' + text.slice(11, 19)}</span>,
        },
        {
            title: '操作',
            dataIndex: 'opera',
            width: 200,
            render: (text, record) => (
                <div className="opera">
                    <Typography.Link
                        className="opera"
                        // target="_blank"
                        // href={`/admin/appManagement/feedback2/readFeedback?id=${record.id}`}
                    >
                        <MyIcon
                            type={'iconchakan1'}
                            style={{
                                fontSize: '1rem',
                                verticalAlign: 'middle',
                                color: 'rgba(0, 0, 0, 0.85)',
                                marginRight: '0.6rem',
                            }}
                            title={'查看'}
                            onClick={() => {
                                RouteHelper.locationTo('/admin/appManagement/feedback2/readFeedback', {
                                    id: record.id,
                                });
                            }}
                        />
                    </Typography.Link>
                    <Popconfirm
                        title="确定要删除吗?"
                        onConfirm={() => {
                            removeFeedback(record.id)
                                .then(() => {
                                    message.success('删除成功');
                                    getList();
                                })
                                .catch();
                        }}
                    >
                        <MyIcon
                            type="iconshanchu1"
                            style={{ color: 'rgba(0, 0, 0, 0.85)', fontSize: '1rem', verticalAlign: 'middle' }}
                            title={'删除'}
                        />
                    </Popconfirm>
                </div>
            ),
        },
    ];
    const pagination = {
        pageSize: pageSize, //每页的条数
        current: page, //当前页
        total: count, //总数据
        showSizeChanger: true,
        onChange: page => {
            setPage(page);
        }, //点击分页号时触发的方法
        onShowSizeChange: (current, pageSize) => {
            setPageSize(pageSize);
        }, //pageSize 变化的回调
        hideOnSinglePage: true, //为true则数据条数不满或刚好一页，则隐藏分页器
        pageSizeOptions: ['12', '20', '30', '50', '100'], // 分页类别
    };
    return (
        <div className="tableBox">
            {/* <Button
                type="primary"
                onClick={() => {
                    setModalType('add');
                    childRef.current.showModal();
                }}
                style={{ marginRight: '10px', marginBottom: '10px' }}
            >
                新增反馈
            </Button> */}
            <Table
                className="formTable bannerClass"
                rowKey={record => record.id}
                columns={columns}
                dataSource={data.data}
                loading={data.isLoading}
                bordered={true}
                scroll={{ x: '100%' }}
                pagination={pagination}
            />
            <CommonModal
                onOk={() => {
                    formChildrenRef.current
                        .validateFields()
                        .then(value => {
                            //do something
                            // value.images = formChildrenRef.current.fileLists.fileList;
                            // getFeedbackDetails(value)
                            //     .then(() => {
                            //         message.info('新增成功');
                            //     })
                            //     .catch();
                        })
                        .catch(err => {
                            childRef.endLoading();
                        });
                }}
                childRef={childRef}
                // title={modalType === 'read' ? '查看反馈' : '编辑反馈'}
            >
                <FeedbackForm childRef={formChildrenRef} />
            </CommonModal>
        </div>
    );
};

const FeedbackForm = ({ childRef }) => {
    const [form] = Form.useForm();
    useImperativeHandle(childRef, () => ({
        validateFields: form.validateFields,
        resetFields: form.resetFields,
    }));

    return (
        <Form layout="horizontal" form={form}>
            <FormItem label="标题" {...FormItemLayout} name="title">
                <Input />
            </FormItem>
            <FormItem
                label="联系电话"
                {...FormItemLayout}
                name="mobile"
                rules={
                    [
                        // { pattern: /^1(3|4|5|7|8)\d{9}$/, message: '请输入正确的手机号' },
                        // { required: true, message: '请输入手机号' },
                    ]
                }
            >
                <Input addonBefore={'+86'} style={{ width: '100%' }} maxLength={11} />
            </FormItem>
            <FormItem {...FormItemLayout} label="内容" name="content">
                <Input.TextArea />
            </FormItem>
            <FormItem label="附件" name="image" {...FormItemLayout}>
                <Image width={200} src={'image'} />
            </FormItem>
        </Form>
    );
};

export default Feedback;
