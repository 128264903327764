import React, { Component } from 'react';
import { Layout } from 'antd';
import ContentMain from '../../components/ContentMain';
import SiderNav from '../../components/SiderNavs';
import Avatar from '../../components/Avatar';
import { fakeAuth } from '../../util/fakeAuth';
import { getPermissions, setPermissions, removeUser } from '../../tools/StorageHelper';
import notFound from '../../assets/images/404.svg';
import './index.less';
import { getCookie, setCookie } from '../../util/cookies';
import { getPermission } from '../../api/webServe';

const { Sider, Header, Content } = Layout;
export default class Index extends Component {
    state = {
        collapsed: getCookie('mspa_SiderCollapsed') === 'true',
        hiddenSlider: false,
        hiddenHeader: false,
        currentPathname: '',
        siderRef: null,
        data: getPermissions() != 'undefined' && JSON.parse(getPermissions()),
    };
    toggle = () => {
        this.setState(
            {
                collapsed: !this.state.collapsed,
            },
            function () {
                setCookie('mspa_SiderCollapsed', this.state.collapsed);
            },
        );
    };

    componentWillMount() {
        getPermission()
            .then(result => {
                let data = [],
                    childData = [],
                    permissionData = [];
                (result.data !== undefined || result.data.length > 0) &&
                    result.data.map(item => {
                        data.push(item.name);
                        if (item && item.children !== undefined) {
                            item &&
                                item.children.map(itemChild => {
                                    childData.push(itemChild.name);
                                    if (
                                        itemChild &&
                                        itemChild.permissions !== undefined &&
                                        itemChild.permissions.length > 0
                                    ) {
                                        itemChild &&
                                            itemChild.permissions.map(itemPermission => {
                                                permissionData.push(itemPermission.name);
                                            });
                                    }
                                });
                        }
                        if (item && item.permissions !== undefined && item.permissions.length > 0) {
                            item &&
                                item.permissions.map(itemPermission => {
                                    permissionData.push(itemPermission.name);
                                });
                        }
                    });
                this.setState({ data: result.data || JSON.parse(getPermissions()) });
                setPermissions(JSON.stringify(result.data));
                // setPermissionsData(JSON.stringify(data));
                // setPermissionsDataChildren(JSON.stringify(childData));
                // setPermissionsPermission(JSON.stringify(permissionData));
            })
            .catch();
    }

    // async componentDidMount() {
    //     //保存Sider收缩
    //     if (getCookie('mspa_SiderCollapsed') === null) {
    //         setCookie('mspa_SiderCollapsed', false);
    //     }
    //     // await this.setState({ data: JSON.parse(getPermissions()),function () {
    //     //     this.state.data = JSON.parse(getPermissions());
    //     // }, });
    //     await getPermission()
    //         .then(result => {
    //             let data = [],
    //                 childData = [],
    //                 permissionData = [];
    //             result.data &&
    //                 (result.data !== undefined || result.data.length > 0) &&
    //                 result.data.map(item => {
    //                     data.push(item.name);
    //                     if (item && item.children !== undefined) {
    //                         item &&
    //                             item.children.map(itemChild => {
    //                                 childData.push(itemChild.name);
    //                                 if (
    //                                     itemChild &&
    //                                     itemChild.permissions !== undefined &&
    //                                     itemChild.permissions.length > 0
    //                                 ) {
    //                                     itemChild &&
    //                                         itemChild.permissions.map(itemPermission => {
    //                                             permissionData.push(itemPermission.name);
    //                                         });
    //                                 }
    //                             });
    //                     }
    //                     if (item && item.permissions !== undefined && item.permissions.length > 0) {
    //                         item &&
    //                             item.permissions.map(itemPermission => {
    //                                 permissionData.push(itemPermission.name);
    //                             });
    //                     }
    //                 });
    //             this.setState({
    //                 data: result.data || (getPermissions() != 'undefined' && JSON.parse(getPermissions())),
    //             });
    //             setPermissions(JSON.stringify(result.data));
    //             // setPermissionsData(JSON.stringify(data));
    //             // setPermissionsDataChildren(JSON.stringify(childData));
    //             // setPermissionsPermission(JSON.stringify(permissionData));
    //         })
    //         .catch();
    // }

    componentDidMount() {
        //保存Sider收缩
        if (getCookie('mspa_SiderCollapsed') === null) {
            setCookie('mspa_SiderCollapsed', false);
        }
        // await this.setState({ data: JSON.parse(getPermissions()),function () {
        //     this.state.data = JSON.parse(getPermissions());
        // }, });
        getPermission()
            .then(result => {
                let data = [],
                    childData = [],
                    permissionData = [];
                result.data &&
                    (result.data !== undefined || result.data.length > 0) &&
                    result.data.map(item => {
                        data.push(item.name);
                        if (item && item.children !== undefined) {
                            item &&
                                item.children.map(itemChild => {
                                    childData.push(itemChild.name);
                                    if (
                                        itemChild &&
                                        itemChild.permissions !== undefined &&
                                        itemChild.permissions.length > 0
                                    ) {
                                        itemChild &&
                                            itemChild.permissions.map(itemPermission => {
                                                permissionData.push(itemPermission.name);
                                            });
                                    }
                                });
                        }
                        if (item && item.permissions !== undefined && item.permissions.length > 0) {
                            item &&
                                item.permissions.map(itemPermission => {
                                    permissionData.push(itemPermission.name);
                                });
                        }
                    });
                this.setState({
                    data: result.data || (getPermissions() != 'undefined' && JSON.parse(getPermissions())),
                });
                setPermissions(JSON.stringify(result.data));
                // setPermissionsData(JSON.stringify(data));
                // setPermissionsDataChildren(JSON.stringify(childData));
                // setPermissionsPermission(JSON.stringify(permissionData));
            })
            .catch();
    }

    render() {
        const { collapsed, hiddenSlider, hiddenHeader, data } = this.state;
        let name;
        if (!getCookie('mspa_user') || getCookie('mspa_user') === 'undefined') {
        } else {
            name = JSON.parse(getCookie('mspa_user')).username;
        }
        // return getPermissions() != 'undefined' && JSON.parse(getPermissions()).length === 0 ? (
            return data.length === 0 ? (
            <div
                className="example"
                style={{
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: 'rgba(0,0,0,0.05)',
                }}
            >
                {/* <Spin tip="加载中..." /> */}
                <div className="notFound">
                    <img src={notFound} className="notFoundIcon" alt="not found" />
                    <p className="tips">
                        此账号无任何访问权限，
                        <a
                            href="/login"
                            onClick={() => {
                                fakeAuth.signout(); // 清除token
                                fakeAuth.logout('selectedKey');
                                fakeAuth.logout('login_tag_key');
                                removeUser();
                            }}
                        >
                            请重新登录
                        </a>
                    </p>
                </div>
            </div>
        ) : (
            <Layout style={{ flexDirection: 'row' }}>
                {hiddenSlider ? null : (
                    <Sider collapsible trigger={null} collapsed={collapsed}>
                        <SiderNav
                            {...this.props}
                            collapsed={collapsed}
                            onToggle={this.toggle}
                            onRef={el => {
                                this.setState({ siderRef: el });
                            }}
                        />
                    </Sider>
                )}
                <Layout>
                    {hiddenHeader ? null : (
                        <Header style={{ background: '#fff', padding: 0 }} username={name}>
                            <Avatar />
                        </Header>
                    )}
                    <Content style={{ height: 'calc(100vh - 64px)', overflow: 'auto' }}>
                        {this.state.siderRef ? (
                            <ContentMain
                                listenRouterPath={this.state.siderRef.pathnameChange}
                                handleSlider={isHidden => {
                                    this.setState({ hiddenSlider: isHidden });
                                }}
                                handleHeader={isHidden => {
                                    this.setState({ hiddenHeader: isHidden });
                                }}
                            />
                        ) : null}
                    </Content>
                </Layout>
            </Layout>
        );
    }
}