import React, { Component } from 'react';
import { Form, Input, Select } from 'antd';

const FormItem = Form.Item;

class EditForm extends Component {
    // constructor(props) {
    //   super(props);
    // }

    render() {
        const { form } = this.props;
        const FormItemLayout = { labelCol: { span: 5 }, wrapperCol: { span: 16 } };
        return (
            <Form layout="horizontal" form={form} initialValues={{role: '正常'}}>
                <Form.Item
                    name="role"
                    label="电流"
                    {...FormItemLayout}
                    hasFeedback
                    rules={[{ required: true, message: '请选择电流！' }]}
                >
                    <Select>
                        <Select.Option value="正常">正常</Select.Option>
                        <Select.Option value="异常">异常</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name="role"
                    label="三相电流"
                    {...FormItemLayout}
                    hasFeedback
                    rules={[{ required: true, message: '请选择三相电流！' }]}
                >
                    <Select>
                        <Select.Option value="正常">正常</Select.Option>
                        <Select.Option value="异常">异常</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name="role"
                    label="剩余电流"
                    {...FormItemLayout}
                    hasFeedback
                    rules={[{ required: true, message: '请选择剩余电流！' }]}
                >
                    <Select>
                        <Select.Option value="正常">正常</Select.Option>
                        <Select.Option value="异常">异常</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name="role"
                    label="电压"
                    {...FormItemLayout}
                    hasFeedback
                    rules={[{ required: true, message: '请选择电压！' }]}
                >
                    <Select>
                        <Select.Option value="正常">正常</Select.Option>
                        <Select.Option value="异常">异常</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name="role"
                    label="功率因数"
                    {...FormItemLayout}
                    hasFeedback
                    rules={[{ required: true, message: '请选择功率因数！' }]}
                >
                    <Select>
                        <Select.Option value="正常">正常</Select.Option>
                        <Select.Option value="异常">异常</Select.Option>
                    </Select>
                </Form.Item>
                <FormItem
                    label="备注"
                    {...FormItemLayout}
                    hasFeedback
                    name="enterprise"
                    rules={[{ required: false, message: '请输入备注！' }]}
                >
                    <Input />
                </FormItem>
                <Form.Item
                    name="role"
                    label="线缆温度"
                    {...FormItemLayout}
                    hasFeedback
                    rules={[{ required: true, message: '请选择线缆温度！' }]}
                >
                    <Select>
                        <Select.Option value="正常">正常</Select.Option>
                        <Select.Option value="异常">异常</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name="role"
                    label="温度"
                    {...FormItemLayout}
                    hasFeedback
                    rules={[{ required: true, message: '请选择温度！' }]}
                >
                    <Select>
                        <Select.Option value="正常">正常</Select.Option>
                        <Select.Option value="异常">异常</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name="role"
                    label="湿度"
                    {...FormItemLayout}
                    hasFeedback
                    rules={[{ required: true, message: '请选择湿度！' }]}
                >
                    <Select>
                        <Select.Option value="正常">正常</Select.Option>
                        <Select.Option value="异常">异常</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name="role"
                    label="烟感"
                    {...FormItemLayout}
                    hasFeedback
                    rules={[{ required: true, message: '请选择烟感！' }]}
                >
                    <Select>
                        <Select.Option value="正常">正常</Select.Option>
                        <Select.Option value="异常">异常</Select.Option>
                    </Select>
                </Form.Item>
                {/* <FormItem {...Layout}>
          <Button style={{ marginTop: '1rem', marginRight: '1.5rem' }}>取消</Button>
          <Button type="primary" htmlType="submit" style={{ marginTop: '1rem' }}>提交</Button>
        </FormItem> */}
            </Form>
        );
    }
}

export default EditForm;
