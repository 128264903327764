import React, { useState, useMemo, useEffect } from 'react';
import { Tree, Input, message } from 'antd';
import { map, get, isEmpty, omit } from 'lodash';
import { EditOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import treeStyle from './commonTree.module.less';
import { searchArea } from '../api/webServe';
import { Loading } from './Loading';
import { EmptyView } from './EmptyView';
import { MyIcon } from '../util/iconfont';
import { treeIcon } from '../global';
const { Search } = Input;

/**
 * 包含区域的组件 （主要用于对比分析）
 * × 不可默认渲染第一条数据
 * √ 可多选
 * 
 * @param {*} treeData   区域树的数据
 * @param {*} onClick    点击事件触发
 * @param {*} operable   编辑节点
 * @param {*} add        新增节点
 * @param {*} edit       编辑节点
 * @param {*} remove     删除节点
 * @param {*} selectNum  节点title旁边的数量
 * @param {*} getAllTree 
 * selectable  给监测点数据中增加了新字段 selectable ： 是否可以选中 （主要是解决选中超过5个监测点的限制）
 * @returns 
 */

export const CommonTree = ({
    treeData = [],
    onClick,
    operable = true,
    add,
    edit,
    remove,
    selectNum,
    getAllTree,
    multiples,
}) => {
    const [searchValue, setSearchValue] = useState('');
    const [searchResult, setSearchResult] = useState({ isLoading: true, data: null });
    const [selected, setSelected] = useState([]);
    const [selectedNode, setSelectedNode] = useState([]);
    const { dataList, parentRegionIds } = useMemo(() => {
        const generateList = data => {
            return map(data, item => {
                const { id, name } = item;
                let _index = selectedNode.findIndex(c => c.id === id);
                return {
                    ...item,
                    key: `${id}`,
                    title: name,
                    children: generateList(item.children),
                    // selectable: multiples && (selected.length >= 5 && _index <= -1 ? false : true),
                    disabled: multiples && (selected.length >= 5 && _index <= -1 ? true : false),
                };
            });
        };
        const dataList = generateList(treeData);
        const parentRegionIds = get(dataList, '[0].id');
        getAllTree && getAllTree(dataList);
        //onClick && onClick(dataList[0]); //功能模块不需要默认请求
        return { dataList, parentRegionIds };
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [treeData, selected]);
    const onDrop = (event, node, dragNode, dragNodesKeys) => {
        // console.log(event);
        // console.log(node);
        // console.log(dragNode);
        // console.log(dragNodesKeys, 'dragNodesKeys');
    };
    // const [selectable, setSelectable] = useState(true);
    //点击树节点触发
    const onSelect = (selectedKeys, info) => {
        //TODO 解决能耗统计中的对比分析功能页面中的超过五个节点不可以选中的问题
        setSelected(selectedKeys);
        setSelectedNode(info.selectedNodes);
        if (multiples && selectedKeys.length < 5) {
            onClick && onClick(info.node, selectedKeys);
        } else if(multiples && selectedKeys.length >= 5) {
            message.info('最多支持5个监测点加入对比');
            onClick && onClick(info.node, selectedKeys.slice(0, 5));
        } else {
            onClick && onClick(info.node);
        }
    };
    const [expandedKeys, setExpandedKeys] = useState([`${parentRegionIds}`]);
    const [autoExpandParent, setAutoExpandParent] = useState(false);
    useEffect(() => {
        setExpandedKeys([`${parentRegionIds}`]);
    }, [parentRegionIds]);
    //展开的回调
    const onExpand = expandedKeysValue => {
        //展开/收起节点时触发
        //展开的状态
        setExpandedKeys(expandedKeysValue);
        setAutoExpandParent(false);
    };
    return (
        dataList.length > 0 ? <div className={treeStyle.container}>
            <Search
                style={{ marginBottom: 8 }}
                placeholder="请输入"
                allowClear
                onSearch={value => {
                    setSearchValue(value);
                    setSearchResult({ ...searchResult, isLoading: true });
                    searchArea({ name: value })
                        .then(result => {
                            result = map(result.data, item => {
                                let path = '';
                                map(get(item, 'routes', []), area => {
                                    path += `${area.name}>`;
                                });
                                path = path.substr(0, path.length - 1);
                                return { ...omit(item, ['routes']), path };
                            });
                            setSearchResult({ data: result, isLoading: false });
                        })
                        .catch(() => {
                            setSearchResult({ data: null, isLoading: false });
                        });
                }}
            />
            {searchValue ? (
                <Loading show={searchResult.isLoading}>
                    {isEmpty(searchResult.data) ? (
                        <EmptyView />
                    ) : (
                        <SearchTemplate data={searchResult.data} onClick={onClick} />
                    )}
                </Loading>
            ) : (
                <Tree
                    multiple={multiples}
                    onExpand={onExpand} //展开事件
                    onDrop={onDrop}
                    onSelect={onSelect}
                    expandedKeys={expandedKeys} //默认展开的key
                    autoExpandParent={autoExpandParent} //是否自动展开父节点
                    treeData={dataList}
                    // selectable={selectable}
                    titleRender={
                        operable
                            ? node => (
                                  <TreeTitle add={add} edit={edit} remove={remove} node={node} selectNum={selectNum} />
                              )
                            : null
                    }
                />
            )}
        </div> : <div style={{height: '76vh'}}><EmptyView /></div> 
    );
};
const SearchTemplate = ({ data, onClick }) => {
    return (
        <div style={{ padding: '0 10px 10px 10px' }}>
            {map(data, item => {
                return (
                    <div
                        onClick={() => {
                            onClick(item);
                        }}
                        key={item.key}
                        style={{
                            padding: '10px 0',
                            cursor: 'pointer',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {item.path}
                    </div>
                );
            })}
        </div>
    );
};
const TreeTitle = ({ node, add, edit, remove }) => {
    const treeRemove = e => {
        remove && remove(node);
        e.stopPropagation();
    };
    const treeAdd = e => {
        add && add(node);
        e.stopPropagation();
    };
    const treeEdit = e => {
        edit && edit(node);
        e.stopPropagation();
    };
    // if (node.level === 1) {
    //     return <span>{node.title}</span>;
    // }
    // const num = 0;
    return (
        <div
            className={treeStyle.title}
            style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
            <div>
                {node.dev_cate ? (
                    <MyIcon type={treeIcon[node.dev_cate]} style={{ fontSize: '1.5rem', verticalAlign: 'middle' }} />
                ) : (
                    <MyIcon type="icon_address" />
                )}
                <span style={{ verticalAlign: 'middle', whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: '95px', display: 'inline-block', overflow: 'hidden',  }}>{' ' + node.title}</span>
                {/* {node.device_num !== undefined ? '(' + selectNum +'/'+(node.device_num === null ? 0 : node.device_num) + ')' : ''} */}
            </div>
            <div className={treeStyle.titleIcon}>
                {node.level === 1 ? (
                    <PlusCircleOutlined onClick={treeAdd} style={{ padding: '0 5px', color: '#1890ff' }} />
                ) : (
                    <>
                        <EditOutlined onClick={treeEdit} style={{ padding: '0 5px', color: '#1890ff' }} />
                        <MinusCircleOutlined onClick={treeRemove} style={{ padding: '0 5px', color: '#1890ff' }} />
                        <PlusCircleOutlined onClick={treeAdd} style={{ padding: '0 5px', color: '#1890ff' }} />
                    </>
                )}
            </div>
        </div>
    );
};
