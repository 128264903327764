/**
 * 硬件管理产品管理
 */
import React, { useRef, useState, useEffect, useImperativeHandle, useMemo } from 'react';
import {
    Table,
    Popconfirm,
    Button,
    Form,
    Input,
    ConfigProvider,
    Select,
    Row,
    Col,
    message,
    Upload,
    Tabs,
    Popover,
    Steps,
    Checkbox,
    Radio,
    InputNumber,
    Space,
} from 'antd';
import { SyncOutlined, LoadingOutlined } from '@ant-design/icons';
import zhCN from 'antd/es/locale/zh_CN';
import moment from 'moment';
import { BreadcrumbCustom } from '../../../components/BreadcrumbCustom';
import { CommonTreeUpdate } from './CommonTreeUpdate';
import { CommonModal } from '../../../components/CommoModal';
import { BASE_URL } from '../../../global';
import ToastHelper from '../../../tools/ToastHelper';
import { omitBy, isUndefined, map, isNull, trim } from 'lodash';
import { MyIcon } from '../../../util/iconfont';
import { isAllEqual } from '../../../util/function-util';
import './adminTablePagination.less';
import {
    getDevice,
    getDeviceDetails,
    addDevice,
    delDevice,
    getDeviceExport,
    getDeviceImport,
    getDeviceCategory,
    getDeviceDetailsIcc,
    getDeviceRefresh,
    getVersion,
    getEnterprise,
    getUpdateStatus,
    getFirmware,
    updateDevice,
    deployCamera,
    upgradeFirmware,
} from '../../../api/adminServe';

const FormItem = Form.Item;
const { TabPane } = Tabs;
const { Step } = Steps;

const HardwareDevices = () => {
    return (
        <div className="container clearfix">
            <BreadcrumbCustom paths={['硬件管理', '产品管理']} className="rumbCustom" />
            <div className="row clearfix" style={{ width: '100%', margin: '1rem 0 0' }}>
                <DevicesTable />
            </div>
        </div>
    );
};

const DevicesTable = () => {
    const childRef = useRef();
    const formChildrenRef = useRef();
    const formChildrenRefDeploy = useRef();
    const searchChildrenRef = useRef();
    const [modalType, setModalType] = useState('add'); //edit add
    const [deviceType, setDeviceType] = useState(0);// 0 基础设备 1 用卡设备
    const [data, setData] = useState({ data: [], isLoading: true });
    const [count, setCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const ref = useRef(null);
    const [page_size, setPageSize] = useState(10);
    const [update, setUpdate] = useState(0);
    const [userId, setUserId] = useState();
    const [category, setCategory] = useState([]);
    const [searchParams, setSearchParams] = useState({}); //搜索功能
    const [selectedKeys, setSelectedKeys] = useState([]); //需要批量升级中选中的版本号集
    const tabSearch = [
        { key: 0, name: '基础设备', model: ['qr_code', 'category_id', 'update_status', 'version', 'enterprise_id'] },
        {
            key: 1,
            name: '用卡设备',
            model: ['qr_code', 'category_id', 'icc_id', 'icc_status', 'update_status', 'version', 'enterprise_id'],
        },
    ];
    // 获取分类ID列表
    useEffect(() => {
        getDeviceCategory({ get_all: true })
            .then(result => {
                setCategory(result.data);
            })
            .catch();
    }, []);
    // 获取产品列表数据
    const userListData = (page, pageSize) => {
        setCurrentPage(page);
        const params = omitBy(searchParams, value => {
            return isUndefined(value) || isNull(value) || value === '' || trim(value) === '';
        });
        getDevice(
            deviceType === 0
                ? { page, page_size: pageSize ? pageSize : page_size, ...params }
                : { page, page_size: pageSize ? pageSize : page_size, ...params, card_category: true },
        )
            .then(result => {
                setData({ data: result.data, isLoading: false });
                setCount(result.count);
            })
            .catch(() => {
                setData({ data: [], isLoading: true });
            });
    };
    useEffect(() => {
        userListData(currentPage);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams, update, deviceType]);
    // 编辑详情
    const getDetail = userId => {
        getDeviceDetails(userId)
            .then(result => {
                formChildrenRef.current &&
                    formChildrenRef.current.setFieldsValue({
                        version: result.data.version,
                        category_id: result.data.category.id,
                        qr_code: result.data.qr_code,
                        service_start_time: result.service_start_time,
                        service_time: result.service_time,
                        // topic_id: result.data.topic_id,
                        
                    });
                formChildrenRefDeploy.current && 
                    formChildrenRefDeploy.current.setFieldsValue({
                        max_voltage: result.data.max_voltage,
                        min_voltage: result.data.min_voltage,
                        low_voltage_up: result.data.low_voltage_up,
                        low_voltage_time: result.data.low_voltage_time,
                        status_store: result.data.status_store,
                        err_store: result.data.err_store,
                        ota_retry: result.data.ota_retry,
                        icc_remainder_flow: result.data.icc_remainder_flow,
                        alarm_up: result.data.alarm_up,
                        alarm_network: result.data.alarm_network,
                        alarm_time: result.data.alarm_time,
                    })
            })
            .catch();
    };
    //导入
    const importFile = params => {
        getDeviceImport({ ...params })
            .then(() => {
                ToastHelper.successToast('导入成功');
                setUpdate(params);
            })
            .catch(() => {
                ToastHelper.errorToast('请检查文件格式');
            });
    };
    useEffect(() => {
        (modalType === 'edit' || modalType === 'deploy') && getDetail(userId);
    }, [userId, modalType]);
    const [isLoadings, setIsLoadings] = useState(false);
    const columns = useMemo(() => {
        return [
            {
                title: '序号',
                dataIndex: 'id',
                key: 'id',
                width: 100, //宽度
                fixed: 'left',
                render: (text, record, index) => `${index + 1}`,
            },
            {
                title: '分类ID',
                dataIndex: 'category',
                render: (text, record) => (
                    <p
                        style={{
                            minWidth: '3rem',
                            maxWidth: '4rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={text === null || text === undefined ? '' : text.id}
                    >
                        {text === null || text === undefined ? '' : text.id}
                    </p>
                ),
            },
            {
                title: '条码(序列号)',
                dataIndex: 'qr_code',
                render: text => (
                    <p
                        style={{
                            minWidth: '10rem',
                            maxWidth: '16rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={text}
                    >
                        {text}
                    </p>
                ),
            },
            {
                title: '固件版本',
                dataIndex: 'version',
                render: text => (
                    <p
                        style={{
                            minWidth: '4rem',
                            maxWidth: '8rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={text}
                    >
                        {text}
                    </p>
                ),
            },
            {
                title: '升级状态',
                dataIndex: 'update_status',
                width: 100,
                render: text => <span>{text}</span>,
            },
            {
                title: 'ICCID',
                dataIndex: 'icc_id',
                render: text => (
                    <p
                        style={{
                            minWidth: '10rem',
                            maxWidth: '16rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={text}
                    >
                        {text}
                    </p>
                ),
            },
            {
                title: 'IMEI',
                dataIndex: 'imei',
                render: text => (
                    <p
                        style={{
                            minWidth: '8rem',
                            maxWidth: '16rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={text}
                    >
                        {text}
                    </p>
                ),
            },
            {
                title: '卡状态',
                dataIndex: 'icc_status_name',
                width: 120,
                render: text => <span>{text}</span>,
            },
            {
                title: '剩余流量(MB)',
                dataIndex: 'icc_remainder_flow',
                width: 120,
                render: text => <span>{text}</span>,
            },
            {
                title: '到期时间',
                dataIndex: 'service_end_time',
                width: 180,
                render: text => <span>{text}</span>,
            },
            {
                title: '关联企业',
                dataIndex: 'enterprise',
                render: text => (
                    <p
                        style={{
                            minWidth: '6rem',
                            maxWidth: '18rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                        }}
                        title={text}
                    >
                        {text}
                    </p>
                ),
            },
            {
                title: '创建时间',
                dataIndex: 'create_time',
                width: 180,
                render: text => <span>{text && text.slice(0, 10) + ' ' + text.slice(11, 19)}</span>,
            },
            {
                title: '操作',
                dataIndex: 'opera',
                width: 200,
                fixed: 'right',
                render: (text, record) => (
                    <div className="opera">
                        {/* <span
                            className="edit"
                            onClick={() => {
                                setModalType('edit');
                                getDetail(record.id)
                                childRef.current.showModal(0);
                                setUserId(record.id);
                            }}
                        >
                            <EditOutlined /> 编辑
                        </span> */}
                        { record.category?.id === 'F901' && deviceType === 1 ? (
                            <span
                                // className="edit"
                                onClick={() => {
                                    setModalType('deploy');
                                    getDetail(record.id);
                                    childRef.current.showModal(0);
                                    setUserId(record.id);
                                }}
                            >
                                <MyIcon
                                    type="iconpeizhi"
                                    title={'配置'}
                                    style={{
                                        color: 'rgba(0, 0, 0, 0.85)',
                                        fontSize: '1.1rem',
                                        verticalAlign: 'middle',
                                    }}
                                />
                            </span>
                        ) : (
                            <span style={{ display: 'inline-block', width: '1.2rem' }}>　</span>
                        )}
                        {deviceType === 1 && (
                            <span
                                onClick={() => {
                                    // setData({ data: null, isLoading: true });
                                    setIsLoadings(true)
                                    getDeviceRefresh({ id: record.id, card_category: true })
                                        .then(result => {
                                            record.icc_remainder_flow = Number(result.data.icc_remainder_flow);
                                            // let newData = data.data.map(item => {
                                            //     if (item.id === result.data.id) {
                                            //         item.icc_remainder_flow = Number(result.data.icc_remainder_flow)+10.0;
                                            //     }
                                            //     return item;
                                            // });
                                            // setData({ data: newData});
                                            setIsLoadings(false)
                                            ToastHelper.successToast('更新剩余流量成功');
                                        })
                                        .catch(() => {
                                            ToastHelper.errorToast('更新剩余流量失败');
                                        });
                                }}
                            >
                            {isLoadings ? <LoadingOutlined spin={true} title={'刷新'}
                                    style={{ color: 'rgba(0, 0, 0, 0.85)', fontSize: '1rem', verticalAlign: 'middle' }}/> :
                                <SyncOutlined
                                    title={'刷新'}
                                    style={{ color: 'rgba(0, 0, 0, 0.85)', fontSize: '1rem', verticalAlign: 'middle' }}
                                />}
                            </span>
                        )}
                        <Popconfirm
                            title="确定要删除吗?"
                            onConfirm={() => {
                                delDevice(record.id)
                                    .then(result => {
                                        message.success('删除成功');
                                        setUpdate(result);
                                        setUserId(record.id);
                                    })
                                    .catch();
                            }}
                        >
                            <MyIcon
                                type="iconshanchu1"
                                style={{
                                    color: 'rgba(0, 0, 0, 0.85)',
                                    fontSize: '1rem',
                                    verticalAlign: 'middle',
                                    margin: '0 0.6rem',
                                }}
                                title={'删除'}
                            />
                        </Popconfirm>
                        <Popover
                            placement="topLeft"
                            title="更新历史"
                            arrowPointAtCenter
                            content={
                                record.firmware_history && record.firmware_history.length > 0 ? (
                                    <Steps
                                        progressDot
                                        current={record.firmware_history && record.firmware_history.length}
                                        direction="vertical"
                                    >
                                        {record.firmware_history &&
                                            record.firmware_history.map(item => {
                                                return (
                                                    <Step
                                                        key={item.version}
                                                        title={item.version}
                                                        description={
                                                            item.time.slice(0, 10) + ' ' + item.time.slice(11, 19)
                                                        }
                                                    />
                                                );
                                            })}
                                    </Steps>
                                ) : (
                                    '无历史信息'
                                )
                            }
                        >
                            <MyIcon
                                type="iconlishijilu"
                                style={{
                                    color: 'rgba(0, 0, 0, 0.85)',
                                    fontSize: '1.2rem',
                                    verticalAlign: 'middle',
                                    margin: '0 0.6rem',
                                }}
                                title={'更新历史'}
                            />
                        </Popover>
                    </div>
                ),
            },
        ];
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, deviceType]);
    //基础设备列头
    let columnsFind = columns.filter(
        item =>
            item.title !== 'ICCID' &&
            item.title !== '卡状态' &&
            item.title !== '剩余流量(MB)' &&
            item.title !== '到期时间' &&
            item.title !== 'IMEI',
    );
    // 基础设备和用卡设备切换
    const callback = key => {
        setDeviceType(Number(key));
        //切换基础和用卡设备时，重置搜索框和搜索列表
        setSearchParams({});
        searchChildrenRef.current.setFieldsValue({
            qr_code: null,
            category_id: null,
            icc_status: null,
            icc_id: null,
            version: null,
            enterprise_id: null,
            update_status: null,
        });
    };
    // 分页配置
    const paginationNo = {
        pageSize: page_size, //每页的条数
        current: currentPage, //当前页
        total: count, //总数据
        showSizeChanger: true,
        onChange: (page, pageSize) => {
            ref.current = page;
            userListData(page, pageSize);
        }, //点击分页号时触发的方法
        onShowSizeChange: (current, pageSize) => {
            setPageSize(pageSize);
        }, //pageSize 变化的回调
        hideOnSinglePage: true, //为true则数据条数不满或刚好一页，则隐藏分页器
        pageSizeOptions: ['10', '15', '20', '30', '50', '100'], // 分页类别
        showTotal: total => `共 ${total} 条`,
    };
    const [selectedRowKeys, setSelectedRowKeys] = useState([]); //获取选中表格行ID
    const [selectedRows, setSelectedRows] = useState([]); //获取选中表格行的分类ID
    const [checkedKeys, setCheckedKeys] = useState();
    const searchBar = useRef();
    const [versionTreeData, setVersionTreeData] = useState();
    const [searchState, setSearchState] = useState(false);
    const [btnId, setBtnId] = useState();
    // 单选
    const onSelectChange = (selectedRowKeys, selectedRows) => {
        setSelectedRowKeys(selectedRowKeys);
        setSelectedRows(
            selectedRows.map((value, index) => {
                return value.category.id;
            }),
        );
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    /**
     * 全选(批量升级)
     * @param e
     */
    const onCheckAllChange = e => {
        setSelectedRowKeys(e.target.checked ? data.data.map((item, index) => item.id) : []);
        setSelectedRows(
            e.target.checked
                ? data.data.map((value, index) => {
                      return value.category.id;
                  })
                : [],
        );
    };
    return (
        <div className="tableBox">
            <Row>
                <Col span={12} className="gutter-row">
                    <Tabs defaultActiveKey={deviceType} onChange={callback} type="card">
                        {map(tabSearch, item => {
                            return <TabPane tab={item.name} key={item.key}></TabPane>;
                        })}
                    </Tabs>
                </Col>
                <Col span={12} className="gutter-row" style={{ textAlign: 'right' }}>
                    <Button
                        type="primary"
                        onClick={() => {
                            setModalType('add');
                            childRef.current.showModal();
                            if (formChildrenRef.current !== undefined || formChildrenRef.current !== null) {
                                formChildrenRef.current && formChildrenRef.current.resetFields();
                            }
                        }}
                        style={{ marginRight: '15px' }}
                    >
                        新增
                    </Button>
                    <Upload
                        customRequest={file => {
                            importFile({ file: file.file });
                        }}
                        accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        showUploadList={false}
                    >
                        <Button onClick={() => {}} type="primary" style={{ marginRight: '10px', marginBottom: '10px' }}>
                            导入
                        </Button>
                    </Upload>
                    <Button
                        type="primary"
                        onClick={() => {
                            const params = omitBy(searchParams, value => {
                                return isUndefined(value) || isNull(value) || value === '' || trim(value) === '';
                            });
                            getDeviceExport({ ...params })
                                .then(result => {
                                    window.open(BASE_URL + result.data.url);
                                })
                                .catch();
                        }}
                        style={{ color: '#fff', backgroundColor: '#439BE3', borderColor: '#439BE3' }}
                    >
                        导出
                    </Button>
                </Col>
                <Col span={24} className="gutter-row">
                    <SearchForm
                        setSearchParams={setSearchParams}
                        projectEquipment={tabSearch[deviceType].model}
                        category={category}
                        childRef={searchChildrenRef}
                    />
                </Col>
            </Row>
            <ConfigProvider locale={zhCN}>
                <Table
                    rowKey={record => record.id}
                    rowSelection={rowSelection}
                    pagination={paginationNo}
                    columns={deviceType === 0 ? columnsFind : columns}
                    loading={data.isLoading}
                    dataSource={data.data}
                    bordered={true}
                    scroll={{ x: deviceType === 0 ? '100%' : 2100 }}
                    className="formTable"
                    style={{ textAlign: 'center', position: 'relative' }}
                />
            </ConfigProvider>
            <div
                style={
                    data.data && data.data.length > 0 ? { position: 'inherit', marginTop: '1rem' } : { display: 'none' }
                }
            >
                <Checkbox
                    indeterminate={
                        //负责样式控制
                        data.data && data.data.length !== selectedRowKeys.length && selectedRowKeys.length !== 0
                    }
                    onChange={onCheckAllChange} //勾选时触发的方法
                    checked={data.data && data.data.length === selectedRowKeys.length} //勾选还是未勾选  options：所有的勾选子项，list
                >
                    全选
                </Checkbox>

                <Radio.Group style={{ marginBottom: 16 }}>
                    <Radio.Button
                        value="off"
                        style={{
                            display: 'inline-block',
                            width: '60px',
                            padding: 0,
                            textAlign: 'center',
                            background: '#439BE3',
                            border: '1px solid #439BE3',
                            color: '#fff',
                            borderRadius: '5px',
                            marginRight: '1rem',
                        }}
                    >
                        <Popconfirm
                            title={
                                selectedRowKeys.length === 0 ? '未选中' : `确定要将${selectedRowKeys.length}项,自动升级吗?`
                            }
                            onConfirm={value => {
                                setBtnId(0)
                                if (selectedRowKeys.length === 0) {
                                    message.warning('请先选中设备');
                                } else {
                                    if (!isAllEqual(selectedRows)) {
                                        message.warning('仅支持相同分类ID的设备批量升级');
                                    } else {
                                        setModalType('update');
                                        childRef.current.showModal();
                                        setSearchState(false);
                                        selectedRows.length > 0 &&
                                            getFirmware({ category: selectedRows[0] })
                                                .then(result => {
                                                    let newData = [];
                                                    // eslint-disable-next-line array-callback-return
                                                    result.data.map(item => {
                                                        newData.push({ id: item.id, name: item.name });
                                                    });
                                                    setVersionTreeData(newData);
                                                })
                                                .catch(() => {});
                                    }
                                }
                            }}
                        >
                            <p>自动升级</p>
                        </Popconfirm>
                    </Radio.Button>
                    <Radio.Button
                        value="off"
                        style={{
                            display: 'inline-block',
                            width: '60px',
                            padding: 0,
                            textAlign: 'center',
                            background: '#64D399',
                            border: '1px solid #64D399',
                            color: '#fff',
                            borderRadius: '5px',
                        }}
                    >
                        <Popconfirm
                            title={
                                selectedRowKeys.length === 0 ? '未选中' : `确定要将${selectedRowKeys.length}项,自主升级吗?`
                            }
                            onConfirm={value => {
                                setBtnId(1)
                                if (selectedRowKeys.length === 0) {
                                    message.warning('请先选中设备');
                                } else {
                                    if (!isAllEqual(selectedRows)) {
                                        message.warning('仅支持相同分类ID的设备批量升级');
                                    } else {
                                        setModalType('update');
                                        childRef.current.showModal();
                                        setSearchState(false);
                                        selectedRows.length > 0 &&
                                            getFirmware({ category: selectedRows[0] })
                                                .then(result => {
                                                    let newData = [];
                                                    // eslint-disable-next-line array-callback-return
                                                    result.data.map(item => {
                                                        newData.push({ id: item.id, name: item.name });
                                                    });
                                                    setVersionTreeData(newData);
                                                })
                                                .catch(() => {});
                                    }
                                }
                            }}
                        >
                            <p>自主升级</p>
                        </Popconfirm>
                    </Radio.Button>
                </Radio.Group>
            </div>
            <CommonModal
                searchBar={searchBar}
                setSearchState={setSearchState} //默认状态为false
                onCancel={() => {
                    searchBar.current && (searchBar.current.state.value = ''); //重置批量升级中的搜索框
                    setCheckedKeys([]); //批量升级中的版本号清空选中状态
                    setSearchState(true); //批量升级中取消再次进入显示默认的列表数据组件，而不是搜索后的列表数据组件
                }}
                onOk={() => {
                    if (modalType === 'update') {
                        if (selectedKeys.length === 0) {
                            message.warning('请选择版本号');
                        } else {
                            if(btnId === 0){
                                updateDevice({ firmware: Number(selectedKeys[0]), device_list: selectedRowKeys })
                                .then(() => {
                                    message.success('自动升级成功');
                                    searchBar.current.state.value = ''; //重置批量升级中的搜索框
                                    childRef.current.hiddenModal();
                                    setCheckedKeys([]); //批量升级中的版本号清空选中状态
                                })
                                .catch();
                            }else{
                                upgradeFirmware({firmware_id: Number(selectedKeys[0]), device_list: selectedRowKeys})
                                    .then(() => {
                                        message.success('自主升级成功');
                                        searchBar.current.state.value = ''; //重置批量升级中的搜索框
                                        childRef.current.hiddenModal();
                                        setCheckedKeys([]); //批量升级中的版本号清空选中状态
                                    })
                                    .catch();
                            }
                        }
                    }
                    if (modalType === 'deploy') {
                        formChildrenRefDeploy.current
                            .validateFields()
                            .then(value => {
                                //do something
                                //TODO 发起http请求提交当前配置
                                if (modalType === 'deploy') {
                                    deployCamera({ ...value, id: userId })
                                        .then(() => {
                                            message.success('配置成功');
                                            childRef.current.hiddenModal();
                                            formChildrenRefDeploy.current.resetFields();
                                        })
                                        .catch(() => {
                                            message.error('配置失败');
                                        });
                                }
                            })
                            .catch();
                    } else {
                        formChildrenRef.current && formChildrenRef.current
                            .validateFields()
                            .then(value => {
                                //do something
                                //TODO 发起http请求提交新增
                                if (modalType === 'add') {
                                    value.service_start_time =
                                        value.service_start_time &&
                                        moment(value.service_start_time).format('YYYY-MM-DD');
                                    addDevice(value)
                                        .then(result => {
                                            message.success('新增成功');
                                            childRef.current.hiddenModal();
                                            setUpdate(result);
                                            formChildrenRef.current.resetFields();
                                        })
                                        .catch();
                                }
                                //TODO 发起http请求提交编辑时的逻辑
                                // value.id = userId
                                // changeDevice(value)
                                //     .then(result => {
                                //         message.success('编辑成功');
                                //         childRef.current.hiddenModal();
                                //         setUpdate(result);
                                //         formChildrenRef.current.resetFields();
                                //     })
                                //     .catch();
                            })
                            .catch();
                    }
                }}
                childRef={childRef}
                title={
                    modalType === 'deploy'
                        ? '配置'
                        : modalType === 'update'
                        ? '选择固件'
                        : `${modalType === 'edit' ? '编辑' : '新增'}产品`
                }
            >
                {modalType === 'update' ? (
                    <ModelUpdate
                        setSelectedKeys={setSelectedKeys}
                        selectedRows={selectedRows}
                        setCheckedKeys={setCheckedKeys}
                        checkedKeys={checkedKeys}
                        searchBar={searchBar}
                        versionTreeData={versionTreeData}
                        setVersionTreeData={setVersionTreeData}
                        searchState={searchState}
                        setSearchState={setSearchState}
                    />
                ) : modalType === 'deploy' ? (
                    <Configure type={modalType} childRef={formChildrenRefDeploy} />
                ) : (
                    <ModelForm childRef={formChildrenRef} type={modalType} category={category} />
                )}
            </CommonModal>
        </div>
    );
};

//新增、编辑
const ModelForm = ({ childRef, type, category }) => {
    const [form] = Form.useForm();
    const FormItemLayout = {
        labelCol: { span: 5 },
        wrapperCol: { span: 16 },
    };
    useImperativeHandle(childRef, () => ({
        validateFields: form.validateFields,
        resetFields: form.resetFields,
        setFieldsValue: form.setFieldsValue,
    }));
    const [gateMagnetism, setGateMagnetism] = useState(false); //判断是否为门磁设备，需要显示IMEI,不需要显示激活时间和服务时长
    return (
        <Form layout="horizontal" form={form}>
            <Form.Item
                label="分类ID"
                {...FormItemLayout}
                name="category_id"
                rules={[{ required: true, message: '请选择分类ID！' }]}
            >
                <Select
                    placeholder="请选择分类ID"
                    virtual={false}
                    showArrow
                    allowClear
                    onChange={value => {
                        setGateMagnetism(value === 'E100' ? true : false); //分类ID为E100(外购门磁)新增时需增加IMEI编码
                    }}
                >
                    {category &&
                        category.map(item => {
                            return (
                                <Select.Option value={item.id} key={item.id}>
                                    {item.id}
                                </Select.Option>
                            );
                        })}
                </Select>
            </Form.Item>
            <FormItem
                name="qr_code"
                label="条码"
                {...FormItemLayout}
                rules={[{ required: true, message: '请输入条码！' }]}
                getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}
            >
                <Input disabled={type === 'edit' ? true : false} placeholder="请输入条码" />
            </FormItem>
            <FormItem
                label="固件版本"
                name="version"
                {...FormItemLayout}
                rules={[{ required: true, message: '请输入固件版本！' }]}
                getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}
            >
                <Input placeholder="请输入固件版本" />
            </FormItem>
            {gateMagnetism && (
                <FormItem
                    label="IMEI"
                    name="imei"
                    {...FormItemLayout}
                    rules={[
                        { required: true, message: '请输入IMEI编码！' },
                        { pattern: new RegExp(/^[0-9]\d*$/, 'g'), message: '请输入数字' },
                    ]}
                    getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}
                >
                    <Input placeholder="请输入IMEI编码！" />
                </FormItem>
            )}
            {/* {!gateMagnetism && <FormItem
                label="激活时间"
                name="service_start_time"
                {...FormItemLayout}
            >
                <DatePicker style={{width: '100%'}} format={'YYYY-MM-DD'}/>
            </FormItem>}
            {!gateMagnetism && <FormItem
                label="服务时长"
                // name="service_time"
                {...FormItemLayout}
            >
                <Form.Item name="service_time" rules={[{ required: false, message: '请输入服务时长！' }]} noStyle>
                    <InputNumber placeholder="请选择/输入服务时长" style={{width: '90%'}} min={1} step={1} precision={0}/>
                </Form.Item>
                <span className="ant-form-text"> 年</span>
            </FormItem>} */}
            {/* <FormItem
                label="通信ID"
                name="topic_id"
                {...FormItemLayout}
                rules={[{ required: true, message: '请输入通信ID！' }]}
            >
                <Input disabled={type === 'edit' ? true : false} />
            </FormItem> */}
        </Form>
    );
};

// 升级
const ModelUpdate = ({
    setSelectedKeys,
    selectedRows,
    checkedKeys,
    setCheckedKeys,
    searchBar,
    versionTreeData,
    setVersionTreeData,
    searchState,
    setSearchState,
}) => {
    useEffect(() => {
        selectedRows.length > 0 &&
            getFirmware({ category: selectedRows[0] })
                .then(result => {
                    let newData = [];
                    // eslint-disable-next-line array-callback-return
                    result.data.map(item => {
                        newData.push({ id: item.id, name: item.name });
                    });
                    setVersionTreeData(newData);
                })
                .catch(() => {});
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRows, setSelectedKeys]);
    return (
        <CommonTreeUpdate
            height={'10vh'}
            treeData={versionTreeData}
            onClick={value => {
                setSelectedKeys(value); //选中分类ID
            }}
            setCheckedKeys={setCheckedKeys} //版本号前面的多选框事件更新
            checkedKeys={checkedKeys} //版本号前面的多选框选中数据
            selectedRows={selectedRows} //选中的产品分类ID
            searchBar={searchBar} //解决搜索后，下一次进入的搜索框重置问题
            searchState={searchState} //默认值为false 显示搜索前的版本号列表(点击升级按钮，弹框中的关闭、取消按钮改变值为false)、true 显示搜索后的版本号列表（点击搜索按钮改变为true）
            setSearchState={setSearchState} //解决搜索后，下一次进入初始化版本列表
        />
    );
};

//搜索
const SearchForm = ({ setSearchParams, projectEquipment, category, childRef }) => {
    const [form] = Form.useForm();
    useImperativeHandle(childRef, () => ({
        validateFields: form.validateFields,
        setFieldsValue: form.setFieldsValue,
    }));
    const [iccStatus, setIccStatus] = useState(); //获取卡状态
    const [version, setVersion] = useState(); //获取固件版本
    const [enterprise, setEnterprise] = useState(); //获取关联公司
    const [updateStatus, setUpdateStatus] = useState(); //获取升级状态
    return (
        <Form
            form={form}
            onFinish={value => {
                setSearchParams(value);
            }}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
            className={'hardwareDevice'}
        >
            {projectEquipment.indexOf('category_id') !== -1 && (
                <FormItem label="分类ID" name="category_id">
                    <Select
                        placeholder="请选择分类ID"
                        virtual={false}
                        showArrow
                        allowClear
                        style={{ minWidth: '3rem', width: '10rem' }}
                    >
                        {category &&
                            category.map(item => {
                                return (
                                    <Select.Option value={item.id} key={item.id}>
                                        {item.id}
                                    </Select.Option>
                                );
                            })}
                    </Select>
                </FormItem>
            )}
            {projectEquipment.indexOf('qr_code') !== -1 && (
                <FormItem
                    label="条码"
                    name="qr_code"
                    style={{ margin: '0 0.5rem' }}
                    getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}
                >
                    <Input placeholder="请输入要搜索的条码" style={{ minWidth: '5rem', width: '12rem' }} />
                </FormItem>
            )}
            {projectEquipment.indexOf('version') !== -1 && (
                <FormItem label="固件版本" name="version">
                    <Select
                        placeholder="请选择固件版本"
                        virtual={false}
                        showArrow
                        allowClear
                        onFocus={() => {
                            getVersion()
                                .then(result => {
                                    setVersion(result.data);
                                })
                                .catch(() => {});
                        }}
                        style={{ minWidth: '3rem', width: '12rem' }}
                    >
                        {version &&
                            version.map(item => {
                                return (
                                    <Select.Option value={item[0]} key={item[0]}>
                                        {item[0]}
                                    </Select.Option>
                                );
                            })}
                    </Select>
                </FormItem>
            )}
            {projectEquipment.indexOf('update_status') !== -1 && (
                <FormItem label="升级状态 " name="update_status" style={{ margin: '0 0.5rem' }}>
                    <Select
                        placeholder="请选择升级状态"
                        virtual={false}
                        showArrow
                        allowClear
                        onFocus={() => {
                            getUpdateStatus()
                                .then(result => {
                                    setUpdateStatus(result.data);
                                })
                                .catch(() => {});
                        }}
                        style={{ minWidth: '3rem', width: '6rem' }}
                    >
                        {updateStatus &&
                            updateStatus.map(item => {
                                return (
                                    <Select.Option value={item[0]} key={item[0]}>
                                        {item[1]}
                                    </Select.Option>
                                );
                            })}
                    </Select>
                </FormItem>
            )}
            {projectEquipment.indexOf('icc_id') !== -1 && (
                <FormItem
                    label="ICCID"
                    name="icc_id"
                    getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}
                >
                    <Input placeholder="请输入要搜索的ICCID" style={{ minWidth: '5rem', width: '10rem' }} />
                </FormItem>
            )}
            {projectEquipment.indexOf('icc_status') !== -1 && (
                <FormItem label="卡状态 " name="icc_status" style={{ margin: '0 0.5rem' }}>
                    <Select
                        placeholder="请选择卡状态"
                        virtual={false}
                        showArrow
                        allowClear
                        onFocus={() => {
                            getDeviceDetailsIcc()
                                .then(result => {
                                    setIccStatus(result.data);
                                })
                                .catch(() => {});
                        }}
                        style={{ minWidth: '3rem', width: '8rem' }}
                    >
                        {iccStatus &&
                            iccStatus.map(item => {
                                return (
                                    <Select.Option value={item[0]} key={item[0]}>
                                        {item[1]}
                                    </Select.Option>
                                );
                            })}
                    </Select>
                </FormItem>
            )}
            {projectEquipment.indexOf('enterprise_id') !== -1 && (
                <FormItem label="关联企业" name="enterprise_id" style={{ margin: '0 1rem 0 0' }}>
                    <Select
                        placeholder="请选择关联企业"
                        virtual={false}
                        showArrow
                        allowClear
                        onFocus={() => {
                            getEnterprise()
                                .then(result => {
                                    setEnterprise(result.data);
                                })
                                .catch(() => {});
                        }}
                        style={{ minWidth: '3rem', width: '10rem' }}
                    >
                        {enterprise &&
                            enterprise.map(item => {
                                return (
                                    <Select.Option value={item.id} key={item.id}>
                                        {item.name}
                                    </Select.Option>
                                );
                            })}
                    </Select>
                </FormItem>
            )}
            {/* <FormItem
                    label="型号"
                    name="category_name"
                    style={{ margin: '0 2rem' }}
                    getValueFromEvent={event => event.target.value.replace(/\s+/g, '')}
                >
                    <Input placeholder="请输入要搜索的型号" />
                </FormItem> */}
            <Button type="primary" htmlType="submit" style={{ marginBottom: '24px' }}>
                搜索
            </Button>
        </Form>
    );
};

const Configure = ({ childRef, type }) => {
    const [form] = Form.useForm();
    const FormItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 },
    };
    useImperativeHandle(childRef, () => ({
        validateFields: form.validateFields,
        resetFields: form.resetFields,
        setFieldsValue: form.setFieldsValue,
    }));
    return (
        <Form layout="horizontal" form={form}>
            <FormItem label={'最小工作电压'} {...FormItemLayout}>
                <Space>
                    <FormItem name="min_voltage" noStyle>
                        <InputNumber
                            min={1}
                            max={99999999}
                            precision={0}
                            placeholder="请选择最小工作电压"
                            style={{ width: '250px' }}
                        />
                    </FormItem>
                    <span>mV</span>
                </Space>
            </FormItem>
            <FormItem label={'最大工作电压'} {...FormItemLayout}>
                <Space>
                    <FormItem name="max_voltage" noStyle>
                        <InputNumber
                            min={1}
                            max={99999999}
                            precision={0}
                            placeholder="请选择最大工作电压"
                            style={{ width: '250px' }}
                        />
                    </FormItem>
                    <span>mV</span>
                </Space>
            </FormItem>
            <FormItem label={'低电量状态上报间隔'} {...FormItemLayout}>
                <Space>
                    <FormItem name="low_voltage_time" noStyle>
                        <InputNumber
                            min={1}
                            max={99999999}
                            precision={0}
                            placeholder="请选择低电量状态上报间隔"
                            style={{ width: '250px' }}
                        />
                    </FormItem>
                    <span>S</span>
                </Space>
            </FormItem>
            <FormItem name="low_voltage_up" label="低电量状态上报次数" {...FormItemLayout}>
                <InputNumber
                    min={1}
                    max={99999999}
                    precision={0}
                    placeholder="请输入低电量状态上报次数"
                    style={{ width: '250px' }}
                />
            </FormItem>
            <FormItem label={'设备故障上报间隔'} {...FormItemLayout}>
                <Space>
                    <FormItem name="alarm_time" noStyle>
                        <InputNumber
                            min={1}
                            max={99999999}
                            precision={0}
                            placeholder="请输入设备故障上报间隔"
                            style={{ width: '250px' }}
                        />
                    </FormItem>
                    <span>S</span>
                </Space>
            </FormItem>
            <Form.Item label="设备故障上报次数" {...FormItemLayout} name="alarm_up">
                <InputNumber
                    min={1}
                    precision={0}
                    disabled={type === 'edit' ? true : false}
                    placeholder="请输入设备故障上报次数"
                    style={{ width: '250px' }}
                />
            </Form.Item>
            <FormItem label={'断网时尝试间隔'} {...FormItemLayout}>
                <Space>
                    <FormItem name="alarm_network" noStyle>
                        <InputNumber
                            min={1}
                            max={99999999}
                            precision={0}
                            placeholder="请输入断网时尝试间隔"
                            style={{ width: '250px' }}
                        />
                    </FormItem>
                    <span>S</span>
                </Space>
            </FormItem>
            <FormItem label="状态信息存储上限" name="status_store" {...FormItemLayout}>
                <InputNumber
                    min={1}
                    max={99999999}
                    precision={0}
                    placeholder="请输入状态信息存储上限"
                    style={{ width: '250px' }}
                />
            </FormItem>
            <FormItem label="状态信息存储下限" name="err_store" {...FormItemLayout}>
                <InputNumber
                    min={1}
                    max={99999999}
                    precision={0}
                    placeholder="请输入状态信息存储下限"
                    style={{ width: '250px' }}
                />
            </FormItem>
            <FormItem label="OTA重试次数" name="ota_retry" {...FormItemLayout}>
                <InputNumber
                    min={1}
                    max={99999999}
                    precision={0}
                    placeholder="请输入OTA重试次数"
                    style={{ width: '250px' }}
                />
            </FormItem>
        </Form>
    );
};
export default HardwareDevices;
