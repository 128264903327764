import React, { useState, useEffect } from 'react';
import { Form, Button, message, Row, Col, Radio, Space } from 'antd';
import { BreadcrumbCustom } from '../../../components/BreadcrumbCustom';
import { changeAlarmSets, getAlarmSets, getGradeCategory } from '../../../api/webServe';
import { EmptyView } from '../../../components/EmptyView';
import { omitBy, isUndefined, isNull } from 'lodash';

const FormItem = Form.Item;

/**
 * 隐患设置组件
 * @returns
 */
const HiddenDanger = () => {
    return (
        <div className="container clearfix">
            <BreadcrumbCustom paths={['首页', '系统设置', '隐患设置']} className="rumbCustom" />
            <div
                className="row clearfix"
                style={{
                    background: '#fff',
                    margin: '1rem',
                    borderRadius: '10px',
                    width: '98%',
                    padding: '0',
                }}
            >
                <RenderForm />
            </div>
        </div>
    );
};

/**
 * 事件类型和级别设置
 * @returns
 */
const RenderForm = () => {
    const [alarmSet, setAlarmSet] = useState([]); //告警类型
    const [alarmGrade, setAlarmGrade] = useState([]); //告警等级
    const notice = [{id:true,name: '是'},{id:false, name: '否'}]
    const layout = {
        wrapperCol: { offset: 12, span: 12 },
    };
    const [form] = Form.useForm();
    /**
     * 点击保存设置提交事件
     * @param {*} values 隐患表单值
     */
    const onFinish = values => {
        const params = omitBy(values, value => {
            return isUndefined(value) || isNull(value) || value === '';
        });
        let data = [];
        let isExhibit = []
        for (let key in params) {
            if(key.slice(0,10) === 'is_exhibit'){
                isExhibit.push({ is_exhibit: params[key]})
            }else{
                data.push({ id: Number(key),grade: params[key]})
            }
        }
        // eslint-disable-next-line array-callback-return
        data.map((item,index) => {
            item.is_exhibit = isExhibit[index].is_exhibit
        })
        changeAlarmSets({ data: data })
            .then(() => {
                message.success('保存成功');
            })
            .catch();
    };
    /**
     * 事件类型 （alarmSet）向后端发送请求
     */
    useEffect(() => {
        getAlarmSets()
            .then(result => {
                setAlarmSet(result.data);
            })
            .catch();
        getGradeCategory()
            .then(result => {
                setAlarmGrade(result.data);
            })
            .catch();
    }, []);
    return (
        <Form layout="horizontal" form={form} scrollToFirstError onFinish={onFinish} style={{ width: '100%' }}>
            <Row
                style={{
                    width: '100%',
                    height: '50px',
                    lineHeight: '50px',
                    paddingBottom: '1rem',
                    boxShadow: '0px 2px 3px #e7ebeb',
                    background: 'linear-gradient(180deg, #fefefe 0%, #f5f6fa 100%)',
                    borderTopLeftRadius: '10px',
                    borderTopRightRadius: '10px',
                    marginBottom: '1rem',
                }}
            >
                <Col span={6} style={{ textAlign: 'center', borderRight: '1px solid #f1f1f1' }}>
                    <span style={{ fontWeight: 500, fontSize: '1.25rem', color: '#333333' }}>事件类型</span>
                </Col>
                <Col span={12} style={{ textAlign: 'center' }}>
                    <span style={{ fontWeight: 500, fontSize: '1.25rem', color: '#333333' }}>级别设置</span>
                </Col>
                <Col span={6} style={{ textAlign: 'center' }}>
                    <span style={{ fontWeight: 500, fontSize: '1.25rem', color: '#333333' }}>是否通知</span>
                </Col>
            </Row>
            {alarmSet.length === 0 ? (
                <EmptyView />
            ) : (
                alarmSet.map(item => {
                    return (
                        <Row style={{ width: '100%' }} key={item.id}>
                            <Col span={6} style={{ textAlign: 'center', fontSize: '1rem' }}>
                                {item.name}
                            </Col>
                            <Col span={12} style={{ textAlign: 'center' }}>
                                <Form.Item name={item.id} initialValue={item.grade} style={{marginBottom: '1rem'}}>
                                    <Radio.Group value={item.grade} label={''}>
                                        <Space direction="horizontal" value={item.grade}>
                                            {alarmGrade.length > 0 && alarmGrade.map(item => {
                                                return (
                                                    <Radio value={item[0]} key={item[0]}>
                                                        {item[1]}
                                                    </Radio>
                                                );
                                            })}
                                        </Space>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={6} style={{ textAlign: 'center' }}>
                                <Form.Item name={'is_exhibit'+item.id} initialValue={item.is_exhibit} style={{marginBottom: '1rem'}}>
                                    <Radio.Group value={item.is_exhibit} label={''}>
                                        <Space direction="horizontal" value={item.is_exhibit}>
                                            {notice.map(item => {
                                                return (
                                                    <Radio value={item.id} key={item.id}>
                                                        {item.name}
                                                    </Radio>
                                                );
                                            })}
                                        </Space>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                    );
                })
            )}
            <FormItem {...layout} style={{paddingBottom: '0.5rem', marginBottom: '1rem'}}>
                {alarmSet.length !== 0 && (
                    <Button type="primary" htmlType="submit" style={{ textAlign: 'center', marginTop: '0.5rem' }}>
                        保存设置
                    </Button>
                )}
            </FormItem>
        </Form>
    );
};
export default HiddenDanger;
