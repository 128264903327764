import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import { Tree, Input, Checkbox } from 'antd';
import { map, get, isEmpty, omit } from 'lodash';
import treeStyle from './commonTree.module.less';
import { searchArea } from '../api/webServe';
import { Loading } from './Loading';
import { EmptyView } from './EmptyView';
import { MyIcon } from '../util/iconfont';
import { treeIcon } from '../global';
const { Search } = Input;
// const getParentKey = (key, tree) => {
//     let parentKey;
//     for (let i = 0; i < tree.length; i++) {
//         const node = tree[i];
//         if (node.children) {
//             if (node.children.some(item => item.key === key)) {
//                 parentKey = node.key;
//             } else if (getParentKey(key, node.children)) {
//                 parentKey = getParentKey(key, node.children);
//             }
//         }
//     }
//     return parentKey;
// };

/**
 *
 * @param treeData passed data used to display
 * @param onClick
 * @param operable
 * @param add
 * @param edit
 * @param remove
 * @param getNode transfer pass treeData to {id:any,name:any} format and callback
 * @param getAllTree
 * @param updateNode
 * @returns {JSX.Element}
 * @constructor
 */
export const CommonTreeSwitch = ({
    childRef,
    treeData = [],
    onClick,
    operable = true,
    add,
    edit,
    remove,
    getNode,
    getAllTree,
    updateNode,
}) => {
    const [searchValue, setSearchValue] = useState('');
    const [searchResult, setSearchResult] = useState({ isLoading: true, data: null });
    // const [selectedKey, setSelectedKey] = useState('');
    const [dataList, setDataList] = useState([]);
    const finalValueBackup = useRef([]);
    useEffect(() => {
        finalValueBackup.current = JSON.parse(JSON.stringify(dataList));
        // console.log('find data list change internal : '+JSON.stringify(dataList));
    }, [dataList]);
    const finalTreeNode = () => {
        // console.log('invoke get final tree : '+JSON.stringify(dataList));
        // console.log('invoke get final tree(backup) : '+JSON.stringify(finalValueBackup.current));
        return finalValueBackup.current;
    };
    useImperativeHandle(childRef, () => ({
        finalTree: finalTreeNode,
        setDataList: setDataList,
    }));
    useEffect(() => {
        const nodeList = [];
        const generateList = data => {
            return map(data, item => {
                const { id, name, selectNum } = item;
                nodeList.push({ id, name });
                return {
                    ...item,
                    key: id,
                    title: name,
                    children: generateList(item.children),
                    selectNum: selectNum,
                    totalEquipment: 0,
                };
            });
        };
        const list = generateList(treeData);
        getNode && getNode(nodeList);
        getAllTree && getAllTree(list);
        // console.log('maybe change data list ccondition1 :'+JSON.stringify(list));
        setDataList(list);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [treeData]);

    useEffect(() => {
        if (isEmpty(updateNode)) {
            return;
        }
        const generateList = data => {
            return map(data, item => {
                if (item.id === updateNode.id) {
                    return updateNode;
                }
                return { ...item, children: generateList(item.children) };
            });
        };
        const list = generateList(dataList);
        // console.log("debug (detected data list change) :"+JSON.stringify(list));
        setDataList(list);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateNode]);

    const onDrop = (event, node, dragNode, dragNodesKeys) => {
        // console.log(event);
        // console.log(node);
        // console.log(dragNode);
        // console.log(dragNodesKeys, 'dragNodesKeys');
    };
    //点击树节点触发
    const onSelect = (selectedKeys, info) => {
        // console.log(info, '!!!!!!!!!!!!');
        onClick && onClick(info.node);
        // console.log(selectedKeys, 'selectedKeys');
        // setSelectedKey(selectedKeys);
    };
    return dataList.length > 0 ? (
        <div className={treeStyle.container}>
            <Search
                style={{ marginBottom: 8 }}
                placeholder="请输入"
                allowClear
                onSearch={value => {
                    setSearchValue(`${value}`);
                    setSearchResult({ ...searchResult, isLoading: true });
                    searchArea({ name: value })
                        .then(result => {
                            // console.log(result,'result')
                            result = map(result.data, item => {
                                let path = '';
                                map(get(item, 'routes', []), area => {
                                    path += `${area.name}>`;
                                });
                                path = path.substr(0, path.length - 1);
                                return { ...omit(item, ['routes']), path };
                            });
                            setSearchResult({ data: result, isLoading: false });
                        })
                        .catch(() => {
                            setSearchResult({ data: null, isLoading: false });
                        });
                }}
            />
            {searchValue ? (
                <Loading show={searchResult.isLoading}>
                    {isEmpty(searchResult.data) ? (
                        <EmptyView />
                    ) : (
                        <SearchTemplate data={searchResult.data} onClick={onClick} />
                    )}
                </Loading>
            ) : (
                <Tree
                    onDrop={onDrop}
                    onSelect={onSelect}
                    // onCheck={onSelect}
                    treeData={dataList}
                    defaultExpandedKeys={['1233']} //默认展开指定的树节点
                    defaultSelectedKeys={['1233']} //默认选中的树节点
                    // multiple
                    // defaultSelectedKeys={[!isEmpty(treeId.name) ? treeId && treeId.name : null]}
                    //checkable={true}
                    //checkedKeys={selectedKey} //选中的key
                    titleRender={
                        operable ? node => <TreeTitle add={add} edit={edit} remove={remove} node={node} /> : null
                    }
                />
            )}
        </div>
    ) : (
        <div /*style={{height: '76vh'}}*/>
            <EmptyView />
        </div>
    );
};
const SearchTemplate = ({ data, onClick }) => {
    return (
        <div style={{ padding: '0 10px 10px 10px' }}>
            {map(data, item => {
                return (
                    <div
                        onClick={() => {
                            onClick(item);
                        }}
                        key={item.key}
                        style={{ padding: '10px 0', cursor: 'pointer' }}
                    >
                        {item.path}
                    </div>
                );
            })}
        </div>
    );
};
const TreeTitle = ({ node }) => {
    var indeterminates;
    // if (node.selectNum !== node.totalEquipment && node.selectNum > 0) {
    if (node.selectNum !== node.device_num && node.selectNum > 0) {
        indeterminates = true;
    }
    return (
        <div
            className={treeStyle.title}
            style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
            <div>
                <Checkbox
                    indeterminate={indeterminates}
                    // checked={node.selectNum === node.totalEquipment && node.selectNum > 0 ? true : false}
                    checked={node.selectNum === node.device_num && node.selectNum > 0 ? true : false}
                />
                {node.dev_cate ? <MyIcon type={treeIcon[node.dev_cate]} /> : <MyIcon type="icon_address" />}
                {' ' + node.title}
                <span style={{ marginLeft: 5 }}>
                    {/* ({node.selectNum} / {node.totalEquipment}) */}(
                    {node.selectNum === undefined ? 0 : node.selectNum} / {node.device_num})
                </span>
            </div>
        </div>
    );
};
