import { map } from 'lodash';
import React, { useMemo } from 'react';
import { Chart, Series, Tooltip, Legend } from '@echarts-start/react-bridge';
import { COLOR3 } from '../../../../global';
const symbolSize = 120;
const minSize = 30;
export const RelationChart = ({ rawData, total }) => {
    const { nodes, links, categories } = useMemo(() => {
        const nodes = [];
        const links = [];
        const categories = [];
        map(rawData, (item, idx) => {
            categories.push({ name: item.name });
            nodes.push({
                id: idx + 1,
                name: item.name,
                value: item.amount,
                category: idx + 1,
                symbolSize: (item.amount / total) * symbolSize < minSize ? minSize : (item.amount / total) * symbolSize,
            });
            links.push({ source: idx + 1, target: 0 });
        });
        categories.unshift({ name: '用电总量' });
        nodes.unshift({ id: 0, name: '用电总量', value: total, category: 0, symbolSize });
        links.unshift({ source: 0, target: '' });
        return { nodes, links, categories };
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rawData]);
    return (
        <Chart color={COLOR3} noCoordinate={true} style={{ width: '100%', height: '100%' }} resize={true}>
            <Tooltip />
            <Legend
                options={{
                    orient: 'vertical',
                    right: '10%',
                    top: 'center',
                    data: map(categories, item => {
                        return item.name;
                    }),
                    textStyle: {
                        color: '#c7c7c7',
                        fontSize: 12,
                    },
                }}
            />
            <Series
                options={{
                    type: 'graph',
                    data: nodes,
                    links: links,
                    layout: 'force',
                    categories: categories,
                    roam: false,
                    force: {
                        initLayout: 'circular',
                        repulsion: 1600,
                    },
                    label: {
                        show: true,
                        formatter: item => {
                            if (item.data.symbolSize < 40) {
                                return null;
                            }
                            return `{a|${item.data.value}kWh}\n{b|${item.data.name}}`;
                        },

                        rich: {
                            a: {
                                color: '#ffffff',
                                fontSize: '14px',
                            },
                            b: {
                                color: '#ffffff',
                                fontSize: '12px',
                                padding: [5, 0, 0, 0],
                                align: 'center',
                            },
                        },
                    },
                    emphasis: {
                        focus: 'adjacency',
                        lineStyle: {
                            width: 10,
                        },
                    },
                }}
            />
        </Chart>
    );
};
