import axios from 'axios';
import ToastHelper from '../tools/ToastHelper';
import { BASE_URL } from '../global';
import { fakeAuth } from '../util/fakeAuth';
import RouteHelper from '../tools/RouteHelper';
import { removeUser } from '../tools/StorageHelper';
import { stringify } from 'querystring';
const instance = axios.create({
    timeout: 30000,
    headers: { Authorization: fakeAuth.authenticate() },
});
  
let showCode = true;//主要是处理当接口返回code 为-2时或者code wei -1msg='身份认证信息未提供'的情况下，token失效跳转到登录页之前只弹一次框，做一次拦截就好
// todo:// 根据服务器接口数据格式处理
function parseErrorMessage(response) {
    //请求状态码
    const code = response.data.code;
    const message = response.data.msg;
    const data = response.data;/*  */
    if (code === -1 && showCode) {
        // console.log(typeof message, '?@@@@@@@@@@@@@@@');
        if (typeof message !== 'string') {
            ToastHelper.errorToast('返回msg不正确');
        } else {
            if(message == '身份认证信息未提供。'){
                showCode = false
                ToastHelper.errorToast(message);
                fakeAuth.signout(); // 清除token
                removeUser();
                RouteHelper.locationTo('/login');
            }else{
                ToastHelper.errorToast(message);
            }
        }
        let err = new Error();
        err.name = 'default';
        err.message = message;
        throw err;
    }
    if (code === -2 && showCode) {
        showCode = false
        ToastHelper.errorToast('登录失效、请重新登录', () => {
            fakeAuth.signout(); // 清除token
            removeUser();
            RouteHelper.locationTo('/login');
        });
        let err = new Error();
        err.name = 'default';
        err.message = message;
        throw err;
    }
    return data;
}

function noParse(response) {
    return response;
}

/**
 * post参数对象转FormData
 * @param params
 * @returns {*}
 */
function paramsToFormData(params) {
    if (!params) {
        return {};
    }
    if (params instanceof FormData) {
        return params;
    }
    let fd = new FormData();
    for (let p in params) {
        let value = params[p];
        // 对象转为json字符串
        if (value instanceof Object) {
            value = JSON.stringify(value);
        }
        fd.append(p, value);
    }
    return fd;
}

/**
 * post参数文件类型对象转FormData
 * @param params
 * @returns {*}
 */

function paramsToFormDataFile(params) {
    if (!params) {
        return {};
    }
    if (params instanceof FormData) {
        return params;
    }
    let fd = new FormData();
    for (let p in params) {
        let value = params[p];
        if (Array.isArray(params[p])) {
            params[p].forEach(item => {
                fd.append(p, item);
            });
        } else {
            fd.append(p, value);
        }
    }
    return fd;
}

/**
 * patch请求
 * @param url
 * @param data
 * @param config 请求参数
 * @returns {Promise<void>}
 */
export async function patch(url, data, config = {}) {
    const { other = false, json = true, file } = config;
    url = BASE_URL + url;
    if (!file) {
        data = json ? data : paramsToFormData(data);
    } else {
        data = paramsToFormDataFile(data);
    }
    return new Promise((resolve, reject) => {
        // startLoading();
        return instance
            .patch(url, data, {timeout:30000})
            .then(other ? noParse : parseErrorMessage)
            .then(result => {
                resolve(result);
            })
            .catch(function (err) {
                if (err.message === 'Network Error') {
                    ToastHelper.errorToast('网络异常');
                    return;
                }
                reject(err.message);
            });
    });
}

/**
 * post请求
 * @param url
 * @param data
 * @param config 请求参数
 * @returns {Promise<void>}
 */
export async function post(url, data, config = {}) {
    const { other = false, json = true, file } = config;
    url = BASE_URL + url;
    if (!file) {
        data = json ? data : paramsToFormData(data);
    } else {
        data = paramsToFormDataFile(data);
    }
    return new Promise((resolve, reject) => {
        // startLoading();
        return instance
            .post(url, data, {timeout:30000})
            .then(other ? noParse : parseErrorMessage)
            .then(result => {
                resolve(result);
            })
            .catch(function (err) {
                if (err.message === 'Network Error') {
                    ToastHelper.errorToast('网络异常');
                    return;
                }
                reject(err.message);
            });
    });
}

/**
 * get请求
 * @param url
 * @param params
 * @param config {object}
 * @returns {Promise<void>}
 */
export async function get(url, data, config = {}, responseType) {
    const { other = false, isDetail = false, json = true, isSpecial = false } = config;
    url = isDetail ? BASE_URL + url + data.id : handleChange(url, data, isSpecial);
    // console.log('get url=', url, data);
    data = json ? data : paramsToFormData(data);
    responseType = 'arraybuffer';
    // startLoading();
    return new Promise(function (resolve, reject) {
        return instance
            .get(url,undefined, {timeout:30000})
            .then(other ? noParse : parseErrorMessage)
            .then(function (result) {
                resolve(result);
            })
            .catch(function (err) {
                if (err.message === 'Network Error') {
                    ToastHelper.errorToast('网络异常');
                    return;
                }
                reject(err.message);
            });
    });
}

/**
 * put请求
 */
export async function put(url, data, config = {}) {
    const { other = false, json = true, customTimout=130000  } = config;
    url = BASE_URL + url;
    data = json ? data : paramsToFormData(data);
    return new Promise((resolve, reject) => {
        // startLoading();
        return instance
            .put(url, data, {timeout:customTimout})
            .then(other ? noParse : parseErrorMessage)
            .then(result => {
                resolve(result);
            })
            .catch(function (err) {
                if(err.code === 'ECONNABORTED'){
                    ToastHelper.errorToast('请求超时');
                    return;
                }else if (err.message === 'Network Error') {
                    ToastHelper.errorToast('网络异常');
                    return;
                }
                reject(err.message);
            });
    });
}

/**
 * delete请求
 * @param url
 * @param data
 * @param config 请求参数
 * @returns {Promise<void>}
 */
export async function remove(url, id, config = {}) {
    // const { other = false, json = true } = config;
    const { other = false } = config;
    // url = BASE_URL + url + id + '/';
    url = BASE_URL + url + id;
    // console.log('delete url=', url);
    // startLoading();
    return new Promise(function (resolve, reject) {
        return instance
            .delete(url, undefined, {timeout:30000})
            .then(other ? noParse : parseErrorMessage)
            .then(function (result) {
                resolve(result);
            })
            .catch(function (err) {
                if (err.message === 'Network Error') {
                    ToastHelper.errorToast('网络异常');
                    return;
                }
                reject(err.message);
            });
    });
}

/**
 * get请求参数处理
 *  @params url ,params
 * **/

function handleChange(url, params, isSpecial) {
    if(isSpecial){
        url = BASE_URL + url + '?' + stringify(params)
    }else{
        url = BASE_URL + url + '?';
        for (let item in params) {
            url = url + item + '=' + params[item] + '&';
        }
        url = url.substring(0, url.length - 1);
    }
    return url;
}