import React from 'react';
import { Row, Col } from 'antd';
// 在线巡检中的基本信息组件
const PatrolItem = props => {
    const { value, name, data } = props;
    return value instanceof Array ? (
        <Col className="gutter-row" span={6} style={{ paddingRight: '0' }}>
            <div style={style.gutterRow}>
                <span style={style.nameStyle}>{name}：</span>
                <Row style={{ textAlign: 'left', marginLeft: '0.5rem', display: 'flex', flexDirection: 'column' }}>
                    {value.map(item => (
                        <Col
                            className="gutter-row"
                            // span={20}
                            key={item.names}
                            style={{
                                width: '100px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                display: 'inline-block',
                                verticalAlign: 'middle',
                                textAlign: 'center',
                            }}
                        >
                            <span style={style.nameStyle}>{item.names}：</span>
                            <span style={style.valueStyle} title={item.num}>
                                {item.num}
                            </span>
                        </Col>
                    ))}
                </Row>
            </div>
        </Col>
    ) : data ? (
        <Col className="gutter-row" span={6} style={{ paddingRight: '0' }}>
            <div style={style.gutterRow}>
                <Row style={{ textAlign: 'left', marginLeft: '0.5rem' }}>
                    {data.map(item => (
                        <Col
                            className="gutter-row"
                            span={24}
                            key={item.names}
                            style={{
                                width: '100px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                display: 'inline-block',
                                verticalAlign: 'middle',
                            }}
                        >
                            <span style={style.nameStyle}>{item.names}：</span>
                            <span style={style.valueStyle} title={item.num}>
                                {item.num}
                            </span>
                        </Col>
                    ))}
                </Row>
            </div>
        </Col>
    ) : (
        <Col className="gutter-row" span={6} style={{ paddingRight: '0' }}>
            <div style={style.gutterRow}>
                <span style={style.nameStyle}>{name}：</span>
                <span
                    style={
                        value === '异常' ? { color: '#E77B5E', fontSize: '1.1rem', fontWeight: 500 } : style.valueStyle
                    }
                    title={value}
                >
                    {value}
                </span>
            </div>
        </Col>
    );
};

export default PatrolItem;

const style = {
    gutterRow: {
        background: 'rgba(242,247,247,0.6)',
        borderRadius: '10px',
        height: '6.5rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    nameStyle: {
        fontSize: '0.95rem',
        color: '#666666',
        textAlign: 'center',
    },
    valueStyle: {
        fontSize: '1.2rem',
        color: '#595959',
        fontWeight: 500,
    },
};
