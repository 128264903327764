import React from 'react';
import { Form, Button, Input, message } from 'antd';
import { BreadcrumbCustom } from '../../../components/BreadcrumbCustom';
import history from '../../../api/history';
import { changePassword } from '../../../api/webServe';
import { fakeAuth } from '../../../util/fakeAuth';
import { removeUser } from '../../../tools/StorageHelper';

const FormItem = Form.Item;
const ChangePasswords = () => {
    const FormItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const layout = {
        wrapperCol: { offset: 8, span: 16 },
    };
    const [form] = Form.useForm();
    const onFinish = values => {
        changePassword(values)
            .then(result => {
                message.success('密码修改成功,请重新登录！');
                fakeAuth.signout(); // 清除token
                removeUser();
                history.push('/login'); //跳转到登录页面
            })
            .catch(error => {
                // message.error(error);
            });
    };
    return (
        <div className="container clearfix">
            <BreadcrumbCustom paths={['修改密码']} className="rumbCustom" />
            <div
                className="row clearfix"
                style={{ padding: '1rem 1rem 0', margin: '1rem', backgroundColor: '#ffffff', borderRadius: '10px' }}
            >
                <div className="personal">
                    <Form
                        layout="horizontal"
                        form={form}
                        scrollToFirstError
                        onFinish={onFinish}
                    >
                        <FormItem
                            label="旧密码"
                            name="old_password"
                            {...FormItemLayout}
                            hasFeedback
                            rules={[{ required: true, message: '请输入旧密码！' }]}
                        >
                            <Input.Password />
                        </FormItem>
                        <FormItem
                            label="新密码"
                            name="new_password"
                            {...FormItemLayout}
                            hasFeedback
                            rules={[
                                { required: true, message: '请输入新密码！' },
                                {
                                    pattern: /^.{6,20}$/,
                                    message: '密码长度为6-20位!',
                                },
                                // ({ getFieldValue }) => ({
                                //     validator(_, value) {
                                //         if (value.length >= 6 && value.length < 20) {
                                //             return Promise.resolve();
                                //         }
                                //         return Promise.reject(new Error('密码长度为6-20位!'));
                                //     },
                                // }),
                            ]}
                        >
                            <Input.Password />
                        </FormItem>
                        <FormItem
                            label="确认新密码"
                            name="confirm_password"
                            dependencies={['new_password']}
                            {...FormItemLayout}
                            hasFeedback
                            rules={[
                                { required: true, message: '请再次输入新密码！' },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('new_password') === value) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(new Error('两次密码不一致!'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password />
                        </FormItem>
                        <FormItem {...layout} hasFeedback>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{ textAlign: 'center', marginBottom: '10px' }}
                            >
                                提交
                            </Button>
                        </FormItem>
                    </Form>
                </div>
            </div>
        </div>
    );
};
export default ChangePasswords;
