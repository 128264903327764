/**
 * 手机号验证码登录
 */
import React, { useState, useEffect } from 'react';
import { Form, Input, Button, message } from 'antd';
import history from '../../api/history';

import { MyIcon } from '../../util/iconfont';
import './index.less';
import { fakeAuth } from '../../util/fakeAuth';
import { setCookie } from '../../util/cookies';
import { setPermissions, setUser, setHomePagePath } from '../../tools/StorageHelper';
import { login, getMobile } from '../../api/webServe';
let timeChange;
const MessageLogin = () => {
    const [form] = Form.useForm();
    const [time, setTime] = useState(60);
    const [mobile, setMobile] = useState({});
    const [isGetCode, setIsGetCode] = useState(true);
    const [session_key, setSession_key] = useState();
    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    // 登录成功
    const onFinish = values => {
        values.way = 'code';
        values.session_key = session_key;
        login(values)
            .then(result => {
                message.success('登录成功');
                fakeAuth.setToken('JWT ' + result.data.token); //储存token到本地
                fakeAuth.setStorage('user', JSON.parse(JSON.stringify(result.data))); //用户信息
                setUser(JSON.stringify(result.data)); //用户信息
                setPermissions(JSON.stringify(result.data.permission));
                let data = result.data.permission.filter(item => {
                    return item.name === '工作台'
                })
                setHomePagePath(data.length > 0 ? (data[0].children.length > 0 ? data[0].children[0].path : undefined) : undefined)
                result.data.is_staff ? history.push('/admin') : history.push(data.length > 0 ? (data[0].children.length > 0 ? data[0].children[0].path : undefined) : '/web/homePage');
            })
            .catch(error => {
                // message.error('验证码错误');
                // message.error('账号或者密码错误');
            });
    };

    useEffect(() => {
        clearInterval(timeChange);
        return () => clearInterval(timeChange);
    }, []);

    useEffect(() => {
        if (time > 0 && time < 60) {
            setIsGetCode(false);
        } else {
            clearInterval(timeChange);
            setTime(60);
            setIsGetCode(true);
        }
    }, [time]);

    /**
     * 获取验证码
     */
    const getMsgCode = async () => {
        // if (isGetCode) {
        //     // 倒计时未结束,不能重复点击
        //     return;
        // }
        if (!form.getFieldValue('mobile')) {
            message.error('请先输入手机号');
            return;
        }
        mobile.purpose = 'login';
        await getMobile(mobile)
            .then(result => {
                message.success('发送成功,请填写收到的验证码');
                setSession_key(result.data.session_id)
                setCookie('sessionid','sessionid='+result.data.session_id)
            })
            .catch(error => {
                // message.error(error);
            });
        // 注意，不要使用 setTime(t-1) ： 闭包问题会导致time一直为59
        timeChange = setInterval(() => setTime(t => --t), 1000);
    };

    return (
        <div className="dataWrap">
            <Form
                className="login-form logins"
                form={form}
                onFinish={onFinish}
                onValuesChange={changedFields => setMobile(changedFields)}
                name="horizontal_login"
                layout="inline"
                initialValues={{
                    remember: true,
                }}
                {...layout}
            >
                <Form.Item
                    name="mobile"
                    rules={[
                        {
                            required: true,
                            message: '请输入手机号',
                        },
                        {
                            pattern: new RegExp(/^1[3-9]\d{9}$/, 'g'),
                            message: '手机号格式不正确',
                        },
                    ]}
                >
                    <Input
                        prefix={<MyIcon type="icon_login_user" className="site-form-item-icon" />}
                        placeholder="请输入手机号"
                        maxLength={11}
                        // onChange={mobile => console.log(mobile, '手机号')}
                    />
                </Form.Item>
                <Form.Item
                    name="code"
                    rules={[
                        {
                            required: true,
                            message: '请输入验证码!',
                        },
                    ]}
                >
                    <Input
                        prefix={<MyIcon type="icon_login_yzm" className="site-form-item-icon" />}
                        placeholder="请输入验证码"
                        style={{ width: '11rem' }}
                        // suffix={
                        //     <span
                        //         className="checkBtn"
                        //         style={{ color: isGetCode ? '#0189FF' : '#B2B4B7' }}
                        //         onClick={isGetCode ? getMsgCode : null}
                        //     >
                        //         {isGetCode ? '获取验证码' : `重新发送(${time}s)`}
                        //     </span>
                        // }
                    />
                </Form.Item>
                <Form.Item>
                    <Button
                        onClick={isGetCode ? getMsgCode : null}
                        style={{
                            background: isGetCode ? '#1bc7d3' : '#eee',
                            color: isGetCode ? '#fff' : '#1bc7d3',
                            border: '1px solid #1bc7d3',
                            position: 'absolute',
                            right: '0',
                            zIndex: 11111,
                            height: '27px',
                            padding: '0 5px',
                            top: '-3.2rem',
                        }}
                    >
                        {isGetCode ? '获取验证码' : `重新发送(${time}s)`}
                    </Button>
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                        style={{
                            background: '#10A7B9',
                            border: '1px solid #10A7B9',
                        }}
                    >
                        登录
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};
export default MessageLogin;
