import React, { useImperativeHandle, useState } from 'react';
import { Button, Modal } from 'antd';

export const CommonModal = ({ children, childRef, onCancel, onOk, title, footer, searchBar, setSearchState }) => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const startLoading = () => {
        setLoading(true);
    };
    const endLoading = () => {
        setLoading(false);
    };
    const showModal = () => {
        setVisible(true);
    };
    const hiddenModal = () => {
        endLoading();
        setVisible(false);
    };
    useImperativeHandle(childRef, () => ({
        showModal,
        hiddenModal,
        startLoading,
        endLoading,
    }));
    return (
        <Modal
            forceRender
            getContainer={false}
            title={<Title title={title} />}
            onCancel={() => {
                onCancel && onCancel();
                endLoading();
                hiddenModal();
                searchBar && (searchBar.current && (searchBar.current.state.value = ''))
                searchBar && setSearchState(true)
            }}
            visible={visible}
            centered={true}
            footer={
                footer ? (
                    footer
                ) : (
                    <Footer
                        onCancel={() => {
                            onCancel && onCancel();
                            endLoading();
                            hiddenModal();
                            searchBar && (searchBar.current && (searchBar.current.state.value = ''))
                            searchBar && setSearchState(true)
                        }}
                        onOk={() => {
                            // startLoading();
                            onOk && onOk();
                        }}
                        loading={loading}
                    />
                )
            }
        >
            {children}
        </Modal>
    );
};

const Footer = ({ loading, onOk, onCancel }) => {
    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <div style={{ padding: '0 25px' }}>
                <Button disabled={loading} onClick={onCancel}>
                    取消
                </Button>
            </div>
            <div style={{ padding: '0 25px' }}>
                <Button type="primary" onClick={onOk} loading={loading} htmlType="submit">
                    确认
                </Button>
            </div>
        </div>
    );
};

const Title = ({ title }) => {
    return <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{title}</div>;
};
