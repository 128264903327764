import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Select } from 'antd';
import { isEmpty, isUndefined } from 'lodash';
import { Loading } from '../../../components/Loading';
import { EmptyView } from '../../../components/EmptyView'; //空数据占位组件
import { CommonCameraTree } from '../../../components/CommonCameraTree';
import { Page } from '../../../components/Pagination';
import { Slider } from './Slider';
import { getRegionCamera, getCamera } from '../../../api/webServe';
import { showCamera } from '../../../global';
import './style.less';

const { Option } = Select;
/**
 * 工作台组件
 */
export const Camera = () => {
    const intervalRef = useRef();
    const [treeData, setTreeData] = useState([]);
    const [, setAllNode] = useState([]);
    const [areaInfo, setAreaInfo] = useState();
    const [treeId, setTreeId] = useState();
    const [data, setData] = useState({ data: null, isLoading: true });
    const [count, setCount] = useState(4);
    const [total, setTotal] = useState(0); //数据总条数
    const [page, setPage] = useState(1); //当前页
    const [page_size, setPageSize] = useState(4); //当前页显示条数

    //切换value宫格显示
    const handleChange = value => {
        setCount(value);
        setPageSize(value);
    };
    const getRegionData = () => {
        getRegionCamera()
            .then(result => {
                setTreeData(result.data);
            })
            .catch();
    };
    useEffect(() => {
        getRegionData(); //左侧区域数据
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        // (!isEmpty(treeId) || (treeData[0] && treeData[0].id)) && getCameraData();
        setData({ data: null, isLoading: true });
        // if(!isEmpty(treeId)){
        if (!isUndefined(treeId)) {
            getCamera({ region_id: treeId, page: page, page_size: page_size })
                .then(result => {
                    setData({ data: result.data, isLoading: false });
                    setTotal(result.count);
                })
                .catch(() => {
                    setData({ data: null, isLoading: false });
                });
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [treeId, page, page_size]);
    // const getCameraData = () => {
    //     setData({ data: null, isLoading: true });
    //     // if(!isEmpty(treeId)){
    //     if (!isUndefined(treeId)) {
    //         getCamera({ region_id: treeId, page: page, page_size: page_size })
    //             .then(result => {
    //                 setData({ data: result.data, isLoading: false });
    //                 setTotal(result.count);
    //             })
    //             .catch(() => {
    //                 setData({ data: null, isLoading: false });
    //             });
    //     }
    // };
    useEffect(() => {
        const id = setInterval(() => {
            // getCameraData();
        }, 60 * 1000); //五分钟执行一次
        intervalRef.current = id;
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div className="container clearfix">
            <div
                className="row clearfix"
                style={{
                    backgroundColor: 'transparent',
                    justifyContent: 'center',
                    width: '100%',
                    paddingLeft: '0px',
                    paddingRight: '1rem',
                    marginBottom: '0px',
                }}
            >
                <Row style={{ width: '100%', marginLeft: '1rem', height: '60vh' }} gutter={32}>
                    <Col
                        className="gutter-row"
                        span={4}
                        style={{
                            padding: '1rem',
                            background: '#ffffff',
                            borderRadius: '10px',
                            height: '89.5vh',
                            marginBottom: '1rem',
                        }}
                    >
                        <CommonCameraTree
                            // treeData={treeData}
                            // onClick={info => {
                            //     console.log(info, 'info');
                            //     !isEmpty(treeData) && setTreeId(info.id || info[0]);
                            // }}
                            treeData={treeData}
                            onClick={value => {
                                !isEmpty(treeData) && setTreeId(value.id);
                                if (!areaInfo || areaInfo.id !== value.id) {
                                    setAreaInfo(value);
                                }
                            }}
                            getNode={all => {
                                setAllNode(all);
                            }}
                        />
                        {/* {!isEmpty(treeData) && (
                            <div style={{marginTop: '2rem'}}>
                                <Select defaultValue="4宫格" style={{ width: '100%' }} onChange={handleChange}>
                                    {showCamera.map(item => {
                                        return (
                                            <Option value={item.id} key={item.id}>
                                                {item.value}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </div>
                        )} */}
                    </Col>
                    <Col className="gutter-row" span={20} style={{ paddingRight: '1rem', paddingLeft: '1rem' }}>
                        <div style={{ background: '#ffffff', height: '98%', borderRadius: '10px' }}>
                            <Loading show={data.isLoading}>
                                {isEmpty(data.data) ? (
                                    <EmptyView />
                                ) : (
                                    <div style={{ padding: '1rem 0' }} className='camera'>
                                        <Slider count={count} data={data.data} click={true} />
                                        {!isEmpty(treeData) && (
                                            <div className="showStyle" style={{ marginTop: '2rem', width: '110px' }}>
                                                <Select
                                                    defaultValue={page_size}
                                                    style={{ width: '100%' }}
                                                    onChange={handleChange}
                                                >
                                                    {showCamera.map(item => {
                                                        return (
                                                            <Option value={item.id} key={item.id}>
                                                                {item.value}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                        )}
                                        <div
                                            span={24}
                                            className="totalStyle"
                                            style={{ left: '12%', width: '14%', bottom: '2.5%', overflow: 'hidden' }}
                                        >
                                            <span
                                                style={{
                                                    display: 'inline-block',
                                                    maxWidth: '70%',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    verticalAlign: 'middle',
                                                }}
                                            >
                                                {total}
                                            </span>
                                            <span style={{ verticalAlign: 'middle' }}>个摄像头</span>
                                        </div>
                                        <div className="paginations" 
                                        style={{
                                            left: '20%',
                                            width: '77%',
                                            bottom: '3px',
                                            marginLeft: '0',
                                            marginBottom: '1rem',
                                        }}>
                                            <Page
                                                count={count}
                                                total={total}
                                                page_size={page_size}
                                                setPageSize={setPageSize}
                                                page={page}
                                                setPage={setPage}
                                            />
                                        </div>
                                    </div>
                                )}
                            </Loading>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
