import { useEffect, useMemo, useState } from 'react';
import { Col, Row, Select } from 'antd';
import moment from 'moment';
import { isEmpty, isNumber, isNull, isUndefined, omitBy } from 'lodash';
import { BreadcrumbCustom } from '../../../components/BreadcrumbCustom';
// import { CommonTree } from '../../../components/CommonTreeRegion';
import { CommonTree } from '../../../components/CommonTreeHidden';
import { EmptyView } from '../../../components/EmptyView';
import { TimeSearch } from '../EnergyConsumptionChart/ECRCondition';
import { ChartStatistics } from '../EnergyConsumptionChart/Chart/ChartStatistics';
import { ChartBar } from './Chart/ChartBar';
import { ChartRadar } from './Chart/ChartRadar';
import { ChartPies } from './Chart/ChartPies';
import { Loading } from '../../../components/Loading';
import { PieChart_COLOR2, COLOR_PLATE_16, selectTop, colorAlarm } from '../../../global';
import { MyIcon } from '../../../util/iconfont';
import { month12 } from '../../../util/timeUtil';
import { timestampToTime } from '../../../util/function-util';
import { getAlarmRank, getAlarmTrend, getAlarmEvent, getAlarmGrade, getRegionDevice } from '../../../api/webServe';

const { Option } = Select;
/**
 * 隐患分析
 * @returns
 */
export const HiddenAnalysis = () => {
    const [areaInfo, setAreaInfo] = useState();
    const [treeData, setTreeData] = useState([]);
    const [allNode, setAllNode] = useState([]);
    const [treeId, setTreeId] = useState(undefined);
    const [treeType, setTreeType] = useState(false);
    // const [time, setNewTime] = useState({ start_time: timestampToTime(month12[0]), end_time: timestampToTime(moment(month12[1]).startOf('day').valueOf()) });
    const [time, setNewTime] = useState({ start_time: month12[0], end_time: month12[1] });
    useEffect(() => {
        const params = omitBy(time, value => {
            if (isNaN(value) || value === '') {
                return isUndefined(value) || isNull(value) || value === '';
            } else {
                return isNull(value);
            }
        });
        getRegionDevice({ ...params })
            .then(result => {
                setTreeData(result.data);
            })
            .catch();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [time]);
    return (
        <div className="container clearfix">
            <BreadcrumbCustom paths={['首页', '隐患管理', '隐患分析']} className="rumbCustom" />
            <div className="row clearfix" style={{ backgroundColor: 'transparent', margin: '1rem', padding: '0' }}>
                <Row style={{ marginLeft: '0rem', width: '100%' }} gutter={32}>
                    <Col
                        className="gutter-row"
                        span={4}
                        style={{ padding: '1rem', background: '#fff', borderRadius: '10px' }}
                    >
                        <CommonTree
                            // operable={false}
                            treeData={treeData}
                            onClick={info => {
                                if (!areaInfo || areaInfo.id !== info.id) {
                                    setAreaInfo(info);
                                }
                                !isEmpty(treeData) && setTreeId(info.id);
                                setTreeType(info.dev_cate ? true : false)
                            }}
                            getNode={all => {
                                setAllNode(all);
                            }}
                        />
                    </Col>
                    <Col
                        span={20}
                        style={{
                            borderRadius: '10px',
                            boxSizing: 'border-box',
                            paddingRight: '0',
                        }}
                    >
                        <ChartView
                            areaInfo={areaInfo}
                            setNewTime={setNewTime}
                            times={time}
                            allNode={allNode}
                            treeId={treeId}
                            treeData={treeData}
                            treeType={treeType}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
};

const ChartView = ({ areaInfo, setNewTime, times, treeData, treeType }) => {
    //解决点击时间触发两次请求的bug：默认监测点id或者点击后的监测点id
    areaInfo = areaInfo || (!isEmpty(treeData) && treeData[0])
    const id = useMemo(() => {
        if (isEmpty(areaInfo)) {
            return null;
        }
        return areaInfo.id;
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [areaInfo, treeData]);
    const [gradeData, setGradeData] = useState({ isLoading: true, data: null });
    const [alarmEvent, setAlarmEvent] = useState({ isLoading: true, data: null });
    const [alarmTrend, setAlarmTrend] = useState({ isLoading: true, data: null });
    const [alarmRankData, setAlarmRank] = useState({ isLoading: true, data: null });
    const [top, setTop] = useState(10);
    const [show, setShow] = useState(true);
    //告警统计-告警等级
    const getGradeData = time => {
        setGradeData({ data: null, isLoading: true });
        getAlarmGrade(treeType ? { device_id: id, start_time: timestampToTime(time[0]), end_time: timestampToTime(moment(time[1]).startOf('day').valueOf()) } : { region_id: id, start_time: timestampToTime(time[0]), end_time: timestampToTime(moment(time[1]).startOf('day').valueOf()) })
            .then(result => {
                result = result.data;
                setGradeData({ isLoading: false, data: result });
            })
            .catch(() => {
                setGradeData({ data: null, isLoading: false });
            });
    };
    //告警统计-异常类型
    const getAlarmEventData = time => {
        setAlarmEvent({ data: null, isLoading: true });
        getAlarmEvent(treeType ? { device_id: id, start_time: timestampToTime(time[0]), end_time: timestampToTime(moment(time[1]).startOf('day').valueOf()) } : { region_id: id, start_time: timestampToTime(time[0]), end_time: timestampToTime(moment(time[1]).startOf('day').valueOf()) })
            .then(result => {
                result = result.data;
                setAlarmEvent({ isLoading: false, data: result });
            })
            .catch(() => {
                setAlarmEvent({ data: null, isLoading: false });
            });
    };
    //告警趋势
    const getAlarmTrendData = time => {
        setAlarmTrend({ data: null, isLoading: true });
        getAlarmTrend(treeType ? { device_id: id, start_time: timestampToTime(time[0]), end_time: timestampToTime(moment(time[1]).startOf('day').valueOf()) } : { region_id: id, start_time: timestampToTime(time[0]), end_time: timestampToTime(moment(time[1]).startOf('day').valueOf()) })
            .then(result => {
                result.data &&
                    result.data.map(item => {
                        if (result.rule === 'h') {
                            item.time = item.time.slice(11, 19);
                        } else {
                            item.time = item.time.slice(0, 10);
                        }
                        return item;
                    });
                setAlarmTrend({ isLoading: false, data: result.data });
            })
            .catch(() => {
                setAlarmTrend({ isLoading: false, data: null });
            });
    };
    //告警排行
    const getAlarmRankData = time => {
        setAlarmRank({ data: null, isLoading: true });
        //判断id是否为设备id,如果为设备id就不发送请求。
        // if(typeof(id) !== 'string' &&  (id && id.length !== 8)){
        if(!treeType){
            getAlarmRank({ id, top, start_time: timestampToTime(time[0]), end_time: timestampToTime(moment(time[1]).startOf('day').valueOf()) })
            .then(result => {
                setAlarmRank({ isLoading: false, data: isEmpty(result.data) ? null : result.data });
                setShow(true)
            })
            .catch(() => {
                setAlarmRank({ data: null, isLoading: false });
                setShow(false)
            });
        }else{
            setShow(false)
        }
    };
    useEffect(() => {
        if (!id && !isNumber(id)) {
            return;
        }
        getGradeData([times.start_time, times.end_time]);
        getAlarmTrendData([times.start_time, times.end_time]);
        getAlarmEventData([times.start_time, times.end_time]);
        getAlarmRankData([times.start_time, times.end_time]);
        // setNewTime({start_time: toDay[0], end_time: toDay[1]});
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (!id && !isNumber(id)) {
            return;
        }
        getAlarmRankData([times.start_time, times.end_time]);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [top]);
    return (
        <>
            <Row
                style={{
                    ...Style.module,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                    borderRadius: '10px',
                    boxShadow: '0px 2px 3px #e7ebeb',
                    background: 'linear-gradient(180deg, #fefefe 0%, #f5f6fa 100%)',
                    margin: '0 0 1rem',
                }}
            >
                <Col span={8}>
                    <span style={Style.title}>
                        <MyIcon
                            type="icon_alrm_info"
                            style={{
                                fontSize: '1.5rem',
                                marginTop: '-0.1rem',
                                verticalAlign: 'middle',
                                paddingRight: '0.4rem',
                                height: 50,
                                lineHeight: '60px',
                            }}
                        />
                        隐患分析
                    </span>
                </Col>
                <Col
                    span={16}
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        lineHeight: '50px',
                    }}
                >
                    <TimeSearch
                        id={id}
                        timeValue={month12}
                        // isDefault={true}
                        onValueChange={times => {
                            const { time } = times;
                            // setNewTime(time);
                            setNewTime({start_time: timestampToTime(time[0]), end_time: timestampToTime(moment(time[1]).startOf('day').valueOf())});
                            if (!id && !isNumber(id)) {
                                return;
                            }
                            getGradeData(time);
                            getAlarmEventData(time);
                            getAlarmTrendData(time);
                            getAlarmRankData(time);
                        }}
                    />
                </Col>
            </Row>
            <Row style={{ marginBottom: '1rem' }}>
                <Col span={24}>
                    <Row
                        style={{
                            ...Style.module,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%',
                            borderBottomLeftRadius: '0px',
                            borderBottomRightRadius: '0px',
                            boxShadow: '0px 2px 3px #e7ebeb',
                            background: 'linear-gradient(180deg, #fefefe 0%, #f5f6fa 100%)',
                        }}
                    >
                        <Col
                            span={4}
                            style={{
                                ...Style.title,
                            }}
                        >
                            <MyIcon
                                type="icon_alrm_statistics"
                                style={{
                                    fontSize: '1.5rem',
                                    marginTop: '-0.1rem',
                                    verticalAlign: 'middle',
                                    paddingRight: '0.6rem',
                                    height: 50,
                                    lineHeight: '60px',
                                }}
                            />
                            告警统计
                        </Col>
                    </Row>
                </Col>
                <Col
                    span={24}
                    style={{ backgroundColor: '#fff', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}
                >
                    <Row
                        style={{
                            ...Style.module,
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            borderTopLeftRadius: '0px',
                            borderTopRightRadius: '0px',
                            borderBottomLeftRadius: '10px',
                            borderBottomRightRadius: '10px',
                        }}
                    >
                        <Col
                            span={12}
                            style={{
                                width: '100%',
                                height: '40vh',
                            }}
                        >
                            <Loading show={gradeData.isLoading}>
                                {isEmpty(gradeData.data) ? (
                                    <EmptyView />
                                ) : (
                                    <ChartPies data={gradeData.data} color={colorAlarm} />
                                )}
                            </Loading>
                        </Col>
                        <Col span={12} style={{ width: '100%', height: '40vh' }}>
                            <Loading show={alarmEvent.isLoading}>
                                {isEmpty(alarmEvent.data) ? (
                                    <EmptyView />
                                ) : (
                                    <ChartRadar rawData={alarmEvent.data} color={PieChart_COLOR2} />
                                )}
                            </Loading>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row style={{ marginBottom: '1rem' }}>
                <Col span={24}>
                    <Row
                        style={{
                            ...Style.module,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%',
                            borderBottomLeftRadius: '0px',
                            borderBottomRightRadius: '0px',
                            boxShadow: '0px 2px 3px #e7ebeb',
                            background: 'linear-gradient(180deg, #fefefe 0%, #f5f6fa 100%)',
                        }}
                    >
                        <Col
                            span={4}
                            style={{
                                ...Style.title,
                            }}
                        >
                            <MyIcon
                                type="icon_title_trend"
                                style={{
                                    fontSize: '1.5rem',
                                    marginTop: '-0.1rem',
                                    verticalAlign: 'middle',
                                    paddingRight: '0.6rem',
                                    height: 50,
                                    lineHeight: '60px',
                                }}
                            />
                            告警趋势
                        </Col>
                    </Row>
                </Col>
                <Col
                    span={24}
                    style={{ backgroundColor: '#fff', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}
                >
                    <Row
                        style={{
                            ...Style.module,
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            borderTopLeftRadius: '0px',
                            borderTopRightRadius: '0px',
                            borderBottomLeftRadius: '10px',
                            borderBottomRightRadius: '10px',
                        }}
                    >
                        <Col span={24} style={{ height: '32vh' }}>
                            <Loading show={alarmTrend.isLoading}>
                                {isEmpty(alarmTrend.data) ? (
                                    <EmptyView />
                                ) : (
                                    <ChartBar
                                        rawData={alarmTrend.data}
                                        color={COLOR_PLATE_16}
                                        bottom={'10%'}
                                        showToolbox={true}
                                        unit={'次'}
                                        showColor={true}
                                    />
                                )}
                            </Loading>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {show &&<Row>
                <Col span={24}>
                    <Row
                        style={{
                            ...Style.module,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%',
                            borderBottomLeftRadius: '0px',
                            borderBottomRightRadius: '0px',
                            boxShadow: '0px 2px 3px #e7ebeb',
                            background: 'linear-gradient(180deg, #fefefe 0%, #f5f6fa 100%)',
                        }}
                    >
                        <Col
                            span={4}
                            style={{
                                ...Style.title,
                            }}
                        >
                            <MyIcon
                                type="icon_title_contrast"
                                style={{
                                    fontSize: '1.5rem',
                                    marginTop: '-0.1rem',
                                    verticalAlign: 'middle',
                                    paddingRight: '0.6rem',
                                    height: 50,
                                    lineHeight: '60px',
                                }}
                            />
                            告警排行
                        </Col>
                        <Col span={19} style={{ marginRight: '1rem', textAlign: 'right' }}>
                            <Select
                                value={top}
                                onChange={value => {
                                    setTop(value);
                                }}
                                placeholder="请选择"
                                virtual={false}
                            >
                                {selectTop.map(item => {
                                    return (
                                        <Option value={item.id} key={item.id}>
                                            {item.value}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Col>
                    </Row>
                </Col>
                <Col
                    span={24}
                    style={{ backgroundColor: '#fff', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}
                >
                    <Row
                        style={{
                            ...Style.module,
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            borderTopLeftRadius: '0px',
                            borderTopRightRadius: '0px',
                            borderBottomLeftRadius: '10px',
                            borderBottomRightRadius: '10px',
                        }}
                    >
                        <Col
                            span={24}
                            style={{
                                height: alarmRankData.data && alarmRankData.data.length <= 20 ? '42vh' : '48vh',
                                paddingBottom: '0.1rem',
                            }}
                        >
                            <Loading show={alarmRankData.isLoading}>
                                {isEmpty(alarmRankData.data) ? (
                                    <EmptyView />
                                ) : (
                                    <ChartStatistics
                                        rawData={alarmRankData.data}
                                        color={COLOR_PLATE_16}
                                        bottom={'10%'}
                                        unit={'次'}
                                        showColor={true}
                                    />
                                )}
                            </Loading>
                        </Col>
                    </Row>
                </Col>
            </Row>}
        </>
    );
};

/**
 * 样式
 */
const Style = {
    title: {
        display: 'flex',
        width: '100%',
        height: '50px',
        lineHeight: '50px',
        fontSize: '1.2rem',
        color: '#333333',
        fontWeight: 500,
        padding: '0 1rem',
        verticalAlign: 'middle',
    },
    module: {
        background: '#ffffff',
        borderRadius: '10px',
        boxShadow: `0px 0px 3px 0px rgba(0,0,0,0.08)`,
    },
};
