/*
 * @Descripttion:环形组件
 * @Author: lipei
 * @Date: 2021-16-Th 09:46:06
 * @Last Modified by:   lipei
 * @Last Modified time: 2021-16-Th 09:46:06
 */
import React, { Component } from 'react';
import ReactEcharts from 'echarts-for-react';

/**
 * 企业概览- 设备分布
 */
export default class AnnularEchart extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    getOption = () => {
        let { color, seriesData, showValue } = this.props;
        seriesData['value'] = seriesData.proportion;
        seriesData = [seriesData, { name: '', value: 100 - Number(seriesData.value) }];
        let option = {
            color: color,
            series: [
                {
                    // name: '中间环形',
                    type: 'pie',
                    radius: ['50%', '80%'],//圆，半径，值1内圆，值2外圆
                    center: ['50%', '50%'],//饼图的中心（圆心）坐标，数组的第一项是横坐标，第二项是纵坐标
                    avoidLabelOverlap: false,
                    hoverAnimation: false,
                    label: {
                        show: showValue ? true : false,
                        position: 'center',
                        textStyle: {
                            fontSize: 12,
                            // fontWeight: 'bold',
                            color: '#f7f7f7',
                        },
                        formatter: '{b}\n{c}%',
                        // 针对 center 参数校正标签显示位置
                        lineHeight: 3,
                    },//数据标签
                    data: seriesData,
                },
            ],
        };
        return option;
    };
    render() {
        const option = this.getOption();
        return (
            <ReactEcharts
                ref={ref => (this.echarts = ref)}
                option={option}
                style={this.props.style}
                // className='react_for_echarts'
            />
        );
    }
}
