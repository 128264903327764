import moment from 'moment';
// const format = 'YYYY-MM-DD';
export const toDay = [moment().startOf('day').valueOf(), moment().valueOf()];
export const day7 = [moment().add(-6, 'd').startOf('day').valueOf(), moment().valueOf()];
export const day30 = [moment().add(-29, 'd').startOf('day').valueOf(), moment().valueOf()];
export const getToDay = () => [moment().startOf('day').valueOf(), moment().valueOf()]
// export const month12 = [moment().subtract(12, 'months').startOf('month').valueOf(), moment().endOf('month').valueOf()];
export const month12 = [moment().subtract(12, 'months').startOf('day').valueOf(), moment().valueOf()];
export const toDate = time => {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
};

//只能选择近30天的日期
export const disabledDate_30day = (current) => {
    return current <= moment().subtract(30, 'days') || current > moment().endOf('day');
}

//只能选择近三个月的数据 startOf(今日可选) endOf(今日不可选)
export const disabledDate_3month = current => {
    //return current && current < moment().startOf('month');//选本月一号到本日之间的日期
    return !(current < moment().endOf('day') && current > moment().subtract(3, 'month'));
};

//只能选择今日以及今日之前的日期
export const disabledDate_day = current => {
    // if (!dates || dates.length === 0) {
    //         return false;
    //     }
    //     const tooLate = dates[0] && current.diff(dates[0], 'days') < 1;
    //     const tooEarly = dates[1] && dates[1].diff(current, 'days') < 1;
    //     return tooEarly || tooLate;
    // };
    return current && current >= moment().endOf('day');
};

//开始时间-禁止选择的日期（当前天以及大于大前天）
export const disabledDate = current => {
    return current && current <= moment().startOf('day'); // 选择时间要大于等于当前天。若今天不能被选择，去掉等号即可。
};

/**
 * 不能选择今日之前的日期  startOf(今日可选) endOf(今日不可选)
 */
export const disabledDate_endDay = current => {
    return current && current < moment().endOf('day');
};

//只能选择近day天的日期
export const disabledDate_days = (current,date) => {
    console.log(date,'day')
    console.log(current,'current')
    return current <= moment().subtract(date, 'days') || current > moment().endOf('day');
}