import React from 'react'; //   useMemo
import {
    Chart,
    Legend,
    XAxis,
    YAxis,
    Series,
    Tooltip,
    Grid,
    Toolbox,
    DataZoom,
    VisualMap,
} from '@echarts-start/react-bridge';
import { map } from 'lodash';
// import * as echarts from 'echarts';
import ReactEcharts from 'echarts-for-react';
import { COLOR_PLATE_16 } from '../../../global';

const XYStyle = {
    axisLine: {
        lineStyle: {
            // color: '#5F7D9C',
        },
    },
};

export const AreaLineChart = ({ data, color, dataZoom, unit, old, height }) => {
    let seriesObjs = [];
    // let value =
    //     data.data &&
    //     data.data[0].map(item => {
    //         return item.value;
    //     });
    data.data &&
        data.data.map((item, index) => {
            seriesObjs.push({
                name: data.legend && data.legend[index],
                color: '#1ed0c9',
                type: 'line',
                showSymbol: index === 3 ? true : false,
                smooth: true,
                data: item,
                itemStyle: {
                    // color: color[index].color,
                },
                lineStyle: {
                    color: color[0].line,
                },
                // color: color[0].line,
                areaStyle: {
                    color: color[0].color,
                    origin: 'start',
                    opacity: color[0].opacity,
                    shadowColor: color[0].shadowColor,
                },
            });
        });

    const getOption = () => {
        let option = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#6a7985',
                    },
                },
            },
            grid: {
                left: 60,
                top: 70,
                bottom: 40,
                right: 30,
            },
            toolbox: {
                //可视化的工具箱
                show: true,
                showTitle: true,
                orient: 'horizontal',
                itemGap: 15,
                itemSize: 16,
                zlevel: 10,
                z: 2,
                right: '8',
                feature: {
                    // dataView: { show: true, readOnly: false },
                    // restore: { show: true, title: '还原' },
                    magicType: {
                        show: true,
                        type: ['bar', 'line'],
                        title: { bar: '切换为柱状图', line: '切换为折线图' },
                        icon: {
                            line:
                                'path://M1006.948898 1023.953728H3.123503V13.881609a13.881609 13.881609 0 1 1 27.763217 0v982.355174h976.062178a13.881609 13.881609 0 0 1 0 27.763217z M168.546006 703.288568a23.136014 23.136014 0 0 1-14.15924-41.644826l257.920289-199.987709 249.868956 140.111703 215.673927-215.859015a23.136014 23.136014 0 1 1 33.315861 32.066516l-27.763218 28.873746-213.406597 212.388613-253.524447-142.193945-233.720018 181.201265a23.136014 23.136014 0 0 1-14.205513 5.043652z',
                            bar:
                                'path://M1006.948898 1023.953728H3.123503V13.881609a13.881609 13.881609 0 1 1 27.763217 0v982.355174h976.062178a13.881609 13.881609 0 1 1 0 27.763217z M168.592279 893.744239a23.136014 23.136014 0 0 1-23.136015-23.136015v-280.130863a23.136014 23.136014 0 0 1 46.272029 0v280.130863a23.136014 23.136014 0 0 1-23.136014 23.136015z M662.962635 893.744239a23.136014 23.136014 0 0 1-23.136014-23.136015v-280.130863a23.136014 23.136014 0 0 1 46.272029 0v280.130863a23.136014 23.136014 0 0 1-23.136015 23.136015z M415.916273 912.206778a23.136014 23.136014 0 0 1-23.136014-23.136014V393.358518a23.136014 23.136014 0 0 1 46.272029 0v495.712246a23.136014 23.136014 0 0 1-23.136015 23.136014z M910.379174 912.25305a23.136014 23.136014 0 0 1-23.136014-23.136014v-417.928966a23.136014 23.136014 0 0 1 46.272029 0v417.928966a23.136014 23.136014 0 0 1-23.136015 23.136014z',
                        },
                    },
                    // myTool2: {
                    //           show: true,
                    //           title: '切换为折线图',
                    //           icon:
                    //               'path://M1006.948898 1023.953728H3.123503V13.881609a13.881609 13.881609 0 1 1 27.763217 0v982.355174h976.062178a13.881609 13.881609 0 0 1 0 27.763217z M168.546006 703.288568a23.136014 23.136014 0 0 1-14.15924-41.644826l257.920289-199.987709 249.868956 140.111703 215.673927-215.859015a23.136014 23.136014 0 1 1 33.315861 32.066516l-27.763218 28.873746-213.406597 212.388613-253.524447-142.193945-233.720018 181.201265a23.136014 23.136014 0 0 1-14.205513 5.043652z',
                    //           onclick: () => {
                    //                     const echartInstance = this.echarts.getEchartsInstance();
                    //                     echartInstance.getOption({
                    //                         toolbox: { iconStyle: { color: '#64D399' } },
                    //                         emphasis: {
                    //                             color: '#64D399',
                    //                             iconStyle: {
                    //                                 color: '#64D399',
                    //                                 borderColor: '#64D399',
                    //                             },
                    //                             borderColor: '#64D399',
                    //                         },
                    //                     });
                    //                     echartInstance.setOption(
                    //                         {
                    //                             series: this.generateChartOption(false),
                    //                         },
                    //                         {
                    //                             replaceMerge: ['series'],
                    //                         },
                    //                     );
                    //                     // showLine = true;
                    //                 },
                    //       },
                    // myTool1:{
                    //           show: true,
                    //           title: '切换为面积图',
                    //           icon:
                    //               'path://M637.6 381.7L495.8 493.1c-4.1 3.1-9.8 3.6-13.9 1l-154.7-84.6c-4.6-2.6-10.8-2.1-14.4 1.5L162.2 547.2v153.1l150-133.5c3.6-3.6 9.8-4.1 14.4-1.5l177.9 96.4c4.1 2.1 9.3 2.1 12.9-0.5l144.4-91.3c1-0.5 2.6-1.5 3.6-1.5l294.4-72.7V301.8l-317.6 77.3c-1.5 0.5-3.1 1.6-4.6 2.6z M662.3 622.2l-142.8 91.3c-3.6 2.6-8.8 2.6-12.9 0.5l-179.9-96.4c-4.6-2.6-10.8-2.1-14.4 1.5 0 0-150.5 133.5-150.5 136.6v111.4h797.6V545.9l-293.9 74.8-3.2 1.5z M64 144.2c0-1.3 0.5-2.6 1.5-3.6s2.3-1.5 3.6-1.5H101c1.3 0 2.6 0.5 3.6 1.5s1.5 2.3 1.5 3.6v778.9H955c1.3 0 2.6 0.5 3.6 1.5s1.5 2.3 1.5 3.6v31.9c0 1.3-0.5 2.6-1.5 3.6s-2.3 1.5-3.6 1.5H64v-821z',
                    //           onclick: () => {
                    //                     const echartInstance = this.echarts.getEchartsInstance();
                    //                     console.log(this.echarts.getEchartsInstance())
                    //                     echartInstance.setOption(
                    //                         {
                    //                             series: generateChartOption(true),
                    //                         },
                    //                         {
                    //                             replaceMerge: ['series'],
                    //                         },
                    //                     );
                    //                     // showLine = true;
                    //                 },
                    //       },
                    saveAsImage: {
                        show: true,
                        title: '保存为图片',
                        icon:
                            'path://M505.593445 831.028187l-145.048047-145.139244a22.799127 22.799127 0 0 1 32.237966-32.237966l112.810081 112.901278 112.901278-112.901278a22.799127 22.799127 0 0 1 32.237966 32.237966z M1028.787817 1024H0v-399.805495a13.679476 13.679476 0 1 1 27.358953 0v372.492141h974.115509v-372.492141a13.679476 13.679476 0 1 1 27.358953 0z M505.593445 804.763593a22.799127 22.799127 0 0 1-22.799127-22.799128V22.799127a22.799127 22.799127 0 0 1 45.598254 0v759.165338a22.799127 22.799127 0 0 1-22.799127 22.799128z',
                    },
                },
                emphasis: {
                    color: '#64D399',
                    iconStyle: {
                        color: '#64D399',
                        borderColor: '#64D399',
                    },
                    borderColor: '#64D399',
                },
                // iconStyle: {
                //     color: '#64D399',
                //     borderColor: '#64D399',
                // },
            },
            legend: {
                textStyle: {
                    fontSize: 12,
                },
                right: 'center',
                top: 10,
                data: data.legend,
                color: color,
            },
            xAxis: {
                type: 'category',
                axisTick: {
                    show: false,
                },
                ...XYStyle,
                data: data.time ? data.time : [],
            },
            yAxis: {
                type: 'value',
                name: unit,
                // min: 0,
                // max: 15,
                splitLine: { show: false },
                ...XYStyle,
            },
            dataZoom: [
                {
                    type: 'inside', //inside slider
                    show: true, //flase直接隐藏图形
                    xAxisIndex: [0],
                    left: '1%', //滚动条靠左侧的百分比
                    // top:'5%',
                    // bottom: '6%',
                    // bottom: 5,
                    start: 0, //滚动条的起始位置
                    end: 100, //滚动条的截止位置（按比例分割你的柱状图x轴长度）
                    orient: 'horizontal',
                },
            ],
            animationType: 'scale',
            animationEasing: 'elasticOut',
            animationDelay: function (idx) {
                return Math.random() * 200;
            },
            animationDuration: 1000,
            series: seriesObjs,
        };
        return option;
    };
    const option = getOption();
    return (
        <ReactEcharts
            option={option}
            id="myChart"
            // className='react_for_echarts'
        />
    );
};

export const AreaLineChart1 = ({ data, color, dataZoom, unit, old, height }) => {
    return (
        <Chart color={COLOR_PLATE_16} style={{ width: '100%', height: height || '100%' }} resize={true}>
            <Tooltip
                options={{
                    trigger: 'axis',
                }}
            />
            <Legend
                options={{
                    textStyle: {
                        fontSize: 12,
                    },
                    right: 'center',
                    top: 10,
                    data: data.legend,
                    color: color,
                    // icon: 'diamond',
                }}
            />
            <XAxis
                options={{
                    type: 'category',
                    axisTick: {
                        show: false,
                    },
                    ...XYStyle,
                    data: data.time ? data.time : [],
                }}
            />
            <YAxis
                options={{
                    type: 'value',
                    name: unit,
                    // min: 0,
                    // max: 15,
                    splitLine: { show: false },
                    ...XYStyle,
                }}
            />
            <Grid
                options={{
                    left: 60,
                    top: 70,
                    bottom: 40,
                    right: 30,
                }}
            />
            {dataZoom && (
                <DataZoom
                    options={{
                        type: 'inside', //inside slider
                        show: true, //flase直接隐藏图形
                        xAxisIndex: [0],
                        left: '1%', //滚动条靠左侧的百分比
                        // top:'5%',
                        // bottom: '6%',
                        // bottom: 5,
                        start: 0, //滚动条的起始位置
                        end: 100, //滚动条的截止位置（按比例分割你的柱状图x轴长度）
                        orient: 'horizontal',
                    }}
                />
            )}
            <Toolbox
                options={{
                    //可视化的工具箱
                    show: true,
                    showTitle: true,
                    orient: 'horizontal',
                    itemGap: 15,
                    itemSize: 16,
                    zlevel: 10,
                    z: 2,
                    right: '8',
                    feature: {
                        // dataView: { show: true, readOnly: false },
                        // restore: { show: true, title: '还原' },
                        magicType: {
                            show: true,
                            type: ['bar', 'line'],
                            title: { bar: '切换为柱状图', line: '切换为折线图' },
                            icon: {
                                line:
                                    'path://M1006.948898 1023.953728H3.123503V13.881609a13.881609 13.881609 0 1 1 27.763217 0v982.355174h976.062178a13.881609 13.881609 0 0 1 0 27.763217z M168.546006 703.288568a23.136014 23.136014 0 0 1-14.15924-41.644826l257.920289-199.987709 249.868956 140.111703 215.673927-215.859015a23.136014 23.136014 0 1 1 33.315861 32.066516l-27.763218 28.873746-213.406597 212.388613-253.524447-142.193945-233.720018 181.201265a23.136014 23.136014 0 0 1-14.205513 5.043652z',
                                bar:
                                    'path://M1006.948898 1023.953728H3.123503V13.881609a13.881609 13.881609 0 1 1 27.763217 0v982.355174h976.062178a13.881609 13.881609 0 1 1 0 27.763217z M168.592279 893.744239a23.136014 23.136014 0 0 1-23.136015-23.136015v-280.130863a23.136014 23.136014 0 0 1 46.272029 0v280.130863a23.136014 23.136014 0 0 1-23.136014 23.136015z M662.962635 893.744239a23.136014 23.136014 0 0 1-23.136014-23.136015v-280.130863a23.136014 23.136014 0 0 1 46.272029 0v280.130863a23.136014 23.136014 0 0 1-23.136015 23.136015z M415.916273 912.206778a23.136014 23.136014 0 0 1-23.136014-23.136014V393.358518a23.136014 23.136014 0 0 1 46.272029 0v495.712246a23.136014 23.136014 0 0 1-23.136015 23.136014z M910.379174 912.25305a23.136014 23.136014 0 0 1-23.136014-23.136014v-417.928966a23.136014 23.136014 0 0 1 46.272029 0v417.928966a23.136014 23.136014 0 0 1-23.136015 23.136014z',
                            },
                        },
                        // myTool2: {
                        //           show: true,
                        //           title: '切换为折线图',
                        //           icon:
                        //               'path://M1006.948898 1023.953728H3.123503V13.881609a13.881609 13.881609 0 1 1 27.763217 0v982.355174h976.062178a13.881609 13.881609 0 0 1 0 27.763217z M168.546006 703.288568a23.136014 23.136014 0 0 1-14.15924-41.644826l257.920289-199.987709 249.868956 140.111703 215.673927-215.859015a23.136014 23.136014 0 1 1 33.315861 32.066516l-27.763218 28.873746-213.406597 212.388613-253.524447-142.193945-233.720018 181.201265a23.136014 23.136014 0 0 1-14.205513 5.043652z',
                        //           onclick: () => {
                        //                     const echartInstance = this.echarts.getEchartsInstance();
                        //                     echartInstance.getOption({
                        //                         toolbox: { iconStyle: { color: '#64D399' } },
                        //                         emphasis: {
                        //                             color: '#64D399',
                        //                             iconStyle: {
                        //                                 color: '#64D399',
                        //                                 borderColor: '#64D399',
                        //                             },
                        //                             borderColor: '#64D399',
                        //                         },
                        //                     });
                        //                     echartInstance.setOption(
                        //                         {
                        //                             series: this.generateChartOption(false),
                        //                         },
                        //                         {
                        //                             replaceMerge: ['series'],
                        //                         },
                        //                     );
                        //                     // showLine = true;
                        //                 },
                        //       },
                        // myTool1:{
                        //           show: true,
                        //           title: '切换为面积图',
                        //           icon:
                        //               'path://M637.6 381.7L495.8 493.1c-4.1 3.1-9.8 3.6-13.9 1l-154.7-84.6c-4.6-2.6-10.8-2.1-14.4 1.5L162.2 547.2v153.1l150-133.5c3.6-3.6 9.8-4.1 14.4-1.5l177.9 96.4c4.1 2.1 9.3 2.1 12.9-0.5l144.4-91.3c1-0.5 2.6-1.5 3.6-1.5l294.4-72.7V301.8l-317.6 77.3c-1.5 0.5-3.1 1.6-4.6 2.6z M662.3 622.2l-142.8 91.3c-3.6 2.6-8.8 2.6-12.9 0.5l-179.9-96.4c-4.6-2.6-10.8-2.1-14.4 1.5 0 0-150.5 133.5-150.5 136.6v111.4h797.6V545.9l-293.9 74.8-3.2 1.5z M64 144.2c0-1.3 0.5-2.6 1.5-3.6s2.3-1.5 3.6-1.5H101c1.3 0 2.6 0.5 3.6 1.5s1.5 2.3 1.5 3.6v778.9H955c1.3 0 2.6 0.5 3.6 1.5s1.5 2.3 1.5 3.6v31.9c0 1.3-0.5 2.6-1.5 3.6s-2.3 1.5-3.6 1.5H64v-821z',
                        //           onclick: () => {
                        //                     const echartInstance = this.echarts.getEchartsInstance();
                        //                     console.log(this.echarts.getEchartsInstance())
                        //                     echartInstance.setOption(
                        //                         {
                        //                             series: generateChartOption(true),
                        //                         },
                        //                         {
                        //                             replaceMerge: ['series'],
                        //                         },
                        //                     );
                        //                     // showLine = true;
                        //                 },
                        //       },
                        saveAsImage: {
                            show: true,
                            title: '保存为图片',
                            icon:
                                'path://M505.593445 831.028187l-145.048047-145.139244a22.799127 22.799127 0 0 1 32.237966-32.237966l112.810081 112.901278 112.901278-112.901278a22.799127 22.799127 0 0 1 32.237966 32.237966z M1028.787817 1024H0v-399.805495a13.679476 13.679476 0 1 1 27.358953 0v372.492141h974.115509v-372.492141a13.679476 13.679476 0 1 1 27.358953 0z M505.593445 804.763593a22.799127 22.799127 0 0 1-22.799127-22.799128V22.799127a22.799127 22.799127 0 0 1 45.598254 0v759.165338a22.799127 22.799127 0 0 1-22.799127 22.799128z',
                        },
                    },
                    emphasis: {
                        color: '#64D399',
                        iconStyle: {
                            color: '#64D399',
                            borderColor: '#64D399',
                        },
                        borderColor: '#64D399',
                    },
                    // iconStyle: {
                    //     color: '#64D399',
                    //     borderColor: '#64D399',
                    // },
                }}
            />
            <VisualMap
                options={[
                    {
                        // pieces:data.pieces && data.pieces.slice(2,4),
                        // show: false,
                        // pieces: data.pieces,
                        // pieces: [
                        //     {
                        //         color: '#E98164',
                        //         gt: 0.004594306929358825,
                        //         lte: 0.010488649579546592,
                        //     },
                        // ],
                        // seriesIndex: 2,
                        // seriesName:'X实时傅里叶',
                        // outOfRange: {
                        //    color: '#999',
                        // }
                    },
                ]}
            />
            {map(data.data, (item, idx) => {
                return (
                    <Series
                        key={idx}
                        options={{
                            type: 'line',
                            name: data.legend && data.legend[idx],
                            showSymbol: false,
                            smooth: true,
                            itemStyle: {
                                // color: color[idx].color,
                            },
                            lineStyle: {
                                color:
                                    data.data.length > 2 && idx === 2 && old === false
                                        ? data.data[2][idx].lineStyle.color
                                        : color[idx].line,
                            },
                            color: color[idx].line,
                            areaStyle: {
                                color: color[idx].color,
                                origin: 'start',
                                opacity: color[idx].opacity,
                                shadowColor: color[idx].shadowColor,
                                shadowOffsetX: idx === 1 && 1,
                            },
                            markLine: {
                                lineStyle: {
                                    color: old === true ? '#333' : '',
                                },
                                // data: [
                                //     { yAxis: yAxisMax },
                                //     { yAxis: yAxisMin },
                                // ],
                                data: [
                                    { yAxis: old === true ? data.data[1][0] : '' },
                                    { yAxis: old === true ? data.data[2][0] : '' },
                                ],
                            },
                            data: item,
                        }}
                    />
                );
            })}
        </Chart>
    );
};

//第一阶段图表
export const LineCharts = ({ data }) => {
    let seriesObjs = [];
    data.data &&
        data.data.map((item, index) => {
            seriesObjs.push({
                name: data.legend && data.legend[index],
                color: '#1ed0c9',
                type: 'line',
                showSymbol: false,
                data: item,
                smooth: true,
            });
        });
    const getOption = () => {
        let option = {
            legend: {
                textStyle: {
                    fontSize: 12,
                },
                right: 'center',
                top: 10,
                data: data.legend,
            },
            grid: {
                left: 100,
                top: 30,
                bottom: 40,
                right: 100,
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#6a7985',
                    },
                },
            },
            xAxis: {
                type: 'category',
                data: data.time,
            },
            yAxis: {
                type: 'value',
                // name: unit,
                show: true,
                splitLine: { show: false },
                axisLine: { show: true },
                ...XYStyle,
            },
            dataZoom: [
                {
                    type: 'inside', //inside slider
                    show: true, //flase直接隐藏图形
                    xAxisIndex: [0],
                    left: '1%', //滚动条靠左侧的百分比
                    // top:'5%',
                    // bottom: '6%',
                    // bottom: 5,
                    start: 0, //滚动条的起始位置
                    end: 100, //滚动条的截止位置（按比例分割你的柱状图x轴长度）
                    orient: 'horizontal',
                },
            ],
            animationType: 'scale',
            animationEasing: 'elasticOut',
            animationDelay: function (idx) {
                return Math.random() * 200;
            },
            animationDuration: 1000,
            series: seriesObjs,
        };
        return option;
    };
    const option = getOption();
    return (
        <ReactEcharts
            // ref={ref => (this.echarts = ref)}
            option={option}
            // style={this.props.style}
            id="myChart"
            // className='react_for_echarts'
        />
    );
};

// 折线图 根据范围线条更换颜色
export const LineCharts2 = ({ data, unit, markLine }) => {
    let seriesObjs = [];
    let value =
        data.data &&
        data.data[0].map(item => {
            return item.value;
        });
    data.data &&
        data.data.map((item, index) => {
            seriesObjs.push({
                name: data.legend && data.legend[index],
                color: '#1ed0c9',
                type: 'line',
                showSymbol: false,
                data: item,
                smooth: true,
                markLine: {
                    lineStyle: {
                        color: '#333',
                    },
                    silent: true,
                    data: [
                        {
                            yAxis: markLine && data.max,
                        },
                        {
                            yAxis: markLine && data.min,
                        },
                    ],
                },
            });
        });
    const getOption = () => {
        let option = {
            legend: {
                textStyle: {
                    fontSize: 12,
                },
                right: 'center',
                top: 10,
                data: data.legend,
            },
            grid: {
                left: 100,
                top: 50,
                bottom: 40,
                right: 100,
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#6a7985',
                    },
                },
            },
            xAxis: {
                type: 'category',
                data: data.time,
            },
            yAxis: {
                type: 'value',
                name: unit,
                show: true,
                min:
                    markLine &&
                    (data && data.min < Math.min.apply('value', value)
                        ? // ? data.data[2][0]
                          data.min
                        : Math.min.apply('value', value)),
                max:
                    markLine &&
                    (data && data.max > Math.max.apply('value', value)
                        ? // ? data.data[1][0]
                          data.max
                        : Math.max.apply('value', value)),
                splitLine: { show: false },
                axisLine: { show: true },
                ...XYStyle,
            },
            animationType: 'scale',
            animationEasing: 'elasticOut',
            animationDelay: function (idx) {
                return Math.random() * 200;
            },
            animationDuration: 1000,
            visualMap: {
                type: 'piecewise',
                show: false,
                precision: 8,
                pieces: [
                    {
                        // lt: -2.03838323,
                        lt: markLine && data && data.min,
                        color: '#E98164',
                    },
                    {
                        gt: markLine && data && data.min,
                        lte: markLine && data && data.max,
                        // gt: -2.03838323,
                        // lte: 2.14219435,
                        color: '#1ed0c9',
                    },
                    {
                        // gt: 2.14219435,
                        gt: markLine && data && data.max,
                        color: '#E98164',
                    },
                ],
            },
            series: seriesObjs,
        };
        return option;
    };
    const option = getOption();
    return (
        <ReactEcharts
            // ref={ref => (this.echarts = ref)}
            option={option}
            // style={this.props.style}
            id="myChart"
            // className='react_for_echarts'
        />
    );
};
