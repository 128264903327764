import React, { useState, useMemo, useEffect } from 'react';
import { Tree, Input } from 'antd';
import { map, get, isEmpty } from 'lodash';
import treeStyle from '../../../components/commonTree.module.less';
import { getFirmware } from '../../../api/adminServe';
import { Loading } from '../../../components/Loading';
import { EmptyView } from '../../../components/EmptyView';
const { Search } = Input;
// const getParentKey = (key, tree) => {
//     let parentKey;
//     for (let i = 0; i < tree.length; i++) {
//         const node = tree[i];
//         if (node.children) {
//             if (node.children.some(item => item.key === key)) {
//                 parentKey = node.key;
//             } else if (getParentKey(key, node.children)) {
//                 parentKey = getParentKey(key, node.children);
//             }
//         }
//     }
//     return parentKey;
// };
/**
 * 硬件管理中的固件版本批量升级
 * 单选
 *
 * @param {*} treeData   区域树的数据
 * @param {*} onClick    点击事件触发
 * @returns
 */
export const CommonTreeUpdate = ({ treeData = [], onClick, height, checkedKeys, setCheckedKeys, selectedRows, searchBar, searchState, setSearchState }) => {
    const [searchValue, setSearchValue] = useState('');
    const [searchResult, setSearchResult] = useState({ isLoading: true, data: null });
    const [selectedKeys, setSelectedKeys] = useState([]);
    // const searchBar = useRef()
    const { dataList, parentRegionIds } = useMemo(() => {
        const nodeList = [];
        const generateList = data => {
            return map(data, item => {
                const { id, name } = item;
                nodeList.push({ id, name });
                return { ...item, key: `${id}`, title: name, children: generateList(item.children) };
            });
        };
        const dataList = generateList(treeData);
        const parentRegionIds = get(dataList, '[0].id');
        // getNode && getNode(nodeList);
        // onClick && onClick(dataList[0]);
        
        return { dataList, parentRegionIds };
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [treeData]);
    //点击树节点触发
    const onSelect = (selectedKeys, info) => {
        onClick && onClick(info.node);
        setSelectedKeys(selectedKeys);
    };
    const onCheck = checkedKeysValue => {
        onClick && onClick(checkedKeysValue.length === 0 ? [] : [checkedKeysValue[checkedKeysValue.length - 1]]);
        setCheckedKeys(checkedKeysValue.length === 0 ? [] : [checkedKeysValue[checkedKeysValue.length - 1]]);
        // console.log(searchBar.current)
        
    };
    const [expandedKeys, setExpandedKeys] = useState([`${parentRegionIds}`]);
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    useEffect(() => {
        setExpandedKeys([`${parentRegionIds}`]);
    }, [parentRegionIds]);
    //展开的回调
    const onExpand = expandedKeysValue => {
        //展开/收起节点时触发
        //展开的状态
        setExpandedKeys(expandedKeysValue);
        setAutoExpandParent(false);
    };
    return (
        dataList.length > 0 ? <div className={treeStyle.container}>
            <Search
                style={{ marginBottom: 8 }}
                placeholder="可搜索版本号"
                allowClear
                ref={searchBar}
                defaultValue=""
                onSearch={value => {
                    setSearchState(true)
                    setSearchValue(`${value}`);
                    setSearchResult({ ...searchResult, isLoading: true });
                    getFirmware({category:selectedRows[0], name:value})
                        .then(result => {
                            // console.log(result,'result')
                            result = map(result.data, item => {
                                let path = {name: item.name,id:item.id,key:item.id,title: item.name, children: []};
                                return path;
                            });
                            setSearchResult({ data: result, isLoading: false });
                        })
                        .catch(() => {
                            setSearchResult({ data: null, isLoading: false });
                        });
                }}
            />
            {searchValue && searchState ? (
                <Loading show={searchResult.isLoading}>
                    {isEmpty(searchResult.data) ? (
                        <EmptyView />
                    ) : (
                        <Tree
                            checkable
                            selectable={false}
                            onExpand={onExpand} //展开事件
                            expandedKeys={expandedKeys} //默认展开的key
                            onSelect={onSelect}
                            selectedKeys={selectedKeys}
                            onCheck={onCheck}
                            checkedKeys={checkedKeys}
                            autoExpandParent={autoExpandParent} //是否自动展开父节点
                            treeData={searchResult.data}
                        />
                    )}
                </Loading>
            ) : (
                <Tree
                    checkable
                    selectable={false}
                    onExpand={onExpand} //展开事件
                    expandedKeys={expandedKeys} //默认展开的key
                    onSelect={onSelect}
                    selectedKeys={selectedKeys}
                    onCheck={onCheck}
                    checkedKeys={checkedKeys}
                    autoExpandParent={autoExpandParent} //是否自动展开父节点
                    treeData={dataList}
                />
            )}
        </div> : <div style={{height: height || '76vh'}}><EmptyView /></div> 
    );
};
