import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Space, Checkbox, message, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { isUndefined, map } from 'lodash';
import { BreadcrumbCustom } from '../../../components/BreadcrumbCustom';
import { getNoticesets, addNoticesets, getUsers } from '../../../api/webServe';
import lessStyle from './style.module.less';
const { Option } = Select;

const AlertNotification = () => {
    const [form2] = Form.useForm();
    const [form3] = Form.useForm();
    const [form4] = Form.useForm();
    const [form02] = Form.useForm();
    const [form03] = Form.useForm();
    const [form04] = Form.useForm();
    const [keys2, setKeys2] = useState();
    const [keys3, setKeys3] = useState();
    const [keys4, setKeys4] = useState();
    const [mobile2, setMobile2] = useState();
    const [mobile3, setMobile3] = useState();
    const [mobile4, setMobile4] = useState();
    const [data2, setData2] = useState({});
    const [data3, setData3] = useState({});
    const [data4, setData4] = useState({});
    /**
     * 获取告警数据
     */
    useEffect(() => {
        getNoticesets()
            .then(result => {
                //当数据为空时
                if (result.data.length === 0) {
                    result.data.push(
                        { category_id: 2, category: [] },
                        { category_id: 3, category: [] },
                        { category_id: 4, category: [] },
                    );
                } else {
                    //当数据不为空时
                    result.data &&
                        result.data.forEach(item => {
                            if (item.category_id === 4) {
                                setData4(item);
                            } else if (item.category_id === 3) {
                                setData3(item);
                            } else if (item.category_id === 2) {
                                setData2(item);
                            }
                        });
                }
            })
            .catch();
            //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    //管理员
    form02.setFieldsValue({
        is_hidden_trouble: data2 ? data2.is_hidden_trouble : false,
        is_high_risk: data2 && data2.is_high_risk,
        is_error: data2 && data2.is_error,
        category_id: data2 && data2.category_id,
    });
    //运维管理员
    form03.setFieldsValue({
        is_hidden_trouble: data3 ? data3.is_hidden_trouble : false,
        is_high_risk: data3 && data3.is_high_risk,
        is_error: data3 && data3.is_error,
        category_id: data3 && data3.category_id,
    });
    //运维专员
    form04.setFieldsValue({
        is_hidden_trouble: data4 ? data4.is_hidden_trouble : false,
        is_high_risk: data4 && data4.is_high_risk,
        is_error: data4 && data4.is_error,
        category_id: data4 && data4.category_id,
    });
    /**
     * 管理员
     */
    useEffect(() => {
        if (!isUndefined(mobile2)) {
            if (JSON.stringify(data2) === '{}') {
                data2.category = [];
                if (isUndefined(data2.category[keys2])) {
                    data2 &&
                        data2.category.splice(keys2, 0, {
                            mobile: mobile2 && mobile2.value,
                            real_name: mobile2 && mobile2.children,
                            user_id: mobile2 && mobile2.key,
                        });
                } else {
                    data2 &&
                        data2.category.splice(keys2, 1, {
                            mobile: mobile2 && mobile2.value,
                            real_name: mobile2 && mobile2.children,
                            user_id: mobile2 && mobile2.key,
                        });
                }
            } else {
                if (isUndefined(data2.category[keys2])) {
                    data2 &&
                        data2.category.splice(keys2, 0, {
                            mobile: mobile2 && mobile2.value,
                            real_name: mobile2 && mobile2.children,
                            user_id: mobile2 && mobile2.key,
                        });
                } else {
                    data2 &&
                        data2.category.splice(keys2, 1, {
                            mobile: mobile2 && mobile2.value,
                            real_name: mobile2 && mobile2.children,
                            user_id: mobile2 && mobile2.key,
                        });
                }
            }
        }
        form2.setFieldsValue({ sights2: data2 && data2.category });
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mobile2, data2]);
    /**
     * 运维管理员
     */
    useEffect(() => {
        if (!isUndefined(mobile3)) {
            if (JSON.stringify(data3) === '{}') {
                data3.category = [];
                if (isUndefined(data3.category[keys3])) {
                    data3 &&
                        data3.category.splice(keys3, 0, {
                            mobile: mobile3 && mobile3.value,
                            real_name: mobile3 && mobile3.children,
                            user_id: mobile3 && mobile3.key,
                        });
                } else {
                    data3 &&
                        data3.category.splice(keys3, 1, {
                            mobile: mobile3 && mobile3.value,
                            real_name: mobile3 && mobile3.children,
                            user_id: mobile3 && mobile3.key,
                        });
                }
            } else {
                if (isUndefined(data3.category[keys3])) {
                    data3 &&
                        data3.category.splice(keys3, 0, {
                            mobile: mobile3 && mobile3.value,
                            real_name: mobile3 && mobile3.children,
                            user_id: mobile3 && mobile3.key,
                        });
                } else {
                    data3 &&
                        data3.category.splice(keys3, 1, {
                            mobile: mobile3 && mobile3.value,
                            real_name: mobile3 && mobile3.children,
                            user_id: mobile3 && mobile3.key,
                        });
                }
            }
        }
        form3.setFieldsValue({ sights3: data3 && data3.category });
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mobile3, data3]);
    /**
     * 运维专员
     */
    useEffect(() => {
        if (!isUndefined(mobile4)) {
            if (JSON.stringify(data4) === '{}') {
                data4.category = [];
                if (isUndefined(data4.category[keys4])) {
                    data4 &&
                        data4.category.splice(keys4, 0, {
                            mobile: mobile4 && mobile4.value,
                            real_name: mobile4 && mobile4.children,
                            user_id: mobile4 && mobile4.key,
                        });
                } else {
                    data4 &&
                        data4.category.splice(keys4, 1, {
                            mobile: mobile4 && mobile4.value,
                            real_name: mobile4 && mobile4.children,
                            user_id: mobile4 && mobile4.key,
                        });
                }
            } else {
                if (isUndefined(data4.category[keys4])) {
                    data4 &&
                        data4.category.splice(keys4, 0, {
                            mobile: mobile4 && mobile4.value,
                            real_name: mobile4 && mobile4.children,
                            user_id: mobile4 && mobile4.key,
                        });
                } else {
                    data4 &&
                        data4.category.splice(keys4, 1, {
                            mobile: mobile4 && mobile4.value,
                            real_name: mobile4 && mobile4.children,
                            user_id: mobile4 && mobile4.key,
                        });
                }
            }
        }
        form4.setFieldsValue({ sights4: data4 && data4.category });
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mobile4, data4]);

    /**
     * 表单数据
     */
    const dataForm = [
        {
            id: 2,
            name: '管理员',
            form: form2,
            setData: 'sights2',
            childern: { form: form02 },
            setKeys: setKeys2,
            setMobile: setMobile2,
        },
        {
            id: 3,
            name: '运维管理员',
            form: form3,
            setData: 'sights3',
            childern: { form: form03 },
            setKeys: setKeys3,
            setMobile: setMobile3,
        },
        {
            id: 4,
            name: '运维专员',
            form: form4,
            setData: 'sights4',
            childern: { form: form04 },
            setKeys: setKeys4,
            setMobile: setMobile4,
        },
    ];

    /**
     * 运维人员姓名
     * @param {*} value
     * @returns 
     */
    const newDataList = value => {
        const data = [];
        map(value, item => {
            if (!isUndefined(item.real_name)) {
                data.push(item);
            }
        });
        return data;
    };

    /**
     * 点击保存设置事件
     */
    const handleFormSubmit = () => {
        //处理多个异步
        Promise.all([
            form2.validateFields(), //管理员
            form3.validateFields(), //运维管理员
            form4.validateFields(), //运维专员
            form02.validateFields(), //管理员 接收事件者
            form03.validateFields(), //运维管理员 接收事件者
            form04.validateFields(), //运维专员 接收事件者
        ])
            .then(values => {
                let newValues = [];
                if (values[0].sights2 && values[0].sights2.length !== 0) {
                    newValues.push({
                        ...values[3],
                        category: newDataList(values[0].sights2) || [],
                        category_id: 2,
                        is_high_risk: values[3].is_high_risk || false,
                        is_hidden_trouble: values[3].is_hidden_trouble || false,
                        is_error: values[3].is_error || false,
                    });
                }
                if (values[1].sights3 && values[1].sights3.length !== 0) {
                    newValues.push({
                        ...values[4],
                        category: newDataList(values[1].sights3) || [],
                        category_id: 3,
                        is_high_risk: values[4].is_high_risk || false,
                        is_hidden_trouble: values[4].is_hidden_trouble || false,
                        is_error: values[4].is_error || false,
                    });
                }
                if (values[2].sights4 && values[2].sights4.length !== 0) {
                    newValues.push({
                        ...values[5],
                        category: newDataList(values[2].sights4) || [],
                        category_id: 4,
                        is_high_risk: values[5].is_high_risk || false,
                        is_hidden_trouble: values[5].is_hidden_trouble || false,
                        is_error: values[5].is_error || false,
                    });
                }
                //  else {
                //     newValues = [
                //         {
                //             ...values[3],
                //             category: newDataList(values[0].sights2) || [],
                //             category_id: 2,
                //             is_high_risk: values[3].is_high_risk || false,
                //             is_hidden_trouble: values[3].is_hidden_trouble || false,
                //         },
                //         {
                //             ...values[4],
                //             category: newDataList(values[1].sights3) || [],
                //             category_id: 3,
                //             is_high_risk: values[4].is_high_risk || false,
                //             is_hidden_trouble: values[4].is_hidden_trouble || false,
                //         },
                //         {
                //             ...values[5],
                //             category: newDataList(values[2].sights4) || [],
                //             category_id: 4,
                //             is_high_risk: values[5].is_high_risk || false,
                //             is_hidden_trouble: values[5].is_hidden_trouble || false,
                //         },
                //     ];
                // }
                addNoticesets(newValues)
                    .then(() => {
                        message.success('提交成功!');
                    })
                    .catch();
            })
            .catch();
    };
    return (
        <div className="container clearfix">
            <BreadcrumbCustom paths={['首页', '系统设置', '告警通知']} className="rumbCustom" />
            <div
                style={{
                    width: '98%',
                    backgroundColor: '#fff',
                    margin: '1rem',
                    borderRadius: '10px',
                    paddingBottom: '1rem',
                }}
                className={lessStyle.containerForm}
            >
                {dataForm.map(item => {
                    return (
                        <Field
                            key={item.id}
                            id={item.id}
                            name={item.name}
                            form={item.form}
                            setData={item.setData}
                            childern={item.childern}
                            setKeys={item.setKeys}
                            setMobile={item.setMobile}
                        />
                    );
                })}
                <Button
                    type="primary"
                    htmlType="submit"
                    onClick={handleFormSubmit}
                    style={{ display: 'flex', margin: '1rem auto 0' }}
                >
                    保存设置
                </Button>
            </div>
        </div>
    );
};
//管理员的表单
// const RenderForm = ({ form, name, id }) => {
//     return (
//         <Form autoComplete="off" form={form} name={0} initialValues={{ user_id: 32 }}>
//             <span
//                 style={{
//                     padding: '0 1rem',
//                     fontSize: '1.1rem',
//                     fontWeight: '600',
//                     marginBottom: '1rem',
//                     display: 'block',
//                 }}
//             >
//                 {name}
//             </span>
//             <Space key={id} style={{ display: 'flex', padding: '0 1rem' }} align="baseline">
//                 {'接收事件者：'}
//                 <Form.Item name="is_hidden_trouble" valuePropName="checked">
//                     <Checkbox>隐患</Checkbox>
//                 </Form.Item>
//                 <Form.Item name="is_high_risk" valuePropName="checked">
//                     <Checkbox>高危</Checkbox>
//                 </Form.Item>
//             </Space>
//             <Space key={name} style={{ display: 'flex', marginBottom: 8, padding: '0 1rem' }} align="baseline">
//                 <Form.Item name="real_name" label="姓名">
//                     <Input placeholder="请选择姓名" />
//                 </Form.Item>
//                 <Form.Item name="user_id" style={{ display: 'none' }}>
//                     <Input placeholder="用户ID" />
//                 </Form.Item>
//                 <Form.Item
//                     name="mobile"
//                     label="手机号"
//                     rules={[{ pattern: /^1(3|4|5|7|8)\d{9}$/, message: '手机号码格式不正确！' }]}
//                 >
//                     <Input placeholder="请选择手机号" addonBefore={'+86'} maxLength={11} />
//                 </Form.Item>
//                 <Form.Item
//                     name="email"
//                     label="邮箱"
//                     rules={[
//                         {
//                             pattern: /^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/,
//                             message: '邮箱格式不正确',
//                         },
//                     ]}
//                 >
//                     <Input placeholder="请输入邮箱" type="email" />
//                 </Form.Item>
//             </Space>
//         </Form>
//     );
// };

/**
 * 接收事件类型
 * @param {*} name
 * @param {*} id
 * @param {*} childern
 * @returns
 */
const RenderCheckbox = ({ name, id, childern }) => {
    return (
        <Form name={id} autoComplete="off" form={childern.form}>
            <span
                style={{
                    padding: '0.5rem 1rem',
                    fontSize: '1.25rem',
                    fontWeight: '500',
                    color: '#333333',
                    marginBottom: '0.5rem',
                    display: 'block',
                    boxShadow: '0px 2px 3px #e7ebeb',
                    background: 'linear-gradient(180deg, #f5f6fa 0%, #f5f6fa 100%)',
                }}
            >
                {name}
            </span>
            <Space key={id} style={{ display: 'flex', padding: '0 1rem', marginBottom: '0.5rem' }} align="baseline">
                <span style={{ fontSize: '0.95rem', marginLeft: '0.5rem' }}>{'接收事件者：'}</span>
                <Form.Item name="category_id" style={{ display: 'none' }}>
                    <Checkbox>类型</Checkbox>
                </Form.Item>
                <Form.Item name="is_hidden_trouble" valuePropName="checked">
                    <Checkbox>异常</Checkbox>
                </Form.Item>
                <Form.Item name="is_high_risk" valuePropName="checked">
                    <Checkbox>高危</Checkbox>
                </Form.Item>
                <Form.Item name="is_error" valuePropName="checked">
                    <Checkbox>故障</Checkbox>
                </Form.Item>
            </Space>
        </Form>
    );
};

/**
 * 人员信息表单
 * @param {*} form
 * @param {*} name
 * @param {*} id
 * @param {*} setData
 * @param {*} childern
 * @param {*} setKeys key
 * @param {*} setMobile 手机号
 * @returns
 */
const Field = ({ form, name, id, setData, childern, setKeys, setMobile }) => {
    const [users, setUsers] = useState();
    const [disableds, setDisableds] = useState(false);
    //用户信息列表
    useEffect(() => {
        if (!isUndefined(id)) {
            getUsers(id).then(result => {
                setUsers(result.data);
            });
        }
    }, [id]);
   
    return (
        <Form name={id} autoComplete="off" form={form}>
            <Form.List name={setData}>
                {(fields, { add, remove }) => (
                    <>
                        <RenderCheckbox childern={childern} name={name} />
                        {fields.length < 1 && setDisableds(false)}
                        {fields.map((field, keys) => (
                            <Space
                                key={field.key}
                                align="baseline"
                                style={{ display: 'flex', marginBottom: '.1rem', padding: '0 1rem' }}
                            >
                                <Form.Item name="user_id" style={{ display: 'none' }}>
                                    <Input placeholder="用户ID" />
                                </Form.Item>
                                <Form.Item
                                    shouldUpdate={(prevValues, curValues) => prevValues.sights !== curValues.sights}
                                    style={{ marginBottom: '5px' }}
                                >
                                    {() => (
                                        <Form.Item
                                            {...field}
                                            label="姓名"
                                            name={[field.name, 'real_name']}
                                            fieldKey={[field.fieldKey, 'real_name']}
                                            style={{ marginBottom: '5px' }}
                                        >
                                            <Select
                                                style={{ width: 150 }}
                                                placeholder="请选择姓名"
                                                virtual={false}
                                                onChange={(values, data) => {
                                                    setMobile(data);
                                                    setKeys(keys);
                                                }}
                                                onSelect={ () => {setDisableds(false)}
                                                }
                                            >
                                                {users &&
                                                    users.map(item => (
                                                        <Option key={item.id} value={item.mobile}>
                                                            {item.real_name}
                                                        </Option>
                                                    ))}
                                            </Select>
                                        </Form.Item>
                                    )}
                                </Form.Item>
                                <Form.Item
                                    {...field}
                                    label="手机号"
                                    name={[field.name, 'mobile']}
                                    fieldKey={[field.fieldKey, 'mobile']}
                                    rules={[{ pattern: /^1(3|4|5|7|8)\d{9}$/, message: '手机号码格式不正确！' }]}
                                    style={{ marginBottom: '5px' }}
                                >
                                    <Input placeholder="请选择手机号!" addonBefore={'+86'} maxLength={11}/>
                                </Form.Item>
                                <Form.Item
                                    {...field}
                                    label="邮箱"
                                    name={[field.name, 'email']}
                                    fieldKey={[field.fieldKey, 'email']}
                                    rules={[
                                        {
                                            pattern: /^([a-z0-9_-]+)@([\da-z-]+)\.([a-z]{2,6})$/,
                                            message: '邮箱格式不正确',
                                        },
                                    ]}
                                    style={{ marginBottom: '5px' }}
                                >
                                    <Input placeholder="请选择邮箱!" type="email" />
                                </Form.Item>
                                <MinusCircleOutlined onClick={() => remove(field.name)} style={{ fontSize: '1rem' }} />
                            </Space>
                        ))}
                        <Form.Item style={{ borderBottom: '12px solid #f2f2f2', padding: '0 1.5rem 1rem' }}>
                            <Button
                                type="dashed"
                                onClick={() => {add();setDisableds(true)}}
                                block
                                icon={<PlusOutlined />}
                                style={{ width: '2%', textAlign: 'center' }}
                                disabled= {disableds}
                            ></Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </Form>
    );
};

export default AlertNotification;
